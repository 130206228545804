<template>
  <div :class="$style.container">
    <section :class="$style.about">
      <h2 :class="$style.header">
        {{ $t('header.account.info.about.header') }}
      </h2>

      <div :class="$style.logos">
        <Icon
          name="od-logo"
          :class="$style.odLogo"
        />

        <Icon
          name="ln-logo"
          :class="$style.lnLogo"
        />

        <span>{{ $t('login.copyright', { year: new Date().getFullYear() }) }}</span>
      </div>

      <div :class="$style.links">
        <a
          :href="privacyPolicyLink"
          target="_blank"
        >
          {{ $t('login.privacyPolicy') }}
        </a>
        <a
          :href="termsLink"
          target="_blank"
        >
          {{ $t('login.termsAndConditions') }}
        </a>
        <a
          :href="copyrightLink"
          target="_blank"
        >
          {{ $t('header.account.info.about.copyrightNotice') }}
        </a>
        <a
          :href="releaseNoteLink"
          target="_blank"
        >
          {{ $t('header.account.info.about.releaseNotes') }}
        </a>
      </div>
    </section>

    <section
      v-for="(data, category) in info"
      :key="category"
    >
      <h2 :class="$style.header">
        {{ $t(`header.account.info.${category}.header`) }}
      </h2>

      <table :class="$style.table">
        <tbody>
          <tr
            v-for="(value, label) in data"
            :key="label"
          >
            <th scope="row">
              {{ $t(`header.account.info.${category}.${label}`) }}
            </th>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserMenuAbout',
  setup: () => {
    const privacyPolicyLink = Constants.PRIVACY_POLICY_PATH;
    const termsLink = Constants.TERMS_AND_COND_PATH;
    const copyrightLink = Constants.COPYRIGHT_PATH;
    const releaseNoteLink = Constants.RELEASENOTE_PATH;
    const { name, ...serverInfo } = APP.shell.info;
    const info = {
      account: { id: APP.patron.accountId },
      client: APP.client.info,
      server: serverInfo
    };

    return {
      copyrightLink,
      info,
      privacyPolicyLink,
      releaseNoteLink,
      termsLink
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  section {
    padding: 1rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid @c-medium-black;
    }
  }

  a {
    .pop-text();

    .focus-outline;
  }
}

.logos,
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.links {
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.header {
  font-size: @fs-small-head;
  font-weight: @fw-bold;
  margin-bottom: 1rem;
}

.od-logo {
  width: 226px;
  height: 27px;
}

.ln-logo {
  width: 253px;
  height: 32px;
}

.od-logo,
.ln-logo {
  display: block;
  margin: 20px auto;

  :global(.icon-solid) {
    fill: var(--c-dark-black);
  }
}

.table {
  width: 100%;

  th,
  td {
    padding: 0.5rem;
    vertical-align: top;
  }

  th {
    color: var(--c-darkish-black);
    text-align: left;
  }

  td {
    color: var(--c-dark-black);
  }
}
</style>
