<template>
  <p
    :class="$style.error"
    role="alert"
  >
    <Icon name="warning" /> {{ contents }}
  </p>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormError',
  props: {
    contents: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.error {
  color: @c-error;
  display: flex;
  align-items: center;
  max-width: max-content;

  svg {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
}
</style>
