import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_BackToTopButton = _resolveComponent("BackToTopButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "notch" }, null, -1)),
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createElementVNode("span", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.header), 1),
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              name: _ctx.icon,
              role: "presentation",
              class: _normalizeClass(_ctx.$style.icon)
            }, null, 8, ["name", "class"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "extra"),
        _createElementVNode("button", {
          "data-close_focus": "",
          class: _normalizeClass(_ctx.$style.close),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, _toDisplayString(_ctx.$t('general.close')), 3)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.body)
    }, [
      _createElementVNode("span", {
        ref: "topOfBody",
        class: _normalizeClass(_ctx.$style.emptySpan)
      }, null, 2),
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _createVNode(_component_BackToTopButton, {
      show: _ctx.showBackToTop,
      scrollTo: _ctx.topOfBody,
      focusOn: _ctx.focusOn,
      onShow: _cache[1] || (_cache[1] = (shouldShow) => _ctx.showBackToTop = shouldShow)
    }, null, 8, ["show", "scrollTo", "focusOn"])
  ], 2))
}