import { C } from 'app/base/common';
import { Hold } from 'app/models/hold';
import { Loan } from 'app/models/loan';
import { Collation } from './collation';
import { Possession } from './possession';

export class Possessions<T extends Possession> extends Collation<T> {
  public static POSSESSION_CLASS = {
    loan: Loan,
    hold: Hold
  };

  public active: Possession;
  protected SERIAL_VERSION = 2;
  protected SERIAL_VERSION_MIN = 2;
  protected MAX_TIMEOUT = 2147483647;
  protected type: string;
  private _sweepTimer: number;


  constructor(type?, persistent?, announce?) {
    super();
    this.type = type;
    this.ITEM_NAME = this.type;
    this.ITEM_CLASS = Possessions.POSSESSION_CLASS[this.type];
    this.PERSISTENT = persistent;
    this.ANNOUNCE = announce;
  }


  protected _fullAttributesToQuery(itemAttributes): any {
    return {
      titleSlug: itemAttributes.id
    };
  }


  protected _scheduleExpireSweep() {
    clearTimeout(this._sweepTimer);
    let soonestExpireTime = Infinity;
    C.each(this.all, (item) => {
      soonestExpireTime = Math.min(soonestExpireTime, item.expireTime);
    });
    if (isFinite(soonestExpireTime)) {
      const deltaFromNow = soonestExpireTime - C.epochMilliseconds() + 1000;
      const delay = Math.max(0, Math.min(deltaFromNow, this.MAX_TIMEOUT));
      console.log('[POSSESSIONS] Expire sweep delay: %s', delay);
      const binding = this._sweepForExpiredPossessions.bind(this);
      this._sweepTimer = window.setTimeout(binding, delay);
    }
  }


  protected _sweepForExpiredPossessions() {
    const removals = [];
    const now = C.epochMilliseconds();
    C.each(this.all, (item) => {
      if (item.expireTime <= now) {
        removals.push(item);
      }
    });
    while (removals.length) {
      this.removeItem(removals.shift());
    }
    this._scheduleExpireSweep();
  }


  public removeItem(possession: T) {
    super.removeItem(possession);
    // TODO: is this still needed?
    // possession.title.deactivate();
  }


  protected _announce(evtSubtype, detail) {
    if (evtSubtype === 'load:all' || evtSubtype === 'update:all') {
      this._scheduleExpireSweep();
    }
    super._announce(evtSubtype, detail);
  }
}
