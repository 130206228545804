import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { Freshable } from '../base/services/freshable';
import { Freshness } from '../base/services/freshness';
import { ThunderItemsResponse, ThunderMediaResponse } from '../base/thunder';
import { BankedTitle, Title, TitleMapper, TitleRecord } from './title';


/**
 * The Title Cache is Elrond's solution to managing title reference and
 * freshening for a patron's "important" titles. It addresses the issue
 * of having each model store a copy of a title, and the likely scenario
 * that all of those copies get out of sync.
 *
 * "Important" means titles that they have on loan, on hold, have tagged,
 * have read, and really any title they've had a meaningful interaction with.
 * If it's important enough to be in the bank, it should be in here.
 *
 * See `ItemWithTitleCache` for information on how to make models
 * that require a title to be stored in the cache.
 *
 */
export class TitleCache {
  private static readonly BANK_NAME: string = 'titlecache';
  // private static readonly TITLE_CHUNK = 25;
  private static readonly GETTITLES_MAX_RETRIES = 2;

  private cache: Dictionary<CacheTitle>;
  private saveTimer = -1;

  private freshenTimer = -1;


  /**
   * Serialize the title cache and store it in the bank.
   */
  public save(): void {
    clearTimeout(this.saveTimer);
    this.saveTimer = window.setTimeout(() => {
      // If the user has signed out or cleared the cache
      // before we can store this in the bank, don't bother.
      if (!APP.library) {
        return;
      }

      const bankedTitles: BankedTitle[] = [];

      C.each(this.cache, (titleSlug, cachedTitle) => {
        // If the cache doesn't have a title,
        // it might be because we're still fetching it.
        // In that case, we're okay skipping it because
        // when the fetch succeeds we'll save again.
        if (cachedTitle.title) {
          bankedTitles.push(<BankedTitle>cachedTitle.title.serialize());
        }
      });

      APP.bank.set(TitleCache.BANK_NAME, bankedTitles);
    }, 200);
  }


  /**
   * Load the title cache from the bank and kick off
   * a freshen.
   */
  public load(): void {
    this.cache = {};

    if (!APP.library) {
      APP.bank.clear(TitleCache.BANK_NAME);
    }

    const bankedTitles = <BankedTitle[]>APP.bank.get(TitleCache.BANK_NAME);

    if (bankedTitles) {
      C.each(bankedTitles, (title) => {
        const cachedTitle = new CacheTitle(title.slug);
        cachedTitle.title = TitleMapper.mapFromBank(title);
        this.cache[title.slug] = cachedTitle;
      });

      this.freshenAll();
    }
  }


  /**
   * Retrieve a title from the title cache.
   * Note: The title may not be fresh.
   *
   * @param slug A title id
   * @returns The title record if it is present in the cache, null otherwise.
   */
  public get(slug: string): TitleRecord {
    return this.cache[slug] ? this.cache[slug].title : null;
  }


  /**
   * Retrieves multiple titles from the title cache.
   * Note: The titles may not be fresh.
   *
   * @param slugs An array of title ids
   * @returns An array of title records. Missing titles are returned as `null`
   * so that the array lengths match.
   */
  public getMultiple(slugs: string[]): TitleRecord[] {
    return slugs.map((slug) => this.get(slug));
  }


  /**
   * Retrieves a title from the cache if and
   * only if it is fresh
   *
   * @param slug A title id
   */
  public getIfFresh(slug: string): TitleRecord | null {
    const title = this.get(slug);

    return title && title.freshness && title.freshness.isFresh()
      ? title
      : null;
  }


  /**
   * Retrieves fresh title records for the supplied ids,
   * and upserts those records in the cache.
   *
   * The returned title array preserves the input
   * ordering.
   *
   * This is the most common usage of the Title Cache.
   *
   * @param slugs An array of title ids
   */
  public async getFreshTitles(slugs: string[]): Promise<TitleRecord[]> {
    return (await this._freshenTitles(slugs)).map((t) => t.title);
  }


  /**
   * Stores the supplied title in the cache.
   *
   * If a title id is given, this kicks off a freshen.
   *
   * @param title A title id or title record
   */
  public set(title: string | TitleRecord): void {
    this.setInternal(title);

    this.save();
  }


  private setInternal(title: string | TitleRecord): void {
    if (C.isString(title)) {
      this.cache[title] = this.cache[title] || new CacheTitle(title);

      this._freshenTitleSoon();
    } else {
      const existing = this.cache[title.slug]?.title;
      const t = existing
        ? TitleMapper.freshenFromCache(existing as Title, title)
        : TitleMapper.mapFromCache(title);

      const cachedTitle = this.cache[title.slug] || new CacheTitle(title.slug);
      cachedTitle.title = t;

      this.cache[title.slug] = cachedTitle;

      // if (title.lexisMetadata && title.lexisMetadata.priorReleases) {
      //   C.each(title.lexisMetadata.priorReleases, (p) => this.setInternal(p.titleId));
      // }
    }
  }


  public async freshenAll(): Promise<boolean> {
    await this._freshenTitles(Object.keys(this.cache));

    this.save();

    return true;
  }


  private _freshenTitleSoon(): void {
    clearTimeout(this.freshenTimer);
    this.freshenTimer = window.setTimeout(() => {
      this.freshenAll();
    }, 200);
  }


  private async _freshenTitles(slugs: string[]): Promise<CacheTitle[]> {
    if (!slugs || !slugs.length) {
      return [];
    }

    const cachedTitles = slugs
      .map((s) => this.cache[s] || (this.cache[s] = new CacheTitle(s)))
      .filter((t) => !t.freshness.isFresh());

    await this._fetchAndMapTitles(cachedTitles);

    return slugs.map((s) => this.cache[s]);
  }


  private async _fetchAndMapTitles(cachedTitles: CacheTitle[]): Promise<boolean> {
    C.each(cachedTitles, (cachedTitle) => {
      cachedTitle.freshness.isAsyncFreshening = true;
    });

    const resultDict = await this._fetchTitles(cachedTitles.map((t) => t.slug));

    if (resultDict) {
      C.each(cachedTitles, (cachedTitle) => {
        cachedTitle.freshness.isAsyncFreshening = false;

        if (resultDict[cachedTitle.slug]) {
          if (cachedTitle.title) {
            TitleMapper.freshenFromThunder(<Title>cachedTitle.title, resultDict[cachedTitle.slug]);
          } else {
            cachedTitle.title = TitleMapper.mapFromThunder(resultDict[cachedTitle.slug]);
          }
          cachedTitle.freshness.freshAt = C.epochMilliseconds();
          cachedTitle.freshness.isAsyncFreshening = false;
          this.cache[cachedTitle.slug] = cachedTitle;
        } else {
          // Noop
        }
      });
    }

    return true;
  }


  /**
   * Make batch calls to thunder for the latest title data
   * @param slugs Title slugs to fetch
   * @notes Uses TITLE_CHUNK for chunk size and sets _isRefreshing=true while processing
   */
  private async _fetchTitles(slugs: string[]): Promise<Dictionary<ThunderMediaResponse>> {
    const outputDictionary = {};

    // for (let i = 0, len = slugs.length; i < len; i += TitleCache.TITLE_CHUNK) {
    //   const derSlugs = slugs.slice(i, i + TitleCache.TITLE_CHUNK);
    const titles = await this._fetchTitleBlock(slugs);

    if (!titles || C.isString(titles)) {
      console.log(`[TITLE-CACHE] Error fetching titles (slugs: ${slugs})`, titles);
    } else {
      C.each(titles.items, (item) => {
        outputDictionary[item.id] = item;
      });
    //   }
    }

    return outputDictionary;
  }


  private async _fetchTitleBlock(slugs: string[]): Promise<ThunderItemsResponse<ThunderMediaResponse> | string> {
    let retries = TitleCache.GETTITLES_MAX_RETRIES;
    let result: ThunderItemsResponse<ThunderMediaResponse> = null;
    let error: string = null;

    while (retries > 0) {
      try {
        result = await APP.services.thunder.getTitles(APP.library.key(), slugs);

        break;
      } catch (err) {
        error = err.toString();

        if (retries > 0) {
          console.log('[FRESHEN] \u21BB', 'TITLECACHE', err);
          retries -= 1;
        }
      }
    }

    return result || error;
  }
}

class CacheTitle implements Freshable {
  public readonly slug: string;
  public freshness: Freshness;
  public title: TitleRecord = null;


  constructor(slug: string) {
    this.slug = slug;
    this.freshness = new Freshness(this, 5 * C.MS_MINUTE);
  }


  public freshen(): Promise<boolean> {
    return undefined;
  }


  public arePropertiesFresh(): boolean {
    return this.title && this.title.arePropertiesFresh();
  }
}
