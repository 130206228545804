<template>
  <Surface>
    <Page
      :header="$t('mysubscriptions.header')"
      :useBackToTop="true"
    >
      <div v-if="subscriptionsWithBook && subscriptionsWithBook.length">
        <ul>
          <li
            v-for="subscription in subscriptionsWithBook"
            :key="subscription.id"
            :class="$style.subscription"
          >
            <Card
              :item="subscription.cardableItem"
              :compact="true"
              :showSeriesBadge="subscription.type === 'Series'"
            >
              <template #cover>
                <Cover
                  v-if="subscription.type === 'Title'"
                  :item="(subscription.item as TitleRecord)"
                />

                <div v-else>
                  <SeriesCover
                    :series="(subscription.item as Series)"
                    :width="seriesCoverSizes.seriesCardCompact"
                  />
                </div>
              </template>

              <template #actions>
                <button
                  :aria-label="$t('subscription.unsubscribe.long')"
                  :class="$style.unsubscribe"
                  @click="unsubscribe(subscription.id)"
                >
                  {{ $t('subscription.unsubscribe.short') }}
                </button>
              </template>
            </Card>
          </li>
        </ul>
      </div>
      <div v-else-if="loading">
        <Loading />
      </div>
      <EmptyState
        v-else
        :link="helpLink"
        :header="$t('mysubscriptions.emptystate.header')"
        :content="$t('mysubscriptions.emptystate.content')"
        :linkLabel="$t('mysubscriptions.emptystate.link')"
      />
    </Page>
  </Surface>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { Constants, seriesCoverSizes } from 'app/base/constants';
import { Subscription } from 'app/base/samwise';
import Cover from 'app/components/Cover.vue';
import EmptyState from 'app/components/EmptyState.vue';
import Loading from 'app/components/Loading.vue';
import Page from 'app/components/Page.vue';
import SeriesCover from 'app/components/SeriesCover.vue';
import Surface from 'app/components/Surface.vue';
import Card, { CardableItem } from 'app/components/cards/Card.vue';
import { SeriesSubscriptionWithMetadata, TitleSubscriptionWithMetadata, useSubscriptionsWithMetadata } from 'app/functions/use-subscriptions-with-metadata';
import { SubscriptionsWithMetadataSymbol } from 'app/keys/injection-keys';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, inject } from 'vue';

type SubscriptionWithBook = Subscription & {
  sortTitle: string;
  item: TitleRecord | Series;
  cardableItem: CardableItem;
};

export default defineComponent({
  name: 'MySubscriptions',
  components: {
    Surface,
    Page,
    EmptyState,
    Cover,
    SeriesCover,
    Card,
    Loading
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.SUBSCRIPTIONS;

    const {
      subscriptionsWithMetadata,
      loading,
      fetchAllSubscriptions,
      unsubscribe
    } = inject(SubscriptionsWithMetadataSymbol, useSubscriptionsWithMetadata());

    if (APP.patron.accountId) {
      fetchAllSubscriptions();
    } else {
      APP.events.on('patron:accountId:acquired', fetchAllSubscriptions);
    }

    const subscriptionsWithBook = computed(() => {
      if (!subscriptionsWithMetadata.value) { return undefined; }

      const subsWithBook: SubscriptionWithBook[] = [];

      const titleSubscriptions = subscriptionsWithMetadata.value.filter((sub) => sub.type === 'Title') as TitleSubscriptionWithMetadata[];
      titleSubscriptions.forEach((sub) => {

        subsWithBook.push({
          ...sub,
          sortTitle: sub.title.sortTitle,
          item: sub.title,
          cardableItem: {
            id: sub.title.slug,
            title: sub.title.title,
            subtitle: sub.title.subtitle
          } as CardableItem
        });
      });

      const seriesSubscriptions = subscriptionsWithMetadata.value.filter((sub) => sub.type === 'Series') as SeriesSubscriptionWithMetadata[];
      seriesSubscriptions.forEach((sub) => {
        subsWithBook.push({
          ...sub,
          sortTitle: sub.series.name,
          item: sub.series as Series,
          cardableItem: {
            id: sub.series.id.toString(),
            title: sub.series.name
          } as CardableItem
        });
      });

      return subsWithBook.sort((a, b) => a.sortTitle.localeCompare(b.sortTitle));
    });

    return {
      helpLink,
      loading,
      seriesCoverSizes,
      subscriptionsWithBook,
      unsubscribe
    };
  }
});

</script>

<style module>
.subscription > article {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  margin-bottom: 1rem;
}

.unsubscribe {
  min-width: calc(var(--rem-cover-width) - 1rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: var(--form-border-radius);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem 1rem;
  color: var(--c-primary-blue);
  font-weight: var(--fw-bold);
}

@media screen and (max-width: 499px /*@px-vp-narrow*/) {
  .subscription > article {
    grid-template-columns: 1fr;
  }
}
</style>
