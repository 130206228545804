import { each } from './iterable';

export function absorb<T, U>(src: T, dest: U): T & U {
  const union = <T & U>dest;

  each(src, (attr, val) => {
    union[attr] = <(T & U)[Extract<keyof T, string>]>val;
  });

  return union;
}

/**
 * Clone object using JSON functions
 * WARNING: Will not work with the following scenarios:
 *  - Date objects or objects containing date objects
 *  - Objects containing functions as values
 * @param src Source object to be cloned
 */
export function jsonClone<T>(src: T): T {
  if (!src) {
    return <T>{};
  }

  return JSON.parse(JSON.stringify(src));
}

// TODO: Consider implementing recursive deep cloning, as it's more performant
// https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/44612374#44612374
