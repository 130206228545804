import i18n from 'app/i18n/i18n';
import { RouteName } from 'app/router/constants';
import { RouteLocation } from 'vue-router';

export type Breadcrumb = {
  label: string;
  name: string;
  fullpath?: string;
  singleDisplay?: boolean;
};

export function breadcrumbTrail(from: RouteLocation, to?: RouteLocation) {
  const fromBreadcrumb = from.meta.breadcrumb as Breadcrumb[];

  const appendBreadcrumb = (breadcrumb: Breadcrumb) => {
    const trail = fromBreadcrumb?.slice() || [];
    trail.push(breadcrumb);

    return trail;
  };

  const createLabel = () => {
    //pageTitles are from List pages
    const label = from.meta.pageTitle ? from.meta.pageTitle as string : from.name === RouteName.Browse ? i18n.t('breadcrumbs.browse') : from.meta.title;

    return label;
  };

  const sliceBreadcrumb = () => {
    const last = (fromBreadcrumb.length || 1) - 1;
    //if where you are going to is listed in the breadcrumbs, remove it
    if (last && fromBreadcrumb[last].fullpath === to?.fullPath) {
      //reset this so it doesn't carry over later
      from.meta.goneBack = false;

      return fromBreadcrumb.slice(0, last);
    }
  };

  return {
    appendBreadcrumb,
    createLabel,
    sliceBreadcrumb
  };
}
