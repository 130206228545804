<template>
  <div>
    <div
      :class="[
        $style.wrapper,
        focused ? $style.focus : '',
        active ? $style.active : '',
        shownError ? $style.error : ''
      ]"
      @click="focus"
    >
      <label
        :class="$style.label"
        :for="id"
      >
        {{ label }}
      </label>

      <input
        :id="id"
        ref="input"
        v-model.trim="internalInput"
        :type="fieldType"
        :autocomplete="autocomplete"
        :spellcheck="false"
        autocorrect="off"
        autocapitalize="off"
        :aria-invalid="!!shownError"
        :autofocus="autofocus"
        :required="required"
        :aria-required="required"
        :aria-describedby="describedBy"
        :class="$style.input"
        :name="formName"
        @focus="focused = true"
        @blur="focused = false"
        @invalid.prevent="onInvalid"
      />

      <label
        v-if="type === 'password'"
        :class="$style.showPassword"
      >
        <Icon
          :key="showPassword"
          :name="showPassword ? 'eye-closed' : 'eye-open'"
        />

        <input
          v-if="type === 'password'"
          v-model="showPassword"
          type="checkbox"
          aria-hidden="true"
          hidden="true"
        />
      </label>
    </div>

    <FormError
      v-if="shownError"
      :class="$style.error"
      :contents="shownError"
    />
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, ref } from 'vue';
import { generateUUID } from 'lib/common';
import FormError from 'app/components/FormError.vue';
import { useI18n } from 'app/functions/use-i18n';

export default defineComponent({
  name: 'FormInput',
  components: {
    FormError
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    type: {
      type: String as () => 'username' | 'password' | 'text' | 'email',
      required: true
    },
    label: {
      type: String,
      required: true
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    describedBy: {
      type: String,
      default: undefined
    },
    error: {
      type: String,
      default: undefined
    },
    formName: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const showPassword = ref(false);
    const validationError = ref('');
    const shownError = computed(() => props.error || validationError.value);

    const fieldType = computed(() => {
      if (showPassword.value) {
        return 'text';
      }

      switch (props.type) {
        case 'password':
          return 'password';

        case 'email':
          return 'email';

        case 'username':
        case 'text':
        default:
          return 'text';
      }
    });

    const autocomplete = computed(() => {
      switch (props.type) {
        case 'username':
          return 'username';

        case 'password':
          return 'current-password';

        case 'email':
          return 'email';

        case 'text':
        default:
          return 'off';
      }
    });

    const input = ref<HTMLInputElement | null>(null);

    const focused = ref(false);
    const active = computed(() => focused.value || !!props.modelValue);

    const id = generateUUID();

    const internalInput = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    const { t } = useI18n();
    const onInvalid = () => {
      validationError.value = fieldType.value === 'email'
        ? t('form.error.invalidEmail')
        : t('form.error.missing', { name: props.label });
    };

    // Public
    const focus = () => input.value?.focus();

    return {
      active,
      autocomplete,
      fieldType,
      focus,
      focused,
      id,
      input,
      internalInput,
      onInvalid,
      shownError,
      showPassword
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.wrapper {
  position: relative;
  background-color: @c-light-gray;
  padding: 1rem;
  border: 1px solid @c-light-gray;
  border-radius: @px-border-radius;
  box-sizing: border-box;
  width: 100%;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  cursor: text;

  &.active {
    border-color: @c-light-gray;
    padding: 1.5rem 1rem 0.5rem;

    .label {
      top: 0.25rem;
      left: 1rem;
      font-size: @fs-mini;
      color: @c-light-black;
    }
  }

  &.focus {
    box-shadow: @shadow-focus;
    background-color: transparent;
  }

  &.error {
    border-color: @c-error;

    .label {
      color: @c-error;
    }
  }
}

.label {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: @fs-med;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  line-height: normal;
}

.input {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: @fs-med;
  width: 100%;

  /* Firefox adds a red box-shadow on :invalid inputs */
  box-shadow: none;
}

.input[type="password"] {
  width: calc(100% - 2rem);
}

.error {
  font-size: @fs-mini;
  margin-top: 0.5rem;
}

.show-password {
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;

  svg {
    fill: @c-light-black;
    width: 100%;
    height: 100%;
  }
}
</style>
