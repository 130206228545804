export interface VersionNumber {
  major: number;
  minor: number;
  patch: number;
  pre?: string;
  meta?: string;
}

export class Version {
  public value?: string;


  public assign(versionString?: string): boolean {
    this.value = versionString;

    const p = this._versionParts(versionString);

    return !(p.major === 0 && p.minor === 0 && p.patch === 0);
  }


  public isEqual(otherVersion: Version): boolean {
    return (
      !!otherVersion &&
      this.compareVersionStrings(otherVersion.value, this.value) === 0
    );
  }


  public isOlderThan(otherVersionString: string): boolean {
    return (
      !!otherVersionString &&
      this.compareVersionStrings(otherVersionString, this.value) > 0
    );
  }


  public isNewerThan(otherVersionString: string): boolean {
    return (
      !!otherVersionString &&
      this.compareVersionStrings(this.value, otherVersionString) > 0
    );
  }


  /**
   * Compares two versions, useful for sorting oldest => newest.
   *
   * @param verA The first version
   * @param verB The second version
   *
   * @returns `-1` if `verA` is older than `verB`, `1` if `verA` is newer than `verB`, `0` if they're equal.
   */
  public compareVersionStrings(verA: string | undefined, verB: string | undefined): number {
    const partsA = this._versionParts(verA);
    const partsB = this._versionParts(verB);
    // If A is a prerelease,
    // but B isn't,
    // then B is always newer.
    if (partsA.pre && !partsB.pre) {
      return -1;
    }
    // If A is not a prerelease,
    // but B is,
    // then B is always older.
    if (!partsA.pre && partsB.pre) {
      return 1;
    }
    if (partsA.major !== partsB.major) {
      return partsA.major - partsB.major;
    }
    if (partsA.minor !== partsB.minor) {
      return partsA.minor - partsB.minor;
    }
    if (partsA.patch !== partsB.patch) {
      return partsA.patch - partsB.patch;
    }
    // If A and B are both prerelease,
    // and all else is equal,
    // then B is newer.
    if (partsA.pre !== partsB.pre) {
      return -1;
    }

    return 0;
  }


  public toJSON() {
    return this.value;
  }


  public toString() {
    return this.value;
  }


  protected _versionParts(versionString: string | undefined): VersionNumber {
    if (!versionString) {
      return {
        major: 0,
        minor: 0,
        patch: 0
      };
    }
    const match = versionString.match(/^(\d+)\.(\d+)\.(\d+)(\-(.+))?$/);

    if (match) {
      return {
        major: parseInt(match[1], 10),
        minor: parseInt(match[2], 10),
        patch: parseInt(match[3], 10),
        pre: match[5]
      };
    }

    console.warn('Invalid version string:', versionString);

    return {
      major: 0,
      minor: 0,
      patch: 0
    };
  }
}
