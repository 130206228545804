import { C } from 'app/base/common';
import Events from 'app/events/events';
import { Version } from '../updates/version';
import { Shell, ShellInfo } from './shell';
import { ShellNative } from './shell-native';
import { ShellNone } from './shell-none';

export class ShellChooser {
  private readonly _shells = {
    None: ShellNone,
    Native: ShellNative
  };


  public select(): Shell {
    let ua = navigator.userAgent;
    if (window.BRIDGE && window.BRIDGE.userAgent) {
      if (C.isString(window.BRIDGE.userAgent)) {
        ua = window.BRIDGE.userAgent || ua;
      } else {
        ua = window.BRIDGE.userAgent() || ua;
      }
    }
    const info = this.parseUA(ua);
    if (info && info.spec && info.spec >= 2) {
      return new this._shells.Native(info);
    }

    return new this._shells.None();
  }


  public parseUA(ua: string): ShellInfo | null {
    const match = ua.match(/\((\w+; V\d+; \w+; .*?)\)/);
    if (match) {
      const parts = match[1].split('; ');
      const info: ShellInfo = {
        name: parts.shift(),
        spec: this.specNumber(parts.shift()),
        platform: parts.shift(),
        version: new Version(),
        flavor: ''
      };
      info.version!.assign(parts.shift());
      info.flavor = parts.shift() || 'RELEASE';

      Events.dispatch('platform:info', info);

      return info;
    }

    return null;
  }


  public specNumber(spec: string | undefined): number | undefined {
    const match = (spec || '').match(/^V(\d+)/);

    return match ? parseFloat(match[1]) : undefined;
  }
}
