import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(_ctx.$style.content)
  }, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('annotationCopying.prompt.copy.intro', { TITLE: _ctx.targetName })), 1),
    _createElementVNode("p", { innerHTML: _ctx.copyQuestion }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style.additional)
    }, _toDisplayString(_ctx.$t('annotationCopying.prompt.copy.additional')), 3)
  ], 10, _hoisted_1))
}