<template>
  <button
    v-bind="$attrs"
    ref="actions"
    :class="{
      [buttonClass]: true,
      [$style.disabled]: disabled
    }"
    :aria-label="attrs.type === 'export-queue' ? $t('exportQueue.contextMenu.header') : $t('annotations.contextMenu.header')"
    aria-haspopup="dialog"
    :aria-disabled="disabled"
    @click="disabled ? undefined : showContextMenu = true"
  >
    <slot name="buttonContent">
      <Icon name="overflow" />
    </slot>
  </button>

  <template v-if="attrs.type === 'export-queue'">
    <ExportQueueContextMenu
      v-if="showContextMenu && actions"
      :reference="actions"
      :annotations="annotations"
      :exportOptions="attrs.exportOptions"
      :showCount="attrs.showCount"
      @close="showContextMenu = false"
      @clear="$emit('selected:clear')"
      @dispatch:toast="(actionToast) => dispatchToast(actionToast.status, actionToast.message, actionToast.action)"
    />
  </template>

  <template v-else>
    <AnnotationDetailsContextMenu
      v-if="showContextMenu && actions"
      :reference="actions"
      :annotations="annotations"
      :showCount="attrs.showCount"
      :showSearchOption="attrs.showSearchOption"
      @close="showContextMenu = false"
      @copied="$emit('selected:clear')"
      @exported="$emit('selected:clear')"
      @search="onSearch"
    />
  </template>

  <SearchThisTitleSidebar
    v-if="showStT"
    :item="annotations[0].parentTitleRecord!"
    :params="searchParams!"
    :skipRouting="true"
    @close="closeSearch"
  />

  <ActionToast
    v-if="showActionToast"
    :message="actionMessage"
    type="success"
    @close="closeActionToast"
  >
    <template #action>
      <button @click="removeFromQueue">
        {{ $t('exportQueue.contextMenu.remove.afterExport', { N: annotations.length }) }}
      </button>
    </template>
  </ActionToast>
</template>

<script lang="ts">
import { SearchThisTitleQuery } from 'app/base/hudson';
import ActionToast from 'app/components/ActionToast.vue';
import SearchThisTitleSidebar from 'app/components/SearchThisTitleSidebar.vue';
import AnnotationDetailsContextMenu from 'app/components/contextMenus/AnnotationDetailsContextMenu.vue';
import ExportQueueContextMenu from 'app/components/contextMenus/ExportQueueContextMenu.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { useI18n } from 'app/functions/use-i18n';
import { PropType, defineComponent, nextTick, ref } from 'vue';

type AnnotationContextMenuAttrs = {
} & {
  type: 'export-queue';
  exportOptions: boolean;
  showCount: boolean;
} | {
  type: 'annotation-details';
  showCount: boolean;
  showSearchOption: boolean;
};

export default defineComponent({
  components: {
    ActionToast,
    AnnotationDetailsContextMenu,
    ExportQueueContextMenu,
    SearchThisTitleSidebar
},
  props: {
    annotations: {
      type: Object as PropType<SelectableAnnotation[]>,
      required: true
    },
    attrs: {
      type: Object as PropType<AnnotationContextMenuAttrs>,
      required: true
    },
    buttonClass: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'selected:clear'
  ],
  setup: (props, ctx) => {
    const { dispatch } = useAppEvents();
    const { t } = useI18n();

    const showContextMenu = ref(false);
    const showStT = ref(false);
    const actions = ref<HTMLElement | null>(null);
    const searchParams = ref<SearchThisTitleQuery | null>(null);

    const onSearch = () => {
      // StT option only available for single annotation context menus,
      // so annotations[0] is the only possible value
      searchParams.value = {
        query: props.annotations[0].quote,
        type: 'keyword',
        scope: 'title'
      } as SearchThisTitleQuery;

      showStT.value = true;
    };

    const closeSearch = async () => {
      searchParams.value = null;
      showStT.value = false;

      await nextTick();
      actions.value?.focus();
    };

    const showActionToast = ref(false);
    const actionMessage = ref('');

    const dispatchToast = async (type: 'success' | 'error' | 'info', message: string, actionable = false) => {
      showContextMenu.value = false;
      await nextTick();

      if (actionable) {
        showActionToast.value = true;
        actionMessage.value = message;
      } else {
        dispatch('toast', {
          type: type,
          message: message
        });
      }
    };

    const closeActionToast = async () => {
      ctx.emit('selected:clear');
      showActionToast.value = false;
      actionMessage.value = '';

      await nextTick();
      actions.value?.focus();
    };

    const removeFromQueue = async () => {
      closeActionToast();

      dispatch('export-queue:remove', props.annotations.map((a) => a.uuid));

      dispatchToast('success', t('exportQueue.contextMenu.remove.success', { N: props.annotations.length }));
    };

    return {
      actions,
      actionMessage,
      searchParams,
      showActionToast,
      showContextMenu,
      showStT,
      closeActionToast,
      closeSearch,
      dispatchToast,
      onSearch,
      removeFromQueue
    };
  }
});
</script>

<style module>
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
