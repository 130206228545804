import { APP } from 'app/base/app';
import { Borrower } from 'app/base/borrower';
import { C } from 'app/base/common';
import { DervishResponse, DervishURLs } from 'app/base/interfaces';
import { Orientation } from '../base/orient';
import { Loan } from '../models/loan';
import { TitleRecord } from '../models/title';


export type SeekLocation = {
  type: 'path' | 'query' | 'highlight';
  location: string;
};


export class OpenController {
  public static READY_DELAY_MS = 300;

  protected title: TitleRecord;
  protected loan: Loan;
  protected anchorID: string | undefined;
  protected parentID: string | undefined;
  protected seekTo: SeekLocation | undefined;

  private _isEntered: boolean;
  private _timer: number;


  public enter(titleSlug: string, anchorID?: string, parentID?: string, seekTo?: SeekLocation): boolean {
    console.log(`[OPEN-CONTROLLER].enter(${titleSlug}, ${anchorID}, ${parentID})`);

    // Make sure the correct title is active
    if (!APP.activeTitle.isActiveTitle(titleSlug)) {
      APP.activeTitle.deactivate(true);
    }

    APP.activeTitle.activate(titleSlug, anchorID, parentID);

    if (APP.activeTitle.isActive()) {
      // Make sure the title is accessible
      // Bundled children are an exception
      if (APP.activeTitle.title && APP.activeTitle.title.circ &&
        !APP.activeTitle.title.isBundledChild &&
        !APP.activeTitle.title.circ.available &&
        !APP.activeTitle.title.circ.holdable) {
          this._handleFallback();

          return false;
      }

      this._isEntered = true;
      this.anchorID = anchorID;
      this.parentID = parentID;
      this.title = APP.activeTitle.title;
      this.loan = APP.patron.loans.find({ titleSlug: titleSlug });
      this.seekTo = seekTo;

      return true;
    }

    this._handleFallback();

    return false;
  }


  public fill(): void {
    if (!this.title) { return; }

    APP.events.on('msg:bifocal:ready', (evt) => this._onBifocalReady(evt.m));
    APP.events.on('msg:bifocal:idle', () => this._onBifocalIdle());
    APP.events.on('msg:bifocal:amreading', () => this._onReading());
    APP.events.on('msg:bifocal:amnotreading', (evt) => this._onNotReading());
    APP.events.on('msg:bifocal:view:failure', (evt) => this._onViewFailure(evt.m));
    if (this._isBifocalOpenAndReady()) {
      APP.semaphore.set('book', 'reopening');
      this._onBifocalReady();
    } else {
      APP.semaphore.set('book', 'opening');
      APP.shell.bifocal.isReady = false;
      APP.shell.bifocal.orientation = null;
      this._openTitle(
        this.title,
        this.loan ? this.loan.downloader.cachedURLs() : null
      );
    }

    this._seekToLocation();
  }


  public focus(): void {
    if (!this.title) { return; }

    const currentCard = APP.patron.currentCard();
    APP.events.dispatch('title:open', {
      cardId: this.loan ? currentCard.cardId : null,
      slug: this.title.slug,
      isBundledChild: this.title.isBundledChild,
      parent: this.title.lexisMetadata?.parent ? this.title.lexisMetadata.parent : this.parentID,
      loan: this.loan
    });

    APP.events.dispatch('bifocal:downloads:pause');
  }


  public blur(): void {
    APP.semaphore.clear('book');
    APP.shell.bifocal.conceal();
    APP.orient.update();
    APP.shell.immerse(false);
  }


  public exit(): void {
    this._isEntered = false;

    if (this.title) {
      APP.events.dispatch('title:close', {
        cardId: this.loan ? APP.patron.currentCard().cardId : null,
        slug: this.title.slug,
        loan: this.loan
      });
    }

    APP.events.dispatch('bifocal:downloads:resume');

    clearTimeout(this._timer);
    this.title = null;
    this.loan = null;
  }


  protected async _openTitle(title: TitleRecord, dervishData: DervishResponse): Promise<void> {
    if (!this._isActiveTitle(title.slug)) {
      console.warn(`[OPEN-CONTROLLER] Aborting open of ${title.title}(${title.slug}) because no longer active title`);

      return;
    }

    if (dervishData && dervishData.urls && dervishData.urls.web) {
      console.log(`[OPEN-CONTROLLER] opening title... ${title.title} (${title.slug}) [anchor:${this.anchorID}]`);
      APP.shell.bifocal.open(title, this.loan, dervishData, this.anchorID);
      this.loan.lastAccessTime = C.epochMilliseconds();
      APP.events.dispatch('loan:accessed');
    } else {
      console.log(`[OPEN-CONTROLLER] fetching Dervish URLs... ${title.title} (${title.slug})`);
      try {
        this.loan = await Borrower.borrowIfPossible(title.slug, this.parentID);
        const loanTitle = APP.titleCache.get(title.slug) || this.loan.titleRecord;

        if (!this.loan) {
          throw new Error(`Could not borrow ${title.slug} or its parent, cancelling open.`);
        }

        if (await loanTitle.isOutOfDate()) {
          APP.activeTitle.isNewRelease = true;
          throw new Error(`Title ${title.slug} is out of date and needs to be updated.`);
        }

        if (this.loan) {
          const autoDown = APP.updateManager.autoDownloadForLoan(this.loan);
          if (autoDown) {
            this.loan.downloader.setAutoDownload(autoDown);
          }
        }

        const dervishUrls = await APP.sentry.fetchDervishURLs(
          title.slug,
          loanTitle.mediaType,
          loanTitle.isLexisPublished ? true : false,
          loanTitle.isLexisPublished ? true : false,
          APP.library.disableNotes,
          undefined,
          loanTitle.lexisMetadata?.parent,
          this.anchorID
        );

        if (!dervishUrls) {
          // If the validation in Sentry failed because it doesn't think the the title is a valid loan or
          // that the title was a valid bundled child of the loan, it will return a 404 with no response.
          // We are currently not throwing an exception for 404s (see server.ts), but want to treat it as
          // one in this case.  (Without this, we get stuck in an infinite loop trying to open the book)
          throw new Error(`Error fetching URLs for title: ${title.slug} (parent: ${this.parentID})`);
        }

        this._openTitle(loanTitle, dervishUrls);
        if (this.loan && this.loan.titleRecord.slug === title.slug) {
          this.loan.downloader.assignURLs(dervishUrls);
        }
      } catch (ex) {
        const reason = 'error fetching urls';
        const errObj = {
          info: {
            result: 'unauthorized'
          }
        };
        this._schedule(this._titleOpenFailure.bind(this, reason, errObj), 200);
      }
    }
  }


  protected _onBifocalReady(evt?: { orientation: Orientation}): void {
    APP.shell.bifocal.isReady = true;
    if (evt) {
      APP.shell.bifocal.orientation = evt.orientation;
    }
    APP.orient.update(APP.shell.bifocal.orientation);
    // Tell bifocal we are starting the revealing process so it can initiate any
    // work that needs to be done before the user interacts with it.
    APP.shell.bifocal.transmit({
      name: 'bifocal:revealing'
    });
    this._schedule(this._revealBifocal.bind(this), OpenController.READY_DELAY_MS);
  }


  protected _onBifocalIdle(): void {
    console.log('[OPEN-CONTROLLER] Bifocal is idle');
    if (this.loan && this.loan.isDownloaded()) {
      this.loan.downloader.check({
        onChecked: this._onBifocalUpdateCheck.bind(this)
      });
    }
  }


  protected _onBifocalUpdateCheck(loan: Loan, urls: DervishURLs, downloading: boolean): void {
    if (downloading && this._isActiveTitle(loan.slug)) {
      console.log('[OPEN-CONTROLLER] Bifocal has been updated, but not reloading.');
      // console.log('[OPEN-CONTROLLER] Bifocal has been updated, reloading...');
      // APP.shell.bifocal.reload();
    }
  }


  protected _revealBifocal(): void {
    if (APP.shell.bifocal.isReady && this._isEntered) {
      console.log(`[OPEN-CONTROLLER] revealing Bifocal...${this.title.title}(${this.title.slug})`);

      APP.semaphore.set('book', 'ready');
      APP.events.dispatch('bifocal:reveal');
      setTimeout(() => APP.shell.immerse(true), 300);
      APP.shell.bifocal.reveal();
      APP.activeTitle.clearAnchor();
    } else {
      console.warn('[OPEN-CONTROLLER] aborting Bifocal reveal (unready)', this.title.title);
      this.blur();
      APP.nav.back();
    }
  }


  /**
   * Determine if the requested title is the active title
   * @param titleSlug The slug of the title to check
   */
  protected _isActiveTitle(titleSlug: string): boolean {
    return (this.title && this.title.slug === titleSlug);
  }


  protected _isBifocalOpenAndReady(): boolean {
    return (
      this._isEntered &&
      APP.shell.bifocal.isReady &&
      APP.shell.bifocal.isOpen(this.title.slug, this.loan)
    );
  }


  protected _onReading(): void {
    APP.shell.immerse(true);
  }


  protected _onNotReading(): void {
    APP.shell.immerse(false);
  }


  protected _titleOpenFailure(reason: string, errorObject?): void {
    if (!this._isEntered) {
      return;
    }
    console.warn('[OPEN-CONTROLLER] FAILURE %s:', reason, this.title, errorObject);
    const errorResult = errorObject?.info?.result;
    const errorCode = errorObject?.info?.response?.errorCode;

    if (errorResult === 'unauthorized') {
      // TODO: Handle unauthorized title open
    } else if (errorCode === 'PatronDoesNotHaveTitleCheckedOut') {
      if (this.loan) {
        APP.patron.loans.removeItem(this.loan);
        APP.patron.syncCoordinator.remoteSync();
      }
    }

    this._handleFallback();
  }


  protected _handleFallback(): void {
    this.blur();

    if (!this._isEntered) { return; }

    if (this.parentID) {
      const parent = APP.titleCache.get(this.parentID);
      if (parent) {
        console.log('Parent title exists for failed open attempt. Navigating to parent title');
        APP.nav.go(parent.path());

        return;
      }
    }

    if (this.title) {
      console.log('Title exists for failed open attempt. Navigating to title');
      APP.nav.go(this.title.path());

      return;
    }

    APP.nav.back();
  }


  protected _onViewFailure(evt: { errorObject?: any }): void {
    if (this.loan) {
      this.loan.downloader.check();
    }
    this.blur();
    this._titleOpenFailure('bifocal view failure', evt.errorObject);
  }


  protected _schedule(callback, delay = 0): void {
    clearTimeout(this._timer);
    this._timer = window.setTimeout(callback, delay);
  }


  protected _seekToLocation(): void {
    if (!this.seekTo) { return; }

    switch (this.seekTo.type) {
      case 'path':
        APP.shell.bifocal.seekToPath(this.title.slug, this.loan, this.seekTo.location);

        return;
      case 'query':
        APP.shell.bifocal.seekToQuery(this.title.slug, this.loan, this.seekTo.location);

        return;
      case 'highlight':
        APP.shell.bifocal.seekToHighlight(this.title.slug, this.loan, this.seekTo.location);

        return;
      default:
        return;
    }
  }
}
