
import { computed, Ref, ref } from 'vue';

export function useTextFilter<T>(items: Ref<T[]>, filterFunc: (item: T, text: string) => boolean) {
  const textFilter = ref<string>('');

  const filteredItems = computed(() => items.value.filter((i) => filterFunc(i, textFilter.value)));

  return {
    textFilter,
    filteredItems
  };
};
