import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_TitleCard = _resolveComponent("TitleCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("h3", {
      class: _normalizeClass(_ctx.$style.type)
    }, _toDisplayString(_ctx.$t(_ctx.typeName)), 3),
    (_ctx.type === 'SeriesNewRelease')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.text)
        }, _toDisplayString(_ctx.$t('alert.details.seriesUpdated', { series: _ctx.name })), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.cardList)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.titles, (title) => {
        return (_openBlock(), _createBlock(_component_TitleCard, {
          key: title.slug,
          class: _normalizeClass(_ctx.$style.card),
          title: title
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_TitleActionButton, {
              class: _normalizeClass(_ctx.$style.alertAction),
              title: title,
              focus: true
            }, null, 8, ["class", "title"])
          ]),
          _: 2
        }, 1032, ["class", "title"]))
      }), 128))
    ], 2)
  ], 2))
}