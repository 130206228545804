import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_BrowseGroupCollection = _resolveComponent("BrowseGroupCollection")!
  const _component_BrowseGroupSubjects = _resolveComponent("BrowseGroupSubjects")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_Page, {
        header: _ctx.library.name,
        announceHeader: _ctx.$t('browse.ariaHeader', { library: _ctx.library?.name }),
        useBackToTop: true,
        class: _normalizeClass(_ctx.$style.page)
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                name: "spinner",
                class: _normalizeClass(_ctx.$style.loading),
                "aria-label": _ctx.$t('general.loading')
              }, null, 8, ["class", "aria-label"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showableCollections, (collection) => {
                  return (_openBlock(), _createBlock(_component_BrowseGroupCollection, {
                    key: collection.id,
                    class: _normalizeClass(_ctx.$style.group),
                    collectionId: collection.id,
                    name: collection.name,
                    description: collection.description,
                    searchType: collection.searchType,
                    definition: collection.definition
                  }, null, 8, ["class", "collectionId", "name", "description", "searchType", "definition"]))
                }), 128)),
                _createVNode(_component_BrowseGroupSubjects, {
                  subjects: _ctx.showableSubjects,
                  class: _normalizeClass(_ctx.$style.group)
                }, null, 8, ["subjects", "class"]),
                _createElementVNode("section", {
                  class: _normalizeClass(_ctx.$style.group)
                }, [
                  _createVNode(_component_router_link, {
                    class: _normalizeClass(_ctx.$style.button),
                    to: _ctx.fullCollectionNavigateLink
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('browse.fullCollection')), 1)
                    ], undefined, true),
                    _: 1
                  }, 8, ["class", "to"])
                ], 2)
              ]))
        ], undefined, true),
        _: 1
      }, 8, ["header", "announceHeader", "class"])
    ], undefined, true),
    _: 1
  }))
}