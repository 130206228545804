<template>
  <div
    :class="{
      [$style.exportQueueGroup]: true,
      [$style.expanded]: expanded
    }"
  >
    <div
      :class="{
        [$style.header]: true,
        [$style.selected]: selectionState !== 'off'
      }"
    >
      <div
        :class="$style.select"
        :aria-labelledby="labelIds.select"
      >
        <FormCheckbox
          :modelValue="selectionState"
          :showLabel="false"
          :label="$t('exportQueue.selectTitle', { title: fullTitle })"
          :ariaLabel="$t('exportQueue.selectTitle', { title: fullTitle })"
          :class="$style.checkbox"
          @update:modelValue="updateAllAnnotations"
        />
      </div>
      <div
        :class="[$style.expander, $style.centered]"
        :aria-labelledby="labelIds.expand"
      >
        <button
          ref="expansionButton"
          type="button"
          :aria-label="$t('exportQueue.expandTitle', { title: fullTitle })"
          :aria-expanded="expanded"
          :aria-controls="annotationPanelId"
          @click="toggleExpansion"
        >
          <Icon
            name="chevron-down"
          />
        </button>
      </div>
      <div
        :class="$style.title"
        :aria-labelledby="labelIds.title"
      >
        <div>
          {{ title }}
        </div>
        <div
          v-if="subtitle"
          :class="$style.subtitle"
        >
          {{ subtitle }}
        </div>
      </div>
      <div
        :class="[$style.count, $style.centered]"
        :aria-labelledby="labelIds.count"
      >
        {{ annotations.length }}
      </div>
      <div
        :class="[$style.actions, $style.centered]"
        :aria-labelledby="labelIds.actions"
      >
        <TitleActionsContextMenuButton
          :title="groupTitle"
          :hideLabel="true"
          :includeCopying="false"
          :darkStyle="false"
        />
      </div>
    </div>

    <template v-if="expanded">
      <div :class="$style.groupIndicatorContainer">
        <span :class="$style.groupIndicator"></span>
      </div>
      <ExportQueueTable
        :id="annotationPanelId"
        :class="$style.annotations"
        :annotations="annotations"
        :hideNote="hideNote"
        :title="fullTitle"
        @annotations:update="(checked, uuid) => updateAnnotation(checked, uuid)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import ExportQueueTable from 'app/components/ExportQueueTable.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import TitleActionsContextMenuButton from 'app/components/contextMenus/TitleActionsContextMenuButton.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { PropType, computed, defineComponent, nextTick, ref } from 'vue';

export type ExportQueueGroupLabelIds = {
  select: string;
  expand: string;
  title: string;
  count: string;
  actions: string;
};

export default defineComponent({
  components: {
    ExportQueueTable,
    FormCheckbox,
    TitleActionsContextMenuButton
  },
  props: {
    titleId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    labelIds: {
      type: Object as PropType<ExportQueueGroupLabelIds>,
      required: true
    },
    subtitle: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'annotations:update',
    'toggle:expansion'
  ],
  setup: (props, ctx) => {
    const groupTitle = APP.library.titles.fetch(props.titleId);
    const fullTitle = (`${props.title} ${props.subtitle || ''}`).trim();
    const allIds = computed<string[]>(() => props.annotations.map((annotation) => annotation.uuid));
    const selectionState = computed<FormCheckboxState>(() => {
      const selected = props.annotations.filter((a) => a.selected);

      return selected.length === 0
        ? 'off'
        : selected.length === props.annotations.length
          ? 'on'
          : 'partial';
    });

    const annotationPanelId = `annotation-panel-${props.titleId}`;

    const expansionButton = ref<HTMLElement | null>(null);
    const toggleExpansion = async () => {
      ctx.emit('toggle:expansion', !props.expanded);
      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth' });
      expansionButton.value?.focus();
    };

    const updateAnnotation = (checked: FormCheckboxState, uuid: string) => {

      ctx.emit('annotations:update', checked, [uuid]);
    };

    const updateAllAnnotations = (checked: FormCheckboxState) => {

      ctx.emit('annotations:update', checked, allIds.value);
    };

    return {
      selectionState,
      annotationPanelId,
      expansionButton,
      fullTitle,
      groupTitle,
      toggleExpansion,
      updateAnnotation,
      updateAllAnnotations
    };
  }
});
</script>

<style module>
.checkbox {
  column-gap: unset;
}

.export-queue-group {
  display: grid;
  grid-template-areas: 'header';
  --grid-gap: 1rem;
  --header-columns: 50px 50px 1fr 100px 100px;
  --group-columns: 50px 1fr;
}

.export-queue-group.expanded {
  grid-template-areas:
    'header           header'
    'group-indicator  annotations';
  grid-template-columns: var(--group-columns);
  gap: var(--grid-gap);
}

.header {
  grid-area: header;
  display: grid;
  grid-template-areas:
    'select expander title count actions';
  grid-template-columns: var(--header-columns);
  gap: var(--grid-gap);
  padding: 16px 0;
}

.header.selected {
  background-color: var(--c-selected);
}

.title {
  grid-area: title;
  color: var(--c-black);
}

.title .subtitle {
  color: var(--c-light-black);
}

.select {
  grid-area: select;
  display: flex;
  justify-content: center;
}

.group-indicator-container {
  grid-area: group-indicator;
  display: flex;
  justify-content: center;
}

.group-indicator-container .group-indicator {
  display: inline-block;
  background-color: var(--c-dark-gray);
  border-radius: var(--form-border-radius);
  width: 2px;
  height: 100%;
}

.expander {
  grid-area: expander;
}

.expander button {
  width: 3ch;
  height: 3ch;
  scroll-margin: 1.5rem;
}

.expander svg {
  transition: transform 150ms ease-in-out;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: translateZ(1px) rotateX(0);
}

.export-queue-group.expanded .expander svg {
  transform: translateZ(1px) rotateX(180deg);
}

.count {
  grid-area: count;
}

.actions {
  grid-area: actions;
  margin-top: -12px;
  padding-right: 6px;
}

.actions > button > svg {
  width: 3ch;
  height: 3ch;
  fill: var(--c-light-black);
  line-height: 0;
  padding: 2px 6px;
}

.annotations {
  grid-area: annotations;
}

.annotations [data-column="highlight"],
.annotations [data-column="note"] {
  min-width: 10ch;
}

.annotations [data-column="citation"],
.annotations [data-column="release"] {
  min-width: 10ch;
  max-width: fit-content;
}

.annotations [data-column="actions"] {
  width: auto;
  text-align: center;
}

.centered {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1470px) {
  .export-queue-group {
    --header-columns: 30px 30px 1fr 100px 75px;
    --group-columns: 30px 1fr;
    --grid-gap: 0.5rem;
  }
}
</style>
