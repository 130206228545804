<template>
  <Accordion
    :sections="sections"
  >
    <template #toc="{ mobile }">
      <SeriesTableOfContents
        :series="series"
        :titleMap="volumeDictionary"
        :appendDropdownToBody="false"
        :showTitle="mobile"
      />
    </template>

    <template #subjects>
      <AccordionSubjectList :subjects="Object.values(series.subjects)" />
    </template>
  </Accordion>
</template>

<script lang='ts'>
import Accordion from 'app/components/Accordion.vue';
import AccordionSubjectList from 'app/components/AccordionSubjectList.vue';
import SeriesTableOfContents from 'app/components/SeriesTableOfContents.vue';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { Dictionary } from 'lib/common/dictionary';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SeriesDetailsAccordion',
  components: {
    AccordionSubjectList,
    SeriesTableOfContents,
    Accordion
  },
  props: {
    series: {
      type: Series,
      required: true
    },
    volumeDictionary: {
      type: Object as () => Dictionary<TitleRecord>,
      required: true
    }
  },
  setup: (props) => {
    const sections = computed(() => {
      const sectionList = [
        {
          id: 'toc',
          label: 'title.tableOfContents.header'
        }
      ];

      if (Object.keys(props.series.subjects).length) {
        sectionList.push({
          id: 'subjects',
          label: 'title.subjects'
        });
      }

      return sectionList;
    });

    return {
      sections
    };
  }
});
</script>
