import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "root" }
const _hoisted_2 = ["aria-label", "aria-describedby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.$style.shield),
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)))
    }, null, 2),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.trapFocus ? 'FocusTrap': 'div'), {
      initialFocus: _ctx.initialFocus,
      onUpdated: _ctx.update,
      onDeactivate: _ctx.closeDialog
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          ref: "content",
          class: _normalizeClass([_ctx.$style.content, _ctx.$style[_ctx.theme]]),
          role: "dialog",
          "aria-modal": "true",
          "aria-label": _ctx.headerLabel,
          "aria-describedby": _ctx.descriptionId
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 10, _hoisted_2)
      ], undefined, true),
      _: 3
    }, 40, ["initialFocus", "onUpdated", "onDeactivate"]))
  ], 512))
}