const subjects = {
  2: 'Analysis',
	3: 'Antiquarian',
	4: 'Antiques',
	5: 'Architecture',
	6: 'Art',
	7: 'Biography & Autobiography',
	8: 'Business',
	9: 'Children',
	10: 'Classic Literature',
	11: 'Urban Fiction',
	12: 'Comic and Graphic Books',
	13: 'Computer Technology',
	14: 'Cooking & Food',
	15: 'Crafts',
	16: 'Criticism',
	17: 'Current Events',
	18: 'Drama',
	19: 'Education',
	20: 'Ethics',
	21: 'Erotic Literature',
	22: 'Essays',
	23: 'Family & Relationships',
	24: 'Fantasy',
	25: 'Feminist',
	26: 'Fiction',
	27: 'Finance',
	28: 'Foreign Language Study',
	29: 'Christian Nonfiction',
	30: 'Games',
	31: 'Gardening',
	32: 'LGBTQIA+ (Nonfiction)',
	33: 'Gender Studies',
	34: 'Grammar & Language Usage',
	35: 'Health & Fitness',
	36: 'History',
	37: 'Home Design & Décor',
	38: 'Horror',
	39: 'Human Rights',
	40: 'Writing',
	41: 'African American Fiction',
	42: 'Judaica',
	43: 'Juvenile Fiction',
	44: 'Juvenile Literature',
	45: 'Juvenile Nonfiction',
	46: 'Language Arts',
	47: 'Chick Lit Fiction',
	48: 'Law',
	49: 'Literature',
	50: 'Literary Anthologies',
	51: 'Literary Criticism',
	52: 'Mathematics',
	53: 'Medical',
	54: 'Foreign Language Study - Arabic',
	55: 'Multi-Cultural',
	56: 'Music',
	57: 'Mystery',
	58: 'Mythology',
	59: 'Nature',
	60: 'New Age',
	61: 'Outdoor Recreation',
	62: 'Performing Arts',
	63: 'Pets',
	64: 'Philosophy',
	65: 'Photography',
	66: 'Poetry',
	67: 'Politics',
	68: 'Professional',
	69: 'Foreign Language',
	70: 'Psychiatry',
	71: 'Psychology',
	72: 'Recovery',
	73: 'Reference',
	74: 'Religion & Spirituality',
	75: 'Chick Lit Nonfiction',
	76: 'Research',
	77: 'Romance',
	78: 'Scholarly',
	79: 'Science',
	80: 'Science Fiction',
	81: 'Self-Improvement',
	82: 'Short Stories',
	83: 'Sociology',
	84: 'Sports & Recreations',
	85: 'Study Aids & Workbooks',
	86: 'Suspense',
	87: 'Technology',
	88: 'Text Book',
	89: 'Transportation',
	90: 'Travel',
	91: 'Travel Literature',
	92: 'True Crime',
	93: 'Genealogy',
	94: 'Beginning Reader',
	95: 'Western',
	96: 'Women\u2019s Studies',
	97: 'Christian Fiction',
	98: 'Science Fiction & Fantasy',
	99: 'Military',
	100: 'Thriller',
	101: 'Management',
	102: 'African American Nonfiction',
	103: 'Crime',
	104: 'Psychiatry & Psychology',
	105: 'Folklore',
	106: 'Engineering',
	107: 'Journalism',
	108: 'Careers',
	109: 'Chemistry',
	110: 'Physics',
	111: 'Nonfiction',
	112: 'Picture Book Fiction',
	113: 'Entertainment',
	114: 'Media Studies',
	115: 'Historical Fiction',
	116: 'Geography',
	117: 'Self Help',
	118: 'Inspirational',
	119: 'Biology',
	120: 'Report',
	121: 'Music-Sheet',
	122: 'Songbook',
	123: 'Humor (Fiction)',
	124: 'Humor (Nonfiction)',
	125: 'Non-English Fiction',
	126: 'Non-English Nonfiction',
	127: 'Young Adult Fiction',
	128: 'Young Adult Nonfiction',
	129: 'Young Adult Literature',
	130: 'Foreign Language Study - Chinese',
	131: 'Foreign Language Study - English',
	132: 'Foreign Language Study - French',
	133: 'Foreign Language Study - German',
	134: 'Foreign Language Study - Italian',
	135: 'Foreign Language Study - Japanese',
	136: 'Foreign Language Study - Korean',
	137: 'Foreign Language Study - Portuguese',
	138: 'Foreign Language Study - Russian',
	139: 'Foreign Language Study - Spanish',
	140: 'Foreign Language Study - Vietnamese',
	141: 'Harry Potter',
	142: 'Marketing & Sales',
	143: 'Foreign Language Study - Latin',
	144: 'Economics',
	145: 'Picture Book Nonfiction',
	146: 'Environment',
	147: 'Social Studies',
	148: 'Comedy',
	149: 'Child Development',
	150: 'Social Media',
	151: 'Design & UI',
	152: 'Antiques & Collectibles',
	153: 'Informational',
	154: 'Communications',
	155: 'Alternative Medicine',
	156: 'Programming',
	157: 'Software',
	158: 'Data Analysis',
	159: 'Sales & Marketing',
	160: 'Web Publishing',
	161: 'Meditation',
	162: 'Ancient History',
	163: 'Self Defense',
	164: 'Foreign Language Study - Greek',
	165: 'Foreign Language Study - Thai',
	166: 'Foreign Language Study - Czech',
	167: 'Foreign Language Study - Hungarian',
	168: 'Foreign Language Study - Polish',
	169: 'Foreign Language Study - Turkish',
	170: 'Foreign Language Study - Hindi',
	171: 'Story Book Fiction',
	172: 'Electrical',
	173: 'Manufacturing',
	174: 'Mechanical',
	175: 'Publishing',
	176: 'Medieval',
	177: 'Story Book Nonfiction',
	178: 'Foreign Language Study - Ukrainian',
	179: 'Foreign Language Study - Swedish',
	180: 'Foreign Language Study - Tagalog',
	181: 'Commercial Real Estate',
	182: 'Foreign Language Study - Persian',
	183: 'Editing',
	184: 'Foreign Language Study - Hebrew',
	300: 'Alternative',
	301: 'Ambient',
	302: 'Blues',
	303: 'Chamber Music',
	304: 'Children\u2019s Music',
	305: 'Choral',
	306: 'Christian',
	307: 'Classical',
	308: 'Concertos',
	309: 'Country',
	310: 'Electronica',
	311: 'Ethnic',
	312: 'Film Music',
	313: 'Folk',
	314: 'Hip-Hop',
	315: 'Holiday Music',
	316: 'Indie',
	317: 'Instrumental',
	318: 'Jazz',
	319: 'Metal',
	320: 'Musical',
	321: 'Opera & Operetta',
	322: 'Orchestral',
	323: 'Pop',
	324: 'R & B',
	325: 'Ragtime',
	326: 'Rock',
	327: 'Soundtrack',
	328: 'Vocal',
	329: 'World Music',
	350: 'Action',
	351: 'Adventure',
	352: 'Animation',
	353: 'Ballet',
	354: 'Cartoon',
	355: 'Children\u2019s Video',
	356: 'Classic Film',
	357: 'Documentary',
	358: 'Feature Film',
	359: 'Foreign Film',
	360: 'IMAX',
	361: 'Instructional',
	362: 'Martial Arts',
	363: 'Music Video',
	364: 'Short Film',
	365: 'Special Event & Stage Performance',
	366: 'Theater',
	367: 'TV Series',
	368: 'Young Adult Video',
	369: 'Ballet',
	370: 'Christian Rock',
	371: 'Comedy',
	372: 'Compilations',
	373: 'Dance',
	374: 'Gospel',
	375: 'Latin',
	376: 'New Age',
	377: 'Rap',
	378: 'Regae',
	379: 'Soul',
	380: 'Spoken Word',
	382: 'Comedy',
	383: 'Drama',
	384: 'Horror',
	385: 'Science Fiction',
	386: 'Suspense',
	388: 'Thriller',
	389: 'Travel',
	390: 'Western',
	400: 'Automotive',
	401: 'Business & Finance',
	402: 'Crafts & Hobbies',
	403: 'Current Events',
	404: 'Entertainment & Celebrity',
	405: 'Family & Parenting',
	406: 'Fashion',
	407: 'Food & Cooking',
	408: 'Health & Fitness',
	409: 'History',
	410: 'Hobbies & Crafts',
	411: 'Home & Garden',
	412: 'International',
	413: 'International Magazines (US)',
	414: 'International Titles (UK)',
	415: 'International Titles (US)',
	416: 'Kids',
	417: 'Lifestyle',
	418: 'Literature & Writing',
	419: 'Men\u2019s Interest',
	420: 'Music',
	421: 'Science & Nature',
	422: 'Spanish',
	423: 'Sports & Recreation',
	424: 'Technology & Home Entertainment',
	425: 'Travel',
	426: 'Women\u2019s Interest',
	427: 'College Newspaper',
	500: 'African-American',
	501: 'African-American',
	502: 'Chick Lit',
	503: 'Chick Lit',
	504: 'Contemporary Romance',
	505: 'Contemporary Romance',
	506: 'Erotic Fiction',
	507: 'Erotic Fiction',
	508: 'Fantasy',
	509: 'Fantasy',
	510: 'Historical Romance',
	511: 'Historical Romance',
	512: 'Heart & Home',
	513: 'Heart & Home',
	514: 'Inspirational Romance',
	515: 'Inspirational Romance',
	516: 'Mystery',
	517: 'Mystery',
	518: 'Passion',
	519: 'Passion',
	520: 'Relationship Novel',
	521: 'Relationship Novel',
	522: 'Romantic Comedy',
	523: 'Romantic Comedy',
	524: 'Spanish',
	526: 'Suspense',
	527: 'Suspense',
	528: 'Thriller',
	529: 'Thriller',
	530: 'Classic Romance',
	531: 'Classic Romance',
	532: 'Paranormal Romance',
	533: 'Paranormal Romance',
	534: 'Suspense',
	535: 'Suspense',
	536: 'Nonfiction',
	537: 'Nonfiction',
	538: 'Teen',
	539: 'Teen',
	540: 'Biography and Memoir',
	541: 'Biography and Memoir',
	542: 'Cooking',
	543: 'Cooking',
	544: 'Crafts and Hobbies',
	545: 'Crafts and Hobbies',
	546: 'Family and Parenting',
	547: 'Family and Parenting',
	548: 'Fun and Games',
	549: 'Fun and Games',
	550: 'Health',
	551: 'Health',
	552: 'Pets',
	553: 'Pets',
	554: 'Self-Help',
	555: 'Self-Help',
	556: 'Relationships',
	557: 'Relationships',
	558: 'Fashion and Style',
	559: 'Fashion and Style',
	600: 'Action Adventure',
	601: 'Comedy',
	602: 'Erotica',
	603: 'Fantasy',
	604: 'Gay/Lesbian',
	605: 'General Fiction',
	606: 'Historical',
	607: 'Horror',
	608: 'Inspirational',
	609: 'Mystery',
	610: 'Paranormal',
	611: 'Romance',
	612: 'Contemporary Romance',
	613: 'Fantasy Romance',
	614: 'Historical Romance',
	615: 'Paranormal Romance',
	616: 'Romantic Suspense',
	617: 'Science Fiction',
	618: 'Women\u2019s Fiction',
	619: 'Africa',
	620: 'Aliens',
	621: 'Alternate History',
	622: 'Alternate World Fantasy',
	623: 'Amish',
	624: 'Ancient Worlds',
	625: 'Angels & Demons',
	626: 'Antarctica',
	627: 'Australia',
	628: 'Aztec',
	629: 'BDSM',
	630: 'Celtic',
	631: 'Central America',
	632: 'Chicklit',
	633: 'Civil War',
	634: 'Colonial',
	635: 'Comedy of Manners',
	636: 'Cozy Mystery',
	637: 'Cyberpunk',
	638: 'Detective',
	639: 'Djinn',
	640: 'Dragon',
	641: 'Post Apocalyptic',
	642: 'Eastern Europe',
	643: 'Edwardian',
	644: 'England',
	645: 'Epic Fantasy',
	646: 'Erotic',
	647: 'Espionage',
	648: 'Fae',
	649: 'Family saga',
	650: 'Far East',
	651: 'Female/Female',
	652: 'First Encounter Science Fiction',
	653: 'Full-figured',
	654: 'Futuristic',
	655: 'Georgian',
	656: 'Ghosts',
	657: 'Gothic',
	658: 'Great Depression',
	659: 'Harem',
	660: 'Henlit',
	661: 'Historical Fantasy',
	662: 'Holiday',
	663: 'Incan',
	664: 'India',
	665: 'Interactive',
	666: 'Interracial',
	667: 'Jazz Age',
	668: 'Legends & Mythology',
	669: 'Male/Male',
	670: 'Marriage of Convenience',
	671: 'Mayan',
	672: 'Medical',
	673: 'Medieval',
	674: 'Ménage',
	675: 'Mermaids',
	676: 'Middle East',
	677: 'Military',
	678: 'Military Science Fiction',
	679: 'Momlit',
	680: 'Multicultural',
	681: 'Mystery Romance',
	682: 'Native American',
	683: 'Ninja',
	684: 'Paranormal Mystery',
	685: 'Paranormal Thriller',
	686: 'Pets',
	687: 'Pioneer',
	688: 'Pirates',
	689: 'Pregnant',
	690: 'Prince',
	691: 'Supernatural',
	692: 'Psychological Horror',
	693: 'Regency',
	694: 'Renaissance',
	695: 'Scottish',
	696: 'Sheik',
	697: 'Shifters',
	698: 'South America',
	699: 'Space Opera',
	700: 'Splatterpunk',
	701: 'Sports',
	702: 'Steampunk',
	703: 'Restoration',
	704: 'Tech Science Fiction',
	705: 'Time Travel',
	706: 'Travel',
	707: 'Urban Fantasy',
	708: 'Vampires',
	709: 'Victorian',
	710: 'Viking',
	711: 'Virgin',
	712: 'Wealth',
	713: 'Western',
	714: 'Western Europe',
	715: 'Witches',
	716: 'World War I',
	717: 'World War II',
	718: 'Zombies',
	719: 'Erotic Romance',
	720: 'Science Fiction Romance',
	721: 'Thriller',
	722: 'Fairy Tale',
	723: 'Ancient Egypt',
	724: 'Gladiators',
	725: 'Spy',
	726: 'Superheroes',
	727: 'Psychic',
	728: 'Epic Fantasy',
	729: 'New Adult',
	730: 'New Adult',
	731: 'New Adult',
	732: 'Romance with More',
	733: 'Wholesome',
	734: 'Home and Family',
	735: 'Noir',
	736: 'Alternate History',
	737: 'Wedding',
	738: 'Dystopian',
	739: 'Rock Star',
	740: 'Series',
	741: 'Bundles',
	742: 'Inspirational Romance',
	801: 'Free Classics',
	900: 'JURISDICTIONS',
	901: 'Alabama',
	902: 'Alaska',
	903: 'Arizona',
	904: 'Arkansas',
	905: 'California',
	906: 'Colorado',
	907: 'Connecticut',
	908: 'Delaware',
	909: 'District of Columbia',
	910: 'Federal',
	911: 'Florida',
	912: 'Georgia',
	913: 'Global Sources',
	914: 'Hawaii',
	915: 'Idaho',
	916: 'Illinois',
	917: 'Indiana',
	918: 'International',
	919: 'Iowa',
	920: 'Kansas',
	921: 'Kentucky',
	922: 'Louisiana',
	923: 'Maine',
	924: 'Maryland',
	925: 'Maryland/DC',
	926: 'Massachusetts',
	927: 'Michigan',
	928: 'Minnesota',
	929: 'Mississippi',
	930: 'Missouri',
	931: 'Montana',
	932: 'National',
	933: 'Nebraska',
	934: 'Nevada',
	935: 'New Hampshire',
	936: 'New Jersey',
	937: 'New Mexico',
	938: 'New York',
	939: 'North Carolina',
	940: 'North Dakota',
	941: 'Ohio',
	942: 'Oklahoma',
	943: 'Oregon',
	944: 'Pennsylvania',
	945: 'Puerto Rico',
	946: 'Rhode Island',
	947: 'South Carolina',
	948: 'South Dakota',
	949: 'Tennessee',
	950: 'Texas',
	951: 'Utah',
	952: 'Vermont',
	953: 'Virgin Islands',
	954: 'Virginia',
	955: 'Washington',
	956: 'West Virginia',
	957: 'Wisconsin',
	958: 'Wyoming',
	959: 'Service',
	960: 'Non-Service',
	961: 'Index',
	1000: 'PRACTICE AREAS',
	1001: 'Administrative Law',
	1002: 'Admiralty',
	1003: 'Agency & Partnership',
	1004: 'Agricultural Law',
	1005: 'Alternative Dispute Resolution',
	1006: 'Antitrust Law',
	1007: 'Appellate Advocacy',
	1008: 'Attorney\u2019s Fees',
	1009: 'Banking Law',
	1010: 'Bankruptcy',
	1011: 'Business Law',
	1012: 'Civil Practice & Procedure',
	1013: 'Civil Practice & Procedure: Federal',
	1014: 'Civil Practice & Procedure: State',
	1015: 'Civil Rights Law',
	1016: 'Commercial Law',
	1017: 'Communications Law',
	1018: 'Computer Law',
	1019: 'Conflict of Laws',
	1020: 'Constitutional Law',
	1021: 'Construction Law',
	1022: 'Contracts',
	1023: 'Copyright',
	1024: 'Corporate Law',
	1025: 'Criminal Justice',
	1026: 'Criminal Law & Procedure',
	1027: 'Education Law',
	1028: 'Elder Law',
	1029: 'Employment & Labor Law',
	1030: 'Energy',
	1031: 'Entertainment Law',
	1032: 'Environmental Law',
	1033: 'Environmental Law – Climate Issues Pamphlet Series',
	1034: 'Estate Gift & Trust Law',
	1035: 'Ethics',
	1036: 'Evidence',
	1037: 'Family Law',
	1038: 'Federal Practice',
	1039: 'Forms',
	1040: 'General Practice',
	1041: 'Government Law',
	1042: 'Health Care Law',
	1043: 'Immigration Law',
	1044: 'Insurance Law',
	1045: 'Intellectual Property Law',
	1046: 'International',
	1047: 'Jurisprudence',
	1048: 'Jury Instructions',
	1049: 'Juvenile Law',
	1050: 'Law Enforcement',
	1051: 'Law Practice Management',
	1052: 'Litigation',
	1053: 'Mediation',
	1054: 'Mental Disability',
	1055: 'Mergers & Acquisitions',
	1056: 'Military Law',
	1057: 'Motor Vehicle and Traffic Law',
	1058: 'Native American Law',
	1059: 'Patent Law',
	1060: 'Personal Injury Law/Medico-Legal',
	1061: 'Practice Management & Software',
	1062: 'Products Liability',
	1063: 'Property Law',
	1064: 'Real Estate Law',
	1065: 'Regulatory Law',
	1066: 'Remedies',
	1067: 'Securities Law',
	1068: 'Social Security Disability',
	1069: 'Statutes',
	1070: 'Tax and Accounting Law',
	1071: 'Torts',
	1072: 'Trademarks',
	1073: 'Transportation Law',
	1074: 'Trial Advocacy',
	1075: 'Uniform Commercial Code (UCC)',
	1076: 'Workers\u2019 Compensation Law',
	1077: 'Treatise',
	1078: 'Deskbook',
	1079: 'Newsletter',
	1081: 'Primary Law',
	1082: 'Table of Contents',
	1083: 'Casebook',
	1084: 'Practice Guide',
	1085: 'Reporter',
	1086: 'Legislative Service',
	1087: 'CLE',
	1088: 'Regulations',
	1089: 'Textbook',
	1090: 'Study Aid',
	1091: 'Court Rules',
	1092: 'Local content',
	1093: 'Adult',
	1094: 'Animals and Pets',
	1095: 'Art',
	1096: 'Automotive',
	1097: 'Boating & Sailing',
	1098: 'Bridal & Weddings',
	1099: 'Business & Finance',
	1100: 'Celebrity',
	1101: 'Children',
	1102: 'Comics',
	1103: 'Computer Technology',
	1104: 'Cooking & Food',
	1105: 'Culture',
	1106: 'Education',
	1107: 'Entertainment',
	1108: 'Fashion',
	1109: 'Fiction',
	1110: 'Fishing & Hunting',
	1111: 'Flying & Aviation',
	1112: 'Gaming',
	1113: 'Health',
	1114: 'History',
	1115: 'Hobbies & Crafts',
	1116: 'Home & Garden',
	1117: 'Journals',
	1118: 'Lifestyle',
	1119: 'Men\u2019s Interest',
	1120: 'Music',
	1121: 'News',
	1122: 'Parenting',
	1123: 'Photography',
	1124: 'Politics',
	1125: 'Property',
	1126: 'Religious & Spiritual',
	1127: 'Science',
	1128: 'Sports',
	1129: 'Technology',
	1130: 'Travel',
	1131: 'TV Guide',
	1132: 'Women\u2019s Interest',
	1133: 'Young Adults',
	1134: 'Canada',
	1135: 'Ontario',
	1136: 'British Columbia',
	1137: 'Alberta',
	1138: 'Québec',
	1139: 'Nova Scotia',
	1140: 'New Brunswick',
	1141: 'Manitoba',
	1142: 'Prince Edward Island',
	1143: 'Saskatchewan',
	1144: 'Newfoundland and Labrador',
	1145: 'Nunavut',
	1146: 'Yukon',
	1147: 'Northwest Territories',
	1148: 'Competition Law',
	1149: 'Human Rights Law',
	1150: 'Information Technology',
	1151: 'Natural Resources Law',
	1152: 'Wills',
	1153: 'Health Law',
	1154: 'Employment Insurance',
	1155: 'Aboriginal Law',
	1156: 'Creditors & Debtors Law',
	1157: 'Landlord and Tenant Law',
	1158: 'Limitation of Actions',
	1159: 'Municipal Law',
	1160: 'Pensions & Benefits Law',
	1161: 'Annual',
	1162: 'Loose-Leaf',
	1163: 'Celebrity & Gossip',
	1164: 'Crafts',
	1165: 'Culture & Literature',
	1166: 'Family & Parenting',
	1167: 'Fashion',
	1168: 'Food & Wine',
	1169: 'Health & Fitness',
	1170: 'Home & Garden',
	1171: 'Hunting & Fishing',
	1172: 'Kids & Teens',
	1173: 'Luxury',
	1174: 'Men\u2019s Lifestyle',
	1175: 'Movies',
	1176: 'News & Politics',
	1177: 'Photography',
	1178: 'Science',
	1179: 'Tech & Gaming',
	1180: 'Travel & Outdoor',
	1181: 'Women\u2019s Lifestyle',
	1182: 'Comics & Manga',
	1183: 'Automotive',
	1184: 'Science & Tech',
	1185: 'Sports',
	1186: 'Women',
	1187: 'Home',
	1188: 'Lifestyle',
	1189: 'Travel',
	1190: 'News',
	1191: 'Art',
	1192: 'Entertainment',
	1193: 'Men',
	1194: 'Adult',
	1195: 'Art & Architecture',
	1196: 'Boating & Aviation',
	1197: 'Business & Finance',
	1198: 'Cars & Motorcycles',
	1200: 'Romance',
	1201: 'Family Film',
	1202: 'Sports',
	1203: 'Fantasy',
	1204: 'Independent & Art House',
	1205: 'Historical',
	1206: 'War',
	1207: 'Crime',
	1208: 'Health & Fitness',
	1209: 'Biography',
	1210: 'Religion and Spirituality',
	1211: 'LGBTQ',
	1212: 'Educational',
	1213: 'Mathematics',
	1214: 'Science',
	1215: 'Social Studies',
	1216: 'History',
	1217: 'Language Arts',
	1218: 'Physical Education',
	1219: 'Culinary Arts',
	1220: 'Language Learning',
	1221: 'Music Education',
	1222: 'Visual Arts',
	1223: 'Computer Science',
	1224: 'LGBTQIA+ (Fiction)'
};

export default subjects as typeof subjects;
