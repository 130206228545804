import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("button", {
        "aria-label": _ctx.$t('general.backToTop'),
        class: _normalizeClass([_ctx.$style.scroller, _ctx.fadeScroller ? _ctx.$style.fade : '']),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollIntoView && _ctx.scrollIntoView(...args)))
      }, [
        _createVNode(_component_Icon, { name: "chevron-up" })
      ], 10, _hoisted_1), [
        [_vShow, _ctx.show]
      ])
    ], undefined, true),
    _: 1
  }))
}