import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "badge" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextFilter = _resolveComponent("TextFilter")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_Page, {
        header: _ctx.$t('tags.header'),
        useBackToTop: true
      }, _createSlots({
        default: _withCtx(() => [
          (!_ctx.hasTags)
            ? (_openBlock(), _createBlock(_component_EmptyState, {
                key: 0,
                link: _ctx.helpLink,
                header: _ctx.$t('tags.empty.header'),
                content: _ctx.$t('tags.empty.content'),
                linkLabel: _ctx.$t('tags.empty.link')
              }, null, 8, ["link", "header", "content", "linkLabel"]))
            : (_ctx.filteredTags.length > 0)
              ? (_openBlock(), _createElementBlock("ol", {
                  key: 1,
                  class: _normalizeClass(_ctx.$style.listContainer)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTags, (tag) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: tag.id,
                      class: _normalizeClass(_ctx.$style.tag)
                    }, [
                      _createVNode(_component_router_link, {
                        to: tag.route,
                        class: _normalizeClass([_ctx.$style.link, "focus-outline"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tag.name), 1)
                        ], undefined, true),
                        _: 2
                      }, 1032, ["to", "class"]),
                      _createElementVNode("p", _hoisted_1, _toDisplayString(tag.count), 1)
                    ], 2))
                  }), 128))
                ], 2))
              : (_openBlock(), _createBlock(_component_EmptyState, {
                  key: 2,
                  header: _ctx.$t('tags.filters.empty.header'),
                  content: _ctx.$t('tags.filters.empty.content')
                }, null, 8, ["header", "content"]))
        ], undefined, true),
        _: 2
      }, [
        (_ctx.hasTags)
          ? {
              name: "toolbar",
              fn: _withCtx(() => [
                _createVNode(_component_TextFilter, {
                  modelValue: _ctx.textFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textFilter) = $event)),
                  label: _ctx.$t('tags.filters.text.placeholder'),
                  class: _normalizeClass(_ctx.$style.textFilter)
                }, null, 8, ["modelValue", "label", "class"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["header"])
    ], undefined, true),
    _: 1
  }))
}