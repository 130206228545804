import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_ctx.reference)
    ? (_openBlock(), _createBlock(_component_ContextMenu, {
        key: 0,
        ref: "menu",
        reference: _ctx.reference,
        options: _ctx.contextMenuOptions,
        headerLabel: _ctx.$t('title.contextMenu.header'),
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        read: _withCtx(() => [
          _createVNode(_component_TitleActionButton, {
            class: _normalizeClass(`${_ctx.$style.titleActionButton} dark`),
            title: _ctx.title,
            "onAction:start": _ctx.onTitleActionStart,
            "onAction:finish": _ctx.onTitleActionFinish
          }, null, 8, ["class", "title", "onAction:start", "onAction:finish"])
        ]),
        _: 1
      }, 8, ["reference", "options", "headerLabel"]))
    : _createCommentVNode("", true)
}