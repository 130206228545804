<template>
  <PromptDialog
    ref="prompt"
    :heading="$t('circ.action.borrow')"
    @close="$emit('close')"
    @submit="submitAndClose"
  >
    <p
      :id="`borrow-explanation-${title.slug}`"
    >
      {{ $t('circ.explanation.borrow') }}
    </p>

    <LabeledDropdown
      v-model="selectedLendingPeriod"
      autofocus
      :options="mappedLendingPeriods"
      optionLabel="label"
      :optionKey="(v: LendingPeriod) => `${v.value}-${v.unit}`"
      :label="$t('circ.lendingPeriod.dropdownLabel')"
      :hideLabelOnSmallScreens="false"
    />

    <Card
      :item="(cardItem as CardableItem)"
      :borderless="isMobile"
      :showExpander="false"
      :class="$style.card"
    >
      <template #cover>
        <Cover
          :item="title"
        />
      </template>
    </Card>

    <p
      v-if="loansRemaining"
      :class="$style.limit"
    >
      {{ $t('circ.limit.loansRemaining', { n: loansRemaining }) }}
    </p>
    <div
      :class="$style.actions"
    >
      <FormSubmitButton
        :label="$t('circ.action.borrow')"
        :enabled="true"
        size="small"
      />

      <button
        :class="$style.cancel"
        type="button"
        @click="prompt?.closeModal()"
      >
        {{ $t('general.cancel') }}
      </button>
    </div>
  </PromptDialog>
</template>

<script lang="ts">
import Card, { CardableItem } from 'app/components/cards/Card.vue';
import Cover from 'app/components/Cover.vue';
import PromptDialog from 'app/components/dialogs/PromptDialog.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import LabeledDropdown from 'app/components/LabeledDropdown.vue';
import { useI18n } from 'app/functions/use-i18n';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { CircData, TitleRecord } from 'app/models/title';
import { computed, defineComponent, ref } from 'vue';


type LendingPeriod = {
  value: number;
  unit: string;
};

export default defineComponent({
  name: 'BorrowPrompt',
  components: {
    Card,
    Cover,
    FormSubmitButton,
    LabeledDropdown,
    PromptDialog
  },
  props: {
    title: {
      type: Object as () => TitleRecord,
      required: true
    },
    circ: {
      type: Object as () => CircData,
      required: true
    },
    loansRemaining: {
      type: Number as () => number | null,
      default: null
    },
    defaultLendingPeriod: {
      type: Object as () => LendingPeriod,
      required: true
    },
    lendingPeriods: {
      type: Array as () => LendingPeriod[],
      required: true
    }
  },
  emits: [
    'borrow',
    'close'
  ],
  setup: (props, ctx) => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);

    const lendingPeriod = ref(props.defaultLendingPeriod);
    const submitAndClose = () => {
      ctx.emit('borrow', {
        lendingPeriod: lendingPeriod.value
      });
      prompt.value?.closeModal();
    };

    const { t } = useI18n();
    const mapLendingPeriod = (period: LendingPeriod) => ({
      ...period,
      label: t('circ.lendingPeriod.option', { value: period.value.toString(), unit: period.unit })
    });

    const selectedLendingPeriod = computed({
      get: () => mapLendingPeriod(lendingPeriod.value),
      set: (val: LendingPeriod) => { lendingPeriod.value = val; }
    });

    const mappedLendingPeriods = computed(() => props.lendingPeriods.map(mapLendingPeriod));

    const cardItem = computed(() => {
      const metadata = [
        {
          label: t('title.copiesAvailable.label'),
          value: t('title.copiesAvailable.message', {
            available: props.circ.copiesAvailable.toString(),
            total: props.circ.copiesOwned.toString()
          })
        }
      ];

      if (props.title.lexisMetadata?.releaseDate) {
        metadata.unshift({
          label: t('title.releaseDate'),
          value: props.title.lexisMetadata.releaseDate
        });
      }

      return {
        id: props.title.slug,
        title: props.title.title,
        subtitle: props.title.subtitle,
        metadata
      };
    });

    const { windowWidth } = useWindowSize();

    const isMobile = computed(() => windowWidth.value < Breakpoint.VeryNarrow);

    return {
      cardItem,
      isMobile,
      mappedLendingPeriods,
      prompt,
      selectedLendingPeriod,
      submitAndClose
    };
  }
});
</script>

<style module>
.card {
  max-width: 35ch;
}

.limit {
  border-left: 0.25rem solid var(--c-dark-gray);
  padding-left: 1ch;
  color: var(--c-medium-black);
}

.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.cancel {
  text-transform: uppercase;
  color: var(--c-medium-black);
  composes: linked from global;
}

</style>
