<template>
  <dialog
    ref="dialog"
    :class="[$style.dialog, $style[side], { [$style.animationOpen]: animationOpen }]"
    :style="[styles, `--min-width: ${minWidth}px`]"
    :aria-label="heading"
    @cancel="closeIfAllowed"
    @click="closeIfAllowed"
    @keydown.esc="closeIfAllowed"
  >
    <div
      v-if="resizable"
      ref="resizer"
      :class="$style.resizer"
      @click="(e) => e.stopPropagation()"
      @mousedown="resize"
    >
    </div>
    <div
      :class="$style.contentWrapper"
      @click="(e) => e.stopPropagation()"
    >
      <div :class="$style.headerBar">
        <h2 :class="[$style.heading, headingClass]">
          {{ heading }}
          <Icon
            v-if="icon"
            :name="icon"
            :class="$style.headingIcon"
          />
        </h2>
        <slot name="extra"></slot>
        <button
          v-if="dismissible"
          :class="$style.dismiss"
          focusOnScroll
          @click="closeDialog"
        >
          {{ $t('general.close') }}
        </button>
      </div>
      <slot name="secondHeader"></slot>
      <div :class="$style.scrollable">
        <span
          ref="topOfContent"
          :class="$style.emptySpan"
        ></span>

        <slot></slot>

        <BackToTopButton
          :show="showBackToTop"
          :scrollTo="topOfContent!"
          :focusOn="focusOnScroll"
          @show="(shouldShow) => showBackToTop = shouldShow"
        />
      </div>
    </div>
  </dialog>
</template>

<script lang="ts">
import BackToTopButton from 'app/components/BackToTopButton.vue';
import { useDialog } from 'app/functions/use-dialog';
import { useResizer } from 'app/functions/use-resizer';
import { defineComponent, ref, SetupContext } from 'vue';

export default defineComponent({
  components: {
    BackToTopButton
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    headingClass: {
      type: String,
      default: undefined
    },
    /* Controls whether the escape key and clicking the backdrop close the dialog.
     * In most cases, just let this default to 'true' for better usability.
     */
     dismissible: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: undefined
    },
    side: {
      type: String as () => 'left' | 'right',
      default: 'right'
    },
    resizable: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx: SetupContext) => {
    const topOfContent = ref<HTMLElement | null>(null);
    const showBackToTop = ref(false);
    const focusOnScroll = '[data-close_focus]';

    // Match to CSS variable
    const minWidth = 300;

    const {
      animationOpen,
      closeDialog,
      dialog,
      styles
    } = useDialog(ctx);

    const closeIfAllowed = (e: Event) => {
      e.preventDefault();

      if (props.dismissible) {
        closeDialog();
      }
    };

    const { resize } = useResizer(dialog, props.side, { minWidth: minWidth });

    return {
      animationOpen,
      dialog,
      focusOnScroll,
      minWidth,
      showBackToTop,
      styles,
      topOfContent,
      closeDialog,
      closeIfAllowed,
      resize
    };
  }
});
</script>

<style module>
.dialog {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  box-shadow: 0 2px 8px var(--c-darkest-gray);

  max-height: 100%; /* override default */
  height: 100%;
  margin: unset; /* override defaults */
  min-width: var(--min-width);
  max-width: 100%;
  width: 500px;

  display: grid;
}

.left {
  margin-right: auto;
  grid-template-areas: 'sidebar resizer';
  grid-template-columns: 1fr auto;
  --hidden: translateX(-100%);
  --visible: translateX(0);
}

.right {
  margin-left: auto;
  grid-template-areas: 'resizer sidebar';
  grid-template-columns: auto 1fr;
  --hidden: translateX(100%);
  --visible: translateX(0);
}

@media screen and (max-width: 768px /*px-vp-tablet*/) {
  .dialog {
    width: 100%;
  }

  .resizer {
    display: none;
  }
}

.dialog::backdrop {
  background-color: rgba(0.2, 0.2, 0.2, 0.2);
}

.content-wrapper {
  grid-area: sidebar;
  overflow: hidden;
  padding: 1rem;
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'secondary'
    'scrollable';
  grid-template-rows: auto auto 1fr;
  box-sizing: border-box;
}

.headerBar {
  display: grid;
  grid-template-areas: 'heading extra dismiss';
  grid-template-columns: 1fr auto auto;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  grid-area: header;
}

.heading {
  grid-area: heading;
  font-size: var(--fs-body);
  font-weight: var(--fw-bold);
  display: flex;
  align-items: center;
}

.heading-icon {
  width: 1.25rem;
  height: 1.25rem;
  padding-left: 0.25rem;
}

.dismiss {
  grid-area: dismiss;
  padding: 0.25rem;
  margin: -0.25rem;
  color: var(--c-light-black);
  composes: linked from global;
}

.scrollable {
  overflow: auto;
  margin: 0 -1rem; /* offset for scrollbar & focus outlines */
  padding: 0 1rem;
  grid-area: scrollable;
}

/* Resizer properties */
.resizer {
  position: relative;
  cursor: col-resize;
  width: 10px;
  background-color: var(--c-light-black);
  transition: background-color 100ms;
  grid-area: resizer;
}

.resizer::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleY(1);
  border-radius: 8px;
  width: 4px;
  height: 30px;
  background-color: var(--c-white);
  transition: transform 100ms, background-color 100ms;
}

.resizer:hover,
.resizer:active {
  background-color: var(--c-focus);
}

.resizer:hover::after,
.resizer:focus::after {
  background-color: var(--c-light-black);
  transform: translate(-50%, -50%) scaleY(1.5);
}

/* Animation properties */
.dialog.animation-open {
  animation: dialog-open var(--animation-duration) ease forwards;
}

.dialog {
  animation: dialog-close var(--animation-duration) ease forwards;
}

.dialog.animation-open::backdrop {
  animation: backdrop-fade-in var(--animation-duration) ease forwards;
}

.dialog::backdrop {
  animation: backdrop-fade-out var(--animation-duration) ease forwards;
}

/* Animation keyframes */

@keyframes dialog-open {
  from {
    transform: var(--hidden);
  }

  to {
    transform: var(--visible);
  }
}

@keyframes dialog-close {
  from {
    transform: var(--visible);
  }

  to {
    transform: var(--hidden);
  }
}

@keyframes backdrop-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes backdrop-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
