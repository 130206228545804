import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.form)
  }, [
    (_ctx.showCode)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('login.email.codeInstructions')), 1))
      : _createCommentVNode("", true),
    (!_ctx.showCode)
      ? (_openBlock(), _createBlock(_component_FormInput, {
          key: 1,
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          type: "email",
          required: true,
          autofocus: true,
          label: _ctx.$t('login.email.emailAddress')
        }, null, 8, ["modelValue", "label"]))
      : (_openBlock(), _createBlock(_component_FormInput, {
          key: 2,
          modelValue: _ctx.code,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.code) = $event)),
          type: "text",
          required: true,
          autofocus: true,
          label: _ctx.$t('login.email.code')
        }, null, 8, ["modelValue", "label"])),
    _createVNode(_component_FormSubmitButton, {
      label: _ctx.$t(_ctx.showCode ? 'login.form.submit' : 'login.email.continue')
    }, null, 8, ["label"])
  ], 2))
}