export function isString(str: unknown): str is string {
  return typeof str === 'string';
}

/**
 * Determine if a string is actually a number in disguise
 * @param str A string that might be a number
 * @returns True if a number; otherwise false
 */
export function isNumber(str: unknown): boolean {
  return !isNaN(Number(str));
}

export function safe(str: string): string {
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(str));

  return div.innerHTML;
}


// This is bad and you should feel bad for using it.
//
export function pluralize(str: string): string {
  return `${str}s`;
}


export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function titleize(str: string): string {
  // Articles, prepositions and conjunctions should be lower-cased
  // unless they are the first word (or last word - but this isn't handled yet).
  const lowers = [
    'a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'as', 'at', 'by',
    'for', 'from', 'in', 'into', 'near', 'of', 'on', 'onto', 'to'
  ];
  const anyCapsPattern = /^\w+[A-Z]/;
  const exclusionsPattern = new RegExp('^(' + lowers.join('|') + ')*$', 'i');
  const wordDividerPattern = /^[-–—:.]$/;
  const tokenPattern = new RegExp('([^\\w\\s]*)(\\w+)', 'g');

  return capitalize(str.replace(tokenPattern, (match, punc, word) => {
    // If there is non-word-dividing punctuation, leave unchanged.
    if (punc && !punc.match(wordDividerPattern)) { return punc + word; }
    // If a word has any caps after first letter, leave unchanged.
    if (word.match(anyCapsPattern)) { return punc + word; }
    // If article, preposition, conjunction: lower-case it.
    if (word.match(exclusionsPattern)) { return punc + word.toLowerCase(); }

    // This is a title-word, so capitalize it.
    return punc + word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  }));
}


// Takes an array of anything, turns it into an English sentence:
//
//  C.listToSentence(['one', 'two', 'three']);
//  => "one, two, and three"
//
// The oxford comma is not optional, sorry.
//
// If you want to transform the array objects when they go into
// the string, you can pass a toStringFn:
//
//  C.listToSentence(['one', 'two', 'three'], C.capitalize);
//  => "One, Two, and Three"
//
export function listToSentence<T>(array: T[], toStringFn?: (item: T) => string): string {
  let arr: string[] = [];
  if (typeof toStringFn === 'function') {
    for (let i = 0, ii = array.length; i < ii; ++i) {
      const str = toStringFn(array[i]);
      if (typeof str !== 'undefined') {
        arr.push(str);
      }
    }
  } else {
    arr = <string[]><unknown>array; // :'(
  }
  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return arr.join(' and ');
  }

  return `${arr.slice(0, -1).join(', ')}, and&nbsp;${arr[arr.length - 1]}`;
}

export enum EncodingContext {
  Html,
  UriComponent
}

export const encode = (value: string, context = EncodingContext.Html) => {
  if (!value) { return value; }

  switch (context) {
    case EncodingContext.Html:
      const node = document.createElement('div');
      node.textContent = value;
      return node.innerHTML;
    case EncodingContext.UriComponent:
      return encodeURIComponent(value).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    default:
      return value;
  }
}


// https://mathiasbynens.be/notes/javascript-unicode
// This doesn't solve more complicated combined Unicode,
// but those solutions get really big with diminishing returns.
// If Intl.Segmenter ever gets implemented, we could use that.
//
export function unicodeLength(str: string): number {
  return [...str].length;
}


function replace(value: unknown, depth: number): boolean | number | bigint | string | object | undefined {
  if (typeof value === 'boolean' ||
    typeof value === 'number' ||
    typeof value === 'bigint' ||
    typeof value === 'string'
  ) {
    return value;
  }

  if (typeof value === 'undefined') {
    return 'undefined';
  }

  if (typeof value === 'function') {
    return 'Function';
  }

  if (typeof value === 'symbol') {
    return 'Symbol';
  }

  if (typeof value === 'object') {
    if (value === null) {
      return 'null';
    }

    if (value instanceof Date) {
      return value.toISOString();
    }

    if (Array.isArray(value)) {
      return depth > 0 ? value.map((i) => replace(i, depth - 1)) : `[...]{${value.length}}`;
    }

    return depth > 0
      ? Object.fromEntries(Object.entries(value).map(([k, v]) => ([k, replace(v, depth - 1)])))
      : '{...}';
  }

  throw new Error('Unexpected value type');
}


export function depthLimitedStringify(obj: unknown, maxDepth: number) {
  return JSON.stringify(replace(obj, maxDepth), null, 2);
}

