import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import env from 'app/base/env';
import { FetchOptions, HttpMethod } from './server';
import { Service } from './services/service';

const JSON_HEADERS = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

/**
 * @desc Hudson service interface, here for all your hudson needs
 * @see {@link http://hudson.hq.overdrive.com/docs/ui/index}
 */
export class Hudson extends Service {
  public static readonly PER_PAGE = 20;

  // Hudson only allows you to page through the first 10,000 results,
  // but will return the real full number of resuts in its response.
  public static readonly MAX_RESULTS = 10000;


  /**
   * @desc Initalizes your Hudson service
   */
  constructor() {
    super('HUDSON', (path: string) => {
      return { url: `${env.ROOT_URI}/api/hudson/v2/${path}` };
    });
  }


  /**
   * @desc Search an entire series' content and returns a list of matches
   * @param reqOptions Collection of call specific values
   * @see {@link http://hudson.hq.overdrive.com/docs/ui/index#!/V2Search/V2Search_Search}
   */
  public async search(params: SearchParameters): Promise<HudsonSearchResponse | null> {
    APP.tracking.log('series_search', {
      query: params.query,
      id: params.id,
      query_type: params.type,
      query_scope: params.scope,
      page: params.page
    });

    const filters: HudsonFilter = {
      edition: params.edition
    };

    if (params.scope === 'series') {
      filters.seriesId = params.id;
    } else if (params.scope === 'title') {
      filters.titleId = params.id;
    }

    const request = new HudsonSearchRequest(
      params.query,
      params.type,
      filters,
      params.page
    );

    const response = await this.fetchAsync<HudsonSearchResponse>(request.toFetchOptions({
      method: 'POST',
      headers: JSON_HEADERS
    }));

    if (!response) {
      return null;
    }

    response.totalPages = Math.ceil(Math.min(response.totalHits, Hudson.MAX_RESULTS) / Hudson.PER_PAGE);

    return response;
  }
}

// This regex matches the <em> tag (which Hudson uses to mark
// a query match) up until the next tag. Hopefully it grabs
// enough words to open the correct part of the book.
export function getBestQueryFromExcerpt(excerpt: string): string {
  const regex = new RegExp(/<em>[^<]*<\/em>[^<]*/);

  return excerpt
    .match(regex)![0]
    .replace(/<em>|<\/em>/g, '')
    .replace(new RegExp(String.fromCharCode(160), 'g'), ' ');
}

export function seekToQuery(query: string): void {
  APP.shell.bifocal.transmit({
    name: 'seek:query',
    query
  });
}


export class HudsonSearchRequest  {
  /**
   * @desc Query clause to apply to the search. Any item not matching the query will be not returned.
   */
  public query: string;
  /**
   * @desc The type of query, keyword for something specific, otherwise; phrase
   */
  public type: 'phrase' | 'keyword';
  /**
   * @desc Maximum number of results to return
   */
  public numMatches?: number;
  /**
   * @desc Number of results per page. Used to determine current position, with the 'page' property
   * @default 20
   */
  public perPage?: number;
  /**
   * @desc Current page that the user is on.
   * @notes ie/ Search for all 'oranges' and return the 2nd page of that query
   * @default 1
   */
  public page?: number;
  public url?: string;
  public filters?: HudsonFilter;


  constructor(query: string, type: 'phrase' | 'keyword', filter?: HudsonFilter, page?: number, perPage?: number) {
    this.query = query;
    this.type = type;
    this.filters = filter;
    this.page = page || 1;
    this.perPage = perPage || Hudson.PER_PAGE;
  }


  /**
   * @desc Helper method to transform this request into IFetchOptions
   * @param overrides
   */
  public toFetchOptions(overrides?: FetchOverrides): FetchOptions {
    const fetchOptions =  C.absorb(overrides || {}, {
      url: 'search',
      body: JSON.stringify(C.absorb(this, {}))
    });

    fetchOptions.headers = C.absorb(fetchOptions.headers || {}, {
      Authorization: `Bearer ${APP.sentry.identityToken}`
    });

    return fetchOptions;
  }
}

export type SearchThisTitleType = 'phrase' | 'keyword';

export type SearchThisTitleScope = 'series' | 'title';

export type SearchThisTitleSort = 'relevance' | 'chapter';

export type SearchThisTitleQuery = {
  query: string;
  type: SearchThisTitleType;
  scope: SearchThisTitleScope;
  page?: number;
  sort?: SearchThisTitleSort;
};

export type SearchParameters = {
  type: SearchThisTitleType;
  scope: SearchThisTitleScope;
  query: string;
  page: number;
  sort: SearchThisTitleSort;
  edition: string | undefined;
  id: string;
};


// Used for parsing the legacy path-encoded search options
export function parseSearchThisSetOptions(searchOptions: string): SearchThisTitleQuery | null {
  const segments = searchOptions.split('/');
  const setSearchOptions = {
    query: '',
    type: 'phrase' as SearchThisTitleType,
    page: 1,
    scope: 'title' as SearchThisTitleScope
  };
  for (const segment of segments) {
    const queryMatch = segment.match(/^query-(.+)$/);
    if (queryMatch) {
      setSearchOptions.query = decodeURIComponent(queryMatch[1]);
    }

    if (segment === 'keyword' || segment === 'phrase') {
      setSearchOptions.type = segment;
    }

    if (segment === 'set' || segment === 'volume') {
      setSearchOptions.scope = segment === 'set' ? 'series' : 'title';
    }

    const pageMatch = segment.match(/^page-(.+)$/);
    if (pageMatch) {
      setSearchOptions.page = parseInt(pageMatch[1], 10);
    }
  }

  if (!setSearchOptions.query) {
    return null;
  }

  return setSearchOptions;
}

export interface MatchedTitle  {
  matches: string[];
  chapterTitle: string | null;
  buid: string;
  edition: string;
  volume: string;
  contentReserveId: string;
  titleId: number;
  seriesId: number | null;
  path: string;
}

export interface HudsonFilter  {
  set?: string;
  seriesId?: string;
  contentReserveId?: string;
  titleId?: string;
  edition?: string;
}

export interface HudsonSearchResponse  {
  request?: HudsonSearchRequest;
  hits: MatchedTitle[];
  totalHits: number;
  totalVolumes: number;
  totalPages: number;
}

export interface FetchOverrides {
  method?: HttpMethod;
  headers?: any;
  credentials?: boolean;
  reportFailures?: boolean;
  ignoreStatusCodes?: number[];
  timeout?: number;
  retries?: number;
}
