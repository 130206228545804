import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_ctx.tags.length)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(_ctx.$style.container)
      }, [
        _createElementVNode("h1", {
          class: _normalizeClass(_ctx.$style.header)
        }, _toDisplayString(_ctx.$t('home.tags.header')), 3),
        _createElementVNode("ol", {
          class: _normalizeClass(_ctx.$style.list)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
            return (_openBlock(), _createElementBlock("li", {
              key: tag.slug
            }, [
              _createVNode(_component_router_link, {
                to: { name: 'TagDetails', params: { tagSlug: tag.slug } },
                class: _normalizeClass(_ctx.$style.link)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(tag.name), 1)
                ], undefined, true),
                _: 2
              }, 1032, ["to", "class"])
            ]))
          }), 128))
        ], 2),
        _createVNode(_component_router_link, {
          to: { name: 'Tags' },
          class: _normalizeClass(_ctx.$style.link)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('home.tags.viewAll')), 1)
          ], undefined, true),
          _: 1
        }, 8, ["class"])
      ], 2))
    : (_openBlock(), _createBlock(_component_EmptyState, {
        key: 1,
        class: _normalizeClass(_ctx.$style.emptyState),
        link: _ctx.helpLink,
        isCompact: true,
        header: _ctx.$t('tags.empty.header'),
        content: _ctx.$t('tags.empty.content'),
        linkLabel: _ctx.$t('tags.empty.link')
      }, null, 8, ["class", "link", "header", "content", "linkLabel"]))
}