import { Dictionary } from 'lib/common/dictionary';

declare var window: any;
declare var navigator: any;
export class Quirks {
  private static _registry: Dictionary<boolean>;
  private static _surveyResults: Dictionary<any>;

  // @ts-ignore
  private static readonly _initializer = Quirks._surveyBrowser(navigator.userAgent);


  /**
   *
   *  Quirks.is the quirk lookup function. Is the 'x' quirk known
   *  to manifest in Quirks.environment?
   *
   *  if (Quirks.has('x')) { console.log('The "x" quirk happens here.'); }
   */

  public static has(quirk: string): boolean {
    return Quirks._registry[quirk] ? true : false;
  }


  /**
   *
   *  Add a new quirk to the database. The first argument is the
   *  name of the quirk. The subsequent arguments are the query sequences
   *  where the quirk is known to manifest.
   *
   *  Quirks.add('fork-or-redmond-lizard', 'trident', 'windows gecko');
   */
  public static add(quirk: string, ...querySequences: unknown[]) {
    return (Quirks._registry[quirk] = Quirks.ask(querySequences));
  }


  /** Sometimes you want to create a new query type. For example:
   *
   *
   *  Quirks.set('screen-aspect-ratio', screen.width / screen.height);
   *
   *  You can then use Quirks.query type when adding quirks:
   *
   *  Quirks.add('widescreen-lizard', 'gecko screen-aspect-ratio>1');
   */
  public static set(key: string, value: unknown) {
    Quirks._surveyResults[key] = typeof value === 'undefined' || value === null ? false :
      (value instanceof Array ? value[1] || true
      : value);
  }


  /**
   *  You can also query the environment directly, rather than just when
   *  adding a quirk. Quirks.is most useful when you want to set a new
   *  query type based on existing query types:
   *
   *  Quirks.set('windows-gecko', Quirks.ask('windows gecko'));
   */
  public static ask(...args: any[]): boolean {
    let sequences = args[0];
    if (!(sequences instanceof Array)) {
      sequences = Array.prototype.slice.call(args, 0);
    }
    for (let i = 0, ii = sequences.length; i < ii; ++i) {
      const conditions = Quirks._tokenize('' + sequences[i]);
      let value = true;
      for (let j = 0, jj = conditions.length; j < jj; ++j) {
        const con = conditions[j];
        value = value && Quirks._evaluate(con[0], con[1], con[2], con[3]);
      }
      if (value) {
        return true;
      }
    }

    return false;
  }


  /**
   *
   *  Quirks.is a debugging tool:
   *
   *  Quirks.dump()
   *
   *  ... will write out the query types and quirks to the console.
   */
  public static dump() {
    const out: string[] = [];
    out.push('Query types:');
    for (const key in Quirks._surveyResults) {
      out.push('  ' + key + ': ' + Quirks._surveyResults[key]);
    }
    out.push('Quirks:');
    for (const key in Quirks._registry) {
      out.push('  ' + key + ': ' + Quirks._registry[key]);
    }
    console.log(out.join('\n'));
  }


  private static _reset() {
    Quirks._surveyResults = {
      true: true,
      false: false
    };
    Quirks._registry = {};
  }


  private static _tokenize(sequence: string): [string, string, string, string][] {
    const parts = sequence.split(/\s+/);
    const conditions: [string, string, string, string][] = [];
    for (let i = 0, ii = parts.length; i < ii; ++i) {
      const match = parts[i].match(/(!?)([^:=<>]+)([:=<>]*)([^:=<>]*)/);
      if (!match) {
        console.warn('No match for sequence: "' + sequence + '"');
      } else {
        conditions.push([match[2], match[3], match[4], match[1]]);
      }
    }

    return conditions;
  }


  private static _evaluate(key: string, op: string, operand: string, modifier: string) {
    const val = Quirks._surveyResults[key];
    let pass: boolean | undefined;
    if (!op) {
      pass = typeof val !== 'undefined' && val !== false;
    } else if (op === '=') {
      pass = '' + val === '' + operand;
    } else if (op === '>=') {
      pass = val !== false && val >= operand;
    } else if (op === '<=') {
      pass = val !== false && val <= operand;
    } else if (op === '>') {
      pass = val !== false && val > operand;
    } else if (op === '<') {
      pass = val !== false && val < operand;
    } else if (op === ':') {
      if (key === 'quirk') {
        pass = Quirks.has(operand);
      } else if (key === 'function') {
        try {
          pass = typeof eval(operand) === 'function';
        } catch (e) {
          pass = false;
        }
      }
    }
    if (pass === undefined) {
      console.warn('No test for %s %s %s', key, op, operand);

      return false;
    }

    return modifier === '!' ? !pass : pass;
  }


  private static _surveyBrowser(ua: string) {
    Quirks._reset();

    // Devices
    Quirks.set('kindle3', ua.match(/Kindle\/3/));
    Quirks.set('nook', ua.match(/NOOK/));
    Quirks.set('sony-reader', ua.match(/Linux;.*EBRD/));
    Quirks.set('nintendo', ua.match(/NintendoBrowser/));

    // Rendering engines
    Quirks.set('webkit', ua.match(/WebKit\/([\d\.]+)/));
    Quirks.set('gecko', ua.match(/Gecko\/([\d\.]+)/));
    Quirks.set('trident', ua.match(/Trident\/([\d\.]+)/));
    Quirks.set('edge', ua.match(/Edge\/([\d\.]+)/));

    // Browsers
    Quirks.set('firefox', ua.match(/Firefox\/([\d\.]+)/));
    Quirks.set(
      'chrome',
      ua.match(/Chrom(?:e|ium)\/([\d\.]+)/) ||
        ua.match(/Cr(?:Mo|iOS)\/([\d\.]+)/)
    );
    Quirks.set(
      'iexplore',
      Quirks.ask('trident') ? ua.match(/(?:rv:|MSIE )([\d\.]+)/) : false
    );
    Quirks.set(
      'safari-build',
      Quirks.ask('!chrome') ? ua.match(/Safari\/([\d\.]+)/) : false
    );
    Quirks.set(
      'safari',
      Quirks.ask('safari-build') ? ua.match(/Version\/([\d\.]+)/) : false
    );

    // Variants
    Quirks.set('silk', ua.match(/Silk/));
    Quirks.set('chromeframe', ua.match(/chromeframe/) && window.externalHost);

    // Platforms
    const andm = ua.match(/Android\s?([\d\.]+)?/);
    Quirks.set('android', andm || Quirks.ask('sony-reader', 'silk'));
    const iosm = ua.match(/OS ([\d_]+).*AppleWebKit.*Mobile/);
    Quirks.set('ios', iosm ? iosm[1].replace(/_/g, '.') : false);
    // Workaround for MobileSafari UIWebViews
    if (Quirks.ask('ios') && !Quirks.ask('safari')) {
      Quirks.set('safari', Quirks._surveyResults.ios);
    }
    Quirks.set('macosx', !iosm && ua.match(/Mac OS X/));
    Quirks.set('windows', ua.match(/Windows/));
    Quirks.set('blackberry', ua.match(/BlackBerry/));

    // More devices
    Quirks.set(
      'mobile',
      ua.match(/mobi|tablet|ip(?:ad|hone|od)|android|silk/i) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
    Quirks.set('ipad', iosm && ua.match(/iPad/));
    Quirks.set('iphone', iosm && ua.match(/(iPhone|iPod)/));

    // Specifics
    Quirks.set(
      'android-chrome',
      Quirks.ask('android chrome') || (andm && ua.match(/CrMo/))
    );
    Quirks.set('chromebook-webview', Quirks.ask('android chrome') && ua.match(/Chromebook/));
    Quirks.set('android-stock', Quirks.ask('android !android-chrome !firefox'));
    Quirks.set('ios-standalone', iosm && navigator.standalone);
    Quirks.set('ios-safari', Quirks.ask('ios safari !ios-standalone'));
    Quirks.set('ios-webview', Quirks.ask('ios !safari !ios-standalone'));
    Quirks.set('ios-uiwebview', Quirks.ask('ios-standalone')); // FIXME: can we improve Quirks.
    Quirks.set('eink', Quirks.ask('kindle3', 'sony-reader'));

    return true;
  }
}
