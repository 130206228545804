import { nextTick } from 'vue';
import { RouteLocation } from 'vue-router';

/**
 * Automatically retain the focus on an element that caused a query param route change.
 *
 * To use:
 *  For components that have the potential to cause a query param route change:
 *  - After the user has initiated a change to that component, add an 'isUpdated' class to the
 *    element that should be getting focus after the change is complete. Then if that element
 *    is still the active element during the route change, this will reset the focus to that
 *    element after the route change is complete.
 *  - Currently used in: Dropdown.vue, TabView.vue
 *  - If you need to override this automatic behavior on any page, add or remove the 'isUpdated'
 *    class as needed before a route is pushed or replaced.
 *
 *  TODO: add logic to determine focus when a new page is navigated to
 */

export function findActiveElement(from: RouteLocation, to: RouteLocation) {
  // if query params changed, but the main path is still the same
  if (from.path === to.path) {
    to.meta.activeElement = document.activeElement?.classList.contains('focus-management-preserve-focus') ? document.activeElement : null;
  }
};


export async function setFocus(to: RouteLocation) {
  const active = to.meta.activeElement as HTMLElement;

  if (active) {
    await nextTick();
    active.focus();
    active.classList.remove('focus-management-preserve-focus'); //remove class to try to avoid any confusion later
  }
};


export function preserveFocus(buttonElement: Element | null | undefined) {
  if (buttonElement) {
    buttonElement.classList.add('focus-management-preserve-focus');
  }
}
