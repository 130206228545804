<template>
  <div :class="$style.content">
    <div :class="$style.stripe"></div>

    <header :class="$style.header">
      <span :class="$style.actions">
        <BreadcrumbNavigation
          :title="title"
          :breadcrumbList="breadcrumb"
          :class="$style.breadcrumb"
        />
        <slot name="context"></slot>
      </span>

      <span
        v-if="label"
        :class="$style.label"
      >
        {{ $t(label) }}
      </span>

      <h1
        :class="$style.title"
        v-html="title"
      ></h1>
      <p
        v-if="subtitle"
        :class="$style.subtitle"
        v-html="subtitle"
      ></p>
      <div
        v-if="authorLinks.length"
        :class="$style.headerLinks"
      >
        <span :class="$style.authorLabel">
          {{ $t('title.creator', { n: authorLinks.length }) }}
        </span>
        <RouteList
          :items="authorLinks"
          itemClass="details-page-author"
        />
      </div>
    </header>

    <section :class="$style.pillar">
      <div :class="$style.jacket">
        <slot name="cover"></slot>
      </div>

      <table
        v-if="shownMetadata.length"
        :class="$style.metadata"
      >
        <caption class="visually-hidden">
          {{ $t('title.metadata.description') }}
        </caption>

        <tbody>
          <tr
            v-for="metadataItem of shownMetadata"
            :key="metadataItem.label"
          >
            <th
              :class="$style.metadataLabel"
              scope="row"
            >
              {{ $t(metadataItem.label) }}
            </th>
            <td>{{ metadataItem.value }}</td>
          </tr>
        </tbody>
      </table>

      <slot name="pillar"></slot>
    </section>

    <div :class="$style.body">
      <slot name="body"></slot>
    </div>

    <div :class="$style.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang='ts'>
import type { IdNamePair } from 'app/base/thunder';
import RouteList from 'app/components/RouteList.vue';
import { useBreadcrumbs } from 'app/functions/use-breadcrumbs';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import BreadcrumbNavigation from './BreadcrumbNavigation.vue';

export type MetadataItem = {
  label: string;
  value: string;
  show: boolean;
};

export default defineComponent({
  name: 'DetailsPageContent',
  components: {
    BreadcrumbNavigation,
    RouteList
  },
  props: {
    metadata: {
      type: Array as () => MetadataItem[],
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    authors: {
      type: Array as () => IdNamePair[],
      default: () => []
    },
    label: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const shownMetadata = computed(() =>
      props.metadata.filter((m) => m.show)
    );

    const authorLinks = computed(() => {
      return props.authors.map((author) => {
        return {
          text: author.name,
          to: {
            name: RouteName.Creator,
            params: {
              creatorId: author.id
            }
          } as RouteLocationRaw
        };
      });
    });

    const breadcrumb = useBreadcrumbs();

    return {
      authorLinks,
      shownMetadata,
      breadcrumb
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.actions {
  display: flex;
  align-items: baseline;
}

.breadcrumb {
  margin-top: -0.5rem;
  text-align: left;
  @media screen and (max-width: @px-vp-narrow) {
    margin-top: -1rem;
  }
}

.content {
  display: grid;
  grid-template-columns:
    [stripe-start] 0
    [side] @rem-details-page-cover-width
    [main] 1fr
    [content-end] 0
    [stripe-end];
  grid-template-rows: [header] max-content [body] 1fr [footer] auto;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
  background-color: var(--c-white);
  // Top left+right border-radius is smaller to hide it as .stripe sits on top
  border-radius: .625rem .625rem .5rem .5rem;
}

.stripe {
  grid-column: stripe-start / stripe-end;
  grid-row: header;
  background-color: var(--c-dark-blue);
  border-radius: .5rem .5rem 0 0;
}

.header {
  grid-column: main;
  grid-row: header;
  color: @c-white;
  margin: 2rem 0;
}

.header > a {
  .focus-outline-dark;
}

.header-links > a {
  .focus-outline-dark;
}

.label {
  background-color: @c-white;
  border-radius: 0.25rem;
  color: @c-black;
  padding: .1rem 1rem;
  line-height: normal;
  display: inline-block;
  text-transform: uppercase;
  transform: translateY(-25%);
  margin-right: 1rem;
}

.title {
  font-size: @fs-large-head;
  font-weight: @fw-bold;
  display: inline-block;
}

.subtitle {
  font-size: @fs-small-head;
  margin-bottom: 0.5rem;
}

.author-label {
  text-transform: uppercase;
  font-size: @fs-metadata;
  margin-right: 0.5rem;
}

:global(.details-page-author) {
  .pop(@fgColor: @c-white);
}


.pillar {
  grid-column: side;
  grid-row: header / footer;
  margin-top: 2rem;

  a {
    .focus-outline;
  }
}

.pillar > *:not(:last-child) {
  margin-bottom: 2rem;
}

.metadata {
  text-align: left;
  font-size: @fs-metadata;
  table-layout: fixed;
  width: 100%;

  tr {
    padding: 1rem 0;
    display: flex;
    border-bottom: 1px solid @c-dark-gray;
  }

    tr:first-child {
      padding-top: 0;
    }

    tr:last-child {
      padding-bottom: 0;
      border: 0;
    }

  th, td {
    width: 50%;
  }

  .metadata-label {
    color: @c-light-black;
    text-transform: uppercase;
  }
}

.body {
  grid-column: main;
  display: grid;
  grid-row-gap: 2rem;
  grid-auto-rows: max-content;
}

.footer {
  grid-column: side / content-end;
  grid-row: footer;
  margin: 2rem 0;
}


// ## TABLET LAYOUT

@media screen and (max-width: @px-vp-very-wide) {
  .content {
    grid-template-columns: minmax(0, 1fr);
    grid-column-gap: 0;
    grid-template-rows:
      [header] max-content
      [overflow] (@rem-details-page-cover-height / 5)
      [pillar] max-content
      [body] max-content
      [footer] max-content;
  }

  div.content > * {
    grid-column: 1;
  }

  .stripe {
    grid-row: header / span 2;
  }

  .header {
    grid-row: header;
    text-align: center;
    margin: 0;
    padding: 1rem;
  }

  .title {
    line-height: 1.25;
    margin-bottom: .5rem;
  }

  .subtitle {
    margin-bottom: 1rem;
  }

  .pillar {
    grid-column: main;
    grid-row: overflow / span 2;
    grid-row-gap: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    max-width: calc(@rem-details-page-cover-width * 2);
  }

  .pillar,
  .jacket,
  .metadata {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .metadata {
  }

  .metadata {
    tr {
      padding: .25rem 0;
      border: 0;
    }

    th {
      text-align: right;
      padding-right: 0.5rem;
    }
  }

  .body {
    grid-template-columns: 100%;
    padding-top: 2rem;
    border-top: 1px solid @c-dark-gray;
    margin: 0 1rem;
  }

  .footer {
    grid-column: main;
    margin-top: 0;
    margin: 0 1rem 1rem;
  }
}

// ## MOBILE LAYOUT

@media screen and (max-width: @px-vp-tablet) {
  .pillar {
    max-width: @rem-details-page-cover-width;
  }
}
</style>
