import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_LoanExpirationIndicator = _resolveComponent("LoanExpirationIndicator")!
  const _component_DownloadIndicator = _resolveComponent("DownloadIndicator")!
  const _component_TagIndicator = _resolveComponent("TagIndicator")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TitleCardContextMenu = _resolveComponent("TitleCardContextMenu")!
  const _component_CoverView = _resolveComponent("CoverView")!
  const _component_PriorReleaseSidebar = _resolveComponent("PriorReleaseSidebar")!
  const _component_TableOfContentsSidebar = _resolveComponent("TableOfContentsSidebar")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "actions",
    class: _normalizeClass(_ctx.$style.actions)
  }, [
    _createVNode(_component_TitleActionButton, {
      title: _ctx.title,
      focus: true,
      class: _normalizeClass(_ctx.$style.titleAction)
    }, null, 8, ["title", "class"]),
    (_ctx.isExpiring && _ctx.loan)
      ? (_openBlock(), _createBlock(_component_LoanExpirationIndicator, {
          key: _ctx.loanKeyUUID,
          loan: (_ctx.loan as Loan),
          iconOnly: _ctx.iconOnly,
          class: _normalizeClass([_ctx.$style.loanIndicator, _ctx.$style.loan])
        }, null, 8, ["loan", "iconOnly", "class"]))
      : _createCommentVNode("", true),
    (_ctx.loan && _ctx.isDownloadableLoan)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass([_ctx.$style.loanIndicator, _ctx.$style.download])
        }, [
          _createVNode(_component_DownloadIndicator, {
            downloader: _ctx.loan.downloader,
            iconOnly: _ctx.iconOnly
          }, null, 8, ["downloader", "iconOnly"])
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_TagIndicator, {
      title: _ctx.title,
      class: _normalizeClass(_ctx.$style.tag)
    }, null, 8, ["title", "class"]),
    _createElementVNode("button", {
      ref: "overflow",
      class: _normalizeClass(_ctx.$style.overflow),
      "aria-label": _ctx.$t('title.contextMenu.header'),
      "aria-haspopup": "dialog",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showContextMenu = true))
    }, [
      _createVNode(_component_Icon, { name: "overflow" })
    ], 10, _hoisted_1),
    (_ctx.showContextMenu)
      ? (_openBlock(), _createBlock(_component_TitleCardContextMenu, {
          key: 2,
          reference: _ctx.overflow,
          title: _ctx.title,
          subscribeText: _ctx.subscribeTextLong,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showContextMenu = false)),
          "onShow:coverview": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCoverView = true)),
          "onShow:priorreleases": _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPriorReleases = true)),
          "onShow:tableofcontents": _cache[4] || (_cache[4] = ($event: any) => (_ctx.showTableOfContents = true)),
          "onToggle:subscription": _ctx.toggleSubscription
        }, null, 8, ["reference", "title", "subscribeText", "onToggle:subscription"]))
      : _createCommentVNode("", true),
    (_ctx.showCoverView)
      ? (_openBlock(), _createBlock(_component_CoverView, {
          key: 3,
          title: _ctx.title,
          onClose: _ctx.hideOverlay
        }, null, 8, ["title", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showPriorReleases)
      ? (_openBlock(), _createBlock(_component_PriorReleaseSidebar, {
          key: 4,
          title: _ctx.title,
          onClose: _ctx.hideOverlay
        }, null, 8, ["title", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showTableOfContents)
      ? (_openBlock(), _createBlock(_component_TableOfContentsSidebar, {
          key: 5,
          item: _ctx.title,
          onClose: _ctx.hideOverlay
        }, null, 8, ["item", "onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}