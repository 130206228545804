import { APP } from 'app/base/app';

export class Catfish {
  public getToC(titleSlug: string): Promise<ToCResponse | null> {
    /* Sentry has the option of passing a card id so that
     * the buid used for the Catfish call is the same as the one
     * in that card's loan stamp.
     *
     * We don't want to do that, because we always give users
     * the newest version of a title when they open it, so we need
     * the newest buid for the ToC too.
     */
    return APP.services.sentry.fetchAsync(APP.sentry.requestOptions({
      url: `title/${titleSlug}/nav`,
      method: 'GET'
    }));
  }
}


export interface ToCResponse {
  landmarks: Landmark[];
  toc: ToC[];
}


interface Landmark {
  type: 'cover' | string;
  path: string;
  title: string;
}


export interface ToC {
  title: string;
  path: string;
  contents?: ToC[];
}
