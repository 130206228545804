import { APP } from 'app/base/app';
import { Dictionary } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { FetchAsyncError } from './server';


export class CirculationError extends Error {
  public errorType: string;
  public textKey: string;
  public remoteError: FetchAsyncError;
  public subs: Dictionary<string | number>;


  constructor(remoteError: FetchAsyncError) {
    super(remoteError.message);
    this.remoteError = remoteError;
    this._parseRemoteError(remoteError);
  }


  protected _initHelper(errorType: string, textKey: string, subs?: Dictionary<string | number>): boolean {
    this.errorType = errorType;
    this.textKey = textKey;
    this.subs = subs;

    return true;
  }


  protected _parseRemoteError(errorObject: FetchAsyncError) {
    if (['unauthorized', 'authentication_failure'].includes(errorObject.responseCode)) {
      return this._initHelper('unauthorized', 'circ.error.auth');
    }

    if (errorObject.responseCode === 'upstream_timeout') {
      return this._initHelper('timeout', 'circ.error.timeout');
    }

    const remoteError = errorObject.response && errorObject.response.bodyText ?
      JSON.parse(errorObject.response.bodyText) :
      null;

    if (remoteError) {
      const upstream = remoteError.upstream || {};

      if (upstream.errorCode === 'PatronCardFailedAuthentication') {
        return this._initHelper('circ.error.library-auth-message', 'circ.error.library-auth-message', {
          MESSAGE: remoteError.message
        });
      }

      if (this._interpretErrorCode(upstream.errorCode)) {
        return true;
      }
    }

    console.warn('[CIRC-ERROR] unrecognized service error:', errorObject);
    APP.sage.submit('error', {
      errorHumanReadable: Constants.text('sge.error.circulation'),
      errorMessage: 'Unrecognized Service Error',
      errorSource: 'CirculationError',
      errorData: {
        relay: errorObject
      }
    });

    return this._initHelper('circ.error.unrecognized', 'circ.error.unrecognized');
  }


  protected _interpretErrorCode(errorCode: string): boolean {
    return false;
  }
}


export class HoldError extends CirculationError {
  protected _interpretErrorCode(errorCode: string): boolean {
    switch (errorCode) {
      case 'PatronExceededHoldLimit':
        return this._initHelper(errorCode, 'circ.error.card-at-hold-limit');
      case 'TitleCannotBePlacedOnHold':
        return this._initHelper(errorCode, 'error.missing-route.unowned-title.explanation');
      default:
        return false;
    }
  }
}


export class LoanError extends CirculationError {
  protected _interpretErrorCode(errorCode: string): boolean {
    switch (errorCode) {
      case 'PatronExceededCheckoutLimit':
        return this._initHelper(errorCode, 'circ.error.card-at-loan-limit');
      case 'PatronExceededChurningLimit':
        return this._initHelper(errorCode, 'circ.error.card-at-churn-limit');
      case 'TitleNoLongerAvailable':
        return this._initHelper(errorCode, 'circ.error.no-copies-available');
      default:
        return false;
    }
  }
}
