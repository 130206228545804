import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["aria-label", "onClick"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _component_SeriesCover = _resolveComponent("SeriesCover")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_Page, {
        header: _ctx.$t('mysubscriptions.header'),
        useBackToTop: true
      }, {
        default: _withCtx(() => [
          (_ctx.subscriptionsWithBook && _ctx.subscriptionsWithBook.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptionsWithBook, (subscription) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: subscription.id,
                      class: _normalizeClass(_ctx.$style.subscription)
                    }, [
                      _createVNode(_component_Card, {
                        item: subscription.cardableItem,
                        compact: true,
                        showSeriesBadge: subscription.type === 'Series'
                      }, {
                        cover: _withCtx(() => [
                          (subscription.type === 'Title')
                            ? (_openBlock(), _createBlock(_component_Cover, {
                                key: 0,
                                item: (subscription.item as TitleRecord)
                              }, null, 8, ["item"]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(_component_SeriesCover, {
                                  series: (subscription.item as Series),
                                  width: _ctx.seriesCoverSizes.seriesCardCompact
                                }, null, 8, ["series", "width"])
                              ]))
                        ]),
                        actions: _withCtx(() => [
                          _createElementVNode("button", {
                            "aria-label": _ctx.$t('subscription.unsubscribe.long'),
                            class: _normalizeClass(_ctx.$style.unsubscribe),
                            onClick: ($event: any) => (_ctx.unsubscribe(subscription.id))
                          }, _toDisplayString(_ctx.$t('subscription.unsubscribe.short')), 11, _hoisted_3)
                        ]),
                        _: 2
                      }, 1032, ["item", "showSeriesBadge"])
                    ], 2))
                  }), 128))
                ])
              ]))
            : (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_Loading)
                ]))
              : (_openBlock(), _createBlock(_component_EmptyState, {
                  key: 2,
                  link: _ctx.helpLink,
                  header: _ctx.$t('mysubscriptions.emptystate.header'),
                  content: _ctx.$t('mysubscriptions.emptystate.content'),
                  linkLabel: _ctx.$t('mysubscriptions.emptystate.link')
                }, null, 8, ["link", "header", "content", "linkLabel"]))
        ], undefined, true),
        _: 1
      }, 8, ["header"])
    ], undefined, true),
    _: 1
  }))
}