import { APP } from 'app/base/app';
import { Library } from './library';
import { Title, TitleRecord } from './title';

export class Titles {
  private all: { [key: string]: any };
  public library: Library;


  constructor(library: Library) {
    this.library = library;
    this._reset();
    APP.events.on('app:refresh', () => this._reset());
  }


  public fetch(slug: string): TitleRecord {
    if (APP.titleCache.get(slug)) {
      return APP.titleCache.get(slug);
    }
    if (this.all[slug]) {
      return this.all[slug];
    }
    const title = new Title();
    title.slug = slug;

    return (this.all[slug] = title);
  }


  protected _reset() {
    this.all = {};
  }
}
