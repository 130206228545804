<template>
  <ol :class="$style.list">
    <li
      v-for="(annotation, index) in annotations"
      :key="annotation.uuid"
    >
      <AnnotationCard
        :annotation="annotation"
        :selectable="selectable"
        :hideNote="hideNote"
        @annotation:update="(a, checked) => updateAnnotation(index, a, checked)"
      />
    </li>
  </ol>
</template>

<script lang="ts">
import AnnotationCard from 'app/components/AnnotationCard.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AnnotationCardList',
  components: { AnnotationCard },
  props: {
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    selectable: {
      type: Boolean,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  emits: [
    'annotations:update'
  ],
  setup: (props, ctx) => {

    const updateAnnotation = (index: number, annotation: SelectableAnnotation, checked: FormCheckboxState) => {
      const copy = props.annotations.slice();
      copy[index] = annotation;

      ctx.emit('annotations:update', copy, checked, annotation.uuid);
    };

    return {
      updateAnnotation
    };
  }
});
</script>

<style module>
.list {
  appearance: none;
}

.list li+li {
  margin-top: 1.5rem;
}
</style>
