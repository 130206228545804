import { Events, EventCallback, EventType, AppEventMap } from './events';

export type GlobalEventHandler<T extends keyof GlobalEventHandlersEventMap> = Handler<GlobalEventHandlersEventMap, T>;
export type TapHandler = GlobalEventHandler<'click'>;

export interface Handler<T, E extends EventType<T>> {
  listen(): unknown;

  deafen(): unknown;
}

export class EventHandler<T, E extends EventType<T>> implements Handler<AppEventMap<T>, E> {
  public evtType: E;
  public callback: EventCallback<T, E>;
  public element: HTMLElement | SVGElement | Window;
  public useCapture: boolean;
  public listening = false;
  private readonly events: Events<T>;


  constructor(
    evtType: E,
    callback: EventCallback<T, E>,
    events: Events<T>,
    element: HTMLElement | SVGElement | Window = Events.DEFAULT_DISPATCHER,
    useCapture = false
  ) {
    this.evtType = evtType;
    this.callback = callback;
    this.events = events;
    this.element = element;
    this.useCapture = useCapture;
  }


  public listen(): EventHandler<T, E> {
    if (this.listening) {
      return this;
    }
    this.events.listen(
      this.evtType,
      this.callback,
      this.element,
      this.useCapture
    );
    this.listening = true;

    return this;
  }


  public deafen(): EventHandler<T, E> {
    if (!this.listening) {
      return this;
    }
    this.events.deafen(
      this.evtType,
      this.callback,
      this.element,
      this.useCapture
    );
    this.listening = false;

    return this;
  }
}
