<template>
  <DetailsPageContent
    label="series.label"
    :title="series.name"
    :authors="series.creators.Author"
    :metadata="metadata"
  >
    <template #cover>
      <SeriesCover
        :series="series"
        :width="seriesCoverSizes.seriesDetails"
        :class="$style.cover"
      />
    </template>

    <template #pillar>
      <div :class="$style.interactContainer">
        <CopyLink
          :class="[$style.interactButton, $style.copyLink]"
        />
        <button
          :class="[$style.interactButton, $style.subscribe]"
          :aria-label="subscribeTextLong"
          @click="toggleSubscription()"
        >
          <div>
            <Icon
              :class="$style.bell"
              name="alert-bell"
            />
          </div>
          <span>{{ subscribeTextShort }}</span>
        </button>
      </div>
    </template>

    <template #body>
      <section>
        <SeriesDetailsAccordion
          :series="series"
          :volumeDictionary="volumeDictionary"
        />
      </section>

      <section>
        <h2 :class="$style.sectionHead">
          {{ $t('searchThisTitle.header.series') }}
        </h2>

        <SearchThisTitle
          :item="series"
          :params="searchThisTitle"
        />
      </section>
    </template>

    <template #footer>
      <TabView
        ariaLabel="series.volumeList.label"
        :tabs="volumeTabs"
      >
        <template #intermediary>
          <div :class="$style.volumeFilter">
            <TextFilter
              v-model="textFilter"
              :label="$t('series.volumeList.textFilter')"
            />
          </div>
        </template>

        <template #panel-all>
          <ol :class="$style.volumeList">
            <li
              v-for="volume in allVolumes"
              v-show="volumeMatchesFilter(volume)"
              :key="volume.title.slug"
            >
              <TitleCard :title="volume.title" />
            </li>
          </ol>
        </template>

        <template #panel-annotated>
          <ol :class="$style.volumeList">
            <li
              v-for="volume in volumesWithAnnotations"
              v-show="volumeMatchesFilter(volume)"
              :key="volume.title.slug"
            >
              <NotesTitleCard :title="volume.title" />
            </li>
          </ol>
        </template>

        <template #panel-downloaded>
          <ol :class="$style.volumeList">
            <li
              v-for="volume in downloadedVolumes"
              v-show="volumeMatchesFilter(volume)"
              :key="volume.title.slug"
            >
              <TitleCard :title="volume.title" />
            </li>
          </ol>
        </template>
      </TabView>
    </template>
  </DetailsPageContent>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import { seriesCoverSizes } from 'app/base/constants';
import { SearchThisTitleQuery } from 'app/base/hudson';
import CopyLink from 'app/components/CopyLink.vue';
import DetailsPageContent from 'app/components/DetailsPageContent.vue';
import SearchThisTitle from 'app/components/SearchThisTitle.vue';
import SeriesCover from 'app/components/SeriesCover.vue';
import SeriesDetailsAccordion from 'app/components/SeriesDetailsAccordion.vue';
import TabView from 'app/components/TabView.vue';
import TextFilter from 'app/components/TextFilter.vue';
import NotesTitleCard from 'app/components/cards/NotesTitleCard.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { useSeriesSubscription } from 'app/functions/use-subscription-interaction';
import { Series } from 'app/models/series';
import { Title, TitleRecord } from 'app/models/title';
import { SeriesDetailsVolume } from 'app/views/SeriesDetails.vue';
import { Dictionary } from 'lib/common/dictionary';
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'SeriesDetailsContent',
  components: {
    DetailsPageContent,
    SeriesCover,
    CopyLink,
    SeriesDetailsAccordion,
    TabView,
    TitleCard,
    NotesTitleCard,
    TextFilter,
    SearchThisTitle
  },
  props: {
    series: {
      type: Series,
      required: true
    },
    volumes: {
      type: Object as () => Dictionary<SeriesDetailsVolume>,
      default: () => ({})
    },
    searchThisTitle: {
      type: Object as () => SearchThisTitleQuery | null,
      default: null
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    onMounted(() => {
      announceMessage(t('series.detailsAriaHeader', { series: props.series.name }));
    });

    const {
      subscribeTextShort,
      subscribeTextLong,
      toggleSubscription
    } = useSeriesSubscription(props.series, 'series details page');

    const metadata = computed(() => {
      return [
        {
          label: 'series.volumeCount',
          value: Object.keys(props.volumes || {}).length
            || props.series.items?.length.toString()
            || '-',
          show: true
        },
        {
          label: 'series.edition',
          value: props.series.edition,
          show: !!props.series.edition
        }
      ];
    });

    const volumeDictionary = computed(() => {
      return Object.values(props.volumes)
        .reduce(
          (dict, next) => {
            dict[next.title.slug] = next.title;

            return dict;
          },
          {} as Dictionary<TitleRecord>
        );
    });

    const legacySetIdForHudson = computed(() => {
      return props.volumes[props.series.firstTitleSlug]?.title.lexisMetadata?.setId;
    });


    const textFilter = ref('');

    const allVolumes = computed(() => {
      return Object.values(props.volumes)
        .sort((v1, v2) => Title.SORT_FUNCTIONS.seriesRank(v1.title, v2.title));
    });

    const volumesWithAnnotations = computed(() => {
      return allVolumes.value
        .filter((v) => v.hasAnnotations);
    });

    const downloadedVolumes = computed(() => {
      return allVolumes.value
        .filter((v) => v.isDownloaded);
    });

    const volumeMatchesFilter = (volume: SeriesDetailsVolume) => {
      return Title.FILTER_FUNCTIONS.filterByText(volume.title, textFilter.value);
    };

    const volumeTabs = computed(() => {
      const tabs = [
        {
          id: 'all',
          label: t('series.volumeList.allVolumes'),
          count: allVolumes.value.filter(volumeMatchesFilter).length
        },
        {
          id: 'annotated',
          label: t('series.volumeList.volumesWithAnnotations'),
          count: volumesWithAnnotations.value.filter(volumeMatchesFilter).length
        }
      ];

      if (APP.shell.has('rosters')) {
        tabs.push({
          id: 'downloaded',
          label: t('series.volumeList.downloadedVolumes'),
          count: downloadedVolumes.value.filter(volumeMatchesFilter).length
        });
      }

      return tabs;
    });

    return {
      downloadedVolumes,
      allVolumes,
      legacySetIdForHudson,
      metadata,
      seriesCoverSizes,
      subscribeTextLong,
      subscribeTextShort,
      textFilter,
      toggleSubscription,
      volumeDictionary,
      volumeTabs,
      volumesWithAnnotations,
      volumeMatchesFilter
    };
  }
});
</script>

<style module>
.section-head {
  font-size: var(--fs-body);
  font-weight: var(--fw-medium-bold);
  margin-bottom: 0.75rem;
}

.volume-list {
  composes: list-grid from global;
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 3;
  --grid-item-min-width: var(--px-card-min-width);
}

.volume-filter {
  margin-bottom: 2rem;
  width: 15rem;
}

.interact-container {
  display: grid;
  width: var(--rem-details-page-cover-width);
  margin: 0 auto;
  grid-template-areas: 'copy subscribe';
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.interact-button {
  display: grid;
  width: fit-content;
  grid-template-areas: 'icon text';
  grid-template-columns: auto auto;
  align-items: center;
  gap: 0.25rem;
}

.interact-button div {
  grid-area: icon;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: 1px solid rgba(41, 98, 255, 0.1);
  border-radius: 50%;
  background-color: #F4F7FF;
}

.interact-button svg {
  height: 1.5rem;
  width: 1.25rem;
  fill: var(--c-primary-blue);
}

.interact-button span {
  grid-area: text;
  color: var(--c-primary-blue);
  text-decoration: underline;
}

.bell {
  stroke: var(--c-primary-blue);
}

.copy-link {
  grid-area: copy;
}

.subscribe {
  grid-area: subscribe;
}

@media screen and (max-width: 1024px /*px-vp-very-wide*/) {
  .cover {
    max-width: var(--rem-details-page-cover-width);
    margin: 0 auto;
  }
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .volume-list {
    --grid-layout-gap: 0;
    grid-row-gap: 1.5rem;
  }
}
</style>
