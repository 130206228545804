import { APP } from 'app/base/app';
import env from 'app/base/env';
import { Roster, RosterDocument } from 'app/base/updates/roster';
import { C } from '../common';
import { EventHandler } from '../event-map';
import { Version } from './version';

export class RosterApp extends Roster {
  public rosterURL?: string;
  private _appUpdateHandler?: EventHandler<'app:update:available'>;
  private _documentHandler?: EventHandler<'msg:roster:response'>;
  private _recoverHandler?: EventHandler<'app:update:recover'>;
  private _recoverTimer = -1;
  private _documentFailures = 0;
  public static ROSTER_PATH = '/roster.json';

  private _updateFailureTimeout = -1;
  private static readonly REATTEMPT_DELAY_MS = 15 * 1000;


  public assign(rosterDoc: RosterDocument): void {
    if (!rosterDoc || APP.client.info.version.isNewerThan(rosterDoc.version)) {
      this._triggerUpdate(APP.client.info.version.value!);
      this._fetchDocument();
    } else {
      super.assign(rosterDoc);
      this._appUpdateHandler = this._appUpdateHandler || APP.events.on(
        'app:update:available',
        (_) => this._onAppVersionUpdate()
      );
      APP.events.onHandler(this._appUpdateHandler);
    }
  }


  protected _onAppVersionUpdate(): void {
    APP.events.off(this._appUpdateHandler);
    this.wipe();
  }


  public cycle(version: Version): void {
    if (this.targetVersion.isEqual(version) && this.finalized) {
      this._updateFailureTimeout = window.setTimeout(() => APP.events.dispatch('app:update:failed'), 500);

      return;
    }
    if (this.doc && this.doc.health === 'pending') {
      this.invalidateUpdate();
    }
    APP.events.off(this._recoverHandler);
    clearTimeout(this._recoverTimer);
    console.log('[ROSTER-APP] cycle to', version);
    this.targetVersion = version;
    this._fetchDocument();
  }


  protected _fetchDocument(): void {
    if (!this.rosterURL) {
      this.rosterURL = env.ROOT_URI + RosterApp.ROSTER_PATH;
    }
    this._documentHandler = this._documentHandler || APP.events.on(
      'msg:roster:response',
      (evt) => this._onDocument(evt.m.url,
        Array.isArray(evt.m.rosters) ? evt.m.rosters[0] : evt.m.rosters,
        evt.m.response ? evt.m.response : undefined,
        evt.m.response ? evt.m.response.status : undefined)
    );
    APP.events.onHandler(this._documentHandler);
    this._updateFailureTimeout = window.setTimeout(() => {
      if (!APP.network.reachable) {
        APP.events.dispatch('app:update:failed');
      }
    }, 10000);
    APP.shell.transmit({
      name: 'roster:request',
      dest: 'shell',
      url: this.rosterURL
    });
  }


  protected _onDocument(url: string, rosters: RosterDocument, response?: any, statusCode?: number): void {
    clearTimeout(this._updateFailureTimeout);
    if (url !== this.rosterURL) {
      return;
    }
    if (!response || (statusCode || 0) >= 400) {
      this._eventuallyRecoverUpdate();

      return;
    }
    APP.events.off(this._documentHandler);

    if (!rosters) {
      this._documentFailures++;
      if (this._documentFailures <= 5) {
        console.log('[ROSTER-APP] Invalid roster document, trying again');
        setTimeout(() => {
          this._fetchDocument();
        }, this._documentFailures * C.MS_SECOND);
       } else {
        console.log('[ROSTER-APP] Invalid roster document, giving up');
        APP.events.dispatch('app:update:failed');
        APP.sage.submit('error', {
          errorMessage: `Failure fetching roster document: ${this.rosterURL}`,
          errorSource: this.rosterURL,
          errorData: { url: url, rosters: rosters }
        });

      }

      return;
    }

    this.initializeUpdate(rosters);
  }


  protected _updateInitialized(): void {
    // When the Elrond app is updated, all loans must be checked
    // for updates too (after reload).
    APP.updateManager.setUpdateHorizon();
  }


  protected _updateFinalized(): void {
    if (this.targetVersion.isEqual(this.version)) {
      APP.events.dispatch('app:update:ready');
    } else {
      APP.events.dispatch('app:update:failed');
    }
  }


  protected _updateInvalidated(): void {
    this.wipe();
    this._eventuallyRecoverUpdate();
  }


  protected _eventuallyRecoverUpdate() {
    if (!this.targetVersion.value) { return; }
    const recycle = () => {
      const ver = this.targetVersion;
      this.targetVersion = new Version();
      this.cycle(ver);
    };


    this._documentFailures++;
    if (this._documentFailures <= 5) {
      // If we failed to cycle, wait fifteen seconds before reattempting
      this._recoverTimer = window.setTimeout(
        () => recycle(),
        RosterApp.REATTEMPT_DELAY_MS
      );
      this._recoverHandler = this._recoverHandler || APP.events.on(
        'app:update:recover',
        () => recycle()
      );
      APP.events.onHandler(this._recoverHandler);
    } else {
      console.log('[ROSTER-APP] Update failed, giving up');
      APP.events.dispatch('app:update:failed');
      this._documentFailures = 0;
      this.targetVersion = new Version();
    }
  }
}
