<template>
  <section>
    <h3 :class="$style.header">
      <Icon name="book" />
      {{ $t('header.account.borrowingLimits.heading') }}
    </h3>

    <template v-if="borrowLimits">
      <p>
        {{ $t('header.account.borrowingLimits.borrowed', { BORROWED: borrowLimits.current, TOTAL: borrowLimits.maximum }) }}
      </p>
      <p>
        {{ $t('header.account.borrowingLimits.remaining', { REMAINING: borrowLimits.remaining, TOTAL: borrowLimits.maximum }) }}
      </p>
    </template>

    <p v-else>
      {{ $t('header.account.borrowingLimits.loading') }}
    </p>
  </section>
</template>

<script lang='ts'>
import { usePatron } from 'app/functions/use-patron';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'UserMenuBorrowLimits',
  setup: (props) => {
    const { card } = usePatron();

    const remainingCheckouts = computed(() => card.value?.remainingNonSuCheckouts());

    const borrowLimits = computed(() => {
      if (card.value?.limits === undefined || remainingCheckouts.value === undefined) {
        return undefined;
      }

      return {
        remaining: remainingCheckouts.value,
        maximum: card.value.limits.loan,
        current: card.value.limits.loan - remainingCheckouts.value
      };
    });

    return {
      borrowLimits
    };
  }
});
</script>

<style lang='less' module>

.header {
  display: flex;
  align-items: center;
  color: var(--c-darkish-black);
  text-transform: uppercase;
  font-size: var(--fs-metadata);
  margin-bottom: 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.25rem;
    fill: var(--c-darkest-gray);
  }
}

.table {
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
}

.row {
  th {
    text-align: left;
    padding: 0.25rem 0;
  }

  td {
    text-align: right;
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      transform: translateX(calc(-100% - 0.5rem));
      top: 50%;
      width: 200%;
      border-bottom: 1px dashed var(--c-light-black);
    }
  }
}

.label {
  position: relative;
  z-index: 2;
  background-color: var(--c-dark-black);
  padding-right: 0.5rem;
}

.loading {
  display: inline-flex;
  align-items: center;
  margin-left: auto;

  svg {
    width: 1rem;
    height: 1rem;
    padding-right: 0.25rem;
  }
}
</style>
