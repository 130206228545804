import { APP } from 'app/base/app';
/**
 * The Orient is in charge of sending 'surface:orientation' messages
 * to the shell. It sends one of:
 *
 *  'portrait'
 *  'landscape'
 *  'auto'
 *
 * ... based on the current window context. In general, on large screens,
 * it will send 'auto', and on small screens it will send 'portrait'.
 *
 * Two exceptions:
 *
 * - if a book has been forced into landscape mode by the publisher,
 *   it will send 'landscape' when the book is opened
 *
 * - otherwise if a fixed layout book is opened, it will send 'auto'
 *   irrespective of screen size
 *
 */
export class Orient {
  public static THRESHOLD_WIDTH_PX = 500;


  constructor() {
    this.update();
    APP.events.on('msg:platform:traits', (evt) => this.update());
  }


  public update(forcedOrientation?: Orientation): void {
    let orientation: Orientation;
    if (forcedOrientation) {
      orientation = forcedOrientation;
    } else {
      orientation = this._orientationFromScreenDimensions();
    }
    this._transmitOrientation(orientation);
  }


  protected _transmitOrientation(orientation: Orientation): void {
    APP.shell.transmit({
      name: 'surface:orientation',
      orientation: orientation
    });
  }


  protected _orientationFromScreenDimensions(): Orientation {
    const wid = Math.min(screen.width, screen.height);
    if (wid < Orient.THRESHOLD_WIDTH_PX) {
      return 'portrait';
    }

    return 'auto';
  }
}

export type Orientation = 'auto' | 'portrait' | 'landscape';
