import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledDropdown = _resolveComponent("LabeledDropdown")!

  return (_openBlock(), _createBlock(_component_LabeledDropdown, {
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    label: _ctx.$t('subjects.sort.label'),
    hideLabelOnSmallScreens: true
  }, null, 8, ["modelValue", "label"]))
}