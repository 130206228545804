import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.channel,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.channel) = $event)),
    options: _ctx.channelOptions,
    buttonClass: [_ctx.$style.dropdownButton, 'dark'],
    listClass: _ctx.$style.dropdownList,
    optionClass: _ctx.$style.dropdownOption,
    selectedClass: _ctx.$style.dropdownSelected,
    highlightedClass: _ctx.$style.dropdownHighlighted,
    style: _normalizeStyle(`--c-channel: ${_ctx.color(_ctx.channel)}`)
  }, null, 8, ["modelValue", "options", "buttonClass", "listClass", "optionClass", "selectedClass", "highlightedClass", "style"]))
}