import { Dictionary } from 'app/base/common';
import { SearchThisTitleQuery } from 'app/base/hudson';
import { SeekLocation } from 'app/controllers/open-controller';
import { SubjectFilters } from 'app/functions/use-subject-filters';
import { Base64 } from 'lib/common';

export const QUERY_FROM_CATEGORY: { [k in keyof SubjectFilters]: string } = {
  Classification: 'contentType',
  Jurisdiction: 'jurisdiction',
  PracticeArea: 'practiceArea',
  Subject: 'otherSubjects'
};

export function mapSubjectFilters(query: Dictionary<string | undefined>): SubjectFilters {
  const contentType = query.contentType?.split(',');
  const jurisdiction = query.jurisdiction?.split(',');
  const practiceArea = query.practiceArea?.split(',');
  const otherSubjects = query.otherSubjects?.split(',');

  return {
    Classification: contentType,
    Jurisdiction: jurisdiction,
    PracticeArea: practiceArea,
    Subject: otherSubjects
  };
}

export function mapSearchThisTitleQueryString(
  query: Dictionary<string | undefined>,
  itemType: 'series' | 'title'
): SearchThisTitleQuery | null {
  if (!query.q) { return null; }

  return {
    query: query.q,
    type: query.type === 'keyword' ? 'keyword' : 'phrase',
    scope: itemType === 'series'
      ? 'series'
      : query.scope === 'set' ? 'series' : 'title',
    page: query.page ? parseInt(query.page, 10) : 1,
    sort: query.sort === 'chapter' ? 'chapter' : 'relevance'
  };
}


export function mapSearchThisTitleQueryParams(
  params: SearchThisTitleQuery
): Dictionary<string | undefined> {
  return {
    q: params.query,
    type: params.type,
    scope: params.scope === 'series' ? 'set' : 'title',
    // sort: params.sort || 'relevance', // Not yet implemented
    page: (params.page || 1).toString()
  };
}


export function mapSeekLocationToQuery(seekLocation: SeekLocation | undefined): Dictionary<string> {
  if (!seekLocation) { return {}; }

  switch (seekLocation.type) {
    case 'path':
      return {
        p: Base64.encode(seekLocation.location, true)
      };
    case 'query':
      return {
        q: Base64.encode(seekLocation.location, true)
      };
    case 'highlight':
      return {
        h: seekLocation.location
      };
    default:
      return {};
  }
}


export function mapQueryToSeekLocation(query: Dictionary<string | undefined>): SeekLocation | undefined {
  if (query.p) {
    return {
      type: 'path',
      location: Base64.decode(query.p)
    };
  }

  if (query.q) {
    return {
      type: 'query',
      location: Base64.decode(query.q)
    };
  }

  if (query.h) {
    return {
      type: 'highlight',
      location: query.h
    };
  }

  return undefined;
}
