import { C } from 'app/base/common';
import { Bank } from './bank';

export class BankMigrator {
  public migrate(bank: Bank): void {
    let ver = bank.get('_bank-version');
    if (ver < 'B3') {
      ver = this._migrateB3(bank);
    }
    if (ver < 'B4') {
      ver = this._migrateB4(bank);
    }
    bank.set('_bank-version', ver);
  }


  protected _migrateB3(bank: Bank): string {
    this._wipeEverything(bank);

    return 'B3';
  }


  protected _migrateB4(bank: Bank): string {
    console.log('Migrating to bank version B4');
    bank.set('library:all', null);

    return 'B4';
  }


  protected _wipeEverything(bank: Bank): void {
    C.each(bank.getData(), (key, val) => { bank.set(key, null); });
  }
}
