import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listParts, (part, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
      (part.to)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: _normalizeClass(_ctx.itemClass),
            to: part.to
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(part.text), 1)
            ], undefined, true),
            _: 2
          }, 1032, ["class", "to"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(part.text), 1))
    ], 64))
  }), 128))
}