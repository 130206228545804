import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href", "onClick"]
const _hoisted_5 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!

  return (_openBlock(), _createElementBlock("article", {
    "aria-label": _ctx.fullTitle,
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.info)
    }, [
      _createVNode(_component_router_link, {
        to: _ctx.titleLink,
        class: _normalizeClass(_ctx.$style.jacket),
        tabindex: "-1",
        "aria-hidden": true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Cover, { item: _ctx.title }, null, 8, ["item"])
        ], undefined, true),
        _: 1
      }, 8, ["to", "class"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.wrapper)
      }, [
        _createElementVNode("h2", {
          class: _normalizeClass(_ctx.$style.title),
          innerHTML: _ctx.title.title
        }, null, 10, _hoisted_2),
        (_ctx.title.subtitle)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(_ctx.$style.subtitle),
              innerHTML: _ctx.title.subtitle
            }, null, 10, _hoisted_3))
          : _createCommentVNode("", true),
        _createVNode(_component_router_link, {
          to: _ctx.titleLink,
          custom: ""
        }, {
          default: _withCtx(({ href, navigate }) => [
            _createElementVNode("a", {
              href: href,
              class: _normalizeClass(_ctx.$style.biblio),
              onClick: navigate,
              onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
            }, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('title.contextMenu.viewDetailsPage')), 1)
            ], 42, _hoisted_4)
          ]),
          _: 1
        }, 8, ["to"])
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.actions)
    }, [
      _createVNode(_component_TitleActionButton, {
        title: _ctx.title,
        class: _normalizeClass(_ctx.$style.action),
        onVfocus: _ctx.onFocus
      }, null, 8, ["title", "class", "onVfocus"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.actionsRight)
    }, [
      (_ctx.timestamp)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_Icon, { name: "expire-clock" }),
            _createVNode(_component_RelativeDate, {
              class: "metadata",
              timestamp: _ctx.timestamp
            }, null, 8, ["timestamp"])
          ], 64))
        : _createCommentVNode("", true)
    ], 2)
  ], 10, _hoisted_1))
}