import TypedStorage from 'app/base/storage/typed-storage';
import { buildCopyJobContext } from 'app/contexts/copy-job-context';
import { NtcContext } from 'app/contexts/use-ntc-context';
import { useAlertsWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { useSubscriptionsWithMetadata } from 'app/functions/use-subscriptions-with-metadata';
import { FilterObject } from 'app/models/filter-object';
import { BankedSeries } from 'app/models/series';
import { BankedTitle } from 'app/models/title';
import { ComputedRef, InjectionKey } from 'vue';

// Injection keys for provide/inject functions without a context
export const ADAllowStTOption = Symbol() as InjectionKey<ComputedRef<Boolean>>;
export const ADCopyJobState = Symbol();
export const ADReleasesWithAnnotationsSymbol = Symbol();
export const ContextMenuType = Symbol() as InjectionKey<'export-queue' | 'annotation-details'>;
export const TDReleasesWithAnnotationsSymbol = Symbol() as InjectionKey<ComputedRef<FilterObject[]>>;
export const TempTitleCacheSymbol = Symbol() as InjectionKey<TypedStorage<BankedTitle>>;
export const TempSeriesCacheSymbol = Symbol() as InjectionKey<TypedStorage<BankedSeries>>;
export const AlertsWithMetadataSymbol = Symbol() as InjectionKey<ReturnType<typeof useAlertsWithMetadata>>;
export const SubscriptionsWithMetadataSymbol = Symbol() as InjectionKey<ReturnType<typeof useSubscriptionsWithMetadata>>;
export const NtcContextSymbol = Symbol() as InjectionKey<NtcContext>;
export const CopyJobContextSymbol = Symbol() as InjectionKey<ReturnType<typeof buildCopyJobContext>>;
