import { Library } from 'app/models/library';

declare const window: any;

export class GTMHelper {
  public static setDataLayer(data: ElrondGTMDataLayer): void {
    window.dataLayer = window.dataLayer || [];

    const payload = {
      user: {
        uniqueUserID: data.accountId
      },
      session: data.session,
      environment: data.environment,
      page: {
        platform: {
          version: data.platform
        },
        library: {
          id: data.library.websiteId,
          name: data.library.name,
          property: data.ga.property,
          view: data.ga.view
        }
      }
    };

    window.dataLayer.push(payload);
  }


  public static addPageView(pageview: ElrondPageView): void {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'page_view',
      page: pageview
    });
  }


  public static clearDataLayer(): void {
    // https://developers.google.com/tag-platform/devguides/datalayer#reset
    window.dataLayer.push(function () {
      // @ts-ignore
      this.reset();
    });
  }
}

export interface ElrondSessionInfo{
  /**
   * How many books does the user currently have checked out
   */
  checkedOut: Number;
}

export interface GoogleAnalyticsInfo{
  /**
   * In Google Analytics, a property is a website, mobile app, etc, that is associated with a unique tracking ID
   */
  property: string;
  /**
   * A view is a set of specifications for tracking traffic on a single domain
   * @example store.test.com, and blog.test.com would each have their own unique view IDs
   */
  view: string;
}

export interface ElrondGTMDataLayer {
  /**
   * The account id based on OverDrive's system e.g. "56789"
   */
  accountId: string;
  session: ElrondSessionInfo;
  /**
   * The environment the application is running in
   * @example Alpha, Beta, Charlie
   */
  environment: string;
  /**
   * The platform the application is running on
   * @example Web, iOS, Android
   */
  ga: GoogleAnalyticsInfo;
  platform: string;
  library: Library;
}

export interface ElrondPageView {
  path: string;
  title: string;
  hash: string;
  query: string;
  pageName: string;
}
