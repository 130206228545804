import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnnotationCard = _resolveComponent("AnnotationCard")!

  return (_openBlock(), _createElementBlock("ol", {
    class: _normalizeClass(_ctx.$style.list)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.annotations, (annotation, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: annotation.uuid
      }, [
        _createVNode(_component_AnnotationCard, {
          annotation: annotation,
          selectable: _ctx.selectable,
          hideNote: _ctx.hideNote,
          "onAnnotation:update": (a, checked) => _ctx.updateAnnotation(index, a, checked)
        }, null, 8, ["annotation", "selectable", "hideNote", "onAnnotation:update"])
      ]))
    }), 128))
  ], 2))
}