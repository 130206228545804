import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Collation } from 'app/models/collation';
import { Tagging } from './tagging';
import { Tags } from './tags';

export class Tag extends Collation<Tagging> {
  public static SORT_FUNCTIONS = {
    /**
     * Sort tags alphabeticaly
     */
    alpha: (a: Tag, b: Tag) => {
      if (!a?.name) { return -1; }
      if (!b?.name) { return 1; }

      const emojiRegex = /\p{Emoji_Presentation}/gu;

      const aWithoutEmoji = a.name.replace(emojiRegex, '').trim();
      const bWithoutEmoji = b.name.replace(emojiRegex, '').trim();

      return aWithoutEmoji.localeCompare(bWithoutEmoji);
    },
    /**
     * Sort tags by most recent (using timestamp)
     */
    recent: (a: Tag, b: Tag) => {
      if (!a?.timestamp) { return -1; }
      if (!b?.timestamp) { return 1; }

      return b.timestamp - a.timestamp;
    }
  };

  public static FILTER_FUNCTIONS = {
    /**
     * Returns all non-system tags.
     */
    nonSystemTags: (value: Tag) => {
      return (APP.patron.tags.SYSTEM_TAGS.indexOf(value.name) < 0);
    },
    /**
     * Returns only system tags
     */
    systemTags: (value: Tag) => {
      return (APP.patron.tags.SYSTEM_TAGS.indexOf(value.name) >= 0);
    }
  };

  public static readonly MAX_LENGTH = 50;

  /**
   * globally-unique string identifier for the tag -- too ugly
   * to be shown to the user, but perfect for avoiding conflicts on sync.
   */
  public uuid: string;

  /**
   * Untrusted, user-entered tag name.
   */
  public name: string;

  public get lowercaseName(): string {
    return this.name ? this.name.toLowerCase() : '';
  }

  public slug: string;

  protected flags = {
    /**
     * Determines if the tag is locked or not. If the tag is locked, it
     * cannot be deleted, renamed, or cleared of contents.
     */
    isLocked: false,
    /**
     * Determines if an svg icon should be used in place of the tag name
     */
    useIcon: false,
    /**
     * Determines if the tag label is editable or not.
     */
    isRenameable: true
  };

  protected ITEM_CLASS = Tagging;
  protected ITEM_NAME = 'tagging';
  protected DEFAULT_SLUG = 'tag';
  protected PERSISTENT = false;
  protected timestamp: number;
  protected collation: Tags;


  public christen(name: string) {
    if (!Tag.isValid(name)) {
      console.error('[TAG] Bad tag name', name);

      return false;
    }

    this.name = name;
    this.slug = this.toTagSlug(name);

    return true;
  }


  /**
   * Rename tag
   * @param name new tag name
   */
  public async rename(name: string): Promise<void> {
    if (!this.flags.isRenameable || this.flags.useIcon) {
      return;
    }

    const previousName = this.name;
    // Check if new name is the same as previous name
    if (!Tags.SAME_NAME(name, previousName)) {
      const wasSuccessful = this.christen(name);
      if (!wasSuccessful) { return; }
    } else {
      this.name = name;
    }

    if (previousName !== this.name) {
      // Don't use icon after rename
      this.flags.useIcon = false;
      const publishMessage = await this.publishMessage();
      if (publishMessage) {
        this.collation.recordOperation('renameTag', publishMessage);
        APP.scribe.publish('tag.rename-tag', publishMessage);
        this.save(true);
        APP.tracking.log('tag_renamed', {
          tag_uuid: this.uuid
        });
      }
    }
  }


  public async delete(): Promise<void> {
    this.timestamp = C.epochMilliseconds();
    const publishMessage = await this.publishMessage();
    if (publishMessage) {
      this.collation.recordOperation('removeTag', publishMessage);
      APP.scribe.publish('tag.remove-tag', publishMessage);
      APP.patron.tags.removeItem(APP.patron.tags.find({ slug: this.slug }));
      APP.tracking.log('tag_deleted', {
        tag_uuid: this.uuid
      });
    }
  }


  /**
   * Check if tag is already applied to title
   * @param title
   */
  public isAppliedToTitle(titleSlug: string): boolean {
    if (!titleSlug) { return undefined; }

    return this.find({ titleSlug: titleSlug }) ? true : false;
  }


  public isRenameable(): boolean {
    return this.flags ? (this.flags.isRenameable &&
      !this.flags.isLocked &&
      !this.flags.useIcon) : true;
  }


  /**
   * Is this a locked/system tag
   */
  public isLocked(): boolean {
    return this.flags ? !!this.flags.isLocked : false;
  }


  public useIcon(): boolean {
    return this.flags ? !!this.flags.useIcon : false;
  }


  /**
   * Add tag to title
   * @param title title to apply tag to
   * @param isAssigned
   */
  public async addToTitle(titleSlug: string, isAssigned = false): Promise<void> {
    if (!this.isAppliedToTitle(titleSlug)) {
      const tagging = this.make({ titleSlug: titleSlug, isAssigned: isAssigned });
      const publishMessage = await this.publishMessage(tagging.serialize());
      if (publishMessage) {
        this.collation.recordOperation('addTagging', publishMessage);
        APP.scribe.publish('tag.add-tagging', publishMessage);
      }
    } else {
      const tagging = this.find({ titleSlug: titleSlug });
      tagging.createTime = C.epochMilliseconds();
      const publishMessage = await this.publishMessage(tagging.serialize());
      if (publishMessage) {
        this.collation.recordOperation('addTagging', publishMessage);
        APP.scribe.publish('tag.add-tagging', publishMessage);
      }
    }
    this.save(true);
  }


  /**
   * Remove tag from title
   * @param title title to remove from tag
   */
  public async removeFromTitle(titleSlug: string): Promise<void> {
    const item = this.find({ titleSlug: titleSlug });
    if (item) {
      this.timestamp = C.epochMilliseconds();
      this.removeItem(item);
      const publishMessage = await this.publishMessage(item.serialize());
      if (publishMessage) {
        this.collation.recordOperation('removeTagging', publishMessage);
        APP.scribe.publish('tag.remove-tagging', publishMessage);
      }
    }
  }


  public save(dispatchEvent?: boolean) {
    this.timestamp = C.epochMilliseconds();
    this.collation.save();
    if (dispatchEvent) {
      APP.events.dispatch('tag:updated', { item: this });
    }
  }


  public async publishMessage(tagging?: any) {
    // Sometimes, the account id is null.
    // In that case, we log to sage,
    // try to refresh the account id,
    // and try again.
    if (!APP.patron.accountId) {
      APP.sage.submit('error', {
        errorMessage: 'APP.patron.accountId is null',
        errorSource: 'Tag#publishMessage',
        submissionContext: {
          library: APP.library
        }
      });

      try {
        await APP.sentry.fetchIdentityToken();
      } catch (e) {
        console.error('[TAG] Could not fetch identity token to set account id.');
      }
    }

    if (!APP.patron.accountId) {
      APP.sage.submit('error', {
        errorMessage: 'APP.patron.accountId is null after retry',
        errorSource: 'Tag#publishMessage',
        submissionContext: {
          library: APP.library
        }
      });

      return null;
    }

    const result: TagPublishMessage = {
      tagCollectionUUID: APP.patron.accountId,
      tag: this._serializeAttributes()
    };

    if (tagging) {
      result.tagging = tagging;
      tagging.slug = tagging.titleSlug;
    }

    return result;
  }


  /**
   * @desc Get the url of this tag.
   */
  public path(): string {
    return `tags/${C.encode(this.slug, C.EncodingContext.UriComponent)}`;
  }


  /**
   * @desc Lets our activity manager (and anyone else listening) that a tag has been viewed
   */
  public viewedTag(): void {
    this.save(false); // Update lastUpdated, but don't raise the alarm...yet.
    APP.events.dispatch('tag:view', { item: this });
  }


  public static isValid(name: string) {
    if (!name) { return false; }
    if (APP.patron.tags.isSystemTagName(name)) { return false; }
    if (APP.patron.tags.isExistingTagName(name)) { return false; }

    return C.unicodeLength(name) <= Tag.MAX_LENGTH;
  }


  protected toTagSlug(name: string) {
    const slugify = (val: string) => val.trim().replace(/[^\w]+/g, '-').replace(/^-+|-+$/g, '').toLowerCase();
    const isDuplicate = (candidate: string) => !!APP.patron.tags.find({ slug: candidate });

    const root = slugify(name) || this.DEFAULT_SLUG;
    let attempt = 0;
    let slug = root;
    while (isDuplicate(slug)) {
      attempt++;
      slug = slugify(`${root}-${attempt}`);
    }

    return slug;
  }


  protected _serializeAttributes() {
    const attrs = super._serializeAttributes();
    attrs.name = this.name;
    attrs.slug = this.slug;
    attrs.uuid = this.uuid;
    attrs.timestamp = this.timestamp;
    attrs.flags = this.flags;

    return attrs;
  }


  protected _transformRemoteAttributes(attrs): any {
    if (attrs.taggings) {
      const taggings = C.excise(attrs, 'taggings');
      attrs.all = taggings.items;
    }
    if (attrs.tagUUID) {
      attrs.uuid = C.excise(attrs, 'tagUUID');
    }

    return attrs;
  }


  protected _deserializeAttributes(attrs): void {
    this.name = attrs.name;
    this.slug = attrs.slug || '';
    this.uuid = attrs.uuid || C.generateUUID();
    this.timestamp = attrs.timestamp || C.epochMilliseconds();
    if (attrs.flags) {
      this.flags = {
        isLocked: !!attrs.flags.isLocked,
        isRenameable: (!attrs.flags.isLocked
          // Locked lists not-renamable
          // Only not-renameable if explicitly set as such
          && (typeof (attrs.flags.isRenameable) !== 'boolean' || attrs.flags.isRenameable)),
        useIcon: !!attrs.flags.useIcon
      };
    } else {
      this.flags = {
        isLocked: false,
        isRenameable: true,
        useIcon: false
      };
    }
  }


  protected _makingItem(tagging: Tagging) {
    tagging.createTime = C.epochMilliseconds();
  }


  protected _fullAttributesToQuery(itemAttributes): any {
    return {
      titleSlug: itemAttributes.title.titleId
    };
  }
}

export class TagMapper {
  public static mapFromBank(bankedTag: BankedTag): Tag {
    if (!bankedTag) {
      return null;
    }

    return new Tag().deserialize(bankedTag);
  }
}

export interface BankedTag {
  slug: string;
  name: string;
  uuid: string;
  timestamp: number;
  lastUpdated: number;
}

export interface TagPublishMessage {
  tagCollectionUUID: string;
  tag: any;
  tagging?: any;
}
