<template>
  <article :class="$style.notFound">
    <h1 :class="$style.header">
      {{ header }}
    </h1>
    <slot>
      <p :class="$style.message">
        {{ message }}
      </p>
      <a
        :class="$style.link"
        :href="anchor"
      >
        {{ link }}
      </a>
    </slot>
  </article>
</template>

<script lang="ts">
import { Constants } from 'app/base/constants';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'NotFound',
  props: {
    headerText: {
      type: String,
      default: undefined
    },
    messageText: {
      type: String,
      default: undefined
    },
    linkText: {
      type: String,
      default: undefined
    },
    linkAnchorHref: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();
    const header = computed(() => props.headerText || t('error.route.header'));
    const message = computed(() => props.messageText || t('error.route.invalid'));
    const link = computed(() => props.linkText || t('general.needHelp'));
    const anchor = computed(() => props.linkAnchorHref || Constants.HELP_PATHS.GET_STARTED);

    onMounted(() => {
      announceMessage(header.value);
    });

    return {
      header,
      message,
      link,
      anchor
    };
  }
});

</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  .not-found {
    width: 75%;
    top: 75px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    color: @c-medium-black;

    .header {
      font-weight: bold;
      font-size: @fs-medium-head;
    }

    .message {
      margin: 5px;
    }

    .link {
      margin: 5px;
      .pop(@c-primary-blue);
      color: @c-primary-blue;
    }
  }
</style>
