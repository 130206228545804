<template>
  <PopoutDialog
    :reference="reference"
    :headerLabel="headerLabel"
    placement="bottom-start"
    @close="$emit('close')"
  >
    <div v-if="subHeaderLabel">
      {{ subHeaderLabel }}
    </div>
    <ul>
      <li
        v-for="(option, index) in options"
        :key="index"
        :class="$style.option"
      >
        <slot
          v-if="'slotName' in option"
          class="focus-outline"
          :name="option.slotName"
        >
        </slot>
        <template v-else>
          <Icon
            v-if="option.iconName"
            :name="option.iconName"
          />
          <button
            v-if="'handler' in option"
            :class="{
              ['disabled']: option.inactive
            }"
            :disabled="option.inactive"
            :aria-haspopup="option.hasPopup"
            @click="option.handler"
          >
            {{ $t(option.label) }}
          </button>
          <router-link
            v-else
            class="focus-outline"
            :to="option.location"
          >
            {{ $t(option.label) }}
          </router-link>
        </template>
      </li>
    </ul>
  </PopoutDialog>
</template>

<script lang='ts'>
import PopoutDialog from 'app/components/dialogs/PopoutDialog.vue';
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';


/**
 * Base context menu option properties.
 */
export type BaseContextMenuOption = {
  /**
   * Label to display for the option
   */
  label: string;

  /**
   * Name of the icon to display alongside the option label.
   * Used with the Icon component.
   * If absent, no icon will be displayed.
   * @example copy-to-clipboard
   */
  iconName?: string;

  /**
   * Option is not clickable, is grayed out.
   */
  inactive?: Boolean;
};

/**
 * The context menu component button option, used to render a standard button
 * option that emits click events
 */
export type ContextMenuButtonOption = BaseContextMenuOption & {
  /**
   * The handler function for an option click.
   */
  handler: Function;

  /**
   * Optionally attaches an aria-haspopup attribute to the button
   */
  hasPopup?: boolean | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';
};

/**
 * The context menu component link option, used to render a standard link
 * option that redirects to a specified location
 */
export type ContextMenuLinkOption = BaseContextMenuOption & {
  /**
   * The location to redirect to on option click.
   */
  location: RouteLocationRaw;
};

export type ContextMenuSlotOption = {
  slotName: string;
};

/**
 * The context menu component option, used to render the standard options of the menu
 */
export type ContextMenuOption = ContextMenuButtonOption | ContextMenuLinkOption | ContextMenuSlotOption;

/**
 * @desc - The base context menu component
 */
export default defineComponent({
  components: {
    PopoutDialog
  },
  props: {
    /**
     * @param reference - The element to anchor this context menu to
     */
    reference: {
      type: HTMLElement,
      required: true
    },
    /**
     * @param options - The options to display in the context menu
     */
    options: {
      type: Array as PropType<ContextMenuOption[]>,
      required: true
    },
    /**
     * @param headerLabel - Label for the context menu
     */
    headerLabel: {
      type: String,
      required: true
    },
    /**
     * @param subHeaderLabel - Optional sub heading for the context menu
     */
     subHeaderLabel: {
      type: String,
      default: undefined,
      required: false
    }
  },
  emits: [
    'close'
  ]
});
</script>

<style module>
.option {
  align-items: center;
  display: flex;
  margin: 0.25rem 0;
}

.option svg {
  margin-right: 0.625rem;
  width: 1rem;
}

.option a, .option button {
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  font-size: var(--fs-metadata);
  padding: 0.25rem;
}
</style>
