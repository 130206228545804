<template>
  <div
    ref="container"
    :class="{ [$style.full]: fullHeight, [$style.container]: true }"
  >
    <button
      v-if="!fullHeight"
      :class="$style.showAll"
      @click="showAll"
    >
      {{ $t('title.showAll') }}
    </button>

    <div :class="$style.text">
      <slot></slot>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, nextTick, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'ShowAll',
  setup: (_, ctx) => {
    const container = ref<HTMLElement | null>(null);
    const fullHeight = ref(false);

    const maxHeight =
      20 // lines
      * 1.5 // line-height
      * 16; // font-size

    onMounted(async () => {
      if (!container.value) { return; }

      await nextTick();
      if (container.value.scrollHeight <= maxHeight) {
        fullHeight.value = true;
      }
    });

    const showAll = () => fullHeight.value = true;

    return {
      container,
      fullHeight,
      showAll
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

// If you change this height, you also need to change the logic above
@em-body-max-height: 20em * @lh-body;

.container {
  position: relative;
  overflow: hidden;
  max-height: @em-body-max-height;
}

.container:not(.full):before {
  content: '';
  pointer-events: none;
  width: 100%;
  height: 0.4 * @em-body-max-height;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    @safari-transparent,
    @c-lightest-gray 80%,
    @c-lightest-gray 100%);
}

.container.full {
  max-height: unset;
}

.show-all {
  z-index: 1;
  position: absolute;
  bottom: 0.5em;
  background: @c-lightest-gray;
  text-align: center;
  .pop-text(@c-primary-blue);
  color: @c-primary-blue;
  cursor: pointer;
  margin: 0 0.25rem;
  padding: 0.25rem;
}
</style>
