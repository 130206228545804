<template>
  <form
    :class="$style.form"
    @submit.prevent="onSubmit"
  >
    <p>{{ $t('gdpr.modal.intro') }}</p>

    <FormCheckbox
      modelValue="on"
      :label="$t('gdpr.modal.requiredCookies.header')"
      :showLabel="true"
      :disabled="true"
      :description="$t('gdpr.modal.requiredCookies.explanation')"
    />

    <FormCheckbox
      v-model="checkbox"
      :label="$t('gdpr.modal.researchCookies.header')"
      :showLabel="true"
      :description="$t('gdpr.modal.researchCookies.explanation')"
    />

    <div :class="$style.links">
      <p
        v-html="$t('gdpr.modal.learnMoreAboutCookies', {
          a: (val) => `<a class='linked focus-outline' href='${cookiePolicyPath}' target='_blank'>${val}</a>`
        })"
      ></p>

      <p
        v-html="$t('gdpr.modal.learnMoreAboutPrivacy', {
          a: (val) => `<a class='linked focus-outline' href='${privacyPolicyPath}' target='_blank'>${val}</a>`
        })"
      ></p>
    </div>

    <FormSubmitButton
      :label="$t('gdpr.modal.confirm')"
      size="small"
    />
  </form>
</template>

<script lang="ts">
import { Constants } from 'app/base/constants';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'GdprForm',
  components: {
    FormSubmitButton,
    FormCheckbox
},
  props: {
    defaultSetting: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'submit'
  ],
  setup: (props, ctx) => {
    const setting = ref(props.defaultSetting);

    const checkbox = computed({
      get: () => setting.value ? 'on' : 'off',
      set: (val: string) => {
        setting.value = val === 'on';
      }
    });

    const onSubmit = () => {
      ctx.emit('submit', setting.value);
    };

    return {
      checkbox,
      cookiePolicyPath: Constants.COOKIE_POLICY_PATH,
      privacyPolicyPath: Constants.PRIVACY_POLICY_PATH,
      onSubmit,
      setting
    };
  }
});
</script>

<style module>
.form,
.form > * + *:not(p) {
  margin-top: 20px;
}

.form label {
  font-weight: var(--fw-bold);
}

.links {
  color: var(--c-light-black);
  font-size: var(--fs-metadata);
}

.links a {
  white-space: nowrap;
}

.form button {
  margin-left: auto;
  display: block;
}

@media screen and (max-width: 499px /* @px-vp-wide */) {
  .form button {
    width: 100%;
  }
}
</style>
