import { APP } from 'app/base/app';
import { ODFirebase } from 'app/base/tracking/od-firebase';
import { AnalyticsEventName } from 'app/keys/analytics-keys';

/**
 * All things analytics
 * @see {@link https://firebase.google.com/docs/analytics}
 */
export class Analytics {
  protected readonly _firebase: ODFirebase;

  /**
   * When true, calls to log() will also output to the console.
   * @default Defaults to true when analytics configuration is invalid.
   */
  protected readonly _localMode: boolean = false;


  /**
   * Initialize our analytics instance
   * @param logToConsole True to log analytics to console
   */
  constructor(firebase: ODFirebase, logToConsole = false) {
    this._firebase = firebase;
    this._localMode = logToConsole;
  }


  /**
   * Log an event to the analytics database
   * @param eventName Name of the event to log.
   * @param eventParams A flat object of properties to send to analytics.
   * Param names can be up to 40 characters long,
   * may only contain alphanumeric characters and underscores ("_"),
   * and must start with an alphabetic character.
   * Param values can be up to 100 characters long.
   * @see {@link https://developers.google.com/gtagjs/reference/event}
   */
  public log(eventName: AnalyticsEventName, eventParams: { [key: string]: any } = {}): void {
    if (this._localMode) {
      console.log(`[ANALYTICS] ${eventName}`, eventParams || '');

      return;
    }

    if (APP.library) {
      eventParams.libraryID = APP.library.websiteId;
      eventParams.libraryName = APP.library.name;
      if (APP.shell.info) {
        eventParams.platformVersion = APP.shell.info.platform || 'Web';
      }
    }

    this._firebase.logAnalyticsEvent(eventName, eventParams);
  }


  /**
   * Set user information for the currently active user
   * @param properties User properties to set
   * @see {@link https://firebase.google.com/docs/analytics/user-properties}
   */
  public setUserProperty(name: string, value: string): void {
    if (!name || !value) {
      return;
    }

    this._firebase.setAnalyticsUserProperty(name, value);
  }


  /**
   * Set user information for the currently active user
   * @param properties User properties to set
   * @see {@link https://firebase.google.com/docs/analytics/user-properties}
   */
  public setUserId(userId: string): void {
    this._firebase.setAnalyticsUserId(userId);
  }
}
