<template>
  <form
    ref="combobox"
    :class="[$style.searchBar, mobile ? $style.mobile : '']"
    role="combobox"
    :aria-expanded="popupShown"
    :aria-owns="popupId"

    aria-haspopup="grid"

    @submit.prevent="submit"
  >
    <div
      ref="searchContainer"
      :class="$style.searchContainer"
    >
      <Icon
        v-if="!mobile"
        :class="$style.searchIcon"
        name="search"
      />

      <input
        id="search-box"
        ref="input"
        v-model="inputQuery"

        type="text"
        :class="[$style.searchBox, 'focus-outline']"

        :placeholder="$t('search.placeholder')"
        autocomplete="off"
        role="searchbox"
        aria-autocomplete="none"
        :aria-controls="popupId"

        :aria-activedescendant="activeId"
        :aria-label="$t('search.inputLabel')"

        @focus="onFocus"
        @blur="onBlur"
        @keydown.space="onSpace"
        @keydown.enter="onEnter"
        @keydown.esc="onEsc"
        @keydown.up="onUp"
        @keydown.down="onDown"

        @keydown.left="onLeft"
        @keydown.right="onRight"
      />

      <button
        v-if="showClear"
        ref="clearButton"
        type="button"
        :class="$style.clearIconButton"

        :aria-label="$t('search.clearButton')"
        @focus="onFocus"

        @blur="onBlur"
        @click="clearInput"
      >
        <Icon name="dismiss" />
      </button>
    </div>

    <button
      type="submit"
      :class="$style.searchButton"
    >
      {{ $t('search.searchButton') }}
    </button>

    <section
      v-if="popupShown"
      :id="popupId"
      ref="popup"
      :class="$style.suggestions"

      role="grid"

      tabindex="-1"

      @focus="onPopupFocus"
      @blur="onBlur"
    >
      <h1>{{ $t('search.recentHeader') }}</h1>
      <ol
        :class="$style.list"
        role="rowgroup"
      >
        <li
          v-for="row in rows"
          :key="row.query"
          :class="$style.suggestion"
          role="row"
        >
          <Icon
            :class="$style.listRowIndicator"
            name="search"
          />
          <button
            :id="row.buttons.text.id"
            :class="[$style.queryButton, row.buttons.text.active ? $style.activeCell : '']"
            type="submit"
            tabindex="-1"
            :aria-selected="row.buttons.text.active"
            role="gridcell"
            @mousedown.prevent="inputQuery = row.query"
          >
            {{ row.query }}
          </button>
          <button
            :id="row.buttons.delete.id"
            type="button"
            :class="[$style.deleteButton, row.buttons.delete.active ? $style.activeCell : '']"
            tabindex="-1"
            role="gridcell"
            :aria-label="$t('search.deleteButton', { query: row.query })"
            :aria-selected="row.buttons.delete.active"
            @mousedown.prevent="() => removeOption(row.query)"
          >
            <Icon name="trashcan" />
          </button>
        </li>
      </ol>
    </section>
  </form>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { usePatron } from 'app/functions/use-patron';
import { RouteName } from 'app/router/constants';
import router from 'app/router/router';
import { generateUUID } from 'lib/common/uuid';
import { computed, defineComponent, ref, watch, watchEffect } from 'vue';

enum PopupState {
  Hidden,
  Shown,
  Active
};

type Cell = {
  id: string;
  active: boolean;
  onSpace?: (event: KeyboardEvent) => void;
  onEnter?: (event: KeyboardEvent) => void;
};

type Row = {
  id: string;
  active: boolean;
  query: string;
  buttons: {
    text: Cell;
    delete: Cell;
  };
};

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'search',
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const { searchHistory } = usePatron();
    const recentSearches = computed(() => searchHistory.value.slice(0, 5));

    const popupState = ref<PopupState>(PopupState.Hidden);
    const popupShown = computed(() => recentSearches.value.length > 0 && (props.mobile || popupState.value >= PopupState.Shown));
    const inPopup = computed(() => popupShown.value && popupState.value >= PopupState.Active);

    const combobox = ref<HTMLElement | null>(null);
    const searchContainer = ref<HTMLElement | null>(null);

    const input = ref<HTMLInputElement | null>(null);
    const inputQuery = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });
    const showClear = computed(() => inputQuery.value.length > 0);

    const popup = ref<HTMLElement | null>(null);
    const popupId = generateUUID();

    const clearButton = ref<HTMLElement | null>(null);

    const _focusRowIndex = ref(0);
    const focusRowIndex = computed({
      get: () => _focusRowIndex.value,
      set: (value) => {
        if (value < 0) {
          _focusRowIndex.value = 0;
        } else if (value >= recentSearches.value.length) {
          _focusRowIndex.value = Math.max(0, recentSearches.value.length - 1);
        } else {
          _focusRowIndex.value = value;
        }
      }
    });


    const focusCol = ref<keyof Row['buttons']>('text');

    const rows = computed<Row[]>(() => recentSearches.value.map((query, i) => {
      return {
        id: `${popupId}-${i}`,
        active: inPopup.value && focusRowIndex.value === i,
        query,
        buttons: {
          text: {
            id: `${popupId}-${i}-text`,
            active: inPopup.value && focusRowIndex.value === i && focusCol.value === 'text'
          },
          delete: {
            id: `${popupId}-${i}-delete`,
            active: inPopup.value && focusRowIndex.value === i && focusCol.value === 'delete',
            onSpace: (event: KeyboardEvent) => {
              event.preventDefault();
              removeOption(query);
            },
            onEnter: (event: KeyboardEvent) => {
              event.preventDefault();
              removeOption(query);
            }
          }
        }
      };
    }));
    watchEffect(() => {
      if (rows.value.length <= 0) {
        popupState.value = PopupState.Hidden;
      }
    });

    const activeCell = computed(() => rows.value[focusRowIndex.value]?.buttons[focusCol.value]);
    const activeId = computed(() => inPopup.value && activeCell.value?.id);

    const savedQuery = ref('');
    watch(inPopup, () => {
      if (inPopup.value) {
        savedQuery.value = inputQuery.value;
        inputQuery.value = rows.value[focusRowIndex.value].query;
      } else {
        inputQuery.value = savedQuery.value;
      }
    });
    watch(focusRowIndex, () => {
      if (inPopup.value) {
        inputQuery.value = rows.value[focusRowIndex.value].query;
      }
    });

    // Event handlers

    const onFocus = () => {
      popupState.value = PopupState.Shown;
    };

    const onBlur = (event: FocusEvent) => {
      if (![searchContainer.value, popup.value].some((el) => el && el.contains(event.relatedTarget as Node))) {
        popupState.value = PopupState.Hidden;
      }
    };

    const onPopupFocus = () => input.value?.focus();

    // Key handlers

    const onSpace = (event: KeyboardEvent) => {
      if (inPopup.value) {
        activeCell.value?.onSpace?.(event);
      }
    };

    const onEnter = (event: KeyboardEvent) => {
      if (inPopup.value && activeCell.value.onEnter) {
        activeCell.value.onEnter(event);
      }
    };

    const onEsc = () => {
      popupState.value = PopupState.Hidden;
    };

    const onUp = (event: KeyboardEvent) => {
      if (inPopup.value) {
        if (focusRowIndex.value === 0) {
          popupState.value = PopupState.Shown;
        } else {
          focusRowIndex.value--;
        }
        event.preventDefault();
      }
    };

    const onDown = (event: KeyboardEvent) => {
      if (inPopup.value) {
        focusRowIndex.value++;
        event.preventDefault();
      } else {
        popupState.value = PopupState.Active;
        focusRowIndex.value = 0;
        focusCol.value = 'text';
      }
    };

    const onLeft = (event: KeyboardEvent) => {
      if (inPopup.value) {
        focusCol.value = 'text';
        event.preventDefault();
      }
    };

    const onRight = (event: KeyboardEvent) => {
      if (inPopup.value) {
        focusCol.value = 'delete';
        event.preventDefault();
      }
    };

    // Actions

    const submit = () => {
      const trimmedQuery = inputQuery.value.trim();
      if (trimmedQuery.length) {
        APP.patron.searchHistory.add(trimmedQuery);

        savedQuery.value = trimmedQuery;
        popupState.value = PopupState.Hidden;

        const newRoute = {
          name: RouteName.Search,
          query: {
            query: C.encode(trimmedQuery)
          }
        };
        router.push(newRoute);

        ctx.emit('search');
      } else {
        input.value?.focus();
      }
    };

    const clearInput = () => {
      inputQuery.value = '';
      input.value?.focus();
    };

    const removeOption = (query: string) => {
      APP.patron.searchHistory.remove(query);
      input.value?.focus();
    };

    return {
      activeId,
      clearButton,
      clearInput,
      combobox,
      inPopup,
      input,
      inputQuery,
      onBlur,
      onDown,
      onEnter,
      onEsc,
      onFocus,
      onLeft,
      onRight,
      onPopupFocus,
      onSpace,
      onUp,
      popup,
      popupId,
      popupShown,
      removeOption,
      rows,
      searchContainer,
      showClear,
      submit
    };
  }
});
</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  .search-bar {
    background-color: var(--c-dark-blue);
    border-radius: @px-border-radius;
    display: grid;
    grid-template-columns: 1fr auto;
    position: relative;
    border-radius: .5rem;
  }

  .search-container {
    position: relative;
  }

  .search-icon {
    fill: @c-white;
    height: 1.75rem;
    left: 0;
    line-height: 0;
    padding: 0.5rem;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 1.75rem;
  }

  .search-box {
    .placeholder(@c-white, 75%);
    background: transparent;
    border: none;
    box-sizing: border-box;
    color: @c-white;
    font-family: @ff-sans;
    font-size: @fs-med;
    font-weight: @fw-medium;
    line-height: 1.75rem;
    padding: 0.5rem 2.75rem;
    width: 100%;
  }

  .clear-icon-button {
    box-sizing: border-box;
    height: 2.75rem;
    padding: 0.75rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 2.75rem;

    svg {
      fill: @c-gray;
      height: 100%;
      width: 100%;
    }
  }

  .search-button {
    background: @c-primary-red;
    border-radius: 0 @px-border-radius @px-border-radius 0;
    border: none;
    color: @c-white;
    font-family: @ff-sans;
    font-size: @fs-body;
    font-weight: @fw-medium;
    padding: 0.5em 1em;
    text-shadow: none;
    text-transform: uppercase;
  }

  .suggestions {
    background-color: var(--c-white);
    border-radius: .5rem;
    box-shadow: 0px 10px 15px 5px rgba(0,0,0,0.5);
    box-sizing: border-box;
    color: var(--c-dark-black);
    font-size: @fs-med;
    padding: 1em 2em;
    position: absolute;
    top: calc(100% + 0.5rem + 2px);
    width: 100%;

    @media screen and (max-width: @px-vp-hide-nav) {
      background-color: var(--c-darkest-blue);
      color: var(--c-white);
      border-radius: 0;
      padding: 1rem;
      margin-top: 1rem;
    }

  }

  .suggestion {
    // Prevents wild text underlines from appearing after deleting a row
    // https://stackoverflow.com/questions/28511539/the-underlying-magic-of-webkit-backface-visibility/28512658#28512658
    backface-visibility: hidden;

    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: auto auto auto 1fr;
    line-height: 1;
    margin: 0.75rem 0.25rem;
    overflow-wrap: anywhere;
  }

  .list-row-indicator {
    fill: @c-gray;
    height: 1rem;
    width: 1em;
  }

  .activeCell {
    .standard-outline;
    padding: 0.125rem;

    @media screen and (max-width: @px-vp-hide-nav) {
      .standard-outline-dark;
    }

  }

  .query-button,
  .delete-button {
    color: inherit;
    padding: 0.125rem 0;
    text-align: left;
  }

  .query-button {
    .pop-text(@c-gray);
    line-height: @lh-body;
  }

  .delete-button {
    border-bottom: none;

    svg {
      fill: @c-gray;
      height: 1rem;
      line-height: 0;
      width: 1rem;
    }
  }

  .search-bar.mobile {
    padding: 0.5rem;

    .search-box, button {
      .focus-outline-dark;
    }

    .search-box {
      padding: 0.5rem 2.75rem 0.5rem 0.5rem;
    }

    .suggestions {
      height: calc(100vh - 100%);
      left: -3.875rem;
      padding: 0.5rem 1rem;
      top: calc(100% + 2px + 0.5rem);
      width: 100vw;
    }

    .suggestion {
      grid-template-columns: auto 1fr auto;
    }

    .delete-button {
      @media screen and (max-width: @px-vp-tablet) {
        margin-left: auto;
      }
    }
  }
</style>
