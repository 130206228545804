<template>
  <div
    :id="id"
    :class="$style.content"
  >
    <p>
      {{ $t('annotationCopying.prompt.copy.intro', { TITLE: targetName }) }}
    </p>
    <p v-html="copyQuestion"></p>
    <p :class="$style.additional">
      {{ $t('annotationCopying.prompt.copy.additional') }}
    </p>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'app/functions/use-i18n';
import { getReleaseDisplayNameTitle } from 'app/functions/use-release-display-name';
import { Annotation } from 'app/models/annotation';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CopyContent',
  props: {
    id: {
      type: String,
      required: true
    },
    targetTitle: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    sourceTitle: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    sourceAnnotations: {
      type: Array as PropType<Annotation[]>,
      required: true
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    const targetRelease = computed(() => getReleaseDisplayNameTitle(props.targetTitle));
    const sourceRelease = computed(() => getReleaseDisplayNameTitle(props.sourceTitle));
    const targetName = computed(() => props.targetTitle.title);

    const copyQuestion = computed(() => {
      return t('annotationCopying.prompt.copy.question',
        {
          NUMBER: props.sourceAnnotations.length,
          SOURCE: sourceRelease.value,
          TARGET: targetRelease.value,
          strong: (val: string) => { return `<strong>${val}</strong>`; }
        }
      );
    });

    return {
      copyQuestion,
      sourceRelease,
      targetName
    };
  }
});
</script>

<style module>
.content {
  display: grid;
  row-gap: 1.5rem;
}

.additional {
  color: var(--c-light-black);
}
</style>
