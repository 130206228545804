import { C, Dictionary } from 'app/base/common';
import { Constants } from 'app/base/constants';

export class PartialView<T extends BasePart = any> {
  protected _parts: T;


  /**
   * Feel free to override this if you don't need impart in the
   * constructor and you want to use the constructor for other things...
   * @param container Containing element that partial should be added to
   */
  constructor(container?: HTMLElement) {
    if (container) {
      this.impart(container);
    }
  }


  // ## PUBLIC METHODS

  /**
   * Create necessary "parts" attributes and append to main page
   */
  public impart(container?: Element): T {
    return this._prepare(container);
  }


  // ## PROTECTED METHODS

  protected _prepare(container?: Element): T {
    if (!this._parts) {
      this._parts = <T>{ box: C.element() };
      this._layoutPartial(this._parts);
      if (container) {
        container.appendChild(this._parts.box);
      }
      this._listenPartial(this._parts);
    } else {
      if (container) {
        container.appendChild(this._parts.box);
      }
    }

    return this._parts;
  }


  protected _layoutPartial(parts: T): void {
    // Implement in subclasses, appending elements to parts.box
  }


  protected _listenPartial(parts: T): void {
    // Implement in subclasses
  }


  public static subText(elem: Element, substitutions: Dictionary<string | number | HTMLElement>): void {
    C.each(substitutions, (label, value) => {
      if (typeof value === 'undefined' || value === undefined || value === null) {
        return;
      }

      const normalized = typeof value === 'number' ? value.toString() : value;

      let subelems = elem.querySelectorAll('[data-sub="' + label + '"]');
      C.each(subelems, (subelem) => {
        if (typeof normalized === 'string') {
          subelem.innerHTML = normalized;
        } else {
          subelem.appendChild(normalized);
        }
      });
      subelems = elem.querySelectorAll('[data-sub="^' + label + '"]');
      C.each(subelems, (subelem) => {
        if (typeof normalized === 'string') {
          subelem.innerHTML = C.capitalize(normalized);
        } else {
          subelem.appendChild(normalized);
        }
      });
    });
  }


  /**
   * Return text associated with key from the resource file
   * - Will return '{{ missing text: label }}' key doesn't exist in file
   *
   * @param label Key value to lookup in resource file
   */
  public static text(label: string, substitutions?: Dictionary<string | number | HTMLElement>): string {
    const txt = Constants.text(label);
    if (!C.isString(txt)) {
      return C.encode('{{missing text: ' + label + '}}');
    }

    if (!substitutions) {
      return txt;
    }

    const elem = C.element({ html: txt });
    PartialView.subText(elem, substitutions);

    return elem.textContent;
  }
}

export interface BasePart {
  box: HTMLElement;
}
