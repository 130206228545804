<template>
  <NotFound
    :headerText="$t('error.route.legacyRedirect.header')"
  >
    <p
      :class="$style.message"
      v-html="$t(
        'error.route.legacyRedirect.instructions',
        {
          a: (help) => {
            return `<a href='https://help.lexisdl.com/3000.htm'>${help}</a>`;
          },
          b: (homePage) => {
            return `<a href='/'>${homePage}</a>`
          }
        }
      )"
    >
    </p>
  </NotFound>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NotFound from 'app/views/NotFound.vue';

export default defineComponent({
  name: 'LegacyRedirectFailed',
  components: { NotFound }
});
</script>

<style lang="less" module>
@import '../../app/views/core/base.less';

.message {
  margin: 0.25rem;

  a {
    .pop-text(@c-primary-blue);
    color: @c-primary-blue;
    .focus-outline();
  }
}
</style>
