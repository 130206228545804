import { getQueryParametersFromSubjectFilters } from 'app/functions/use-subject-filters';

/**
 * Legacy URL example:
 *  https://lexisdl.com/library/rll/browse/search/query-chisum/sort-releasedate/subjects-[351,7]/practice-LAW041000,1045/jurisdiction-932/classification-961,1090/title/page-1
 *
 * @param address The legacy search URL format
 * @returns Search.vue's expected properties
 */
export function parseLegacySearchParameters(address: string[]) {
  const legacyProperties = legacyPropertiesFromAddress(address);

  return {
    query: legacyProperties.query || '',
    sort: legacyProperties.sort,
    ...tabbedParameters(legacyProperties),
    ...subjectParameters(legacyProperties)
  };
}


/**
 * Legacy URL example:
 *  https://lexisdl.com/library/rll/browse/creator-240304/sort-releasedate/classification-1078/title/page-1
 *
 * @param address The legacy search URL format
 * @returns Creator.vue's expected properties
 */
export function parseLegacyListParameters(address: string[]) {
  const legacyProperties = legacyPropertiesFromAddress(address);

  return {
    // creatorId is handled in router, as it's in the params
    sort: legacyProperties.sort,
    ...tabbedParameters(legacyProperties),
    ...subjectParameters(legacyProperties)
  };
}

/**
 * Legacy URL example:
 *  https://lexisdl.com/library/rll/browse/generated-255123/sort-releasedate/jurisdiction-902/page-2
 *
 * @param address The legacy search URL format
 * @returns GeneratedCollectionDefinition.vue's expected properties
 */
export function parseLegacyCollectionParameters(address: string[]) {
  const legacyProperties = legacyPropertiesFromAddress(address);

  return {
    sort: legacyProperties.sort,
    breakpoint: legacyProperties.contd,
    page: legacyProperties.page || 1,
    ...subjectParameters(legacyProperties)
  };
}


function subjectParameters(legacyProperties: Record<string, any>) {
  return {
    subjects: getQueryParametersFromSubjectFilters({
      Classification: legacyProperties.classification,
      Jurisdiction: legacyProperties.jurisdiction,
      PracticeArea: legacyProperties.practice,
      Subject: legacyProperties.subjects
    })
  };
}


function tabbedParameters(legacyProperties: Record<string, any>) {
  return {
    tab: legacyProperties.tab || 'title',
    titlePage: legacyProperties.tab === 'title'
      ? legacyProperties.page || 1 : 1,
    seriesPage: legacyProperties.tab === 'set'
      ? legacyProperties.page || 1 : 1
  };
}


function legacyPropertiesFromAddress(address: string[]) {
  return Object.fromEntries(parseSegments(address));
}


function parseSegments(addressSegments: string[]) {
  return addressSegments
    .map((segment) => {
      const [key, value] = segment.split('-');

      return parseLegacySearchParameter(key, value);
    });
}


function parseLegacySearchParameter(key: string, value = ''): [string, any] {
  switch (key) {
    case 'query':
    case 'sort':
      return [key, value];

    case 'page':
      return [key, parseInt(value, 10) || 1];

    case 'set':
    case 'title':
      return ['tab', key];

    case 'practice':
    case 'jurisdiction':
    case 'classification':
      return [key, value.split(',')];

    case 'subjects': {
      const parsedArray = JSON.parse(
        value,
        (_, jsonValue) => {
          return Array.isArray(jsonValue) ? jsonValue : jsonValue.toString();
        }
      );

      return [key, parsedArray];
    }

    default:
      return [key, value];
  }
}
