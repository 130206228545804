import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    header: _ctx.$t('general.loading'),
    announceHeader: false
  }, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_Icon, {
        class: _normalizeClass(_ctx.$style.icon),
        "aria-label": _ctx.$t('general.loading'),
        name: "spinner"
      }, null, 8, ["class", "aria-label"])
    ]),
    _: 2
  }, [
    (_ctx.includeToolbar)
      ? {
          name: "toolbar",
          fn: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.toolbar)
            }, null, 2)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["header"]))
}