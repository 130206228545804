<template>
  <button
    v-if="status"
    :aria-label="$t(`annotationCopying.titleCard.${status}`)"
    aria-haspopup="dialog"
    :class="$style.status"
    @click="showSidebar = true"
  >
    <div :class="$style.iconContainer">
      <Icon
        v-if="hasError"
        name="warning"
        :class="$style.error"
      />
      <Icon
        v-if="status === 'copying'"
        name="spinner"
        :class="$style.icon"
      />
      <Icon
        v-else
        name="dl-downloaded"
        :class="$style.icon"
      />
    </div>
    <p
      v-if="!actionsIconsOnly"
      :class="$style.label"
    >
      {{ $t(`annotationCopying.titleCard.${status}`) }}
    </p>
  </button>

  <ManageCopyingSidebar
    v-if="showSidebar"
    :title="title"
    :copyJobsState="copyJobsState"
    @close="showSidebar = false"
  />
</template>

<script lang="ts">
import ManageCopyingSidebar from 'app/components/ManageCopyingSidebar.vue';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { TitleRecord } from 'app/models/title';
import { PropType, computed, defineComponent, ref } from 'vue';

type Status = 'copying' | 'copied' | undefined;

export default defineComponent({
  name: 'TitleCardCopyStatus',
  components: {
    ManageCopyingSidebar
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    copyJobsState: {
      type: Object as PropType<CopyJobsState>,
      required: true
    },
    actionsIconsOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const showSidebar = ref(false);

    const status = computed<Status>(() => {
      if (props.copyJobsState.status === 'loaded') {
        const jobs = props.copyJobsState.jobs;
        if (jobs.every((job) => job.reviewed)) {
          return undefined;
        }

        if (jobs.some((job) => job.status === 'IN_PROGRESS' || job.status === 'QUEUED')) {
          return 'copying';
        }

        return 'copied';
      }

      return undefined;
    });

    const hasError = computed(() => {
      return props.copyJobsState.status === 'loaded'
        ? props.copyJobsState.jobs.some((job) => job.status === 'ERROR')
        : false;
    });

    return {
      hasError,
      showSidebar,
      status
    };
  }
});
</script>

<style module>
.status {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.25rem;
  align-items: baseline;
  padding: 0.5rem 0;
}

.icon-container {
  position: relative;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: bottom;
}

.error {
  height: 1.25rem;
  width: 1.25rem;
  left: -10px;
  top: -10px;
  fill: #FF6C00;
  border-radius: 0 17px 0 0;
  position: absolute;
  background-color: white;
}

.label {
  text-decoration: underline;
  font-size: var(--fs-metadata);
}
</style>
