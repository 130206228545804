import { APP } from 'app/base/app';
import { Bank } from './bank';
import { BankScopeMemory } from './bank-scope-memory';

export class BankScopeNative extends BankScopeMemory implements Bank {
  public static COMMIT_DELAY_MS = 1000;


  protected _commit() {
    this._eachFlushKey((key) => {
      APP.shell.transmit({
        name: 'bank:write',
        scope: this._name,
        key: key,
        value: this.get(key)
      });
    });
  }
}
