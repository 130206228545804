import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbNavigation = _resolveComponent("BreadcrumbNavigation")!
  const _component_TitleActionsContextMenuButton = _resolveComponent("TitleActionsContextMenuButton")!
  const _component_Cover = _resolveComponent("Cover")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(_ctx.$style.header)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.actions)
    }, [
      _createVNode(_component_BreadcrumbNavigation, {
        class: _normalizeClass(_ctx.$style.breadcrumbs),
        title: _ctx.header,
        breadcrumbList: _ctx.breadcrumbs
      }, null, 8, ["class", "title", "breadcrumbList"]),
      _createVNode(_component_TitleActionsContextMenuButton, { title: _ctx.title }, null, 8, ["title"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.info)
    }, [
      (!_ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_Cover, {
            key: 0,
            class: _normalizeClass(_ctx.$style.cover),
            item: _ctx.title
          }, null, 8, ["class", "item"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.details)
      }, [
        _createElementVNode("h1", {
          class: _normalizeClass(_ctx.$style.title)
        }, [
          _createVNode(_component_router_link, {
            to: _ctx.detailsPage,
            class: "focus-outline dark"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", { innerHTML: _ctx.header }, null, 8, _hoisted_1)
            ], undefined, true),
            _: 1
          }, 8, ["to"])
        ], 2),
        (_ctx.subtitle)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(_ctx.$style.subtitle),
              innerHTML: _ctx.subtitle
            }, null, 10, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.metadata && _ctx.metadata.edition)
          ? (_openBlock(), _createElementBlock("dl", {
              key: 1,
              class: _normalizeClass(_ctx.$style.metadata)
            }, [
              (_ctx.metadata.edition)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.metadataItem)
                  }, [
                    _createElementVNode("dt", {
                      class: _normalizeClass(_ctx.$style.metadataLabel)
                    }, _toDisplayString(_ctx.$t('pageHeader.metadata.edition')), 3),
                    _createElementVNode("dd", {
                      class: _normalizeClass(_ctx.$style.metadataValue),
                      innerHTML: _ctx.metadata.edition
                    }, null, 10, _hoisted_3)
                  ], 2))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ], 2))
}