import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Constants } from 'app/base/constants';
import env from 'app/base/env';
import { EventHandler } from '../event-map';
import { Roster, RosterDocument, RosterDocumentEntry } from './roster';

export class RosterCovers extends Roster {
  private _timer = 0;
  private _loanUpdateHandler?: EventHandler<'loan:update:all'>;
  public static COVER_FILE_SIZE = 50000;
  public static VERSION = '2.0.4';


  public assign(rosterDoc: RosterDocument): void {
    if (rosterDoc) {
      super.assign(rosterDoc);
    }
    this._loanUpdateHandler = this._loanUpdateHandler || APP.events.on(
      'loan:update:all',
      (_) => this._onLoanUpdate()
    );
  }


  public ensureAllCoversRostered(): void {
    const newDoc = this._generateDocument();
    if (this._isDifferentDocument(newDoc)) {
      this.initializeUpdate(newDoc, true);
    }
  }


  protected _onLoanUpdate(): void {
    clearTimeout(this._timer);
    this._timer = window.setTimeout(this.ensureAllCoversRostered.bind(this), 200);
  }


  protected _generateDocument(): RosterDocument {
    const doc: RosterDocument = {
      group: 'covers',
      id: 'shelf-covers',
      role: 'system',
      entries: this._defaultEntries(),
      version: RosterCovers.VERSION
    };
    C.each(
      APP.patron.loans.all,
      (loan) => {
        doc.entries.push({
          url: env.ROOT_URI + encodeURI(decodeURI(loan.coverURL())),
          bytes: RosterCovers.COVER_FILE_SIZE
        });
      }
    );

    return doc;
  }


  /**
   * Returns true if equivalent, false if different.
   * @param rosterDoc the old version to check against
   */
  protected _isDifferentDocument(rosterDoc: RosterDocument): boolean {
    const oldVersion = rosterDoc ? rosterDoc.version : null;
    const newVersion = this.doc ? this.doc.version : null;
    let result = oldVersion !== newVersion;
    if (!result) {
      const oldEntries = this.doc ? this.doc.entries : [];
      const newEntries = rosterDoc ? rosterDoc.entries : [];
      result = !this._compareEntries(oldEntries, newEntries);
    }

    return result;
  }


  protected _compareEntries(entriesA: RosterDocumentEntry[], entriesB: RosterDocumentEntry[]): boolean {
    const urlsA: string[] = [];
    const urlsB: string[] = [];
    const pusher = (urls: string[], entry: RosterDocumentEntry) => {
      urls.push(encodeURI(decodeURI(entry.url)));
    };
    C.each(entriesA, pusher.bind(this, urlsA));
    C.each(entriesB, pusher.bind(this, urlsB));
    const strA = urlsA.sort().join(' | ');
    const strB = urlsB.sort().join(' | ');

    //console.log('[COVERS-ROSTER] compare:\n* %s\n* %s', strA, strB);
    return strA === strB;
  }


  /**
   * The 404 graphic conveniently ensures that this roster has at least
   * one entry, and therefore can always be valid.
   */
  protected _defaultEntries(): RosterDocumentEntry[] {
    return [
      {
        url: Constants.assetURL('images/cover-404-book.png'),
        bytes: 14060
      },
      {
        url: Constants.assetURL('images/cover-404-audiobook.png'),
        bytes: 13708
      }
    ];
  }
}
