import { epochMilliseconds } from './time';

/**
 * Enforce the given callback is not called more frequently than specified
 * by the minInterval value (number in MS)
 *
 * Returns a wrapper around the callback that is then to be used when the callback
 * needs to be invoked
 *
 * See also: debounce
 *
 * @param callback The function to stagger
 * @param minInterval The number of milliseconds to wait before the function is executed.
 * @param maxInterval
 */
export function staggerInvocation(callback: Function, minIntervalMS: number, maxIntervalMS = 0) {
  let timer;
  let requestedAt = Infinity;
  const invokeCallback = () => {
    requestedAt = Infinity;
    callback();
  };

  return (forceInvocation = false) => {
    clearTimeout(timer);
    const now = epochMilliseconds();
    requestedAt = Math.min(requestedAt, now);
    const deadline = forceInvocation ? 0 : requestedAt + Math.max(minIntervalMS, maxIntervalMS);
    if (now >= deadline) {
      invokeCallback();
    } else {
      const delay = Math.min(minIntervalMS, deadline - now);
      timer = setTimeout(invokeCallback, delay);
    }
  };
}


export const withLock = <T extends (...args: any[]) => any>(func: T) => {
  let awaitable: Promise<Awaited<ReturnType<T>>> | undefined;

  return (...args: Parameters<T>): Promise<Awaited<ReturnType<T>>> => {
    if (!awaitable) {
      awaitable = Promise.resolve(func(...args)).finally(() => { awaitable = undefined; });
    }

    return awaitable;
  };
};


export const withBlocker = <T extends (...args: any[]) => any>(func: T) => {
  let blocker = false;

  const blockedFunction =  (...args: Parameters<T>): ReturnType<T> | undefined => {
    if (!blocker) {
      return func(...args);
    }
  };

  const block = () => blocker = true;
  const unblock = () => blocker = false;
  const isBlocked = () => blocker;

  return {
    block,
    blockedFunction,
    isBlocked,
    unblock
  };
};


export const debounce = <T extends (...args: any[]) => any>(func: T, wait: number) => {
  let timeout: number | undefined;
  let resolveList: ((value: ReturnType<T>) => void)[] = [];

  return (...args: Parameters<T>): Promise<Awaited<ReturnType<T>>> => {
    return new Promise((resolve) => {
      resolveList.push(resolve);

      window.clearTimeout(timeout);

      timeout = window.setTimeout(() => {
        const result: ReturnType<T> = func(...args);
        resolveList.forEach((r) => r(result));
        resolveList = [];
        timeout = undefined;
      }, wait);
    });
  };
};
