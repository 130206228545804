import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LibraryLogo = _resolveComponent("LibraryLogo")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FormError = _resolveComponent("FormError")!
  const _component_LoginForm = _resolveComponent("LoginForm")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.$style.welcomePanel)
    }, [
      _createVNode(_component_LibraryLogo, {
        library: _ctx.library,
        class: _normalizeClass(_ctx.$style.logo)
      }, null, 8, ["library", "class"]),
      (_ctx.state.libraryState === 'resolving')
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.$style.loading)
          }, [
            _createVNode(_component_Icon, {
              name: "spinner",
              "aria-label": _ctx.$t('login.loading')
            }, null, 8, ["aria-label"])
          ], 2))
        : (_ctx.state.libraryState === 'error')
          ? (_openBlock(), _createBlock(_component_FormError, {
              key: 1,
              class: _normalizeClass(_ctx.$style.error),
              contents: _ctx.$t(_ctx.state.libraryError)
            }, null, 8, ["class", "contents"]))
          : (_ctx.state.loginPage.state === 'loading')
            ? (_openBlock(), _createElementBlock("p", {
                key: 2,
                class: _normalizeClass(_ctx.$style.loading)
              }, [
                _createVNode(_component_Icon, {
                  name: "spinner",
                  "aria-label": _ctx.$t('login.loading')
                }, null, 8, ["aria-label"])
              ], 2))
            : (_ctx.state.loginPage.state === 'error')
              ? (_openBlock(), _createBlock(_component_FormError, {
                  key: 3,
                  class: _normalizeClass(_ctx.$style.error),
                  contents: _ctx.$t(_ctx.state.loginPage.error)
                }, null, 8, ["class", "contents"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                  _createElementVNode("h1", {
                    class: _normalizeClass(_ctx.$style.header)
                  }, _toDisplayString(_ctx.$t('login.form.header')), 3),
                  _createVNode(_component_LoginForm, {
                    page: _ctx.state.loginPage.loginPage,
                    auth: _ctx.state.loginPage.authenticationState,
                    onCancel: _ctx.cancelAuth,
                    onSubmit: _ctx.authenticate
                  }, null, 8, ["page", "auth", "onCancel", "onSubmit"])
                ], 64))
    ], 2),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style.disclaimer),
      innerHTML: _ctx.$t('login.form.disclaimer', {
        strong: (val) => { return `<strong>${val}</strong>`; },
        a: (val) => { return `<a href='https://www.lexisnexis.com/en-us/terms/publications-services-agreement.page' target='about:blank'>${val}</a>`; },
      })
    }, null, 10, _hoisted_1)
  ], 2))
}