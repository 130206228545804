import { APP } from 'app/base/app';
import MemoryStorage from 'app/base/storage/memory-storage';
import TypedStorage from 'app/base/storage/typed-storage';
import { TempTitleCacheSymbol } from 'app/keys/injection-keys';
import { BankedTitle, TitleMapper, TitleRecord } from 'app/models/title';
import { Ref, inject, isRef, readonly, ref, watch } from 'vue';
import { useRoute } from 'vue-router';


export function useTitle(titleIdRaw: Ref<string | null> | string) {
  const tempTitleCache = inject(
    TempTitleCacheSymbol,
    new TypedStorage<BankedTitle>(new MemoryStorage())
  );

  const titleId = isRef(titleIdRaw) ? titleIdRaw : ref(titleIdRaw);

  const title = ref<TitleRecord | null>(null);
  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const timer = ref<ReturnType<typeof setTimeout> | null>(null);

  const update = async () => {
    if (!titleId.value) { return; }

    try {
      loading.value = true;

      let cachedTitle: TitleRecord | null = null;

      const storedTitle = tempTitleCache.getItem(titleId.value);
      if (storedTitle) {
        cachedTitle = TitleMapper.mapFromBank(storedTitle, true);
      }

      if (!cachedTitle) {
        cachedTitle = APP.titleCache.getIfFresh(titleId.value) || null;
      }

      if (cachedTitle) {
        title.value = cachedTitle;
      } else {
        const thunderTitle = await APP.services.thunder.getTitle(APP.library.key(), titleId.value);
        const mapped = TitleMapper.mapFromThunder(thunderTitle);
        if (!mapped) { throw new Error; }

        const bankedTitle = mapped.serialize() as BankedTitle;
        tempTitleCache.setItem(titleId.value, bankedTitle);

        title.value = mapped;
      }

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  watch(titleId, update, { immediate: true });

  const poll = () => {
    return setTimeout(async () => {
      await update();

      timer.value = poll();
    }, 2000);
  };

  const stopPolling = () => {
    if (timer.value) {
      clearTimeout(timer.value);
      timer.value = null;
    }
  };

  const startPolling = () => {
    timer.value = poll();

    const route = useRoute();
    watch(route, stopPolling);
  };

  return {
    title: title,
    loading: readonly(loading),
    error: readonly(error),
    update,
    startPolling,
    stopPolling
  };

}
