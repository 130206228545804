export function normalizeDate(dateString: string | undefined) {
  if(dateString) {
    const pattern = /((?<month>\d+)(\/|-))?((?<day>\d+)(\/|-))?(?<year>(\d+))/;

    const { day, month, year } = pattern.exec(dateString)?.groups;

    const parts = {
      day: parseInt(day || '1', 10),
      month: parseInt(month || '1', 10) - 1, // Because Date is weird
      year: parseInt(year, 10) // If it can't find a year, just fail
    };

    const millis = new Date(parts.year, parts.month, parts.day).valueOf();

    return millis;
  }

  return dateString;
}
