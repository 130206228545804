import env from 'app/base/env';
import { Service } from 'app/base/services/service';

export class NTC extends Service {
  constructor() {
    super('NTC', (path: string) => {
      return { url: `${env.NTC_API_URI}/v1/${path}` };
    });
  }


  public getNtcSubscriptions(libraryKey: string): Promise<NtcProviderResponse[] | null> {
    const route = `provider-subscriptions?libraryKey=${libraryKey}`;

    return this.fetchAsync(route);
  }


  public goToNtcWebsite(libraryKey: string, providerId: string, code: string) {
    const route = `${env.NTC_SITE_URI}/${libraryKey}/${providerId}?code=${code}`;

    window.open(route, '_blank');
  }
}

export interface NtcProviderResponse {
  id: string;
  reserveId: string;
  slug: string;
  name: string;
  url: string;
  category: string;
  popularity: number;
  colors: {
    background: number[];
    brand: number[];
  };
  assets: NtcProviderAsset[];
  tagline: string;
  description: string;
  features: string[];
  logo: NtcProviderAsset;
  icon: NtcProviderAsset;
  shareImage: NtcProviderAsset;
  piiStatement: string;
  subscription: {
    providerId: string;
    startDate: number;
    endDate: number;
    status: 'active' | 'expired';
  };
};

interface NtcProviderAsset {
  image: {
    url: string;
    dimensions: number[];
  };
  title?: string;
  caption?: string;
};
