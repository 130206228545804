import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  class: "visually-hidden",
  "aria-live": "polite"
}
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("dialog", {
    ref: "dialog",
    class: _normalizeClass([
      _ctx.$style.dialog,
      { [_ctx.$style.animationOpen]: _ctx.animationOpen }
    ]),
    style: _normalizeStyle(_ctx.styles),
    "aria-label": _ctx.heading,
    onCancel: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args))),
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args))),
    onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)), ["prevent"]), ["esc"]))
  }, [
    _createElementVNode("div", {
      ref: "container",
      class: _normalizeClass(_ctx.$style.container),
      onClick: _cache[2] || (_cache[2] = (e) => e.stopPropagation())
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.wrapper)
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.announcement), 1),
        _createElementVNode("header", {
          class: _normalizeClass(_ctx.$style.header)
        }, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "notch" }, null, -1)),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.backContainer)
          }, [
            (_ctx.showBack)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  ref: "backButton",
                  class: _normalizeClass(_ctx.$style.back),
                  "aria-label": _ctx.$t('header.account.back'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
                }, [
                  _createVNode(_component_Icon, { name: "chevron-left" })
                ], 10, _hoisted_3))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.headingContainer)
          }, [
            _createElementVNode("h2", {
              class: _normalizeClass(_ctx.$style.heading)
            }, [
              (_ctx.headingIcon)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    name: _ctx.headingIcon
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.heading), 1)
            ], 2)
          ], 2),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.dismissContainer)
          }, [
            _createElementVNode("button", {
              ref: "dismissButton",
              class: _normalizeClass(_ctx.$style.dismiss),
              "aria-label": _ctx.$t('general.close'),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)))
            }, [
              _createVNode(_component_Icon, { name: "dismiss" })
            ], 10, _hoisted_4)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.extraContainer)
          }, [
            _renderSlot(_ctx.$slots, "extra")
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.content)
        }, [
          _createVNode(_Transition, {
            name: _ctx.showBack ? 'slide-in-right' : 'slide-in-left',
            onAfterEnter: _ctx.scrollTop
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ], undefined, true),
            _: 3
          }, 8, ["name", "onAfterEnter"])
        ], 2)
      ], 2)
    ], 2)
  ], 46, _hoisted_1))
}