<template>
  <Teleport
    to="#toasts"
  >
    <div
      v-if="show"
      :class="[$style.toast, $style[type], animate ? $style.animate : '']"
    >
      <Icon
        :name="iconName"
      />

      <p :class="$style.message">
        {{ message }}
      </p>

      <button
        :class="$style.dismiss"
        :aria-label="$t('close')"
        :aria-hidden="true"
        @click="show = false"
      >
        {{ $t('general.dismiss') }}
      </button>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { useAppEvents } from 'app/functions/use-app-events';
import { announceMessage } from 'app/functions/use-chatterbox';
import { computed, defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  name: 'Toast',
  setup: () => {
    const message = ref('');
    const type = ref<'success' | 'error' | 'info'>('success');
    const show = ref(false);
    const animate = ref(true);
    const iconName = computed(() => {
      return type.value === 'success' ? 'check'
        : type.value === 'error' ? 'dismiss'
        : 'info';
    });

    let hideTimeout = -1;

    useAppEvents({
      toast: async ({ m }) => {
        show.value = false;
        animate.value = false;
        clearTimeout(hideTimeout);

        await nextTick();

        message.value = m.message;
        type.value = m.type;
        show.value = true;
        animate.value = true;

        announceMessage(message.value);

        hideTimeout = window.setTimeout(
          () => show.value = false,
          5000
        );
      }
    });

    return {
      animate,
      iconName,
      message,
      show,
      type
    };
  }
});
</script>

<style module>
.toast {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 4rem;
  line-height: normal;
  color: var(--c-white);
  display: flex;
  align-items: center;
  padding: 0 2rem;

  display: grid;
  grid-template-areas:
    'icon message dismiss';
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
}

@media screen and (max-width: 499px) {
  .toast {
    display: grid;
    grid-template-areas:
      'icon'
      'message'
      'dismiss';
    grid-template-columns: auto;
    justify-items: center;
    gap: 0.75rem;
    padding: 1rem;
  }
}
.toast svg {
  stroke: currentColor;
  fill: currentColor;
  height: 1.25rem;
  width: 1.25rem;
  grid-area: 'icon';
}

.toast.animate {
  animation: fadein 500ms, fadeout 1s 4s forwards;
}

.toast.success {
  background-color: var(--c-notif-success);
}

.toast.error {
  background-color: var(--c-notif-error);
}

.toast.info {
  color: var(--c-black);
  background-color: var(--c-notif-info);
}

.message {
  grid-area: 'message';
}

.dismiss {
  grid-area: 'actions';
  border: 1px solid;
  border-radius: var(--form-border-radius);
  padding: 0.5rem 1rem;
}

@-webkit-keyframes fadein {
  to { opacity: 1; }
  from { opacity: 0; }
}

@keyframes fadein {
  to { opacity: 1; }
  from { opacity: 0; }
}

@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}
</style>
