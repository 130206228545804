import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "panel",
    class: _normalizeClass(_ctx.$style.panelWrapper)
  }, [
    _createVNode(_Transition, {
      name: "fade",
      onEnter: _ctx.setHeight,
      onLeave: _ctx.clearHeight
    }, {
      default: _withCtx(() => [
        (_ctx.expanded)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: _ctx.panelId,
              class: _normalizeClass(_ctx.$style.panel),
              "aria-labelledby": _ctx.headerId
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 10, _hoisted_1))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 3
    }, 8, ["onEnter", "onLeave"])
  ], 2))
}