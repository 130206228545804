import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: _normalizeClass(_ctx.$style.switch)
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.ariaLabel)), 1),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
        type: "checkbox",
        class: "visually-hidden"
      }, null, 512), [
        [_vModelCheckbox, _ctx.checked]
      ]),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.slider)
      }, null, 2)
    ], 2),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.text)
    }, _toDisplayString(_ctx.$t(`switch.${ _ctx.checked ? 'on' : 'off'}`)), 3)
  ]))
}