

  // "Only one element can have this className at any time."
  //
  // Adds the className to the element's classList and removes it from
  // any other element in the given scope (defaults to documentElement).
  //
  export function batonClass(element: HTMLElement, className: string, scope?: HTMLElement): void {
    const elementScope = scope
      || (element.ownerDocument
        ? element.ownerDocument.documentElement
        : document.documentElement);
    const batonHolders = elementScope.querySelectorAll(`.${className}`);
    for (let i = 0, ii = batonHolders.length; i < ii; ++i) {
      if (batonHolders[i] === element) { continue; }
      batonHolders[i].classList.remove(className);
    }
    element.classList.add(className);
  }
