import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "disabled", "aria-checked", "aria-describedby", "aria-label"]
const _hoisted_2 = ["for", "aria-hidden"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
      type: "checkbox",
      disabled: _ctx.disabled,
      "aria-checked": _ctx.ariaChecked,
      "aria-describedby": _ctx.description ? _ctx.descriptionId : undefined,
      "aria-label": _ctx.ariaLabel,
      class: _normalizeClass(`${_ctx.$style.input} focus-outline`)
    }, null, 10, _hoisted_1), [
      [_vModelCheckbox, _ctx.internalValue]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.checkmark)
    }, [
      (_ctx.modelValue === 'partial')
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: _ctx.modelValue,
            role: "presentation",
            name: "minus"
          }))
        : (_ctx.modelValue === 'on')
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: _ctx.modelValue,
              role: "presentation",
              name: "check"
            }))
          : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("label", {
      for: _ctx.id,
      class: _normalizeClass(_ctx.showLabel ? _ctx.$style.label : 'visually-hidden'),
      "aria-hidden": !!_ctx.ariaLabel
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ], 10, _hoisted_2),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          id: _ctx.descriptionId,
          class: _normalizeClass(_ctx.$style.description)
        }, _toDisplayString(_ctx.description), 11, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}