<template>
  <PromptDialog
    ref="prompt"
    :heading="$t('annotationCopying.prompt.inProgress.header')"
    :dismissible="false"
    @submit="openBook"
    @close="$emit(onCloseEvent)"
  >
    <InProgressContent
      :id="inProgressPromptId"
    />
    <div
      :class="$style.actions"
    >
      <FormSubmitButton
        autofocus
        :label="$t('annotationCopying.prompt.inProgress.continue')"
        :enabled="true"
        size="small"
      />

      <button
        :class="$style.cancel"
        type="button"
        @click="goToAnnotations"
      >
        {{ $t('annotationCopying.prompt.inProgress.cancel') }}
      </button>
    </div>
  </PromptDialog>
</template>

<script lang="ts">
import InProgressContent from 'app/components/InProgressContent.vue';
import { TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { generateUUID } from 'lib/common';
import { defineComponent, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import FormSubmitButton from './FormSubmitButton.vue';
import PromptDialog from './dialogs/PromptDialog.vue';

export default defineComponent({
  name: 'CopyingPrompt',
  components: {
    InProgressContent,
    FormSubmitButton,
    PromptDialog
  },
  props: {
    targetTitle: {
      type: Object as PropType<TitleRecord>,
      default: undefined
    }
  },
  emits: [
    'close',
    'open'
  ],
  setup: (props) => {
    const router = useRouter();
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);
    const onCloseEvent = ref<'open' | 'close'>('open');
    const inProgressPromptId = ref(`inProgress-explanation-${generateUUID()}`);

    const goToAnnotations = () => {
      onCloseEvent.value = 'close';
      prompt.value?.closeModal();

      router.push({
        name: RouteName.NoteDetails,
        params: {
          titleSlug: props.targetTitle!.slug
        }
      });
    };

    const openBook = () => {
      onCloseEvent.value = 'open';
      prompt.value?.closeModal();
    };

    return {
      inProgressPromptId,
      onCloseEvent,
      prompt,
      goToAnnotations,
      openBook
    };
  }
});
</script>

<style module>
.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.cancel {
  text-transform: uppercase;
  color: var(--c-medium-black);
  composes: linked from global;
}
</style>
