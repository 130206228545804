import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { EventMap } from 'app/base/event-map';
import { BifocalClientQuery, DervishResponse, DervishURLs } from 'app/base/interfaces';
import { Possession } from 'app/models/possession';
import { TitleRecord } from 'app/models/title';
import { BasePart, PartialView } from 'app/views/core/partial';
import { MS_MINUTE } from 'lib/common';
import { Orientation } from '../../base/orient';
import { Bifocal } from './bifocal';

/**
 * Abstract of the Bifocal interface, with some base implementations
 */
export abstract class BifocalViewBase extends PartialView<BifocalParts> implements Bifocal {
  public isReady: boolean;
  public orientation: Orientation;
  public lastOpenMessage: any;

  protected _title: TitleRecord;
  protected _possession: Possession;
  protected _urls: DervishURLs;
  protected _syncActive: boolean;

  /** Used to debounce bifocal client data requests */
  private readonly _syncTimer = MS_MINUTE * 5;


  public abstract open(title: TitleRecord, possession: Possession, dervishData: DervishResponse, anchorID?: string);


  public abstract reveal(): void;


  public abstract conceal(): void;


  public abstract transmit(object: {}): void;


  public abstract reload(): void;


  public abstract getUrl(): string;


  public clear(): void {
    delete this._title;
    delete this._possession;
    delete this._urls;
  }


  public isOpen(titleSlug: string, possession: Possession): boolean {
    return (
      this._title &&
      this._title.slug === titleSlug &&
      this._possession?.slug === possession?.slug
    );
  }


  public title(): TitleRecord {
    return this._title;
  }


  public async seekToAnchor(titleSlug: string, possession: Possession, anchorID: string): Promise<void> {
    const msg = {anchorID: anchorID, name: 'seek:anchor'};

    return this.transmitTitleMessage(titleSlug, possession, msg);
  }


  public async seekToHighlight(titleSlug: string, possession: Possession, highlightUUID: string): Promise<void> {
    const msg = {highlightUUID: highlightUUID, name: 'seek:highlightUUID'};

    return this.transmitTitleMessage(titleSlug, possession, msg);
  }


  public async seekToPath(titleSlug: string, possession: Possession, path: string): Promise<void> {
    const msg = {path: path, name: 'seek:path'};

    return this.transmitTitleMessage(titleSlug, possession, msg);
  }


  public async seekToQuery(titleSlug: string, possession: Possession, query: string): Promise<void> {
    const msg = {query: query, name: 'seek:query'};

    return this.transmitTitleMessage(titleSlug, possession, msg);
  }


  /**
   * Send a message to bifocal to seek to an anchor
   * @param titleSlug The titleID to seek with
   * @param possession The possession for this title
   * @param message The message to send
   */
  protected async transmitTitleMessage(titleSlug: string, possession: Possession, message: any): Promise<void> {
    if (this.isReady && this.isOpen(titleSlug, possession)) {
      console.log(`[BIFOCAL-VIEW-BASE] Book already open, sending "${message?.name}" message.`);
      this.transmit(message);
    } else {
      console.log(`[BIFOCAL-VIEW-BASE] Setting up handler to transmit "${message?.name}"...`);
      const handler = APP.events.on('msg:bifocal:ready', () => {
        console.log(`[BIFOCAL-VIEW-BASE] Book is ready, sending "${message?.name}" message.`);
        window.setTimeout(() => this.transmit(message), C.MS_SECOND);

        handler.deafen();
      });
    }
  }


  protected async _sendData(evt: EventMap['msg:bifocal:client:query']): Promise<void> {
    if (!this._title || evt.sync && this._syncActive) { return; }
    if (evt.sync) {
      try {
        this._syncActive = true;
        await APP.patron.syncCoordinator.remoteSync({ freshness: this._syncTimer });
      } catch (err) {
        console.warn('[BIFOCAL][SYNC] Failed sync');
      } finally {
        this._syncActive = false;
      }
    }
    const data = this._collectScopes(evt.scopes);
    this.transmit({
      name: 'bifocal:client:query',
      scopes: data
    });
  }


  protected _collectScopes(scopes: (keyof BifocalClientQuery)[]): BifocalClientQuery {
    return scopes.reduce((result, scope) => {
      result[scope] = this._collectScope(scope);

      return result;
    }, {} as BifocalClientQuery);
  }


  protected _collectScope<T extends keyof BifocalClientQuery>(key: T): BifocalClientQuery[T] {
    switch (key) {
      case 'annotations':
        return APP.patron.annotations.getSerializedByBuidOrActiveTitle();
      default:
        return undefined;
    }
  }
}

export interface BifocalParts extends BasePart {
  frame: HTMLIFrameElement;
}
