import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SeriesCover = _resolveComponent("SeriesCover")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createVNode(_component_Icon, { name: "series" }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('title.seriesLink.header')), 1)
    ], 2),
    _createVNode(_component_router_link, {
      to: { name: 'SetDetails', params: { seriesId: `${_ctx.series.id}` } },
      class: _normalizeClass(_ctx.$style.link),
      "aria-label": _ctx.$t('title.seriesLink.label', { name: _ctx.series.name })
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SeriesCover, {
          series: _ctx.series,
          width: _ctx.seriesCoverSizes.titleDetails
        }, null, 8, ["series", "width"]),
        _createElementVNode("div", null, [
          _createTextVNode(_toDisplayString(_ctx.series.name) + " ", 1),
          _createElementVNode("div", null, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.volumeLabel)
            }, _toDisplayString(_ctx.$t('title.seriesLink.volumeLabel')), 3),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.volumeCount)
            }, _toDisplayString(_ctx.series.items.length), 3)
          ])
        ])
      ], undefined, true),
      _: 1
    }, 8, ["to", "class", "aria-label"])
  ], 2))
}