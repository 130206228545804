<template>
  <section :class="$style.container">
    <component
      :is="headerTag"
      v-if="label"
      :class="$style.header"
    >
      {{ label }}
    </component>
    <ol :class="$style.list">
      <li
        v-for="subject in subjects"
        :key="subject.id"
        :class="$style.subject"
      >
        <router-link
          :class="$style.link"
          :to="subject.route"
        >
          <span :class="$style.name">{{ subject.name }}</span>
          <span :class="$style.count">{{ subject.count }}</span>
        </router-link>
      </li>
    </ol>
  </section>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import { SubjectCollection } from 'app/models/subject';


export default defineComponent({
  props: {
    subjects: {
      type: Array as () => SubjectCollection[],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: undefined
    },
    headerTag: {
      type: String as () => 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
      required: false,
      default: 'h1'
    }
  }
});
</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  .list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: @px-vp-tablet) {
      grid-template-columns: repeat(2 ,1fr);
    }

    @media screen and (max-width: @px-vp-narrow) {
      grid-template-columns: 1fr;
    }

  }

  .header {
    display: inline-block;
    color: @c-dark-black;
    font-size: @fs-medium-head;
    font-weight: @fw-medium;
    padding-bottom: .5rem;
    border-bottom: 2px solid @c-light-black;
    margin-bottom: 1.5rem;
  }

  .subject {
    padding: 0.5rem 0.5rem 0.5rem 0;
    box-sizing: border-box;
  }

  .link {
    .focus-outline;
  }

  .name {
    .pop(@c-blue);
    color: @c-blue;
    margin-right: 0.5rem;
  }

  .count {
    color: @c-light-black;
  }
</style>
