import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(_ctx.$style.triggerButton),
    "aria-label": _ctx.$t('search.triggerButton'),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.grid)
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.triggerInputContainer)
      }, [
        _createVNode(_component_Icon, {
          class: _normalizeClass(_ctx.$style.searchIcon),
          name: "search"
        }, null, 8, ["class"]),
        (_ctx.query)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([_ctx.$style.triggerText, _ctx.$style.query])
            }, _toDisplayString(_ctx.query), 3))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass([_ctx.$style.triggerText, _ctx.$style.placeholder])
            }, _toDisplayString(_ctx.$t('search.placeholder')), 3))
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.searchButton)
      }, _toDisplayString(_ctx.$t('search.searchButton')), 3)
    ], 2)
  ], 10, _hoisted_1))
}