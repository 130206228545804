import { APP } from 'app/base/app';
import { Network } from 'app/base/network';
import { useAppEvents } from 'app/functions/use-app-events';
import { ref } from 'vue';

export function useNetworkStatus() {
  const reachable = ref(false);
  const metered = ref(false);
  const connection = ref<string | undefined>();

  const update = (networkInfo: Network) => {
    reachable.value = networkInfo.reachable;
    metered.value = networkInfo.metered;
    connection.value = networkInfo.connection;
  };

  // If network info is already set, use it for initial values
  if (APP.network) {
    update(APP.network);
  }

  // Update when new network info is received
  useAppEvents({
    'network:info': (e) => update(e.m.network)
  });

  return {
    reachable,
    metered,
    connection
  };
}
