import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { Hold } from './hold';
import { Item } from './item';
import { Library } from './library';
import { Loan } from './loan';
import { Possession } from './possession';

export type Limits = {
  book: number;
  audiobook: number;
  video: number;
  hold: number;
  loan: number;
};

export class LibraryCard extends Item {
  public advantageKey: string;
  public cardName: string;
  public cardId: number;
  public createTime: number;
  public puid: number;
  /**
   * Keys: MediaType
   */
  public lendingPeriods: Dictionary<LendingPeriods>;
  public defaultLendingPeriods: Dictionary<[number, string]>;
  public library: Library;
  public limits?: Limits;
  public emailAddress: string;
  public contentMask: number;
  public isVisitingCard: boolean;
  public canPlaceHolds: boolean;
  public canRecommendTitles: boolean;
  public isSessionUser: boolean;


  constructor() {
    super();
    this.defaultLendingPeriods = {};
  }


  public loans(): Loan[] {
    return APP.patron.loans.all;
  }


  public remainingNonSuCheckouts(): number {
    return this.limits?.loan !== undefined
      ? Math.max(0, this.limits.loan - this.loans().filter((l) => !l.titleRecord.isSimultaneousUse).length)
      : null;
  }


  public holds(): Hold[] {
    return APP.patron.holds.all;
  }


  public totalLoanCount() {
    return this.loans().length + this._possessions('suppressedLoans').length;
  }


  public totalHoldCount() {
    return APP.patron.holds.all.length + this._possessions('suppressedHolds').length;
  }


  public equals(other: LibraryCard): boolean {
    return other && this.cardId === other.cardId;
  }


  /**
   * Takes the Sentry response and convert it to a deserializable object
   * @param attrs
   */
  protected _transformRemoteAttributes(attrs) {
    const out = C.absorb(attrs, {});
    if (out.limits) {
      out.limits.loan = Math.max(out.limits.book, out.limits.audiobook);
    }

    return out;
  }


  protected _deserializeAttributes(attrs) {
    const rawCardId = C.excise(attrs, 'cardId');
    const potentialCardId = parseInt(rawCardId, 10);
    if (!isNaN(potentialCardId)) {
      this.cardId = potentialCardId;
    } else {
      // console.warn('Unable to parse cardID', rawCardId);
      throw new Error('Unable to parse cardID');
    }

    const libAttrs = C.excise(attrs, 'library');
    if (libAttrs) {
      const dummyLibrary = new Library();
      dummyLibrary.websiteId = parseInt(libAttrs.websiteId, 10);

      this.library = APP.library && APP.library.websiteId === parseInt(libAttrs.websiteId, 10)
        ? APP.library
        : dummyLibrary;
    }
    if (attrs.lendingPeriods && this.lendingPeriods) {
      this._mergeLendingPeriods(C.excise(attrs, 'lendingPeriods'));
    }
    this.createTime = this._utcStringToTime(C.excise(attrs, 'createDate'));
    super._deserializeAttributes(attrs);
  }


  protected _serializeAttributes() {
    return { // Alphabetical, for readability
      advantageKey: this.advantageKey,
      canPlaceHolds: this.canPlaceHolds,
      canRecommendTitles: this.canRecommendTitles,
      cardId: this.cardId,
      cardName: this.cardName,
      contentMask: this.contentMask,
      createTime: this.createTime,
      isVisitingCard: this.isVisitingCard,
      lendingPeriods: this.lendingPeriods,
      emailAddress: this.emailAddress,
      library: {
        logo: this.library.logo,
        name: this.library.name,
        websiteId: this.library.websiteId
      },
      puid: this.puid
    };
  }


  /**
   * This will merge in the lending periods from serialization,
   * but will preserve the *local* preference for each mediaType,
   * if we have a preference.
   *
   * I don't think lending period preference is something that
   * needs to be synchronized across devices -- local preference is fine.
   * @param lendingPeriods
   */
  protected _mergeLendingPeriods(lendingPeriods: Dictionary<LendingPeriods>) {
    C.each(
      lendingPeriods,
      (mediaType, periods) => {
        if (this.lendingPeriods[mediaType]) {
          this.lendingPeriods[mediaType].options = periods.options;
          this.defaultLendingPeriods[mediaType] = periods.preference;
          const pref = this.lendingPeriods[mediaType].preference ;
          if (pref) {
            for (const option of periods.options) {
              if (option[0] === pref[0] && option[1] === pref[1]) {
                return;
              }
            }
          }
          this.lendingPeriods[mediaType].preference = periods.preference;
        } else {
          this.lendingPeriods[mediaType] = periods;
        }
      }
    );
  }


  protected _possessions(psnCollection: 'suppressedLoans' | 'suppressedHolds') {
    const psns = [];
    C.each(
      <Possession[]>APP.patron[psnCollection].all,
      (psn: Possession) => {
        psns.push(psn);
      }
    );

    return psns;
  }
}

export interface LendingPeriods {
  /**
   * A list of lending periods ordered from shortest to longest.
   * Each tuple defines a number with a unit of time,
   * e.g. [1, 'days'].
   */
  options: [number, string][];

  /**
   * The lending period preference set by the user.
   * Defaults to the values set by the library.
   * The tuple defines a number with a unit of time,
   * e.g. [1, 'days'].
   */
  preference: [number, string];
}
