<template>
  <fieldset :class="$style.fields">
    <FormInput
      ref="initialInput"
      v-model="usernameInput"
      type="username"
      :label="$t(`login.form.username.${usernameConfig.key || 'Username'}`)"
      :required="true"
    />

    <FormInput
      v-if="passwordConfig.enabled"
      v-model="passwordInput"
      type="password"
      :label="$t(`login.form.password.${passwordConfig.key || 'Password'}`)"
      :required="true"
    />


    <FormSubmitButton :label="$t('login.form.submit')" />

    <a
      v-if="loginHintUrl"
      :href="loginHintUrl"
      rel="noreferrer noopener"
      target="_blank"
      :class="$style.helpLink"
    >
      {{ $t('login.form.help') }}
    </a>
  </fieldset>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { LoginFormField } from 'app/base/interfaces';
import FormInput from 'app/components/FormInput.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';

export default defineComponent({
  name: 'LoginFormLocal',
  components: {
    FormInput,
    FormSubmitButton
  },
  props: {
    username: {
      type: String,
      required: false,
      default: ''
    },
    usernameConfig: {
      type: Object as () => LoginFormField,
      required: true
    },
    password: {
      type: String,
      required: false,
      default: ''
    },
    passwordConfig: {
      type: Object as () => LoginFormField,
      required: true
    },
    loginHintUrl: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'username',
    'password',
    'submit'
  ],
  setup: (props, ctx) => {
    const usernameInput = computed({
      get: () => props.username,
      set: (val) => ctx.emit('username', val)
    });
    const passwordInput = computed({
      get: () => props.password,
      set: (val) => ctx.emit('password', val)
    });

    const showPassword = ref(false);

    const submit = () => {
      ctx.emit('submit',
        {
          username: usernameInput.value,
          password: passwordInput.value
        }
      );
    };

    const initialInput = ref<{ focus: () => void } | null>(null);

    onMounted(() => {
      initialInput.value?.focus();
    });

    return {
      initialInput,
      passwordInput,
      showPassword,
      submit,
      usernameInput
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.fields > * + * {
  margin-top: 1.5rem;
}

.help-link {
  .pop-text();
  margin-left: auto;
  margin-right: auto;
  padding: 0.25rem;
  display: block;
  max-width: max-content;
  color: @c-light-black;
  .focus-outline;
}
</style>
