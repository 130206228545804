import { APP } from 'app/base/app';
import MemoryStorage from 'app/base/storage/memory-storage';
import TypedStorage from 'app/base/storage/typed-storage';
import { TempSeriesCacheSymbol } from 'app/keys/injection-keys';
import { BankedSeries, Series, SeriesMapper } from 'app/models/series';
import { Ref, inject, isRef, readonly, ref, watch } from 'vue';

export function useSeries(seriesIdsRaw: Ref<Set<number>> | Set<number>) {
  const tempSeriesCache = inject(
    TempSeriesCacheSymbol,
    new TypedStorage<BankedSeries>(new MemoryStorage())
  );

  const seriesIds = isRef(seriesIdsRaw) ? seriesIdsRaw : ref(seriesIdsRaw);
  const series = ref<Series[] | undefined>();
  const loading = ref<boolean>(false);
  const error = ref<boolean>(false);

  const update = async () => {
    if (!seriesIds.value) { return; }

    try {
      loading.value = true;

      const fetchedSeries: Series[] = [];
      const idsToFetch: number[] = [];

      seriesIds.value.forEach((seriesId) => {
        const cachedSeries = tempSeriesCache.getItem(seriesId.toString());

        if (cachedSeries) {
          const mapped = SeriesMapper.mapFromBank(cachedSeries);
          fetchedSeries.push(mapped);
        } else {
          idsToFetch.push(seriesId);
        }
      });

      if (idsToFetch.length > 0) {
        const thunderSeries = await APP.services.thunder.getBulkSeries(APP.library.key(), idsToFetch);
        if (!thunderSeries) { throw new Error; }

        thunderSeries.items.forEach((ser) => {
          const mapped = SeriesMapper.mapFromThunder(ser);

          if (!mapped) { throw new Error; }

          fetchedSeries.push(mapped);

          const bankedSeries = mapped.serialize() as BankedSeries;
          tempSeriesCache.setItem(mapped.id.toString(), bankedSeries);
        });
      }

      series.value = fetchedSeries;

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  watch(seriesIds, update, { immediate: true });

  return {
    series: readonly(series),
    loading: readonly(loading),
    error: readonly(error)
  };

}
