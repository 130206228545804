import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { DataEvent } from 'lib/gala/src/events';
import { Collation } from '../models/collation';
import { Loan } from './loan';
import { RecentlyReadTitle } from './recently-read-title';


export class RecentlyRead extends Collation<RecentlyReadTitle> {
  protected ITEM_CLASS = RecentlyReadTitle;
  protected PERSISTENT = false;


  constructor() {
    super();
    this.ITEM_NAME = 'recently-read';
    APP.events.on('title:open', (evt) => this._onTitleOpen(evt));
    APP.events.on('patron:sync:complete', () => this.load());
  }


  public load() {
    this.all = [];
    const recentReadTag = APP.patron.tags.find({name: APP.patron.tags.RECENTLY_READ_TAG});
    if (recentReadTag && recentReadTag.all?.length) {
      const titles = recentReadTag.all.map((tag) => {
        return {
          titleSlug: tag.titleSlug,
          lastAccessTime: tag.createTime
        };
      });

      this.deserialize({all: titles});
    }
    this._announce('load:all', { collation: this, all: this.all });
  }


  public refresh() {
    this.load();
  }


  private _onTitleOpen(evt: DataEvent<{ slug: string; loan?: Loan; isBundledChild: boolean; parent: string }>) {
    if (!evt || !evt.m) { return; }

    const titleSlug = (evt.m.parent) ? evt.m.parent : evt.m.slug;

    // Add title to the system 'Recently read' tag:
    const tag = APP.patron.tags.find({name: APP.patron.tags.RECENTLY_READ_TAG}) ||
      APP.patron.tags.stub({ slug: titleSlug, name: APP.patron.tags.RECENTLY_READ_TAG, flags: {isLocked: true}});
    tag.addToTitle(titleSlug);

    // Remove any existing entry for this title
    C.excise(this.all, (t) => t.titleSlug === titleSlug);

    // Add title to recently read
    const rrt = new RecentlyReadTitle();
    rrt.titleSlug = titleSlug;
    rrt.lastAccessTime = C.epochMilliseconds();
    this.all.push(rrt);
    this._saveNow();
  }
}
