
import { APP } from 'app/base/app';
import { useI18n } from 'app/functions/use-i18n';
import { SeriesSubscriptionWithMetadata, TitleSubscriptionWithMetadata, useSubscriptionsWithMetadata } from 'app/functions/use-subscriptions-with-metadata';
import { SubscriptionEvent, SubscriptionLocation } from 'app/keys/analytics-keys';
import { SubscriptionsWithMetadataSymbol } from 'app/keys/injection-keys';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { computed, inject, ref } from 'vue';


export function useTitleSubscription(content: TitleRecord, location: SubscriptionLocation) {
  const titleId = parseInt(content.slug, 10);

  const gaInfo = computed<SubscriptionEvent>(() => {
    return {
      subscription_location: location,
      is_set: false,
      title_slug: content.slug,
      title_name: content.subtitle ? `${content.title} ${content.subtitle}` : content.title
    };
  });

  const {
    subscriptionsWithMetadata,
    unsubscribe,
    subscribeToTitle
  } = inject(SubscriptionsWithMetadataSymbol, useSubscriptionsWithMetadata());

  const subscription = computed<TitleSubscriptionWithMetadata | undefined>(() => {
    if (!subscriptionsWithMetadata.value) { return undefined; }

    const match = subscriptionsWithMetadata.value.find((sub) => {
      return sub.type === 'Title' && sub.titleId === titleId;
    }) as TitleSubscriptionWithMetadata;

    return match;
  });

  const { t } = useI18n();
  const subscribeTextShort = computed(() => t(subscription.value ? 'subscription.unsubscribe.short' : 'subscription.subscribe.short'));
  const subscribeTextLong = computed(() => t(subscription.value ? 'subscription.unsubscribe.long' : 'subscription.subscribe.long'));

  const isDisabled = ref(false); // prevent rapid clicking

  const toggleSubscription = async () => {
    if (isDisabled.value) { return; }

    isDisabled.value = true;

    if (subscription.value) {
      const success = await unsubscribe(subscription.value.id);
      if (success) {
        APP.tracking.log('unsubscribe', gaInfo.value);
      }
    } else {
      const success = await subscribeToTitle(titleId);
      if (success) {
        APP.tracking.log('subscribe', gaInfo.value);
      }
    }

    setTimeout(() => isDisabled.value = false, 1000);
  };

  return {
    subscribeTextShort,
    subscribeTextLong,
    toggleSubscription
  };
};

export function useSeriesSubscription(series: Series, location: SubscriptionLocation) {
  const seriesId = series.id;

  const gaInfo = computed<SubscriptionEvent>(() => {
    return {
      subscription_location: location,
      is_set: true,
      series_slug: series.id.toString(),
      series_name: series.name
    };
  });

  const {
    subscriptionsWithMetadata,
    unsubscribe,
    subscribeToSeries
  } = inject(SubscriptionsWithMetadataSymbol, useSubscriptionsWithMetadata());

  const subscription = computed<SeriesSubscriptionWithMetadata | undefined>(() => {
    if (!subscriptionsWithMetadata.value) { return undefined; }

    const match = subscriptionsWithMetadata.value.find((sub) => {
      return sub.type === 'Series' && sub.seriesId === seriesId;
    }) as SeriesSubscriptionWithMetadata;

    return match;
  });

  const { t } = useI18n();
  const subscribeTextShort = computed(() => t(subscription.value ? 'subscription.unsubscribe.short' : 'subscription.subscribe.short'));
  const subscribeTextLong = computed(() => t(subscription.value ? 'subscription.unsubscribe.long' : 'subscription.subscribe.long'));

  const isDisabled = ref(false); // prevent rapid clicking

  const toggleSubscription = async () => {
    if (isDisabled.value) { return; }

    isDisabled.value = true;

    if (subscription.value) {
      const success = await unsubscribe(subscription.value.id);
      if (success) {
        APP.tracking.log('unsubscribe', gaInfo.value);
      }
    } else {
      const success = await subscribeToSeries(seriesId);
      if (success) {
        APP.tracking.log('subscribe', gaInfo.value);
      }
    }

    setTimeout(() => isDisabled.value = false, 1000);
  };

  return {
    subscribeTextShort,
    subscribeTextLong,
    toggleSubscription
  };
};
