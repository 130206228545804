<template>
  <button
    ref="alertButton"
    aria-haspopup="dialog"
    :class="$style.alertButton"
    @click="openMenu"
  >
    <Icon
      v-if="hasUnreviewedAlerts"
      name="alert-bell-unreviewed"
      :aria-label="$t('alert.labelUnreviewed')"
    />
    <Icon
      v-else
      name="alert-bell"
      :aria-label="$t('alert.label')"
    />
  </button>

  <SiteMenu
    v-if="showMenu"
    :heading="$t('alert.header')"
    :announcement="announcement"
    :showBack="state.status === 'details'"
    @back="hideAlertDetails"
    @close="closeMenu"
  >
    <template
      v-if="shouldShowActions"
      #extra
    >
      <div :class="$style.actions">
        <router-link
          :to="mySubLink"
          :class="[$style.link, 'focus-outline']"
          @click="closeMenu"
        >
          {{ $t('alert.manage') }}
        </router-link>

        <button
          :class="[$style.link, 'focus-outline', { [$style.disabled]: !allowClearAll }]"
          :disabled="!allowClearAll"
          @click="deleteAllAlertsAndAnnounce"
        >
          {{ $t('alert.clearAll') }}
        </button>
      </div>
    </template>

    <section
      v-if="state.status === 'offline'"
      :class="$style.errorDialog"
    >
      <h3>
        {{ $t('alert.error.header') }}
      </h3>
      <p>
        {{ $t('alert.error.offline') }}
      </p>
    </section>

    <section
      v-else-if="state.status === 'error'"
      :class="$style.errorDialog"
    >
      <h3>
        {{ $t('alert.error.header') }}
      </h3>
      <p>
        {{ $t('alert.error.general') }}
      </p>
    </section>

    <AlertDetails
      v-else-if="state.status === 'details'"
      :alert="state.alert"
      @close="closeMenu"
    />

    <AlertList
      v-else-if="state.status === 'ready' && state.alerts.length > 0"
      :alertsWithMetadata="state.alerts"
      @view-details="showAlertDetails"
      @clear="deleteAlertAndAnnounce"
    />

    <AlertListEmptyState
      v-else-if="state.status === 'ready' && state.alerts.length === 0"
    />

    <Icon
      v-else
      name="spinner"
      :class="$style.loading"
      :aria-label="$t('general.loading')"
    />
  </SiteMenu>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import AlertDetails from 'app/components/AlertDetails.vue';
import AlertList from 'app/components/AlertList.vue';
import AlertListEmptyState from 'app/components/AlertListEmptyState.vue';
import SiteMenu from 'app/components/dialogs/SiteMenu.vue';
import { AlertWithMetadata, useAlertsWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { useNetworkStatus } from 'app/functions/use-network-status';
import { GAContentInfo } from 'app/keys/analytics-keys';
import { AlertsWithMetadataSymbol } from 'app/keys/injection-keys';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, inject, nextTick, ref } from 'vue';

type State = {
  status: 'ready';
  alerts: AlertWithMetadata[];
} | {
  status: 'loading';
} | {
  status: 'error';
} | {
  status: 'details';
  alert: AlertWithMetadata;
} | {
  status: 'offline';
};

export default defineComponent({
  components: {
    AlertDetails,
    AlertList,
    AlertListEmptyState,
    SiteMenu
  },
  emits: [
    'close'
  ],
  setup: () => {
    const { reachable: network } = useNetworkStatus();
    const mySubLink = { name: RouteName.MySubscriptions };

    const { t } = useI18n();
    const announcement = ref<string | undefined>();

    const showMenu = ref<boolean>(false);
    const alertDetailsAlert = ref<AlertWithMetadata>();

    const {
      alertsWithMetadata,
      error: alertsError,
      fetchAllAlerts,
      reviewAlert,
      deleteAlert,
      deleteAllAlerts
    } = inject(AlertsWithMetadataSymbol, useAlertsWithMetadata());

    const deleteAlertAndAnnounce = async (id: number) => {
      const succeeded = await deleteAlert(id);
      if (succeeded) {
        announcement.value = t('alert.cleared');
      }

      return succeeded;
    };

    const deleteAllAlertsAndAnnounce = async () => {
      const succeeded = await deleteAllAlerts();
      if (succeeded) {
        announcement.value = t('alert.clearedAll');
      }

      return succeeded;
    };

    const hasUnreviewedAlerts = computed(() => {
      if (!alertsWithMetadata.value) { return false; }

      return alertsWithMetadata.value.some((a) => !a.reviewed);
    });

    const state = computed<State>(() => {
      if (!network.value) {
        return {
          status: 'offline'
        };
      }

      if (alertsError.value) {
        return {
          status: 'error'
        };
      }

      if (alertDetailsAlert.value) {
        return {
          status: 'details',
          alert: alertDetailsAlert.value
        };
      }

      if (alertsWithMetadata.value) {
        return {
          status: 'ready',
          alerts: alertsWithMetadata.value || []
        };
      };

      return {
        status: 'loading'
      };
    });

    const openMenu = () => {
      showMenu.value = true;
      APP.tracking.log('view_alerts');
      fetchAllAlerts();
    };

    const closeMenu = () => {
      showMenu.value = false;
      alertDetailsAlert.value = undefined;
      announcement.value = t('alert.header');
    };

    const shouldShowActions = computed(() => ['ready', 'error'].includes(state.value.status));
    const allowClearAll = computed(() =>
      (state.value.status === 'ready' && state.value.alerts.length > 0) ||
      state.value.status === 'error'
    );

    const showAlertDetails = async (alert: AlertWithMetadata) => {
      alertDetailsAlert.value = alert;
      if (!alert.reviewed) {
        reviewAlert(alert.id);

        let contentInfo: GAContentInfo;
        if (alert.type === 'TitleNewRelease' || alert.type === 'TitleNotAvailable') {
          const item = alert.title;

          contentInfo = {
            is_set: false,
            title_slug: item.slug,
            title_name: item.subtitle ? `${item.title} ${item.subtitle}` : item.title
          };
        } else {
          const item = alert.series;

          contentInfo = {
            is_set: true,
            series_slug: item.id.toString(),
            series_name: item.name
          };
        }

        APP.tracking.log('review_alert', { alert_type: alert.type, ...contentInfo });
      }

      await nextTick();
      announcement.value = t('alert.reviewDetails', { type: t(`alert.type.${alert.type}`) });
    };

    const hideAlertDetails = () => {
      alertDetailsAlert.value = undefined;

      announcement.value = t('alert.header');
    };

    useAppEvents({
      'router:navigate': () => {
        if (showMenu.value) {
          closeMenu();
        }
      }
    });

    return {
      // Properties
      alertsWithMetadata,
      alertDetailsAlert,
      allowClearAll,
      announcement,
      hasUnreviewedAlerts,
      mySubLink,
      shouldShowActions,
      showMenu,
      state,

      // Functions
      deleteAlertAndAnnounce,
      deleteAllAlertsAndAnnounce,
      openMenu,
      closeMenu,
      showAlertDetails,
      hideAlertDetails
    };
  }
});
</script>

<style module>
.actions {
  display: grid;
  align-items: center;
  padding: 0.5rem 0rem;
  grid-template-columns: auto auto;
  justify-content: space-between;
  border-bottom: 1px solid var(--c-dark-gray);
}

.link {
  color: var(--c-primary-blue);
  display: inline;
  line-height: var(--lh-body);
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  /* Focus padding */
  padding: 0.25rem 0.5rem;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-dialog h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.loading {
  display: block;
  grid-area: content;
  align-self: center;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

.alert-button {
  border-radius: 0.25rem;
  line-height: 0;
  color: var(--c-white);
}

.alert-button svg {
  --indicator-fill: var(--c-primary-red);

  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  stroke: none;
}

@media screen and (max-width: 1200px /*px-vp-hide-nav*/) {
  .alert-button svg {
    color: var(--c-black);
    width: 1.75rem;
    height: 1.75rem;
  }
}
</style>
