<template>
  <div
    ref="actions"
    :class="$style.actions"
  >
    <TitleActionButton
      :title="title"
      :focus="true"
      :class="$style.titleAction"
    />

    <LoanExpirationIndicator
      v-if="isExpiring && loan"
      :key="loanKeyUUID"
      :loan="(loan as Loan)"
      :iconOnly="iconOnly"
      :class="[$style.loanIndicator, $style.loan]"
    />
    <span
      v-if="loan && isDownloadableLoan"
      :class="[$style.loanIndicator, $style.download]"
    >
      <DownloadIndicator
        :downloader="loan.downloader"
        :iconOnly="iconOnly"
      />
    </span>
    <TagIndicator
      :title="title"
      :class="$style.tag"
    />

    <button
      ref="overflow"
      :class="$style.overflow"
      :aria-label="$t('title.contextMenu.header')"
      aria-haspopup="dialog"
      @click="showContextMenu = true"
    >
      <Icon name="overflow" />
    </button>

    <TitleCardContextMenu
      v-if="showContextMenu"
      :reference="overflow"
      :title="title"
      :subscribeText="subscribeTextLong"
      @close="showContextMenu = false"
      @show:coverview="showCoverView = true"
      @show:priorreleases="showPriorReleases = true"
      @show:tableofcontents="showTableOfContents = true"
      @toggle:subscription="toggleSubscription"
    />

    <CoverView
      v-if="showCoverView"
      :title="title"
      @close="hideOverlay"
    />

    <PriorReleaseSidebar
      v-if="showPriorReleases"
      :title="title"
      @close="hideOverlay"
    />

    <TableOfContentsSidebar
      v-if="showTableOfContents"
      :item="title"
      @close="hideOverlay"
    />
  </div>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import TitleCardContextMenu from 'app/components/contextMenus/TitleCardContextMenu.vue';
import CoverView from 'app/components/CoverView.vue';
import DownloadIndicator from 'app/components/DownloadIndicator.vue';
import LoanExpirationIndicator from 'app/components/LoanExpirationIndicator.vue';
import PriorReleaseSidebar from 'app/components/PriorReleaseSidebar.vue';
import TableOfContentsSidebar from 'app/components/TableOfContentsSidebar.vue';
import TagIndicator from 'app/components/TagIndicator.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import { Loan } from 'app/models/loan';
import { TitleRecord } from 'app/models/title';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, nextTick, onMounted, ref, shallowRef } from 'vue';


export default defineComponent({
  name: 'TitleCardOptions',
  components: {
    DownloadIndicator,
    LoanExpirationIndicator,
    TableOfContentsSidebar,
    TitleActionButton,
    TitleCardContextMenu,
    PriorReleaseSidebar,
    CoverView,
    TagIndicator
},
  props: {
    title: {
      type: Object as () => TitleRecord,
      required: true
    },
    /**
     * Determines whether to only show icons for the title actions (ie/ Expiration and Download indicators)
     * Defaults to false
     */
    actionsIconsOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const loanKeyUUID = ref(generateUUID()); // key to force component reload
    const loan = shallowRef<Loan | undefined>(props.title.loan());

    useAppEvents({
      'loan:update:all': () => {
        updateLoan(props.title.loan());
      },
      'loan:renew': (evt) => {
        if (evt.m.item.titleSlug !== props.title.slug) { return; }
        updateLoan(evt.m.item);
      },
      'loan:return': (evt) => {
        if (evt.m.item.titleSlug !== props.title.slug) { return; }
        updateLoan(undefined);
      }
    });

    const updateLoan = (l?: Loan) => {
      loanKeyUUID.value = generateUUID();
      loan.value = l;
    };

    const isDownloadableLoan = computed(() => (loan.value && APP.shell.has('rosters') && props.title.hasODRFormat));
    const isExpiring = computed(() => (loan.value && !props.title?.isSimultaneousUse));
    const iconOnly = ref<boolean>(props.actionsIconsOnly);

    const showContextMenu = ref(false);
    const overflow = ref<HTMLElement | null>(null);
    const actions = ref<HTMLElement | null>(null);

    const updateIcons = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      const cutoff = 600;

      iconOnly.value = entry.contentRect.width < cutoff;
    };

    const resizeObserver = new ResizeObserver(updateIcons);

    onMounted(() => {
      if (actions.value) {
        resizeObserver.observe(actions.value);
      }
    });


    const showCoverView = ref(false);
    const showPriorReleases = ref(false);
    const showTableOfContents = ref(false);

    const {
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'title card');

    const hideOverlay = async () => {
      showCoverView.value = false;
      showPriorReleases.value = false;
      showTableOfContents.value = false;
      await nextTick();
      overflow.value?.focus();
    };

    return {
      actions,
      hideOverlay,
      iconOnly,
      isDownloadableLoan,
      isExpiring,
      loan,
      loanKeyUUID,
      overflow,
      showContextMenu,
      showCoverView,
      showPriorReleases,
      showTableOfContents,
      subscribeTextLong,
      toggleSubscription
    };
  }
});
</script>

<style module>
.actions {
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  grid-template-areas:
    'titleAction loan download tag overflow';
  grid-gap: 0.5rem;
  align-items: center;
}

.title-action {
  font-weight: var(--fw-bold);
  width: max-content;
  grid-area: titleAction;
}

.title-action > a, .title-action > button {
  min-width: calc(var(--rem-cover-width) - 1rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: var(--form-border-radius);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem;
  color: var(--c-primary-blue);
}

.loan-indicator {
  display: flex;
  align-items: center;
}

.loan-indicator > button > svg {
  fill: var(--c-light-black);
  stroke: var(--c-light-black);
}

.loan {
  grid-area: loan;
}

.download {
  grid-area: download;
}

.tag {
  grid-area: tag;
}

.overflow {
  height: 2.5rem;
  width: 2.5rem;
  line-height: 0;
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: 50%;
  grid-area: overflow;
}

.overflow&:hover, .overflow&:focus {
  border-radius: 1.5rem;
  background-color: var(--c-light-gray);
}

.overflow > svg {
  height: 1.25rem;
  fill: rgba(var(--c-primary-blue-rgb), 1);
}
</style>
