import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileSearchTrigger = _resolveComponent("MobileSearchTrigger")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MobileSearchTrigger, {
      class: _normalizeClass(_ctx.$style.trigger),
      query: _ctx.searchQuery,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.active = true))
    }, null, 8, ["class", "query"]),
    _createVNode(_component_Overlay, {
      show: _ctx.active,
      contentClass: _ctx.$style.panel,
      initialFocus: "#search-box",
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.active = false))
    }, {
      default: _withCtx(() => [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "notch" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.bar)
        }, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(_ctx.$style.cancelButton),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.active = false))
          }, [
            _createVNode(_component_Icon, { name: "chevron-left" })
          ], 2),
          _createVNode(_component_SearchBar, {
            modelValue: _ctx.searchQuery,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchQuery) = $event)),
            mobile: true,
            onSearch: _cache[3] || (_cache[3] = ($event: any) => (_ctx.active = false))
          }, null, 8, ["modelValue"])
        ], 2)
      ], undefined, true),
      _: 1
    }, 8, ["show", "contentClass"])
  ]))
}