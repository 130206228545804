<template>
  <ContextMenu
    ref="contextMenu"
    :reference="reference"
    :headerLabel="$t('title.contextMenu.header')"
    :options="menuOptions"

    @close="closeContextMenu"
  />
</template>

<script lang='ts'>
import ContextMenu, { ContextMenuOption } from 'app/components/contextMenus/ContextMenu.vue';
import { useI18n } from 'app/functions/use-i18n';
import { Title } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';

/**
 * @desc - The title card context menu component
 */
export default defineComponent({
  name: 'TitleCardContextMenu',
  components: {
    ContextMenu
  },
  props: {
    /**
     * @param title - The series to display context options for
     */
    title: {
      type: Title,
      required: true
    },
    /**
     * @param reference - The element to anchor this context menu to
     */
    reference: {
      type: HTMLElement,
      required: true
    },
    subscribeText: {
      type: String,
      required: true
    }
  },
  emits: [
    'close',
    'show:coverview',
    'show:priorreleases',
    'show:tableofcontents',
    'toggle:subscription'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const menuOptions = [] as ContextMenuOption[];

    const closeContextMenu = () => {
      ctx.emit('close');
    };

    const hasPriorReleases = computed(() => {
      return !!props.title.lexisMetadata?.priorReleases?.length;
    });

    const viewCover = () => {
      ctx.emit('show:coverview');
      closeContextMenu();
    };

    const viewPriorReleases = () => {
      ctx.emit('show:priorreleases');
      closeContextMenu();
    };

    const viewToC = () => {
      ctx.emit('show:tableofcontents');
      closeContextMenu();
    };

    const toggleSubscription = () => {
      ctx.emit('toggle:subscription');
      ctx.emit('close');
    };

    // Menu Options:
    menuOptions.push({
      label: t('title.contextMenu.viewCover'),
      handler: viewCover,
      hasPopup: 'dialog'
    });
    menuOptions.push({
      label: t('title.contextMenu.viewToC'),
      handler: viewToC,
      hasPopup: 'dialog'
    });
    menuOptions.push({
      label: t('title.contextMenu.viewDetailsPage'),
      location: { name: RouteName.TitleDetails, params: { titleSlug: props.title.slug } }
    });
    menuOptions.push({
      label: props.subscribeText,
      handler: toggleSubscription
    });
    if (hasPriorReleases.value) {
      menuOptions.push({
        label: t('title.contextMenu.viewPriorReleases'),
        handler: viewPriorReleases,
        hasPopup: 'dialog'
      });
    }

    return {
      closeContextMenu,
      menuOptions,
      viewCover,
      viewPriorReleases,
      viewToC
    };
  }
});
</script>

<style module>
</style>
