
import { getReleaseDisplayNameTitle } from 'app/functions/use-release-display-name';
import { FilterObject } from 'app/models/filter-object';
import { TitleRecord } from 'app/models/title';
import { computed, Ref, ref } from 'vue';

type ReleaseFilterable = {
  release: string;
  releaseDate: string;
};

export function useReleaseFilter<T extends ReleaseFilterable>(items: Ref<T[]>, releases: Readonly<Ref<TitleRecord[]>>) {
  const releaseFilter = ref<string[] | undefined>(undefined);

  const groupByRelease = (filterableItems: T[]) => {
    return releases.value.reduce((obj, release) => {
      obj[release.slug] = filterableItems.filter((i) =>
        i.release === release.lexisMetadata?.release &&
        i.releaseDate === release.lexisMetadata?.releaseDate);

      return obj;
    }, {} as Record<string, T[]>);
  };

  const byRelease = computed<Record<string, T[]>>(() => groupByRelease(items.value));

  const filteredItems = computed<T[]>(() => {
    return releaseFilter.value && releaseFilter.value.length
      ? releaseFilter.value.reduce((matches, releaseId) => {
          const forRelease = byRelease.value[releaseId] || [];

          return [...matches, ...forRelease];
        }, [] as T[])
      : items.value;
  });

  const filterObjects = computed(() => {
    return releases.value.reduce((options, release) => {
      const matchingAnnotations = byRelease.value[release.slug] || [];

      if (matchingAnnotations.length) {
        const filterObj: FilterObject = {
          id: release.slug,
          category: 'Release',
          name: getReleaseDisplayNameTitle(release),
          selected: !!releaseFilter.value?.includes(release.slug)
        };

        return [...options, filterObj];
      }

      return options;
    }, [] as FilterObject[]);
  });

  /* We don't directly compute this off of filteredItems,
   * because we may want to layer multiple filters before we generate the options
   */
  const getFilterObjects = (fullyFilteredItems: T[]) => {
    const itemsByRelease = groupByRelease(fullyFilteredItems);

    const filterObjs = filterObjects.value.filter((fo) => itemsByRelease[fo.id]?.length || fo.selected);

    // Fill in counts
    filterObjs.forEach((fo) => {
      fo.count = (itemsByRelease[fo.id]?.length || 0).toString();
    });

    return filterObjs;
  };

  return {
    releaseFilter,
    filteredItems,
    getFilterObjects
  };
};
