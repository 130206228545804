<template>
  <section :class="$style.container">
    <button
      ref="tagIcon"
      :aria-label="$t('tags.indicator', { COUNT: tagCount })"
      aria-haspopup="dialog"
      :class="$style.indicatorButton"
      @click="showTagWidget = true"
    >
      <Icon
        name="nav-tags"
        :class="$style.cardIcon"
      />
      <div :class="$style.badge">
        {{ tagCount }}
      </div>
    </button>
    <PopoutDialog
      v-if="showTagWidget && tagIcon"
      :class="$style.popout"
      :reference="tagIcon"
      :headerLabel="$t('tags.header')"
      @close="showTagWidget = false"
    >
      <TagWidget
        :title="title"
        :restrictHeight="true"
      />
    </PopoutDialog>
  </section>
</template>

<script lang="ts">
import PopoutDialog from 'app/components/dialogs/PopoutDialog.vue';
import TagWidget from 'app/components/TagWidget.vue';
import { usePatron } from 'app/functions/use-patron';
import { Tag } from 'app/models/tag';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    PopoutDialog,
    TagWidget
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    }
  },
  setup: (props) => {
    const tagIcon = ref<HTMLButtonElement | null>(null);

    const showTagWidget = ref(false);

    const { tags } = usePatron();
    const tagCount = computed(() => {
      const forTitle = tags.value
        .filter(Tag.FILTER_FUNCTIONS.nonSystemTags)
        .filter((tag) => tag.isAppliedToTitle(props.title.slug));

      return forTitle.length > 99 ? '99+' : forTitle.length.toString();
    });

    return {
      showTagWidget,
      tagCount,
      tagIcon
    };
  }
});
</script>

<style module>

.container {
  display: flex;
  align-items: center;
}

.indicator-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.indicator-button > * {
  margin: 0;
}

.card-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--c-light-black);
  stroke: none;
}

.badge {
  composes: badge from global;
  margin-left: 0;
}

.popout {
  overflow: visible;
}
</style>
