import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Item } from 'app/models/item';
import { TitleRecord } from '../models/title';

/**
 * Maintains the currently active title information
 */
export class ActiveTitle extends Item {
  private readonly BANK_KEY = 'active-title';

  public isNewRelease = false;
  private _anchorID: string;
  private _parentID: string;
  private _titleSlug: string;
  private _title: TitleRecord;

  /**
   * The current active anchorID
   */
  public get anchorID(): string {
    return this._anchorID;
  }

  /**
   * The current active title
   */
  public get title(): TitleRecord {
    return this._title;
  }


  /**
   * The current active parentID
   */
  public get parentID(): string {
    return this._parentID;
  }


  /**
   * Designate a title as the current active title
   * @param titleSlug The slug of the title to activate
   * @param anchorID An anchorID to open to
   * @param parentID The parent of this title
   */
  public activate(titleSlug: string, anchorID?: string, parentID?: string): void {
    console.log(`[ACTIVE-TITLE] Activating new active title (${titleSlug})[${anchorID}] Parent:${parentID}`);
    this._anchorID = anchorID;
    this._titleSlug = titleSlug;
    this._parentID = parentID;
    this.isNewRelease = false;
    APP.bank.dump(this.BANK_KEY, this);

    this._title = APP.library.titles.fetch(titleSlug);
    this._title?.activate();
  }


  /**
   * Anchors should only live until they've been used
   */
  public clearAnchor(): void {
    console.log('[ACTIVE-TITLE] Deactivating active anchor', this._anchorID);
    this._anchorID = null;
  }


  /**
   * Removes the current active title
   */
  public deactivate(anotherActive: boolean): void {
    console.log(`[ACTIVE-TITLE] Deactivating active title (${this._titleSlug})[${this._anchorID}]`);

    this._title?.deactivate(anotherActive);
    this._anchorID = null;
    this._title = null;
    this._titleSlug = null;
    this._parentID = null;
    this.isNewRelease = false;

    APP.bank.set(this.BANK_KEY, null);
  }


  /**
   * Returns whether there is a title currently active or not.
   */
  public isActive(): boolean {
    return !!this._title;
  }


  /**
   * Returns whether the referenced titleSlug is the currently active title or not.
   * @param titleSlug The slug of the title to check
   * @returns True if the requested titleSlug is the current active title, otherwise; false
   */
  public isActiveTitle(titleSlug: string): boolean {
    return this._titleSlug === titleSlug;
  }


  /**
   * Restores the last title in the bank as the current active title
   */
  public restore(): void {
    // console.log('[ACTIVE-TITLE] Restoring active title');
    const attrs = APP.bank.get(this.BANK_KEY);
    this._deserializeAttributes(attrs);

    if (this._title) {
      this.activate(this._titleSlug, this._anchorID);
    }
  }


  // -- Protected Methods --
  protected _deserializeAttributes(attrs) {
    if (!attrs) { return; }

    this._titleSlug = C.excise(attrs, 'titleSlug');

    if (this._titleSlug) {
      this._title = APP.titleCache.get(this._titleSlug);
    }
    super._deserializeAttributes(attrs);
  }


  protected _serializeAttributes() {
    return {
      titleSlug: this._titleSlug
    };
  }
}
