import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "notch" }, null, -1)),
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.bar)
    }, [
      (_ctx.backButtonLabel)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(_ctx.$style.headerLink),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
          }, _toDisplayString(_ctx.backButtonLabel), 3))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: _ctx.helpLink,
        target: "_blank",
        class: _normalizeClass(_ctx.$style.headerLink)
      }, _toDisplayString(_ctx.$t('welcome.header.help')), 11, _hoisted_1)
    ], 2)
  ], 2))
}