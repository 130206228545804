<template>
  <section :class="$style.container">
    <h1>{{ $t('home.recentlyRead.header') }}</h1>
    <template v-if="!recentlyReadTitles || recentlyReadTitles.length">
      <section :class="$style.carousel">
        <ol
          ref="carouselList"
          :class="$style.carouselList"
        >
          <li
            v-for="(item, index) in (recentlyReadTitles || [])"
            :key="item.slug"
          >
            <RecentlyReadTitleCard
              :title="item"
              @vfocus="onSlideFocus(index)"
            />
          </li>
        </ol>
        <div
          ref="curtain"
          :class="$style.curtain"
        ></div>
      </section>
      <section
        :class="$style.carouselActions"
        aria-hidden="true"
      >
        <button
          tabindex="-1"
          :disabled="currentPage <= 1"
          @click="previousPage"
        >
          <Icon name="chevron-left" />
        </button>

        <div :class="$style.breadcrumbs">
          <button
            v-for="index in numberPages"
            :key="index"
            :class="[$style.dot, index === currentPage ? $style.currentPage : '']"
            tabindex="-1"
            :disabled="index == currentPage"
            @click="goToPage(index)"
          ></button>
        </div>

        <button
          tabindex="-1"
          :disabled="currentPage >= numberPages"
          @click="nextPage"
        >
          <Icon name="chevron-right" />
        </button>
      </section>
    </template>
    <EmptyState
      v-else
      :class="$style.emptyState"
      :link="helpLink"
      :isCompact="true"
      :isDarkMode="true"
      :header="$t('home.recentlyRead.emptystate.header')"
      :content="$t('home.recentlyRead.emptystate.content')"
      :linkLabel="$t('home.recentlyRead.emptystate.link')"
    />

    <div :class="$style.recentlyReadActions">
      <router-link
        :class="$style.viewAll"
        to="mybooks#history"
      >
        {{ $t('home.recentlyRead.viewAll') }}
      </router-link>
    </div>
  </section>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { defineComponent, ref, onMounted, computed, onBeforeUnmount, nextTick } from 'vue';
import { RecentlyReadTitle } from 'app/models/recently-read-title';
import { TitleRecord } from 'app/models/title';
import EmptyState from 'app/components/EmptyState.vue';
import RecentlyReadTitleCard from 'app/components/RecentlyReadTitleCard.vue';
import { Scroller } from 'app/views/core/scroller';
import { Constants } from 'app/base/constants';
import { usePatron } from 'app/functions/use-patron';

export default defineComponent({
  name: 'RecentlyRead',
  components: {
    EmptyState,
    RecentlyReadTitleCard
  },
  setup: (props, ctx) => {
    const helpLink = Constants.HELP_PATHS.GET_STARTED;
    const MAX_TITLES = 6;
    const carouselList = ref<HTMLElement | null>(null);
    const curtain = ref<HTMLElement | null>(null);

    const { recentlyRead } = usePatron();
    const recentlyReadTitleIDs = computed(() => {
      return (recentlyRead.value as RecentlyReadTitle[])
        .sort(RecentlyReadTitle.SORT_FUNCTIONS.accessed)
        .slice(0, MAX_TITLES)
        .map((t) => t.titleSlug);
    });
    const recentlyReadTitles = ref<TitleRecord[] | null>(null);

    const numberPages = ref(1);
    const currentPage = ref(1);
    const slideWidth = 304;
    let slidesPerPage = 1;

    const recalculatePaging = (evt = null) => {
      calculatePages();
      calculateCurrentPage();
    };

    const calculatePages = () => {
      if (!carouselList.value || !carouselList.value.children.length) { return; }

      const childCount = recentlyReadTitles.value?.length || 0;
      slidesPerPage = Math.max(1, Math.floor(carouselList.value.offsetWidth / slideWidth));
      numberPages.value = Math.ceil(childCount / slidesPerPage);
      const leftoverSpace = carouselList.value.offsetWidth - slidesPerPage * slideWidth;
      const extraSlides = slidesPerPage - ((childCount % slidesPerPage) || slidesPerPage);
      const leftoverSpaceWithEmptyCards = leftoverSpace + slideWidth * extraSlides;
      const lastChild = carouselList.value.children[carouselList.value.children.length - 1] as HTMLElement;
      lastChild.style.paddingRight = `calc(${leftoverSpaceWithEmptyCards}px + 48px)`;
      lastChild.style.width = slideWidth + leftoverSpaceWithEmptyCards + '8px';

      if (curtain.value) {
        curtain.value.style.width = slidesPerPage === 1 ? '2rem' : leftoverSpace + 'px';
      }
    };

    const calculateCurrentPage = () => {
      const carousel = carouselList.value;
      if (!carousel) { return; }

      const pageWidth = slidesPerPage * slideWidth;
      const normalizedPage = Math.ceil(carousel.scrollLeft / pageWidth - 0.5);
      currentPage.value = Math.max(0, Math.min(normalizedPage + 1, numberPages.value));
    };

    const staggeredPaging = C.staggerInvocation(recalculatePaging, 250);
    const staggeredCurrentPage = C.staggerInvocation(calculateCurrentPage, 100);

    const nextPage = C.staggerInvocation(() => {
      scrollToPage(currentPage.value + 1);
    }, Scroller.DEFAULT_DURATION);

    const previousPage = C.staggerInvocation(() => {
      scrollToPage(currentPage.value - 1);
    }, Scroller.DEFAULT_DURATION);

    const goToPage = (i: number) => {
      scrollToPage(i);
    };

    const onSlideFocus = (slideIndex: number) => {
      const currentPageSlideIndex = (currentPage.value - 1) * slidesPerPage;
      // No need to handle previousPage() case (slideIndex < currentPageSlideIndex)
      if (slideIndex > currentPageSlideIndex + slidesPerPage - 1) {
        scrollToPage(currentPage.value + 1);
      }
    };


    let animationCancel = { id: 0 };
    const scrollToPage = (n: number) => {
      if (!carouselList.value) { return; }

      window.cancelAnimationFrame(animationCancel.id);

      animationCancel = Scroller.smoothScroll(
        (slideWidth * slidesPerPage) * (n - 1),
        'x',
        Scroller.DEFAULT_DURATION,
        carouselList.value
      );
    };


    const resizeHandler = () => staggeredPaging();
    const orientationHandler = () => recalculatePaging();
    const scrollHandler = () => staggeredCurrentPage();

    onMounted (async () => {
      // Data
      recentlyReadTitles.value = await APP.titleCache.getFreshTitles(recentlyReadTitleIDs.value);

      // Event listeners
      window.addEventListener('resize', resizeHandler);
      window.addEventListener('orientationchange', orientationHandler);
      carouselList.value?.addEventListener('scroll', scrollHandler);

      nextTick(() => recalculatePaging(null));
    });

    onBeforeUnmount (() => {
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('orientationchange', orientationHandler);
      carouselList.value?.removeEventListener('scroll', scrollHandler);
    });

    return {
      carouselList,
      curtain,
      helpLink,

      nextPage,
      previousPage,
      goToPage,
      onSlideFocus,

      currentPage,
      numberPages,

      recentlyReadTitles
    };
  }
});
</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  @gutter: 2rem;
  @dotSize: 0.75rem;

  // IF YOU CHANGE THIS,
  // YOU NEED TO CHANGE `slideWidth` IN SETUP
  @slide-width: calc(224px + 5rem);

  .container {
    grid-row: 1;
    grid-column: 1 / span 2;

    display: grid;
    box-sizing: border-box;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr auto;

    background-color: var(--c-dark-blue);
    color: @c-white;
    border-radius: .5rem;

    &> h1 {
      font-size: @fs-large-head;
      font-family: @ff-head;
      font-weight: @fw-bold;
      line-height: 1;

      grid-row: 1;
      grid-column: 1;

      padding: 2 * @gutter 0 0 @gutter;
    }
  }

  @media screen and (max-width: @px-vp-narrow) {
    .container > h1 {
      font-size: @fs-medium-head;
      padding: @gutter 0 0 @gutter / 2;
    }
  }

  .carousel {
    grid-row: 2;
    grid-column: 1 / span 2;
    box-sizing: border-box;
    position: relative;
    overscroll-behavior: contain;
    overflow-x: auto;

    .carousel-list {
      display: flex;
      overflow-x: scroll;
      .no-scrollbar;
      scroll-snap-type: x mandatory;
      min-height: 267px; // CALCULATED FROM CARD HEIGHT

      li {
        padding: 2rem @gutter 3rem;
        scroll-snap-align: start;
        box-sizing: border-box;
        padding-top: 3.375rem;
      }

      li:not(:last-child) {
        min-width: @slide-width;
        width: @slide-width;
        max-width: @slide-width;
        display: flex;
      }

      @media screen and (max-width: @px-vp-narrow) {
        li {
          padding: @gutter 1rem 3rem;
        }
      }

    }
  }

  .empty-card {
    width: @slide-width;
  }

  .curtain {
    background: linear-gradient(90deg, rgba(14,42,67,0) 0%, rgba(14,42,67,0.5) 50%, rgba(14,42,67,1) 100%);
    opacity: 0.9;
    position: absolute;
    top: 1.5rem;
    bottom: 0;
    right: 0;

    pointer-events: none;
  }

  .carousel-actions {
    background-color: var(--c-darkest-blue);
    color: @c-white;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    line-height: 0;
    position: relative;
    grid-column: 1 / end;
    padding: .75rem 0;

    @media screen and (max-width: @px-vp-narrow) {
      justify-content: space-between;
    }

    .breadcrumbs {
      grid-row: 1;
      grid-column: 2;
      margin: 0 2rem;

      cursor: pointer;
    }

    .dot {
      height: @dotSize;
      width: @dotSize;
      margin: 0 0.25em;
      background-color: @c-darkest-gray;
      border-radius: 50%;
      display: inline-block;
      transition: transform 150ms;
    }

    .current-page {
      background-color: @c-light-gray;
      transform: scale(1.2);
    }

    button {
      &:focus {
        box-shadow: none;
      }

      svg {
        width: 2em;
        height: 3em;
        fill: @c-white;
        padding: 0 0.5em;
      }

      &:disabled svg {
        fill: @c-light-black;
      }
    }
  }

  .recently-read-actions {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: @gutter @gutter 0 0;

    .view-all {
      display: inline-block;
      .pop(@c-white);
      .focus-outline-dark;
    }
  }

  .empty-state {
    // override the margin from EmptyState.isCompact=true
    // NOTE: we still want the logo image that comes with isCompact=true
    margin: 2rem auto !important;

    grid-column: 1 / end;
  }
</style>
