import type { SentrySyncResponse } from 'app/base/sentry';


export type AuthErrorWithCustom =
  | {
    code: Exclude<AuthError, AuthError.Custom | AuthError.Unknown>;
  }
  | {
    code: AuthError.Custom;
    message: string;
  }
  | {
    code: AuthError.Unknown;
    data: any;
  };

export const enum AuthError {
  Custom = 'Custom',
  CaptchaRequired = 'CaptchaRequired',
  InvalidCredentials = 'InvalidCredentials',
  RegistrationIneligible = 'RegistrationIneligible',
  RegistrationInvalidCode = 'RegistrationInvalidCode',
  Unsupported = 'Unsupported',
  Unknown = 'Unknown',

  /* Library error codes */
  ExcessiveFines = 'ExcessiveFines',
  OverdueItems = 'OverdueItems',
  ExpiredCard = 'ExpiredCard',
  LostCard = 'LostCard',
  StolenCard = 'StolenCard',
  PrivilegesRevoked = 'PrivilegesRevoked',
  InvalidAccount = 'InvalidAccount',
  RecordDeleted = 'RecordDeleted'
};


export type AuthResult =
| {
  state: 'success';
  response: SentrySyncResponse;
}
| {
  state: 'code';
}
| (
  { state: 'error' }
  & AuthErrorWithCustom
);
