import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_TitleDetailsContent = _resolveComponent("TitleDetailsContent")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      (_ctx.state.state === 'loading')
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : (_ctx.state.state === 'notfound')
          ? (_openBlock(), _createBlock(_component_NotFound, {
              key: 1,
              messageText: _ctx.$t(_ctx.state.label)
            }, null, 8, ["messageText"]))
          : (_openBlock(), _createBlock(_component_TitleDetailsContent, {
              key: 2,
              title: _ctx.state.title,
              series: _ctx.state.series,
              onLoan: !!_ctx.loan,
              annotationCount: _ctx.annotationCount,
              recommended: _ctx.recommended,
              searchThisTitle: _ctx.searchParameters
            }, null, 8, ["title", "series", "onLoan", "annotationCount", "recommended", "searchThisTitle"]))
    ], undefined, true),
    _: 1
  }))
}