<template>
  <div :class="$style.container">
    <div class="notch"></div>

    <header :class="$style.bar">
      <button
        v-if="backButtonLabel"
        :class="$style.headerLink"
        @click="$emit('back')"
      >
        {{ backButtonLabel }}
      </button>

      <a
        :href="helpLink"
        target="_blank"
        :class="$style.headerLink"
      >
        {{ $t('welcome.header.help') }}
      </a>
    </header>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'WelcomeHeader',
  props: {
    backButtonLabel: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'back'
  ],
  setup: () => {
    return {
      helpLink: Constants.HELP_PATHS.HOME
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  background-color: var(--c-darkest-blue);
  border-bottom: 2px solid @c-primary-red;
  position: sticky;
  top: -1rem;
  z-index: 100;
}

.bar {
  padding: 1rem 1.75rem;
  display: flex;
  margin-top: -1rem;

  .header-link:last-child {
    margin-left: auto;
  }
}

.header-link {
  color: @c-white;
  .pop-text();
  .focus-outline-dark;
}

@media screen and (max-width: @px-vp-narrow) {
  .bar {
    padding: 1rem 2rem;
  }
}
</style>
