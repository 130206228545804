import { PostishCopyJobResponse, PostishCopyStatus } from 'app/base/postish';
import { buildCopyJobContext } from 'app/contexts/copy-job-context';
import { CopyJobContextSymbol } from 'app/keys/injection-keys';
import { DeepReadonly, Ref, computed, inject, readonly, ref } from 'vue';

export function useCopyJobs(titleId?: string) {
  const {
    copyJobs: copyJobsRaw,
    getAllCopyJobs
  } = inject(CopyJobContextSymbol, buildCopyJobContext());

  const copyJobs = computed(() => sortJobs(filterJobs(copyJobsRaw.value)));

  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const update = async () => {
    try {
      loading.value = true;

      await getAllCopyJobs();

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  const filterJobs = (jobs: PostishCopyJobResponse[] | null) => {
    if (!jobs) { return null; }

    if (titleId) {
      return jobs.filter((job) => job.target_title.title_id === titleId);
    }

    return jobs;
  };

  const sortJobs = (jobs: PostishCopyJobResponse[] | null) => {
    if (!jobs) { return null; }

    const statusPriority: { [key in PostishCopyStatus | 'FINISHED_REVIEWED']: number } = {
      ERROR: 0,
      QUEUED: 1,
      IN_PROGRESS: 1,
      FINISHED: 2,
      FINISHED_REVIEWED: 3
    };

    return jobs.slice().sort((a, b) => {
      const aStatus = a.status === 'FINISHED' && a.reviewed ? 'FINISHED_REVIEWED' : a.status;
      const bStatus = b.status === 'FINISHED' && b.reviewed ? 'FINISHED_REVIEWED' : b.status;

      if (statusPriority[aStatus] < statusPriority[bStatus]) { return -1; }
      if (statusPriority[aStatus] > statusPriority[bStatus]) { return 1; }

      return Date.parse(b.created_at) - Date.parse(a.created_at);
    });
  };

  return {
    copyJobs: readonly(copyJobs) as DeepReadonly<Ref<PostishCopyJobResponse[]>>,
    loading: readonly(loading),
    error: readonly(error),
    update
  };

}
