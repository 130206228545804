import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecentlyRead = _resolveComponent("RecentlyRead")!
  const _component_HomeAnnotationList = _resolveComponent("HomeAnnotationList")!
  const _component_HomeTagList = _resolveComponent("HomeTagList")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_RecentlyRead, {
        class: _normalizeClass(_ctx.$style.recentlyRead)
      }, null, 8, ["class"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.bottom)
      }, [
        _createVNode(_component_HomeAnnotationList),
        _createVNode(_component_HomeTagList)
      ], 2)
    ], undefined, true),
    _: 1
  }))
}