import { APP } from 'app/base/app';
import { Tracking } from 'app/base/tracking/tracking';
import { inject, InjectionKey, provide, readonly, Ref, ref } from 'vue';

interface PrivacySettingsContext {
  defaultSetting: Readonly<Ref<boolean>>;
  shouldShowSettings: Ref<boolean>;
  showSettings: () => void;
  hideSettings: () => void;
  saveSettings: (val: boolean) => void;
};


export const PrivacySettingsSymbol: InjectionKey<PrivacySettingsContext> = Symbol('privacy-settings');

const buildPrivacySettingsContext = () => {
  const defaultSetting = ref(false);
  const shouldShowSettings = ref(false);

  const showSettings = () => {
    defaultSetting.value = Tracking.getBankedValue() || false;
    shouldShowSettings.value = true;
  };

  const hideSettings = () => {
    shouldShowSettings.value = false;
  };

  const saveSettings = (val: boolean) => {
    if (val) {
      APP.tracking.enable();
    } else {
      APP.tracking.disable();
    }
  };

  const context: PrivacySettingsContext = {
    defaultSetting: readonly(defaultSetting),
    shouldShowSettings: readonly(shouldShowSettings),
    showSettings,
    hideSettings,
    saveSettings
  };

  return context;
};

export const providePrivacySettingsContext = () => {
  const context = buildPrivacySettingsContext();

  provide(PrivacySettingsSymbol, context);

  return context;
};

export const injectPrivacySettingsContext = () => {
  const context = inject(PrivacySettingsSymbol);

  // If we try to inject without a provided context, print an error but create a non-provided context for use in-place.
  if (!context) {
    console.error('Privacy settings context not provided');

    return buildPrivacySettingsContext();
  }

  return context;
};
