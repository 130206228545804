import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "coverContainer",
    class: _normalizeClass(_ctx.$style.container),
    style: _normalizeStyle([`--c-set-color: ${_ctx.series.coverColor || ''}`, `--rem-cover-width: ${_ctx.width + 'rem'}`])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.stack, _ctx.$style.stack3, 'relieve'])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.stack, _ctx.$style.stack2, 'relieve'])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.stack, _ctx.$style.stack1, 'relieve'])
    }, null, 2),
    _createVNode(_component_Cover, {
      item: _ctx.series,
      lazy: false,
      class: _normalizeClass([_ctx.$style.cover, _ctx.$style.stack0, 'relieve'])
    }, null, 8, ["item", "class"])
  ], 6))
}