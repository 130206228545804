<template>
  <button
    v-bind="$attrs"
    id="context-menu"
    ref="contextMenuButton"
    :aria-label="$t('title.contextMenu.header')"
    aria-haspopup="dialog"
    :class="{
      [$style.contextMenuButton]: true,
      ['dark']: darkStyle
    }"
    type="button"
    @click="showContextMenu = true"
  >
    <div v-if="!hideLabel">
      {{ $t('title.contextMenu.header') }}
    </div>
    <Icon name="overflow" />
  </button>
  <TitleActionsContextMenu
    v-if="showContextMenu"
    :reference="contextMenuButton"
    :title="title"
    :includeCopying="includeCopying"
    :allowCopying="allowManualCopy"
    :subscribeText="subscribeTextLong"
    @close="showContextMenu = false"
    @show:cover="showCoverView = true"
    @show:tableofcontents="showTableOfContents = true"
    @show:manualcopy="showManualCopy = true"
    @show:managecopying="showManageCopying = true"
    @toggle:subscription="toggleSubscription"
  />
  <TableOfContentsSidebar
    v-if="showTableOfContents"
    :item="title"
    @close="hideOverlay"
  />
  <CoverView
    v-if="showCoverView"
    :title="title"
    @close="hideOverlay"
  />
  <ManualCopySidebar
    v-if="showManualCopy"
    :title="title"
    :releasesWithAnnotations="releasesWithAnnotations"
    @close="hideOverlay"
  />
  <ManageCopyingSidebar
    v-if="showManageCopying"
    :title="title"
    :copyJobsState="copyJobsState"
    @close="hideOverlay"
  />
</template>

<script lang="ts">
import TitleActionsContextMenu from 'app/components/contextMenus/TitleActionsContextMenu.vue';
import CoverView from 'app/components/CoverView.vue';
import ManageCopyingSidebar from 'app/components/ManageCopyingSidebar.vue';
import ManualCopySidebar from 'app/components/ManualCopySidebar.vue';
import TableOfContentsSidebar from 'app/components/TableOfContentsSidebar.vue';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { getReleaseDisplayNameTitle } from 'app/functions/use-release-display-name';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import { ADCopyJobState, ADReleasesWithAnnotationsSymbol } from 'app/keys/injection-keys';
import { FilterObject } from 'app/models/filter-object';
import { TitleRecord } from 'app/models/title';
import { ComputedRef, defineComponent, inject, nextTick, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    CoverView,
    ManageCopyingSidebar,
    ManualCopySidebar,
    TitleActionsContextMenu,
    TableOfContentsSidebar
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    includeCopying: {
      type: Boolean,
      required: false,
      default: true
    },
    darkStyle: {
      type: Boolean,
      default: true
    }
  },
  setup: (props) => {
    const showContextMenu = ref<boolean>(false);
    const showCoverView = ref<boolean>(false);
    const showTableOfContents = ref<boolean>(false);
    const showManualCopy = ref<boolean>(false);
    const showManageCopying =  ref<boolean>(false);

    const releasesWithAnnotations = inject(ADReleasesWithAnnotationsSymbol, undefined) as ComputedRef<FilterObject[]> | undefined;
    const allowManualCopy = !!(releasesWithAnnotations?.value.filter((release) => release.name !== getReleaseDisplayNameTitle(props.title)).length);
    const copyJobsState = inject(ADCopyJobState, undefined) as ComputedRef<CopyJobsState> | undefined;

    const contextMenuButton = ref<HTMLElement | null>(null);

    const {
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'annotation details / export queue page');

    const hideOverlay = async () => {
      showCoverView.value = false;
      showTableOfContents.value = false;
      showManualCopy.value = false;
      showManageCopying.value = false;

      await nextTick();
      contextMenuButton.value?.focus();
    };

    return {
      allowManualCopy,
      contextMenuButton,
      copyJobsState,
      releasesWithAnnotations,
      showContextMenu,
      showCoverView,
      showManageCopying,
      showManualCopy,
      showTableOfContents,
      subscribeTextLong,
      hideOverlay,
      toggleSubscription
    };
  }
});
</script>

<style module>
.context-menu-button {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.context-menu-button svg {
  fill: var(--c-white);
  width: 1.5rem;
  height: 1.5rem;
}

</style>
