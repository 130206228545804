import { Roster } from './roster';
import { Dictionary } from '../common';

export class RosterDervish extends Roster {
  private _callbacks: Dictionary<() => void> = {};


  public registerCallbacks(callbacks: Dictionary<() => void>) {
    this._callbacks = callbacks;
  }


  protected _updateInitialized(): void {
    this._invokeCallback('initialize');
  }


  protected _updateProgress(): void {
    this._invokeCallback('progress');
  }


  protected _updateFinalized(): void {
    this._invokeCallback('finalize');
  }


  protected _updateInvalidated(): void {
    this._invokeCallback('invalidate');
  }


  protected _invokeCallback(cbName: string): void {
    if (this._callbacks) {
      const cb = this._callbacks[cbName];
      if (typeof cb === 'function') {
        cb();
      }
    }
  }
}
