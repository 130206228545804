<template>
  <span
    :class="$style.color"
    :style="`--highlight-color: var(--highlight-color-${colorGroup})`"
  >
    {{ $t(`annotations.color.${colorGroup}`) }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HighlightColor',
  props: {
    colorGroup: {
      type: String,
      required: true
    }
  }
});
</script>

<style module>
.color {
  display: inline-block;
  background-color: var(--highlight-color, var(--c-dark-gray));
  color: var(--c-black);
  padding: 8px 10px;
  border-radius: 2rem;
  line-height: 1;
}
</style>
