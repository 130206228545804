import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_WelcomeView = _resolveComponent("WelcomeView")!

  return (_openBlock(), _createBlock(_component_WelcomeView, {
    backButtonLabel: _ctx.backButtonLabel,
    onBack: _ctx.onBackClicked
  }, {
    default: _withCtx(() => [
      (_ctx.targetLibrary)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: _normalizeClass(_ctx.$style.container)
          }, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('redirect.header')), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('redirect.description', { CURRENT_LIBRARY: _ctx.currentLibraryName, TARGET_LIBRARY: _ctx.targetLibraryName })), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('redirect.instructions', { CURRENT_LIBRARY: _ctx.currentLibraryName, TARGET_LIBRARY: _ctx.targetLibraryName })), 1),
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.$style.submit),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signOutAndContinue && _ctx.signOutAndContinue(...args)))
            }, _toDisplayString(_ctx.$t('redirect.continue')), 3)
          ], 2))
        : (_openBlock(), _createElementBlock("section", {
            key: 1,
            class: _normalizeClass(_ctx.$style.loading)
          }, [
            _createVNode(_component_Icon, { name: "spinner" })
          ], 2))
    ], undefined, true),
    _: 1
  }, 8, ["backButtonLabel", "onBack"]))
}