<template>
  <Surface>
    <Page
      :header="$t('ntc.header')"
      :announceHeader="$t('ntc.ariaHeader')"
      :class="$style.page"
    >
      <Icon
        v-if="state === 'loading'"
        name="spinner"
        :class="$style.loading"
        :aria-label="$t('general.loading')"
      />
      <ol
        v-else-if="providers.length"
        :class="$style.cardList"
      >
        <li
          v-for="provider in providers"
          :key="provider.id"
        >
          <NtcTitleCard
            :provider="provider"
            :error="ntcError === provider.id"
            @access="showPrompt(provider.id)"
            @clearError="ntcError = ''"
          />
        </li>
      </ol>
      <EmptyState
        v-else
        :class="$style.empty"
        :link="helpLink"
        :header="$t('ntc.emptystate.header')"
        :content="$t('ntc.emptystate.content')"
        :linkLabel="$t('ntc.emptystate.link', {
          b: (val) => { return `<b>${val}</b>`; }
        })"
      />
    </Page>
  </Surface>
</template>

<script lang="ts">
import { Constants } from 'app/base/constants';
import NtcTitleCard from 'app/components/cards/NtcTitleCard.vue';
import EmptyState from 'app/components/EmptyState.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import { useNtcContext } from 'app/contexts/use-ntc-context';
import { usePatron } from 'app/functions/use-patron';
import { NtcContextSymbol } from 'app/keys/injection-keys';
import { Loan } from 'app/models/loan';
import { computed, defineComponent, inject, watch } from 'vue';
import { useRoute } from 'vue-router';

export type NtcProvider = {
  id: string;
  name: string;
  description: string;
  backgroundColor: [number, number, number];
  icon: string;
  loan?: Loan;
  unavailable: boolean;
};

export default defineComponent({
  name: 'Ntc',
  components: {
    EmptyState,
    NtcTitleCard,
    Page,
    Surface
  },
  setup: (props, ctx) => {
    const { getNtcContent, ntcState, isProviderUnavailable, ntcError, show: showPrompt } = inject(NtcContextSymbol, useNtcContext());
    const { loans } = usePatron();

    const route = useRoute();
    watch(route, () => {
      getNtcContent(); //ensure ntc is up to date when visiting the page
    }, { immediate: true, flush: 'post' });

    const providers = computed(() => {
      return ntcState.value.state === 'loaded'
        ? ntcState.value.providers
          .filter((provider) => provider.subscription.status === 'active')
          .map((provider) => {
            return {
              id: provider.slug,
              name: provider.name,
              description: provider.description,
              backgroundColor: provider.colors.brand as [number, number, number],
              icon: provider.icon.image.url,
              loan: loans.value.find((loan) => loan.titleRecord.lexisMetadata.contentReserveID === provider.reserveId),
              unavailable: isProviderUnavailable(provider.slug)
            };
          })
        : [];
    });

    const state = computed(() => {
      return ntcState.value.state;
    });

    const helpLink = Constants.HELP_PATHS.NTC;

    return {
      helpLink,
      ntcError,
      providers,
      showPrompt,
      state
    };
  }
});
</script>

<style module>
.page header:not(.card-list header):not(.empty header) {
  padding: 4rem 2rem 2rem;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

.card-list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(425px, 1fr));
  grid-auto-rows: 1fr;
}

@media screen and (max-width: 499px /* @px-vp-narrow */) {
  .card-list {
    grid-template-columns: none;
  }

  .page header:not(.card-list header):not(.empty header) {
    padding: 3rem 1rem 1rem;
  }
}

.card-list > li:only-child {
  max-width: 650px;
}

.empty {
  margin-top: 35px;
}
</style>
