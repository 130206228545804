import { App, createApp, h, Plugin, VNode } from 'vue';
import { Router } from 'vue-router';
import Icon from 'app/components/Icon.vue';

export function configureVue(render: (renderFun: typeof h) => VNode, router: Router, i18n: Plugin) {
  const app = createApp({
    render: () => render(h)
  });

  configureApp(app, router, i18n);

  return app;
}


export function configureApp(app: App, router: Router, i18n: Plugin) {
  app.component('Icon', Icon);
  app.use(router);
  app.use(i18n);

  return app;
}
