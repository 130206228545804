import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledDropdown = _resolveComponent("LabeledDropdown")!

  return (_openBlock(), _createBlock(_component_LabeledDropdown, {
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    options: _ctx.options,
    optionKey: (v) => v.slug,
    optionLabel: "subtitle",
    label: _ctx.$t('series.volume')
  }, null, 8, ["modelValue", "options", "optionKey", "label"]))
}