import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';
import { ThunderAvailabilityOption, ThunderCollectionDefinition, ThunderFacets, ThunderMediaParams, ThunderMediaResponse, ThunderQueryKeys, ThunderSearchResponse, ThunderSeriesParams, ThunderSeriesResponse, ThunderSortType } from 'app/base/thunder';
import { SubjectFilters, toSubjectFilters } from 'app/functions/use-subject-filters';
import i18n from 'app/i18n/i18n';
import { Series as SeriesModel, SeriesMapper } from 'app/models/series';
import { FilterSubject, getSubjectCategory } from 'app/models/subject';
import { Title as TitleModel, TitleMapper } from 'app/models/title';

const CLIENT_NAME = 'elrond';
const PER_PAGE_DEFAULT = 48;
const SUBJECT_FACETS: (keyof ThunderFacets)[] = ['jurisdiction', 'practiceArea', 'classification', 'subjects', 'bisacCodes'];

export const enum SearchErrorCode {
  NetworkFailure = 'NetworkFailure',
  NotFound = 'NotFound',
  Unexpected = 'Unexpected'
};

export class SearchError extends Error {
  public message: SearchErrorCode;


  constructor(code: SearchErrorCode) {
    super(code);
    this.message = code;
  }
}

export const enum SearchType {
  Title = 'title',
  Series = 'series'
};


/* DEFINITION */

// Type alias to intentionally break with Thunder, even though we're using the same strings out of convenience.
// May want to further restrict allowed values via an Extract typing.
export type SearchSortOption = ThunderSortType;

type SearchAvailabilityOption = ThunderAvailabilityOption;

export type SearchDefinition = {
  sort?: SearchSortOption;
  availabilityOption?: SearchAvailabilityOption;
  formats?: string[];
  language?: string;
  maturityLevels?: string[];
  maxItems?: number;
  mediaTypes?: string[];
  publisherId?: string;
  publisherEntityId?: number;
  query?: string;
  subjects?: SubjectFilters;
  creatorId?: string;
  breakpoint?: string;
  curatedCollectionId?: string;
};


/* MODIFICATIONS */

type SearchModifications = {
  page?: number;
  perPage?: number;
  subjects?: SubjectFilters;
  sort?: SearchSortOption;
};


/* REQUEST */

export type SearchRequest<T extends SearchType> = {
  libraryKey: string;
  searchType: T;
  definition: SearchDefinition;
  modifications: SearchModifications;
};


/* LIST */

type Pagination = {
  current: number;
  total: number;
};

type ListItemMapping<T extends SearchType> =
  T extends SearchType.Title ? Readonly<TitleModel> :
  T extends SearchType.Series ? Readonly<SeriesModel> :
  never;

export type List<T extends SearchType> = {
  items: ListItemMapping<T>[];
  totalItems: number;
  pagination: Pagination;
};


/* RESPONSE */

type SearchTerms = ThunderQueryKeys;

type QueryResponse<T extends SearchType> = {
  searchType: T;
  terms: SearchTerms;
  list: List<T>;
  subjectIds: string[];
  breakpoint?: string;
};

export type SearchResponse<T extends SearchType> = {
  searchType: T;
  terms: SearchTerms;
  list: List<T>;
  subjects: FilterSubject[];
  breakpoint?: string;
};


/* Title Search */


export const searchTitle = async (request: SearchRequest<SearchType.Title>): Promise<SearchResponse<SearchType.Title>> => {
  const thunderParams = toThunderMediaParams(request.definition);
  const modifiedParams = applyModificationsMedia(thunderParams, request.modifications);
  const fullParams = applyElrondParamsMedia(modifiedParams);
  const definedParams = Object.fromEntries(Object.entries(fullParams).filter(([_, value]) => value !== undefined && value !== null));

  const queryResponse = await queryTitle(request.libraryKey, definedParams);

  const response = {
    ...queryResponse,
    subjects: processSubjects(queryResponse.subjectIds, request.definition.subjects, request.modifications.subjects)
  };

  return response;
};

const queryTitle = async (libraryKey: string, params: ThunderMediaParams): Promise<QueryResponse<SearchType.Title>> => {
  let result: ThunderSearchResponse<ThunderMediaResponse> | null = null;

  try {
    result = await APP.services.thunder.search(libraryKey, params);
  } catch (err) {
    throw new SearchError(SearchErrorCode.NetworkFailure);
  }

  if (!result) { throw new SearchError(SearchErrorCode.NotFound); }

  const terms: SearchTerms = result.queryKeys;

  const list: List<SearchType.Title> = {
    items: result.items.map(TitleMapper.mapFromThunder).filter((item): item is Readonly<TitleModel> => !!item),
    totalItems: result.totalItems,
    pagination: {
      current: result.links.self.page,
      total: result.links.last.page
    }
  };

  const subjectIds = parseSubjectFacets(result.facets);

  return {
    searchType: SearchType.Title,
    terms,
    list,
    subjectIds,
    breakpoint: result.breakpoint
  };
};

const toThunderMediaParams = (definition: SearchDefinition): ThunderMediaParams => {
  const mapped: ThunderMediaParams = {
    sortBy: definition.sort
      ? [definition.sort]
      : undefined,
    availableFirst: definition.availabilityOption === 'available-first',
    format: definition.formats
      ? [definition.formats.join(',')] // Join into a comma-separated string to use as an OR clause instead of an AND clause
      : undefined,
    language: definition.language
      ? [definition.language]
      : undefined,
    maturityLevel: definition.maturityLevels,
    maxItems: definition.maxItems,
    mediaTypes: definition.mediaTypes,
    publisherId: definition.publisherId,
    publisherEntityId: definition.publisherEntityId?.toString(),
    query: definition.query,
    showOnlyAvailable: definition.availabilityOption === 'available-only',
    creatorId: definition.creatorId,
    breakpoint: definition.breakpoint,
    collectionId: definition.curatedCollectionId,

    ...toThunderSubjectFiltersFromDefinitionMedia(definition.subjects),

    includedFacets: SUBJECT_FACETS
  };

  return mapped;
};

const applyModificationsMedia = (params: ThunderMediaParams, modifications: SearchModifications): ThunderMediaParams => {
  const subjectFilters = toThunderSubjectFiltersMedia(modifications.subjects);

  const mapped: ThunderMediaParams = {
    ...params,
    page: modifications.page
      ? modifications.page
      : undefined,
    perPage: modifications.perPage
      ? modifications.perPage
      : PER_PAGE_DEFAULT,
    subject: subjectFilters.subject
      ? [...(params.subject || []), ...subjectFilters.subject]
      : params.subject,
    subjectOrBisac: subjectFilters.subjectOrBisac
      ? [...(params.subjectOrBisac || []), ...subjectFilters.subjectOrBisac]
      : params.subjectOrBisac,
    sortBy: modifications.sort
      ? [modifications.sort]
      : params.sortBy
  };

  return mapped;
};

const applyElrondParamsMedia = (params: ThunderMediaParams): ThunderMediaParams => {
  let formats = Constants.SUPPORTED_FORMATS;

  // In Elrond the "release date" is realy the "publish date", so prefer that option for searches
  const sortBy: ThunderSortType[] | undefined = params.sortBy?.map((s) => s === 'releasedate' ? 'publishdate' : s);

  // If our definition included formats, we need to split the comma-separated list back up and filter
  if (params.format && params.format.length) {
    const parsedFormats = params.format[0].split(',');
    const filtered = parsedFormats.filter((f) => Constants.SUPPORTED_FORMATS.includes(f));
    formats = filtered;
  }

  return {
    ...params,
    sortBy,
    format: [formats.join(',')], // Need to use a comma separated list again instead of an array
    searchBehavior: CLIENT_NAME // Use client-specific configuration for search sub-sorting
  };
};

// For the definition, we assume no BISAC codes.
// Due to a Thunder limitation subjectOrBisac can't be ANDed,
// so definition subjects would otherwise be ORed with the filters meant to be layered on top.
const toThunderSubjectFiltersFromDefinitionMedia = (listSubjects?: SubjectFilters): Pick<ThunderMediaParams, 'subject' | 'subjectOrBisac'> => {
  if (!listSubjects) {
    return {};
  }

  const subject: string[] = [];

  if (listSubjects.Jurisdiction?.length) {
    subject.push(listSubjects.Jurisdiction.join(','));
  }

  if (listSubjects.Classification?.length) {
    subject.push(listSubjects.Classification.join(','));
  }

  // Due to a limitation in Thunder, we can't use BISAC codes for both PracticeArea and Subject categories
  const subjectSubjectIds = (listSubjects.Subject || []).filter((subjectId) => /^\d+$/.test(subjectId));
  if (subjectSubjectIds.length) {
    subject.push(subjectSubjectIds.join(','));
  }

  if (listSubjects.PracticeArea?.length) {
    subject.push(listSubjects.PracticeArea.join(','));
  }

  return {
    subject: subject.length > 0 ? subject : undefined
  };
};

const toThunderSubjectFiltersMedia = (listSubjects?: SubjectFilters): Pick<ThunderMediaParams, 'subject' | 'subjectOrBisac'> => {
  if (!listSubjects) {
    return {};
  }

  const subject: string[] = [];
  let subjectOrBisac: string[] = [];

  if (listSubjects.Jurisdiction?.length) {
    subject.push(listSubjects.Jurisdiction.join(','));
  }

  if (listSubjects.Classification?.length) {
    subject.push(listSubjects.Classification.join(','));
  }

  // Due to a limitation in Thunder, we can't use BISAC codes for both PracticeArea and Subject categories
  const subjectSubjectIds = (listSubjects.Subject || []).filter((subjectId) => /^\d+$/.test(subjectId));
  if (subjectSubjectIds.length) {
    subject.push(subjectSubjectIds.join(','));
  }

  if (listSubjects.PracticeArea?.length) {
    subjectOrBisac = listSubjects.PracticeArea;
  }

  return {
    subject: subject.length > 0 ? subject : undefined,
    subjectOrBisac: subjectOrBisac.length > 0 ? subjectOrBisac : undefined
  };
};


/* Series Search */

export const searchSeries = async (request: SearchRequest<SearchType.Series>): Promise<SearchResponse<SearchType.Series>> => {
  const thunderParams = toThunderSeriesParams(request.definition);
  const modifiedParams = applyModificationsSeries(thunderParams, request.modifications);
  const fullParams = applyElrondParamsSeries(modifiedParams);
  const definedParams = Object.fromEntries(Object.entries(fullParams).filter(([_, value]) => value !== undefined && value !== null));

  const queryResponse = await querySeries(request.libraryKey, definedParams);

  const response = {
    ...queryResponse,
    subjects: processSubjects(queryResponse.subjectIds, request.definition.subjects, request.modifications.subjects)
  };

  return response;
};

const querySeries = async (libraryKey: string, params: ThunderSeriesParams): Promise<QueryResponse<SearchType.Series>> => {
  let result: ThunderSearchResponse<ThunderSeriesResponse> | null = null;

  try {
    result = await APP.services.thunder.seriesSearch(libraryKey, params);
  } catch (err) {
    throw new SearchError(SearchErrorCode.NetworkFailure);
  }

  if (!result) { throw new SearchError(SearchErrorCode.NotFound); }

  const terms: SearchTerms = result.queryKeys;

  const list: List<SearchType.Series> = {
    items: result.items.map(SeriesMapper.mapFromThunder).filter((item): item is SeriesModel => !!item),
    totalItems: result.totalItems,
    pagination: {
      current: result.links.self.page,
      total: result.links.last.page
    }
  };

  const subjectIds = parseSubjectFacets(result.facets);

  return {
    searchType: SearchType.Series,
    terms,
    list,
    subjectIds,
    breakpoint: result.breakpoint
  };
};

const toThunderSeriesParams = (definition: SearchDefinition): ThunderSeriesParams => {
  const mapped: ThunderSeriesParams = {
    sortBy: definition.sort,
    format: definition.formats, // Seems to be an OR clause by default
    language: definition.language
      ? [definition.language]
      : undefined,
    mediaType: definition.mediaTypes,
    publisherId: definition.publisherId,
    publisherEntityId: definition.publisherEntityId?.toString(),
    query: definition.query,
    creatorId: definition.creatorId,
    collectionId: definition.curatedCollectionId,

    ...toThunderSubjectFiltersSeries(definition.subjects),

    includedFacets: SUBJECT_FACETS,
    includeFacets: true // Does not default to true like the media endpoint
  };

  return mapped;
};

const applyModificationsSeries = (params: ThunderSeriesParams, modifications: SearchModifications): ThunderSeriesParams => {
  const subjectFilters = toThunderSubjectFiltersSeries(modifications.subjects);

  const mapped: ThunderSeriesParams = {
    ...params,
    page: modifications.page
      ? modifications.page
      : undefined,
    perPage: modifications.perPage
      ? modifications.perPage
      : PER_PAGE_DEFAULT,
    lexisNexisJurisdiction: subjectFilters.lexisNexisJurisdiction
      ? [...(params.lexisNexisJurisdiction || []), ...subjectFilters.lexisNexisJurisdiction]
      : params.lexisNexisJurisdiction,
    lexisNexisContentType: subjectFilters.lexisNexisContentType
      ? [...(params.lexisNexisContentType || []), ...subjectFilters.lexisNexisContentType]
      : params.lexisNexisContentType,
    lexisNexisPracticeArea: subjectFilters.lexisNexisPracticeArea
      ? [...(params.lexisNexisPracticeArea || []), ...subjectFilters.lexisNexisPracticeArea]
      : params.lexisNexisPracticeArea,
    subject: subjectFilters.subject
      ? [...(params.subject || []), ...subjectFilters.subject]
      : params.subject,
    sortBy: modifications.sort
      ? modifications.sort
      : params.sortBy
  };

  return mapped;
};

const applyElrondParamsSeries = (params: ThunderSeriesParams): ThunderSeriesParams => {
  const format = params.format && params.format.length
    ? params.format.filter((f) => Constants.SUPPORTED_FORMATS.includes(f))
    : Constants.SUPPORTED_FORMATS;

  // Transform illegal sorts into valid ones
  const sortBy: ThunderSortType | undefined =
    params.sortBy === 'title' ? 'seriesname' :
    params.sortBy === 'publishdate' ? 'releasedate' :
    params.sortBy;

  return {
    ...params,
    format,
    sortBy
  };
};

const toThunderSubjectFiltersSeries = (listSubjects?: SubjectFilters): Pick<ThunderSeriesParams, 'lexisNexisJurisdiction' | 'lexisNexisContentType' | 'lexisNexisPracticeArea' | 'subject'> => {
  const filterParams: Pick<ThunderSeriesParams, 'lexisNexisJurisdiction' | 'lexisNexisContentType' | 'lexisNexisPracticeArea' | 'subject'> = {};

  if (!listSubjects) {
    return filterParams;
  }

  if (listSubjects.Jurisdiction?.length) {
    filterParams.lexisNexisJurisdiction = listSubjects.Jurisdiction;
  }

  if (listSubjects.Classification?.length) {
    filterParams.lexisNexisContentType = listSubjects.Classification;
  }

  const subjectSubjectIds = (listSubjects.Subject || []).filter((subjectId) => /^\d+$/.test(subjectId));
  if (subjectSubjectIds.length) {
    filterParams.subject = subjectSubjectIds;
  }

  // Filter out BISAC codes from series endpoint
  const practiceAreaSubjectIds = (listSubjects.PracticeArea || []).filter((ls) => /^\d+$/.test(ls));
  if (practiceAreaSubjectIds.length) {
    filterParams.lexisNexisPracticeArea = practiceAreaSubjectIds;
  }

  return filterParams;
};


/* Utility */

// Only really care about ids because we have to fill in translated names, the correct 'isApplied' status, and missing values ourselves
const parseSubjectFacets = (facets: ThunderFacets) => {
  const subjects: string[] = [];

  SUBJECT_FACETS.forEach((facetName) => {
    const facet = facets[facetName];

    if (facet) {
      // Due to a limitation in Thunder, we can't use BISAC codes for both PracticeArea and Subject categories
      // Filter out non-LAW BISAC codes to avoid displaying them since they can't be used.
      const facetItems = facetName === 'bisacCodes' ? facet.items.filter((facetItem) => facetItem.id.startsWith('LAW')) : facet.items;

      facetItems.forEach((facetItem) => {
        subjects.push(facetItem.id);
      });
    }
  });

  return subjects;
};

// Thunder is currently not supplying the 'isApplied' property correctly
// Check the request for what should be applied instead.
const processSubjects = (querySubjectIds: string[], definitionSubjects?: SubjectFilters, modificationSubjects?: SubjectFilters) => {
  const definitionSubjectIds = !!definitionSubjects
    ? Object.values(definitionSubjects).filter((subArr): subArr is string[] => !!subArr).flat()
    : [];
  const modificationSubjectIds = !!modificationSubjects
    ? Object.values(modificationSubjects).filter((subArr): subArr is string[] => !!subArr).flat()
    : [];
  const allSubjectIds = [...new Set([...querySubjectIds, ...modificationSubjectIds])];

  const toRemove = new Set(definitionSubjectIds);
  const toSelect = new Set(modificationSubjectIds);

  const filtered = allSubjectIds.filter((subId) => !toRemove.has(subId));
  const mapped: FilterSubject[] = filtered.map((subId) => {
    return {
      id: subId,
      name: i18n.t(`subjectIdOrBisacCode.${subId}`),
      category: getSubjectCategory(subId),
      selected: toSelect.has(subId)
    };
  });

  return mapped;
};

export const toSearchDefinition = (thunderDefinition: ThunderCollectionDefinition, breakpoint?: string): SearchDefinition => {
  const { availabilityOption, sortBy } = thunderDefinition;

  const base: SearchDefinition = {
    availabilityOption,
    sort: sortBy
  };

  if ('generatedCollectionDetails' in thunderDefinition) {
    const { subjects, ...details } = thunderDefinition.generatedCollectionDetails;
    const mappedSubjects = subjects ? toSubjectFilters(subjects) : undefined;

    return {
      ...base,
      ...details,
      subjects: mappedSubjects,
      breakpoint
    };
  }

  if ('curatedCollectionDetails' in thunderDefinition) {
    return {
      ...base,
      curatedCollectionId: thunderDefinition.id,
      sort: thunderDefinition.curatedCollectionDetails.isOrdered ? 'listorder' : undefined
    };
  }

  return base;
};
