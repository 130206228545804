import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BrowseGroup = _resolveComponent("BrowseGroup")!

  return (_openBlock(), _createBlock(_component_BrowseGroup, {
    name: _ctx.$t('browse.subjects'),
    description: "",
    buttonLabel: _ctx.buttonLabel,
    navigateLink: _ctx.navigateLink,
    "aria-label": _ctx.$t('browse.subjects')
  }, {
    default: _withCtx(() => [
      _createElementVNode("ol", {
        class: _normalizeClass(_ctx.$style.content)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjects.slice(0, 16), (subject) => {
          return (_openBlock(), _createElementBlock("li", {
            key: subject.id,
            class: _normalizeClass(_ctx.$style.item)
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.titleCount),
              "aria-hidden": true
            }, _toDisplayString(subject.totalItems), 3),
            _createVNode(_component_router_link, {
              class: _normalizeClass(_ctx.$style.name),
              to: _ctx.subjectRoute(subject.id),
              "aria-label": _ctx.$t('browse.subjectWithCount', { NAME: subject.name, N: subject.count })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(subject.name), 1)
              ], undefined, true),
              _: 2
            }, 1032, ["class", "to", "aria-label"])
          ], 2))
        }), 128))
      ], 2)
    ], undefined, true),
    _: 1
  }, 8, ["name", "buttonLabel", "navigateLink", "aria-label"]))
}