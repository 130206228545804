<template>
  <div :class="$style.container">
    <ol>
      <li
        v-for="section in sections"
        :key="section.id"
        :class="$style.sectionWrapper"
      >
        <section :class="[$style.section, isActive(section.id) && !mobile ? $style.expanded : '']">
          <h1 :class="$style.header">
            <button
              :id="sectionHeaderId(section.id)"
              :class="$style.trigger"
              :aria-controls="sectionPanelId(section.id)"
              :aria-expanded="isActive(section.id)"
              @click="toggle(section.id)"
            >
              <span :class="$style.triggerContents">
                <span>
                  {{ $t(section.label) }}

                  <p
                    v-if="section.badge"
                    class="badge"
                  >
                    {{ section.badge }}
                  </p>
                </span>
                <Icon
                  name="chevron-right"
                  :class="$style.arrow"
                />
              </span>
            </button>
          </h1>
          <Collapsible
            :class="$style.panel"
            :expanded="!mobile && isActive(section.id)"
            :panelId="sectionPanelId(section.id)"
            :headerId="sectionHeaderId(section.id)"
          >
            <slot :name="section.id"></slot>
          </Collapsible>
        </section>
      </li>
    </ol>

    <Overlay
      teleportTo="#sidebars"
      :show="mobile && !!activeSection"
      transition="up"
      @close="activeSection = null"
    >
      <InfoPanel
        v-if="activeSection"
        :header="$t(activeSection.label)"
        @close="activeSection = null"
      >
        <slot
          :name="activeSection.id"
          :mobile="true"
        ></slot>
      </InfoPanel>
    </Overlay>
  </div>
</template>

<script lang='ts'>
import Collapsible from 'app/components/Collapsible.vue';
import InfoPanel from 'app/components/InfoPanel.vue';
import Overlay from 'app/components/Overlay.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { computed, defineComponent, ref } from 'vue';

export type AccordionSection = {
  id: string;
  label: string;
  badge?: number;
};

export default defineComponent({
  name: 'Accordion',
  components: {
    Collapsible,
    InfoPanel,
    Overlay
  },
  props: {
    sections: {
      type: Array as () => AccordionSection[],
      required: true
    }
  },
  setup: (props) => {
    const { windowWidth } = useWindowSize();
    const mobile = computed(() => {
      return windowWidth.value <= Breakpoint.Narrow;
    });

    const sectionHeaderId = (id: string) => `accordion-header-${id}`;
    const sectionPanelId = (id: string) => `accordion-panel-${id}`;

    const activeSection = ref<AccordionSection | null>(null);

    const toggle = (id: string) => {
      activeSection.value = isActive(id)
        ? null
        : (props.sections.find((s) => s.id === id) || null);
    };

    const isActive = (id: string) => {
      return activeSection.value?.id === id;
    };

    useAppEvents({
      'prompt:show': () => {
        if (mobile.value) {
          activeSection.value = null;
        }
      }
    });

    return {
      activeSection,
      isActive,
      mobile,
      sectionHeaderId,
      sectionPanelId,
      toggle
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  box-shadow: 0px 1px 6px 0px @c-shadow;
}

.header {
  box-sizing: border-box;
  width: 100%;
}

.trigger {
  padding: 1rem;
  text-align: left;
  width: 100%;
}

.trigger-contents {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.section-wrapper {
  border: 1px solid @c-dark-gray;
}

.section-wrapper:first-child {
  border-top-left-radius: @px-border-radius;
  border-top-right-radius: @px-border-radius;
}

.section-wrapper:not(:last-child) {
  border-bottom: 0;
}

.section-wrapper:last-child {
  border-bottom-left-radius: @px-border-radius;
  border-bottom-right-radius: @px-border-radius;
}

.arrow {
  height: 1rem;
  transition: transform 200ms ease-in-out;
  width: 1rem;
}

.section.expanded {
  .header {
    border-bottom: 2px solid @c-primary-red;
    border-radius: @px-border-radius @px-border-radius 0 0;
  }

  .arrow {
    transform: rotate(90deg);
  }
}

.panel {
  background-color: var(--c-lightest-gray);
}
</style>
