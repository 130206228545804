import { APP } from 'app/base/app';
import { HoldError, LoanError } from 'app/base/circulation-error';
import { C } from 'app/base/common';
import { FetchAsyncError } from 'app/base/server';
import { useI18n } from 'app/functions/use-i18n';
import { listFormatter } from 'app/i18n/list-formatter';
import { Annotations } from 'app/models/annotations';
import { ExportQueue } from 'app/models/export-queue';
import { Device, LibraryCards } from 'app/models/library-cards';
import { Possessions } from 'app/models/possessions';
import { RecentlyReadTitle } from 'app/models/recently-read-title';
import { SearchHistory } from 'app/models/search-history';
import { Tags } from 'app/models/tags';
import { SentryLoan } from '../base/sentry';
import { SyncCoordinator } from '../base/sync-coordinator';
import { Hold } from './hold';
import { Library } from './library';
import { LibraryCard } from './library-card';
import { Loan } from './loan';
import { RecentlyRead } from './recently-read';
import { SharedTitles } from './shared-titles';
import { TitleInfoForShell, TitleRecord } from './title';

export class Patron {
  public searchHistory: SearchHistory;
  public recentlyRead: RecentlyRead;
  public tags: Tags;
  public sharedTitles: SharedTitles;
  public suppressedHolds: Possessions<Hold>;
  public suppressedLoans: Possessions<Loan>;
  public holds: Possessions<Hold>;
  public loans: Possessions<Loan>;
  public annotations: Annotations;
  public exportQueue: ExportQueue;
  public syncCoordinator: SyncCoordinator;
  public accountId: string;
  private readonly _cards: LibraryCards;
  private _sendPlayable: boolean;


  constructor() {
    this._cards = new LibraryCards();
    this.loans = new Possessions('loan', true, true);
    this.holds = new Possessions('hold', true, true);
    this.suppressedLoans = new Possessions('loan');
    this.suppressedHolds = new Possessions('hold');
    this.tags = new Tags();
    this.searchHistory = new SearchHistory();
    this.sharedTitles = new SharedTitles();
    this.recentlyRead = new RecentlyRead();
    this.annotations = new Annotations();
    this.exportQueue = new ExportQueue();
    this.syncCoordinator = new SyncCoordinator();
    this._sendPlayable = false;
    APP.events.on('msg:title:list:playable', () => this._onTitleListPlayable());
    APP.events.on('msg:environment:ready?', () => APP.shell.transmit('environment:ready'));
  }


  public load() {
    this._cards.load();

    // Only load data from the bank if the user is
    // authenticated
    if (this.isAuthenticated()) {
      this.loans.load();
      this.holds.load();
      this.searchHistory.deserialize(APP.bank.get('search-history'));
      this.sharedTitles.load();
      this.annotations.load();
      this.exportQueue.load();
      this.tags.load();
      this.recentlyRead.load(); // Dependent on tags.load()
    }

    this.syncCoordinator.activate();
  }


  public isAuthenticated(): boolean {
    return !!this.currentCard();
  }


  public currentCard(): LibraryCard {
    if (APP.library) {
      return this.cardForLibrary(APP.library);
    }

    return null;
  }


  public libraryCards(): LibraryCards {
    return this._cards;
  }


  public authenticate(library: Library, goTo: string): void {
    // TODO: Make this work when already authenticated
    APP.nav.goRoot(`welcome/login/${library.baseKey}?origination=${goTo}`);
  }


  public async reset(reload = true) {
    try {
      await APP.sentry.reset();
    } catch (ex) {
      console.warn('[AUTH] Error with reset: %O', ex);
    } finally {
      APP.tracking.log('logout');
    }

    if (reload) {
      // Reload the app
      location.href = '/';
    }
  }


  public cardForLibrary(library: Library): LibraryCard {
    const query = library.baseKey ? { 'library.baseKey': library.baseKey } : { 'library.websiteId': library.websiteId };

    return this._cards.find(query);
  }


  public cardPreferences(): Preferences {
    return {
      readingDevice: this._cards.readingDevice,
      sendingDevice: this._cards.sendingDevice,
      listeningDevice: this._cards.listeningDevice,
      daysToSuspendHolds: this._cards.daysToSuspendHolds,
      autoBorrowHolds: this._cards.autoBorrowHolds
    };
  }


  public async borrowTitle(titleSlug: string, lendingPeriod?: [number, string]): Promise<Loan> {
    try {
      const loanResponse = await APP.sentry.borrowTitle(this.currentCard(), titleSlug, lendingPeriod);

      return this._completeCirc('loan', loanResponse);
    } catch (ex) {
      if (ex instanceof FetchAsyncError) {
        throw new LoanError(ex);
      }
    }
  }


  public async renewLoan(titleSlug: string): Promise<Loan> {
    const loanResponse = await APP.sentry.borrowTitle(this.currentCard(), titleSlug, undefined, true);

    return this._completeCirc('renew', loanResponse);
  }


  public async holdTitle(title: TitleRecord, emailAddress?: string): Promise<Hold> {
    try {
      if (emailAddress) {
        APP.patron.currentCard().emailAddress = emailAddress;
        APP.patron.libraryCards().save();
      }

      const holdResponse = await APP.sentry.holdTitle(this.currentCard(), title.slug);

      // Lets not lose those subjects that sentry doesnt know about
      holdResponse.subjects = title.subjects;

      const holds = this.holds.subsume([holdResponse]);

      return holds[0];
    } catch (ex) {
      throw new HoldError(ex);
    }
  }


  public async editHold(title: TitleRecord, emailAddress: string): Promise<Hold> {
    try {
      APP.patron.currentCard().emailAddress = emailAddress;
      APP.patron.libraryCards().save();

      const response = await APP.sentry.editHold(title.slug, { emailAddress });

      // Lets not lose those subjects that sentry doesnt know about
      response.subjects = title.subjects;

      const holds = this.holds.subsume([response]);
      const updatedHold = holds[0];
      updatedHold.emailAddress = emailAddress;

      return holds[0];
    } catch (ex) {
      throw new HoldError(ex);
    }
  }


  public async deleteHold(titleSlug: string): Promise<void> {
    try {
      await APP.sentry.cancelHold(titleSlug);

      this.holds.remove({ titleSlug });
    } catch (ex) {
      throw new HoldError(ex);
    }
  }


  protected _onTitleListPlayable() {
    this._sendPlayable = true;
    this._sendTitleListPlayable();
    // Add the other event listeners
    APP.events.on('loan:update:all', () => this._sendTitleListPlayable());
    APP.events.on('loan:download:complete', () => this._sendTitleListPlayable());
    APP.events.on('loan:download:wipe', () => this._sendTitleListPlayable());
    APP.events.on('loan:accessed', () => this._sendTitleListPlayable());
  }


  protected async _sendTitleListPlayable() {
    if (this._sendPlayable) {
      try {
        this._sendPlayable = false;
        const playableAudiobooks = await Promise.all(this.loans.all
        .filter((loan) => loan.titleRecord.mediaType === 'audiobook')
        .map(async (loan) => {
          const titleInfo = await this._getInfoForShell(loan);

          return titleInfo;
        }));
        playableAudiobooks.sort(RecentlyReadTitle.SORT_FUNCTIONS.accessedShell);

        APP.shell.transmit({ name: 'title:list:playable', titles: playableAudiobooks });
      } catch {
        // Do nothing
      }

      this._sendPlayable = true;
    }
  }


  private _completeCirc(type: 'loan' | 'renew', loanResponse: SentryLoan): Loan {
    const updatedLoan = this.loans.subsume([loanResponse])[0];
    const hold = this.holds.find({ slug: updatedLoan.slug });
    if (hold) {
      this.holds.removeItem(hold);
    }
    APP.events.dispatch(type === 'loan' ? 'loan:add' : 'loan:renew', { item: updatedLoan });

    return updatedLoan;
  }


  private async _getInfoForShell(loan: Loan): Promise<TitleInfoForShell> {
    try {
      await loan.updateLoanFromSentry();
    } catch {
      // Use local access time
    }
    const { locale } = useI18n();
    const authors = loan.titleRecord.creators?.Author || [];
    const titleInfo = {
      id:  loan.titleRecord.slug,
      title: loan.titleRecord.title,
      creator: listFormatter(authors.map((author) => author.name), locale),
      cover: C.absoluteURL(loan.titleRecord.coverURL() || ''),
      format: loan.titleRecord.mediaType,
      duration: loan.titleRecord.duration,
      createTime: loan.createTime || 0,
      accessTime: loan.lastAccessTime || 0,
      expireTime: loan.expireTime || 0,
      downloadProgress: loan.downloader.downloadPercent || 0,
      readingProgress: loan.readingProgress || 0,
      paths: {
        open: `/open/${loan.titleRecord.slug}`,
        openbook: await loan.downloader.getOpenBookURL()
      },
      sample: false,
      active: (loan.titleRecord.slug === APP.activeTitle.title?.slug)
    };

    return titleInfo;
  }
}

export class Preferences {
  public listeningDevice: Device = 'here';
  public sendingDevice: Device = 'here';
  public readingDevice: Device = 'here';
  public daysToSuspendHolds = 0;
  public autoBorrowHolds = true;
}
