import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { id: "loanPopoutLabel" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!
  const _component_PopoutDialog = _resolveComponent("PopoutDialog")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("button", {
      id: "iconBtn",
      ref: "iconBtn",
      type: "button",
      "aria-label": _ctx.$t('title.expirationIndicator.label'),
      "aria-haspopup": "dialog",
      class: _normalizeClass(_ctx.$style.indicatorButton),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPopped = true))
    }, [
      _createVNode(_component_Icon, {
        name: "expire-clock",
        class: _normalizeClass(_ctx.$style.icon)
      }, null, 8, ["class"]),
      (!_ctx.iconOnly)
        ? (_openBlock(), _createBlock(_component_RelativeDate, {
            key: 0,
            timestamp: _ctx.timestamp,
            numeric: true,
            class: _normalizeClass(_ctx.$style.label)
          }, null, 8, ["timestamp", "class"]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1),
    (_ctx.isPopped && _ctx.iconBtn)
      ? (_openBlock(), _createBlock(_component_PopoutDialog, {
          key: 0,
          ref: "popout",
          reference: _ctx.iconBtn,
          headerLabel: _ctx.$t('title.expirationIndicator.label'),
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isPopped = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.description)
            }, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.relativeTime
            ? _ctx.$t('title.expirationIndicator.expiresOn.time', { relativeTime: _ctx.relativeTime })
            : _ctx.$t('title.expirationIndicator.expiresOn.date', { date: _ctx.date })), 1),
              (_ctx.isRenewable && _ctx.isReturnable)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('title.expirationIndicator.message.both')), 1))
                : (_ctx.isRenewable)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('title.expirationIndicator.message.renew')), 1))
                  : (_ctx.isReturnable)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('title.expirationIndicator.message.return')), 1))
                    : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.actions)
            }, [
              (_ctx.isRenewable)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.renewLoan && _ctx.renewLoan(...args)))
                  }, _toDisplayString(_ctx.$t('title.expirationIndicator.renew.button')), 1))
                : _createCommentVNode("", true),
              (_ctx.isReturnable)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.returnLoan && _ctx.returnLoan(...args)))
                  }, _toDisplayString(_ctx.$t('title.expirationIndicator.return.button')), 1))
                : _createCommentVNode("", true)
            ], 2)
          ], undefined, true),
          _: 1
        }, 8, ["reference", "headerLabel"]))
      : _createCommentVNode("", true)
  ]))
}