<template>
  <div
    :class="$style.container"
  >
    <h3 :class="$style.type">
      {{ $t(typeName) }}
    </h3>
    <div :class="$style.text">
      <template v-if="type === 'TitleNotAvailable'">
        {{ $t('alert.details.titleRemoved') }}
      </template>
      <template v-else>
        {{ $t('alert.details.seriesRemoved') }}
      </template>
    </div>
    <Card
      :class="$style.card"
      :compact="true"
      :item="cardableItem"
    >
      <template #cover>
        <slot name="cover"></slot>
      </template>
    </Card>
  </div>
</template>

<script lang='ts'>
import Card, { CardableItem } from 'app/components/cards/Card.vue';
import { useI18n } from 'app/functions/use-i18n';
import { listFormatter } from 'app/i18n/list-formatter';
import { Creator } from 'app/models/title';
import { PropType, computed, defineComponent } from 'vue';


export default defineComponent({
  name: 'AlertTitleNotAvailable',
  components: {
    Card
},
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    authors: {
      type: Object as PropType<Creator[]>,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const { locale } = useI18n();
    const authors = props.authors || [];
    const authorNames = authors.map((author) => author.name);
    const authorsString = listFormatter(authorNames, locale);

    const cardableItem = {
      id: props.id,
      title: props.title,
      subtitle: props.subtitle,
      authors: authorsString
    } as CardableItem;

    const typeName = computed(() => 'alert.type.'+props.type);

    return {
      cardableItem,
      typeName
    };
  }
});
</script>

<style module>

  .container {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'type'
      'text'
      'card';
    width: 100%;
  }

  .type {
    grid-area: type;
    font-size: 1.25rem;
    font-weight: var(--fw-medium-bold);
    margin-bottom: 0.5rem;
  }

  .text {
    grid-area: text;
    margin-bottom: 1rem;
  }

  .card {
    grid-area: card;
    height: fit-content;
    width: calc(100% - 2rem);
    --rem-cover-height: 9rem;
  }
</style>
