import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WelcomeView = _resolveComponent("WelcomeView")!

  return (_openBlock(), _createBlock(_component_WelcomeView, null, {
    default: _withCtx(() => [
      _createElementVNode("section", {
        class: _normalizeClass(_ctx.$style.container)
      }, [
        _createElementVNode("h1", {
          class: _normalizeClass(_ctx.$style.header)
        }, _toDisplayString(_ctx.$t('signedOut.header')), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.message)
        }, _toDisplayString(_ctx.$t('signedOut.message', { LIBRARY: _ctx.libraryName })), 3),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.$style.action),
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToTarget && _ctx.goToTarget(...args)))
        }, _toDisplayString(_ctx.$t('signedOut.action')), 3)
      ], 2)
    ], undefined, true),
    _: 1
  }))
}