import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { MediaType } from 'app/base/interfaces';
import { ShellTraits } from 'app/base/shell/shell';
import { AutoDownloadRule, DownloadQueueRule } from 'app/base/updates/update-manager';
import { Library } from 'app/models/library';
import { Possession } from 'app/models/possession';
import { TitleRecord } from 'app/models/title';
import router from 'app/router/router';
import { DataEvent } from '../../lib/gala/src/events';
type SageCategory = 'error' | 'idea' | 'rating' | 'problem' | 'complaint' | 'question' | 'event';

/**
 * Sage is the backend service that is responsible for listening
 * to user feedback and providing access to support documents and
 * human support chat. User feedback comes in various flavors,
 * including:
 *
 * - support case chat
 * - NPS survey
 * - error report form when something goes wrong
 *
 */
export class Sage {
  public static MAX_ERRORS_PER_MINUTE = 10;
  public static MAX_AGE = 60 * 60 * 24; // One day in seconds

  private _history: HistoricalSageSubmission[] = [];


  constructor() {
    APP.events.on(
      'msg:sage:submit:error',
      (evt) => this._submitErrorFromEvent(evt)
    );
    APP.events.on(
      'msg:diagnostics:shell:error',
      (evt) => this._submitErrorFromEvent(evt)
    );
  }


  /**
   * Send data back to OverDrive
   *
   * @param category type of data being sent
   * @param submission detail
   * @param onSuccess
   * @param onFailure
   */
  public async submit(
    category: SageCategory,
    submission: SageSubmission
  ): Promise<void> {
    // Fill out the submission with contextual properties:
    const fullSubmission = this._fillOutSubmission(category, submission);

    const historyItem = <HistoricalSageSubmission>C.absorb(fullSubmission, {
      category: category,
      createdAt: C.epochSeconds()
    });
    this._history.unshift(historyItem);

    if (category === 'error') {
      // Legacy: In shell contexts, send errors to the shell for Crashlytics aggregation:
      this._sendErrorToShell(fullSubmission);
      // Throttle errors per minute
      if (this.history(60, 'error').length > Sage.MAX_ERRORS_PER_MINUTE) {
        console.warn('[SAGE] throttled - did not submit', fullSubmission);

        return;
      }
    }

    try {
      // Send the submission to Sage:
      const response = await this._submitToSage(category, fullSubmission);
      if (!response) {
        throw new Error('Did not get response from Sage');
      }

      console.log('[SAGE] submitted:', fullSubmission);
      historyItem.sageId = response.submission.id;
    } catch (e) {
      console.warn('[SAGE] Error submitting to Sage:', fullSubmission, e);
    }

    // Remove submissions older than our max age
    const cutoff = C.epochSeconds() - Sage.MAX_AGE;
    this._history = this._history.filter((h) => {
      return h.createdAt > cutoff;
    });
  }


  // Temporary: To always send annotation messages through to Sage
  public async submitAnnotation(submission: SageSubmission) {
    const fullSubmission = this._fillOutSubmission('error', submission);

    try {
      // Send the submission to Sage:
      const response = await this._submitToSage('error', fullSubmission);
      if (!response) {
        throw new Error('Did not get response from Sage');
      }

      console.log('[SAGE] submitted:', fullSubmission);
    } catch (e) {
      console.warn('[SAGE] Error submitting to Sage:', fullSubmission, e);
    }
  }


  private _fillOutSubmission(category: SageCategory, submission: SageSubmission): ExpandedSageSubmission {
    let sub: ExpandedSageSubmission = submission;
    try {
      sub = this._expandSubmissionContext(submission);
      this._prop(sub, 'chip', APP.sentry.chip);
      this._prop(sub, 'primaryChip', APP.sentry.primaryChip);
      // this._prop(sub, 'patronName', APP.patron.patronName());
      if (APP.patron.currentCard()) {
        this._prop(sub, 'patronEmailAddress', APP.patron.currentCard().emailAddress);
      }
      this._prop(sub, 'patronPreferences', this._getPatronPreferences());
      this._prop(sub, 'libraryCards', this._getLibraryCardData());
      this._prop(sub, 'navigation', this._getNavigationData());
      this._prop(sub, 'appClientName', APP.client.name);
      this._prop(sub, 'appClientVersion', APP.client.info.version.value);
      if (APP.shell.info.version) {
        this._prop(sub, 'appShellVersion', APP.shell.info.version.value);
        this._prop(sub, 'appShellFlavor', APP.shell.info.flavor);
      }
      // if (APP.shell.traits) {
      //   this._prop(sub, 'deviceTraits', APP.shell.traits);
      // }
      this._prop(sub, 'userAgentString', this._getUserAgentString());
      this._prop(sub, 'userAgentWidth', window.innerWidth);
      this._prop(sub, 'userAgentHeight', window.innerHeight);
      C.each(['deviceTraits', 'libraryCards'],
        (prop: Extract<keyof ExpandedSageSubmission, 'deviceTraits' | 'libraryCards'>) => {
          if (C.isString(sub[prop])) {
            try {
              const val = sub[prop];
              if (val && C.isString(val)) {
                return JSON.parse(val);
              }
            } catch (e) {
              // It's fine, we don't need to error
            }
            (sub as Dictionary<unknown>)[prop] = { value: sub[prop] };
          }
        }
      );
    } catch (e) {
      console.warn('[SAGE] error filling out submission', category, submission, e);
    }

    return sub;
  }


  public history(sinceSecondsAgo: number, categoryFilter?: string): HistoricalSageSubmission[] {
    const horizon = sinceSecondsAgo ? C.epochSeconds() - sinceSecondsAgo : 0;
    const hist = [];
    const cats = categoryFilter ? C.flatten([categoryFilter]) : [];
    for (let i = 0, ii = this._history.length; i < ii; ++i) {
      const item = this._history[i];
      if (item.createdAt < horizon) { break; }
      if (cats.length && cats.indexOf(item.category) < 0) { continue; }
      hist.push(item);
    }

    return hist;
  }


  private _expandSubmissionContext(submission: SageSubmission): ContextualSageSubmission {
    const sub = <ExpandedSageSubmission>C.absorb(submission, {});
    try {
      const context = C.excise(sub, 'submissionContext') || {};
      const title = context.loan ? APP.titleCache.get(context.loan.titleSlug) : context.title;
      if (title) {
        sub.titleId = title.slug;
        sub.titleName = title.title;
        sub.titleFormat = title.mediaType;
        if (context.loan) {
          sub.titleAccess = 'full';
          sub.titleDownloadStatus = context.loan.downloader.downloadPercent;
        } else {
          sub.titleAccess = 'sample';
        }
      }
      const lib = context.library || APP.library;
      if (lib) {
        this._prop(sub, 'activeLibraryName', lib.name);
        this._prop(sub, 'activeLibraryKey', lib.key());
        this._prop(sub, 'activeLibraryWebsiteId', lib.websiteId.toString());
        // Library model doesn't contain branch information
        // if (lib.branch) {
        //   this._prop(sub, 'activeBranchId', lib.branch.id);
        //   this._prop(sub, 'activeBranchName', lib.branch.name);
        //   this._prop(sub, 'activeBranchCity', lib.branch.city);
        //   this._prop(sub, 'activeBranchRegion', lib.branch.region);
        //   this._prop(sub, 'activeBranchCountry', lib.branch.country);
        //   this._prop(sub, 'activeBranchLatitude', lib.branch.lat);
        //   this._prop(sub, 'activeBranchLongitude', lib.branch.lng);
        // }
      }
    } catch (e) {
      console.warn('[SAGE] error expanding submission', submission, e);
    }

    return sub;
  }


  private _sendErrorToShell(submission: ExpandedSageSubmission): void {
    try {
      const error = {
        message: submission.errorMessage,
        source: submission.errorSource
      };
      APP.shell.transmit({ name: 'diagnostics:client:error', error: error });
    } catch (e) {
      console.warn('[SAGE] error sending error to shell', submission, e);
    }
  }


  private async _submitToSage(
    category: SageCategory,
    submission: ExpandedSageSubmission
  ): Promise<{ submission: { id: number } } | null> {
    if (!APP.services.sage) {
      console.warn('[SAGE] Sage URI undefined. Did not send:', submission);

      return null;
    }

    return APP.services.sage.fetchAsync<{ submission: { id: number } }>(
      {
        url: 'submit/' + category,
        method: 'POST',
        body: JSON.stringify(submission),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        reportFailures: false
      }
    );
  }


  private _prop<T, E extends keyof T>(
    submission: T,
    property: E,
    defaultValue: T[E]
  ): T[E] {
    if (typeof submission[property] === 'undefined') {
      submission[property] = defaultValue;
    }

    return submission[property];
  }


  private _getPatronPreferences(): PatronPreferences {
    const cardPrefs = APP.patron.cardPreferences();
    const prefs: PatronPreferences = {
      readingDevice: cardPrefs.readingDevice,
      listeningDevice: cardPrefs.listeningDevice,
      sendingDevice: cardPrefs.sendingDevice,
      autoBorrowHolds: cardPrefs.autoBorrowHolds,
      autoDownloadRule: undefined,
      downloadQueueRule: undefined
    };

    if (APP.shell.has('rosters')) {
      prefs.autoDownloadRule = APP.updateManager.autoDownloadRule;
      prefs.downloadQueueRule = APP.updateManager.downloadQueueRule;
    }

    return prefs;
  }


  private _getNavigationData(): NavigationData {
    const out: NavigationData = {
      origin: location.origin,
      current: router.currentRoute.value.path,
      history: [],
      future: undefined
    };

    return out;
  }


  private _getLibraryCardData(): LibraryCardData[] {
    const cards: LibraryCardData[] = [];
    C.each(APP.patron.libraryCards().all, (card) => {
      cards.push({
        cardId: card.cardId,
        cardName: card.cardName,
        createTime: new Date(card.createTime),
        puid: card.puid,
        libraryName: card.library.name,
        libraryWebsiteId: card.library.websiteId.toString(),
        libraryAdvantageKey: card.advantageKey,
        limits: card.limits || {},
        counts: {
          loan: card.totalLoanCount(),
          hold: card.totalHoldCount()
        }
      });
    });

    return cards;
  }


  private _getUserAgentString(): string {
    return (window.BRIDGE && window.BRIDGE.userAgent
      ? (C.isString(window.BRIDGE.userAgent) ? window.BRIDGE.userAgent : window.BRIDGE.userAgent()) : '')
      || navigator.userAgent;
  }


  // The event detail object (evt.m) can include
  // errorMessage, errorSource, errorHumanReadable, errorData,
  // as well as any other submission property. For example:
  //
  // {
  //   source: 'shell',
  //   errorMessage: '...',
  //   errorSource: '...',
  //   chip: '...'
  // }
  //
  // Any of the four error-specific properties can also supplied
  // unprefixed in a nested 'error' object:
  //
  // {
  //   source: 'bifocal',
  //   error: {
  //     message: '...',
  //     source: '...'
  //   },
  //   titleId: '...'
  // }
  //
  private _submitErrorFromEvent(evt: DataEvent<SageRemoteErrorSubmission>) {
    const sub: SageRemoteErrorSubmission = C.jsonClone(evt.m);
    sub.errorMessage = sub.errorMessage || sub.message;

    const errInfo = C.excise(sub, 'error') || {};
    if (errInfo.message) { sub.errorMessage = errInfo.message; }
    if (errInfo.source) { sub.errorSource = errInfo.source; }
    if (errInfo.humanReadable) { sub.errorHumanReadable = errInfo.humanReadable; }
    if (errInfo.data) { sub.errorData = errInfo.data; }
    sub.errorSource = sub.errorSource || C.excise(sub, 'source');

    if (sub.errorMessage && sub.errorMessage.match('highlight')) {
      return this.submitAnnotation(sub);
    }

    return this.submit('error', sub);
  }
}

interface SageSubmission {
  errorHumanReadable?: string;
  errorSource?: string;
  errorMessage?: string;
  errorData?: Dictionary<any> | string;
  submissionContext?: SubmissionContext;
}

interface ContextualSageSubmission extends SageSubmission {
  titleId?: string;
  titleName?: string;
  titleFormat?: MediaType;
  titleAccess?: 'full' | 'sample';
  titleDownloadStatus?: number;
  activeLibraryName?: string;
  activeLibraryKey?: string;
  activeLibraryWebsiteId?: string;
}

interface ExpandedSageSubmission extends ContextualSageSubmission {
  chip?: string;
  primaryChip?: string;
  patronPreferences?: PatronPreferences;
  libraryCards?: LibraryCardData[];
  navigation?: NavigationData;
  appClientName?: string;
  appClientVersion?: string;
  appShellVersion?: string;
  appShellFlavor?: string;
  deviceTraits?: ShellTraits;
  userAgentString?: string;
  userAgentWidth?: number;
  userAgentHeight?: number;
  patronEmailAddress?: string;
}

export interface HistoricalSageSubmission extends ExpandedSageSubmission {
  category: SageCategory;
  createdAt: number;
  sageId?: number;
}

interface SubmissionContext {
  loan?: Possession;
  title?: TitleRecord;
  library?: Library;
}

interface PatronPreferences {
  readingDevice?: string;
  listeningDevice?: string;
  sendingDevice?: string;
  autoBorrowHolds?: boolean;
  autoDownloadRule?: AutoDownloadRule;
  downloadQueueRule?: DownloadQueueRule;
}

interface LibraryCardData {
  cardId?: number;
  cardName?: string;
  createTime?: Date;
  puid?: number;
  libraryName?: string;
  libraryWebsiteId?: string;
  libraryAdvantageKey?: string;
  limits?: any;
  counts?: {
    loan?: number;
    hold?: number;
  };
}

interface NavigationData {
  origin?: string;
  current?: string;
  history?: string[];
  future?: string[];
}

export interface SageRemoteErrorSubmission extends SageSubmission {
  error?: {
    message?: string;
    source?: string;
    humanReadable?: string;
    data?: Dictionary<any> | string;
  };
  message?: string;
  source?: string;
}
