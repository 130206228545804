<template>
  <PromptDialog
    ref="prompt"
    :heading="$t('circ.action.editHold')"
    @close="$emit('close')"
    @submit="submitAndClose"
  >
    <p
      :id="`edit-hold-explanation-${titleId}`"
    >
      {{ $t('circ.explanation.editHold') }}
    </p>

    <FormInput
      v-model="email"
      autofocus
      type="email"
      :required="true"
      :label="$t('form.email')"
      formName="email"
      :class="$style.email"
    />

    <div
      :class="$style.actions"
    >
      <FormSubmitButton
        :label="$t('circ.action.applyHoldEdit')"
        :enabled="changed"
        size="small"
      />

      <button
        :class="$style.cancel"
        type="button"
        @click="prompt?.closeModal()"
      >
        {{ $t('general.cancel') }}
      </button>

      <button
        :class="$style.delete"
        type="button"
        @click="deleteAndClose"
      >
        <Icon name="trashcan" />

        {{ $t('circ.action.deleteHold') }}
      </button>
    </div>
  </PromptDialog>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import PromptDialog from './dialogs/PromptDialog.vue';
import FormInput from './FormInput.vue';
import FormSubmitButton from './FormSubmitButton.vue';

export default defineComponent({
  name: 'HoldPrompt',
  components: {
    FormInput,
    FormSubmitButton,
    PromptDialog
  },
  props: {
    titleId: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      default: ''
    }
  },
  emits: [
    'close',
    'delete',
    'edit'
  ],
  setup: (props, ctx) => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);

    const submitAndClose = () => {
      ctx.emit('edit', { email: email.value });
      prompt.value?.closeModal();
    };

    const email = ref(props.emailAddress);

    const changed = computed(() => email.value !== props.emailAddress);

    const deleteAndClose = () => {
      ctx.emit('delete');
      prompt.value?.closeModal();
    };

    return {
      changed,
      email,
      prompt,
      deleteAndClose,
      submitAndClose
    };
  }
});
</script>

<style module>
.email {
  min-width: 25ch;
}

.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.cancel {
  text-transform: uppercase;
  color: var(--c-medium-black);
  composes: linked from global;
}
.delete {
  color: var(--c-primary-red);
  display: flex;
  align-items: center;
  padding: 0.25rem;
  composes: linked from global;
  margin-left: auto;
}

.delete svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5em;
  fill: currentColor;
}

@media screen and (max-width: 360px /* @px-vp-very-narrow */) {
  .delete {
    margin-left: 0;
  }
}
</style>
