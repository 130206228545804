import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_SortSelect = _resolveComponent("SortSelect")!
  const _component_PaginatedCardList = _resolveComponent("PaginatedCardList")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    header: _ctx.title,
    subtitle: _ctx.subtitle,
    announceHeader: _ctx.announcement
  }, {
    toolbar: _withCtx(() => [
      (_ctx.allFilters)
        ? (_openBlock(), _createBlock(_component_FilterButton, {
            key: 0,
            options: _ctx.allFilters,
            filterType: "subject",
            iconOnly: _ctx.mobile,
            onInput: _cache[0] || (_cache[0] = (val) => _ctx.$emit('update:filters', val))
          }, null, 8, ["options", "iconOnly"]))
        : _createCommentVNode("", true),
      _createVNode(_component_SortSelect, {
        modelValue: _ctx.sort,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sort) = $event)),
        options: _ctx.sortOptions,
        optionKey: "id",
        optionLabel: "label",
        class: _normalizeClass(_ctx.$style.sort)
      }, null, 8, ["modelValue", "options", "class"])
    ]),
    default: _withCtx(() => [
      (_ctx.tabs.length > 1)
        ? (_openBlock(), _createBlock(_component_TabView, {
            key: 0,
            tabs: _ctx.tabs,
            initialTab: _ctx.currentTab,
            onTab: _cache[3] || (_cache[3] = (val) => _ctx.$emit('update:tab', val))
          }, _createSlots({ _: 2 }, [
            _renderList(_ctx.tabs, (tab) => {
              return {
                name: `panel-${tab.id}`,
                fn: _withCtx(() => [
                  _createVNode(_component_PaginatedCardList, {
                    state: tab.state,
                    "onUpdate:currentPage": _cache[2] || (_cache[2] = (val) => _ctx.$emit('update:page', val))
                  }, null, 8, ["state"])
                ])
              }
            })
          ]), 1032, ["tabs", "initialTab"]))
        : (_ctx.tabs.length === 1)
          ? (_openBlock(), _createBlock(_component_PaginatedCardList, {
              key: 1,
              state: _ctx.tabs[0].state,
              "onUpdate:currentPage": _cache[4] || (_cache[4] = (val) => _ctx.$emit('update:page', val))
            }, null, 8, ["state"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["header", "subtitle", "announceHeader"]))
}