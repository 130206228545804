import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { Catalog } from 'app/models/catalog';
import { Lists } from 'app/models/lists';
import { Titles } from 'app/models/titles';
import { FreshableItem } from '../base/services/freshable';
import { ThunderLibrary, ThunderLibraryStatus } from '../base/thunder';

export class Library extends FreshableItem<ThunderLibrary> {
  private static readonly BANK_NAME = 'library';
  private static readonly SAVE_DELAY = 200;
  public static DEFAULT_COLORS: Dictionary<Color> = {
    primary: { hex: '#b3191b', rgb: [80, 178, 226] },
    secondary: { hex: '#74CEE2', rgb: [116, 206, 226] }
  };

  public static HUMAN_TYPES: {[key: string]: LibraryType} = {
    DLR: 'Public Library',
    SDL: 'School Library',
    CDL: 'College Library',
    Corporate: 'Corporate Library'
  };

  public type: LibraryType;
  public status: ThunderLibraryStatus;
  public isLexisNexis: boolean;
  public lists: Lists;
  public titles: Titles;
  public catalog: Catalog;
  public websiteId: number;
  public baseKey: string;
  public name: string;
  public hasAdvantageAccounts: boolean;
  public colors: Color[];
  public logo: Logo;
  public activatedAt: number;
  public retailerId: string;
  protected _activeKey: string;
  protected _failedSyncOffline: boolean;
  private _saveTimer: number;
  public disableNotes: boolean;


  constructor() {
    super('library', C.MS_DAY);
    this.type = '';
    this.lists = new Lists(this);
    this.titles = new Titles(this);
    this.catalog = new Catalog(this);
    this.logo = {width: 140, height: 60, url: Constants.assetPath('images/app-logo-ln.svg')};
    this.disableNotes = false;

    APP.events.listen('card:update:all', (_) => this._computeActiveKey());
  }


  public static async resolve(queryAttributes: BaseLibraryAttributes): Promise<Library> {
    let library: Library = null;

    try {
      let result: ThunderLibrary = null;

      if (queryAttributes.websiteId) {
        result = await APP.services.thunder.getLibraryByWebsiteId(queryAttributes.websiteId);
      } else {
        result = await APP.services.thunder.getLibraryByKey(queryAttributes.baseKey);
      }

      library = LibraryMapper.mapFromThunder(result);
    } catch (err) {
      // log?
    }

    return library;
  }


  public save() {
    clearTimeout(this._saveTimer);
    this._saveTimer = window.setTimeout(this._saveNow.bind(this), Library.SAVE_DELAY);
  }


  public static async load(): Promise<Library> {
    let library: Library = null;

    const bankedLibrary = <BankedLibrary>APP.bank.get(Library.BANK_NAME);

    if (bankedLibrary) {
      if (!bankedLibrary.retailerId && bankedLibrary.baseKey) {
        library = await Library.resolve({
          baseKey: bankedLibrary.baseKey
        });
      } else {
        library = LibraryMapper.mapFromBank(bankedLibrary);
      }
    }

    APP.events.dispatch('library:load', library);

    return library;
  }


  public activate() {
    if (!this.activatedAt) {
      this.activatedAt = C.epochMilliseconds();
      this.save();
    }

    this._computeActiveKey();

    if (!this.isEquivalent(APP.library)) {
      APP.library = this;
      APP.events.dispatch('library:update', this);
    }
  }


  /**
   * Returns the current activated library card for this
   * library, if there is such a card.
   */

  public key() {
    return this._activeKey || this.baseKey;
  }


  protected async freshenCall(): Promise<ThunderLibrary> {
    return APP.services.thunder.getLibraryByWebsiteId(this.websiteId);
  }


  protected freshenMap(result: ThunderLibrary) {
    LibraryMapper.freshenFromThunder(this, result);
  }


  public path() {
    return 'library/' + (this.baseKey || this._activeKey) + '/browse';
  }


  /**
   * Determines if this library is able to be accessed using the current
   * environment and the libraries status
   */
  public isAccessible(): boolean {
    return ['Live', 'Preview', 'Merged'].indexOf(this.status) >= 0;
  }


  public isEquivalent(otherLibrary: Library) {
    return otherLibrary && otherLibrary.websiteId === this.websiteId;
  }


  public isElrondLibrary() {
    return this.isLexisNexis;
  }


  protected _saveNow() {
    APP.bank.dump(Library.BANK_NAME, this);
  }


  protected _serializeAttributes(): BankedLibrary {
    return {
      websiteId: this.websiteId,
      baseKey: this.baseKey,
      name: this.name,
      type: this.type,
      isLexisNexis: this.isLexisNexis,
      hasAdvantageAccounts: this.hasAdvantageAccounts,
      colors: this.colors,
      logo: this.logo,
      activatedAt: this.activatedAt,
      retailerId: this.retailerId,
      disableNotes: this.disableNotes
    };
  }


  /**
   * Library is not fresh if it does not have a type
   */
  public arePropertiesFresh(): boolean {
    return !!this.type;
  }


  protected _computeActiveKey() {
    const card = APP.patron.cardForLibrary(this);
    const key = (card ? card.advantageKey : null) || this.baseKey;
    const changed = this._activeKey && (this._activeKey !== key);
    this._activeKey = key;
    if (changed && APP.library.websiteId === this.websiteId) {
      setTimeout(() => APP.refresh(), 0);
    }
  }
}

interface BaseLibraryAttributes {
  websiteId?: number;
  baseKey?: string;
}


interface Logo {
  width: number;
  height: number;
  url: string;
}

interface Color {
  hex: string;
  rgb: [number, number, number];
}

type LibraryType = '' | 'Public Library' | 'School Library' | 'College Library' | 'Corporate Library';

interface BankedLibrary {
  websiteId: number;
  baseKey: string;
  name: string;
  type: LibraryType;
  isLexisNexis: boolean;
  hasAdvantageAccounts: boolean;
  colors: Color[];
  logo: Logo;
  activatedAt: number;
  retailerId: string;
  disableNotes: boolean;
}

export class LibraryMapper {
  public static mapFromThunder(thunderLibrary: ThunderLibrary): Library {
    if (!thunderLibrary) {
      return null;
    }

    const library = new Library();
    LibraryMapper.freshenFromThunder(library, thunderLibrary);

    return library;
  }


  public static freshenFromThunder(library: Library, thunderLibrary: ThunderLibrary): void {
    if (!thunderLibrary) {
      return;
    }

    library.websiteId = thunderLibrary.websiteId;
    library.baseKey = thunderLibrary.preferredKey;
    library.name = thunderLibrary.name;
    library.type = Library.HUMAN_TYPES[thunderLibrary.type];
    library.isLexisNexis = thunderLibrary.isLexisNexis;
    library.hasAdvantageAccounts = thunderLibrary.hasAdvantageAccounts;
    library.retailerId = thunderLibrary.fulfillmentId;
    library.status = thunderLibrary.status;

    if (thunderLibrary.settings) {
      library.colors = [Library.DEFAULT_COLORS.primary, Library.DEFAULT_COLORS.secondary];
      let c = thunderLibrary.settings.primaryColor;
      if (c) {
        library.colors[0] = { hex: c.hex, rgb: [c.rgb.red, c.rgb.green, c.rgb.blue] };
      }

      c = thunderLibrary.settings.secondaryColor;
      if (c) {
        library.colors[2] = { hex: c.hex, rgb: [c.rgb.red, c.rgb.green, c.rgb.blue] };
      }

      if (thunderLibrary.settings.logo140X60) {
        library.logo = {
          width: 140,
          height: 60,
          url: thunderLibrary.settings.logo140X60.href
        };
      }

      if (thunderLibrary.settings.disableNotes) {
        library.disableNotes = thunderLibrary.settings.disableNotes;
      }
    }
  }


  public static mapFromBank(bankedLibrary: BankedLibrary): Library {
    if (!bankedLibrary) {
      return null;
    }

    const library = new Library();
    library.websiteId = bankedLibrary.websiteId;
    library.baseKey = bankedLibrary.baseKey;
    library.retailerId = bankedLibrary.retailerId;
    library.name = bankedLibrary.name;
    library.type = bankedLibrary.type;
    library.hasAdvantageAccounts = bankedLibrary.hasAdvantageAccounts;
    library.colors = bankedLibrary.colors;

    if (bankedLibrary.logo) {
      library.logo = bankedLibrary.logo;
    }

    if (bankedLibrary.disableNotes) {
      library.disableNotes = bankedLibrary.disableNotes;
    }

    library.activatedAt = bankedLibrary.activatedAt;

    return library;
  }
}
