import { APP } from 'app/base/app';
import { TagRemoteResponse } from 'app/base/taggish';
import { ItemWithTitleCache } from './item-with-title';

export class Tagging extends ItemWithTitleCache<TagResponse, BankedTag> {
  public slug: string;
  public createTime: number;


  protected mapItem(response: TagResponse): void {
    this.createTime = response.createTime;

    if (response.titleSlug) {
      this.slug = APP.library.websiteId + '-' + response.titleSlug;
    }
  }


  protected mapItemFromBank(attrs: BankedTag): void {
    if (attrs.createTime) {
      this.createTime = attrs.createTime;
    }

    attrs.isAssigned = attrs.isAssigned;

    if (this.titleSlug) {
      this.slug = APP.library.websiteId + '-' + this.titleSlug;
    }
  }


  protected mapTitle(response: TagResponse): string {
    return response.titleSlug;
  }


  protected serializeInternal(): BankedTag {
    const out: BankedTag = {};
    if (this.createTime) {
      out.createTime = this.createTime;
    }

    return out;
  }


  protected _transformRemoteAttributes(attrs: TagResponse | TagRemoteResponse): any {
    if (this.attrsAreTagRemoteResponse(attrs)) {
      return {
        createTime: attrs.timestamp,
        titleSlug: attrs.title.titleId
      };
    }

    return attrs;
  }


  private attrsAreTagRemoteResponse(attrs: TagResponse | TagRemoteResponse): attrs is TagRemoteResponse {
    return 'title' in attrs && !!attrs.title.titleId;
  }
}

interface TagResponse {
  titleSlug: string;
  createTime: number;
}

interface BankedTag {
  createTime?: number;
  isAssigned?: boolean;
}
