import { APP } from 'app/base/app';
import Quirks from 'lib/gala/src/quirks';

export class Scroller {
  public static DEFAULT_DURATION = 250;

  public element: HTMLElement;
  public lastScroll: ScrollInfo;

  private _announceTimer: number;
  private _nativeScrolling: boolean;


  constructor(element: HTMLElement) {
    this.element = element;
    APP.events.scrollable(this.element);
    this.lastScroll = { y: 0, h: 0 };
    APP.events.on('scroll', () => this._onScroll(), this.element);
    this.announce();
  }


  /**
   * Calculates scroll state on the next tick, and dispatches
   * arena:scroll if this is the arena's active scroller.
   */
  public announce(): void {
    clearTimeout(this._announceTimer);
    this._announceTimer = window.setTimeout(() => this._onScroll(true), 0);
  }


  /**
   * Options are as per scrollTo().
   * @param options
   */
  public scrollToTop(options?: { duration?: number }): void {
    this.scrollTo(0, options);
  }


  /**
   * Options:
   *
   * - duration - How long to animate the scroll. 0 for immediate. Other
   *    durations are not guaranteed, because native scrolling duration
   *    isn't configurable.
   *
   * @param y
   * @param options
   */
  public scrollTo(y: number, options: { duration?: number } = {}): void {
    if (this.element && this.element.scrollTop !== y) {
      if (options.duration === 0) {
        this.element.scrollTop = y;
      }
      if (typeof this._nativeScrolling === 'undefined') {
        this._nativeScrolling = (
          'scrollBehavior' in this.element.style &&
          typeof this.element.scroll === 'function'
        );
      }
      if (this._nativeScrolling) {
        this.element.scroll({ top: y, behavior: 'smooth' });
      } else {
        Scroller.smoothScroll(y, 'y', options.duration || Scroller.DEFAULT_DURATION, this.element);
        // This style-fiddling is for MobileSafari, which does not
        // stop any momentum scrolling when scrollTop is set. Reproduce
        // by disabling this, the flinging the scroller and tapping
        // on the status bar.
        this.element.style.setProperty('-webkit-overflow-scrolling', 'auto');
        setTimeout(() => this.element.style.removeProperty('-webkit-overflow-scrolling'), 0);
      }
    }
  }


  /**
   * Options:
   *
   * - AS FOR scrollTo()
   *
   * - aperture - The distance from top of screen where the element
   *    is allowed to be. If the element is not in this rectangle, we
   *    will scroll so that the top of the element is at the middle
   *    point of this rectangle.
   *
   *    i.e. an aperture of window.innerHeight*0.5 will mean the element
   *    must be in the top half of the screen, and if it is not, we will
   *    scroll so that the element's top is at 25% of the screen height.
   *
   * @param element
   * @param options
   */
  public scrollToElement(element: HTMLElement, options: { aperture?: number; duration?: number } = {}): void {
    if (!this.element.contains(element)) {
      return;
    }
    const aperture = typeof options.aperture !== 'undefined'
      ? options.aperture : window.innerHeight * 0.5;
    const scRect = this.element.getBoundingClientRect();
    const elRect = element.getBoundingClientRect();
    const deltaY = elRect.top - scRect.top;
    const scrollY = this.element.scrollTop;
    if (deltaY > 0 && scrollY + deltaY < scrollY + aperture) {
      return;
    }
    const destY = scrollY + deltaY - aperture;
    this.scrollTo(destY, options);
  }


  /**
   * Options:
   *
   * - duration - How long to animate the scroll. 0 for immediate.
   *
   * - destY - Target pixel location on screen. Defaults to 120.
   *
   * - anchor - Element to raise up to destY. Defaults to input. But
   *    if the input is in a popover you may want to use the popover
   *    anchor instead.
   *
   * @param input
   * @param options
   */
  public scrollToFocus(input, options: { aperture?: number; duration?: number; destY?: number; anchor?: HTMLElement } = {}): void {
    options.anchor = options.anchor || input;
    if (typeof options.destY !== 'number') {
      options.destY = 120;
    }
    if (typeof options.duration !== 'number') {
      options.duration = 200;
    }
    if (!APP.shell.has('soft-keyboard')) {
      this.scrollToElement(options.anchor, {
        aperture: window.innerHeight * 0.75,
        duration: options.duration
      });
    } else {
      const deltaY = options.anchor.parentElement.getBoundingClientRect().top;
      const anchorY = deltaY + this.element.scrollTop;
      if (deltaY < 0 || deltaY > options.destY) {
        const finalY = anchorY - options.destY;
        this.scrollTo(finalY, options);
        // This style-fiddling is solely for MobileSafari, which
        // weirdly does not scroll the cursor with the rest of the input
        // box, so it keeps flashing at its original location.
        // https://bugs.webkit.org/show_bug.cgi?id=138201
        input.style.removeProperty('-webkit-transform');
        setTimeout(() => input.style.setProperty('-webkit-transform', 'translateZ(0)'), 200);
      }
    }
  }


  public static smoothScroll(
    to: number,
    direction: 'x' | 'y',
    duration: number,
    element: HTMLElement
  ): { id: number } {
    const property = direction === 'x' ? 'scrollLeft' : 'scrollTop';

    const easeInOutQuad = (t, b, c, d) => {
      let adjustedT = t / (d / 2);
      if (adjustedT < 1) {
        return c / 2 * adjustedT * adjustedT + b;
      }
      adjustedT--;

      return -c / 2 * (adjustedT * (adjustedT - 2) - 1) + b;
    };
    const start = element[property];
    const change = to - start;
    let time = 0;
    const increment = 20;
    const animationId = { id: 0 };
    const animateScroll = () => {
      time += increment;
      if (time < duration) {
        element[property] = easeInOutQuad(time, start, change, duration);
        animationId.id = requestAnimationFrame(animateScroll);
      } else {
        element[property] = to;
        element.style.removeProperty('scroll-snap-type');
      }
    };
    // Scroll snapping clamps the scroll[Left, Top] values which does not
    // allow for smooth scrolling. But if you do this on Safari,
    // it doesn't let you scroll at all.
    if (
      !(
        Quirks.ask('safari')
        || Quirks.ask('ios')
        || (Quirks.ask('mobile') && Quirks.ask('macosx'))
      )
    ) {
      element.style.setProperty('scroll-snap-type', 'none');
    }
    animateScroll();

    return animationId;
  }


  protected _onScroll(navigated = false): void {
    const max = Math.ceil(this.element.scrollHeight - this.element.clientHeight) - 1;
    const y = Math.round(Math.max(0, Math.min(this.element.scrollTop, max)));
    const scroll: ScrollInfo = {
      max: Math.max(y, max),
      y: y,
      h: this.element.clientHeight,
      navigated: navigated
    };
    if (
      scroll.y !== this.lastScroll.y ||
      scroll.max !== this.lastScroll.max
    ) {
      this.lastScroll = scroll;
    }
    if (this === APP.arena.scroller) {
      APP.events.dispatch('arena:scroll', scroll);
    }
  }
}

export interface ScrollInfo  {
  max?: number;
  y: number;
  h: number;
  navigated?: boolean;
}
