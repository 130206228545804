import * as C from 'lib/common';
import { Dictionary } from 'lib/common/dictionary';
import { Quirks } from 'lib/quirkbase/quirkbase';
import { Constants } from './constants';
import { Events } from './events';
import { EventHandler, Handler } from './handler';

export class ContactHandler implements Handler<GlobalEventHandlersEventMap, ContactEvent> {
  public callbacks: Dictionary<(evt: Pointer) => void>;
  public element: HTMLElement | SVGElement;
  public options: ContactHandlerOptions;
  private readonly _handlers: ContactHandlers;
  private _pointers: Pointer[];
  private _continued = false;
  private _initialPointerDistance: number | undefined;


  /**
   * Options:
   *  - multitouch: False by default. If true, sends events for multiple
   *      pointers. Otherwise, cancels contact if a second pointer appears.
   *
   *  - cancelOnLeave: If any pointer leaves the element, cancel contact.
   *      Defaults to false, which means the element "captures the pointer".
   *      You can set it to true, or you can set it to a particular element.
   *
   * - capture
   *
   * @param element
   * @param callbacks
   * @param options
   */
  constructor(
    callbacks: Dictionary<(evt: Pointer) => void>,
    element: HTMLElement | SVGElement = Events.DEFAULT_DISPATCHER,
    options: ContactHandlerOptions = {}
  ) {
    this.element = element;
    this.callbacks = callbacks;
    this.options = options;
    this._handlers = { start: [], move: [], end: [], cancel: [] };
    this._pointers = [];
    this._setAppropriateTouchAction(this.element);
  }


  // ## PUBLIC METHODS

  public listen(): ContactHandler {
    this._listenOnCategory(this.element, (evt) => this._start(evt), ['pointerdown'], this._handlers.start);

    return this;
  }


  public deafen(): ContactHandler {
    this._deafenHandlers(this._handlers.start);
    while (this._pointers.length) {
      this._removePointer(this._pointers[0]);
    }

    return this;
  }


  // ## PROTECTED METHODS

  public _setAppropriateTouchAction(element: (HTMLElement | SVGElement) & { _scrollableAxis?: 'x' | 'y' }): void {
    let touchAction = Constants.DEFAULT_TOUCH_ACTION;
    let currentElement: ((HTMLElement | SVGElement) & { _scrollableAxis?: 'x' | 'y' }) | null = element;
    while (currentElement && currentElement.getAttribute) {
      const ta = (
        currentElement.getAttribute('touch-action') ||
        (currentElement._scrollableAxis ? `pan-${currentElement._scrollableAxis}` : null)
      );
      if (ta) {
        touchAction = ta;
        break;
      }
      currentElement = currentElement.parentElement;
    }
    Events.GLOBAL_EVENTS.setTouchAction(this.element, touchAction, { override: false });
  }


  public _listenContinue(): void {
    const eventTypes: Dictionary<ContactEvent[]> = {
      move: ['pointermove'],
      end: ['pointerup'],
      cancel: ['pointercancel']
    };
    let pointerTarget = this.element;
    if (this.options.cancelOnLeave) {
      eventTypes.cancel.push('pointerleave');
      if (typeof this.options.cancelOnLeave === 'boolean') {
        pointerTarget = this.element;
      } else {
        pointerTarget = this.options.cancelOnLeave;
      }
    }
    this._listenOnCategory(pointerTarget, (evt) => this._move(evt), eventTypes.move, this._handlers.move);
    this._listenOnCategory(pointerTarget, (evt) => this._end(evt), eventTypes.end, this._handlers.end);
    this._listenOnCategory(pointerTarget, (evt) => this._cancel(evt), eventTypes.cancel, this._handlers.cancel);
    this._continued = true;
  }


  public _deafenContinue(): void {
    this._deafenHandlers(this._handlers.move);
    this._deafenHandlers(this._handlers.end);
    this._deafenHandlers(this._handlers.cancel);
    this._continued = false;
  }


  public _listenOnCategory(
    target: any,
    callback: (evt: PointerEvent) => void,
    eventTypes: ContactEvent[],
    contactHandler: EventHandler<GlobalEventHandlersEventMap, ContactEvent>[]
  ): void {
    if (contactHandler.length) {
      return;
    }

    C.each(eventTypes, (eventType) => {
      // CAUTION = 'capturing phase' option is not yet supported in PEP:
      const handler = Events.GLOBAL_EVENTS.on(
        eventType,
        (evt) => callback(evt),
        target,
        this.options.capture
      );
      contactHandler.push(handler);
    });
  }


  public _start(evt: Pointer): void {
    if (evt.button > 0) {
      return;
    }

    // Disable multi-finger swipe on trackpad in app on ChromeOS. See
    // https://confluence.hq.overdrive.com/display/KL/Chromebook+trackpad
    if (Quirks.ask('chromebook-webview') && evt.width === 0) {
      return;
    }

    this._addPointer(evt);
    this._invokeCallback('start', evt);
  }


  public _move(evt: Pointer): void {
    if (!this._updatePointer(evt)) {
      return;
    }
    this._invokeCallback('move', evt);
  }


  public _end(evt: Pointer): void {
    if (!this._updatePointer(evt)) {
      return;
    }
    this._invokeCallback('end', evt);
    this._removePointer(evt);
  }


  public _cancel(evt: Pointer): void {
    if (!this._updatePointer(evt)) {
      return;
    }
    this._invokeCallback('cancel', evt);
    this._removePointer(evt);
  }


  public _deafenHandlers(handlers: EventHandler<GlobalEventHandlersEventMap, ContactEvent>[]): void {
    while (handlers.length) {
      handlers.shift()!.deafen();
    }
  }


  public _invokeCallback(evtCategory: ContactCategory, evt: Pointer): void {
    evt.contactType = evtCategory;
    evt.contactPointers = this._pointers.slice(0);
    evt.contactScale = this._computeScaleOfPointers();
    if (typeof this.callbacks[evtCategory] === 'function') {
      this.callbacks[evtCategory](evt);
    }
  }


  public _addPointer(evt: Pointer): void {
    if (!this.options.multitouch) {
      this._pointers.splice(0);
    }
    evt.contactIndex = this._pointers.length;
    this._pointers.push(evt);
    if (!this._continued) {
      this._listenContinue();
    }
    if (!this.options.cancelOnLeave) {
      this.element.setPointerCapture(evt.pointerId);
    }
    delete this._initialPointerDistance;
  }


  public _updatePointer(evt: Pointer): boolean {
    for (let i = 0; i < this._pointers.length; ++i) {
      const ptr = this._pointers[i];
      if (ptr.pointerId === evt.pointerId) {
        this._pointers[i] = evt;
        evt.contactIndex = ptr.contactIndex;

        return true;
      }
    }

    return false;
  }


  public _removePointer(evt: PointerEvent): void {
    try {
      this.element.releasePointerCapture(evt.pointerId);
    } catch (e) {
      // no-op
    }
    const ptrs = [];
    for (const ptr of this._pointers) {
      if (ptr.pointerId !== evt.pointerId) {
        ptrs.push(ptr);
      }
    }
    this._pointers = ptrs;
    if (this._continued && !this._pointers.length) {
      this._deafenContinue();
    }
  }


  public _computeScaleOfPointers(): number {
    if (this._pointers.length !== 2) {
      return 1;
    }
    const pointerDistance = (ptrs: PointerEvent[]): number => {
      const x = ptrs[1].pageX - ptrs[0].pageX;
      const y = ptrs[1].pageY - ptrs[0].pageY;

      return Math.sqrt((x * x) + (y * y));
    };
    const latestDistance = pointerDistance(this._pointers);
    const initialDistance = this._initialPointerDistance || latestDistance;
    this._initialPointerDistance = initialDistance;

    return latestDistance / initialDistance;
  }
}

type ContactCategory = 'start' | 'move' | 'end' | 'cancel';
type ContactEvent = 'pointermove' | 'pointerup' | 'pointercancel' | 'pointerleave' | 'pointerdown';
type Pointer = PointerEvent & { contactIndex?: number, contactType?: ContactCategory, contactPointers?: Pointer[], contactScale?: number };

export interface ContactHandlerState {
  startX?: number;
  startPosition?: number;
  slidePosition?: number;
  prevPosition?: number;
  immediate?: boolean;
}

export interface ContactHandlerOptions {
  multitouch?: boolean;
  cancelOnLeave?: boolean | HTMLElement;
  capture?: boolean;
}

interface ContactHandlers {
  start: EventHandler<GlobalEventHandlersEventMap, ContactEvent>[];
  move: EventHandler<GlobalEventHandlersEventMap, ContactEvent>[];
  end: EventHandler<GlobalEventHandlersEventMap, ContactEvent>[];
  cancel: EventHandler<GlobalEventHandlersEventMap, ContactEvent>[];
}
