import type { EventCallback } from 'app/base/event-map';
import Events from 'app/events/events';
import { Ref, ref } from 'vue';

type LibraryData = {
  websiteId: number;
  baseKey: string;
  advantageKey: string;
  name: string;
  disableNotes: boolean;
};

const library = ref<LibraryData | null>(null);

export function useLibrary(): Readonly<Ref<Readonly<LibraryData> | null>> {
  return library;
}

const update: EventCallback<'library:load'> = ({ m }) => {
  if (!m) {
    library.value = null;
  } else {
    library.value = {
      websiteId: m.websiteId,
      baseKey: m.baseKey,
      advantageKey: m.key(),
      name: m.name,
      disableNotes: m.disableNotes
    };
  }
};

Events.on('library:load', update);
Events.on('library:update', update);
Events.on('card:update:all', ({ m }) => {
  if (!library.value) { return; }

  const activeCard = m.all
    .find((card) => card.library.websiteId === library.value?.websiteId);

  if (activeCard) {
    library.value.advantageKey = activeCard.advantageKey;
  }
});
