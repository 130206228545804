import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSwitch = _resolveComponent("FormSwitch")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.$style.header)
    }, _toDisplayString(_ctx.$t('header.account.downloadSettings.label')), 3),
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.$style.section)
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('header.account.downloadSettings.autoDownloads.label')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('header.account.downloadSettings.autoDownloads.explanation')), 1)
      ]),
      _createVNode(_component_FormSwitch, {
        modelValue: _ctx.autoDownloads,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.autoDownloads) = $event)),
        ariaLabel: "header.account.downloadSettings.autoDownloads.label"
      }, null, 8, ["modelValue"])
    ], 2),
    (_ctx.hasCellular)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: _normalizeClass([_ctx.$style.section, _ctx.autoDownloads ? '' : _ctx.$style.disabled])
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('header.account.downloadSettings.wifiDownloads.label')), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('header.account.downloadSettings.wifiDownloads.explanation')), 1)
          ]),
          _createVNode(_component_FormSwitch, {
            modelValue: _ctx.wifiDownloads,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.wifiDownloads) = $event)),
            ariaLabel: "header.account.downloadSettings.wifiDownloads.label"
          }, null, 8, ["modelValue"])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}