<template>
  <SidebarDialog
    :heading="$t('annotationCopying.manageCopying.header')"
    :resizable="false"
    side="right"
    @close="$emit('close')"
  >
    <template #secondHeader>
      <section :class="$style.header">
        <Cover
          :item="title"
          :class="$style.cover"
        />
        <p :class="$style.title">
          {{ title.title }}
        </p>
        <p
          v-if="title.subtitle"
          :class="$style.subtitle"
        >
          {{ title.subtitle }}
        </p>
      </section>
    </template>
    <template #default>
      <ManageCopyingContent
        v-if="copyJobsState"
        :title="title"
        :state="copyJobsState"
      />
    </template>
  </SidebarDialog>
</template>

<script lang="ts">
import Cover from 'app/components/Cover.vue';
import ManageCopyingContent from 'app/components/ManageCopyingContent.vue';
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'ManageCopyingSidebar',
  components: {
    ManageCopyingContent,
    SidebarDialog,
    Cover
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    copyJobsState: {
      type: Object as PropType<CopyJobsState>,
      default: undefined
    }
  },
  emits: [
    'close'
  ]
});
</script>

<style module>
.header {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  row-gap: 0.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-dark-gray);
  margin-bottom: 1rem;
}

.cover {
  grid-row: 1 / span 2;
  grid-column: 1;
  width: 2.5rem;
  height: 3.25rem;
  border: 1px solid var(--c-darkest-gray);
}

.title {
  grid-row: 1;
  grid-column: 2;
  font-size: var(--fs-medium);
  font-weight: var(--fw-bold);
}

.subtitle {
  grid-row: 2;
  grid-column: 2;
  color: var(--c-light-black);
  font-size: var(--fs-body);
}
</style>
