import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "role", "viewBox", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    id: _ctx.iconId,
    ref: "root",
    role: _ctx.role,
    class: "icon",
    viewBox: _ctx.viewBox,
    innerHTML: _ctx.icon
  }, null, 8, _hoisted_1))
}