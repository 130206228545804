import { PostishCopyJobResponse } from 'app/base/postish';
import { DeepReadonly, Ref, computed } from 'vue';

export type CopyJobsState = {
  status: 'loaded';
  jobs: PostishCopyJobResponse[];
} | {
  status: 'loading';
} | {
  status: 'error';
};

export function useCopyJobsState(copyJobs: DeepReadonly<Ref<PostishCopyJobResponse[]>>, loading: Ref<boolean>, error: Ref<boolean>) {
  const copyJobsState = computed<CopyJobsState>(() => {
    if (copyJobs.value) {
      return {
        status: 'loaded',
        jobs: copyJobs.value
      } as CopyJobsState;
    }

    if (loading.value) {
      return {
        status: 'loading'
      };
    }

    return {
      status: 'error'
    };
  });

  return {
    copyJobsState
  };
};
