<template>
  <Dropdown
    v-model="channel"
    :options="channelOptions"
    :buttonClass="[$style.dropdownButton, 'dark']"
    :listClass="$style.dropdownList"
    :optionClass="$style.dropdownOption"
    :selectedClass="$style.dropdownSelected"
    :highlightedClass="$style.dropdownHighlighted"
    :style="`--c-channel: ${color(channel)}`"
  />
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import { channel as changeChannel } from 'app/base/debugger';
import env from 'app/base/env';
import { Dictionary } from 'lib/common/dictionary';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import Dropdown from './Dropdown.vue';

export default defineComponent({
  name: 'ChannelIndicator',
  components: {
    Dropdown
  },
  setup: () => {
    const channel = ref(env.CHANNEL);
    const channels = ref<string[]>([ channel.value ]);

    onMounted(async () => {
      try {
        const channelResponse = (await APP.services.elrond
          .fetchAsync<Dictionary<{ name: string }>>(
            'channel/guide'
          )
        )!;

        channels.value = Object.keys(channelResponse);
      } catch (e) {
        console.error('[CHANNELINDICATOR] Could not load channel.');
      }
    });

    watch(channel, async () => {
      await changeChannel({ name: channel.value });
    });

    const channelOptions = computed(() => {
      return channels.value.map((c) => ({
        id: c,
        displayName: c
      }));
    });

    return {
      color,
      channel,
      channelOptions
    };
  }
});

export function color(channel: string) {
  if (!channel || channel === 'main') {
    return 'var(--c-primary-red)';
  }
  let hash = 0;
  for (let i = 0; i < channel.length; i++) {
    hash = channel.charCodeAt(i) + ((hash << 5) - hash);
  }

  const [h, s, l] = [Math.abs(hash % 360), 100, 70];
  const c = `hsl(${h}, ${s}%, ${l}%)`;

  return c;
}
</script>

<style module>
.dropdown-button {
  display: flex;
  align-content: center;
  padding: 0.25rem 0.5rem;
  border: 2px solid var(--c-channel);
  color: var(c--dark-black);
  font-weight: bold;
  font-size: 1rem;
  border-radius: var(--form-border-radius);
}

.dropdown-list {
  position: absolute;
  padding: 0.5rem;
  right: 0;
  transform: translateY(0.25rem);
  display: grid;
  grid-template-columns: max-content;
  grid-auto-flow: row;
  grid-row-gap: 0.5rem;
  border-radius: var(--form-border-radius);
  background-color: var(--c-light-gray);
  color: var(--c-dark-black);
  box-shadow: 0px 3px 6px rgba(34, 34, 34, 0.15);
}

.dropdown-option {
  padding: 0.5rem;
  min-width: max-content;
  border-left: 4px solid transparent;
}

.dropdown-option:last-child {
  margin-bottom: 0;
}

.dropdown-highlighted,
.dropdown-option:hover {
  color: var(--c-dark-black);
  background-color: var(--c-dark-gray);
}

.dropdown-selected {
  border-color: var(--c-dark-black);
}
</style>
