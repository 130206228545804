import { APP } from 'app/base/app';
import { ItemWithTitleCache } from 'app/models/item-with-title';
import { TitleMapper, TitleRecord } from 'app/models/title';
import { SentryPossession } from '../base/sentry';


export class SharedTitle extends ItemWithTitleCache<SentryPossession, BankedSharedTitle> {
  public titleSlug: string;
  public createTime: number;
  public lastAccessTime: number;
  public slug: string;


  public static SORT_FUNCTIONS = {
    title: (a: SharedTitle, b: SharedTitle) => {
      const titleA = a.titleRecord;
      const titleB = b.titleRecord;

      if (!a || !titleA) { return -1; }
      if (!b || !titleB) { return 1; }

      return titleA.title.localeCompare(titleB.title);
    },
    created: (a: SharedTitle, b: SharedTitle) => {
      if (!a || !a.createTime) { return -1; }
      if (!b || !b.createTime) { return 1; }

      return b.createTime - a.createTime;
    },
    accessed: (a: SharedTitle, b: SharedTitle) => {
      if (!a) { return 1; }
      if (!b) { return -1; }

      if (a.lastAccessTime && b.lastAccessTime) { return b.lastAccessTime - a.lastAccessTime; }
      if (a.lastAccessTime && !b.lastAccessTime) { return -1; }
      if (!a.lastAccessTime && b.lastAccessTime) { return 1; }

      return b.createTime - a.createTime;
    }
  };

  public static FILTER_FUNCTIONS = {
    filterByTitle: (s: SharedTitle, filterValue: string, caseSensitive = false) => {
      if (filterValue && s && s.titleRecord && s.titleRecord.title) {
        const normTitle = caseSensitive ? s.titleRecord.title : s.titleRecord.title.toLowerCase();
        const normFilter = caseSensitive ? filterValue : filterValue.toLowerCase();

        return normTitle.indexOf(normFilter) >= 0;
      }

      return true;
    },
    filterByTitles: (s: SharedTitle, filterValue: string, caseSensitive = false) => {
      if (filterValue && s && s.titleRecord && s.titleRecord.title) {
        let normTitle = caseSensitive ? s.titleRecord.title : s.titleRecord.title.toLowerCase();
        if (s.titleRecord.subtitle) { // include subtitle in this text compare
          const normSubtitle = caseSensitive ? s.titleRecord.subtitle : s.titleRecord.subtitle.toLowerCase();
          normTitle = `${normTitle} ${normSubtitle}`;
        }
        const normFilter = caseSensitive ? filterValue : filterValue.toLowerCase();

        return normTitle.indexOf(normFilter) >= 0;
      }

      return true;
    }
  };


  // ** ItemWithTitleCache Implementation **


  protected mapItem(pos: SentryPossession): void {
    this.createTime = pos.createTime;
    const slug = pos.id;
    if (slug) {
      this.assignTitle(slug);
    }
  }


  protected mapItemFromBank(attrs: BankedSharedTitle): void {
    if (!attrs) {
      return;
    }

    this.slug = attrs.slug;
    this.createTime =  attrs.createTime;
    this.lastAccessTime =  attrs.lastAccessTime;
  }


  protected mapTitle(response: SentryPossession): TitleRecord {
    return TitleMapper.mapFromThunder(response);
  }


  protected serializeInternal(): BankedSharedTitle {
    const out: BankedSharedTitle = {};
    if (this.createTime) {
      out.createTime = this.createTime;
    }

    if (this.lastAccessTime) {
      out.lastAccessTime = this.lastAccessTime;
    }

    return {
      slug: this.slug,
      createTime: this.createTime,
      lastAccessTime: this.lastAccessTime
    };
  }


  // ** Public Functions **


  /**
   * @desc Assign a title to this shared title object
   * @param titleSlug
   */
  public assignTitle(titleSlug: string) {
    if (!titleSlug) {
      throw(new Error('[SHARED-TITLE] Unable to assign title without slug'));
    }

    this.titleSlug = titleSlug;
    this.slug = `${APP.library.websiteId}-${titleSlug}`;
  }
}


export interface BankedSharedTitle {
  slug?: string;
  createTime?: number;
  lastAccessTime?: number;
}
