import { Dictionary } from './dictionary';
import { encode, EncodingContext } from './string';

export function absoluteURL(relativeURL: string, doc: Document = document): string {
  const resolver = doc.createElement('a');
  resolver.setAttribute('href', relativeURL);
  const url = resolver.href;

  return url.match(/%/) ? url : encodeURI(url);
}


export function resolvePath(path: string): string {
  const parts = path.split('/');
  let i = 1;
  while (i < parts.length) {
    if (parts[i] === '.') {
      parts.splice(i, 1);
    } else if (parts[i] === '..' && i > 0 && parts[i - 1] !== '..') {
      parts.splice(i - 1, 2);
      i -= 2;
    }
    i += 1;
  }

  return parts.join('/');
}


export function expandPath(path: string, base: string): string {
  const baseParts = base.split('/');
  baseParts.pop();

  return resolvePath(`${baseParts.join('/')}/${path}`);
}


export function parameterizeURL<T>(
  url: string,
  params: T
): string {
  const paramList: string[] = [];
  for (const key in params) {
    const val = params[key];
    if (typeof val !== 'undefined' && val !== null && <string><unknown>val !== '') {
      const valArray = val instanceof Array ? val : [val];
      for (let i = 0, ii = valArray.length; i < ii; ++i) {
        const v = valArray[i];
        paramList.push(`${encode(key, EncodingContext.UriComponent)}=${encode(v, EncodingContext.UriComponent)}`);
      }
    }
  }

  let parameterizedURL = url;
  if (paramList.length) {
    parameterizedURL += parameterizedURL.match(/\?/) ? '&' : '?';
    parameterizedURL += paramList.join('&');
  }

  return parameterizedURL;
}

/**
 * Parse current query string and return all
 * parameters as object
 * @param qs query string
 */
export function parseQueryString<T extends Dictionary<string>>(qs: string): T {
  if (!qs) { return <T>{}; }
  const result: Dictionary<string> = {};
  const parts = qs.substring(1).split('&') || [];

  for (const part of parts) {
    const comp = part.split('=');
    result[decodeURIComponent(comp[0])] = decodeURIComponent(comp[1]);
  }

  return <T>result;
}

