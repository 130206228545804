import { EventCallback, EventHandler, EventId } from 'app/base/event-map';
import Events from 'app/events/events';
import { onBeforeUnmount } from 'vue';

/**
 * Sets up event handlers using APP.events.
 * Tears down the event handlers when the component unmounts.
 *
 * @param events A dictionary of event name to event callback
 */
export function useAppEvents(events: Partial<{ [k in EventId]: EventCallback<k> }> = {}) {
  const handlers = {} as { [k: string]: EventHandler<any> };
  for (const e in events) {
    // Not sure how to type this properly
    handlers[e] = Events.on(e as EventId, events[e as EventId] as any);
  }

  onBeforeUnmount(() => {
    Events.off(handlers);
  });

  return {
    dispatch: Events.dispatch
  };
}
