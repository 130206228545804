import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["datetime", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("time", {
    datetime: new Date(_ctx.timestamp).toISOString(),
    title: new Date(_ctx.timestamp).toString()
  }, [
    (_ctx.relativeTime)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('time.relative', { relativeTime: _ctx.relativeTime })), 1)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('time.date', { date: _ctx.date })), 1)
        ], 64))
  ], 8, _hoisted_1))
}