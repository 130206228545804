<template>
  <Teleport
    to="#popouts"
  >
    <dialog
      v-bind="$attrs"
      ref="dialog"
      :class="[$style.dialog, { [$style.animationOpen]: animationOpen }]"
      :style="styles"
      :aria-label="headerLabel"
      @cancel="closeWithAnimation"
      @click="closeWithAnimation"
      @keydown.esc="closeIfAllowed"
    >
      <div
        :class="$style.content"
        @click="(e) => e.stopPropagation()"
      >
        <div
          class="visually-hidden"
          aria-live="polite"
        >
          {{ announce ? announcement : undefined }}
        </div>
        <div :class="$style.headerContainer">
          <header :class="$style.header">
            <h2
              :class="$style.menuHeading"
            >
              {{ headerLabel }}
            </h2>
          </header>
          <button
            ref="closeButton"
            :class="$style.dismiss"
            :aria-label="$t('general.close')"
            @click="closeWithAnimation"
          >
            <Icon name="dismiss" />
          </button>
        </div>
        <slot></slot>
      </div>
    </dialog>
  </Teleport>
</template>

<script lang='ts'>
import { createPopperLite, flip, Instance, Placement, preventOverflow } from '@popperjs/core';
import { defaultModifiers } from '@popperjs/core/lib/popper-lite';
import { useAppEvents } from 'app/functions/use-app-events';
import { useDialog } from 'app/functions/use-dialog';
import { defineComponent, onBeforeUnmount, onUpdated, PropType, ref, SetupContext, watch } from 'vue';

export default defineComponent({
  props: {
    /**
     * @param reference - The element to anchor this context menu to
     */
    reference: {
      type: HTMLElement,
      required: true
    },
    /**
     * @param headerLabel - Label for the context menu
     */
    headerLabel: {
      type: String,
      required: true
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'bottom-end' as Placement
    },
    display: {
      type: String,
      default: 'menu'
    },
    announcement: {
      type: String,
      default: undefined
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx: SetupContext) => {
    const closeButton = ref<HTMLElement | null>(null);
    let popper: Instance | null = null;
    const announce = ref(false);

    const {
      closeDialog,
      animationOpen,
      styles,
      dialog,
      animationDurationMs
    } = useDialog(ctx);

    const originalDuration = animationDurationMs.value;

    const closeWithAnimation = async () => {
      animationDurationMs.value = originalDuration;
      closeDialog();
    };

    const closeIfAllowed = (e: Event) => {
      e.preventDefault();
      closeWithAnimation();
    };

    useAppEvents({
      'router:navigate': () => {
        closeDialog();
      }
    });

    preventOverflow.options = {
      altAxis: true,
      padding: 16
    };
    watch(() => dialog.value, () => {
      if (!dialog.value) { return; }
      popper = createPopperLite(props.reference, dialog.value, {
        placement: props.placement,
        modifiers: [ ...defaultModifiers, flip, preventOverflow ]
      });
    });

    watch(() => props.display, () => {
      closeButton.value?.focus();
    });

    watch(() => props.announcement, () => {
      if (props.announcement) {
        announce.value = true;
      }
    });

    watch(() => props.animate, () => {
      animationDurationMs.value = props.animate ? originalDuration : 0;
    }, { immediate: true });

    onBeforeUnmount(() => {
      announce.value = false;
      popper?.destroy();
    });

    onUpdated(() => {
      popper?.update();
    });

    return {
      animationOpen,
      announce,
      closeButton,
      dialog,
      styles,
      closeDialog,
      closeIfAllowed,
      closeWithAnimation
    };
  }
});
</script>

<style module>

.dialog {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  inset-inline-start: unset;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px var(--c-darkest-gray);
}

.dialog::backdrop {
  background-color: rgba(0.2, 0.2, 0.2, 0.2);
}

.content{
  padding: 1rem;
  max-width: 18rem;
}

.header-container {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto;
  margin-bottom: 0.5rem;
}

.header {
  align-items: center;
  display: flex;
}

.menu-heading {
  display: inline-block;
  font-size: var(--fs-body);
  font-weight: var(--fw-medium);
}

.dismiss {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.dismiss svg {
  margin-right: 0;
  fill: var(--c-black);
  height: .75rem;
  width: .75rem;
}

/* Animation classes */

.dialog.animation-open {
  animation: dialog-open var(--animation-duration) ease forwards;
}

.dialog {
  animation: dialog-close var(--animation-duration) ease forwards;
}

.dialog.animation-open::backdrop {
  animation: backdrop-fade-in var(--animation-duration) ease forwards;
}

.dialog::backdrop {
  animation: backdrop-fade-out var(--animation-duration) ease forwards;
}

/* Animation keyframes */

@keyframes dialog-open {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes dialog-close {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes backdrop-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes backdrop-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
