import { APP } from 'app/base/app';
import { ItemWithTitleCache } from 'app/models/item-with-title';
import { TitleMapper, TitleRecord } from 'app/models/title';
import { SentryPossession } from '../base/sentry';
import { Downloader } from '../base/updates/downloader';

export class Possession extends ItemWithTitleCache<SentryPossession, BankedPossession> {
  public cardId: number;
  public type = 'undefined';
  public titleSlug: string;
  public createTime: number;
  public expireTime: number;
  public slug: string;
  public emailAddress: string;
  public autoBorrowHold: boolean;
  public downloader: Downloader;
  public isAssigned: boolean;

  public static FILTER_FUNCTIONS = {
    filterByTitle: (s: Possession, filterValue: string, caseSensitive = false) => {
      if (filterValue && s && s.titleRecord && s.titleRecord.title) {
        const normTitle = caseSensitive ? s.titleRecord.title : s.titleRecord.title.toLowerCase();
        const normFilter = caseSensitive ? filterValue : filterValue.toLowerCase();

        return normTitle.indexOf(normFilter) >= 0;
      }

      return true;
    },
    filterByTitles: (s: Possession, filterValue: string, caseSensitive = false) => {
      if (filterValue && s && s.titleRecord && s.titleRecord.title) {
        let normTitle = caseSensitive ? s.titleRecord.title : s.titleRecord.title.toLowerCase();
        if (s.titleRecord.subtitle) { // include subtitle in this text compare
          const normSubtitle = caseSensitive ? s.titleRecord.subtitle : s.titleRecord.subtitle.toLowerCase();
          normTitle = `${normTitle} ${normSubtitle}`;
        }
        const normFilter = caseSensitive ? filterValue : filterValue.toLowerCase();

        return normTitle.indexOf(normFilter) >= 0;
      }

      return true;
    }
  };

  public static SORT_FUNCTIONS = {
    title: (a: Possession, b: Possession) => {
      const titleA = APP.titleCache.get(a.titleSlug);
      const titleB = APP.titleCache.get(b.titleSlug);

      if (!a || !titleA) { return -1; }
      if (!b || !titleB) { return 1; }

      return titleA.title.localeCompare(titleB.title);
    },
    created: (a: Possession, b: Possession) => {
      if (!a || !a.createTime) { return -1; }
      if (!b || !b.createTime) { return 1; }

      return b.createTime - a.createTime;
    },
    expires: (a: Possession, b: Possession) => {
      // Generally we want expire time to sort with the soonest expiring first
      if (!a || !a.expireTime) { return 1; }
      if (!b || !b.expireTime) { return -1; }

      return a.expireTime - b.expireTime;
    }
  };


  protected mapItemFromBank(attrs: BankedPossession): void {
    PossessionMapper.freshenFromBank(this, attrs);
  }


  protected mapItem(response: SentryPossession): void {
    PossessionMapper.freshenFromSentry(this, response);
  }


  protected mapTitle(response: SentryPossession): TitleRecord {
    return TitleMapper.mapFromThunder(response);
  }


  public coverURL(): string | undefined {
    const loan = APP.patron.loans.find({ titleSlug: this.titleSlug });

    return loan ? loan.coverURL() : APP.titleCache.get(this.titleSlug).rawCoverURL();
  }


  protected _transformRemoteAttributes(attrs) {
    const out = super._transformRemoteAttributes(attrs);
    out.cardId = parseInt(out.cardId, 10) || 0;

    return out;
  }


  protected serializeInternal(): BankedPossession {
    const out: BankedPossession = {};
    if (this.createTime) {
      out.createTime = this.createTime;
    }
    if (this.expireTime) {
      out.expireTime = this.expireTime;
    }
    if (this.isAssigned) {
      out.isAssigned = this.isAssigned;
    }

    return out;
  }


  public assignTitle(titleSlug: string, isAssigned?: boolean) {
    if (!titleSlug) { throw(new Error('Missing title for ' + this.type)); }
    this.titleSlug = titleSlug;
    this.slug = APP.library.websiteId + '-' + titleSlug;
    this.isAssigned = isAssigned;
  }
}

export class PossessionMapper {
  public static freshenFromSentry(possession: Possession, sentryPossession: SentryPossession) {
    possession.isAssigned = sentryPossession.isAssigned;
    possession.expireTime = sentryPossession.expireTime || new Date(sentryPossession.expires).getTime();
    possession.createTime = sentryPossession.createTime;
    const slug = sentryPossession.id;
    if (slug) {
      possession.assignTitle(slug, possession.isAssigned);
    }
  }


  public static freshenFromBank(possession: Possession, bankedPossession: BankedPossession) {
    if (bankedPossession.createTime) {
      possession.createTime = bankedPossession.createTime;
    }

    if (bankedPossession.expireTime) {
      possession.expireTime = bankedPossession.expireTime;
    }

    possession.isAssigned = bankedPossession.isAssigned;

    if (possession.titleSlug) {
      possession.assignTitle(possession.titleSlug, possession.isAssigned);
    }
  }
}

export interface BankedPossession {
  createTime?: number;
  expireTime?: number;
  isAssigned?: boolean;
}
