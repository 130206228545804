<template>
  <form
    :class="$style.form"
    @submit.prevent="onSubmit"
  >
    <div :class="$style.logoContainer">
      <img
        :src="logoPath"
        :class="$style.logo"
        alt="LexisNexis"
      />
    </div>
    <p
      :id="promptId"
      :class="$style.prompt"
    >
      {{ $t('libraryCode.prompt') }}
    </p>
    <FormInput
      v-model="libraryCode"
      type="text"
      :label="$t('libraryCode.label')"
      :autofocus="true"
      :required="true"
      :describedBy="promptId"
      :error="codeError ? $t(codeError) : undefined"
    />
    <FormSubmitButton :label="$t('libraryCode.submit')" />
  </form>
</template>

<script lang='ts'>
import { defineComponent, ref } from 'vue';
import { generateUUID } from 'lib/common/uuid';
import FormInput from 'app/components/FormInput.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import { resolve } from 'app/base/library-resolver';
import { Constants } from 'app/base/constants';

export type LibraryCodeEntry = {
  ghost?: string;
  code: string;
};

export default defineComponent({
  components: {
    FormInput,
    FormSubmitButton
  },
  emits: [
    'valid'
  ],
  setup: (props, ctx) => {
    const input = ref<HTMLElement | null>(null);

    const promptId = generateUUID();

    const libraryCode = ref('');
    const codeError = ref<string | null>(null);

    const inputFocus = ref(false);
    const logoPath = Constants.assetPath('images/app-logo-ln.svg');

    const onSubmit = async (event: Event) => {
      codeError.value = null;

      /* Valid code examples:
      * od:rll
      * ln:lexisdl
      * mockingbird
      * legal-aid
      */
      const codeRegex = /^(?:(?<ghost>od|ln):)?(?<code>\w+(-\w+)*)$/;
      const matches = libraryCode.value.toLowerCase().match(codeRegex);
      const { ghost, code } = matches?.groups || {};

      const resolveResult = await resolve(code);

      if (resolveResult.state === 'error') {
        codeError.value = `libraryCode.error.${resolveResult.error}`;
      } else {
        ctx.emit('valid', { ghost, code} as LibraryCodeEntry);
      }

      if (codeError.value) {
        input.value?.focus();
      }
    };

    return {
      codeError,
      inputFocus,
      libraryCode,
      logoPath,
      onSubmit,
      promptId
    };
  }
});
</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  .form {
    .welcome-panel;

    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .logo-container {
    margin: 0 auto;
  }

  .logo {
    height: auto;
  }

  .prompt {
    font-size: @fs-medium-head;
    font-weight: @fw-bold;
    border-top: 1px solid @c-dark-gray;
    padding-top: 2rem;
    text-align: center;

    @media screen and (min-width: @px-vp-wide) {
      white-space: nowrap;
    }
  }

  .input-wrapper {
    position: relative;
    background-color: @c-light-gray;
    border: 1px solid @c-light-gray;
    border-radius: @px-border-radius;
    box-sizing: border-box;
    width: 100%;
    transition: all 0.2s ease-in-out;
    max-height: 3.5rem;

    &.active {
      background-color: transparent;
      border: 1px solid @c-dark-gray;

      .library-code-label {
        top: 0.3rem;
        left: 1rem;
        font-size: @fs-mini;
        color: @c-light-black;
      }

      .library-code {
        padding: 1.7rem 1rem 0.3rem;
      }
    }

    &.focus {
      box-shadow: inset 0 0 0 3px @c-white, 0 0 0 3px @c-focus;
    }
  }

  .library-code-label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: @fs-body;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .library-code {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    font-size: @fs-med;
    cursor: pointer;
  }

  .error-message {
    color: @c-primary-red;
    font-size: @fs-mini;
    margin-top: 0.5rem;
    padding: 0 10px;
  }

  .submit {
    display: block;
    width: 100%;
    background: @c-primary-red;
    border-radius: @px-border-radius;
    border: none;
    color: @c-white;
    font-family: @ff-sans;
    font-size: @fs-med;
    font-weight: @fw-bold;
    padding: 1rem;
    text-shadow: none;
    text-transform: uppercase;
  }

  @media screen and (max-width: @px-vp-narrow) {
    .logo {
      max-width: 100%;
    }
  }
</style>
