import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeAnnotationCard = _resolveComponent("HomeAnnotationCard")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_ctx.titlesWithAnnotations.length)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(_ctx.$style.container)
      }, [
        _createElementVNode("h1", {
          class: _normalizeClass(_ctx.$style.header)
        }, _toDisplayString(_ctx.$t('home.annotations.header')), 3),
        _createElementVNode("ol", {
          class: _normalizeClass(_ctx.$style.list)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.titlesWithAnnotations, (title) => {
            return (_openBlock(), _createElementBlock("li", {
              key: title.slug,
              class: _normalizeClass(_ctx.$style.title)
            }, [
              _createVNode(_component_router_link, {
                to: { name: 'NoteDetails', params: { titleSlug: title.slug } },
                class: _normalizeClass(_ctx.$style.link)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_HomeAnnotationCard, { title: title }, null, 8, ["title"])
                ], undefined, true),
                _: 2
              }, 1032, ["to", "class"])
            ], 2))
          }), 128))
        ], 2),
        _createVNode(_component_router_link, {
          to: { name: 'Notes' },
          class: _normalizeClass(_ctx.$style.viewAll)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('home.annotations.viewAll')), 1)
          ], undefined, true),
          _: 1
        }, 8, ["class"])
      ], 2))
    : (_openBlock(), _createBlock(_component_EmptyState, {
        key: 1,
        class: _normalizeClass(_ctx.$style.emptyState),
        link: _ctx.helpLink,
        isCompact: true,
        header: _ctx.$t('home.annotations.emptystate.header'),
        content: _ctx.$t('home.annotations.emptystate.content'),
        linkLabel: _ctx.$t('home.annotations.emptystate.link')
      }, null, 8, ["class", "link", "header", "content", "linkLabel"]))
}