import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "visually-hidden" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { "data-column": "expander" }
const _hoisted_4 = ["id", "aria-label", "onClick"]
const _hoisted_5 = { "data-column": "color_id" }
const _hoisted_6 = { "data-column": "quote" }
const _hoisted_7 = {
  key: 0,
  "data-column": "note"
}
const _hoisted_8 = { "data-column": "citation" }
const _hoisted_9 = { "data-column": "release" }
const _hoisted_10 = { "data-column": "syncstamp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_HighlightColor = _resolveComponent("HighlightColor")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!

  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(_ctx.$style.table)
  }, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", {
        class: _normalizeClass(_ctx.$style.header)
      }, [
        _createElementVNode("th", null, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('annotations.display.property.expander')), 1)
        ]),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.color')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.highlight')), 1),
        (!_ctx.hideNote)
          ? (_openBlock(), _createElementBlock("th", _hoisted_2, _toDisplayString(_ctx.$t('annotations.display.property.note')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.citation')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.release')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.lastUpdated')), 1)
      ], 2)
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.snapshots, (snapshot) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: snapshot.id,
          class: _normalizeClass({
          [_ctx.$style.row]: true,
          [_ctx.$style.expanded]: _ctx.expanded.has(snapshot.id)
        })
        }, [
          _createElementVNode("td", _hoisted_3, [
            _createElementVNode("button", {
              id: `expander-${snapshot.id}`,
              type: "button",
              class: _normalizeClass(_ctx.$style.expanderButton),
              "aria-label": _ctx.$t('annotations.display.expandText'),
              onClick: () => _ctx.toggleExpanded(snapshot.id)
            }, [
              _createVNode(_component_Icon, {
                class: _normalizeClass(_ctx.$style.expander),
                name: "chevron-down"
              }, null, 8, ["class"])
            ], 10, _hoisted_4)
          ]),
          _createElementVNode("td", _hoisted_5, [
            _createVNode(_component_HighlightColor, {
              colorGroup: snapshot.color_id
            }, null, 8, ["colorGroup"])
          ]),
          _createElementVNode("td", _hoisted_6, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.$style.text)
            }, _toDisplayString(snapshot.quote), 3)
          ]),
          (!_ctx.hideNote)
            ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                (snapshot.note)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.text)
                    }, _toDisplayString(snapshot.note), 3))
                  : (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      class: _normalizeClass(_ctx.$style.noNote)
                    }, _toDisplayString(_ctx.$t('annotations.display.noNote')), 3))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("td", _hoisted_8, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.$style.text)
            }, _toDisplayString(snapshot.citation), 3)
          ]),
          _createElementVNode("td", _hoisted_9, [
            _createElementVNode("p", null, _toDisplayString(snapshot.release), 1),
            _createElementVNode("p", null, _toDisplayString(snapshot.release_date), 1)
          ]),
          _createElementVNode("td", _hoisted_10, [
            _createVNode(_component_RelativeDate, {
              timestamp: snapshot.syncstamp
            }, null, 8, ["timestamp"])
          ])
        ], 2))
      }), 128))
    ])
  ], 2))
}