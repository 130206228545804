<template>
  <button
    ref="indicatorButton"
    :class="$style.indicatorButton"
    :aria-label="downloadInfo.buttonLabel || downloadInfo.headerLabel"
    aria-haspopup="dialog"
    @click="showPopout = true"
  >
    <ProgressCircle
      v-if="downloadInfo.status === 'downloading'"
      :value="progress"
    />
    <Icon
      v-else
      :name="downloadInfo.iconLabel"
      :class="[$style.icon, 'icon-complete']"
    />
    <span v-if="!iconOnly">
      {{ downloadInfo.buttonLabel || downloadInfo.headerLabel }}
    </span>
    <span
      class="visually-hidden"
      aria-live="polite"
    >
      {{ downloadAnnouncement }}
    </span>
  </button>

  <DownloadIndicatorContent
    v-if="showPopout && indicatorButton"
    :reference="indicatorButton"
    :headerLabel="downloadInfo.headerLabel"
    :menuActionLabel="downloadInfo.action.label"
    :menuAnnouncement="menuAnnouncement"
    :confirmationMessage="downloadInfo.confirmation.message"
    :confirmationAnnouncement="downloadInfo.confirmation.announcement"
    :confirmationRequired="downloadInfo.confirmation.required"
    :actionEvent="downloadInfo.action.event"
    @download:start="startDownload"
    @download:stop="stopDownload"
    @download:settings="openSettings"
    @close="showPopout = false"
  />
</template>

<script lang="ts">
import { Downloader } from 'app/base/updates/downloader';
import DownloadIndicatorContent from 'app/components/DownloadIndicatorContent.vue';
import ProgressCircle from 'app/components/ProgressCircle.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useDownloadInfo } from 'app/functions/use-download-info';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, ref } from 'vue';

export type ActionEvent = 'download:start' | 'download:stop';
type DownloadIconName = 'dl-downloaded' | 'dl-errored' | 'dl-paused' | 'dl-queued' | 'dl-streaming' | 'export';
type DownloadStatus = 'streaming' | 'downloading' | 'paused' | 'queued' | 'downloaded' | 'failed';

type DownloadInfo = {
  status: DownloadStatus;
  iconLabel: DownloadIconName;
  buttonLabel?: string;
  headerLabel: string;
  action: {
    event: ActionEvent;
    label: string;
  };
  confirmation: {
    announcement: string;
    message: string;
    required: boolean;
  };
};


/**
 * An indicator (and label) that displays the download status of a loan
 * It also allows for some simple download actions (cancel, clear, etc.)
 * @displayName DownloadIndicator
 */
export default defineComponent({
  components: {
    DownloadIndicatorContent,
    ProgressCircle
  },
  props: {
    /**
     * The downloader to display information for.
     */
    downloader: {
      type: Downloader,
      required: true
    },
    /**
     * When true, only the icon will be rendered. No status label.
     */
    iconOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const indicatorButton = ref<HTMLButtonElement | undefined>();
    const showPopout = ref(false);

    // NOTE: We limit functionality when the download is just starting up
    const MIN_DOWNLOAD_THRESHOLD = 0.005;

    const { t } = useI18n();

    const {
      downloadSize,
      isErrored,
      isMeteredConnection,
      isPaused,
      isSetToDownload,
      progress
    } = useDownloadInfo(props.downloader);

    const startDownload = () => {
      if (isMeteredConnection.value) {
        // eslint-disable-next-line vue/no-mutating-props
        props.downloader.overrideCellular = true;
      }
      props.downloader.setAutoDownload(true);

      showPopout.value = false;
    };

    const stopDownload = () => {
      if (isMeteredConnection.value) {
        // eslint-disable-next-line vue/no-mutating-props
        props.downloader.overrideCellular = false;
      }
      props.downloader.setAutoDownload(false);

      showPopout.value = false;
    };

    const { dispatch } = useAppEvents();
    const openSettings = () => {
      dispatch('open:download:settings');

      showPopout.value = false;
    };

    const downloadInfo = computed<DownloadInfo>(() => {
      if (isErrored.value) {
        return {
          status: 'failed',
          iconLabel: 'dl-errored',
          headerLabel: t('title.downloadIndicator.status.failed'),
          action: {
            event: 'download:start',
            label: t('title.downloadIndicator.startDownloadButton', { SIZE: downloadSize.value })
          },
          confirmation: {
            announcement: t('title.downloadIndicator.announcement.start'),
            message: t('title.downloadIndicator.downloadConfirmMsg'),
            required: true
          }
        } as DownloadInfo;
      }

      if (progress.value >= 1) {
        return {
          status: 'downloaded',
          iconLabel: 'dl-downloaded',
          buttonLabel: t('title.downloadIndicator.status.downloaded', { SIZE: downloadSize.value }),
          headerLabel: t('title.downloadIndicator.status.downloadedNoSize'),
          action: {
            event: 'download:stop',
            label: t('title.downloadIndicator.deleteButton', { SIZE: downloadSize.value })
          },
          confirmation: {
            announcement: t('title.downloadIndicator.announcement.delete'),
            message: t('title.downloadIndicator.deleteConfirmMsg'),
            required: !isMeteredConnection.value
          }
        } as DownloadInfo;
      }

      if (isSetToDownload.value) {
        if (isPaused.value) {
          return {
            status: 'paused',
            iconLabel: 'dl-paused',
            headerLabel: t('title.downloadIndicator.status.paused'),
            action: {
              event: 'download:stop',
              label: t('title.downloadIndicator.cancelDownloadButton')
            },
            confirmation: {
              announcement: t('title.downloadIndicator.announcement.cancel'),
              message: t('title.downloadIndicator.cancelConfirmMsg'),
              required: isMeteredConnection.value
            }
          } as DownloadInfo;
        }

        if (progress.value <= MIN_DOWNLOAD_THRESHOLD) {
          return {
            status: 'queued',
            iconLabel: 'dl-queued',
            headerLabel: t('title.downloadIndicator.status.queued'),
            action: {
              event: 'download:stop',
              label: t('title.downloadIndicator.cancelDownloadButton')
            },
            confirmation:{
              announcement: t('title.downloadIndicator.announcement.cancel'),
              message: t('title.downloadIndicator.cancelConfirmMsg'),
              required: isMeteredConnection.value
            }
          } as DownloadInfo;
        }

        return {
            status: 'downloading',
            iconLabel: 'export',
            headerLabel: t('title.downloadIndicator.status.downloading'),
            action: {
              event: 'download:stop',
              label: t('title.downloadIndicator.cancelDownloadButton')
            },
            confirmation: {
              announcement: t('title.downloadIndicator.announcement.cancel'),
              message: t('title.downloadIndicator.cancelConfirmMsg'),
              required: isMeteredConnection.value
            }
        } as DownloadInfo;
      }

      return {
        status: 'streaming',
        iconLabel: 'dl-streaming',
        headerLabel: t('title.downloadIndicator.status.streaming'),
        action: {
          event: 'download:start',
          label: downloadSize.value ? t('title.downloadIndicator.startDownloadButton',{ SIZE: downloadSize.value })
            : t('title.downloadIndicator.startDownloadButtonNoSize')
        },
        confirmation: {
          announcement: t('title.downloadIndicator.announcement.start'),
          message: t('title.downloadIndicator.downloadConfirmMsg'),
          required: isMeteredConnection.value
        }
      } as DownloadInfo;
    });

    const downloadAnnouncement = computed(() => {
      const fullTitle = (`${props.downloader.loan.titleRecord.title} ${props.downloader.loan.titleRecord.subtitle || ''}`).trim();

      return t(`title.downloadIndicator.announcement.${downloadInfo.value.status}`, { title: fullTitle });
    });

    const menuAnnouncement = computed(() => t('title.downloadIndicator.announcement.menu', { status: downloadInfo.value.headerLabel }));

    return {
      downloadAnnouncement,
      downloadInfo,
      indicatorButton,
      menuAnnouncement,
      progress,
      showPopout,
      openSettings,
      startDownload,
      stopDownload
    };
  }
});
</script>

<style module>
.indicator-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke: var(--c-secondary-gray);
}
</style>
