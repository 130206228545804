<template>
  <div :class="$style.container">
    <section>
      <h2
        ref="listHeader"
        :tabindex="-1"
        class="visually-hidden"
      >
        {{ $t('annotationCopying.manageCopying.listHeader') }}
      </h2>
      <ol v-if="state.status === 'loaded' && state.jobs.length">
        <li
          v-for="job in state.jobs"
          :key="job.id"
          :class="$style.listItem"
        >
          <CopyJobCard
            :job="job"
            @update="(val) => update(job.id, val)"
          />
        </li>
      </ol>

      <EmptyState
        v-else-if="state.status === 'loaded'"
        :header="$t('annotationCopying.manageCopying.emptyState.header')"
        :content="$t('annotationCopying.manageCopying.emptyState.content')"
        :linkLabel="$t('annotationCopying.manageCopying.emptyState.link')"
        :link="helpLink"
        :isCompact="true"
        :class="$style.empty"
      />

      <Icon
        v-else-if="state.status === 'loading'"
        name="spinner"
        :class="$style.loading"
        :aria-label="$t('general.loading')"
      />

      <p
        v-else
        :class="$style.error"
      >
        {{ $t('general.error') }}
      </p>
    </section>
  </div>
</template>

<script lang="ts">
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import Icon from 'app/components/Icon.vue';
import CopyJobCard from 'app/components/cards/CopyJobCard.vue';
import { CopyUpdateType, useAnnotationCopying } from 'app/functions/use-annotation-copying';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ManageCopyingContent',
  components: {
    CopyJobCard,
    EmptyState,
    Icon
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    state: {
      type: Object as PropType<CopyJobsState>,
      required: true
    }
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.COPYING;
    const listHeader = ref<HTMLElement | null>(null);

    const { updateCopyJob } = useAnnotationCopying();

    const update = (id: number, updateType: CopyUpdateType) => {
      // Shift focus to visually hidden header
      listHeader.value?.focus();

      updateCopyJob(id, updateType);
    };

    return {
      helpLink,
      listHeader,
      update
    };
  }
});
</script>

<style module>
.container {
  margin: 1rem;
}

.list-item:not(:last-child) {
  padding-bottom: 1rem;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 2rem auto;
}

.error,
.empty {
  margin: 1rem;
  text-align: center;
}
</style>
