<template>
  <div
    :class="$style.container"
  >
    <h2
      :class="$style.header"
    >
      <Icon name="series" />

      {{ $t('title.seriesLink.header') }}
    </h2>
    <router-link
      :to="{ name: 'SetDetails', params: { seriesId: `${series.id}` } }"
      :class="$style.link"
      :aria-label="$t('title.seriesLink.label', { name: series.name })"
    >
      <SeriesCover
        :series="series"
        :width="seriesCoverSizes.titleDetails"
      />

      <div>
        {{ series.name }}

        <div>
          <span :class="$style.volumeLabel">
            {{ $t('title.seriesLink.volumeLabel') }}
          </span>

          <span :class="$style.volumeCount">
            {{ series.items.length }}
          </span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script lang='ts'>
import { seriesCoverSizes } from 'app/base/constants';
import SeriesCover from 'app/components/SeriesCover.vue';
import { Series } from 'app/models/series';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsSetLink',
  components: {
    SeriesCover
  },
  props: {
    series: {
      type: Series,
      required: true
    }
  },
  setup: () => {
    return {
      seriesCoverSizes
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  display: grid;
  grid-row-gap: 1rem;
  max-width: max-content;
}

.header {
  display: flex;
  align-items: center;
  color: @c-light-black;
  font-weight: @fw-medium;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    stroke: currentColor;
    margin-right: 0.5rem;
  }
}

.link {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  grid-template-columns: 80px;
}

.volume-label {
  composes: metadata-label from global;
  color: @c-light-black;
  margin-right: 0.5rem;
}

.volume-count {
  font-size: @fs-metadata;
}

// ## TABLET LAYOUT

@media screen and (max-width: @px-vp-very-wide) {
  .container {
    max-width: none;
  }

  .container,
  .header {
    justify-content: center;
  }
}
</style>
