<template>
  <section
    :class="{
      [$style.reviewSection]: true,
      [$style.expanded]: expanded
    }"
  >
    <component
      :is="expandable ? 'button' : 'div'"
      :id="`header-${uuid}`"
      :class="{
        [$style.reviewSectionHeader]: true,
        [$style.expandable]: expandable
      }"
      @click="toggleExpansion"
    >
      <span :class="$style.icon">
        <slot name="icon"></slot>
      </span>
      <span :class="$style.message">
        {{ message }}
      </span>
      <span :class="$style.description">
        {{ description }}
      </span>
      <Icon
        v-if="expandable"
        :class="$style.chevron"
        name="chevron-down"
      />
    </component>
    <Collapsible
      :class="$style.reviewSectionPanel"
      :expanded="expanded"
      :panelId="`panel-${uuid}`"
      :headerId="`header-${uuid}`"
    >
      <div :class="$style.reviewSectionPanelContent">
        <ul
          v-if="mobile"
          :class="$style.cardList"
        >
          <li
            v-for="snapshot in snapshots"
            :key="snapshot.uuid"
          >
            <CopyAnnotationSnapshotCard
              :snapshot="snapshot"
              :hideNote="hideNote"
            />
          </li>
        </ul>
        <CopyAnnotationSnapshotTable
          v-else
          :snapshots="snapshots"
          :hideNote="hideNote"
        />
      </div>
    </Collapsible>
  </section>
</template>

<script lang="ts">
import { PostishCopyAnnotationSnapshot } from 'app/base/postish';
import Collapsible from 'app/components/Collapsible.vue';
import CopyAnnotationSnapshotCard from 'app/components/CopyAnnotationSnapshotCard.vue';
import CopyAnnotationSnapshotTable from 'app/components/CopyAnnotationSnapshotTable.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { generateUUID } from 'lib/common';
import { PropType, computed, defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    Collapsible,
    CopyAnnotationSnapshotCard,
    CopyAnnotationSnapshotTable
  },
  props: {
    message: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    snapshots: {
      type: Array as PropType<PostishCopyAnnotationSnapshot[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const uuid = generateUUID();

    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.VeryWide);

    const expandable = computed(() => props.snapshots.length > 0);
    const expanded = ref(false);
    const toggleExpansion = () => {
      if (!expandable.value) {
        return;
      }

      expanded.value = !expanded.value;

      setTimeout(() => {
        // wait until panel is expanded/collapsed, then scroll it into view
        const expanderButton = document.getElementById(`header-${uuid}`);
        expanderButton?.scrollIntoView({ behavior: 'smooth'});
      }, 200);
    };

    return {
      expandable,
      expanded,
      mobile,
      toggleExpansion,
      uuid
    };
  }
});
</script>

<style module>
.review-section {
  border: 1px solid var(--c-card-border);
  border-radius: var(--form-border-radius);
  position: relative;
}

.review-section-header {
  align-items: center;
  border-block-end: 1px solid var(--c-card-border);
  box-sizing: border-box;
  column-gap: 1rem;
  display: grid;
  grid-template-areas:
    'icon message'
    'icon description';
  grid-template-columns: auto 1fr;
  justify-items: left;
  padding: 0.5rem;
  width: 100%;
  scroll-margin: 1rem;
}

.review-section-header.expandable {
  grid-template-areas:
    'icon message chevron'
    'icon description chevron';
  grid-template-columns: auto 1fr auto;
}

.review-section-header .message {
  font-weight: var(--fw-bold);
  grid-area: message;
}

.review-section-header .description {
  grid-area: description;

  text-align: left;
}

.review-section-header .chevron {
  grid-area: chevron;
  height: 2rem;
  /* Match Collapsible transition time */
  transition: transform 200ms ease;
  width: 2rem;
}

.review-section-panel {
  background: none;
}

.review-section-panel-content {
  padding: 0.5rem;
}

.review-section.expanded .review-section-header .chevron {
  transform: rotate(-180deg);
}

.review-section.expanded .review-section-panel {
  /* Leave room for the focus outline */
  margin-block-start: 5px;
}

.card-list {
  appearance: none;

  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.icon {
  grid-area: icon;

  width: 2rem;
  height: 2rem;
}
</style>
