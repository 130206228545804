<template>
  <transition
    name="fade"
  >
    <button
      v-show="show"
      :aria-label="$t('general.backToTop')"
      :class="[$style.scroller, fadeScroller ? $style.fade : '']"
      @click="scrollIntoView"
    >
      <Icon name="chevron-up" />
    </button>
  </transition>
</template>

<script lang='ts'>
import { defineComponent, ref, watchEffect } from 'vue';

export default defineComponent({
  name: 'BackToTopButton',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    scrollTo: {
      type: HTMLElement,
      default: undefined as HTMLElement | undefined
    },
    focusOn: {
      type: String,
      required: true
    }
  },
  emits: [
    'show'
  ],
  setup: (props, ctx) => {
    const fadeScroller = ref(false);

    const observer = new IntersectionObserver(
      (entries) => {
        const shouldShow = !entries.some((entry) => entry.intersectionRatio > 0 || entry.isIntersecting ||
          (entry.rootBounds && (entry.rootBounds.height < entry.boundingClientRect.top))
        );

        ctx.emit('show', shouldShow);

        if (shouldShow) {
          fadeScroller.value = false;
          setTimeout(() => fadeScroller.value = true, 1000);
        }
      },
      { root: null, threshold: 0 }
    );

    watchEffect(() => {
      if (props.scrollTo) {
        observer.observe(props.scrollTo);
      } else {
        observer.disconnect();
      }
    });

    const scrollIntoView = () => {
      props.scrollTo?.scrollIntoView({ behavior: 'smooth' });

      setFocus();
    };

    const setFocus = () => {
      const toFocus = document.querySelector(props.focusOn) as HTMLElement;
      toFocus?.focus({ preventScroll: true }); //preventScroll so it does not interfere with the smooth scrolling already happening
    };

    return {
      fadeScroller,
      scrollIntoView
    };
  }
});
</script>

<style lang='less' module>
.scroller {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  border-radius: var(--form-border-radius);
  z-index: 501;
  background: var(--c-light-gray);
  padding: 0.5rem 1rem;
  line-height: 0;
  box-shadow: 0px 0px 6px var(--c-shadow);
  transition: opacity 300ms;

  &.fade {
    opacity: 0.75;
  }

  &:hover, &:focus {
    opacity: 1;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--c-light-black);
  }
}
</style>
