import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleCard = _resolveComponent("TitleCard")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.volumes)
    ? (_openBlock(), _createElementBlock("ol", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.volumes, (volume) => {
          return (_openBlock(), _createElementBlock("li", {
            key: volume.slug,
            class: _normalizeClass(_ctx.$style.volume)
          }, [
            _createVNode(_component_TitleCard, {
              title: volume,
              borderless: true
            }, null, 8, ["title"])
          ], 2))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("p", {
        key: 1,
        class: _normalizeClass(_ctx.$style.loading)
      }, [
        _createVNode(_component_Icon, {
          name: "spinner",
          "aria-label": _ctx.$t('general.loading')
        }, null, 8, ["aria-label"])
      ], 2))
}