import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Possession } from 'app/models/possession';
import { TitleRecord } from 'app/models/title';
import { BifocalParts, BifocalViewBase } from 'app/views/open/bifocal-view-base';
import styles from 'app/views/open/bifocal-view.module.less';
import { DervishResponse } from '../../base/interfaces';

const CLEAR_URL = 'about:blank';
export class BifocalView extends BifocalViewBase {
  public isCleared: boolean;


  public isOpen(titleSlug: string, possession: Possession): boolean {
    return (!this.isCleared && super.isOpen(titleSlug, possession));
  }


  /**
   * Load the given title in bifocal.  Does not automatically reveal the view.
   * @param title
   * @param possession
   * @param dervishData
   * @param anchorID Optional anchor ID. When supplied, anchor will be included in the request
   */
  public open(title: TitleRecord, possession: Possession, dervishData: DervishResponse, anchorID?: string): void {
    this.clear();
    this._title = title;
    this._possession = possession;
    this._urls = dervishData.urls;

    const tData = {
      'title-id': title.slug,
      'share-path': `${APP.client.info.url}/library/${APP.library.baseKey}/open/${title.slug}`
    };

    const baseUrl = this._urls.web + (dervishData.message ? '?' + dervishData.message : '');
    const params = {
      a: anchorID,
      t: JSON.stringify(tData)
    };
    const url = C.parameterizeURL(baseUrl, params);

    this._openURL(url);
  }


  public reload(): void {
    this._openURL(this._urls?.web);
  }


  public reveal(): void {
    // Show the view. In this case the semaphore flag does it.
  }


  public conceal(): void {
    // Hide the view. In this case the semaphore flag does it.
  }


  public transmit(object: {}): void {
    const msg = {
      name: APP.shell.bridgeMessageEventType('receive'),
      dest: 'bifocal',
      detail: object
    };
    if (this._parts.frame) {
      this._parts.frame.contentWindow.postMessage(JSON.stringify(msg), '*');
    }
  }


  public clear(): void {
    super.clear();
    if (this._parts && this._parts.frame) {
      this._openURL(CLEAR_URL);
    }
  }


  public getUrl(): string {
    return this._urls.web;
  }


  protected _layoutPartial(parts: BifocalParts): void {
    parts.box.classList.add(...styles.bifocalView.split(' '));
  }


  protected _listenPartial(parts: BifocalParts): void {
    APP.events.on('title:switch', (evt) => this._onTitleSwitch(evt));
    APP.events.on('msg:bifocal:client:query', (evt) => this._sendData(evt.m));
  }


  protected _openURL(url: string): void {
    this.isCleared = (url === CLEAR_URL);
    if (!this._parts.frame) {
      this._parts.frame = C.iframe({
        parentNode: this._parts.box,
        src: url,
        width: '100%',
        height: '100%'
      });
    } else {
      this._parts.frame.contentWindow.location.replace(url);
    }
  }


  protected _onTitleSwitch(evt): void {
    if (!evt.m.codex) {
      this.clear();
    }
  }
}
