import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withKeys as _withKeys, mergeProps as _mergeProps, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  class: "visually-hidden",
  "aria-live": "polite"
}
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#popouts" }, [
    _createElementVNode("dialog", _mergeProps(_ctx.$attrs, {
      ref: "dialog",
      class: [_ctx.$style.dialog, { [_ctx.$style.animationOpen]: _ctx.animationOpen }],
      style: _ctx.styles,
      "aria-label": _ctx.headerLabel,
      onCancel: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeWithAnimation && _ctx.closeWithAnimation(...args))),
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeWithAnimation && _ctx.closeWithAnimation(...args))),
      onKeydown: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args)), ["esc"]))
    }), [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.content),
        onClick: _cache[1] || (_cache[1] = (e) => e.stopPropagation())
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.announce ? _ctx.announcement : undefined), 1),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.headerContainer)
        }, [
          _createElementVNode("header", {
            class: _normalizeClass(_ctx.$style.header)
          }, [
            _createElementVNode("h2", {
              class: _normalizeClass(_ctx.$style.menuHeading)
            }, _toDisplayString(_ctx.headerLabel), 3)
          ], 2),
          _createElementVNode("button", {
            ref: "closeButton",
            class: _normalizeClass(_ctx.$style.dismiss),
            "aria-label": _ctx.$t('general.close'),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeWithAnimation && _ctx.closeWithAnimation(...args)))
          }, [
            _createVNode(_component_Icon, { name: "dismiss" })
          ], 10, _hoisted_3)
        ], 2),
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 16, _hoisted_1)
  ]))
}