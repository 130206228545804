import { EventId, EventMap } from 'app/base/event-map';
import Events from 'app/events/events';
import { useLibrary } from 'app/functions/use-library';
import type { LibraryCard } from 'app/models/library-card';
import { computed, Ref, ref } from 'vue';

const library = useLibrary();

const cards = buildPatronArray('card');

const card = computed(() => {
  return cards.value.find((c) => {
    return c.library.websiteId === library.value?.websiteId;
  }) as Readonly<LibraryCard> | undefined;
});

const Patron = {
  annotations: buildPatronArray('annotation'),
  card,
  exportQueue: buildPatronArray('export-queue'),
  holds: buildPatronArray('hold'),
  loans: buildPatronArray('loan'),
  recentlyRead: buildPatronArray('recently-read'),
  searchHistory: buildPatronArray('search-history'),
  shared: buildPatronArray('shared-titles'),
  tags: buildPatronArray('tag')
} as const;

export function usePatron() {
  return Patron;
}


function buildPatronArray<K extends PatronEventPrefixes<EventId>>(eventPrefix: K) {
  const array = ref<PatronArrayType<K>[]>([]);
  const update = updateFunction(array as Ref<PatronArrayType<K>[]>);
  Events.on(`${eventPrefix}:load:all` as const, update);
  Events.on(`${eventPrefix}:update:all` as const, update);

  return array as Readonly<Ref<readonly PatronArrayType<K>[]>>;
}


function updateFunction<T>(items: Ref<T[]>) {
  return ({ m }: { m: { all: T[] } }) => {
    items.value = m.all.slice();
  };
}


type PatronEventPrefixes<S extends string> =
  S extends `${infer P}:load:all`
    ? P
    : never;

type PatronArrayType<K extends PatronEventPrefixes<EventId>> =
  EventMap[`${K}:load:all`]['all'][0];
