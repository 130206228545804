import { Events, GlobalEventCallback } from './src/events';
import { TapHandler } from './src/handler';
import { Quirks } from '../quirkbase/quirkbase';
import { LegacyOnTap } from './src/legacy-ontap';
import { TapOptions } from './src/tap-interfaces';

export class Gala<T> extends Events<T> {
  public onTap: (callback: GlobalEventCallback<'click'>, element: HTMLElement | SVGElement, options?: TapOptions) => TapHandler
    = Gala._setOnTap();


  private static _setOnTap(): (callback: GlobalEventCallback<'click'>, element: HTMLElement | SVGElement, options?: TapOptions) => TapHandler {
    return Quirks.has('legacy-ontap') ? LegacyOnTap.onTap : Events.GLOBAL_EVENTS.onTap;
  }
}
