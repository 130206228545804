<template>
  <ContextMenu
    v-bind="$attrs"
    :reference="reference"
    :options="contextMenuOptions"
    :headerLabel="$t('exportQueue.contextMenu.header')"
    :subHeaderLabel="showCount ? $t('annotations.selectedCount', { COUNT: annotations.length }) : undefined"
    @close="$emit('close')"
  />
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { ClipboardHelper } from 'app/base/clipboard';
import { C } from 'app/base/common';
import ContextMenu, { ContextMenuOption } from 'app/components/contextMenus/ContextMenu.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { useI18n } from 'app/functions/use-i18n';
import { RouteName } from 'app/router/constants';
import { Quirks } from 'lib/quirkbase/quirkbase';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    ContextMenu
  },
  props: {
    reference: {
      type: Object as PropType<HTMLElement>,
      required: true
    },
    annotations: {
      type: Object as PropType<SelectableAnnotation[]>,
      required: true
    },
    exportOptions: {
      type: Boolean,
      required: false,
      default: false
    },
    showCount: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close',
    'dispatch:toast'
  ],
  setup: (props, ctx) => {
    const { dispatch } = useAppEvents();
    const { t } = useI18n();
    const actionToast = {
      status: 'error',
      message: '',
      action: false
    };

    const annotationIds = computed(() => props.annotations.map((a) => a.uuid));

    const exportAnnotation = async (type: 'pdf' | 'csv' | 'drive') => {
      const dateInfo = C.timeUnitsToNumericalDate(C.timestampToUnits(Date.now())).replace(/\//g, '_');
      const filename  = `Annotation_Export_${dateInfo}`;
      let success = false;

      try {
        if (Quirks.ask('safari')) {
          success = !!APP.shell.openInNewViewAsync(() => APP.services.postish.getExportUrl(annotationIds.value, type, filename));
        } else {
          const url = await APP.services.postish.getExportUrl(annotationIds.value, type, filename);
          success = !!APP.shell.openInNewView(url);
        }
      } catch (ex) {
        console.error('[EXPORT] Error fetching export url', ex);
      }

      if (success) {
        actionToast.status = 'success';
        actionToast.message = t('exportQueue.contextMenu.export.success');
        actionToast.action = true;
        APP.tracking.log('notes_exported', {
          note_ids: annotationIds.value,
          export_name: filename,
          export_type: type
        });
      } else {
        actionToast.status = 'error';
        actionToast.message = t('exportQueue.contextMenu.export.failure');
        actionToast.action = false;
      }

      ctx.emit('dispatch:toast', actionToast);
    };

    const exportPDF = () => {
      exportAnnotation('pdf');
    };

    const exportCSV = () => {
      exportAnnotation('csv');
    };

    const exportDrive = () => {
      exportAnnotation('drive');
    };

    const copyAnnotation = async () => {
      const toCopyString = (annotation: SelectableAnnotation) => [
        annotation.quote,
        annotation.citation,
        annotation.note
      ].filter((i) => !!i).join('\n\n');

      const copyString = props.annotations.map(toCopyString).join('\n\n\n-------------------------------\n\n\n');

      const success = APP.flags.get('force_error_toast') ? false : await ClipboardHelper.copyToClipboard(copyString);

      if (success) {
        actionToast.status = 'success';
        actionToast.message = t('exportQueue.contextMenu.copy.success');
        actionToast.action = true;
      } else {
        actionToast.status = 'error';
        actionToast.message = t('exportQueue.contextMenu.copy.failure');
        actionToast.action = false;
      }
      ctx.emit('dispatch:toast', actionToast);
    };

    const removeFromQueue = async () => {
      dispatch('export-queue:remove', props.annotations.map((a) => a.uuid));
      actionToast.status = 'success';
      actionToast.message = t('exportQueue.contextMenu.remove.success', { N: props.annotations.length });
      actionToast.action = false;
      ctx.emit('dispatch:toast', actionToast);
    };

    const contextMenuOptions = computed<ContextMenuOption[]>(() => {
      const opts = [
        {
          label: t('exportQueue.contextMenu.export.drive'),
          iconName: 'google-drive',
          handler: exportDrive
        },
        {
          label: t('exportQueue.contextMenu.export.pdf'),
          iconName: 'file-pdf',
          handler: exportPDF
        },
        {
          label: t('exportQueue.contextMenu.export.csv'),
          iconName: 'csv',
          handler: exportCSV
        },
        {
          label: t('exportQueue.contextMenu.copy.label'),
          iconName: 'copy-to-clipboard',
          handler: copyAnnotation as (() => void) | (() => Promise<void>)
        },
        {
          label: t('exportQueue.contextMenu.annotations.label'),
          iconName: 'nav-notes',
          location: {
            name: RouteName.NoteDetails,
            params: { titleSlug: props.annotations[0]?.titleSlug }
          }
        },
        {
          label: t('exportQueue.contextMenu.remove.label'),
          iconName: 'dismiss',
          handler: removeFromQueue
        }
      ] as ContextMenuOption[];

      if (props.exportOptions) {
        opts.splice(4, 1); //remove annotation details link
      } else {
        opts.splice(0, 3); //remove export actions
      }

      return opts;
    });

    return {
      contextMenuOptions
    };
  }
});
</script>

<style lang="less" module>

</style>
