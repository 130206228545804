import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_openBlock(), _createBlock(_component_ContextMenu, {
    ref: "contextMenu",
    reference: _ctx.reference,
    headerLabel: _ctx.$t('title.contextMenu.header'),
    options: _ctx.menuOptions,
    onClose: _ctx.closeContextMenu
  }, null, 8, ["reference", "headerLabel", "options", "onClose"]))
}