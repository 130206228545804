import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { VersionMonitor } from 'app/base/updates/version-monitor';
import { DataEvent } from 'lib/gala/src/events';
import { Loan } from '../../models/loan';
import { Delta, Network } from '../network';
import { Roster, RosterDocument } from './roster';
import { RosterApp } from './roster-app';
import { RosterCovers } from './roster-covers';
import { RosterDervish } from './roster-dervish';
import { Version } from './version';

export interface AutoDownloadRule {
  key: 'everything' | 'byte-limited' | 'nothing';
  limit?: number;
}

export type DownloadQueueRule = 'go' | 'wifi';
type PauseReason = 'wifi' | 'bifocal' | 'offline';

export class UpdateManager {
  public downloadQueueRuleAt?: number;
  public downloadQueueRule?: DownloadQueueRule;
  public autoDownloadRuleAt?: number;
  public autoDownloadRule?: AutoDownloadRule;
  public updateHorizon: number;
  public pauseReasons: Set<PauseReason>;
  public rosters: Dictionary<Roster>;
  protected _targetAppVersion: Version | undefined;
  private _auditTimer = -1;
  private _auditPollingTimer = -1;
  private _auditing = false;
  private _paused: boolean;
  private _auto: boolean;
  private readonly _versionMonitor = new VersionMonitor();

  public static AUTO_DOWNLOAD_RULES: AutoDownloadRule[] = [
    { key: 'everything' },
    { key: 'byte-limited', limit: 20},
    { key: 'nothing' }
  ];

  public static AUTO_DOWNLOAD_DEFAULT = UpdateManager.AUTO_DOWNLOAD_RULES[0];

  public static AUTO_DOWNLOAD_MEGABYTE_LIMITS = [10, 20, 50, 100, 200, 300];

  public static DOWNLOAD_QUEUE_RULES: DownloadQueueRule[] = ['go', 'wifi'];
  public static DOWNLOAD_QUEUE_DEFAULT: DownloadQueueRule = 'wifi';
  public static BANK_KEY_AUTO_DOWNLOAD = 'update-manager.auto-download';
  public static BANK_KEY_DOWNLOAD_QUEUE = 'update-manager.download-queue';
  public static BANK_KEY_UPDATE_HORIZON = 'update-manager.update-horizon';
  public static UPDATE_DELAY_MS = 200;


  constructor() {
    this.rosters = {};
    this._paused = false;
    this._auto = false;
    this.pauseReasons = new Set();
    APP.events.on('msg:roster:audit', (evt) => this._performAudit(evt.m.rosters));
    APP.events.on('network:info', (evt) => this._onNetworkInfo(evt.m));
    APP.events.on('patron:sync:complete', () => this._attemptAudit());
    APP.events.on('app:update:available', (evt) => this._onUpdateAvailable(evt));
    APP.events.on('app:update:simulate', () => this._onAppUpdateSimulate());
    APP.events.on('bifocal:downloads:pause', () => this._onBifocalDownloadsPause());
    APP.events.on('bifocal:downloads:resume', () => this._onBifocalDownloadsResume());
    this.updateHorizon = APP.bank.get(UpdateManager.BANK_KEY_UPDATE_HORIZON) || 0;
    this._loadAutoDownloadRule();
    this._loadDownloadQueueRule();
    this._deferAudit(0);
    this._versionMonitor.start();
  }

  public get paused() {
    return this._paused;
  }

  public set paused(val) {
    this._paused = val;

    APP.events.dispatch('downloads:paused', { paused: val });
  }

  public get auto() {
    return this._auto;
  }

  public set auto(val) {
    this._auto = val;
  }


  public roster(id: string): Roster {
    const roster = this.rosters[id];
    if (roster) {
      return roster;
    }
    switch (id) {
      case 'elrond-js':
        this.rosters[id] = new RosterApp();
        break;
      case 'shelf-covers':
        this.rosters[id] = new RosterCovers();
        break;
      default:
        this.rosters[id] = new RosterDervish();
        break;
    }

    return this.rosters[id];
  }


  public pause(reason: PauseReason): void {
    if (!this.pauseReasons.size) {
      setTimeout(() => {
        APP.shell.transmit({ name: 'roster:pause:role', role: 'content' });
        this.paused = true;
      });
    }

    this.pauseReasons.add(reason);
  }


  public resume(reason: PauseReason, forceCellular?: boolean): void {
    this.pauseReasons.delete(reason);

    if (this.pauseReasons.size) { return; }

    setTimeout(() => {
      APP.shell.transmit({
        name: 'roster:resume:role',
        role: 'content',
        cellular: this.downloadQueueRule === 'go' || !!forceCellular
      });
    });

    this.paused = false;
  }


  public autoDownloadRules(): AutoDownloadRule[] {
    const rules: AutoDownloadRule[] = [];
    C.each(
      UpdateManager.AUTO_DOWNLOAD_RULES,
      (rule) => {
        let autoDownloadRule = rule;
        if (this.autoDownloadRule && this.autoDownloadRule.key === rule.key) {
          autoDownloadRule = this.autoDownloadRule;
        }
        rules.push(autoDownloadRule);
      }
    );

    return rules;
  }


  public setAutoDownloadRule(rule: AutoDownloadRule): void {
    if (this.autoDownloadRule) {
      this.autoDownloadRule.key = rule.key;
      this.autoDownloadRule.limit = rule.limit;
    } else {
      this.autoDownloadRule = rule;
    }
    this.autoDownloadRuleAt = C.epochMilliseconds();
    APP.bank.set(UpdateManager.BANK_KEY_AUTO_DOWNLOAD, {
      rule: this.autoDownloadRule,
      at: this.autoDownloadRuleAt
    });
    APP.events.dispatch('updates:set:autodownload', { rule: rule });
    console.log('[UPDATE-MGR] AutoDownloads updated to:', this.autoDownloadRule);
  }


  public autoDownloadForLoan(loan: Loan): boolean {
    // Don't queue titles if we're on cellular
    if (APP.network.metered
      && this.downloadQueueRule === 'wifi'
      && APP.network.connection === 'cellular') {
      return false;
    }

    const prefs = APP.patron.cardPreferences();
    const title = APP.titleCache.get(loan.titleSlug);

    if (
      title.mediaType === 'book' &&
      prefs.readingDevice !== 'here'
    ) {
      return false;
    }
    if (
      title.mediaType === 'audiobook' &&
      (prefs.listeningDevice !== 'here' ||
      this._auto)
    ) {
      return false;
    }
    if (this.autoDownloadRule && this.autoDownloadRule.key === 'nothing') {
      return false;
    }

    if (this.autoDownloadRule && this.autoDownloadRule.key === 'byte-limited') {
      const bytes = this.autoDownloadRule.limit! * 1024 * 1024;

      return bytes >= loan.downloader.totalBytes;
    }

    return true;
  }


  public setDownloadQueueRule(rule: DownloadQueueRule): void {
    this.downloadQueueRule = rule;
    this.downloadQueueRuleAt = C.epochMilliseconds();
    APP.bank.set(UpdateManager.BANK_KEY_DOWNLOAD_QUEUE, {
      rule: this.downloadQueueRule,
      at: this.downloadQueueRuleAt
    });
    this._applyDownloadQueueRule();
    APP.events.dispatch('updates:set:downloadqueue', { rule: rule });
  }


  public setUpdateHorizon(): void {
    this.updateHorizon = C.epochMilliseconds() - 1000;
    APP.bank.set(UpdateManager.BANK_KEY_UPDATE_HORIZON, this.updateHorizon);
  }


  public recheckDownloaders(): void {
    this.setUpdateHorizon();
    this._deferAudit(3000);
  }


  public wipeUnownedRosters(): void {
    const ownedRosterIds = ['elrond-js', 'shelf-covers'];
    C.each(
      APP.patron.loans.all,
      (loan) => {
        ownedRosterIds.push.apply(ownedRosterIds, loan.downloader.rosterIds);
      }
    );
    const unownedRosterIds: string[] = [];
    C.each(
      this.rosters,
      (id, roster) => {
        if (ownedRosterIds.indexOf(id) < 0) {
          unownedRosterIds.push(id);
          roster.wipe();
        }
      }
    );
    C.each(
      unownedRosterIds,
      (id) => {
        delete this.rosters[id];
      }
    );
  }


  protected _loadAutoDownloadRule(): void {
    const pref = APP.bank.get(UpdateManager.BANK_KEY_AUTO_DOWNLOAD);
    if (pref && pref.rule) {
      const rule = pref.rule as AutoDownloadRule;
      const validRules = this.autoDownloadRules();
      for (let i = 0, ii = validRules.length; i < ii; ++i) {
        if (rule.key === validRules[i].key) {
          this.autoDownloadRule = rule;
          this.autoDownloadRuleAt = pref.at || C.epochMilliseconds();
          break;
        }
      }
    }
    if (!this.autoDownloadRule) {
      this.autoDownloadRule = UpdateManager.AUTO_DOWNLOAD_DEFAULT;
    }

    console.log('[UPDATE-MGR] AutoDownloads set to:', this.autoDownloadRule);
  }


  protected _loadDownloadQueueRule(): void {
    const pref = APP.bank.get(UpdateManager.BANK_KEY_DOWNLOAD_QUEUE);
    if (pref && pref.rule) {
      const validRules = UpdateManager.DOWNLOAD_QUEUE_RULES;
      this.downloadQueueRule = validRules.indexOf(pref.rule) > -1 ? pref.rule : undefined;
      if (this.downloadQueueRule) {
        this.downloadQueueRuleAt = pref.at || C.epochMilliseconds();
      }
    }
    if (!this.downloadQueueRule) {
      this.downloadQueueRule = UpdateManager.DOWNLOAD_QUEUE_DEFAULT;
    }
    this._applyDownloadQueueRule();
  }


  protected _applyDownloadQueueRule(): void {
    if (this.downloadQueueRule === 'wifi') {
      APP.network.check();
    } else if (this.downloadQueueRule === 'go') {
      this.resume('wifi');
    }
  }


  protected _onNetworkInfo(evt: { network: Network; delta: Delta }): void {
    const network = evt.network;

    if (network.reachable && this.pauseReasons.has('offline')) {
      this.resume('offline');
    } else if (!network.reachable) {
      this.pause('offline');
    }

    if (this.downloadQueueRule === 'wifi') {
      // We only check CELLULAR connections for metering, because some
      // VPNs are "metered" but we should not prevent downloads on those:
      if (network.connection === 'cellular' && network.metered) {
        this.pause('wifi');
      } else {
        this.resume('wifi');
      }
    }
  }


  protected _attemptAudit(): void {
    clearTimeout(this._auditTimer);
    if (!this._auditing) {
      APP.shell.transmit('roster:audit');
      this._auditing = true;
    }
  }


  protected _deferAudit(delay: number): void {
    clearTimeout(this._auditTimer);
    this._auditTimer = window.setTimeout(this._attemptAudit.bind(this), delay || 0);
  }


  protected _performAudit(rosterDocuments: RosterDocument[]): void {
    const docLookup: Dictionary<RosterDocument> = {};
    C.each(
      rosterDocuments,
      (doc) => {
        if (!docLookup[doc.id] || doc.health === 'valid') {
          docLookup[doc.id] = doc;
        }
      }
    );
    const findOrMakeRoster = (id: string) => {
      const r = this.roster(id);
      r.assign(docLookup[id]);
      delete docLookup[id];

      return r;
    };

    findOrMakeRoster('elrond-js');
    findOrMakeRoster('shelf-covers').ensureAllCoversRostered();

    // Instantiate each dervish-roster found in the audit:
    const rosterLookup: Dictionary<{ roster: Roster; found: boolean }> = {};
    C.each(
      docLookup,
      (id, doc) => {
        const roster = findOrMakeRoster(id);
        rosterLookup[id] = {
          roster: roster,
          found: false
        };
      }
    );

    // Assign each downloader the dervish-rosters it references:
    C.each(APP.patron.loans.all, (loan) => {
      C.each(loan.downloader.rosterIds, (id) => {
        const info = rosterLookup[id];
        if (info) {
          info.found = true;
          loan.downloader.rosters[id] = info.roster;
          loan.downloader.updateProgress();
        } else {
          loan.downloader.rosters[id] = null;
        }
      });
    });

    // Delete any dervish-rosters that weren't assigned to
    // at least one downloader:
    C.each(
      rosterLookup,
      (id, info) => {
        if (!info.found) {
          info.roster.wipe();
          delete this.rosters[id];
        }
      }
    );

    // Perform any pending downloader checks:
    C.each(
      APP.patron.loans.all,
      (loan) => {
        if (loan.downloader.isAwaitingCheck(this.updateHorizon)) {
          loan.downloader.check({ recheckOnUpdate: false });
        }
      }
    );

    this._scheduleAuditProgressPolling();
  }


  protected _scheduleAuditProgressPolling(): void {
    clearTimeout(this._auditPollingTimer);
    const pollerFn = this._pollAuditProgress.bind(this);
    this._auditPollingTimer = window.setTimeout(pollerFn, 1000);
  }


  protected _pollAuditProgress(): void {
    for (let i = 0, ii = APP.patron.loans.all.length; i < ii; ++i) {
      const loan = APP.patron.loans.all[i];
      if (loan.downloader.checking) {
        this._scheduleAuditProgressPolling();

        return;
      }
    }
    for (const id in this.rosters) {
      if (this.rosters[id].updating) {
        this._scheduleAuditProgressPolling();

        return;
      }
    }
    APP.shell.transmit('roster:flush:all');
    this._auditing = false;
  }


  protected _onAppUpdateSimulate() {
    const appRoster = this.rosters['elrond-js'];
    if (appRoster) {
      appRoster.wipe();
    }
    setTimeout(() => {
      APP.events.dispatch('app:update:available',
        { version: APP.client.info.version.value! });
    }, 200);
  }


  protected _onUpdateAvailable(evt: DataEvent<{ version: string }>): void {
    const newVersion = new Version();
    newVersion.assign(evt.m.version);
    if (APP.shell.has('rosters')) {
      const appRoster = this.roster('elrond-js');
      if (!appRoster.targetVersion.isEqual(newVersion) || !appRoster.finalized) {
        appRoster.cycle(newVersion);
      }
    } else if (!this._targetAppVersion || this._targetAppVersion !== newVersion) {
      this._targetAppVersion = newVersion;
      setTimeout(() => {
        APP.events.dispatch('app:update:ready');
      }, 200);
    }
  }


  protected _onBifocalDownloadsPause() {
    setTimeout(() => this.pause('bifocal'));
  }


  protected _onBifocalDownloadsResume() {
    setTimeout(() => this.resume('bifocal'), 2000);
  }
}
