import { ODFirebase } from 'app/base/tracking/od-firebase';

/**
 * Retrieve remote configurations for libraries.
 * This is currently being done through Firebase Remote Configs
 */
export class RemoteConfig {
  protected readonly _firebase: ODFirebase;

  private readonly _initialize: Promise<void>;


  /**
   * @desc Initalizes remote config
   */
  constructor(firebase: ODFirebase) {
    this._firebase = firebase;

    console.log('[REMOTECONFIG] Initializing Remote Config');
    this._initialize = this._firebase.initializeRemoteConfig();
  }


  public async getValue(key: string) {
    await this._initialize;
    const output = this._firebase.getRemoteConfigValue(key);

    return output;
  }
}
