<template>
  <div>
    <!-- Input -->
    <div
      ref="tagForm"
      :class="$style.widget"
      @keydown.esc="close"
    >
      <input
        :id="ids.textInput"
        :value="textInput"
        type="text"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        :maxlength="maxCharLength"
        size="15"
        :placeholder="$t('tag.create.placeholder')"
        role="combobox"
        :aria-labelledby="ids.addButton"
        aria-autocomplete="list"
        :aria-controls="ids.autocomplete"
        aria-haspopup="listbox"
        :aria-expanded="showAutocomplete"
        :class="$style.inputField"
        @keydown.up.prevent="highlight(0)"
        @keydown.down.prevent="highlight(0)"
        @keydown.tab="close"
        @keydown.enter.prevent="applyTag(textInput)"
        @input="updateTagValue"
      />

      <button
        :id="ids.addButton"
        :class="$style.applyButton"
        @click="applyTag(textInput)"
      >
        {{ $t('tag.create.action') }}
      </button>
    </div>

    <!-- Error Field -->
    <FormError
      v-if="invalidNameError"
      aria-live="polite"
      :contents="$t('tag.create.invalidName')"
    />

    <!-- Autocomplete -->
    <Popout
      v-if="showAutocomplete && tagForm"
      :labelId="ids.autocomplete"
      :initialFocus="`#${ids.textInput}`"
      :reference="tagForm"
      :trapFocus="false"
      :boundary="autocompleteBoundary"
      @close="close"
    >
      <div
        role="listbox"
        :class="$style.dropdown"
        :style="`--width-size: ${dropdownWidth}px`"
      >
        <ul
          :id="ids.autocomplete"
          :aria-label="$t('tag.create.autocompleteList')"
          :class="$style.dropdownList"
          @keydown.esc="closeAndFocus(`#${ids.textInput}`)"
          @keydown.tab.prevent="closeAndFocus(`#${ids.addButton}`)"
        >
          <li
            v-for="(hint, index) in hints"
            :key="hint"
            @keydown.up.prevent="highlight(index-1)"
            @keydown.down.prevent="highlight(index+1)"
            @keydown.left.prevent="closeAndFocus(`#${ids.textInput}`)"
            @keydown.right.prevent="closeAndFocus(`#${ids.addButton}`)"
          >
            <button
              :id="`${ids.autocomplete}-hint-${index}`"
              :class="$style.dropdownHint"
              @click="applyTag(hint)"
            >
              {{ hint }}
            </button>
          </li>
        </ul>
      </div>
    </Popout>

    <!-- Applied Tags -->
    <DynamicExpander
      :numElements="tagsForTitle.length"
      :panelId="ids.appliedPanel"
      :headerId="title.slug"
    >
      <transition-group
        v-if="tagsForTitle.length"
        tag="ol"
        role="list"
        :aria-label="$t('tag.create.appliedList')"
        name="list"
        :enterFromClass="$style.listEnter"
        :enterActiveClass="$style.listEnterActive"
        :leaveActiveClass="$style.listLeaveActive"
        :leaveToClass="$style.listLeaveTo"
        :class="{
          [$style.appliedList]: true,
          [$style.scrollable] : restrictHeight
        }"
      >
        <li
          v-for="(tag, index) in tagsForTitle"
          :key="tag.slug"
          :class="$style.appliedListItem"
        >
          <button
            :id="`${ids.appliedPanel}-tag-${tag.slug}`"
            :class="$style.applied"
            @click="removeTag(tag, index)"
          >
            <span :class="$style.appliedText">
              {{ tag.name }}
            </span>
            <Icon
              name="dismiss"
              role="presentation"
              :class="$style.dismiss"
            />
          </button>
        </li>
      </transition-group>
    </DynamicExpander>

    <!-- Live region -->
    <div>
      <p
        class="visually-hidden"
        aria-live="polite"
      >
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import DynamicExpander from 'app/components/DynamicExpander.vue';
import FormError from 'app/components/FormError.vue';
import Popout from 'app/components/Popout.vue';
import { useAutocomplete } from 'app/functions/use-autocomplete';
import { useI18n } from 'app/functions/use-i18n';
import { usePatron } from 'app/functions/use-patron';
import { Tag } from 'app/models/tag';
import { TitleRecord } from 'app/models/title';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue';

export default defineComponent({
  name: 'TagWidget',
  components: {
    Popout,
    DynamicExpander,
    FormError
},
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    restrictHeight: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { t } = useI18n();
    const tagForm = ref<HTMLElement | null>(null);
    const dropdownWidth = ref(tagForm.value?.offsetWidth || 0);
    const showAutocomplete = ref(false);
    const autocompleteBoundary = document.body;
    const textInput = ref('');
    const maxCharLength = Tag.MAX_LENGTH;
    const invalidNameError = ref(false);
    const idBase = `tag-widget-${generateUUID()}`;
    const ids = {
      textInput: `${idBase}-input`,
      addButton: `${idBase}-button`,
      autocomplete: `${idBase}-autocomplete`,
      appliedPanel: `${idBase}-applied`
    };
    const message = ref('');

    const { tags } = usePatron();
    const userTags = computed(() => tags.value.filter(Tag.FILTER_FUNCTIONS.nonSystemTags));
    const tagsForTitle = computed(() => userTags.value.filter((tag) => tag.isAppliedToTitle(props.title.slug)).sort(Tag.SORT_FUNCTIONS.alpha));
    const unappliedTagNames = computed(() => userTags.value.filter((tag) => !tagsForTitle.value.includes(tag)).map((tag) => tag.name));

    // Quirk for suggestion keyboards where v-model only updates at the end of the word
    // Using :value/@input pair to work around that
    const updateTagValue = (typingEvent: Event) => {
      if (typingEvent.target) {
        const target = typingEvent.target as HTMLInputElement;
        textInput.value = target.value;
      } else {
        textInput.value = '';
      }
      // hide any active error message once the
      // user starts to make changes to the input
      invalidNameError.value = false;
    };

    // Autocomplete

    const { hints } = useAutocomplete(textInput, unappliedTagNames, { maxResults: 5 });

    watch(() => hints.value, async () => {
      if (hints.value.length && document.activeElement === document.getElementById(`${ids.textInput}`)) {
        // assign size of popout before it opens
        await nextTick();
        dropdownWidth.value = tagForm.value?.offsetWidth || 0;
        showAutocomplete.value = true;
      } else {
        close();
      }
    });

    const highlight = async (index: number) => {
      if (!showAutocomplete.value) { return; }

      let adjIndex = index;
      if (index < 0) { adjIndex = hints.value.length - 1; }
      if (index >= hints.value.length) { adjIndex = 0; }

      const element = document.querySelector(`#${ids.autocomplete}-hint-${adjIndex}`) as HTMLElement;

      await nextTick();
      (element)?.focus();
    };

    const close = () => {
      showAutocomplete.value = false;
    };

    const closeAndFocus = (selector: string) => {
      close();
      (document.querySelector(selector) as HTMLElement)?.focus();
    };


    // Tag actions

    const applyTag = (input: string) => {
      let tagName = input.trim();

      if (!tagName) {
        invalidNameError.value = true;

        return;
      }

      // input field should prevent more than max chars,
      // but double check here and cut it off if needed
      if (C.unicodeLength(tagName) > maxCharLength) {
        tagName = tagName.slice(0, maxCharLength);
      }

      const currentTags = APP.patron.tags;

      if (currentTags.isSystemTagName(tagName)) {
        invalidNameError.value = true;

        return;
      }

      const newOrExisting = currentTags.find({ lowercaseName: tagName.toLowerCase() }) || currentTags.stub({ name: tagName });
      newOrExisting.addToTitle(props.title.slug);

      APP.tracking.log('tag_created', {
        tag_uuid: newOrExisting.uuid,
        title_slug: props.title.slug,
        title_name: (`${props.title.title} ${props.title.subtitle || ''}`).trim(),
        reserveID: props.title.lexisMetadata?.contentReserveID,
        is_set: false
      });

      textInput.value = '';
      closeAndFocus(`#${ids.textInput}`);

      changeMessage(t('tag.create.added', { NAME: newOrExisting.name }));
    };

    const removeTag = (selectedTag: Tag, index: number) => {
      // shift keyboard focus to sibling
      const sibling = tagsForTitle.value[index + 1] || tagsForTitle.value[index - 1];
      if (sibling) {
        (document.querySelector(`#${ids.appliedPanel}-tag-${sibling.slug}`) as HTMLElement).focus();
      }

      // prevent a tag button from changing its position due to the position:absolute on leave-active
      const parent = (document.querySelector(`#${ids.appliedPanel}-tag-${selectedTag.slug}`) as HTMLElement).parentElement;
      if (parent) {
        parent.style.top = parent.offsetTop + 'px';
        parent.style.left = parent.offsetLeft + 'px';
      }

      selectedTag.removeFromTitle(props.title.slug);

      changeMessage(t('tag.create.removed', { NAME: selectedTag.name }));
    };


    // Accessibility

    const changeMessage = (msg: string) => {
      // annouce message to screen reader after small delay to account for reassigned focus
      setTimeout(() => message.value = msg, 250);
      // reset the message so it isn't accessible on it's own and allows for repeated content to be annouced
      setTimeout(() => message.value = '', 1000);
    };

    let typeaheadTimer = -1;
    watch(() => textInput.value, () => {
      clearTimeout(typeaheadTimer);

      // wait until the user pauses typing before announcing count message
      typeaheadTimer = window.setTimeout(() => {
        if (hints.value.length) {
          changeMessage(t('tag.create.autocompleteCount', { COUNT: hints.value.length }));
        }
      }, 1000);
    });

    return {
      applyTag,
      autocompleteBoundary,
      close,
      closeAndFocus,
      dropdownWidth,
      highlight,
      hints,
      ids,
      invalidNameError,
      maxCharLength,
      message,
      removeTag,
      showAutocomplete,
      tagForm,
      tagsForTitle,
      textInput,
      updateTagValue
    };
  }
});
</script>

<style module>
.widget {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.25rem;
  border: 1px solid var(--c-darkest-gray);
  padding: 0.25rem;
  border-radius: var(--form-border-radius);
}

.input-field {
  border: none;
  padding: 0 0.25rem;
  composes: focus-outline from global;
  composes: ellipsis from global;
}

.dropdown {
  width: calc(var(--width-size) - 0.5rem);
  display: grid;
  grid-gap: 0.25rem;
  margin: 0.5rem 0.25rem;
}

.dropdown-list {
  width: inherit;
}

.dropdown-hint {
  padding: 0 0.25rem;
  max-width: 100%;
  composes: ellipsis from global;
}

.apply-button {
  padding: 0 0.25rem;
  border: 1px solid var(--c-dark-gray);
  border-radius: var(--form-border-radius);
}

.applied-list {
  padding: 0 0.25rem;
  margin: 0.5rem -0.25rem 0;
  /* scrollbar styling for Firefox */
  scrollbar-color: var(--c-light-black) var(--c-light-gray);
}

.scrollable {
  max-height: 100px;
  overflow-y: auto;
}

/* scrollbar styling for Chrome, Safari */
.applied-list::-webkit-scrollbar {
  width: .3rem;
}

.applied-list::-webkit-scrollbar-track {
  background: var(--c-light-gray);
  border-left: .1rem solid white;
  border-right: .1rem solid white;
}

.applied-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--c-light-black);
}

.applied-list::-webkit-scrollbar-thumb &:hover {
    background: var(--c-darkest-gray);
  }

.applied-list-item {
  transition: opacity .5s ease, transform .5s ease;
  display: inline-flex;
  max-width: 100%;
}

.list-leave-active {
  position: absolute;
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.applied {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-black);
  padding: 0 0.25rem;
  font-size: var(--fs-metadata);
  margin: 0.25rem 0.5rem 0.25rem 0;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  max-width: calc(100% - 0.5rem);
}

.applied-text {
  composes: ellipsis from global;
}


.dismiss {
  height: 0.6rem;
  width: 0.6rem;
  padding-left: 0.5rem;
  fill: var(--c-dark-black);
}
</style>
