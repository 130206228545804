import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["aria-label", "aria-current", "onClick"]
const _hoisted_4 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("nav", {
    role: "navigation",
    "aria-label": _ctx.$t('form.pagination.label')
  }, [
    _createElementVNode("ol", {
      class: _normalizeClass(_ctx.$style.pagination)
    }, [
      (_ctx.showBackButton)
        ? (_openBlock(), _createElementBlock("li", {
            key: 0,
            class: _normalizeClass([_ctx.$style.bookend, _ctx.$style.left])
          }, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(_ctx.$style.bookend),
              "aria-label": _ctx.$t('form.pagination.previousPage'),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
            }, [
              _createVNode(_component_Icon, {
                name: "chevron-left",
                class: _normalizeClass(_ctx.$style.shiftButton)
              }, null, 8, ["class"]),
              _createElementVNode("span", {
                class: _normalizeClass([_ctx.$style.page, _ctx.$style.ends])
              }, _toDisplayString(_ctx.$t('nav.pagination.previous')), 3)
            ], 10, _hoisted_2)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("li", {
        class: _normalizeClass(_ctx.$style.pages)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedPages, (group, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: `group-${index}`
          }, [
            (index !== 0)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  "aria-hidden": "true",
                  class: _normalizeClass(_ctx.$style.listItem)
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style.separator)
                  }, " … ", 2)
                ], 2))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (page) => {
              return (_openBlock(), _createElementBlock("li", {
                key: page,
                class: _normalizeClass(_ctx.$style.listItem)
              }, [
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass({
                [_ctx.$style.page]: true,
                [_ctx.$style.active]: page === _ctx.currentPage
              }),
                  "aria-label": _ctx.$t(`form.pagination.${ page === _ctx.currentPage ? 'currentPage' : 'goToPage' }`, { page }),
                  "aria-current": page === _ctx.currentPage ? 'page' : false,
                  onClick: ($event: any) => (_ctx.goToPage(page))
                }, _toDisplayString(page), 11, _hoisted_3)
              ], 2))
            }), 128))
          ], 64))
        }), 128))
      ], 2),
      (_ctx.showForwardButton)
        ? (_openBlock(), _createElementBlock("li", {
            key: 1,
            class: _normalizeClass([_ctx.$style.bookend, _ctx.$style.right])
          }, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(_ctx.$style.bookend),
              "aria-label": _ctx.$t('form.pagination.nextPage'),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.forward && _ctx.forward(...args)))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass([_ctx.$style.page, _ctx.$style.ends])
              }, _toDisplayString(_ctx.$t('nav.pagination.next')), 3),
              _createVNode(_component_Icon, {
                name: "chevron-right",
                class: _normalizeClass(_ctx.$style.shiftButton)
              }, null, 8, ["class"])
            ], 10, _hoisted_4)
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 8, _hoisted_1))
}