import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["id", "aria-selected", "aria-controls", "aria-label", "tabindex", "onClick"]
const _hoisted_3 = {
  key: 0,
  class: "badge"
}
const _hoisted_4 = ["id", "aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      ref: "list",
      role: "tablist",
      "aria-label": _ctx.ariaLabel,
      class: _normalizeClass([_ctx.$style.tabList, 'no-scrollbar']),
      onKeydown: [
        _cache[0] || (_cache[0] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.changeFocusToLeft && _ctx.changeFocusToLeft(...args)), ["prevent"]), ["left"])),
        _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.changeFocusToRight && _ctx.changeFocusToRight(...args)), ["prevent"]), ["right"]))
      ]
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
        return (_openBlock(), _createElementBlock("button", {
          id: `tab-${tab.id}`,
          ref_for: true,
          ref: _ctx.setItemRef,
          key: tab.id,
          role: "tab",
          "aria-selected": _ctx.activeTab === tab.id,
          "aria-controls": `panel-${tab.id}`,
          "aria-label": _ctx.tabLabel(tab),
          tabindex: _ctx.activeTab === tab.id ? '0' : '-1',
          class: _normalizeClass([_ctx.$style.tab, _ctx.activeTab === tab.id ? _ctx.$style.active : '']),
          onClick: ($event: any) => (_ctx.tabIndex = i)
        }, [
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(tab.label) + " ", 1),
            (typeof tab.count === 'number')
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$n(tab.count)), 1))
              : _createCommentVNode("", true)
          ])
        ], 10, _hoisted_2))
      }), 128))
    ], 42, _hoisted_1),
    _renderSlot(_ctx.$slots, "intermediary"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        id: `panel-${tab.id}`,
        key: `panel-${tab.id}`,
        role: "tabpanel",
        "aria-labelledby": `tab-${tab.id}`
      }, [
        _renderSlot(_ctx.$slots, `panel-${tab.id}`)
      ], 8, _hoisted_4)), [
        [_vShow, _ctx.activeTab === tab.id]
      ])
    }), 128))
  ], 2))
}