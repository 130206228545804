import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("h3", {
      class: _normalizeClass(_ctx.$style.type)
    }, _toDisplayString(_ctx.$t(_ctx.typeName)), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.text)
    }, [
      (_ctx.type === 'TitleNotAvailable')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('alert.details.titleRemoved')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('alert.details.seriesRemoved')), 1)
          ], 64))
    ], 2),
    _createVNode(_component_Card, {
      class: _normalizeClass(_ctx.$style.card),
      compact: true,
      item: _ctx.cardableItem
    }, {
      cover: _withCtx(() => [
        _renderSlot(_ctx.$slots, "cover")
      ]),
      _: 3
    }, 8, ["class", "item"])
  ], 2))
}