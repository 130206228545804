import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { HighlightColor } from 'app/base/constants';
import { DervishLocator } from 'app/base/interfaces';
import formatter from 'app/i18n/i18n';
import { ItemWithTitleCache } from 'app/models/item-with-title';
import { ScribeTitleAttributes, TitleRecord } from 'app/models/title';


export type AnnotationCategory = 'Color' | 'Release';

export type FilterAnnotation = {
  id: string;
  name: string;
  category: AnnotationCategory;
  selected: boolean;
};

export class Annotation extends ItemWithTitleCache<AnnotationRemoteResponse, AnnotationsAttrs> {
  public uuid: string;
  public syncstamp: number;
  public colorID: HighlightColor;
  public quote: string;
  public note: string | null;
  public percentageOfComponent: number;
  public percentageOfBook: number;
  public timestamp: number;
  public titleSlug: string;
  public citation: string;
  public chapterIndex: number;
  public chapterTitle: string;
  public release: string;
  public releaseDate: string;
  public releaseSlug: string;
  public buid: string;
  public componentMilliseconds: number;
  public extentMilliseconds: number;
  public locatorA: DervishLocator;
  public locatorZ: DervishLocator;
  public spinePosition: number;
  public migration: number | null;


  public static SORT_FUNCTIONS = {
    release: (a: Annotation, b: Annotation) => {
      if (!a.release) { return 1; }
      if (!b.release) { return -1; }

      const releaseComparison = b.release.localeCompare(a.release);
      if (releaseComparison !== 0) { return releaseComparison; }

      if (!a.releaseDate) { return 1; }
      if (!b.releaseDate) { return -1; }

      const releaseDateMillisA = (new Date(a.releaseDate)).getTime();
      const releaseDateMillisB = (new Date(b.releaseDate)).getTime();

      return releaseDateMillisB - releaseDateMillisA;
    },
    syncstamp: (a: Annotation, b: Annotation) => {
      if (!a) { return -1; }
      if (!b) { return 1; }

      return b.syncstamp - a.syncstamp;
    }
  };


  public static FILTER_FUNCTIONS = {
    filterByText: (a: Annotation, query: string | undefined) => {
      if (query) {
        const toSearch = [a.quote];

        if (a.note) {
          toSearch.push(a.note);
        }

        if (a.citation) {
          toSearch.push(a.citation);
        }

        if (a.titleRecord?.title) {
          toSearch.push(a.titleRecord.title);
        }

        if (a.titleRecord?.subtitle) {
          toSearch.push(a.titleRecord.subtitle);
        }

        if (a.release) {
          toSearch.push(a.release);
        }

        if (a.releaseDate) {
          toSearch.push(formatter.t('time.date', { date: new Date(a.releaseDate) }));
        }

        const queryComparable = query.toLowerCase().replace(/\s+/g, ' ');
        const isMatch = toSearch.some((field) => {
          const fieldComparable = field.toLowerCase().replace(/\s+/g, ' ');
          const fieldComparableNoSpecial = fieldComparable.replace(/[^\w\s ] ?/g, '');

          return fieldComparable.indexOf(queryComparable) >= 0 || fieldComparableNoSpecial.indexOf(queryComparable) >= 0;
        });

        return isMatch;
      }

      return true;
    },
    filterByRelease: (a: Annotation, release: string, releaseDate: string) => {
      return a.release === release && a.releaseDate === releaseDate;
    }
  };


  public toCopyString(): string {
    return [this.quote, this.citation, this.note].filter((i) => i).join('\n\n');
  }


  public async releaseTitleRecord(): Promise<TitleRecord> {
    if (this.releaseSlug) {
      return APP.titleCache.get(this.releaseSlug);
    }

    if (this.titleRecord &&
      this.titleRecord.lexisMetadata?.release === this.release &&
      this.titleRecord.lexisMetadata?.releaseDate === this.releaseDate) {
        this.releaseSlug = this.titleRecord.slug;

        return this.titleRecord;
    }

    // Try to map release slug
    if (!this.releaseSlug && (this.release || this.releaseDate)) {
      const releases = await this.titleRecord.getPriorReleases();
      if (releases) {
        const matchingRelease = releases.filter((r) => {
          return (r && r.lexisMetadata?.release === this.release &&
            r.lexisMetadata?.releaseDate === this.releaseDate);
        });

        if (matchingRelease && matchingRelease.length) {
          this.releaseSlug = matchingRelease[0].slug;

          return matchingRelease[0];
        }
      }
    }

    return null;
  }


  protected mapItem(response: AnnotationRemoteResponse): void {
    this.mapAnnotation(response);
  }


  protected mapItemFromBank(attrs: AnnotationsAttrs): void {
    this.mapAnnotation(attrs);
  }


  protected mapTitle(response: AnnotationRemoteResponse): string {
    return response.title ? response.title.titleId : APP.shell.bifocal.title().slug;
  }


  protected serializeInternal(): AnnotationsAttrs {
    return {
      uuid: this.uuid,
      syncstamp: this.syncstamp,
      colorID: HighlightColor[this.colorID],
      colorGroup: HighlightColor[this.colorID],
      quote: this.quote,
      note: this.note,
      percentageOfBook: this.percentageOfBook,
      percentageOfComponent: this.percentageOfComponent,
      timestamp: this.timestamp,
      citation: this.citation,
      chapterIndex: this.chapterIndex,
      chapterTitle: this.chapterTitle,
      release: this.release || null,
      releaseDate: this.releaseDate || null,
      releaseSlug: this.releaseSlug || null,
      buid: this.buid,
      locatorA: this.locatorA,
      locatorZ: this.locatorZ,
      spinePosition: this.spinePosition,
      componentMilliseconds: this.componentMilliseconds,
      extentMilliseconds: this.extentMilliseconds,
      migration: this.migration
    };
  }


  protected mapAnnotation(attrs: AnnotationsAttrs | AnnotationRemoteResponse): void {
    this.uuid = attrs.uuid;
    this.syncstamp = attrs.syncstamp;
    this.quote = attrs.quote;
    this.note = !APP.library.disableNotes ? attrs.note : null;
    this.percentageOfBook = attrs.percentageOfBook;
    this.percentageOfComponent = attrs.percentageOfComponent;
    this.timestamp = attrs.timestamp;
    this.citation = attrs.citation;
    this.chapterIndex = attrs.chapterIndex;
    this.chapterTitle = attrs.chapterTitle;
    this.release = attrs.release || null;
    this.releaseDate = attrs.releaseDate || null;
    this.releaseSlug = attrs.releaseSlug || null;
    this.buid = attrs.buid;
    this.locatorA = attrs.locatorA;
    this.locatorZ = attrs.locatorZ;
    this.spinePosition = attrs.spinePosition;
    this.componentMilliseconds = attrs.componentMilliseconds;
    this.extentMilliseconds = attrs.extentMilliseconds;
    this.migration = attrs.migration || null;

    if (C.isNumber(attrs.colorID)) {
      this.colorID = Number(attrs.colorID);
    } else if (attrs.colorID || attrs.colorGroup) {
      this.colorID = HighlightColor[attrs.colorGroup || attrs.colorID];
    }

    if (this.isAnnotationRemoteResponse(attrs)) {
      this.buid = this.buid || attrs.title.buid;
      this.release = attrs.title.release || null;
      this.releaseDate = attrs.title.releaseDate || null;
    }
  }


  /**
   * Type guard for mapAnnotation()
   * @param attr The object to check
   */
  protected isAnnotationRemoteResponse(attr: AnnotationsAttrs | AnnotationRemoteResponse): attr is AnnotationRemoteResponse {
    return (attr as AnnotationRemoteResponse).title !== undefined;
  }
}

interface AnnotationRemoteResponse extends AnnotationsAttrs {
  title: ScribeTitleAttributes & { buid?: string };
}

export interface AnnotationsAttrs {
  uuid: string;
  syncstamp: number;
  colorID: string;
  colorGroup?: string;
  quote: string;
  note: string | null;
  percentageOfBook: number;
  percentageOfComponent: number;
  timestamp: number;
  citation: string;
  chapterIndex: number;
  chapterTitle: string;
  release?: string;
  releaseDate?: string;
  releaseSlug?: string;
  buid: string;
  locatorA: DervishLocator;
  locatorZ: DervishLocator;
  spinePosition: number;
  componentMilliseconds: number;
  extentMilliseconds: number;
  migration: number | null;
}
