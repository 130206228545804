<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.selectable]: selectable
    }"
  >
    <FormCheckbox
      v-if="selectable"
      :modelValue="displayAnnotation.selected ? 'on' : 'off'"
      :showLabel="false"
      :label="$t('annotations.display.select')"
      :ariaLabel="$t('annotations.display.select')"
      :class="$style.checkbox"
      @update:modelValue="updateSelected"
    />
    <!-- We change numElements when expanded to force height recalculation -->
    <DynamicExpander
      :panelId="`annotation-card-panel-${displayAnnotation.id}`"
      :headerId="displayAnnotation.id"
      :numElements="expanded ? 2 : 1"
      :class="$style.panel"
    >
      <article
        :class="{
          [$style.card]: true,
          [$style.expanded]: expanded,
          [$style.selected]: selectable && displayAnnotation.selected
        }"
      >
        <button
          v-if="selectable"
          ref="selectButton"
          :class="$style.fullCardButton"
          :aria-label="$t('annotations.display.select')"
          @click="updateSelected(displayAnnotation.selected ? 'off' : 'on')"
        ></button>
        <button
          v-else
          ref="expansionButton"
          :class="$style.fullCardButton"
          :aria-label="$t('annotations.display.expandText')"
          @click="toggleExpansion"
        ></button>
        <span
          :class="$style.colorStrip"
          :style="`--highlight-color: var(--highlight-color-${displayAnnotation.colorGroup})`"
          aria-hidden="true"
        ></span>
        <p
          :class="{
            [$style.text]: true,
            [$style.truncatedLong]: !expanded
          }"
        >
          {{ displayAnnotation.highlight }}
        </p>

        <dl :class="$style.metadataList">
          <template v-if="!hideNote">
            <dt>{{ $t('annotations.display.property.note') }}</dt>
            <dd
              v-if="displayAnnotation.note"
              :class="{
                [$style.text]: true,
                [$style.truncated]: !expanded
              }"
            >
              {{ displayAnnotation.note }}
            </dd>
            <dd
              v-else
              :class="$style.noNote"
            >
              {{ $t('annotations.display.noNote') }}
            </dd>
          </template>

          <dt>{{ $t('annotations.display.property.citation') }}</dt>
          <dd v-if="!selectable && displayAnnotation.releaseTitleRecord">
            <TitleActionButton
              :title="displayAnnotation.releaseTitleRecord"
              :parent="displayAnnotation.parentTitleRecord"
              :seekTo="{
                type: 'highlight',
                location: displayAnnotation.id
              }"
              :label="displayAnnotation.citation"
              :truncate="true"
              :class="{
                [$style.titleActionButton]: true,
                [$style.text]: true,
                [$style.linkText]: true,
                [$style.truncated]: !expanded
              }"
            />
          </dd>
          <dd
            v-else
            :class="{ [$style.truncated]: !expanded }"
          >
            {{ displayAnnotation.citation }}
          </dd>

          <dt>{{ $t('annotations.display.property.release') }}</dt>
          <dd :class="{ [$style.truncated]: !expanded }">
            {{ displayAnnotation.releaseDisplayName }}
          </dd>
        </dl>

        <div :class="$style.footer">
          <dl :class="$style.footerMetadataList">
            <dt
              class="visually-hidden"
            >
              {{ $t('annotations.display.property.color') }}
            </dt>
            <dd>
              <HighlightColor
                :colorGroup="displayAnnotation.colorGroup"
              />
            </dd>

            <dt
              class="visually-hidden"
            >
              {{ $t('annotations.display.property.lastUpdated') }}
            </dt>
            <dd>
              <span :class="$style.lastUpdated">
                <Icon
                  name="expire-clock"
                  aria-hidden="true"
                />
                <RelativeDate
                  :timestamp="displayAnnotation.lastUpdated"
                />
              </span>
            </dd>
          </dl>

          <AnnotationContextMenuButton
            v-if="!selectable"
            :annotations="[annotation]"
            :buttonClass="$style.actions"
            :attrs="contextMenuType === 'export-queue' ? {
              type: contextMenuType,
              showCount: false,
              exportOptions: false
            } : {
              type: contextMenuType,
              showCount: false,
              showSearchOption: true
            }"
          />
        </div>
      </article>
    </DynamicExpander>
  </div>
</template>

<script lang="ts">
import DynamicExpander from 'app/components/DynamicExpander.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import HighlightColor from 'app/components/HighlightColor.vue';
import Icon from 'app/components/Icon.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import AnnotationContextMenuButton from 'app/components/contextMenus/AnnotationContextMenuButton.vue';
import { SelectableAnnotation, useDisplayAnnotation } from 'app/functions/use-display-annotation';
import { ADAllowStTOption, ContextMenuType } from 'app/keys/injection-keys';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { PropType, Ref, computed, defineComponent, inject, nextTick, ref, toRef, watchEffect } from 'vue';

export default defineComponent({
  name: 'AnnotationCard',
  components: {
    DynamicExpander,
    FormCheckbox,
    HighlightColor,
    Icon,
    RelativeDate,
    TitleActionButton,
    AnnotationContextMenuButton
  },
  props: {
    annotation: {
      type: Object as PropType<SelectableAnnotation>,
      required: true
    },
    selectable: {
      type: Boolean,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'annotation:update'
  ],
  setup: (props, ctx) => {
    const actions = ref<HTMLElement | null>(null);
    const expansionButton = ref<HTMLButtonElement | null>(null);
    const selectButton = ref<HTMLButtonElement | null>(null);

    const contextMenuType = inject(ContextMenuType, 'annotation-details');

    const showContextMenu = ref(false);
    const showSearchOption = inject(ADAllowStTOption, computed(() => false));

    const { displayAnnotation } = useDisplayAnnotation(toRef(props, 'annotation') as Ref<SelectableAnnotation>);

    const expanded = ref(false);
    const toggleExpansion = async () => {
      expanded.value = !expanded.value;
      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth' });
      expansionButton.value?.focus({ preventScroll: true });
    };

    watchEffect(() => {
      if (props.selectable) {
        expanded.value = false;
      }
    });

    const updateSelected = (checked: FormCheckboxState) => {
      selectButton.value?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      selectButton.value?.focus();
      ctx.emit('annotation:update', { ...props.annotation, selected: checked === 'on' }, checked);
    };

    return {
      actions,
      contextMenuType,
      displayAnnotation,
      expanded,
      expansionButton,
      selectButton,
      showContextMenu,
      showSearchOption,
      toggleExpansion,
      updateSelected
    };
  }
});

</script>

<style module>
.text {
  white-space: pre-wrap;
  composes: word-break from global;
}

.truncated {
  --line-clamp: 1;
  composes: line-clamp from global;
}

.truncated-long {
  --line-clamp: 3;
  composes: line-clamp from global;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.container.selectable {
  grid-template-columns: auto 1fr;
}

.panel > div {
  padding: 0.25rem 1rem; /* room for focus outlines */
}

.card {
  border: 1px solid rgba(var(--c-primary-blue-rgb), 0.1);
  border-radius: var(--form-border-radius);
  padding: 1rem;
  position: relative;

  display: grid;
  grid-template-areas:
    "color quote"
    "empty metadata"
    "empty footer";
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 1rem;
}

.card.expanded {
  background-color: var(--c-lightest-gray);
}

.card.selected {
  background-color: var(--c-selected);
}

.full-card-button {
  position: absolute;
  width: 100%;
  height: 100%;

  scroll-margin: 1rem;
}

.color-strip {
  grid-area: color;

  background-color: var(--highlight-color);
  border-radius: var(--form-border-radius);
  width: 4px;
}

.metadata-list {
  grid-area: metadata;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 0.25rem 1rem;

  color: var(--c-darkish-black);
  font-size: var(--fs-metadata);
}

.link-text {
  padding: 0.25rem;
  margin: -0.25rem;
}

.metadata-list .no-note {
  font-style: italic;
}

.footer {
  grid-area: footer;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 1rem;
}

.footer-metadata-list {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.last-updated {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;

  color: var(--c-light-black);
  font-size: var(--fs-metadata);
}

.last-updated svg {
  width: 1rem;
  height: 1rem;
  stroke: var(--c-light-black);
}

.actions {
  padding: .625rem 1.125rem;
  line-height: 0;
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: 50%;

  &:hover, &:focus {
    border-radius: 1.5rem;
    background-color: var(--c-light-gray);
  }

  svg {
    height: 1.25rem;
    width: 0.625rem;
    fill: rgba(var(--c-primary-blue-rgb), 1);
  }
}

/* Force a new stacking context to raise them above the full card buttons */
.title-action-button,
.actions {
  isolation: isolate;
}
</style>
