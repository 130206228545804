import { ComputedRef, watch } from 'vue';
import Events from '../../app/events/events';


export type ChatterboxMessage = {
  message: string;
  politeness?: 'polite' | 'assertive' | undefined;
};


/**
 * Announce to the screen reader when a page is in a loading state.
 *
 * @param loading computed function of whether the page is loading or not
 * @param announceLoading true to announce 'Loading', false to announce nothing, or pass in a different string to announce
 */
export function watchLoadingState(loading: ComputedRef<boolean>, announceLoading: boolean | string) {
  watch(() => loading.value, () => {
    if (loading.value) {
      Events.dispatch('chatter:loading', { message: announceLoading});
    } else {
      Events.dispatch('chatter:loaded');
    }
  }, { immediate: true });
}


/**
 * Announce a single message to the screen reader.
 * Will allow you to announce the same message twice.
 * If polite, has a small delay before announcing,
 * to account for cases where keyboard focus is assigned.
 *
 * Assumptions:
 * - this is the result of a direct user interaction
 * - will only have one message at a time
 * - does not need to respect aria-busy
 *
 * @param message string to be announce to the screen reader
 * @param politeness 'polite' or 'assertive' depending on how quickly the message needs announced
 */
export function announceMessage(message: string, politeness?: 'polite' | 'assertive') {
  window.setTimeout(
    () => Events.dispatch('chatter:message', { message: message, politeness: politeness }),
    politeness === 'assertive' ? 0 : 1000
  );
}


/**
 * Keep track of a message that may change and need to be reannounced.
 * Assumes the message will not be the same as before.
 *
 * @param message ComputedRef<string> of which the value will be announced to the screen reader when it changes
 * @param politeness 'polite' or 'assertive' depending on how quickly the message needs announced
 */
export function watchMessage(message: ComputedRef<string>, politeness?: 'polite' | 'assertive') {
  watch(() => message.value, () => {
    Events.dispatch('chatter:message', { message: message.value, politeness: politeness });
  });
}


/**
 * Keep track of the number of results on a page.
 * If number is same as before, you will need to force a temp loading state to allow the result to be repeated.
 * Or dispatch chatter:loaded to acheive the same results.
 *
 * @param message ComputedRef<sting> of which the value will be announced to the screen reader when it changes, or loading is finished
 */
export function watchResultCount(message: ComputedRef<string>) {
  watch(() => message.value, () => {
    // defaulting politeness for now, but potential to use assertive for 0 results in future
    Events.dispatch('chatter:result', { message: message.value, politeness: 'polite' });
  }, { immediate: true });
}
