import { HighlightColorGroup } from 'app/base/constants';
import env from 'app/base/env';
import { APP } from './app';
import { C } from './common';
import { Service } from './services/service';

const POSTISH_ANNOTATION_BATCH_SIZE = 10000;

type Pagination = {
  count?: number;
  offset?: number;
  limit?: number;
};

export class Postish extends Service {

  constructor() {
    super('POSTISH', (path) => {
      return { url: `${env.POSTISH_URI}/${path}` };
    });
  }

  public async getAnnotations(): Promise<PostishAnnotation[]> {
    const initialResult = await this._getAnnotationBatch({ limit: POSTISH_ANNOTATION_BATCH_SIZE });
    if (!initialResult) { return []; }

    const batchRequests = [];
    for (
      let offset = POSTISH_ANNOTATION_BATCH_SIZE;
      offset < initialResult.count;
      offset += POSTISH_ANNOTATION_BATCH_SIZE
    ) {
      batchRequests.push(this._getAnnotationBatch({ offset, limit: POSTISH_ANNOTATION_BATCH_SIZE }));
    }

    const remainingResults = await Promise.all(batchRequests);
    const allResults = [initialResult, ...remainingResults];
    const allAnnotations =  allResults.flatMap((result) => result?.annotations || []);

    return allAnnotations;
  }

  private _getAnnotationBatch(pagination: Pagination): Promise<PostishAnnotationsResponse | null> {
    return this.fetchAsync<PostishAnnotationsResponse>(
      {
        url: C.parameterizeURL(`annotations/${APP.patron.accountId}`, pagination),
        headers: {
          Authorization: `Bearer ${APP.sentry.identityToken}`
        }
      }
    );
  }


  public async getExportUrl(ids: string[], type: 'csv' | 'txt' | 'pdf' | 'drive', filename: string): Promise<string> {
    const token = await this.fetchExportToken(ids);

    const file = `${filename}.${type !== 'drive' ? type : 'pdf'}`;

    const path = `export/annotations/${file}`;

    let pathWithQuery = C.parameterizeURL(path, { t: token });

    if (type === 'drive') {
      pathWithQuery = `save_to_google_drive/${pathWithQuery}&file_name=${file}`;
    }

    return this.pathToURL(pathWithQuery);
  }


  public fetchExportToken(ids: string[]): Promise<string | null> {
    return APP.services.postish.fetchAsync({
      url: 'export/token',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`,
        'Accept-Language': 'en' // need to manually pass a valid locale as defined by Postish: https://github.com/bksh/postish/tree/live-elrond/config/locales
      },
      credentials: true,
      body: JSON.stringify({
        account_id: APP.patron.accountId,
        uuids: ids
      })
    });
  }


  // Annotation Copying endpoints

  public startCopyJob(targetId: string, releaseId: string): Promise<PostishCopyJobResponse | null> {
    return APP.services.postish.fetchAsync({
      url: `annotations/${APP.patron.accountId}/copy`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        source_title_id: releaseId,
        target_title_id: targetId
      })
    });
  }


  public getCopyJob(jobId: string): Promise<PostishCopyJobResponse | null> {
    if (!APP.patron.accountId || !APP.sentry.identityToken) {
      console.warn('[POSTISH] getCopyJob: Missing accountId or identityToken');

      return Promise.resolve(null);
    }

    return APP.services.postish.fetchAsync({
      url: `annotations/${APP.patron.accountId}/copy/${jobId}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true
    });
  }


  public getAllCopyJobs(): Promise<PostishCopyJobResponse[] | null> {
    if (!APP.patron.accountId || !APP.sentry.identityToken) {
      console.warn('[POSTISH] getAllCopyJobs: Missing accountId or identityToken');

      return Promise.resolve(null);
    }

    return APP.services.postish.fetchAsync({
      url: `annotations/${APP.patron.accountId}/copy`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true
    });
  }


  public getCopyAnnotationSnapshots(jobId: string): Promise<PostishCopyAnnotationSnapshotResponse | null> {
    if (!APP.patron.accountId || !APP.sentry.identityToken) {
      console.warn('[POSTISH] getCopyAnnotationSnapshots: Missing accountId or identityToken');

      return Promise.resolve(null);
    }

    return APP.services.postish.fetchAsync<PostishCopyAnnotationSnapshotResponse>({
      url: `annotations/${APP.patron.accountId}/copy/${jobId}/snapshots`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true
    }).then((snapshots) => {
      /* For very old annotations the snapshot may have a color instead of a color_id.
       * To make things appear correctly for now we're doing some manual mapping here.
       *
       * TODO: Some sort of color and color handling rework. This is very much a bandaid.
       */

      if (!snapshots) { return snapshots; }

      const colorMappings: { [hex: string]: HighlightColorGroup } = {
        '#ffccce': 'RL',
        '#ff7979': 'RS',
        '#fbd7a9': 'OL',
        '#ffae5b': 'OS',
        '#f2f098': 'YL',
        '#fffa67': 'YS',
        '#b9f9ca': 'GL',
        '#5ef987': 'GS',
        '#b4fffb': 'TL',
        '#54f9f1': 'TS',
        '#a2e3fd': 'BL',
        '#51cfff': 'BS',
        '#eecffb': 'VL',
        '#e29dff': 'VS'
      };

      const mapped: PostishCopyAnnotationSnapshotResponse = snapshots.map((snapshot) => {
        const colorId: HighlightColorGroup =
          snapshot.color_id
            ? snapshot.color_id
            : snapshot.color
              ? colorMappings[snapshot.color.toLowerCase()] || 'RL'
              : 'RL';

        return { ...snapshot, color_id: colorId };
      });

      return mapped;
    });
  }


  public reviewCopyJob(jobId: string): Promise<PostishCopyJobResponse | null> {
    return APP.services.postish.fetchAsync({
      url: `annotations/${APP.patron.accountId}/copy/${jobId}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        reviewed: true
      })
    });
  }


  public retryCopyJob(jobId: string): Promise<PostishCopyJobResponse | null> {
    return APP.services.postish.fetchAsync({
      url: `annotations/${APP.patron.accountId}/copy/${jobId}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true
    });
  }


  public async deleteCopyJob(jobId: string): Promise<void> {
    await APP.services.postish.fetchAsync({
      url: `annotations/${APP.patron.accountId}/copy/${jobId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      textResponse: true
    });
  }
}

export interface PostishAnnotationsResponse {
  count: number;
  offset: number;
  limit: number;
  annotations: PostishAnnotation[];
}

export interface PostishAnnotation {
  accountId: string;
  chip: string;
  uuid: string;
  color: string;
  timestamp: number;
  syncstamp: number;
  note: string | null;
  quote: string;
  percentageOfBook: number;
  percentageOfComponent: number;
  title: {
    release: string;
    releaseDate: string;
    titleId: string;
  };
}

export type PostishCopyStatus = 'QUEUED' | 'IN_PROGRESS' | 'FINISHED' | 'ERROR';

export type PostishCopyTitleSnapshot = {
  buids: string[];
  id: number;
  parent_title_id: string | null;
  release: string | null;
  release_date: string | null;
  reserve_id: string;
  title_id: string;
};

export interface PostishCopyJobResponse {
  id: number;
  source_title: PostishCopyTitleSnapshot;
  target_title: PostishCopyTitleSnapshot;
  reviewed: boolean;
  created_at: string;
  updated_at: string;
  status: PostishCopyStatus;
  results: {
    successes: {
      original: string;
      copied: string;
    }[];
    failures: string[];
  };
}

export interface PostishCopyAnnotationSnapshot {
  id: number;
  copy_job_id: number;
  uuid: string;
  account_id: string;
  quote: string;
  color_id: HighlightColorGroup;
  citation: string;
  title_id: string;
  release_date: string;
  timestamp: number;
  syncstamp: number;
  created_at: string;
  updated_at: string;

  color?: string;
  note?: string;
  release?: string;
};

export type PostishCopyAnnotationSnapshotResponse = PostishCopyAnnotationSnapshot[];
