import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "container",
    class: _normalizeClass({ [_ctx.$style.full]: _ctx.fullHeight, [_ctx.$style.container]: true })
  }, [
    (!_ctx.fullHeight)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(_ctx.$style.showAll),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showAll && _ctx.showAll(...args)))
        }, _toDisplayString(_ctx.$t('title.showAll')), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.text)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}