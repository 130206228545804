<template>
  <Teleport
    :to="teleportTo"
  >
    <transition
      :enterActiveClass="`${$style.enter} ${$style[transition]}`"
      :leaveActiveClass="`${$style.leaveTo} ${$style[transition]}`"
    >
      <div
        v-if="show"
        ref="parent"
        :class="$style.overlay"
      >
        <component
          :is="escapeDeactivates ? 'button' : 'div'"
          :class="$style.shield"
          :aria-label="$t('general.close')"
          @click="close"
        />
        <FocusTrap
          :key="header"
          :initialFocus="initialFocus"
          :escapeDeactivates="escapeDeactivates"
          @deactivate="close"
        >
          <div
            :id="id"
            :class="[$style.content, contentClass]"
            :role="type"
            aria-modal="true"
            :aria-label="header"
            :aria-describedby="describedBy"
          >
            <slot></slot>
          </div>
        </FocusTrap>
      </div>
    </transition>
  </Teleport>
</template>

<script lang="ts">
import FocusTrap from 'app/components/FocusTrap.vue';
import { useModalDialog } from 'app/functions/use-modal-dialog';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue';

export default defineComponent({
  name: 'Overlay',
  components: {
    FocusTrap
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    transition: {
      type: String,
      default: 'none'
    },
    header: {
      type: String,
      default: undefined
    },
    describedBy: {
      type: String,
      default: undefined
    },
    initialFocus: {
      type: String,
      default: undefined
    },
    type: {
      type: String as () => 'dialog' | 'alertdialog',
      default: 'dialog'
    },
    teleportTo: {
      type: String,
      default: 'body'
    },
    contentClass: {
      type: String,
      default: undefined
    },
    escapeDeactivates: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx) => {
    const id = `overlay-${props.header?.replace(/[^a-zA-Z0-9]/g, '-')}-${generateUUID()}`;
    const parent = ref<HTMLElement | null>(null);

    const close = () => {
      ctx.emit('close');
    };

    onBeforeUnmount(() => {
      ctx.emit('close');
    });

    useModalDialog(computed(() => props.show), id);

    return {
      close,
      id,
      parent
    };
  }
});
</script>

<style lang="less" module>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.shield {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0.2, 0.2, 0.2, 0.2);
  transition: opacity 0.5s;
}

.content {
  position: absolute;

  > * {
    will-change: transform;
    transition: transform 300ms ease, opacity 300ms ease;
  }
}

.enter, .leave-to {
  .shield {
    opacity: 0;
  }
}

.leave-to:not(.none) {
  transition: transform 300ms ease, opacity 300ms ease;
}

.right {
  .content > * {
    transform: translateX(100%);
  }
}

.left {
  .content > * {
    transform: translateX(-100%);
  }
}

.down {
  .content > * {
    transform: translateY(-100%);
  }
}

.up {
  .content > * {
    transform: translateY(100%);
  }
}

.up-gentle {
  .content > * {
    transform: translateY(10%);
    opacity: 0;
  }
}

.spin {
  .content > * {
    transform: translateX(100%) rotateZ(180deg);
  }
}

.stall {
  .content > * {
    transform: translateY(0);
  }
}

.fade {
  .content > * {
    transform: translateY(0);
    opacity: 0;
  }
}
</style>
