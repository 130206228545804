<template>
  <button
    type="submit"
    :disabled="!enabled"
    :class="{
      [$style.button]: true,
      [$style[size]]: true,
      [$style.disabled]: !enabled
    }"
  >
    <span v-if="!loading">
      {{ label }}
    </span>
    <span
      v-else
      aria-live="polite"
    >
      {{ loadingText || $t('general.loading') }}
    </span>
  </button>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormSubmitButton',
  props: {
    label: {
      type: String,
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    },
    size: {
      type: String as () => 'large' | 'small',
      default: 'large'
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: undefined
    }
  }
});
</script>

<style module>
.button {
  color: var(--c-white);
  background-color: var(--c-primary-red);
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  font-weight: var(--fw-bold);
  border-radius: var(--form-border-radius);
  padding: 1rem;
  transition: opacity 150ms;
}

.button.large {
  width: 100%;
}

.button.small {
  padding: 0.5rem;
}

.button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
