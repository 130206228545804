import { C, Dictionary } from 'app/base/common';
import { Bank } from './bank';

export class BankScopeMemory implements Bank {
  protected _name: string;
  protected _flush: { keys: string[]; timer: any };
  protected _data: Dictionary<any>;
  protected _frozen: boolean;

  public static COMMIT_DELAY_MS = 0;


  constructor(name: string, data?: any) {
    this._name = name;
    this._flush = { keys: [], timer: null };
    this._data = data || this._read();
    this._frozen = false;
  }


  public get<T = any>(key: string): T {
    return this._data[key];
  }


  public getData() {
    return this._data;
  }


  public set<T>(key: string, value: T) {
    if (this._frozen) {
      console.log('[BANK] Bank is frozen - discarding update of: ' + key);

      return;
    }
    if (typeof value === 'undefined' || value === null) {
      delete this._data[key];
    } else {
      this._data[key] = value;
    }
    this._write(key);
  }


  public dump(key: string, object: any) {
    this.set(key, object.serialize());
  }


  public clear(key: string) {
    this.set(key, null);
  }


  public flush() {
    if (this._frozen) {
      if (this._flush.keys.length > 0) {
        console.log(
          '[BANK] Bank is frozen - discarding flush of: ' +
            this._flush.keys.join(',')
        );
      }
    } else {
      this._commit();
    }
    this._flush.keys = [];
    this._flush.timer = null;
  }


  public wipeAllExcept(excludeKeys?: string[]) {
    const wipeAll = !(excludeKeys && excludeKeys.length);
    C.each(this._data, (key, val) => {
      if (wipeAll || excludeKeys!.indexOf(key) === -1) {
        this.set(key, null);
      }
    });
    this.flush();
  }


  public wipe() {
    this.wipeAllExcept();
  }


  public freeze() {
    this._frozen = true;
  }


  /**
   * Implement in sub-classes: generally this should load data
   * from source into memory. May be called multiple times over
   * the lifespan of the bank.
   */
  protected _read() {
    return {};
  }


  /**
   * Implement in sub-classes: generally this should write data
   * from memory to the source.
   */
  protected _commit() {
    return;
  }


  protected _write(key: string) {
    if (this._flush.keys.indexOf(key) < 0) {
      this._flush.keys.push(key);
    }
    this._flush.timer =
      this._flush.timer ||
      setTimeout(this.flush.bind(this), BankScopeMemory.COMMIT_DELAY_MS);
  }


  protected _eachFlushKey(fn: (key: string) => void) {
    C.each(this._flush.keys, (key: string) => fn(key));
  }
}
