import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import env from 'app/base/env';
import { EventHandler, EventHandlerDictionary } from '../event-map';
import { Version } from './version';

export abstract class Roster {
  public updating: VersionUpdate | null = null;
  public entries: Dictionary<RosterDocumentEntry> = {};
  public version: Version = new Version();
  public targetVersion: Version = new Version();
  public sizeInBytes: { total: number; progress: number } = { total: 0, progress: 0 };
  public finalized = false;
  public doc: RosterDocument | null = null;

  private _downloadState: Dictionary<{ percent: number; bytes: number }> = {};
  private _handlers: RosterHandlers | null = null;

  public static DEFAULT_ENTRY_BYTES = 1000;


  constructor() {
    this._reset();
  }


  public assign(rosterDoc: RosterDocument): void {
    if (!rosterDoc) {
      console.warn('[ROSTER] cannot assign empty doc');

      return;
    }
    if (this._isDifferentDocument(rosterDoc)) {
      console.log('[ROSTER] %s = %s', rosterDoc.id, rosterDoc.version);
      this._reset();
      this.version.assign(rosterDoc.version);
    }
    this.doc = rosterDoc;
    this.doc.role = this.doc.role || 'content';
    this.finalized = this.doc.health === 'valid';
    this.sizeInBytes = { total: 0, progress: 0 };
    C.each(
      this.doc.entries,
      (docEntry) => {
        let url = docEntry.url;
        if (!url.match(/^https?:/)) {
          url = env.ROOT_URI + url;
        }
        const entry = (this.entries[url] = C.absorb(docEntry, {}));
        entry.url = url;
        entry.bytes = entry.bytes || Roster.DEFAULT_ENTRY_BYTES;
        this.sizeInBytes.total += entry.bytes;
      }
    );
  }


  public wipe(): void {
    if (this.doc) {
      console.log('[ROSTER] %s **WIPE**', this.doc.id);
      APP.shell.transmit({ name: 'roster:wipe', roster: this.doc });
    }
    this._reset();
  }


  public initializeUpdate(newRosterDoc: RosterDocument, forceUpdate?: boolean): void {
    this.assign(newRosterDoc);
    if (!this.doc || (this.finalized && !forceUpdate)) {
      return;
    }
    this._triggerUpdate(newRosterDoc.version);
    console.log('[ROSTER] %s initialize', this.doc.id);
    this._downloadState = {};
    this._handlers = {
      progress: APP.events.on(
        'msg:roster:entry:progress',
        (evt) => this._onEntryProgress(evt.m.roster, evt.m.entry, evt.m.progress)),
      response: APP.events.on(
        'msg:roster:entry:response',
        (evt) => this._onEntryResponse(evt.m.roster, evt.m.entry, evt.m.response.status)),
      finalize: APP.events.on('msg:roster:finalize', (evt) => this._onFinalize(evt.m.roster)),
      error: APP.events.on('msg:roster:error', (_) => this._onError())
    };
    const entries: (RosterDocumentEntry & { request: RosterDocumentEntryRequest })[] = [];
    C.each(
      this.entries,
      (url, entry) => {
        const reqAttr = { request: this._requestAttributesForEntry(entry) };
        entries.push(C.absorb(entry, reqAttr));
      }
    );
    APP.shell.transmit({
      name: 'roster:initialize',
      roster: C.absorb({ entries: entries }, this.doc)
    });
    this.doc.health = 'pending';
    this._updateInitialized();
  }


  public invalidateUpdate(): void {
    this.updating = null;
    this.finalized = false;
    if (this.doc) {
      this.doc.health = 'invalid';
    }

    this._deafen();
  }


  public registerCallbacks(callbacks: Dictionary<Function>): void {
    // Override in subclasses if desired.
  }


  public ensureAllCoversRostered(): void {
    // Implement in subclasses
  }


  public cycle(targetVersion: Version): void {
    // Implement in subclasses
  }


  protected _reset(): void {
    this._deafen();
    this.doc = null;
    this.version = new Version();
    this.finalized = false;
    this.entries = {};
    this.sizeInBytes = { total: 0, progress: 0 };
  }


  protected _triggerUpdate(newRosterVersion: string): void {
    this.finalized = false;
    if (this.updating && this.updating.to !== newRosterVersion) {
      console.warn('[ROSTER] update mismatch', this.updating, newRosterVersion);
      this.updating = null;
    }
    this.updating = this.updating || {
      from: this.version.value!,
      to: newRosterVersion,
      at: C.epochMilliseconds()
    };
  }


  protected _onEntryProgress(roster: RosterDocument, entryResponse: RosterDocumentEntry, progress: number): void {
    if (!this._eventIsForThisRoster(roster)) {
      return;
    }
    if (entryResponse && entryResponse.url && progress) {
      const entry = this._entryForURL(entryResponse.url);
      if (entry) {
        this._downloadState[entry.url] = {
          percent: progress,
          bytes: progress * entry.bytes
        };
        this._calculateProgress();
      }
    }
  }


  protected _onEntryResponse(roster: RosterDocument, entryResponse: RosterDocumentEntry, code: number): void {
    if (!this.doc || !this._eventIsForThisRoster(roster)) {
      return;
    }
    const entry = this._entryForURL(entryResponse.url);
    if (!entry) {
      console.log(
        'Entry not found for %s - %o',
        entryResponse.url,
        this.doc.entries
      );

      return;
    }
    if (this.finalized) {
      console.warn(
        '[ROSTER] %s cannot acknowledge entry:response (finalized roster):',
        this.doc.id,
        entry.url
      );

      return;
    }
    if ((entry && (code >= 200 && code <= 300)) || code === 410) {
      this._onEntryFetched(entry);
    } else {
      this._onEntryFailed(entry);
    }
  }


  protected _onEntryFetched(entry: RosterDocumentEntry): void {
    if (!this.doc) {
      return;
    }

    console.log('[ROSTER] %s fetched: %s', this.doc.id, entry.url);
    this._downloadState[entry.url] = { percent: 1, bytes: entry.bytes };
    this._calculateProgress();
  }


  protected _onEntryFailed(entry: RosterDocumentEntry): void {
    console.warn(
      '[ROSTER] %s fetch failed: %s',
      this.doc ? this.doc.id : 'MISSING',
      entry ? entry.url : '--'
    );
    this.invalidateUpdate();
    this._updateInvalidated();
  }


  protected _onError(): void {
    if (this.updating) {
      this.invalidateUpdate();
      this._updateInvalidated();
    }
  }


  protected _onFinalize(roster: RosterDocument): void {
    if (!this.doc || !this._eventIsForThisRoster(roster)) {
      return;
    }
    console.log('[ROSTER] %s finalize', this.doc.id);
    this.finalized = true;
    this.doc.health = 'valid';
    this._deafen();
    this._updateFinalized();
    this.updating = null;
  }


  protected _deafen(): void {
    APP.events.off(this._handlers);
    this._handlers = null;
  }


  protected _eventIsForThisRoster(edoc: RosterDocument): boolean {
    return !!this.doc && (edoc.id === this.doc.id && edoc.version === this.doc.version);
  }


  protected _calculateProgress(): void {
    let bytes = 0;
    C.each(this._downloadState, (url, state) => {
      bytes += state.bytes;
    });
    bytes = Math.min(bytes, this.sizeInBytes.total);
    if (bytes !== this.sizeInBytes.progress) {
      this.sizeInBytes.progress = bytes;
      this._updateProgress();
    }
  }


  protected _updateInitialized(): void {
    // Override in subclasses if desired.
  }


  protected _updateProgress(): void {
    // Override in subclasses if desired.
  }


  protected _updateFinalized(): void {
    // Override in subclasses if desired.
  }


  protected _updateInvalidated(): void {
    // Override in subclasses if desired.
  }


  /**
   *  You can set the following request attributes:
   *   url - a string
   *   method - "GET" / "POST"
   *   headers - a hash of strings
   *   body - a string
   * @param entry
   */
  protected _requestAttributesForEntry(entry: RosterDocumentEntry): RosterDocumentEntryRequest {
    const attrs: RosterDocumentEntryRequest = entry.request || { url: entry.url, method: entry.method || 'GET', headers: {} };
    attrs.url = attrs.url || entry.url;
    attrs.method = attrs.method || entry.method || 'GET';
    if (attrs.method === 'POST') {
      if (!attrs.headers) {
        attrs.headers = {};
      }
      if (!attrs.headers['Content-Type']) {
        attrs.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      }
    }

    return attrs;
  }


  protected _entryForURL(url: string): RosterDocumentEntry {
    return (
      this.entries[url] ||
      // TMP: this is because Android changes the URL. Fix it in Android.
      this.entries[encodeURI(url)]
    );
  }


  /**
   * Optionally override in subclasses.
   * @param rosterDoc
   */
  protected _isDifferentDocument(rosterDoc: RosterDocument): boolean {
    return rosterDoc.version !== this.version.value;
  }
}

interface RosterHandlers extends EventHandlerDictionary {
  progress: EventHandler<'msg:roster:entry:progress'>;
  response: EventHandler<'msg:roster:entry:response'>;
  finalize: EventHandler<'msg:roster:finalize'>;
  error: EventHandler<'msg:roster:error'>;
}

export interface RosterDocument {
  id: string;
  version: string;
  entries: RosterDocumentEntry[];
  role: 'content' | 'system';
  group?: 'covers';
  health?: 'valid' | 'pending' | 'invalid';
}

export interface RosterDocumentEntry {
  url: string;
  bytes: number;
  method?: 'GET' | 'POST';
  request?: RosterDocumentEntryRequest;
}

interface RosterDocumentEntryRequest {
  url: string;
  method: 'GET' | 'POST';
  headers: Dictionary<string>;
  body?: string;
}

interface VersionUpdate {
  from: string;
  to: string;
  at: number;
}
