import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "visually-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyStatusBanner = _resolveComponent("CopyStatusBanner")!
  const _component_DynamicExpander = _resolveComponent("DynamicExpander")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.statusBlock)
  }, [
    _createElementVNode("h2", {
      ref: "header",
      tabIndex: -1,
      class: "visually-hidden"
    }, _toDisplayString(_ctx.$t('annotationCopying.banner.header')), 513),
    (_openBlock(), _createBlock(_component_DynamicExpander, {
      key: _ctx.numInProgress,
      numElements: _ctx.filteredJobs.length || 0,
      panelId: `copy-status-panel-${_ctx.titleSlug}`,
      headerId: _ctx.titleSlug
    }, {
      default: _withCtx(() => [
        (_ctx.copyState.status === 'loaded' && _ctx.filteredJobs.length)
          ? (_openBlock(), _createBlock(_TransitionGroup, {
              key: 0,
              tag: "ol",
              role: "list",
              name: "list",
              enterFromClass: _ctx.$style.listEnter,
              enterActiveClass: _ctx.$style.listEnterActive,
              leaveActiveClass: _ctx.$style.listLeaveActive,
              leaveToClass: _ctx.$style.listLeaveTo
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredJobs, (status) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: status.id,
                    class: _normalizeClass(_ctx.$style.listItem)
                  }, [
                    _createVNode(_component_CopyStatusBanner, {
                      status: status.status,
                      source: status.source_title,
                      target: status.target_title,
                      onUpdate: (val) => _ctx.update(status.id, val)
                    }, null, 8, ["status", "source", "target", "onUpdate"])
                  ], 2))
                }), 128))
              ], undefined, true),
              _: 1
            }, 8, ["enterFromClass", "enterActiveClass", "leaveActiveClass", "leaveToClass"]))
          : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('annotationCopying.banner.empty')), 1))
      ], undefined, true),
      _: 1
    }, 8, ["numElements", "panelId", "headerId"]))
  ], 2))
}