

  export function prefixProperty(style: CSSStyleDeclaration, property: string, value?: string) {
    const prefixes = ['-webkit-', '-moz-', '-ms-', ''];
    if (typeof value === 'undefined' || value === null) {
      while (prefixes.length) {
        style.removeProperty(prefixes.shift()! + property);
      }
    } else {
      while (prefixes.length) {
        style.setProperty(prefixes.shift()! + property, value);
      }
    }
  }

