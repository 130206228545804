<template>
  <BrowseGroup
    :name="name"
    :description="description"
    :buttonLabel="buttonLabel"
    :navigateLink="navigateLink"
    :class="{
      [$style.hide]: searchState.state === 'success' && !items.length
    }"
    :aria-label="$t('browse.collection', { NAME: name })"
  >
    <Icon
      v-if="searchState.state === 'loading'"
      name="spinner"
      :class="$style.loading"
      :aria-label="$t('general.loading')"
    />
    <ul
      v-else
      :class="$style.content"
    >
      <li
        v-for="item in items"
        :key="item.id"
      >
        <TitleCard
          v-if="searchType === 'title'"
          :title="item"
        />
        <SeriesCard
          v-else
          :series="item"
        />
      </li>
    </ul>
  </BrowseGroup>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import { SearchDefinition, SearchRequest, SearchType } from 'app/base/search';
import BrowseGroup from 'app/components/BrowseGroup.vue';
import SeriesCard from 'app/components/cards/SeriesCard.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import { useI18n } from 'app/functions/use-i18n';
import { useSearch } from 'app/functions/use-search';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BrowseGroupCollection',
  components: {
    BrowseGroup,
    SeriesCard,
    TitleCard
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: undefined
    },
    searchType: {
      type: String as PropType<SearchType.Title | SearchType.Series>,
      required: true
    },
    definition: {
      type: Object as PropType<SearchDefinition>,
      required: true
    }
  },
  setup: (props, ctx) => {
    const { windowWidth } = useWindowSize();

    const request = computed<SearchRequest<typeof props.searchType>>(() => {
      return {
        libraryKey: APP.library.key(),
        searchType: props.searchType,
        definition: props.definition,
        modifications: {
          page: 1,
          perPage: 6
        }
      };
    });

    const { state: searchState } = useSearch(request);

    const items = computed(() => {
      if (searchState.value?.state === 'success') {
        return windowWidth.value > Breakpoint.Tablet
          ? searchState.value.response.list.items
          : searchState.value.response.list.items.slice(0, 3);
      }

      return [];
    });

    const { t } = useI18n();

    const buttonLabel = computed(() => {
      return searchState.value?.state === 'success'
        ? t(`browse.seeAll.${props.searchType}`, { N: searchState.value.response.list.totalItems })
        : t('browse.seeAll.generic');
    });

    const navigateLink = computed(() => ({
      name: RouteName.Collection,
      params: {
        collectionId: props.collectionId
      }
    }));

    return {
      buttonLabel,
      navigateLink,
      searchState,
      items
    };
  }
});
</script>

<style module>

.hide {
  display: none;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

.content {
  composes: list-grid from global;
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 3;
  --grid-item-min-width: var(--px-card-min-width);
}

@media screen and (max-width: 499px /* px-vp-very-narrow */) {
  .content {
    --grid-layout-gap: 0;
    grid-row-gap: 1.5rem;
  }
}

</style>
