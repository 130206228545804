import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { IssuableEventId } from '../base/event-map';
import { Item } from './item';

export class Collation<T extends Item | Collation<Item>> {
  /**
   * You MUST supply in subclasses
   */
  protected ITEM_CLASS: any = null;
  /**
   * You MUST rename in subclasses
   */
  protected ITEM_NAME = 'item';
  protected BANK_SUFFIX = ':all';
  protected SAVE_DELAY = 200;
  protected PERSISTENT = true;
  protected ANNOUNCE = true;
  protected SERIAL_PROPERTY = 'v';
  protected SERIAL_VERSION = 1;
  /**
   * Any persisted collation with a smaller version than this
   * will be discarded, not deserialized:
   */
  protected SERIAL_VERSION_MIN = 1;
  private _saveTimer: number;
  public all: T[] = [];
  public slug: string;
  public pauseRecordingTime: number;
  public removedFromCollation: boolean;


  public load() {
    if (this.PERSISTENT) {
      this.deserialize(APP.bank.get(this._bankName()));
    }
    this._announce('load:all', { collation: this, all: this.all });
  }


  /**
   * Save this collation
   * @param {boolean} [isSilent=false] If true, no events will be announced on save
   */
  public save(isSilent?: boolean) {
    if (this.PERSISTENT) {
      clearTimeout(this._saveTimer);
      this._saveTimer = window.setTimeout(() => this._saveNow(isSilent), this.SAVE_DELAY);
    }
  }


  public find(itemQuery): T {
    return this.filter(itemQuery, 1)[0];
  }


  public filter(itemQuery, limit?: number): T[] {
    if (!itemQuery) {
      return this.all.slice(0, limit || undefined);
    }

    const out: T[] = [];
    for (let i = 0, ii = this.all.length; i < ii; ++i) {
      let match = true;
      for (const attr in itemQuery) {
        const properties = attr.split('.');
        let value = this.all[i];
        while (properties.length) {
          const prop = properties.shift();
          value = value ? value[prop] : null;
        }

        if (itemQuery[attr] instanceof Function) {
          match = itemQuery[attr](value);
        } else if (itemQuery[attr] instanceof Array) {
          if (itemQuery[attr].indexOf(value) < 0) {
            match = false;
          }
        } else {
          if (value !== itemQuery[attr]) {
            match = false;
          }
        }
        if (!match) {
          break;
        }
      }
      if (match) {
        out.push(this.all[i]);
        if (limit && out.length >= limit) {
          break;
        }
      }
    }

    return out;
  }


  public stub(itemAttributes): T {
    const p = this.ITEM_CLASS;
    itemAttributes[p.SERIAL_PROPERTY] = p.SERIAL_VERSION;

    return this.make(itemAttributes, true);
  }


  public make(itemAttributes, stubbed = false): T {
    const item = this._spawn(itemAttributes);
    if (item) {
      this._makingItem(item, itemAttributes, stubbed);
      this.all.push(item);
      this._announce('add', { item: item });
      this.save();

      return item;
    }

    console.warn('[COLLATION] could not make item with:', itemAttributes);
  }


  public remove(itemQuery): void {
    const item = this.find(itemQuery);
    if (item) {
      this.removeItem(item);
    } else {
      console.warn(
        '[COLLATION] Could not remove missing',
        this.ITEM_NAME,
        itemQuery
      );
    }
  }


  public removeItem(item: T): void {
    C.excise(this.all, item);
    item.removedFromCollation = true;
    item.removed();
    this._announce('remove', { item: item });
    this.save();
  }


  public removeAll(): void {
    if (this.all.length > 0) {
      this.all.splice(0);
      this.save();
    }
  }


  /**
   * Override in subclasses if you need to do something
   * when the collation is removed from another collation
   */
  public removed() {
    return;
  }


  /**
   * Will create or update all items to the given attributes, deleting
   * any items that are not matched. Returns the deleted items.
   * @param itemsAttributes
   */
  public sync(itemsAttributes): T[] {
    const items = this.subsume(itemsAttributes);
    const deletions: T[] = [];
    C.each(
      this.all,
      (item) => {
        if (items.indexOf(item) < 0) {
          deletions.push(item);
        }
      }
    );
    C.each(deletions, this.removeItem.bind(this));
    this.save();

    return deletions;
  }


  /**
   * Will update any items that match an identifier to the given
   * attributes, and create missing items, but does not delete
   * unmatched items. Returns the updated and created items.
   * @param itemsAttributes
   */
  public subsume(itemsAttributes: any[]): T[] {
    const items: T[] = [];
    C.each(
      itemsAttributes,
      (itemAttributes) => {
        const itemQuery = this._fullAttributesToQuery(itemAttributes);
        let item = this.find(itemQuery);
        if (item) {
          item.deserialize(itemAttributes);
        } else {
          item = this.make(itemAttributes);
        }
        items.push(item);
      }
    );
    this.save();

    return items;
  }


  public serialize() {
    const collationAttributes = this._serializeAttributes();
    collationAttributes.all = this._serializeItems();

    return collationAttributes;
  }


  public deserialize(collationAttributes) {
    const normalizedAttrs = this._normalizeAttributes(collationAttributes || {});
    this._deserializeAttributes(normalizedAttrs);
    this._deserializeItems(normalizedAttrs.all || []);

    return this;
  }


  protected _spawn(itemAttributes) {
    const item = new this.ITEM_CLASS();
    if (itemAttributes) {
      item.deserialize(itemAttributes);
    }
    item.collation = this;

    return item;
  }


  protected _bankName() {
    return this.ITEM_NAME + this.BANK_SUFFIX;
  }


  protected _saveNow(isSilent?: boolean) {
    if (this.PERSISTENT) {
      APP.bank.dump(this._bankName(), this);
    }
    if (!isSilent) {
      this._announce('update:all', { collation: this.ITEM_NAME, all: this.all });
    }
  }


  /**
   * Optionally implement in subclasses:
   * Called when spawning a new item into the collation
   * (and not called when re-spawning a persisted item).
   * @param {T} item
   * @param {any} itemAttributes
   * @param {boolean} stubbed Created by hand (i.e. not loaded from bank or remote)
   */
  protected _makingItem(item: T, itemAttributes: any, stubbed: boolean) {
    return;
  }


  /**
   * Optionally re-implement in subclasses
   */
  protected _serializeAttributes(): any {
    const out = {};
    out[this.SERIAL_PROPERTY] = this.SERIAL_VERSION;

    return out;
  }


  /**
   * Optionally re-implement in subclasses
   */
  protected _serializeItems() {
    const all = [];
    C.each(this.all, (item) => {
      if (item && typeof item.serialize === 'function') {
        all.push(item.serialize());
      }
    });

    return all;
  }


  /**
   * Optionally re-implement in subclasses
   */
  protected _deserializeAttributes(attrs) {
    if (attrs[this.SERIAL_PROPERTY] < this.SERIAL_VERSION_MIN) {
      for (const key in attrs) {
        delete attrs[key];
      }
    }
  }


  protected _normalizeAttributes(attrs) {
    if (attrs[this.SERIAL_PROPERTY]) {
      return C.absorb(attrs, {});
    }

    return this._transformRemoteAttributes(attrs);
  }


  /**
   * You should override in subclasses to take a thunder response object
   * and convert it into an object that can be deserialized.
   * @param attrs
   */
  protected _transformRemoteAttributes(attrs) {
    return attrs;
  }


  /**
   * Optionally re-implement in subclasses
   */
  protected _deserializeItems(itemsAttributes: any[]) {
    C.each(
      itemsAttributes,
      (itemAttributes) => {
        const item = this._spawn(itemAttributes);
        if (item) {
          this.all.push(item);
        }
      }
    );
  }


  /**
   * You should consider reimplementing this in subclasses that
   * use sync/subsume:
   * @param itemAttributes
   */
  protected _fullAttributesToQuery(itemAttributes): any {
    return { id: itemAttributes.id };
  }


  protected _announce(evtSubtype, detail) {
    if (this.ANNOUNCE) {
      APP.events.dispatch(<IssuableEventId>`${this.ITEM_NAME}:${evtSubtype}`, detail);
    }
  }
}
