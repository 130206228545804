import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import env from 'app/base/env';


/**
 * Scribe is responsibile for sending user activity to the
 * readiverse so it can be persisted across sessions/devices.
 *
 * Activities that need to be tracked should call the Scribe.publish
 * method.  Scribe will queue that activity and send it to the server
 * in batches.  Queued activities are also written to the bank in case
 * the user is offline and should be sent as soon as they re-establish
 * a network connection.
 *
 * The activity is sent to elrond server, which then sends the activity
 * to rabbit.  Various services process the different types of activity
 * and will be used to sync data.
 *
 */

export class Scribe {
  private readonly _bankKey = 'activity:queues';
  private readonly _queues: {
    pending: ActivityEntry[];
    sending: ActivityEntry[];
    failed: ActivityEntry[];
  };

  private readonly _maxBatchSize = 20;
  private _nextBatchSize = 0;

  private readonly _trySendToServer: () => Promise<void> | undefined;
  private readonly _isSendingPaused: () => boolean;
  private readonly _pauseSending: () => void;
  private readonly _resumeSending: () => Promise<void> | undefined;

  private _lastSuccess = 0;
  private _failureTimer = 0;


  constructor() {
    const bankedQueues = APP.bank.get(this._bankKey) || {};
    const pendingQueue = bankedQueues.pending || [];
    const sendingQueue = bankedQueues.sending || [];
    const failedQueue = bankedQueues.failed || [];
    // We don't actually care about the sending queue anymore, but including it here for backwards compatibility
    this._queues = {
      pending: [...sendingQueue, ...pendingQueue, ...failedQueue],
      sending: [],
      failed: []
    };

    this._nextBatchSize = this._maxBatchSize;

    // Prevent multiple sends to server running at the same time and messing with the bank
    const locked = C.withLock(this._sendToServer.bind(this));

    // Add the ability to temporarily block sends to the server when addressing transient issues
    const { blockedFunction, isBlocked, block, unblock } = C.withBlocker(locked);

    // Debounce the send to server calls in case of many messages coming in at once.
    const debounced = C.debounce(blockedFunction, 1.2 * C.MS_SECOND);

    this._trySendToServer = debounced;
    this._isSendingPaused = isBlocked;
    this._pauseSending = () => {
      block();

      setTimeout(() => {
        if (APP.network.reachable) { this._resumeSending(); }
      }, C.MS_MINUTE);
    };
    this._resumeSending = () => {
      unblock();

      return this._trySendToServer();
    };

    // TODO: Should we keep this?  If so should we pass the route data for
    //  the Google Analytic like data some want captured???
    APP.events.on('router:navigate', (_) => this._trySendToServer());

    // Could be getting the message when losing network so we shouldn't try
    // to send
    APP.events.on('network:info', (_) => {
      const reachable = APP.network.reachable;
      if (reachable) {
        unblock();
        this._trySendToServer();
      } else {
        block();
      }
    });

    APP.events.on('patron:accountId:acquired', (_) => {
      this._trySendToServer();
    });

    // If the bank contained any activities at startup, try sending them
    if (this._queues.pending.length > 0) {
      this._trySendToServer();
    }
  }


  /**
   * Publish user's elrond activity to the server
   * @param label activity key (e.g. tag.add)
   * @param data activity specific data
   */
  public publish<T>(label: string, data: T & { syncstamp?: number }): void {
    if (!data.syncstamp) {
      data.syncstamp = C.epochMilliseconds();
    }
    this._queues.pending.push({
      label: label,
      data: C.jsonClone(data)
    });
    this._saveQueuesToBank();


    this._trySendToServer();
  }


  // If the send to server process is not running start it and wait for it to finish
  public async sendOnLogout() {
    this._queues.pending = [...this._queues.pending, ...this._queues.failed];
    await this._resumeSending();
  }


  private async _sendToServer(): Promise<void> {

    while (!this._isSendingPaused() && this._queues.pending.length > 0 && (APP.patron && APP.patron.accountId)) {
      const batch = this._queues.pending.splice(0, this._nextBatchSize);

      const toRequeue = await this._sendBatchToServer(batch);

      this._queues.pending = [...toRequeue, ...this._queues.pending];

      this._saveQueuesToBank();
    }
  }


  // Attempt to send batch of activities to the server.
  // Returns activities that need to be requeued.
  private async _sendBatchToServer(batch: ActivityEntry[]) {

    const body = JSON.stringify({
      env: this._env(),
      acts: batch
    });

    try {
      const scribeResponse = await APP.services.elrond.fetchAsync<ScribeResponse>({
        url: 'inscribe/activities',
        method: 'POST',
        body: body,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${APP.sentry.identityToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (scribeResponse?.success) {
        this._nextBatchSize = this._maxBatchSize;
        this._lastSuccess = C.epochMilliseconds();

        // Temporary: log all successfully sent annotations to Sage
        // Ignore non-annotation activities to lessen the clutter
        const annotationMessageLabel = /^annotations\..+$/;
        const toSendToSage = batch.filter((act, index) =>
          annotationMessageLabel.test(act.label) &&
          !scribeResponse.failed.includes(index)
        );

        toSendToSage.forEach((act) => {
          this._logAnnotationActivity(act, 'SCRIBE: '+act.label, 'scribe#_sendBatchToServer');
        });
      }

      this._evaluateFailedMessages(batch, scribeResponse);

      return [];
    } catch (err) {
      console.warn('Scribe did not accept activity.', err);

      return this._onSendError(batch, err);
    }
  }


  // Handle HTTP error when sending batch to server
  // Returns activities to be requeued
  private _onSendError(batch: ActivityEntry[], error: any) {
    const statusCode = C.parsePossibleInt(error?.status, 10);
    if (statusCode && statusCode >= 400 && statusCode < 500) {
      // If there was an error because the data was too big,
      // try splitting into a smaller batch and resending
      if (statusCode === 413 && this._nextBatchSize > 1) {
        this._nextBatchSize = Math.ceil(this._nextBatchSize / 2);

        return batch;
      }

      // A 400 error means the server understood the request but rejected it.
      // Other than the 413 case, it is unlikely we can resolve it so we discard
      // the data.
      return [];
    }

    // For non-400 errors, it is more likely a transient issue so
    // we move the data back to pending so it can be tried again
    // in the future.  Waiting a bit so if there is a transient
    // problem on the server we don't make matters worse by having
    // every user retrying sending scribe messages every second.
    this._pauseSending();

    return batch;
  }


  /**
   * This handles the case where elrond server was able to connect to rabbit
   * but it couldn't publish at least some of the activities.
   *
   * This should be a pretty rare case, but will requeue just those activities.
   * If a given message fails multiple times and other messages are successful,
   * we can assume there is a problem with that message and we eventually give up.
   *
   * Returns activities to requeue
   */
  private _evaluateFailedMessages(batch: ActivityEntry[], data: ScribeResponse | null) {
    const failedActivities = data?.failed.map((batchIndex) => batch[batchIndex]) || batch;

    const toRequeue: ActivityEntry[] = [];
    const toDiscard: ActivityEntry[] = [];
    failedActivities.forEach((activity) => {
      activity.error = activity.error || {
        firstStamp: C.epochMilliseconds(),
        count: 0
      };

      activity.error.count = activity.error.count + 1;

      if (activity.error.count > 5 && (this._lastSuccess > activity.error.firstStamp)) {
        // We were succesfully able to send other messages but this one has continued to fail,
        // so must be a problem with this message.
        console.error('[SCRIBE] Discarding activity that we have been unable to send: ', activity);
        toDiscard.push(activity);

        return;
      }

      toRequeue.push(activity);
    });

    if (toDiscard.length > 0) {
      toDiscard.forEach((act) => {
        this._logAnnotationActivity(act, 'SCRIBE: Discarding '+act.label, 'scribe#_evaluateFailedMessages');
      });
    }

    //If messages failed to send, add them to the failed queue and set a time for them to return to pending.
    if (toRequeue.length > 0) {
      this._queueFailures(toRequeue);
    }
  }


  private _queueFailures(toRequeue: ActivityEntry[]) {
    this._queues.failed = [...this._queues.failed, ...toRequeue];
    window.clearTimeout(this._failureTimer);

    this._failureTimer = window.setTimeout(() => {
      this._queues.pending = [...this._queues.pending, ...this._queues.failed];
      this._saveQueuesToBank();
      this._trySendToServer();
      this._failureTimer = 0;
    }, C.MS_MINUTE);
  }


  // Save any queued activities to the bank now
  private _saveQueuesToBank() {
    APP.bank.set(this._bankKey, this._queues);
  }


  private _env() {
    const primaryCard = APP.patron.currentCard();

    return {
      chip: APP.sentry.primaryChip,
      acct: APP.patron.accountId,
      puid: primaryCard && primaryCard.puid,
      cli: {
        ver: APP.client.info,
        sha: env.BUILD_SHA
      },
      shell: APP.shell.info
    };
  }


  private _logAnnotationActivity(act: ActivityEntry, errorMessage: string, errorSource: string) {

    const activity = {
      message_id: act.data.message_id,
      created_by: act.data.created_by,
      timestamp: C.epochSeconds(),
      uuid: act.data.uuid,
      syncstamp: act.data.syncstamp,
      source: 'elrond',
      operation: act.label,
      recipient: 'elrond server',
      deletedUuids: act.data.deletedUuids
    };

    APP.sage.submitAnnotation({
      errorMessage: errorMessage,
      errorSource: errorSource,
      errorData: activity,
      submissionContext: {
        library: APP.library
      }
    });
  }
}

interface ScribeResponse {
  success: number;
  failed: number[];
}

interface ActivityEntry {
  label: string;
  data: any;
  error?: {
    firstStamp: number;
    count: number;
  };
}
