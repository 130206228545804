import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchThisTitleResults = _resolveComponent("SearchThisTitleResults")!

  return (_openBlock(), _createBlock(_component_SearchThisTitleResults, _mergeProps(_ctx.$attrs, {
    item: _ctx.item,
    request: _ctx.results.params,
    results: _ctx.results.state === 'loaded' ? _ctx.results.results : null,
    titles: _ctx.results.state === 'loaded' ? _ctx.results.titles : {},
    error: _ctx.results.state === 'error',
    onSearch: _ctx.onSearch,
    onPage: _ctx.onPage,
    onClose: _ctx.clear
  }), null, 16, ["item", "request", "results", "titles", "error", "onSearch", "onPage", "onClose"]))
}