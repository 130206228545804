import { APP } from 'app/base/app';
import { C } from 'app/base/common';
export class SearchHistory {
  public queries: string[];


  constructor() {
    this.queries = [];
  }


  public add(query: string) {
    C.excise(this.queries, query);
    this.queries.unshift(query);
    this._persist();
  }


  public remove(query: string) {
    if (C.excise(this.queries, query)) {
      this._persist();
    }
  }


  public reset() {
    this.queries.splice(0);
    this._persist();
  }


  public serialize() {
    return { v: 1, queries: this.queries.slice(0) };
  }


  public deserialize(attrs: { queries: string[] }) {
    this.queries = attrs ? attrs.queries : [];
    APP.events.dispatch('search-history:load:all', { all: this.queries });
  }


  protected _persist() {
    APP.bank.dump('search-history', this);
    APP.events.dispatch('search-history:update:all', { all: this.queries });
  }
}
