import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.$style.form),
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.logoContainer)
    }, [
      _createElementVNode("img", {
        src: _ctx.logoPath,
        class: _normalizeClass(_ctx.$style.logo),
        alt: "LexisNexis"
      }, null, 10, _hoisted_1)
    ], 2),
    _createElementVNode("p", {
      id: _ctx.promptId,
      class: _normalizeClass(_ctx.$style.prompt)
    }, _toDisplayString(_ctx.$t('libraryCode.prompt')), 11, _hoisted_2),
    _createVNode(_component_FormInput, {
      modelValue: _ctx.libraryCode,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.libraryCode) = $event)),
      type: "text",
      label: _ctx.$t('libraryCode.label'),
      autofocus: true,
      required: true,
      describedBy: _ctx.promptId,
      error: _ctx.codeError ? _ctx.$t(_ctx.codeError) : undefined
    }, null, 8, ["modelValue", "label", "describedBy", "error"]),
    _createVNode(_component_FormSubmitButton, {
      label: _ctx.$t('libraryCode.submit')
    }, null, 8, ["label"])
  ], 34))
}