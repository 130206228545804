import { APP } from 'app/base/app';
import { ToC, ToCResponse } from 'app/base/catfish';
import { FreshableItem } from 'app/base/services/freshable';
import { MS_DAY } from 'lib/common';

export class TableOfContents extends FreshableItem<ToCResponse> {
  public readonly titleSlug: string;

  public chapters: Chapter[];


  constructor(titleSlug: string) {
    super('toc', MS_DAY);

    this.titleSlug = titleSlug;
  }


  public arePropertiesFresh(): boolean {
    return !!this.chapters;
  }


  protected freshenCall(): Promise<ToCResponse> {
    return APP.services.catfish.getToC(this.titleSlug);
  }


  protected freshenMap(response: ToCResponse): void {
    this.chapters = response.toc.map((c) => this.mapChapter(c));
    if (APP.titleCache.get(this.titleSlug)) {
      APP.titleCache.save();
    }
  }


  protected mapChapter(chapter: ToC): Chapter {
    return {
      title: chapter.title,
      path: chapter.path,
      chapters: chapter.contents
        ? chapter.contents.map((c) => this.mapChapter(c))
        : undefined
    };
  }


  protected _serializeAttributes() {
    return {
      titleSlug: this.titleSlug,
      chapters: this.chapters
    };
  }
}


export interface Chapter {
  title: string;
  path: string;
  chapters?: Chapter[];
}
