<template>
  <Icon
    name="dl-downloading"
    :class="$style.icon"
    :style="`--offset:${progressOffset}`"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

/**
 * An progress indicator
 * @displayName ProgressCircle
 */
export default defineComponent({
  name: 'ProgressCircle',
  props: {
    /**
     * The progress value to show.
     */
    value: {
      type: Number,
      required: true
    }
  },
  setup: (props) => {
    // NOTE: 10.5 == r (radius) value from .progress-ring-circle in icon-dl-fetching.text.svg
    // When updating, also update the css variable (@progress-circumference) below
    const PROGRESS_CIRCUMFERENCE = 2 * Math.PI * 10.5; // 65.973
    const progressOffset = computed(() => {
      return PROGRESS_CIRCUMFERENCE - props.value * PROGRESS_CIRCUMFERENCE;
    });

    return {
      progressOffset
    };
  }
});
</script>

<style lang="less" module>
@import '../../../styles/config/ambience.less';

@icon-dimesions: 1.5rem;
@progress-circumference: 65.973px; // PROGRESS_CIRCUMFERENCE in script
@stroke: 1;

.icon {
  vertical-align: middle;
  height: @icon-dimesions;
  width: @icon-dimesions;
  stroke: @c-secondary;

  :global .fetching-circle {
    stroke-dasharray: @progress-circumference, @progress-circumference;
    stroke-dashoffset: var(--offset);
  }
}
</style>
