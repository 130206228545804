import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { FetchOptions } from '../server';

export class Service {
  public name: string;
  private readonly  _rewriteURL: (str: string) => string | { url: string };


  constructor(
    name: string,
    urlRewritePattern: ((path: string) => { url: string }) | string,
    urlRewriteSubstitution?: string
  ) {
    this.name = name;
    if (typeof urlRewritePattern === 'function') {
      this._rewriteURL = urlRewritePattern;
    } else {
      const urlRewriteRegExp = new RegExp(urlRewritePattern);
      this._rewriteURL = (str) => {
        return str.replace(urlRewriteRegExp, urlRewriteSubstitution!);
      };
    }
  }


  public async fetchAsync<T>(urlOrOptions: string | FetchOptions): Promise<T | null> {
    const options = APP.server.toRequestOptions(urlOrOptions);
    const rwOptions = APP.server.toRequestOptions(this._rewriteURL(options.url));
    C.absorb(rwOptions, options);

    console.log('[%s] ➜ %s', this.name, options.url);

    return APP.server.fetchAsync(options);
  }


  /**
   * Various freshen functions bind this method as the failure callback
   * if they don't have any special failure behavior. It just logs a warning
   * if you're online, or shows the offline message if you're not.
   *
   * TODO: If the failure is due to Thunder unavailability or malfunction,
   * what should we show to the user? (At the moment it's just a console log.)
   *
   * @param owner
   * @param errorObject
   */
  public onServiceFailure(owner: {}, errorObject?: {}): void {
    if (APP.network.reachable) {
      console.warn('[SERVICE] unhandled failure:', errorObject);
    }
  }


  public pathToURL(path: string) {
    const opts = APP.server.toRequestOptions(this._rewriteURL(path));

    return opts.url;
  }
}
