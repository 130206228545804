import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "aria-labelledby", "aria-expanded"]
const _hoisted_3 = {
  class: "line-clamp",
  style: {"--line-clamp":"1"}
}
const _hoisted_4 = ["id", "aria-labelledby", "aria-activedescendant"]
const _hoisted_5 = ["id", "aria-selected", "aria-label", "onClick"]
const _hoisted_6 = {
  class: "line-clamp",
  style: {"--line-clamp":"3"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.root)
  }, [
    (!_ctx.labelId && _ctx.placeholder)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          id: _ctx.fallbackLabelId,
          class: "visually-hidden"
        }, _toDisplayString(_ctx.placeholder), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      id: _ctx.buttonId,
      ref: "buttonRef",
      type: "button",
      "aria-haspopup": "listbox",
      "aria-labelledby": `${_ctx.labelId || _ctx.fallbackLabelId} ${_ctx.modelValue ? _ctx.buttonId : ''}`,
      "aria-expanded": _ctx.open,
      class: _normalizeClass(_ctx.buttonClass || _ctx.$style.button),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
      onMousedown: _withModifiers(() => {}, ["prevent"]),
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.show && _ctx.show(...args)), ["prevent"]), ["up"])),
        _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.show && _ctx.show(...args)), ["prevent"]), ["down"]))
      ]
    }, [
      _renderSlot(_ctx.$slots, "button", { text: _ctx.buttonText }, () => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.buttonText), 1),
        _renderSlot(_ctx.$slots, "countWithLabel"),
        _createVNode(_component_Icon, {
          name: "chevron-down",
          role: "presentation",
          class: _normalizeClass(_ctx.iconClass || _ctx.$style.icon)
        }, null, 8, ["class"])
      ])
    ], 42, _hoisted_2),
    _createVNode(_Transition, {
      enterFromClass: _ctx.transition.enterFrom,
      enterActiveClass: _ctx.transition.enterActive,
      leaveActiveClass: _ctx.transition.leaveActive,
      leaveToClass: _ctx.transition.leaveTo
    }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("ul", {
              key: 0,
              id: _ctx.listId,
              ref: "listRef",
              tabindex: "-1",
              role: "listbox",
              "aria-labelledby": _ctx.labelId,
              "aria-activedescendant": _ctx.activeDescendant,
              class: _normalizeClass(_ctx.listClass || _ctx.$style.list),
              onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
              onKeydown: [
                _cache[4] || (_cache[4] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.commitAndFocus && _ctx.commitAndFocus(...args)), ["prevent"]), ["enter"])),
                _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeAndFocus && _ctx.closeAndFocus(...args)), ["esc"])),
                _cache[6] || (_cache[6] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.highlightUp && _ctx.highlightUp(...args)), ["prevent"]), ["up"])),
                _cache[7] || (_cache[7] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.highlightDown && _ctx.highlightDown(...args)), ["prevent"]), ["down"])),
                _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.keydown && _ctx.keydown(...args)))
              ]
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createElementBlock("li", {
                  id: _ctx.optionElementId(option.id),
                  ref_for: true,
                  ref: _ctx.setItemRef,
                  key: option.id,
                  role: "option",
                  "aria-selected": _ctx.modelValue === option.id,
                  "aria-label": option.ariaLabel,
                  onClick: ($event: any) => (_ctx.click(option.id))
                }, [
                  _renderSlot(_ctx.$slots, `option:${option.id}`, {
                    option: option,
                    isSelected: _ctx.modelValue === option.id,
                    isHighlighted: _ctx.highlighted === option.id
                  }, () => [
                    _createElementVNode("p", {
                      class: _normalizeClass({
                [_ctx.$style.selected]: !_ctx.selectedClass && (_ctx.modelValue === option.id),
                [_ctx.$style.highlighted]: !_ctx.highlightedClass && (_ctx.highlighted === option.id),
                [_ctx.selectedClass]: _ctx.modelValue === option.id,
                [_ctx.highlightedClass]: _ctx.highlighted === option.id,
                [_ctx.optionClass]: true
              })
                    }, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(option.displayName), 1),
                      _renderSlot(_ctx.$slots, `count:${option.id}`)
                    ], 2)
                  ])
                ], 8, _hoisted_5))
              }), 128))
            ], 42, _hoisted_4))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 3
    }, 8, ["enterFromClass", "enterActiveClass", "leaveActiveClass", "leaveToClass"])
  ], 2))
}