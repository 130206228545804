<template>
  <button
    ref="tagActions"
    type="button"
    aria-haspopup="dialog"
    :class="[$style.tagActionsMenu, 'dark']"
    @click="showTagActions = true"
  >
    {{ $t('tag.actions.header') }}
    <Icon name="overflow" />
  </button>
  <PopoutDialog
    v-if="showTagActions && tagActions"
    ref="popout"
    :key="state"
    :headerLabel="headerLabel"
    :display="state"
    :reference="tagActions"
    :animate="animate"
    @close="onClose"
  >
    <template v-if="state === 'menu'">
      <div :class="$style.mainMenu">
        <button
          type="button"
          :class="[$style.tagActionButton, 'focus-outline']"
          @click="state = 'renaming'"
        >
          {{ $t('tag.actions.rename.header') }}
        </button>
        <button
          type="button"
          :class="[$style.tagActionButton, 'focus-outline']"
          @click="state = 'deleting'"
        >
          {{ $t('tag.actions.delete.header') }}
        </button>
      </div>
    </template>

    <template v-else-if="state === 'renaming'">
      <div :class="$style.actionMenu">
        <input
          :value="tagRenameVal"
          type="text"
          name="tagName"
          :class="[$style.renameInput,'focus-outline']"
          autocapitalize="off"
          autocomplete="off"
          autocorrect="off"
          :maxlength="Tag.MAX_LENGTH"
          :placeholder="$t('tag.actions.rename.placeholder')"
          :aria-label="$t('tag.actions.rename.header')"
          required
          @keydown.enter.prevent="onRenameSubmit"
          @input="updateRenameValue"
        />
        <FormError
          v-if="isInvalidRename"
          :class="$style.renameWarning"
          :contents="$t('tag.actions.rename.invalid')"
        />
        <div :class="$style.actionActions">
          <button
            :class="[$style.tagActionButton, 'focus-outline', { 'disabled': isInvalidRename }]"
            :disabled="isInvalidRename"
            @click="onRenameSubmit"
          >
            {{ $t('tag.actions.rename.confirm') }}
          </button>
          <button
            type="button"
            :class="[$style.tagActionButton, 'focus-outline']"
            @click="onCancel"
          >
            {{ $t('tag.actions.rename.cancel') }}
          </button>
        </div>
      </div>
    </template>

    <template v-else-if="state === 'deleting'">
      <div
        :class="$style.actionMenu"
      >
        <div>
          {{ $t('tag.actions.delete.warning') }}
        </div>
        <div :class="$style.actionActions">
          <button
            type="button"
            :class="[$style.tagActionButton, 'focus-outline']"
            @click="onDeleteClick"
          >
            {{ $t('tag.actions.delete.confirm') }}
          </button>
          <button
            type="button"
            :class="[$style.tagActionButton, 'focus-outline']"
            @click="onCancel"
          >
            {{ $t('tag.actions.delete.cancel') }}
          </button>
        </div>
      </div>
    </template>
  </PopoutDialog>
</template>

<script lang='ts'>
import FormError from 'app/components/FormError.vue';
import PopoutDialog from 'app/components/dialogs/PopoutDialog.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { Tag } from 'app/models/tag';
import { Tags } from 'app/models/tags';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, nextTick, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'TagActions',
  components: {
    FormError,
    PopoutDialog
  },
  props: {
    tag: {
      type: Tag,
      required: true
    }
  },
  setup: (props) => {
    const { dispatch } = useAppEvents();
    const { t } = useI18n();
    const myTagsLink = { name: RouteName.Tags };
    const showTagActions = ref<boolean>(false);
    const state = ref<'menu' | 'renaming' | 'deleting'>('menu');
    const tagActions = ref<HTMLElement | null>(null);
    const tagRenameVal = ref(props.tag.name);
    const isInvalidRename = ref(false);
    const popout = ref<InstanceType<typeof PopoutDialog> | null>(null);
    const animate = ref<boolean>(true);

    const headerLabel = computed(() => state.value === 'menu'
      ? t('tag.actions.header')
      : state.value === 'renaming'
        ? t('tag.actions.rename.header')
        : t('tag.actions.delete.header'));

    // Quirk for suggestion keyboards where v-model only updates at the end of the word
    // Using :value/@input pair to work around that
    const updateRenameValue = (renameEvent: Event) => {
      if (renameEvent.target) {
        const target = renameEvent.target as HTMLInputElement;
        tagRenameVal.value = target.value;
      } else {
        tagRenameVal.value = '';
      }

      const trimmedVal = tagRenameVal.value.trim();
      if (!Tags.SAME_NAME(trimmedVal, props.tag.name) && !Tag.isValid(trimmedVal)) {
        isInvalidRename.value = true;
      } else {
        isInvalidRename.value = false;
      }
    };

    const router = useRouter();
    const onRenameSubmit = () => {

      if (isInvalidRename.value) {
        return;
      }

      const trimmedVal = tagRenameVal.value.trim();

      if (props.tag.name === trimmedVal) {
        animate.value = true;
        popout.value?.closeDialog();

        return;
      }

      props.tag.rename(trimmedVal);
      router.replace({ name: RouteName.TagDetails, params: {
        tagSlug: props.tag.slug
      }});

      announceMessage(t('tag.actions.rename.success', { name: trimmedVal }));
    };

    const onDeleteClick = () => {
      props.tag.delete();
      dispatch('toast', { type: 'success', message: t('tag.actions.delete.success') });
      router.push({ name: RouteName.Tags });
    };

    const onCancel = () => {
      tagRenameVal.value = props.tag.name;
      isInvalidRename.value = false;
      state.value = 'menu';
    };

    const onClose = async () => {
      showTagActions.value = false;
      onCancel();
      // reset back to true
      await nextTick();
      animate.value = true;
    };

    watch(() => state.value, () => {
      animate.value = false;
    });

    return {
      state,
      tagRenameVal,
      isInvalidRename,
      showTagActions,
      myTagsLink,
      Tag,
      headerLabel,
      tagActions,
      popout,
      animate,

      onDeleteClick,
      onClose,
      onRenameSubmit,
      updateRenameValue,
      onCancel
    };
  }
});
</script>

<style module>

  .tag-actions-menu {
    display: flex;
    padding: 0.25rem;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  .tag-actions-menu svg {
    fill: var(--c-white);
    width: 1.5rem;
    height: 1.5rem;
  }

  .tag-action-button {
    display: block;
    text-decoration: underline;
    text-underline-offset: 0.25rem;
    padding: 0.25rem 0;
  }

  .action-menu {
    max-width: 12.5rem;
  }


  .action-actions {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: min-content min-content;
  }

  .rename-input {
    color: var(--c-medium-black);
    background: var(--c-light-gray);
    border: 1px solid var(--c-light-gray);
    padding: 0.625rem 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
  }

  .rename-warning {
    margin-bottom: -0.5rem;
  }
</style>
