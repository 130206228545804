<template>
  <tr
    :class="{
      [$style.row]: true,
      [$style.selected]: displayAnnotation.selected,
      [$style.expanded]: expanded
    }"
  >
    <td data-column="selected">
      <FormCheckbox
        :modelValue="displayAnnotation.selected ? 'on' : 'off'"
        :showLabel="false"
        :label="$t('annotations.display.select')"
        :ariaLabel="$t('annotations.display.select')"
        :class="$style.checkbox"
        @update:modelValue="updateSelected"
      />
    </td>
    <td data-column="expander">
      <button
        ref="expansionButton"
        type="button"
        :class="$style.expander"
        :aria-label="$t('annotations.display.expandText')"
        @click="toggleExpansion"
      >
        <Icon
          name="chevron-down"
        />
      </button>
    </td>
    <td data-column="color">
      <HighlightColor
        :colorGroup="displayAnnotation.colorGroup"
      />
    </td>

    <td data-column="highlight">
      <DynamicExpander
        :panelId="`annotation-highlight-panel-${displayAnnotation.id}`"
        :headerId="`highlight-${displayAnnotation.id}`"
        :numElements="expanded ? 2 : 1"
      >
        <p
          :class="$style.text"
        >
          {{ displayAnnotation.highlight }}
        </p>
      </DynamicExpander>
    </td>

    <td
      v-if="!hideNote"
      data-column="note"
    >
      <DynamicExpander
        :panelId="`annotation-note-panel-${displayAnnotation.id}`"
        :headerId="`note-${displayAnnotation.id}`"
        :numElements="expanded ? 2 : 1"
      >
        <p
          v-if="displayAnnotation.note"
          :class="$style.text"
        >
          {{ displayAnnotation.note }}
        </p>
        <p
          v-else
          :class="$style.noNote"
        >
          {{ $t('annotations.display.noNote') }}
        </p>
      </DynamicExpander>
    </td>

    <td data-column="citation">
      <DynamicExpander
        :panelId="`annotation-citation-panel-${displayAnnotation.id}`"
        :headerId="`citation-${displayAnnotation.id}`"
        :numElements="expanded ? 2 : 1"
        :class="$style.citationPanel"
      >
        <TitleActionButton
          v-if="displayAnnotation.releaseTitleRecord"
          :title="displayAnnotation.releaseTitleRecord"
          :parent="displayAnnotation.parentTitleRecord"
          :seekTo="{
            type: 'highlight',
            location: displayAnnotation.id
          }"
          :label="displayAnnotation.citation"
          :truncate="true"
          :class="[$style.text, $style.linkText]"
        />
        <p
          v-else
          :class="$style.text"
        >
          {{ displayAnnotation.citation }}
        </p>
      </DynamicExpander>
    </td>

    <td data-column="release">
      <p>{{ displayAnnotation.release }}</p>
      <p>{{ displayAnnotation.releaseDate }}</p>
    </td>

    <td data-column="lastUpdated">
      <RelativeDate
        :timestamp="displayAnnotation.lastUpdated"
      />
    </td>

    <td data-column="actions">
      <AnnotationContextMenuButton
        :annotations="[annotation]"
        :buttonClass="$style.actions"
        :attrs="{
          type: 'export-queue',
          exportOptions: false,
          showCount: false
        }"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import AnnotationContextMenuButton from 'app/components/contextMenus/AnnotationContextMenuButton.vue';
import DynamicExpander from 'app/components/DynamicExpander.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import HighlightColor from 'app/components/HighlightColor.vue';
import Icon from 'app/components/Icon.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { SelectableAnnotation, useDisplayAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { defineComponent, nextTick, PropType, Ref, ref, toRef } from 'vue';

export default defineComponent({
  name: 'AnnotationTableRow',
  components: {
    RelativeDate,
    HighlightColor,
    Icon,
    FormCheckbox,
    TitleActionButton,
    DynamicExpander,
    AnnotationContextMenuButton
  },
  props: {
    annotation: {
      type: Object as PropType<SelectableAnnotation>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'annotation:update'
  ],
  setup: (props, ctx) => {
    const actions = ref<HTMLElement | null>(null);
    const expansionButton = ref<HTMLElement | null>(null);

    const expanded = ref(false);
    const showContextMenu = ref(false);

    const { displayAnnotation } = useDisplayAnnotation(toRef(props, 'annotation') as Ref<SelectableAnnotation>);

    const toggleExpansion = async () => {
      expanded.value = !expanded.value;
      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth' });
      expansionButton.value?.focus();
    };

    const updateSelected = (checked: FormCheckboxState) => {

      ctx.emit('annotation:update', checked);
    };

    return {
      actions,
      expanded,
      expansionButton,
      displayAnnotation,
      showContextMenu,
      toggleExpansion,
      updateSelected
    };
  }
});

</script>

<style module>
.row td {
  padding: 16px 20px;
  vertical-align: top;
}

@media screen and (max-width: 1470px) {
  .row td {
    padding: 16px 8px;
  }
}

.text {
  --line-clamp: 3;
  white-space: pre-wrap;
  composes: line-clamp from global;
  composes: word-break from global;
}

/* link-text & citation-panel classes needed to see full focus ring around TitleActionButton */
.link-text {
  padding: 0.25rem;
  margin: -0.25rem;
}

.citation-panel {
  margin: -1rem;
}

.citation-panel > div {
  padding: 1rem;
}

.checkbox {
  row-gap: 0;
  column-gap: 0;
}

.row.expanded .text {
  overflow: visible;
  display: block;
}

.expander {
  width: 3ch;
  height: 3ch;
  scroll-margin: 1.5rem;
}

.expander svg {
  transition: transform 150ms ease-in-out;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: translateZ(1px) rotateX(0);
}

.row.expanded .expander svg {
  transform: translateZ(1px) rotateX(180deg);
}

.row.selected {
  background-color: var(--c-selected);
}

.no-note {
  font-style: italic;
  color: var(--c-medium-black);
}


.row [data-column="selected"],
.row [data-column="expander"],
.row [data-column="color"],
.row [data-column="actions"],
.row [data-column="lastUpdated"] {
  width: auto;
  white-space: nowrap;
}

.row [data-column="citation"],
.row [data-column="release"] {
  min-width: 10ch;
  max-width: fit-content;
}

.row [data-column="highlight"],
.row [data-column="note"] {
  min-width: 10ch;
}

.row [data-column="actions"] {
  text-align: center;
}

.actions {
  height: 100%;
  width: 18px;
  fill: var(--c-light-black);
  line-height: 0;
  padding: 2px 6px;
}
</style>
