<template>
  <div :class="$style.container">
    <section v-if="showDebug">
      <div :class="$style.channel">
        Channel

        <ChannelIndicator />
      </div>

      <button
        :class="$style.link"
        @click="toggleDebugConsole"
      >
        Debug console
      </button>
    </section>

    <UserMenuBorrowLimits />

    <section>
      <button
        v-if="canDownload"
        :class="$style.link"
        @click="$emit('menu', 'download')"
      >
        {{ $t('header.account.downloadSettings.label') }}
      </button>

      <button
        :class="$style.link"
        aria-haspopup="dialog"
        @click="showGdprModal"
      >
        {{ $t('gdpr.modal.header') }}
      </button>
    </section>

    <section>
      <button
        :class="$style.link"
        @click="signOut"
      >
        {{ $t('header.account.signOut') }}
      </button>
    </section>

    <section>
      <a
        href="https://help.lexisdl.com"
        target="_blank"
        :class="$style.link"
      >
        {{ $t('header.account.help') }}
      </a>

      <a
        href="mailto:lexisdl@overdrive.com"
        :class="$style.link"
      >
        {{ $t('header.account.sendFeedback') }}
      </a>

      <button
        v-if="hasDiagnosticsCapability"
        :class="$style.link"
        @click="triggerDiagnosticsLog"
      >
        {{ $t('header.account.sendDiagnosticsLog.label') }}
      </button>

      <button
        :class="$style.link"
        @click="$emit('menu', 'about')"
      >
        {{ $t('header.account.about') }}
      </button>
    </section>
  </div>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import UserMenuBorrowLimits from 'app/components/UserMenuBorrowLimits.vue';
import { injectPrivacySettingsContext } from 'app/contexts/privacy-settings-context';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ChannelIndicator from './ChannelIndicator.vue';


export default defineComponent({
  name: 'UserMenuMain',
  components: {
    ChannelIndicator,
    UserMenuBorrowLimits
  },
  emits: [
    'close',
    'menu'
  ],
  setup: (_, ctx) => {
    const { t } = useI18n();
    const { dispatch } = useAppEvents();

    const canDownload = APP.shell.has('rosters');
    const showDebug = APP.flags.get('debug');
    const toggleDebugConsole = () => {
      dispatch('show:debug');
      ctx.emit('close');
    };
    const router = useRouter();
    const signOut = () => router.push({ name: RouteName.SignOut });

    const { showSettings } = injectPrivacySettingsContext();

    const showGdprModal = () => {
      showSettings();
      ctx.emit('close');
    };

    const hasDiagnosticsCapability = computed(() => APP.shell.has('diagnostics:log'));
    const triggerDiagnosticsLog = () => {
      APP.shell.transmit('diagnostics:log:email');
      dispatch('toast', { type: 'success', message: t('header.account.sendDiagnosticsLog.message') });
      ctx.emit('close');
    };

    return {
      canDownload,
      hasDiagnosticsCapability,
      toggleDebugConsole,
      showDebug,
      showGdprModal,
      signOut,
      triggerDiagnosticsLog
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  section {
    padding: 1rem 0;

    &:first-child {
      padding-top: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--c-dark-gray);
    }
  }
}

.channel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.link {
  display: block;
  max-width: max-content;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .pop-text();

  .focus-outline;
}
</style>
