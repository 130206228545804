import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CopyReviewSection = _resolveComponent("CopyReviewSection")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Page = _resolveComponent("Page")!
  const _component_LoadingPage = _resolveComponent("LoadingPage")!
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      (_ctx.state.status === 'loaded')
        ? (_openBlock(), _createBlock(_component_Page, {
            key: 0,
            header: _ctx.$t('copyReview.header'),
            announceHeader: _ctx.$t('copyReview.ariaHeader', { title: _ctx.state.title.fullTitle() })
          }, {
            subheader: _withCtx(() => [
              _createElementVNode("h2", {
                class: _normalizeClass(_ctx.$style.title)
              }, _toDisplayString(_ctx.state.title.title), 3),
              (_ctx.state.title.subtitle)
                ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.state.title.subtitle), 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.release)
              }, _toDisplayString(_ctx.state.release), 3)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.warning)
              }, [
                _createVNode(_component_Icon, {
                  class: _normalizeClass(_ctx.$style.warningIcon),
                  name: "warning"
                }, null, 8, ["class"]),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.warningText)
                }, _toDisplayString(_ctx.$t('copyReview.warning')), 3)
              ], 2),
              (_ctx.state.successes.length > 0 || _ctx.state.failures.length > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_CopyReviewSection, {
                      class: _normalizeClass(_ctx.$style.reviewSection),
                      message: _ctx.$t('copyReview.successes.count', { COUNT: _ctx.state.successes.length }),
                      description: _ctx.$t('copyReview.successes.description'),
                      snapshots: _ctx.state.successes,
                      hideNote: _ctx.hideNote
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_Icon, {
                          class: _normalizeClass(_ctx.$style.successIcon),
                          name: "dl-downloaded"
                        }, null, 8, ["class"])
                      ]),
                      _: 1
                    }, 8, ["class", "message", "description", "snapshots", "hideNote"]),
                    _createVNode(_component_CopyReviewSection, {
                      class: _normalizeClass(_ctx.$style.reviewSection),
                      message: _ctx.$t('copyReview.failures.count', { COUNT: _ctx.state.failures.length }),
                      description: _ctx.$t('copyReview.failures.description'),
                      snapshots: _ctx.state.failures,
                      hideNote: _ctx.hideNote
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_Icon, {
                          class: _normalizeClass(_ctx.$style.failureIcon),
                          name: "dismiss"
                        }, null, 8, ["class"])
                      ]),
                      _: 1
                    }, 8, ["class", "message", "description", "snapshots", "hideNote"])
                  ], 64))
                : (_openBlock(), _createBlock(_component_EmptyState, {
                    key: 1,
                    header: _ctx.$t('copyReview.empty.header'),
                    content: _ctx.$t('copyReview.empty.content')
                  }, null, 8, ["header", "content"]))
            ]),
            _: 1
          }, 8, ["header", "announceHeader"]))
        : (_ctx.state.status === 'loading')
          ? (_openBlock(), _createBlock(_component_LoadingPage, {
              key: 1,
              includeToolbar: false
            }))
          : (_openBlock(), _createBlock(_component_NotFound, { key: 2 }))
    ], undefined, true),
    _: 1
  }))
}