<template>
  <PromptDialog
    ref="prompt"
    :heading="$t('ntc.prompt.header')"
    @close="$emit('close')"
    @submit="submitAndClose"
  >
    <p
      :id="`ntc-explanation-${providerId}`"
    >
      {{ $t('ntc.prompt.explanation') }}
    </p>
    <div
      :class="$style.actions"
    >
      <FormSubmitButton
        autofocus
        :label="$t('general.continue')"
        :enabled="true"
        size="small"
      />

      <button
        :class="$style.cancel"
        type="button"
        @click="prompt?.closeModal()"
      >
        {{ $t('general.cancel') }}
      </button>
    </div>
  </PromptDialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import FormSubmitButton from './FormSubmitButton.vue';
import PromptDialog from './dialogs/PromptDialog.vue';

export default defineComponent({
  name: 'NtcPrompt',
  components: {
    FormSubmitButton,
    PromptDialog
  },
  props: {
    providerId: {
      type: String,
      default: 'provider'
    }
  },
  emits: [
    'submit',
    'close'
  ],
  setup: (_, ctx) => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);
    const submitAndClose = () => {
      ctx.emit('submit');
      prompt.value?.closeModal();
    };


    return {
      prompt,
      submitAndClose
    };
  }
});
</script>

<style module>
.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.cancel {
  text-transform: uppercase;
  color: var(--c-medium-black);
  composes: linked from global;
}
</style>
