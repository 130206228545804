<template>
  <div
    :class="{
      [$style.alert]: true,
      [$style.unreviewed]: !alertWithMetadata.reviewed
    }"
    :aria-label="unreviewedLabel"
  >
    <div :class="$style.metadata">
      <template v-if="alertWithMetadata.type === 'TitleNewRelease' || alertWithMetadata.type === 'TitleNotAvailable'">
        <Cover
          :class="$style.titleCover"
          :item="alertWithMetadata.title"
          :bordered="true"
        />
        <div :class="$style.info">
          <h3
            :class="$style.type"
            :aria-label="unreviewedLabel"
          >
            {{ typeName }}
          </h3>
          <p :class="[$style.title, $style.truncated]">
            {{ alertWithMetadata.title.title }}
          </p>
          <p
            v-if="alertWithMetadata.title.subtitle"
            :class="[$style.subtitle, $style.truncated]"
          >
            {{ alertWithMetadata.title.subtitle }}
          </p>
        </div>
      </template>
      <template v-else>
        <SeriesCover
          :series="alertWithMetadata.series"
          :width="seriesCoverSizes.alertShade"
          :class="$style.seriesCover"
        />
        <div :class="$style.info">
          <h3
            :class="$style.type"
            :aria-label="unreviewedLabel"
          >
            {{ typeName }}
          </h3>
          <p :class="[$style.title, $style.truncated]">
            {{ alertWithMetadata.series.name }}
          </p>
        </div>
      </template>
      <button
        :id="`alert-review-${alertWithMetadata.id}`"
        :aria-label="$t('alert.review')"
        :class="$style.fullButton"
        @click="$emit('view-details', alertWithMetadata)"
      ></button>
    </div>
    <button
      :aria-label="$t('alert.clear')"
      :class="$style.delete"
      @click="$emit('clear')"
    >
      <Icon name="trashcan" />
    </button>
  </div>
</template>

<script lang='ts'>
import { seriesCoverSizes } from 'app/base/constants';
import Cover from 'app/components/Cover.vue';
import SeriesCover from 'app/components/SeriesCover.vue';
import { AlertWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { useI18n } from 'app/functions/use-i18n';
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertCard',
  components: {
    Cover,
    SeriesCover
  },
  props: {
    alertWithMetadata: {
      type: Object as PropType<AlertWithMetadata>,
      required: true
    }
  },
  emits: [
    'view-details',
    'clear'
  ],
  setup: (props) => {
    const { t } = useI18n();
    const typeName = computed(() => t(`alert.type.${props.alertWithMetadata.type}`));
    const unreviewedLabel = computed(() => props.alertWithMetadata.reviewed ? typeName.value : t('alert.type.Unreviewed', { type: typeName.value }));

    return {
      seriesCoverSizes,
      typeName,
      unreviewedLabel
    };
  }
});
</script>

<style module>

  .alert {
    display: grid;
    grid-template-areas: 'metadata delete';
    grid-template-columns: 1fr auto;
    gap: 1rem;

    padding: 0.5rem;
    border-radius: var(--form-border-radius);
  }

  .alert.unreviewed {
    background-color: var(--c-light-gray);
  }

  .metadata {
    grid-area: metadata;
    display: grid;
    grid-template-areas: 'cover info';
    grid-template-columns: 3.5rem 1fr;
    gap: 1rem;
    padding: 0.5rem;
    margin: -0.5rem;
  }

  .title-cover {
    grid-area: cover;
    width: 3.375rem;
    height: 4.5625rem;
  }

  .series-cover {
    grid-area: cover;
    position: relative;
  }

  .info {
    grid-area: info;
    display: grid;
    grid-template-areas:
      'type'
      'title'
      'subtitle';
    grid-template-rows: 1.5rem 1.5rem 1.5rem;
    text-align: left;
  }

  .type {
    grid-area: type;
    font-weight: var(--fw-medium-bold);
  }

  .title {
    grid-area: title;
  }

  .subtitle {
    grid-area: subtitle;
    color: var(--c-medium-black);
  }

  .fullButton {
    position: absolute;
    height: calc(100% - 1rem);
    width: calc(100% - 2.5rem);
    left: 0;
    top: 0.5rem;
  }

  .delete {
    grid-area: delete;
    height: fit-content;
    padding: 0.25rem 0.25rem 0rem 0.25rem;
  }

  .delete svg {
    height: 1rem;
    width: 1rem;
  }

  .truncated {
    --line-clamp: 1;
    composes: line-clamp from global;
  }

  @media screen and (max-width: 360px /*px-vp-very-narrow*/) {
    .info {
      grid-template-rows: auto 1.5rem 1.5rem;
    }
  }

</style>
