<template>
  <PopoutDialog
    ref="popout"
    :reference="reference"
    :headerLabel="headerLabel"
    :display="display"
    :announcement="announcement"
    @close="$emit(closeEvent)"
  >
    <template v-if="display === 'menu'">
      <div :class="$style.menu">
        <button
          :class="$style.action"
          @click="() => confirmationRequired ? showConfirmation() : closeDialog(actionEvent)"
        >
          {{ menuActionLabel }}
        </button>
        <button
          :class="$style.action"
          aria-haspopup="dialog"
          @click="closeDialog('download:settings')"
        >
          {{ $t('title.downloadIndicator.configButton') }}
        </button>
      </div>
    </template>

    <div
      v-else
      :class="$style.confirmation"
    >
      <span>
        {{ confirmationMessage }}
      </span>
      <div :class="$style.confirmationActions">
        <button
          :class="$style.action"
          @click="closeDialog(actionEvent)"
        >
          {{ $t('title.downloadIndicator.confirmButton') }}
        </button>
        <button
          :class="$style.action"
          @click="showMenu"
        >
          {{ $t('title.downloadIndicator.cancelButton') }}
        </button>
      </div>
    </div>
  </PopoutDialog>
</template>

<script lang="ts">
import PopoutDialog from 'app/components/dialogs/PopoutDialog.vue';
import { PropType, computed, defineComponent, onBeforeMount, ref } from 'vue';
import { ActionEvent } from './DownloadIndicator.vue';


type PopoutDisplay = 'menu' | 'confirmation';
type CloseEvent = ActionEvent | 'download:settings' | 'close';

export default defineComponent({
  components: {
    PopoutDialog
  },
  props: {
    /**
     * Reference element the popout should position itself relative to.
     */
    reference: {
      type: Object as PropType<HTMLElement>,
      required: true
    },
    /**
     * Descriptive popout header.
     */
    headerLabel: {
      type: String,
      required: true
    },
    /**
     * Label to display in the menu for the action a user can take.
     */
     menuActionLabel: {
      type: String,
      required: true
    },
    /**
     * Announcement when menu is displayed.
     */
    menuAnnouncement: {
      type: String,
      required: true
    },
    /**
     * When confirmation is required, a description of the action
     * that will be taken upon confirmation.
     */
     confirmationMessage: {
      type: String,
      required: true
    },
    /**
     * Announcement when confirmation message is displayed.
     */
    confirmationAnnouncement: {
      type: String,
      required: true
    },
    /**
     * When true, indicates that the user should be prompted for
     * confirmation before acting.
     */
    confirmationRequired: {
      type: Boolean,
      default: true
    },
    /**
     * Download action to emit when the popout is closed by
     * download action button.
     */
    actionEvent: {
      type: String as PropType<ActionEvent>,
      required: true
    }
  },
  emits: [
    'close',
    'download:start',
    'download:stop',
    'download:settings'
  ],
  setup: (props) => {
    const popout = ref<InstanceType<typeof PopoutDialog> | null>(null);
    const display = ref<PopoutDisplay>('menu');
    const closeEvent = ref<CloseEvent>('close');

    const showMenu = () => {
      display.value = 'menu';
    };

    const showConfirmation = () => {
      display.value = 'confirmation';
    };

    const announcement = computed(() => {
      return display.value === 'menu' ? props.menuAnnouncement : props.confirmationAnnouncement;
    });

    const closeDialog = (event?: CloseEvent) => {
      closeEvent.value = event ?? 'close';
      popout.value?.closeDialog();
    };

    onBeforeMount(() => {
      showMenu();
    });

    return {
      announcement,
      closeEvent,
      display,
      popout,
      closeDialog,
      showConfirmation,
      showMenu
    };
  }
});
</script>

<style module>
.action {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  padding: 0.25rem 0;
}

.menu .action {
  display: block;
}

.confirmation {
  display: grid;
  grid-template-rows: auto auto;
}

.confirmation .confirmation-actions {
  display: flex;
  gap: 1rem;
}
</style>
