<template>
  <div
    v-if="state !== 'updated'"
    :class="[$style.updateStrip, { [$style.error]: state === 'error' }]"
  >
    <label for="progressBar">
      <span v-if="state === 'error'">{{ $t('update.error') }}</span>
      <span v-else-if="state === 'reloading'">{{ $t('update.reloading') }}</span>
      <span v-else>{{ $t('update.updating') }}</span>
    </label>
    <progress
      v-if="state !== 'error'"
      id="progressBar"
      ref="progressBar"
      value="0.01"
    ></progress>
    <div :class="$style.actions">
      <button
        type="button"
        aria-label="Stop Update"
        :disabled="state === 'reloading'"
        :class="{ ['dark']: state !== 'error' }"
        @click="cancelRelaunch()"
      >
        <Icon
          name="dismiss"
          :class="$style.dismissButton"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
// DEVELOPER NOTE: When testing this functionality, you can use the following command
// in the console to kick off an update: APP.events.dispatch('app:update:simulate')
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { defineComponent, ref } from 'vue';
import { APP } from '../../app/base/app';

/**
 * Notification that a new version of the application is availble.
 */
export default defineComponent({
  name: 'UpdateStrip',
  emits: [
    'cancel',
    'show'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();
    const label = ref<HTMLLabelElement | null>(null);
    const progressBar = ref<HTMLProgressElement | null>(null);
    const isUpdateReady = ref<boolean>(false);
    const state = ref<'updated' | 'available' | 'updating' | 'error' | 'reloading'>('updated');

    const RELAUNCH_DELAY_MS = 3000;
    let timer = -1;

    const cancelRelaunch = () => {
      console.log('[UPDATE-STRIP] Update reload cancelled');
      clearTimeout(timer);
      announceMessage(t('update.cancelled'));
      state.value = 'updated';
      ctx.emit('cancel');
    };

    /**
     * Set the progress components value
     * @note Because FF does not support "-webkit-progress-value" pseudo-element, we
     * need to simulate the animation.
     * @ref https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-progress-value
     * @ref https://stackoverflow.com/questions/44588284/animating-progress-element-value
     */
    const setProgress = (val: number) => {
      if (!progressBar.value) {
        return;
      }

      const currentVal = progressBar.value.value || 0;
      const step = val * 16 / 500;

      // we're not animating backwards (css will cover non-FF browsers)
      if (val <= currentVal) {
        progressBar.value.value = val;

        return;
      }

      const animate = (current: number) => {
        if (!progressBar.value) { return; }

        const newVal = current + step;
        progressBar.value.value = newVal;

        newVal < val && requestAnimationFrame(() => {
          animate(newVal);
        });
      };

      animate(currentVal);
    };


    APP.events.on('app:update:available', () => {
      announceMessage(t('update.inProgress'), 'assertive');
      console.log('[UPDATE-STRIP] Update available');
      state.value = 'available';
      ctx.emit('show');
      setProgress(0.5);
    });

    APP.events.on('app:update:ready', () => {
      console.log('[UPDATE-STRIP] Update ready');

      // If the user cancelled, no need to continue
      if (state.value === 'updated') {
        return;
      }

      isUpdateReady.value = true;
      setProgress(1);

      console.log('[UPDATE-STRIP] Scheduling reload');
      timer = window.setTimeout(() => {
        console.log('[UPDATE-STRIP] Reloading updated application');
        state.value = 'reloading';
        setProgress(1);
        APP.reload();
      }, RELAUNCH_DELAY_MS);
    });

    APP.events.on('app:update:failed', () => {
      // Nothing to worry about if not 'actively' updating
      if (state.value === 'updated') {
        return;
      }

      console.error('[UPDATE-STRIP] Update failed');
      state.value = 'error';
      announceMessage(t('update.error'));

      setProgress(0);
    });

    return {
      isUpdateReady,
      label,
      progressBar,
      state,
      cancelRelaunch
    };
  }
});

</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.update-strip {
  background-color: @c-primary;
  color: @c-white;
  font-size: @fs-mini;

  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 2rem;
  align-items: center;

  progress {
    grid-column: 1;
    width: 100%;
    margin: 0.6rem 0;
  }
}

.error {
  color: var(--c-white);
  background-color: var(--c-notif-error);
}

.actions {
  grid-column: 2;
  text-align: right;

  button {
    margin-left: 10px;
    vertical-align: bottom;
    padding: 5px;

    :global .icon-solid {
      fill: @c-white;
    }

    svg {
      display: block;
      stroke: @c-white;
      fill: @c-white;
      height: 1rem;
      width: 1rem;
    }

    &:disabled svg {
      cursor: default;
      stroke: @c-light-black;
      fill: @c-light-black;
    }
  }
}
</style>

