import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { useAlertsWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { SeriesSubscriptionWithMetadata, TitleSubscriptionWithMetadata, useSubscriptionsWithMetadata } from 'app/functions/use-subscriptions-with-metadata';
import { TitleMapper, TitleRecord } from 'app/models/title';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export const useAlertGeneration = (
  subscriptionsWithMetadataContext: ReturnType<typeof useSubscriptionsWithMetadata>,
  alertsWithMetadataContext: ReturnType<typeof useAlertsWithMetadata>
) => {
  const {
    subscriptionsWithMetadata,
    fetchAllSubscriptions,
    setLastChecked,
    unsubscribe
  } = subscriptionsWithMetadataContext;

  const {
    fetchAllAlerts,
    addTitleNewReleaseAlert,
    addTitleNotAvailableAlert,
    addSeriesNewReleaseAlert,
    addSeriesNotAvailableAlert
  } = alertsWithMetadataContext;

  const readyToGenerate = ref<boolean>(true);
  let timer: ReturnType<typeof setTimeout>;

  const interval = 30 * C.MS_MINUTE;
  const startTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      readyToGenerate.value = true;
    }, interval);
  };

  const generateAlerts = async () => {
    if (!APP.patron.accountId) { return; }
    if (!subscriptionsWithMetadata.value) { return; }
    if (!readyToGenerate.value) { return; }

    try {
      readyToGenerate.value = false;

      const titleSubs = subscriptionsWithMetadata.value.filter((sub) => sub.type === 'Title') as TitleSubscriptionWithMetadata[];
      const seriesSubs = subscriptionsWithMetadata.value.filter((sub) => sub.type === 'Series') as SeriesSubscriptionWithMetadata[];

      await generateTitleAlerts(titleSubs);
      await generateSeriesAlerts(seriesSubs);

      // Resync alerts with the server
      await fetchAllAlerts();

      startTimer();
    } catch {
      readyToGenerate.value = true;
    }
  };

  const generateTitleAlerts = async (titleSubs: TitleSubscriptionWithMetadata[]) => {
    for await (const sub of titleSubs) {
      const notAvailable = sub.title.circ && !sub.title.circ.owned;
      const releaseDate = sub.title.lexisMetadata.releaseDate;

      if (notAvailable) {
        const succeeded = await addTitleNotAvailableAlert(sub.titleId);
        if (succeeded) {
          await unsubscribe(sub.id, false);
        }
      } else if (releaseDate) {
        const releaseTimestamp = C.toTimestamp(releaseDate);
        if (sub.lastChecked <= releaseTimestamp) {
          const succeeded = await addTitleNewReleaseAlert(sub.titleId);
          if (succeeded) {
            await setLastChecked(sub.id);
          }
        }
      }
    }
  };

  const generateSeriesAlerts = async (seriesSubs: SeriesSubscriptionWithMetadata[]) => {
    for await (const sub of seriesSubs) {
      try {
        const thunderTitles = await APP.services.thunder.getTitles(APP.library.key(), sub.titleIds);
        if (!thunderTitles) { throw new Error; }

        const titles: TitleRecord[] = [];
        thunderTitles.items.forEach((title) => {
          const mapped = TitleMapper.mapFromThunder(title);
          if (!mapped) { throw new Error; }

          titles.push(mapped);
        });

        const titlesWithCirc = titles.filter((title) => title.circ && title.circ.owned);
        const titlesWithNewRelease = titlesWithCirc.filter((title) => title.lexisMetadata?.releaseDate && sub.lastChecked <= C.toTimestamp(title.lexisMetadata.releaseDate));
        const newReleaseIds = titlesWithNewRelease.map((title) => parseInt(title.slug, 10));

        if (titles.length && !titlesWithCirc.length) {
          const succeeded = await addSeriesNotAvailableAlert(sub.seriesId);
          if (succeeded) {
            await unsubscribe(sub.id, false);
          }
        } else if (newReleaseIds.length) {
          const succeeded = await addSeriesNewReleaseAlert(sub.seriesId, newReleaseIds);
          if (succeeded) {
            await setLastChecked(sub.id);
          }
        }
      } catch {
        continue;
      }
    }
  };

  // When the route changes, if the timer has expired, resync subscriptions
  const route = useRoute();
  watch(() => route.path, () => {
    if (!readyToGenerate.value) { return; }

    fetchAllSubscriptions();
  });

  // When subscriptions change and metadata loads, generate alerts
  watch(subscriptionsWithMetadata, generateAlerts);

  return {
    startAlertGeneration: fetchAllSubscriptions
  };
};
