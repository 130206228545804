import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loading-box", _ctx.$style.box])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.shield, _ctx.$style.dark])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.spinner)
      }, [
        _createVNode(_component_Icon, { name: "spinner" })
      ], 2)
    ], 2)
  ], 2))
}