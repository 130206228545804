import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_TextFilter = _resolveComponent("TextFilter")!
  const _component_MyBooksTabPanel = _resolveComponent("MyBooksTabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_Page, {
        header: _ctx.$t('mybooks.header'),
        useBackToTop: true
      }, {
        toolbar: _withCtx(() => [
          _createVNode(_component_FilterButton, {
            options: _ctx.availableSubjects,
            filterType: "subject",
            iconOnly: _ctx.mobile,
            onInput: _ctx.subjectsSelected
          }, null, 8, ["options", "iconOnly", "onInput"]),
          _createVNode(_component_TextFilter, {
            modelValue: _ctx.textFilter,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textFilter) = $event)),
            label: _ctx.$t('mybooks.textFilter'),
            class: _normalizeClass(_ctx.$style.textFilter)
          }, null, 8, ["modelValue", "label", "class"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_TabView, {
            ref: "tabView",
            tabs: _ctx.tabs,
            initialTab: _ctx.currentTab,
            ariaLabel: _ctx.$t('mybooks.tabs.ariaLabel'),
            onTab: _ctx.onTabChange
          }, _createSlots({ _: 2 }, [
            _renderList(_ctx.tabs, (tab) => {
              return {
                name: `panel-${tab.id}`,
                fn: _withCtx(() => [
                  _createVNode(_component_MyBooksTabPanel, {
                    titles: (tab.items as TitleRecord[]),
                    filter: _ctx.titlesMatchingFilter
                  }, null, 8, ["titles", "filter"])
                ])
              }
            })
          ]), 1032, ["tabs", "initialTab", "ariaLabel", "onTab"]),
          (_ctx.noTitles)
            ? (_openBlock(), _createBlock(_component_EmptyState, {
                key: 0,
                link: _ctx.helpLink,
                header: _ctx.$t('home.recentlyRead.emptystate.header'),
                content: _ctx.$t('home.recentlyRead.emptystate.content'),
                linkLabel: _ctx.$t('home.recentlyRead.emptystate.link')
              }, null, 8, ["link", "header", "content", "linkLabel"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["header"])
    ], undefined, true),
    _: 1
  }))
}