<template>
  <Accordion
    :sections="sections"
  >
    <template #toc="{ mobile }">
      <TableOfContents
        :title="title"
        :showTitle="mobile"
      />
    </template>

    <template #description="{ mobile }">
      <component :is="mobile ? 'div' : 'ShowAll'">
        <p
          :class="$style.description"
          v-html="title.description"
        >
        </p>
      </component>
    </template>

    <template #details>
      <TitleDetailsAccordionDetails :title="title" />
    </template>

    <template #subjects>
      <AccordionSubjectList :subjects="title.subjects" />
    </template>

    <template #volumes>
      <TitleDetailsAccordionVolumes :series="series" />
    </template>

    <template #releases>
      <TitlePriorReleaseList :title="title" />
    </template>
  </Accordion>
</template>

<script lang='ts'>
import Accordion, { AccordionSection } from 'app/components/Accordion.vue';
import AccordionSubjectList from 'app/components/AccordionSubjectList.vue';
import ShowAll from 'app/components/ShowAll.vue';
import TableOfContents from 'app/components/TableOfContents.vue';
import TitleDetailsAccordionDetails from 'app/components/TitleDetailsAccordionDetails.vue';
import TitleDetailsAccordionVolumes from 'app/components/TitleDetailsAccordionVolumes.vue';
import TitlePriorReleaseList from 'app/components/TitlePriorReleaseList.vue';
import type { Series } from 'app/models/series';
import type { TitleRecord } from 'app/models/title';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsAccordion',
  components: {
    Accordion,
    TableOfContents,
    TitleDetailsAccordionVolumes,
    TitleDetailsAccordionDetails,
    TitlePriorReleaseList,
    AccordionSubjectList,
    ShowAll
  },
  props: {
    title: {
      type: Object as () => TitleRecord,
      required: true
    },
    series: {
      type: Object as () => Series | null,
      default: null
    }
  },
  setup: (props) => {
    const sections = computed(() => {
      const sectionList: AccordionSection[] = [
        {
          id: 'toc',
          label: 'title.tableOfContents.header'
        },
        {
          id: 'description',
          label: 'title.description'
        },
        {
          id: 'details',
          label: 'title.detailedInformation'
        }
      ];

      if (props.title.subjects?.length) {
        sectionList.push({
          id: 'subjects',
          label: 'title.subjects'
        });
      }

      if (props.series && props.series.items.length > 1) {
        sectionList.push({
          id: 'volumes',
          label: 'series.volumeCount',
          badge: props.series.items.length
        });
      }

      if (props.title.lexisMetadata?.priorReleases?.length) {
        sectionList.push({
          id: 'releases',
          label: 'title.priorReleases'
        });
      }

      return sectionList;
    });

    return {
      sections
    };
  }
});
</script>

<style lang="less" module>
@import '../../app/views/core/base.less';

.description {
  i {
    font-style: italic;
  }

  a {
    color: @c-primary;
    .pop(@c-primary);
    .focus-outline;
  }
}
</style>
