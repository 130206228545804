import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleCard = _resolveComponent("TitleCard")!

  return (_ctx.titles && _ctx.titles.length)
    ? (_openBlock(), _createElementBlock("ol", {
        key: 0,
        class: _normalizeClass(_ctx.$style.list)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.titles, (title) => {
          return _withDirectives((_openBlock(), _createElementBlock("li", {
            key: title.slug
          }, [
            _createVNode(_component_TitleCard, { title: title }, null, 8, ["title"])
          ])), [
            [_vShow, _ctx.filter(title)]
          ])
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}