<template>
  <Surface>
    <Page
      :header="$t('exportQueue.header')"
      :useBackToTop="true"
    >
      <template
        v-if="exportQueue.length"
        #toolbar
      >
        <FilterButton
          :options="filterObjects"
          filterType="export"
          :iconOnly="mobile"
          @input="updatePath"
        />
        <TextFilter
          v-model="textFilter"
          :label="$t('annotations.textFilter')"
          :class="$style.textFilter"
          @update:modelValue="expandShownTitles"
        />
      </template>
      <template #default>
        <template v-if="exportQueue.length">
          <template v-if="!mobile">
            <div :class="$style.toolbar">
              <AnnotationSelectionToolbar
                :headerLabel="$t('exportQueue.contextMenu.header')"
                :selected="selectedAnnotations"
                :menuType="menuType"
                @selected:clear="updateAllAnnotations('off')"
              />
              <p>
                {{ showingCount }}
              </p>
            </div>
            <div
              v-if="idsToShow.length"
              :class="$style.groupsHeader"
            >
              <span :class="[$style.selectAll, $style.centered]">
                <FormCheckbox
                  :modelValue="selectionState"
                  :showLabel="false"
                  :label="$t('exportQueue.selectAll')"
                  :ariaLabel="$t('exportQueue.selectAll')"
                  :class="$style.checkbox"
                  @update:modelValue="updateAllAnnotations"
                />
              </span>
              <span :id="labelIds.select">
                <span class="visually-hidden">{{ $t('exportQueue.groupsHeader.select') }}</span>
              </span>
              <span :id="labelIds.expand">
                <span class="visually-hidden">{{ $t('exportQueue.groupsHeader.expand') }}</span>
              </span>
              <span :id="labelIds.title">
                {{ $t('exportQueue.groupsHeader.title') }}
              </span>
              <span
                :id="labelIds.count"
                :class="$style.centered"
              >
                {{ $t('exportQueue.groupsHeader.count') }}
              </span>
              <span
                :id="labelIds.actions"
                :class="$style.centered"
              >
                {{ $t('exportQueue.groupsHeader.actions') }}
              </span>
            </div>
            <p v-else>
              {{ $t('annotations.display.empty') }}
            </p>
            <ul :class="$style.groupList">
              <template
                v-for="titleSection in exportQueueByTitle"
                :key="titleSection.titleId"
              >
                <li>
                  <ExportQueueGroup
                    :titleId="titleSection.titleId"
                    :title="titleSection.title"
                    :subtitle="titleSection.subtitle"
                    :annotations="titleSection.annotations"
                    :labelIds="labelIds"
                    :hideNote="hideNote"
                    :expanded="titleSection.expanded"
                    @annotations:update="(checked, uuids) => onSelectedUpdate(checked, uuids)"
                    @toggle:expansion="(expanded) => onExpansionUpdate(titleSection.titleId, expanded)"
                  />
                </li>
              </template>
            </ul>
          </template>
          <template v-else>
            <div :class="$style.toolbar">
              <button
                :class="[$style.selectButton, selectMode ? $style.selectButtonActive : '']"
                :aria-label="$t('annotations.select.toggle')"
                @click="selectMode = !selectMode"
              >
                {{ $t('annotations.select.label') }}
              </button>
              <p>
                {{ showingCount }}
              </p>
            </div>
            <div
              v-if="selectMode"
              :class="$style.selectModeActions"
            >
              <FormCheckbox
                :modelValue="selectionState"
                :showLabel="false"
                :label="$t('exportQueue.selectAll')"
                :ariaLabel="$t('exportQueue.selectAll')"
                :class="$style.checkbox"
                :disabled="idsToShow.length === 0"
                @update:modelValue="updateAllAnnotations"
              />
              <AnnotationSelectionToolbar
                :selected="selectedAnnotations"
                :menuType="menuType"
                :class="$style['selection-toolbar']"
                @selected:clear="updateAllAnnotations('off')"
              />
            </div>
            <ul
              v-if="idsToShow.length"
              :class="$style.groupList"
            >
              <template
                v-for="titleSection in exportQueueByTitle"
                :key="titleSection.titleId"
              >
                <li>
                  <ExportQueueMobileGroup
                    :titleId="titleSection.titleId"
                    :title="titleSection.title"
                    :subtitle="titleSection.subtitle"
                    :annotations="titleSection.annotations"
                    :selectable="selectMode"
                    :hideNote="hideNote"
                    :expanded="titleSection.expanded"
                    @annotations:update="(checked, uuids) => onSelectedUpdate(checked, uuids)"
                    @toggle:expansion="(expanded) => onExpansionUpdate(titleSection.titleId, expanded)"
                  />
                </li>
              </template>
            </ul>
            <p v-else>
              {{ $t('annotations.display.empty') }}
            </p>
          </template>
        </template>
        <EmptyState
          v-else
          :link="helpLink"
          :header="$t('exportQueue.emptyState.header')"
          :content="$t('exportQueue.emptyState.content')"
          :linkLabel="$t('exportQueue.emptyState.link')"
        />
      </template>
    </Page>
  </Surface>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { Constants, HighlightColorGroup } from 'app/base/constants';
import AnnotationSelectionToolbar from 'app/components/AnnotationSelectionToolbar.vue';
import EmptyState from 'app/components/EmptyState.vue';
import ExportQueueGroup, { ExportQueueGroupLabelIds } from 'app/components/ExportQueueGroup.vue';
import ExportQueueMobileGroup from 'app/components/ExportQueueMobileGroup.vue';
import FilterButton from 'app/components/FilterButton.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { watchMessage, watchResultCount } from 'app/functions/use-chatterbox';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { ExportFilters, getQueryParametersFromExportFilters, useExportFilters } from 'app/functions/use-export-filters';
import { useI18n } from 'app/functions/use-i18n';
import { useLibrary } from 'app/functions/use-library';
import { normalizeDate } from 'app/functions/use-normalize-date';
import { usePatron } from 'app/functions/use-patron';
import { usePriorReleases } from 'app/functions/use-prior-releases';
import { useTitle } from 'app/functions/use-title';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { ContextMenuType } from 'app/keys/injection-keys';
import { Annotation } from 'app/models/annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { Title, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import router from 'app/router/router';
import { Dictionary } from 'lib/common/dictionary';
import { generateUUID } from 'lib/common/uuid';
import { PropType, Ref, computed, defineComponent, provide, ref, watch, watchEffect } from 'vue';

export default defineComponent({
  components: {
    AnnotationSelectionToolbar,
    EmptyState,
    ExportQueueGroup,
    ExportQueueMobileGroup,
    FilterButton,
    FormCheckbox,
    Page,
    Surface,
    TextFilter
  },
  props: {
    colorFilters: {
        type: Array as PropType<HighlightColorGroup[]>,
        default: undefined
      }
  },
  setup: (props) => {
    const uuid = generateUUID();
    const labelIds = computed<ExportQueueGroupLabelIds>(() => {
      return {
        select: `select-${uuid}`,
        expand: `expand-${uuid}`,
        title: `title-${uuid}`,
        count: `count-${uuid}`,
        actions: `actions-${uuid}`
      };
    });

    const { exportQueue } = usePatron();

    const selected = ref<Set<string>>(new Set());
    const parentTitleRecords = ref<Dictionary<TitleRecord>>({});
    const releaseTitleRecords = ref<Dictionary<TitleRecord>>({});

    const {
      textFilter,
      colorFilter,
      filterObjects,
      filteredAnnotations,
      displayCounts
    } = useExportFilters(exportQueue);
    const idsToShow = computed(() => filteredAnnotations.value.map((a) => a.uuid));

    // Use this instead of exportQueue on any other computed or watch values on this page
    const exportQueueSelectable = computed<SelectableAnnotation[]>(() => {
      return exportQueue.value.slice().sort(Annotation.SORT_FUNCTIONS.release).map((annotation) => {
        return {
          ...annotation,
          parentTitleRecord: parentTitleRecords.value[annotation.titleSlug] as TitleRecord,
          releaseTitleRecord: releaseTitleRecords.value[annotation.uuid] as TitleRecord,
          selected: selected.value.has(annotation.uuid)
        };
      });
    });

    watchEffect(() => colorFilter.value = props.colorFilters);
    watch(colorFilter, () => {
      expandShownTitles();
      selected.value.clear();
    });

    const selectItems = (selectedUuids: string[]) => selectedUuids.forEach((selectedUuid) => selected.value.add(selectedUuid));
    const unselectItems = (selectedUuids: string[]) => selectedUuids.forEach((selectedUuid) => selected.value.delete(selectedUuid));

    const onSelectedUpdate = (checked: FormCheckboxState, uuids: string[]) => {
      checked === 'on'
        ? selectItems(uuids)
        : unselectItems(uuids);
    };
    const updateAllAnnotations = (checked: FormCheckboxState) => {
      checked === 'on'
        ? selectItems(idsToShow.value)
        : unselectItems(idsToShow.value);
    };

    const selectedAnnotations = computed(() => exportQueueSelectable.value.filter((a) => a.selected));
    const selectionState = computed<FormCheckboxState>(() => {
      const shownAndSelected = idsToShow.value.filter((shownID) => selected.value.has(shownID));

      return shownAndSelected.length === 0
        ? 'off'
        : shownAndSelected.length === idsToShow.value.length
          ? 'on'
          : 'partial';
    });

    const expandedTitles = ref<Set<string>>(new Set(exportQueue.value.map((annotation) => annotation.titleSlug)));

    const exportQueueByTitle = computed(() => {
      const grouped = exportQueueSelectable.value
        .filter((annotation) => idsToShow.value.includes(annotation.uuid))
        .reduce((obj, annotation) => {
          const titleId = annotation.titleSlug;
          const current = obj[titleId] || [];
          obj[titleId] = [...current, annotation];

          return obj;
        }, {} as Record<string, SelectableAnnotation[]>);

      const mapped = Object.entries(grouped).map(([titleId, annotations]) => {
        const { title, subtitle, sortTitle } = APP.library.titles.fetch(annotations[0].titleSlug) || { title: '',subtitle: '',sortTitle: '' };

        return {
          titleId,
          title,
          subtitle,
          sortTitle,
          annotations: annotations.filter((annotation) => idsToShow.value.includes(annotation.uuid)),
          expanded: expandedTitles.value.has(titleId)
        };
      });

      const sorted = mapped.sort((a, b) => a.sortTitle.localeCompare(b.sortTitle));

      return sorted;
    });

    exportQueueByTitle.value.forEach((titleSection) => {
      const { title: groupTitle } = useTitle(titleSection.annotations[0].titleSlug);
        watch(groupTitle, () => {
          if (groupTitle.value) {
            parentTitleRecords.value[groupTitle.value.slug] = groupTitle.value as TitleRecord;
          }
        });


        const { priorReleases } = usePriorReleases(groupTitle as Ref<Title | null>);
        watch(priorReleases, () => {
          if (priorReleases.value && groupTitle.value) {
            const releases = priorReleases.value.concat(groupTitle.value);
            titleSection.annotations.forEach((a) => {
              const release = releases.find((pr) =>
                pr.lexisMetadata.release === a.release &&
                normalizeDate(pr.lexisMetadata.releaseDate) === normalizeDate(a.releaseDate)
              );

              releaseTitleRecords.value[a.uuid] = release as TitleRecord;
            });
          }
        });
    });

    const library = useLibrary();
    const hideNote = computed(() => library.value?.disableNotes);
    const { t } = useI18n();

    const selectMode = ref(false);
    watchMessage(computed(() => selectMode.value ? t('annotations.select.on') : t('annotations.select.off')));
    watch(selectMode, () => {
      if (!selectMode.value) {
        selected.value.clear();
      }
    });

    const showingCount = computed(() => {
      const displayedAnnotations = filteredAnnotations.value.filter((annotation) => expandedTitles.value.has(annotation.titleSlug));

      return t(
        'annotations.showingCount',
        { SHOWING: displayedAnnotations.length, TOTAL: displayCounts.value.total });
    });
    watchResultCount(showingCount);

    const helpLink = Constants.HELP_PATHS.EXPORTS;

    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.VeryWide);

    const menuType = 'export-queue';
    provide(ContextMenuType, menuType);

    const updatePath = (filters: ExportFilters) => {
      const newRoute = {
        name: RouteName.Export,
        query: {
          ...getQueryParametersFromExportFilters(filters)
        }
      };

      router.replace(newRoute);
    };

    const onExpansionUpdate = (titleId: string, expanded: Boolean) => {
      expanded ? expandedTitles.value.add(titleId) : expandedTitles.value.delete(titleId);
    };

    const expandShownTitles = () => {
      if (textFilter.value.length) {
        exportQueueByTitle.value.forEach((titleSection) => expandedTitles.value.add(titleSection.titleId));
      }
    };

    return {
      exportQueue,
      exportQueueByTitle,
      filterObjects,
      helpLink,
      hideNote,
      idsToShow,
      labelIds,
      menuType,
      mobile,
      selectMode,
      showingCount,
      selectedAnnotations,
      selectionState,
      textFilter,
      onSelectedUpdate,
      updateAllAnnotations,
      updatePath,
      onExpansionUpdate,
      expandShownTitles
    };
  }
});
</script>

<style module>
.toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.checkbox {
  column-gap: unset;
}

.select-all {
  position: absolute;
  width: 50px;
  top: 0;
  left: 0;
}

.groups-header {
  display: grid;
  grid-template-columns: 50px 50px 1fr 100px 100px;
  gap: 1rem;

  background-color: var(--c-light-gray);
  margin-bottom: 1.25rem;
  font-weight: var(--fw-bold);
  font-size: var(--fs-metadata);
  text-transform: uppercase;
  text-align: left;
  color: var(--c-medium-black);
  white-space: nowrap;

  position: relative;
}

.groups-header > span {
  padding: 12px 0;
}

.centered {
  display: flex;
  justify-content: center;
}

.group-list {
  display: grid;
  grid-gap: 1rem;
}

@media screen and (max-width: 1470px) {
  .groups-header {
    grid-template-columns: 30px 30px 1fr 100px 75px;
    gap: 0.5rem;
  }

  .select-all {
    width: 30px;
  }
}
.select-button {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  padding: 0.5rem 1rem;
}

.select-button-active {
  background-color: var(--c-light-gray)
}

.select-mode-actions {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}

.selection-toolbar {
  margin-left: 1rem;
}

.text-filter {
  margin-left: auto;
  max-width: 16rem;
}
</style>

