import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "visually-hidden" }
const _hoisted_3 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HighlightColor = _resolveComponent("HighlightColor")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass({
      [_ctx.$style.card]: true,
      [_ctx.$style.expanded]: _ctx.expanded
    })
  }, [
    _createElementVNode("button", {
      ref: "expansionButton",
      class: _normalizeClass(_ctx.$style.fullCardButton),
      "aria-label": _ctx.$t('annotations.display.expandText'),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpansion && _ctx.toggleExpansion(...args)))
    }, null, 10, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.content)
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.colorStrip),
        style: _normalizeStyle(`--highlight-color: var(--highlight-color-${_ctx.snapshot.color_id})`),
        "aria-hidden": "true"
      }, null, 6),
      _createElementVNode("p", {
        class: _normalizeClass([_ctx.$style.quote, _ctx.$style.text, _ctx.$style.clampedLong])
      }, _toDisplayString(_ctx.snapshot.quote), 3),
      _createElementVNode("dl", {
        class: _normalizeClass(_ctx.$style.table)
      }, [
        (!_ctx.hideNote)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annotations.display.property.note')), 1),
              (_ctx.snapshot.note)
                ? (_openBlock(), _createElementBlock("dd", {
                    key: 0,
                    class: _normalizeClass([_ctx.$style.text, _ctx.$style.clamped])
                  }, _toDisplayString(_ctx.snapshot.note), 3))
                : (_openBlock(), _createElementBlock("dd", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style.noNote)
                  }, _toDisplayString(_ctx.$t('annotations.display.noNote')), 3))
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annotations.display.property.citation')), 1),
        _createElementVNode("dd", {
          class: _normalizeClass([_ctx.$style.text, _ctx.$style.clamped])
        }, _toDisplayString(_ctx.snapshot.citation), 3),
        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annotations.display.property.release')), 1),
        _createElementVNode("dd", {
          class: _normalizeClass([_ctx.$style.text, _ctx.$style.clamped])
        }, _toDisplayString(_ctx.releaseDisplayName), 3)
      ], 2),
      _createElementVNode("dl", {
        class: _normalizeClass(_ctx.$style.extra)
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("dt", _hoisted_2, _toDisplayString(_ctx.$t('annotations.display.property.color')), 1),
          _createElementVNode("dd", null, [
            _createVNode(_component_HighlightColor, {
              colorGroup: _ctx.snapshot.color_id
            }, null, 8, ["colorGroup"])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("dt", _hoisted_3, _toDisplayString(_ctx.$t('annotations.display.property.lastUpdated')), 1),
          _createElementVNode("dd", null, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.lastUpdated)
            }, [
              _createVNode(_component_Icon, {
                name: "expire-clock",
                "aria-hidden": "true"
              }),
              _createVNode(_component_RelativeDate, {
                timestamp: _ctx.snapshot.syncstamp
              }, null, 8, ["timestamp"])
            ], 2)
          ])
        ])
      ], 2)
    ], 2)
  ], 2))
}