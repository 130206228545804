import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { CoverItem } from 'app/base/interfaces';
import { FreshableItem } from 'app/base/services/freshable';
import { IdNamePair, ThunderSeriesCover, ThunderSeriesResponse } from 'app/base/thunder';
import { SeriesList } from './list';
import { TitleMapper, TitleRecord } from './title';

/**
 * @notes This model is named "Series". Externally, this is known as a "Set".
 */
export class Series extends FreshableItem<ThunderSeriesResponse> implements CoverItem {
  public id: number;
  public name: string;
  public items: SeriesItem[];
  public releaseDate: string;
  public subjects: Dictionary<IdNamePair> = {};
  public authors: string;
  public creators: Dictionary<({ id: number; name: string })[]> = {};
  public publisher: IdNamePair;
  public coverURLForDPI: string;
  public coverColor: string;
  public tableOfContents?: string;
  public edition?: string;
  public firstTitleSlug: string;
  public readonly mediaType = 'series';

  public get title() { return this.name; }


  public static SORT_FUNCTIONS = {
    rank: (a: SeriesItem, b: SeriesItem) => {
      if (!a?.rank) { return -1; }
      if (!b?.rank) { return 1; }

      return a.rank - b.rank;
    }
  };


  constructor(id: number) {
    super('set');
    this.id = id;
    this.items = [];
  }


  public arePropertiesFresh(): boolean {
    return !!this.coverURLForDPI;
  }


  protected freshenCall(): Promise<ThunderSeriesResponse> {
    return APP.services.thunder.getSeries(APP.library.key(), this.id);
  }


  protected freshenMap(response: ThunderSeriesResponse): void {
    SeriesMapper.freshenFromThunder(this, response);
  }


  public path(list?: SeriesList): string {
    let path = '';
    if (list) {
      path = list.path();
    }

    return `${path}/set/${this.id.toString()}`;
  }


  public async getAllTitleObjects(): Promise<Dictionary<TitleRecord>> {
    const titlesToFetch: string[] = [];
    const titlesInCache: TitleRecord[] = [];

    C.each(this.items, (item) => {
      const t = APP.titleCache.get(item.id);
      if (t) {
        titlesInCache.push(t);
      } else {
        titlesToFetch.push(item.id);
      }
    });

    let titlesNotInCache: TitleRecord[] = [];

    if (titlesToFetch.length) {
      titlesNotInCache = (await APP.services.thunder.getTitles(APP.library.key(), titlesToFetch))
        .items
        .map(TitleMapper.mapFromThunder);
    }

    const titleObjects: Dictionary<TitleRecord> = {};

    C.each(titlesInCache.concat(titlesNotInCache), (title) => {
      titleObjects[title.slug] = title;
    });

    return titleObjects;
  }


  public coverURL(): string {
    return this.coverURLForDPI;
  }


  protected _serializeAttributes(): BankedSeries {
    return {
      id: this.id,
      name: this.name,
      items: this.items,
      releaseDate: this.releaseDate,
      subjects: this.subjects,
      authors: this.authors,
      creators: this.creators,
      publisher: this.publisher,
      coverURLForDPI: this.coverURLForDPI,
      coverColor: this.coverColor,
      tableOfContents: this.tableOfContents,
      edition: this.edition,
      firstTitleSlug: this.firstTitleSlug
    };
  }
}

export class SeriesMapper {
  public static mapFromBank(bankedSeries: BankedSeries): Series {
    const series = new Series(bankedSeries.id);

    SeriesMapper.freshenFromBank(series, bankedSeries);

    return series;
  }

  public static freshenFromBank(series: Series, bankedSeries: BankedSeries) {
    series.id = bankedSeries.id;
    series.name = bankedSeries.name;
    series.items = bankedSeries.items;
    series.releaseDate = bankedSeries.releaseDate;
    series.subjects = bankedSeries.subjects;
    series.authors = bankedSeries.authors;
    series.creators = bankedSeries.creators;
    series.publisher = bankedSeries.publisher;
    series.coverURLForDPI = bankedSeries.coverURLForDPI;
    series.coverColor = bankedSeries.coverColor;
    series.tableOfContents = bankedSeries.tableOfContents;
    series.edition = bankedSeries.edition;
    series.firstTitleSlug = bankedSeries.firstTitleSlug;
  }

  public static mapFromThunder(thunderSeries: ThunderSeriesResponse | null): Series | null {
    if (!thunderSeries) {
      return null;
    }

    const series = new Series(thunderSeries.id);
    SeriesMapper.freshenFromThunder(series, thunderSeries);

    return series;
  }


  public static freshenFromThunder(series: Series, thunderSeries: ThunderSeriesResponse): void {
    if (!thunderSeries) {
      return;
    }

    series.id = thunderSeries.id;
    series.name = thunderSeries.name;
    series.items = thunderSeries.items;

    if (thunderSeries.lexisSeriesData) {
      const units = C.timestampToUnits(
        new Date(thunderSeries.lexisSeriesData.earliestEstimatedReleaseDate).getTime()
      );
      series.releaseDate = C.timeUnitsToNumericalDate(units);
      series.publisher = thunderSeries.lexisSeriesData.publishers ? thunderSeries.lexisSeriesData.publishers[0] : null;
      if (thunderSeries.lexisSeriesData.subjects) {
        thunderSeries.lexisSeriesData.subjects.map((s) => series.subjects[s.id] = s);
      }

      if (thunderSeries.lexisSeriesData.firstItem) {
        series.firstTitleSlug = thunderSeries.lexisSeriesData.firstItem.titleId.toString();
        [ series.edition ] = TitleMapper.parseEdition(thunderSeries.lexisSeriesData.firstItem.edition);
        series.tableOfContents = thunderSeries.lexisSeriesData.firstItem.tableOfContents;

        const cover = SeriesMapper.coverAttrsForDPI(thunderSeries.lexisSeriesData.firstItem.imageList);
        series.coverURLForDPI = cover.url;
        series.coverColor = cover.primaryColor;


        series.creators = {};
        const authors: string[] = [];
        C.each(thunderSeries.lexisSeriesData.creators, (creator) => {
          series.creators[creator.role] = series.creators[creator.role] || [];
          series.creators[creator.role].push({ name: creator.name, id: creator.id });
          if (creator.role === 'Author') {
            authors.push(creator.name);
          }
        });

        series.authors = authors.join(', ');
      }
    }
  }


  private static coverAttrsForDPI(covers: ThunderSeriesCover[]): ThunderSeriesCover {
    if (!covers?.length) {
      return {
        url: Constants.assetPath('images/cover-404-book.png'),
        primaryColor: '#BBBBBB',
        type: 400,
        width: 400,
        height: 300
      };
    }

    const sizes = [510, 300, 150];

    for (const size of sizes) {
      const cover: ThunderSeriesCover = covers.filter((c) => c.width === size)[0];

      if (cover && cover.url) {
        return cover;
      }
    }
  }
}

// Like titles, Series includes some cyclical properties that prevent properly serializing it.
// Pick out the jsut the metadata properties we can use to reconstruct the series object,
// and we can serialize those instead
export type BankedSeries = {
  id: number;
  name: string;
  items: SeriesItem[];
  releaseDate: string;
  subjects: Dictionary<IdNamePair>;
  authors: string;
  creators: Dictionary<({ id: number; name: string })[]>;
  publisher: IdNamePair;
  coverURLForDPI: string;
  coverColor: string;
  tableOfContents?: string;
  edition?: string;
  firstTitleSlug: string;
};


interface SeriesItem {
  rank: number;
  readingOrder: string;
  title: string;
  id: string;
}
