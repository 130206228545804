import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "visually-hidden" }
const _hoisted_2 = { class: "visually-hidden" }
const _hoisted_3 = { class: "visually-hidden" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExportQueueTableRow = _resolveComponent("ExportQueueTableRow")!

  return (_openBlock(), _createElementBlock("table", _mergeProps(_ctx.$attrs, {
    class: _ctx.$style.table
  }), [
    _createElementVNode("caption", _hoisted_1, _toDisplayString(_ctx.$t('annotations.display.header', { title: _ctx.title })), 1),
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("th", null, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('annotations.display.property.select')), 1)
        ]),
        _createElementVNode("th", null, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('annotations.display.property.expander')), 1)
        ]),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.color')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.highlight')), 1),
        (!_ctx.hideNote)
          ? (_openBlock(), _createElementBlock("th", _hoisted_4, _toDisplayString(_ctx.$t('annotations.display.property.note')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.citation')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.release')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t('annotations.display.property.lastUpdated')), 1),
        _createElementVNode("th", {
          class: _normalizeClass(_ctx.$style.centered)
        }, _toDisplayString(_ctx.$t('annotations.display.actions.label')), 3)
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.annotations, (annotation) => {
        return (_openBlock(), _createBlock(_component_ExportQueueTableRow, {
          key: annotation.uuid,
          annotation: annotation,
          hideNote: _ctx.hideNote,
          "onAnnotation:update": (checked) => _ctx.updateAnnotation(checked, annotation.uuid)
        }, null, 8, ["annotation", "hideNote", "onAnnotation:update"]))
      }), 128))
    ])
  ], 16))
}