import { C, Dictionary } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { APP } from './app';
import { PostishAnnotation } from './postish';
import { SentryCard, SentryHold, SentryLoan, SentryPossession, SentrySyncResponse, SyncPlan } from './sentry';
import { TaggishTagsResponse } from './taggish';

const SYNC_INTERVAL_MIN = C.MS_SECOND;
const SYNC_INTERVAL_FOREGROUND = 2 * C.MS_MINUTE;
const SYNC_INTERVAL_PERIODIC = 30 * C.MS_MINUTE;

type SyncTypes = 'loans' | 'holds' | 'preferences' | '_cards' | 'annotations' | 'tags';

interface SyncOperation {
  type: SyncTypes;
  wipeIfAnonymous: boolean;
  execute: () => Promise<void>;
}

export class SyncCoordinator {
  private _syncTimer = -1;
  private _lastSyncTimestamp = 0;
  private _fetchIdentityToken = false;
  private _syncActive = false;

  // Define all the sync operations
  private readonly _syncOperations: SyncOperation[] = [
    {
      type: '_cards',
      wipeIfAnonymous: false,
      execute: async () => this._consumeSyncData('sync', 'cards', await APP.sentry.sync('cards'))
    },
    {
      type: 'loans',
      wipeIfAnonymous: true,
      execute: async () => this._consumeSyncData('sync', 'loans', await APP.sentry.sync('loans'))
    },
    {
      type: 'holds',
      wipeIfAnonymous: true,
      execute: async () => this._consumeSyncData('sync', 'holds', await APP.sentry.sync('holds'))
    },
    {
      type: 'annotations',
      wipeIfAnonymous: true,
      execute: async () => this._consumeSyncData('sync', 'annotations', await APP.services.postish.getAnnotations())
    },
    {
      type: 'tags',
      wipeIfAnonymous: true,
      execute: async () => this._consumeSyncData('sync', 'tags', await APP.services.taggish.getTags())
    }
  ];


  public activate(): void {
    this._scheduleSync();
  }


  /**
   * Initiate a full sync
   *
   * @param {RemoteSyncOptions} options ignore frequence check, request will still be ignored if offline or another sync in progress
   */
  public remoteSync(options: RemoteSyncOptions = {}): Promise<void> {
    if (!APP.network.reachable) {
      console.warn('[SYNC] ignored because network unreachable');
    } else if (this.isSyncActive()) {
      console.warn('[SYNC] ignored because active sync request');
    } else if (this._syncedSince(options.freshness || SYNC_INTERVAL_MIN) && !options.force) {
      console.warn('[SYNC] ignored because of recent sync');
    } else if (!APP.sentry.identityToken) {
      console.warn('[SYNC] ignored because we do not have an identity token');
    } else {
      clearTimeout(this._syncTimer);
      this._syncTimer = window.setTimeout(
        this.remoteSync.bind(this),
        SYNC_INTERVAL_PERIODIC
      );

      return this._remoteSync(options?.type);
    }

    return Promise.resolve();
  }


  /**
   * This is called when authentication is complete.  Data related
   * to the newly linked library is returned when authentication is
   * succesful.  So, we can add that library's card information, loans,
   * and holds to the patron without the need for another sync
   *
   * @param syncData cards, holds, loans for newly linked account
   */
  public async syncWithAuthData(syncData: SentrySyncResponse) {
    this._consumeSyncData('subsume', 'cards', syncData.cards);
    this._consumeSyncData('subsume', 'loans', syncData.loans);
    this._consumeSyncData('subsume', 'holds', syncData.holds);

    try {
      // Remote sync for any data we didn't get from the auth result
      await this._processSyncOperations(this._syncOperations.filter((op) => {
        return ['cards', 'loans', 'holds'].indexOf(op.type) === -1;
      }));
    } catch (ex) {
      console.warn('Error with sync: %O', ex);
    }
    APP.events.dispatch('patron:sync:complete');
  }


  public isSyncActive(): boolean {
    return this._syncActive;
  }


  protected _scheduleSync() {
    APP.events.on('msg:client:view:foreground', () => this._syncOnForeground());
  }


  protected _syncOnForeground() {
    if (APP.patron.isAuthenticated() && !this._syncedSince(SYNC_INTERVAL_FOREGROUND)) {
      this.remoteSync();
    }
  }


  protected async _processSyncOperations(operations: SyncOperation[]) {
    await Promise.all(operations.map((operation) => {
      console.log('[SYNC] sync: ' + operation.type);

      return operation.execute();
    }));
  }


  /**
   * Perform a complete sync for the current chip.  First call
   * Sentry to get latest information about cards and then
   * use that data to get the rest of the needed data.
   *
   * TODO:
   *   - Make sure we have an update to date identity token before
   *     calling services that use it for authentication (Postish, ...)
   */
  protected async _remoteSync(ops?: SyncTypes[]): Promise<void> {
    this._syncActive = true;
    const operations = ops ? this._syncOperations.filter((op) => ops.includes(op.type)) : this._syncOperations;

    try {
      await this._processSyncOperations(this._syncOperations.filter((op) => op.type === '_cards'));
      console.log('[SYNC] cards: %O', APP.patron.libraryCards());
      if (APP.patron.isAuthenticated()) {
        console.log('[SYNC] sync other stuff');
        await this._processSyncOperations(operations.filter((op) => op.type !== '_cards'));
      } else {
        // If the sync on library cards returned an empty array, user is not authenticated
        // so make sure all other user content is empty.
        this._syncOperations.forEach((operation) => {
          console.log('[SYNC] Checking type: ' + operation.type);
          const patronData = APP.patron[operation.type];
          if (operation.wipeIfAnonymous && 'removeAll' in patronData) {
            patronData.removeAll();
          }
        });
      }
    } catch (ex) {
      console.warn('Error with sync: %O', ex);
    }

    this._syncActive = false;
    this._syncDone();
  }


  protected _syncDone() {
    console.log('[SYNC] Sync is done!');
    this._lastSyncTimestamp = C.epochMilliseconds();
    APP.events.dispatch('patron:sync:complete');
    // Skip fetch on local auth
    if (this._fetchIdentityToken) {
      APP.sentry.fetchIdentityToken();
    }
    this._fetchIdentityToken = true; // Reset for all future syncs
  }


  protected _syncedSince(ms: number) {
    const gap = C.epochMilliseconds() - (this._lastSyncTimestamp || 0);

    return gap <= ms;
  }


  /**
   * Update given item with the data retrieved
   *
   * TODO: Consider moving to a pattern like Sora where the patron or item handles the consuming
   * of the sync data
   * @param operation 'sync' or 'subsume'
   * @param type one of APP.sentry.SYNC_OPERATIONS
   * @param syncData data returned by sentry, in JSON format
   */
  protected _consumeSyncData<K extends keyof AllSyncPlan>(operation: 'sync' | 'subsume', type: K, syncData: AllSyncPlan[K]): void {
    console.log('[SYNC] type: ' + type + ' syncData: %O', syncData);
    if (type === 'cards') {
      APP.patron.libraryCards()[operation](syncData as SentryCard[]);
    } else if (type === 'loans') {
      const loanData = this._filterLoansAndHoldsByCardAndFormat(syncData as SentryLoan[]);
      APP.patron.loans[operation](loanData.compatible);
      APP.patron.suppressedLoans[operation](loanData.suppressed);
    } else if (type === 'holds') {
      const holdData = this._filterLoansAndHoldsByCardAndFormat(syncData as SentryHold[]);
      APP.patron.holds[operation](holdData.compatible);
      APP.patron.suppressedHolds[operation](holdData.suppressed);
    } else if (type === 'annotations') {
      APP.patron.annotations[operation]((syncData as PostishAnnotation[]));
    } else if (type === 'tags') {
      APP.patron.tags[operation]((syncData as TaggishTagsResponse).tags.items);
    }
  }


  protected _filterLoansAndHoldsByCardAndFormat(items: SentryPossession[]) {
    const out: Dictionary<SentryPossession[]> = { compatible: [], suppressed: [] };
    const currentCard = APP.patron.currentCard();
    C.each(items, (item) => {
      if (!item) {
        return;
      }
      if (currentCard) {
        if (currentCard.cardId === parseInt(item.cardId, 10)) {
          if (item.isBundledChild) {
            // Noop
          } else if (this._isCompatibleItemByFormat(item)) {
            out.compatible.push(item);
          } else {
            out.suppressed.push(item);
          }
        }
      }
    });

    return out;
  }


  protected _isCompatibleItemByFormat(item: SentryPossession) {
    const validFormatIds = [...Constants.SUPPORTED_FORMATS, 'ntc-subscription'];
    const validTypeIds = ['ebook', 'audiobook', 'external service'];
    const fmt = item.overDriveFormat;
    if (fmt && validFormatIds.indexOf(fmt.id) >= 0) {
      return true; // this should catch all loans
    }
    if (item.otherFormats && item.otherFormats.length) {
      return item.otherFormats.some((f) => validFormatIds.indexOf(f.id) >= 0);
    }
    if (item.type && validTypeIds.indexOf(item.type.id) >= 0) {
      return true; // this is for the benefit of holds
    }

    return false;
  }
}

export interface RemoteSyncOptions {
  force?: boolean;
  freshness?: number;
  type?: SyncTypes[];
}

type AllSyncPlan = SyncPlan & {
  'annotations': PostishAnnotation[];
  'tags': TaggishTagsResponse;
};
