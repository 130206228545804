import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { PostishCopyJobResponse } from 'app/base/postish';
import { computed, ref } from 'vue';

type CopyJobMap = {[id: string]: PostishCopyJobResponse};

export const buildCopyJobContext = () => {
  const copyJobMap = ref<CopyJobMap>({});
  const copyJobs = computed(() => Object.values(copyJobMap.value));

  // Wrapped Postish functions to interact with shared map

  const getAllCopyJobs = async () => {
    const allJobs = await APP.services.postish.getAllCopyJobs();
    if (!allJobs) { throw new Error; }

    const mapped = allJobs.reduce((acc, job) => {
      acc[job.id] = job;

      return acc;
    }, {} as CopyJobMap);

    copyJobMap.value = mapped;
  };

  const getCopyJob = async (jobId: string) => {
    const response = await APP.services.postish.getCopyJob(jobId);
    if (!response) { throw new Error; }

    copyJobMap.value[response.id] = response;
  };

  const startCopyJob = async (targetId: string, releaseId: string) => {
    const response = await APP.services.postish.startCopyJob(targetId, releaseId);
    if (!response) { throw new Error; }

    copyJobMap.value[response.id] = response;

    const hasInProgress = await checkForInProgressJobs();
    if (hasInProgress) { poll(); }
  };

  const reviewCopyJob = async (jobId: string) => {
    const response = await APP.services.postish.reviewCopyJob(jobId);
    if (!response) { throw new Error; }

    copyJobMap.value[response.id] = response;
  };

  const retryCopyJob = async (jobId: string) => {
    const response = await APP.services.postish.retryCopyJob(jobId);
    if (!response) { throw new Error; }

    copyJobMap.value[response.id] = response;

    const hasInProgress = await checkForInProgressJobs();
    if (hasInProgress) { poll(); }
  };

  const deleteCopyJob = async (jobId: string) => {
    await APP.services.postish.deleteCopyJob(jobId);
    delete copyJobMap.value[jobId];
  };


  // Polling for in progress jobs

  const pollingPeriod = 2 * C.MS_SECOND;
  let pollingTimeout: ReturnType<typeof setTimeout> | null = null;

  const checkForInProgressJobs = async () => {
    await getAllCopyJobs();
    const hasInProgress = copyJobs.value.some((job) => job.status === 'IN_PROGRESS' || job.status === 'QUEUED');

    return hasInProgress;
  };

  const poll = async () => {
    if (pollingTimeout) { return; }

    pollingTimeout = setTimeout(async () => {
      pollingTimeout = null;

      const hasInProgress = await checkForInProgressJobs();

      if (hasInProgress) {
        poll();
      }
    }, pollingPeriod);
  };

  // Syncing with Postish at regular intervals

  const syncPeriod = 5 * C.MS_MINUTE;
  let syncInterval: ReturnType<typeof setInterval> | null = null;

  const stopSync = () => {
    if (syncInterval) {
      clearInterval(syncInterval);
      syncInterval = null;
    }
  };

  const startSync = async () => {
    stopSync();

    await getAllCopyJobs();
    syncInterval = setInterval(getAllCopyJobs, syncPeriod);
  };

  return {
    copyJobMap,
    copyJobs,
    getAllCopyJobs,
    getCopyJob,
    startCopyJob,
    reviewCopyJob,
    retryCopyJob,
    deleteCopyJob,
    startSync
  };
};
