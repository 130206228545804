<template>
  <Teleport
    to="#prompts"
  >
    <dialog
      ref="dialog"
      :style="`--animation-duration: ${animationDurationMs}ms`"
      :class="{
        [$style.dialog]: true,
        [$style.closing]: closing
      }"
      :aria-label="heading"
      @cancel="closeIfAllowed"
      @click="closeIfAllowed"
      @keydown.esc="closeIfAllowed"
    >
      <div
        :class="$style.contentWrapper"
        @click="(e) => e.stopPropagation()"
      >
        <div :class="$style.notch"></div>
        <form
          :class="$style.container"
          @submit.prevent="$emit('submit')"
        >
          <h2
            :class="$style.heading"
          >
            {{ heading }}
          </h2>
          <slot></slot>
        </form>
      </div>
    </dialog>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref, SetupContext } from 'vue';

export default defineComponent({
  props: {
    heading: {
      type: String,
      required: true
    },
    /* Controls whether the escape key and clicking the backdrop close the dialog.
     * In most cases, just let this default to 'true' for better usability.
     */
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close',
    'submit'
  ],
  setup: (props, ctx: SetupContext) => {
    const animationDurationMs = 300;

    const dialog = ref<HTMLDialogElement | null>(null);

    const closing = ref<boolean>(false);

    const closeModal = () => {
      closing.value = true;
      setTimeout(() => {
        closing.value = false;
        ctx.emit('close');
      }, animationDurationMs);
    };

    const closeIfAllowed = (e: Event) => {
      e.preventDefault();

      if (props.dismissible) {
        closeModal();
      }
    };

    onMounted(() => {
      dialog.value?.showModal();
    });

    onBeforeUnmount(() => {
      dialog.value?.close();
    });

    ctx.expose({
      closeModal
    });

    return {
      animationDurationMs,
      dialog,
      closing,
      closeModal,
      closeIfAllowed
    };
  }
});
</script>

<style module>
.dialog {
  box-sizing: border-box;
  padding: 0;
  margin-top: 0;
  border: 0;
  min-width: 100%;
  max-height: 100%;
  overflow: auto;
  border-radius: var(--form-border-radius);
  box-shadow: 0 2px 8px var(--c-darkest-gray);
}

.dialog::backdrop {
  background-color: rgba(0.2, 0.2, 0.2, 0.2);
}

.notch {
  background-color: var(--c-white);
  composes: notch from global;
}

.content-wrapper {
  background-color: var(--c-white);
}

.container {
  padding: 2rem 5.5rem;
  width: 100%;
  box-sizing: border-box;
  font-weight: var(--fw-regular);

  display: grid;
  grid-auto-flow: row;
  row-gap: 1.5rem;
}

.container,
.container > :not(.actions) {
  max-width: max-content;
}

@media screen and (max-width: 1200px /* @px-vp-hide-nav */) {
  .container {
    padding: 2rem;
  }
}

.heading {
  font-size: var(--fs-large-body-head);
  font-weight: var(--fw-bold);
}

/* Animation properties */
.dialog {
  animation: open-animation var(--animation-duration) ease;
}

.dialog.closing {
  animation: close-animation var(--animation-duration) ease forwards;
}

.dialog::backdrop {
  animation: backdrop-fade-in var(--animation-duration) ease;
}

.dialog.closing::backdrop {
  animation: backdrop-fade-out var(--animation-duration) ease forwards;
}

/* Animation keyframes */

@keyframes open-animation {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes close-animation {
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    display: none;
  }
}

@keyframes backdrop-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes backdrop-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }

  100% {
    display: none;
  }
}
</style>
