<template>
  <template
    v-for="(part, index) in listParts"
    :key="index"
  >
    <router-link
      v-if="part.to"
      :class="itemClass"
      :to="part.to"
    >
      {{ part.text }}
    </router-link>
    <span v-else>
      {{ part.text }}
    </span>
  </template>
</template>

<script lang="ts">
import { useI18n } from 'app/functions/use-i18n';
import { listFormatterParts } from 'app/i18n/list-formatter';
import { PropType, computed, defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';


type RouteListItem = {
  text: string;
  to: RouteLocationRaw;
};


export default defineComponent({
  name: 'RouteList',
  props: {
    items: {
      type: Array as PropType<RouteListItem[]>,
      required: true
    },
    itemClass: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { locale } = useI18n();

    const listParts = computed(() => {
      const parts = listFormatterParts(props.items.map((i) => i.text), locale);

      // Use an index instead of find to avoid multiple iterations and handle duplicate words
      let itemIndex = 0;

      return parts.map((p) => {
        if (p.type === 'element') {
          const item = props.items[itemIndex];
          itemIndex++;

          return {
            text: p.value,
            to: item.to
          };
        }

        return {
          text: p.value
        };
      });
    });

    return {
      listParts
    };
  }
});
</script>
