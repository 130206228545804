<template>
  <header :class="$style.header">
    <div :class="$style.actions">
      <BreadcrumbNavigation
        :class="$style.breadcrumbs"
        :title="header"
        :breadcrumbList="breadcrumbs"
      />
      <TitleActionsContextMenuButton
        :title="title"
      />
    </div>
    <div :class="$style.info">
      <Cover
        v-if="!isMobile"
        :class="$style.cover"
        :item="title"
      />
      <div :class="$style.details">
        <h1 :class="$style.title">
          <router-link
            :to="detailsPage"
            class="focus-outline dark"
          >
            <span v-html="header"></span>
          </router-link>
        </h1>
        <p
          v-if="subtitle"
          :class="$style.subtitle"
          v-html="subtitle"
        >
        </p>
        <dl
          v-if="metadata && metadata.edition"
          :class="$style.metadata"
        >
          <div
            v-if="metadata.edition"
            :class="$style.metadataItem"
          >
            <dt :class="$style.metadataLabel">
              {{ $t('pageHeader.metadata.edition') }}
            </dt>
            <dd
              :class="$style.metadataValue"
              v-html="metadata.edition"
            >
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import BreadcrumbNavigation from 'app/components/BreadcrumbNavigation.vue';
import TitleActionsContextMenuButton from 'app/components/contextMenus/TitleActionsContextMenuButton.vue';
import Cover from 'app/components/Cover.vue';
import { useBreadcrumbs } from 'app/functions/use-breadcrumbs';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Title } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    BreadcrumbNavigation,
    Cover,
    TitleActionsContextMenuButton
  },
  props: {
    header: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    title: {
      type: Object as PropType<Title>,
      required: true
    }
  },
  setup: (props, ctx) => {
    const { windowWidth } = useWindowSize();
    const isMobile = computed(() => windowWidth.value <= Breakpoint.VeryWide);

    const breadcrumbs = useBreadcrumbs();

    const metadata = computed(() => props.title.lexisMetadata);

    const detailsPage = computed(() => {
      return {
        name: RouteName.TitleDetails,
        params: { titleSlug: props.title.slug }
      };
    });

    return {
      breadcrumbs,
      detailsPage,
      isMobile,
      metadata
    };
  }
});
</script>

<style module>
.actions {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 1.5rem;
}

.breadcrumbs {
  margin-left: -0.5rem;
}

.info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}

.cover {
  --cover-width: 6rem;
  width: var(--cover-width);
  height: calc(4/3 * var(--cover-width));
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.title {
  font-size: var(--fs-large-head);
  font-weight: var(--fw-bold);
  line-height: 1.25;
}

.subtitle {
  line-height: 1.25;
}

.metadata {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
}

.metadata-item {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  font-size: var(--fs-metadata);
}

.metadata-label {
  color: var(--c-dark-gray);
  text-transform: uppercase;
}

.metadata-value {
  color: var(--c-white);
}
</style>
