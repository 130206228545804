<template>
  <form
    :class="$style.container"
    @submit.prevent="apply"
  >
    <section :class="$style.contentContainer">
      <div :class="$style.section">
        <Card
          :item="titleCard"
          :class="$style.card"
        >
          <template #cover>
            <Cover
              :item="title"
            />
          </template>
        </Card>
      </div>

      <div :class="$style.section">
        <p :class="$style.header">
          {{ $t('annotationCopying.manualCopy.source.header') }}
        </p>
        <FormSelect
          v-model="selectedRelease"
          :options="releaseOptions"
          :prompt="$t('annotationCopying.manualCopy.source.placeholder')"
          :optionLabel="'displayName'"
          :optionKey="(r) => r.id"
          :classOverrides="{ 'highlighted': $style.highlighted, 'option': $style.option }"
          :class="$style.dropdown"
        >
          <template
            v-if="selectedRelease"
            #countWithLabel
          >
            <span :class="['badge', $style.badgeWithLabel]">{{ $t('annotationCopying.manualCopy.count', { N: selectedRelease.count }) }}</span>
          </template>
          <template
            v-for="option in releaseOptions"
            :key="option.id"
            #[`count:${option.id}`]
          >
            <span :class="$style.optionBadge">{{ option.count }}</span>
          </template>
        </FormSelect>
        <p :class="$style.additional">
          {{ $t('annotationCopying.manualCopy.source.additional') }}
        </p>
      </div>

      <div :class="$style.section">
        <p :class="$style.header">
          {{ $t('annotationCopying.manualCopy.destination.header') }}
        </p>
        <div :class="$style.destination">
          <p>
            {{ newestRelease.name }}
          </p>
          <p :class="['badge', $style.badgeWithLabel]">
            {{ $t('annotationCopying.manualCopy.count', { N: newestRelease.count }) }}
          </p>
        </div>
        <p :class="$style.additional">
          {{ $t('annotationCopying.manualCopy.destination.additional') }}
        </p>
      </div>
    </section>

    <footer :class="$style.applyContainer">
      <FormSubmitButton
        :label="$t('annotationCopying.manualCopy.submit')"
        :disabled="!selectedRelease"
        :loading="loading"
        :loadingText="$t('annotationCopying.manualCopy.loading')"
        :class="!selectedRelease || loading ? $style.applyInactive : ''"
        :aria-label="!selectedRelease ? $t('annotationCopying.manualCopy.submitInactive') : ''"
        @click.prevent="apply"
      />
    </footer>
  </form>
</template>

<script lang="ts">
import Card from 'app/components/cards/Card.vue';
import Cover from 'app/components/Cover.vue';
import { DropdownOption } from 'app/components/Dropdown.vue';
import FormSelect from 'app/components/FormSelect.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import { useAnnotationCopying } from 'app/functions/use-annotation-copying';
import { useI18n } from 'app/functions/use-i18n';
import { getReleaseDisplayNameTitle } from 'app/functions/use-release-display-name';
import { FilterObject } from 'app/models/filter-object';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'ManualCopyContent',
  components: {
    Card,
    Cover,
    FormSelect,
    FormSubmitButton
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    releasesWithAnnotations: {
      type: Array as PropType<FilterObject[]>,
      required: true
    }
  },
  emits: [
    'loading',
    'submit'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();
    const loading = ref(false);

    const titleCard = computed(() => {
      return {
        id: props.title.slug,
        title: props.title.title,
        subtitle: props.title.subtitle
      };
    });

    const newestRelease = computed(() => {
      const name = getReleaseDisplayNameTitle(props.title);

      return {
        name: name,
        count: props.releasesWithAnnotations.find((release) => release.name === name)?.count || 0
      };
    });

    const releaseOptions = computed(() => {
      const opts = props.releasesWithAnnotations
      .filter((release) => release.name !== getReleaseDisplayNameTitle(props.title))
      .map((release) => {
        return {
          id: release.id,
          displayName: release.name,
          count: release.count,
          ariaLabel: t('annotationCopying.manualCopy.source.option', { RELEASE: release.name, N: release.count })
        } as DropdownOption;
      });

      return opts;
    });
    releaseOptions.value;

    const selectedRelease = ref<DropdownOption | null>(null);

    const { startCopyJob } = useAnnotationCopying();

    const apply = async () => {
      if (!selectedRelease.value) { return; }

      ctx.emit('loading');
      loading.value = true;
      // kick off new copy job
      await startCopyJob(props.title.slug, selectedRelease.value.id);

      // If the user starts a copy job, but hasn't opened the newest release yet,
      // switch them to the newest so they don't get new release / copying prompts
      const shouldUpdate = await props.title.isOutOfDate();

      if (shouldUpdate) {
        props.title.updateDownloads();
      }
      ctx.emit('submit');
    };

    return {
      apply,
      loading,
      newestRelease,
      releaseOptions,
      selectedRelease,
      titleCard
    };
  }
});
</script>

<style module>
.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.content-container {
  padding-bottom: 0;
  overflow-y: auto;
  grid-column: 1;
  grid-row: 1;
  margin-bottom: 0.25rem;
}

.section {
  margin: 2rem 1rem;
}

.section:first-child {
  margin-top: 1rem;
}

.header {
  font-size: var(--fs-medium);
  font-weight: var(--fw-medium);
  color: var(--c-medium-black);
  margin-bottom: 0.5rem;
}

.additional {
  color: var(--c-light-black);
  margin-top: 0.5rem;
}

.card {
  /* keep consistent with dropdown and .destination */
  border-radius: 0.25rem;
  border-color: var(--c-dark-gray);
}

.dropdown button {
  border: 1px solid var(--c-light-gray); /* add border to keep text vertically in line with .card and .destination */
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
}

.dropdown .badge-with-label { /* need to override global badge values */
  background-color: var(--c-dark-gray); /* contrast with FormSelect background-color */
  margin-left: auto;
  margin-right: 0.5rem;
}

.destination {
  composes: shadow-box from global;
  padding: 1rem;
 display: grid;
 grid-template-columns: 1fr auto;
}

.destination .badge-with-label { /* need to override global badge values */
  background-color: var(--c-dark-gray); /* match with one in dropdown */
}

.option-badge {
  composes: badge from global;
}

.apply-container {
  bottom: 0;
  position: sticky;
  border-top: 2px solid var(--c-light-gray);
  background-color: var(--c-white);
  padding: 2rem 1rem 1rem;
  grid-column: 1;
  grid-row: 2;
}

.apply-inactive {
  opacity: .6;
  cursor: default;
}

@media screen and (max-width: 360px) {
  .apply-container {
    padding: 1rem 1rem 0;
  }
}

/* Dropdown overrides */

.option {
  color: var(--c-dark-black);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.highlighted {
  background-color: var(--c-dark-black);
  color: var(--c-light-gray);
  composes: dark from global; /* to get the badge to be dark */
}

.option:hover {
  background-color: var(--c-dark-black);
  color: var(--c-light-gray);
}

.option:hover .option-badge {
  /* explicitly write out the dark badge styles, since it won't allow composes: dark from global; */
  color: var(--c-light-gray);
  background-color: var(--c-dark-badge);
  display: inline-block;
  padding: 5px;
  font-size: var(--fs-metadata);
  font-weight: var(--fw-medium);
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
}
</style>
