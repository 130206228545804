<template>
  <SidebarDialog
    :heading="$t(`searchThisTitle.header.${(item && item.mediaType === 'series') ? 'series' : 'title'}`)"
    @close="$emit('close')"
  >
    <SearchThisTitleContent
      :item="(item as TitleRecord | Series)"
      :params="params"
      :skipRouting="skipRouting"
      @close="$emit('close')"
    />
  </SidebarDialog>
</template>

<script lang="ts">
import { SearchThisTitleQuery } from 'app/base/hudson';
import SearchThisTitleContent from 'app/components/SearchThisTitleContent.vue';
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchThisTitleSidebar',
  components: {
    SearchThisTitleContent,
    SidebarDialog
  },
  props: {
    item: {
      type: Object as PropType<TitleRecord | Series>,
      required: true
    },
    params: {
      type: Object as PropType<SearchThisTitleQuery>,
      required: true
    },
    skipRouting: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close'
  ]
});
</script>

<style module>

</style>
