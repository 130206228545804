

  // Default precision is 5
  export function smoothFloat(rawFloat: number, precision?: number): number {
    const acc = Math.pow(10, precision || 5);

    return Math.round(rawFloat * acc) / acc;
  }


  export function numberWithCommas(num: number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
