import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { DervishResponse } from 'app/base/interfaces';
import { Possession } from 'app/models/possession';
import { TitleRecord } from 'app/models/title';
import { BifocalViewBase } from 'app/views/open/bifocal-view-base';
import { Loan } from '../../models/loan';

export class BifocalViewProxy extends BifocalViewBase {
  private _backgrounded: boolean;


  constructor() {
    super();
    APP.events.on('msg:network:info', (evt) => this._onNetworkInfo(evt));
    APP.events.on('loan:download:commence', (evt) => this._onDownloadState(evt.m));
    APP.events.on('loan:download:wipe', (evt) => this._onDownloadState(evt.m));
    APP.events.on('title:switch', (evt) => this._onTitleSwitch(evt));
    APP.events.on('msg:bifocal:client:query', (evt) => this._sendData(evt.m));
  }


  public open(title: TitleRecord, possession: Possession, dervishData: DervishResponse, anchorID?: string): void {
    this._backgrounded = false;
    this._title = title;
    this._possession = possession;
    this._urls = dervishData.urls;

    // The openbookURL should only contain the message value in the query string if it is a
    // streaming title.  Nautilus uses the presence of a query string to determine if it should
    // try to proxy the request or not.  If the query string exists it will not proxy the request
    // and therefore never looked for a cached copy.  downloader.cachedURLs() is where we decide
    // if the cached copy should be used.  If will either return the URLs without a message (if
    // we are using the cached copy) or return null, which tells the app to get the URLs from
    // Sentry.  The sentry response will include the message value, which will then tell Nautilus
    // to not proxy the request.  It is that simple...
    this.lastOpenMessage = {
      name: 'bifocal:view:open',
      url: this._urls.web,
      openbookURL: this._urls.openbook + (dervishData.message ? '?' + dervishData.message : ''),
      format: title.mediaType,
      reload: false
    };
    APP.shell.transmit(this.lastOpenMessage);
    this.lastOpenMessage.time = (new Date()) + '';

    // Because of the way the shell caches the urls, we need to
    // have Bifocal seek to the anchor through a message and can't rely
    // on Dervish handling it.
    if (anchorID) {
      this.seekToAnchor(title.slug, possession, anchorID);
    }
  }


  public reload(): void {
    if (this._title && this._urls) {
      APP.shell.transmit({
        name: 'bifocal:view:open',
        url: this._urls.web,
        openbookURL: this._urls.openbook,
        format: this._title.mediaType,
        reload: true
      });
    } else {
      console.warn('[BIFOCAL-VIEW-PROXY] cannot reload - view has been reset');
    }
  }


  public reveal(): void {
    this._backgrounded = false;
    this._transmitNetworkInfo();
    APP.shell.transmit({ name: 'bifocal:view:reveal' });
  }


  public conceal(): void {
    this._backgrounded = true;
    APP.shell.transmit({ name: 'bifocal:view:conceal' });
    this.clear();
  }


  public clear(): void {
    APP.shell.transmit({ name: 'bifocal:view:clear' });
    super.clear();
  }


  public transmit(object: {}): void {
    const detail = C.absorb(object, { dest: 'bifocal' });
    APP.shell.dispatchMessageObjectToShell(detail);
  }


  public getUrl(): string {
    return this._urls?.web || this.lastOpenMessage.url;
  }


  protected _onNetworkInfo(evt: Event): void {
    this._transmitNetworkInfo();
  }


  protected _transmitNetworkInfo(): void {
    if (this._title) {
      this.transmit({
        name: 'network:info',
        source: 'client',
        reachable: APP.network.reachable
      });
    }
  }


  protected _onDownloadState(evt: { loan: Loan }): void {
    const psn = evt.loan;
    if (!psn.removedFromCollation && this.isOpen(psn.titleSlug, psn) && this._backgrounded) {
      this.clear();
    }
  }


  protected _onTitleSwitch(evt): void {
    if (!evt.m.codex) {
      this.clear();
    }
  }
}
