import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_component_Icon, {
    name: "dl-downloading",
    class: _normalizeClass(_ctx.$style.icon),
    style: _normalizeStyle(`--offset:${_ctx.progressOffset}`)
  }, null, 8, ["class", "style"]))
}