import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withModifiers as _withModifiers, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "type", "autocomplete", "aria-invalid", "autofocus", "required", "aria-required", "aria-describedby", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FormError = _resolveComponent("FormError")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([
        _ctx.$style.wrapper,
        _ctx.focused ? _ctx.$style.focus : '',
        _ctx.active ? _ctx.$style.active : '',
        _ctx.shownError ? _ctx.$style.error : ''
      ]),
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args)))
    }, [
      _createElementVNode("label", {
        class: _normalizeClass(_ctx.$style.label),
        for: _ctx.id
      }, _toDisplayString(_ctx.label), 11, _hoisted_1),
      _withDirectives(_createElementVNode("input", {
        id: _ctx.id,
        ref: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalInput) = $event)),
        type: _ctx.fieldType,
        autocomplete: _ctx.autocomplete,
        spellcheck: false,
        autocorrect: "off",
        autocapitalize: "off",
        "aria-invalid": !!_ctx.shownError,
        autofocus: _ctx.autofocus,
        required: _ctx.required,
        "aria-required": _ctx.required,
        "aria-describedby": _ctx.describedBy,
        class: _normalizeClass(_ctx.$style.input),
        name: _ctx.formName,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focused = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focused = false)),
        onInvalid: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onInvalid && _ctx.onInvalid(...args)), ["prevent"]))
      }, null, 42, _hoisted_2), [
        [
          _vModelDynamic,
          _ctx.internalInput,
          void 0,
          { trim: true }
        ]
      ]),
      (_ctx.type === 'password')
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: _normalizeClass(_ctx.$style.showPassword)
          }, [
            (_openBlock(), _createBlock(_component_Icon, {
              key: _ctx.showPassword,
              name: _ctx.showPassword ? 'eye-closed' : 'eye-open'
            }, null, 8, ["name"])),
            (_ctx.type === 'password')
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showPassword) = $event)),
                  type: "checkbox",
                  "aria-hidden": "true",
                  hidden: "true"
                }, null, 512)), [
                  [_vModelCheckbox, _ctx.showPassword]
                ])
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.shownError)
      ? (_openBlock(), _createBlock(_component_FormError, {
          key: 0,
          class: _normalizeClass(_ctx.$style.error),
          contents: _ctx.shownError
        }, null, 8, ["class", "contents"]))
      : _createCommentVNode("", true)
  ]))
}