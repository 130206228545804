import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["id", "role", "aria-label", "aria-describedby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createBlock(_Teleport, { to: _ctx.teleportTo }, [
    _createVNode(_Transition, {
      enterActiveClass: `${_ctx.$style.enter} ${_ctx.$style[_ctx.transition]}`,
      leaveActiveClass: `${_ctx.$style.leaveTo} ${_ctx.$style[_ctx.transition]}`
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref: "parent",
              class: _normalizeClass(_ctx.$style.overlay)
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.escapeDeactivates ? 'button' : 'div'), {
                class: _normalizeClass(_ctx.$style.shield),
                "aria-label": _ctx.$t('general.close'),
                onClick: _ctx.close
              }, null, 8, ["class", "aria-label", "onClick"])),
              (_openBlock(), _createBlock(_component_FocusTrap, {
                key: _ctx.header,
                initialFocus: _ctx.initialFocus,
                escapeDeactivates: _ctx.escapeDeactivates,
                onDeactivate: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    id: _ctx.id,
                    class: _normalizeClass([_ctx.$style.content, _ctx.contentClass]),
                    role: _ctx.type,
                    "aria-modal": "true",
                    "aria-label": _ctx.header,
                    "aria-describedby": _ctx.describedBy
                  }, [
                    _renderSlot(_ctx.$slots, "default")
                  ], 10, _hoisted_1)
                ], undefined, true),
                _: 3
              }, 8, ["initialFocus", "escapeDeactivates", "onDeactivate"]))
            ], 2))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 3
    }, 8, ["enterActiveClass", "leaveActiveClass"])
  ], 8, ["to"]))
}