<template>
  <ol
    v-if="titles && titles.length"
    :class="$style.list"
  >
    <li
      v-for="title in titles"
      v-show="filter(title)"
      :key="title.slug"
    >
      <TitleCard :title="title" />
    </li>
  </ol>
</template>

<script lang='ts'>
import TitleCard from 'app/components/cards/TitleCard.vue';
import { TitleRecord } from 'app/models/title';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MyBooksTabPanel',
  components: {
    TitleCard
  },
  props: {
    titles: {
      type: Array as () => TitleRecord[] | null,
      default: null
    },
    filter: {
      type: Function,
      required: true
    }
  }
});
</script>

<style module>
.list > li {
  margin: 2rem 0;
}

.list > li:first-child {
  margin: 0;
}
</style>
