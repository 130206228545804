import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSelect = _resolveComponent("FormSelect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("label", {
      id: _ctx.labelElementId,
      class: _normalizeClass({
        [_ctx.$style.label]: true,
        'visually-hidden': _ctx.hideLabelOnSmallScreens && _ctx.hideLabel
      })
    }, _toDisplayString(_ctx.label), 11, _hoisted_1),
    _createVNode(_component_FormSelect, {
      modelValue: _ctx.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      class: _normalizeClass(_ctx.$style.dropdown),
      options: _ctx.options,
      optionLabel: _ctx.optionLabel,
      optionKey: _ctx.optionKey,
      labelId: _ctx.labelElementId,
      prompt: _ctx.prompt
    }, null, 8, ["modelValue", "class", "options", "optionLabel", "optionKey", "labelId", "prompt"])
  ], 2))
}