import * as C from 'lib/common';
import { Dictionary } from 'lib/common/dictionary';
import { Quirks } from 'lib/quirkbase/quirkbase';
import { Constants } from './constants';
import { ContactHandler, ContactHandlerOptions } from './contact-handler';
import { EventHandler, GlobalEventHandler, Handler, TapHandler } from './handler';
import { ScrollManager } from './scroll-manager';
import { TapOptions } from './tap-interfaces';

/**
 * The default dispatcher
 *
 * For `listen`, `deafen` and `dispatch`, the element you want the event
 * to be dispatched on is optional - sometimes you just want "global" events.
 * So, if you omit the element argument, the `DEFAULT_DISPATCHER` element
 * will be used instead. (Events.is usually `document.documentElement`,
 * but you can reconfigure the Events.DEFAULT_DISPATCHER constant
 * if you insist. Note that 'window' has a dispatchEvent flaw in
 * Android 2.3 -- events without listeners return false.)
 *
 */
export class Events<T> {
  public static readonly DEFAULT_DISPATCHER = document.documentElement;
  public static readonly GLOBAL_EVENTS = new Events<GlobalEventHandlersEventMap>();


  /**
   * Enable handlers/contact handlers on elements in `hitTarget` to handle pointer events.
   * Disallow handlers/contact handlers on elements in `releaseTarget` but outside `hitTarget`
   * from handling pointer events. Instead, `onExit()` is called.
   *
   * NB: This can't be used for blur handling of form input fields on iOS (needed b/c iOS needs
   * an explicit blur handler for input fields, otherwise clicking outside the input field doesn't
   * blur the field). When the user presses 'enter' in an input field to submit the form,
   * a 'click' event is sent to the submit button. However, because the input field is active,
   * these handlers are active, and the click handler stops the click event from propagating,
   * preventing form submission.
   * @param hitTarget
   * @param releaseTarget
   * @param onExit
   */
  public limitPointerEvents(
    hitTarget: HTMLElement,
    releaseTarget: HTMLElement = Events.DEFAULT_DISPATCHER,
    onExit: Function
  ): EventHandler<GlobalEventHandlersEventMap, PointerEvents>[] {
    const isInHitTarget = (evt: UIEvent) => {
      let pa = <Node | null>evt.target;
      while (pa) {
        if (pa === hitTarget) { return true; }
        pa = pa.parentNode;
      }
    };
    const downFn = (evt: UIEvent) => {
      if (isInHitTarget(evt)) { return; }
      this.stop(evt);
    };
    const upFn = (evt: UIEvent) => {
      if (isInHitTarget(evt)) { return; }
      onExit();
    };
    const clickFn = (evt: UIEvent) => {
      if (!isInHitTarget(evt)) {
        this.stop(evt);
      }
    };
    const handlers: EventHandler<GlobalEventHandlersEventMap, PointerEvents>[] = [];

    // We have to cover all event types because PEP doesn't support the capture phase yet.
    C.each(['pointerdown', 'mousedown', 'touchstart'],
      (evtType: PointerEvents) => handlers.push(Events.GLOBAL_EVENTS.on(evtType, downFn, releaseTarget, true)));
    C.each(['pointerup', 'mouseup', 'touchend'],
      (evtType: PointerEvents) => handlers.push(Events.GLOBAL_EVENTS.on(evtType, upFn, releaseTarget, true)));
    handlers.push(<EventHandler<GlobalEventHandlersEventMap, PointerEvents>>Events.GLOBAL_EVENTS.on('click', clickFn, releaseTarget, true));

    return handlers;
  }


  /**
   * Add an event listener.
   * @param evtType
   * @param fn
   * @param element
   * @param useCapture
   */
  public listen<E extends EventType<T>>(
    evtType: E,
    fn: EventCallback<T, E>,
    element: HTMLElement | SVGElement | Window = Events.DEFAULT_DISPATCHER,
    useCapture = false
  ): void {
    element.addEventListener(evtType, <(evt: Event) => void>fn, useCapture);
  }


  /**
   * Remove an event listener.
   * @param evtType
   * @param fn
   * @param element
   * @param useCapture
   */
  public deafen<E extends EventType<T>>(
    evtType: E,
    fn: EventCallback<T, E>,
    element: HTMLElement | SVGElement | Window = Events.DEFAULT_DISPATCHER,
    useCapture = false
  ): void {
    element.removeEventListener(evtType, <(evt: Event) => void>fn, useCapture);
  }


  /**
   * Fire an event on the element.
   *
   * The data supplied will be available in the event object in the 'm'
   * property -- eg, alert(evt.m) --> 'foo'
   * @param evtType
   * @param data
   * @param element If no element, use 'undefined'
   * @param cancelable
   */
  public dispatch<E extends StringIndex<T>>(
    evtType: E,
    data?: T[E],
    element: HTMLElement = Events.DEFAULT_DISPATCHER,
    cancelable = false
  ): boolean {
    const evt = document.createEvent('Events');
    evt.initEvent(evtType, false, cancelable);
    if (data) {
      (evt as DataEventMap<T>[E]).m = data;
    }

    return element.dispatchEvent(evt);
  }


  /**
   * Prevents the browser-default action on an event and stops it from
   * propagating up the DOM tree.
   * @param evt
   */
  public stop(evt: Event = window.event): void {
    if (evt.preventDefault) { evt.preventDefault(); }
    if (evt.stopPropagation) { evt.stopPropagation(); }
    if (evt.stopImmediatePropagation) { evt.stopImmediatePropagation(); }
    evt.cancelBubble = true;
  }


  /**
   * Listen for a tap or a click event.
   *
   * Options:
   * - 'tapClass' - Sets a class on the element. Defaults to 'tappable'.
   *      Can pass a falsey value (null, blank string) to avoid setting class.
   *
   * - 'ariaRole' - Sets the ARIA role. This is 'button' by default, but
   *      you can pass another string, or you can pass null to prevent
   *      any role being assigned.
   *
   * - 'propagate' - If false, the pointerdown event will not be propagated
   *      up the element stack. True by default.
   *
   * @param callback
   * @param element
   * @param options
   * @returns a handler on which you can call deafen() and listen()
   */
  public onTap(callback: GlobalEventCallback<'click'>, element: HTMLElement | SVGElement, options: TapOptions = {}): TapHandler {
    // Assign the tap class:
    if (typeof options.tapClass === 'undefined') {
      options.tapClass = Constants.TAPPABLE_CLASS;
    }
    if (options.tapClass) {
      // IE11 has a problem with the class list being null/undefined
      if (element.classList) {
        element.classList.add(options.tapClass);
      } else {
        element.setAttribute('class', `${element.getAttribute('class')} ${options.tapClass}`);
      }
    }

    // Assign the ARIA role:
    if (typeof options.ariaRole === 'undefined') {
      options.ariaRole = 'button';
    }
    if (options.ariaRole) {
      element.setAttribute('role', options.ariaRole);
    }

    // Assign the default propagate value:
    if (typeof options.propagate === 'undefined') {
      options.propagate = true;
    }

    const onClick = (evt: MouseEvent & { tappedElement?: Element }) => {
      evt.tappedElement = element;
      callback(evt);
    };

    // Set up the listen/deafen result:
    const out: TapHandler = {
      listen: () => Events.GLOBAL_EVENTS.listen('click', onClick, element),
      deafen: () => Events.GLOBAL_EVENTS.deafen('click', onClick, element)
    };

    if (!options.propagate || Quirks.has('active-pseudoclass-needs-touchstart')) {
      const handler = new ContactHandler({
        start: (evt) => {
          if (options.propagate) {
            return;
          }
          if (typeof evt.stopPropagation === 'function') {
            evt.stopPropagation();
          }
        }
      }, element);
      out.listen = () => {
        handler.listen();
        Events.GLOBAL_EVENTS.listen('click', onClick, element);
      };
      out.deafen = () => {
        handler.deafen();
        Events.GLOBAL_EVENTS.deafen('click', onClick, element);
      };
    }

    // Start listening for clicks:
    out.listen();

    return out;
  }


  /**
   * Create a Handler for an event. If you pass only one argument,
   * it will be treated as a handler, or an array of handlers, or a hash
   * of handlers, and listen() will be invoked for each.
   */
  public onHandler<E extends EventType<T>>(handler: Handlers<T, E>): Handlers<T, E> {
    return this.invokeOnEachHandler(handler, 'listen');
  }


  public onKey(
    key: string,
    callback: GlobalEventCallback<'keydown'>,
    element?: HTMLElement | SVGElement | Window
  ): GlobalEventHandler<'keydown'> {
    return this.on(
      'keydown',
      (evt) => {
        if (evt.key === key) {
          callback(evt);
        }
      },
      element
    );
  }


  public on<E extends EventType<T>>(
    evtType: E,
    callback: EventCallback<T, E>,
    element?: HTMLElement | SVGElement | Window,
    useCapture?: boolean
  ): EventHandler<T, E> {
    return new EventHandler(evtType, callback, this, element, useCapture).listen();
  }


  /**
   * Deafen event handlers:
   * - it is safe to pass null/undefined
   * - `handlers` can be a single handler, an array, or a hash of handlers
   * @param handlers
   */
  public off<E extends EventType<T>>(handlers: Handlers<T, E> | undefined | null): void {
    if (handlers) {
      this.invokeOnEachHandler(handlers, 'deafen');
    }
  }


  public onContact(
    callbacks: Dictionary<(evt: PointerEvent) => void>,
    element: HTMLElement | SVGElement = Events.DEFAULT_DISPATCHER,
    options: ContactHandlerOptions = {}
  ): ContactHandler {
    return new ContactHandler(callbacks, element, options).listen();
  }


  public scrollable(element: HTMLElement, axis: 'x' | 'y' = 'y'): void {
    ScrollManager.scrollable(element, axis);
  }


  public invokeOnEachHandler<E extends EventType<T>>(handlers: Handlers<T, E>, method: 'listen' | 'deafen'): Handlers<T, E> {
    if (this.isIHandler(handlers)) {
      handlers[method]();
    } else if (Array.isArray(handlers)) {
      for (const handler of handlers) {
        handler[method]();
      }
    } else if (handlers) {
      const names = Object.keys(handlers);
      for (const name of names) {
        handlers[name][method]();
      }
    }

    return handlers;
  }


  /**
   * Options:
   * - 'override' - If false and element already has a touch action value set, don't change the value.
   * @param element
   * @param touchActionValue
   * @param options
   */
  public setTouchAction(element: HTMLElement | SVGElement, touchActionValue: string, options: Dictionary<boolean> = {}): void {
    if (options.override === false) {
      if (element.getAttribute('touch-action')) {
        return;
      }
    }
    element.setAttribute('touch-action', touchActionValue);
    element.style.setProperty('-ms-touch-action', touchActionValue);
    element.style.setProperty('touch-action', touchActionValue);
  }


  private isIHandler<E extends EventType<T>>(handlers: Handlers<T, E>): handlers is Handler<T, E> {
    return handlers && !!(handlers as Handler<T, E>).listen;
  }
}

export type EventCallback<T, E extends EventType<T>> = (evt: AppEventMap<T>[E]) => void;

type Handlers<T, E extends EventType<T>> = Dictionary<Handler<T, E>> | Handler<T, E>[] | Handler<T, E>;
export type PointerEvents = 'click' | 'pointerdown' | 'mousedown' | 'touchstart' | 'pointerup' | 'mouseup' | 'touchend';
export type GlobalEventCallback<T extends keyof GlobalEventHandlersEventMap> = (evt: GlobalEventHandlersEventMap[T]) => void;

export type EventType<T> = StringIndex<AppEventMap<T>>;
type StringIndex<T> = Extract<keyof T, string>;

export interface DataEvent<T> extends Event {
  m: T;
}

export type AppEventMap<T> = GlobalEventHandlersEventMap & DataEventMap<T>;

type DataEventMap<T> = {
  [P in keyof T]: DataEvent<T[P]>;
};
