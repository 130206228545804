import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_openBlock(), _createBlock(_component_ContextMenu, {
    reference: _ctx.reference,
    options: _ctx.contextMenuOptions,
    headerLabel: _ctx.$t('annotations.contextMenu.header'),
    subHeaderLabel: _ctx.showCount ? _ctx.$t('annotations.selectedCount', { COUNT: _ctx.annotations.length }) : undefined,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, null, 8, ["reference", "options", "headerLabel", "subHeaderLabel"]))
}