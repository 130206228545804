<template>
  <div
    ref="root"
    :class="$style.container"
  >
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'FormCaptcha',
  props: {
    siteKey: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const root = ref<HTMLElement | null>(null);

    onMounted(() => {
      if (!root.value) { return; }

      if (window.grecaptcha) {
        renderCaptcha(root.value, props.siteKey);
      } else {
        addCaptchaScript(() => renderCaptcha(root.value!, props.siteKey));
      }
    });

    return {
      root
    };
  }
});

function addCaptchaScript(onLoad: () => void) {
  const captchaLoadingFunction = 'onCaptchaLoad';
  (window as any)[captchaLoadingFunction] = onLoad;
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?onload=${captchaLoadingFunction}&render=explicit`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

function renderCaptcha(container: HTMLElement, siteKey: string): void {
  if (container.innerHTML === '') {
    try {
      window.grecaptcha.render(container, {
        sitekey: siteKey
      });
    } catch (renderError) {
      console.warn('[CAPTCHA] Did not render or has already been rendered, attempting to reset.', renderError);
      try {
        window.grecaptcha.reset();
      } catch (resetError) {
        console.warn('[CAPTCHA] Could not reset.', resetError);
      }
    }
  } else {
    window.grecaptcha.reset();
  }

  window.grecaptcha.ready(() => setTimeout(() => {
    const captchaChallenge = getCaptchaChallengeContainer();
    console.log(captchaChallenge, document.querySelectorAll('iframe'));
    if (captchaChallenge) {
      captchaChallenge.classList.add('recaptcha-challenge');
    }
  }, 1000));
};

function getCaptchaChallengeContainer(): HTMLElement | null {
  const iframe = document.querySelector('iframe[title="recaptcha challenge"]');
  let parent = iframe?.parentElement;
  while (parent?.parentElement && parent.parentElement !== document.body) {
    parent = parent.parentElement;
  }

  return parent || null;
}
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container > div {
  margin: auto;
}

@media screen and (max-width: @px-vp-very-narrow) {
  .container {
    transform: scale(0.9);

    div {
      max-width: 90vw;
    }
  }
}

@media screen and (max-width: @px-vp-narrow) {
  :global(.recaptcha-challenge) {
    transform: scale(0.7);
    transform-origin: left;
  }
}
</style>
