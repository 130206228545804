import { APP } from 'app/base/app';
import { Alert } from 'app/base/samwise';
import { ref } from 'vue';


export function useAlerts() {

  const alerts = ref<Alert[] | undefined>();
  const loading = ref<boolean>(false);
  const error = ref<boolean>(false);

  const updateAlerts = <T extends (...args: any[]) => Promise<Alert[]>>(func: T) => async (...args: Parameters<T>) => {
    let succeeded = false;

    try {
      loading.value = true;

      if (!APP.patron.accountId) { throw new Error; }

      const alertsUpdated = await func(...args);
      if (!alertsUpdated) { throw new Error; }

      alerts.value = alertsUpdated;
      error.value = false;

      succeeded = true;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }

    return succeeded;
  };

  const fetchAllAlerts = updateAlerts(async () => {
    const fetchedAlerts = await APP.services.samwise.fetchAllAlerts();
    if (fetchedAlerts === null) { throw new Error; }

    return fetchedAlerts;
  });

  const addTitleNewReleaseAlert = updateAlerts(async (titleId: number) => {
    const newAlert = await APP.services.samwise.addTitleNewReleaseAlert(titleId);
    if (newAlert === null) { throw new Error; }

    const alertsOrDefault = alerts.value || [];

    return [...alertsOrDefault, newAlert];
  });

  const addTitleNotAvailableAlert = updateAlerts(async (titleId: number) => {
    const newAlert = await APP.services.samwise.addTitleNotAvailableAlert(titleId);
    if (newAlert === null) { throw new Error; }

    const alertsOrDefault = alerts.value || [];

    return [...alertsOrDefault, newAlert];
  });

  const addSeriesNewReleaseAlert = updateAlerts(async (seriesId: number, titleIds: number[]) => {
    const newAlert = await APP.services.samwise.addSeriesNewReleaseAlert(seriesId, titleIds);
    if (newAlert === null) { throw new Error; }

    const alertsOrDefault = alerts.value || [];

    return [...alertsOrDefault, newAlert];
  });

  const addSeriesNotAvailableAlert = updateAlerts(async (seriesId: number) => {
    const newAlert = await APP.services.samwise.addSeriesNotAvailableAlert(seriesId);
    if (newAlert === null) { throw new Error; }

    const alertsOrDefault = alerts.value || [];

    return [...alertsOrDefault, newAlert];
  });

  const reviewAlert = updateAlerts(async (alertId: number) => {
    const updatedAlert = await APP.services.samwise.reviewAlert(alertId);
    if (updatedAlert === null) { throw new Error; }

    const alertsOrDefault = alerts.value || [];
    const withoutOldAlert = alertsOrDefault.filter((a) => a.id !== alertId);

    return [...withoutOldAlert, updatedAlert];
  });

  const deleteAlert = updateAlerts(async (alertId: number) => {
    const res = await APP.services.samwise.deleteAlert(alertId);
    if (res === null) { throw new Error; }

    const alertsOrDefault = alerts.value || [];

    return alertsOrDefault.filter((a) => a.id !== alertId);
  });

  const deleteAllAlerts = updateAlerts(async () => {
    const res = await APP.services.samwise.deleteAllAlerts();
    if (res === null) { throw new Error; }

    return [];
  });

  return {
    alerts,
    loading,
    error,

    fetchAllAlerts,
    addTitleNewReleaseAlert,
    addTitleNotAvailableAlert,
    addSeriesNewReleaseAlert,
    addSeriesNotAvailableAlert,
    reviewAlert,
    deleteAlert,
    deleteAllAlerts
  };
}
