<template>
  <AlertNewReleaseDetails
    v-if="alert.type === 'TitleNewRelease'"
    :name="alert.title.title"
    :type="alert.type"
    :titles="[alert.title]"
  />
  <AlertNewReleaseDetails
    v-else-if="alert.type === 'SeriesNewRelease'"
    :name="alert.series.title"
    :type="alert.type"
    :titles="alert.titles"
  />
  <AlertNotAvailableDetails
    v-else-if="alert.type === 'TitleNotAvailable'"
    :id="alert.title.slug"
    :title="alert.title.title"
    :subtitle="alert.title.subtitle"
    :authors="alert.title.creators?.Author"
    :type="alert.type"
  >
    <template #cover>
      <Cover
        :item="(alert.title as TitleRecord)"
      />
    </template>
  </AlertNotAvailableDetails>
  <AlertNotAvailableDetails
    v-else-if="alert.type === 'SeriesNotAvailable'"
    :id="alert.series.id.toString()"
    :title="alert.series.title"
    :authors="alert.series.creators?.Author"
    :type="alert.type"
  >
    <template #cover>
      <SeriesCover
        :series="(alert.series as Series)"
        :width="seriesCoverSizes.alertDetails"
      />
    </template>
  </AlertNotAvailableDetails>
</template>

<script lang='ts'>
import { seriesCoverSizes } from 'app/base/constants';
import AlertNewReleaseDetails from 'app/components/AlertNewReleaseDetails.vue';
import AlertNotAvailableDetails from 'app/components/AlertNotAvailableDetails.vue';
import Cover from 'app/components/Cover.vue';
import SeriesCover from 'app/components/SeriesCover.vue';
import { AlertWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent } from 'vue';


export default defineComponent({
  components: {
    AlertNewReleaseDetails,
    AlertNotAvailableDetails,
    Cover,
    SeriesCover
},
  props: {
    alert: {
      type: Object as PropType<AlertWithMetadata>,
      required: true
    }
  },
  emits: [
    'close'
  ],
  setup: () => {

    return {
      seriesCoverSizes
    };
  }
});
</script>
