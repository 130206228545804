import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Collection = _resolveComponent("Collection")!
  const _component_LoadingPage = _resolveComponent("LoadingPage")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      (_ctx.state.state === 'success')
        ? (_openBlock(), _createBlock(_component_Collection, {
            key: 0,
            title: _ctx.state.title,
            description: _ctx.state.description,
            searchType: _ctx.state.searchType,
            definition: _ctx.state.definition,
            currentSort: _ctx.currentSort,
            appliedFilters: _ctx.appliedFilters,
            titlePage: _ctx.state.searchType === 'title' ? _ctx.page : undefined,
            seriesPage: _ctx.state.searchType === 'series' ? _ctx.page : undefined
          }, null, 8, ["title", "description", "searchType", "definition", "currentSort", "appliedFilters", "titlePage", "seriesPage"]))
        : (_ctx.state.state === 'loading')
          ? (_openBlock(), _createBlock(_component_LoadingPage, { key: 1 }))
          : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('list.error')), 1))
    ], undefined, true),
    _: 1
  }))
}