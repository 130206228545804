<template>
  <WelcomeView
    :backButtonLabel="backButtonLabel"
    @back="onBackClicked"
  >
    <section
      v-if="targetLibrary"
      :class="$style.container"
    >
      <h1>{{ $t('redirect.header') }}</h1>

      <p>{{ $t('redirect.description', { CURRENT_LIBRARY: currentLibraryName, TARGET_LIBRARY: targetLibraryName }) }}</p>

      <p>{{ $t('redirect.instructions', { CURRENT_LIBRARY: currentLibraryName, TARGET_LIBRARY: targetLibraryName }) }}</p>

      <button
        :class="$style.submit"
        @click="signOutAndContinue"
      >
        {{ $t('redirect.continue') }}
      </button>
    </section>
    <section
      v-else
      :class="$style.loading"
    >
      <Icon name="spinner" />
    </section>
  </WelcomeView>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import env from 'app/base/env';
import WelcomeView from 'app/components/WelcomeView.vue';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { Library } from 'app/models/library';
import { RouteName } from 'app/router/constants';
import { Dictionary } from 'lib/common/dictionary';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Redirect',
  components: {
    WelcomeView
  },
  props: {
    currentLibraryId: {
      type: String,
      required: true
    },
    targetLibraryId: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: undefined as string | undefined
    }
  },
  setup: (props, ctx) => {
    const targetLibrary = ref<Library | null>(null);
    const currentLibraryName = computed(() => APP.library.name);
    const targetLibraryName = computed(() => targetLibrary.value?.name || '');
    const targetUrl = computed(() => {
      return props.target || {
            name: RouteName.LibraryHome,
            params: {
              libraryId: props.targetLibraryId
            }
          };
    });

    const { t } = useI18n();
    const router = useRouter();

    const displayUrl = computed(() => {
      const resolved = router.resolve(targetUrl.value) || { href: '' };

      return env.ROOT_URI + resolved.href;
    });

    onMounted(async () => {
      targetLibrary.value = await Library.resolve({
        baseKey: props.targetLibraryId
      });

      // If the target library is invalid, we'll just take them to the home page
      // of their current library.
      if (!targetLibrary.value
        || !targetLibrary.value.isAccessible
        || !targetLibrary.value.isElrondLibrary()
      ) {
        console.log('[REDIRECT] Invalid library, going home...');

        router.replace({
          name: RouteName.LibraryHome,
          params: {
            libraryId: props.currentLibraryId
          }
        });
      } else if (targetLibrary.value.websiteId === APP.library.websiteId) {
        console.log('[REDIRECT] Target library has same website id as current library, proceeding...');
        const targetRoute = router.resolve(targetUrl.value)
          || {
            name: RouteName.LibraryHome,
            params: {} as Dictionary<string>,
            query: undefined,
            hash: undefined
          };
        targetRoute.params!.libraryId = props.currentLibraryId;

        router.replace({
          name: targetRoute.name || undefined,
          params: targetRoute.params,
          query: targetRoute.query,
          hash: targetRoute.hash
        });
      } else {
        announceMessage(t('redirect.ariaHeader', { TARGET_LIBRARY: targetLibraryName.value }));
      }
    });

    let backButtonLabel;
    if (APP.shell.info.type === 'Native' && APP.shell.info.platform !== 'PWA') {
      backButtonLabel = t('redirect.cancel');
    }
    const onBackClicked = () => {
      if (APP.nav.length > 0) {
        APP.nav.back();
      } else {
        router.push({
          name: RouteName.LibraryHome,
          params: {
            libraryId: props.currentLibraryId
          }
        });
      }
    };

    const signOutAndContinue = () => {
      console.log('[REDIRECT] Signing out...');
      // TODO: Migrate this to a shared logout function
      // when ELRO-979 gets done
      APP.bank.wipe();
      APP.bank.freeze();
      APP.shell.transmit('roster:wipe:all');
      APP.shell.transmit('title:info');

      // Sign the user out (waiting for a while to give the bank and rosters events time to occur)
      setTimeout(async () => {
        await APP.patron.reset(false);
        APP.events.dispatch('auth:logout');

        console.log('[REDIRECT] Sign out complete, redirecting...');

        location.href = router.resolve(targetUrl.value).href || env.ROOT_URI;
      }, 200);
    };

    return {
      backButtonLabel,
      onBackClicked,
      currentLibraryName,
      displayUrl,
      signOutAndContinue,
      targetLibrary,
      targetLibraryName
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  .welcome-panel;
}

.container > * + * {
  margin-top: 1.5rem;
}

.container > h1 {
  font-size: @fs-head;
  font-weight: @fw-bold;
  text-align: center;
}

.loading {
  width: 20rem;
  height: 20rem;
  margin: auto;
  position: relative;

  svg {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.submit {
  display: block;
  width: 100%;
  background: @c-primary-red;
  border-radius: @px-border-radius;
  border: none;
  color: @c-white;
  font-family: @ff-sans;
  font-size: @fs-med;
  font-weight: @fw-bold;
  padding: 1rem;
  text-shadow: none;
  text-transform: uppercase;
  text-align: center;
}
</style>
