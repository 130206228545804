<template>
  <div
    :class="{
      [$style.exportQueueGroupMobile]: true,
      [$style.expanded]: expanded,
      [$style.selectable]: selectable
    }"
  >
    <FormCheckbox
      v-if="selectable"
      :modelValue="selectionState"
      :showLabel="false"
      :label="$t('exportQueue.selectTitle', { title: fullTitle })"
      :ariaLabel="$t('exportQueue.selectTitle', { title: fullTitle })"
      :class="{
        [$style.checkbox]: true,
        [$style.selected]: selectionState !== 'off'
      }"
      @update:modelValue="updateAllAnnotations"
    />
    <button
      ref="expansionButton"
      type="button"
      :aria-label="$t('exportQueue.expandTitle', { title: fullTitle })"
      :aria-expanded="expanded"
      :aria-controls="annotationPanelId"
      :class="{
        [$style.headerMobile]: true,
        [$style.selected]: selectionState !== 'off'
      }"
      @click="toggleExpansion"
    >
      <Icon
        name="chevron-down"
        :class="$style.expander"
      />
      <div>
        <div :class="$style.title">
          {{ title }}
        </div>
        <div
          v-if="subtitle"
          :class="$style.subtitle"
        >
          {{ subtitle }}
        </div>
      </div>
    </button>

    <template v-if="expanded">
      <div :class="$style.groupIndicatorContainer">
        <span :class="$style.groupIndicator"></span>
      </div>
      <AnnotationCardList
        :id="annotationPanelId"
        :class="$style.annotations"
        :annotations="annotations"
        :selectable="selectable"
        :title="fullTitle"
        :hideNote="hideNote"
        @annotations:update="(copy, checked, uuid) => updateAnnotation(checked, uuid)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import AnnotationCardList from 'app/components/AnnotationCardList.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { PropType, computed, defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  components: {
    AnnotationCardList,
    FormCheckbox
  },
  props: {
    titleId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean
    },
    selectable: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'annotations:update',
    'toggle:expansion'
  ],
  setup: (props, ctx) => {
    const fullTitle = (`${props.title} ${props.subtitle || ''}`).trim();
    const allIds = computed<string[]>(() => props.annotations.map((annotation) => annotation.uuid));
    const selectionState = computed<FormCheckboxState>(() => {
      const selected = props.annotations.filter((a) => a.selected);

      return selected.length === 0
        ? 'off'
        : selected.length === props.annotations.length
          ? 'on'
          : 'partial';
    });

    const annotationPanelId = `annotation-panel-${props.titleId}`;

    const expansionButton = ref<HTMLElement | null>(null);
    const toggleExpansion = async () => {
      ctx.emit('toggle:expansion', !props.expanded);
      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth' });
      expansionButton.value?.focus({ preventScroll: true });
    };

    const updateAnnotation = (checked: FormCheckboxState, uuid: string) => {

      ctx.emit('annotations:update', checked, [uuid]);
    };

    const updateAllAnnotations = (checked: FormCheckboxState) => {

      ctx.emit('annotations:update', checked, allIds.value);
    };

    return {
      annotationPanelId,
      expansionButton,
      fullTitle,
      selectionState,
      toggleExpansion,
      updateAnnotation,
      updateAllAnnotations
    };
  }
});
</script>

<style module>
.export-queue-group-mobile {
  --buffer: 0.5rem;
  display: grid;
  grid-template-areas:
    'header';
  grid-template-columns: auto;
}

.export-queue-group-mobile.selectable {
  grid-template-areas:
    'checkbox header';
  grid-template-columns: 30px 1fr;
}

.export-queue-group-mobile.expanded {
  grid-template-areas:
    'header          header'
    'group-indicator annotations';
  grid-template-columns: 30px 1fr;
}

.export-queue-group-mobile.expanded.selectable {
  grid-template-areas:
    'checkbox          header'
    'group-indicator annotations';
}


.checkbox {
  grid-area: checkbox;
  padding: var(--buffer) 0;
  margin-bottom: var(--buffer);
}

.header-mobile {
  grid-area: header;
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 0.5rem;
  text-align: left;
  scroll-margin: 0.25rem;
  padding: var(--buffer) var(--buffer) var(--buffer) 0;
  margin-bottom: var(--buffer);
}

.selected {
  background-color: var(--c-selected);
}

.expander {
  height: 30px;
  width: 30px;
  transition: transform 150ms ease-in-out;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: translateZ(1px) rotateX(0);
}

.export-queue-group-mobile.expanded .expander {
  transform: translateZ(1px) rotateX(180deg);
}

.title {
  color: var(--c-black);
  --line-clamp: 2;
  composes: line-clamp from global;
}

.subtitle {
  color: var(--c-light-black);
  --line-clamp: 1;
  composes: line-clamp from global;
}

.group-indicator-container {
  grid-area: group-indicator;
  display: flex;
  justify-content: center;
  padding: 0.25rem var(--buffer) 0 0;
}

.group-indicator-container .group-indicator {
  display: inline-block;
  background-color: var(--c-dark-gray);
  border-radius: var(--form-border-radius);
  width: 2px;
  height: 100%;
}

.annotations {
  grid-area: annotations;
}
</style>
