// There's a bug with VoiceOver on macOS Safari
// (macOS 11.6.1 and Safari 15.1)
// where dialogs opened from other dialogs
// don't get screen reader focus.
// The solution here is to keep a list of
// current modal dialogs and toggle aria-modal
// as they open and close.

import Events from 'app/events/events';

const trackedDialogs: Set<string> = new Set();

Events.on('dialog:show', ({ m: dialogId }) => showDialog(dialogId));
Events.on('dialog:hide', ({ m: dialogId }) => hideDialog(dialogId));

function showDialog(id: string) {
  console.log('[A11Y] Showing dialog', id);
  trackedDialogs.delete(id);

  [...trackedDialogs].forEach((trackedDialogId) => {
    console.log('[A11Y] Pausing dialog', trackedDialogId);

    const dialogElement = document.getElementById(trackedDialogId);
    if (dialogElement?.hasAttribute('aria-modal')) {
      dialogElement.setAttribute('aria-modal', 'false');
    }
  });

  trackedDialogs.add(id);
}


function hideDialog(id: string) {
  console.log('[A11Y] Hiding dialog', id);
  trackedDialogs.delete(id);

  // We need to clear aria-modal before we close the dialog
  // or VoiceOver on macOS Safari won't allow interaction with
  // whatever is now active.
  const dialogElement = document.getElementById(id);
  if (dialogElement?.hasAttribute('aria-modal')) {
    dialogElement.setAttribute('aria-modal', 'false');
  }

  const nextDialogInStack = [...trackedDialogs][trackedDialogs.size - 1];

  if (nextDialogInStack) {
    console.log('[A11Y] Unpausing dialog', nextDialogInStack);
    const nextDialogElement = document.getElementById(nextDialogInStack);
    if (nextDialogElement?.hasAttribute('aria-modal')) {
      nextDialogElement.setAttribute('aria-modal', 'true');
    }
  } else {
    console.log('[A11Y] No other dialogs to unpause');
  }
}
