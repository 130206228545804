<template>
  <div class="visually-hidden">
    <div
      :aria-busy="busy"
      :aria-live="politeness"
    >
      {{ singleMessage }}
    </div>
    <div
      :aria-live="politeness"
      :aria-busy="busy"
    >
      {{ resultCount }}
    </div>
    <div
      aria-live="assertive"
    >
      {{ loadingMessage }}
    </div>
  </div>
</template>

<script lang='ts'>
import { useAppEvents } from 'app/functions/use-app-events';
import { ChatterboxMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { DataEvent } from 'lib/gala/src/events';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Chatterbox',
  setup: (props, ctx) => {
    const singleMessage = ref<string>('');
    const politeness = ref<'polite' | 'assertive'>('polite');
    const busy = ref(false);
    const loadingMessage = ref<string>('');
    const resultCount = ref<string>('');

    const { t } = useI18n();

    const announceNewMessage = (evt: DataEvent<ChatterboxMessage>) => {
      const modified = modifyIfSame(singleMessage.value, evt.m.message);
      singleMessage.value = modified;
      politeness.value = evt.m.politeness || 'polite';
    };

    const announceResultCount = (msg: string) => {
      const modified = modifyIfSame(resultCount.value, msg);
      resultCount.value = modified;
    };

    const modifyIfSame = (oldMsg: string, newMsg: string) => {
      if (oldMsg === newMsg) {
        // if same message as before, add/remove . so the string is different enough to be repeated
        // super hacky but can't find another solution that actually works
        return newMsg.charAt(newMsg.length - 1) === '.'
          ? newMsg.substring(0, newMsg.length - 1)
          : newMsg.concat('.');
      }

      return newMsg;
    };

    const loading = (evt: DataEvent<{ message: boolean | string }>) => {
      loadingMessage.value = typeof evt.m.message === 'string' ? evt.m.message
        : evt.m.message ? t('general.loading')
        : '';

      busy.value = true; // set aria-busy to true to delay any new messages
    };

    useAppEvents({
      'chatter:message': announceNewMessage,
      'chatter:result': (evt) => announceResultCount(evt.m.message),
      'chatter:loading': loading,
      'chatter:loaded': () => {
        busy.value = false;
        if (resultCount.value) {
          announceResultCount(resultCount.value);
        }
        loadingMessage.value = '';
      },
      'chatter:clear': () => {
        singleMessage.value = '';
        resultCount.value = '';
        loadingMessage.value = '';
      }
    });

    return {
      singleMessage,
      politeness,
      busy,
      loadingMessage,
      resultCount
    };
  }
});
</script>
