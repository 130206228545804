<template>
  <Surface>
    <Page
      :header="$t('tags.header')"
      :useBackToTop="true"
    >
      <template
        v-if="hasTags"
        #toolbar
      >
        <TextFilter
          v-model="textFilter"
          :label="$t('tags.filters.text.placeholder')"
          :class="$style.textFilter"
        />
      </template>

      <EmptyState
        v-if="!hasTags"
        :link="helpLink"
        :header="$t('tags.empty.header')"
        :content="$t('tags.empty.content')"
        :linkLabel="$t('tags.empty.link')"
      />
      <ol
        v-else-if="filteredTags.length > 0"
        :class="$style.listContainer"
      >
        <li
          v-for="tag in filteredTags"
          :key="tag.id"
          :class="$style.tag"
        >
          <router-link
            :to="tag.route"
            :class="$style.link"
            class="focus-outline"
          >
            {{ tag.name }}
          </router-link>
          <p class="badge">
            {{ tag.count }}
          </p>
        </li>
      </ol>
      <EmptyState
        v-else
        :header="$t('tags.filters.empty.header')"
        :content="$t('tags.filters.empty.content')"
      />
    </Page>
  </Surface>
</template>

<script lang='ts'>
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { usePatron } from 'app/functions/use-patron';
import { useTextFilter } from 'app/functions/use-text-filter';
import { Tag } from 'app/models/tag';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';

type DisplayTag = {
  id: string;
  name: string;
  count: number;
  route: RouteLocationRaw;
};

export default defineComponent({
  name: 'Tags',
  components: {
    EmptyState,
    Page,
    Surface,
    TextFilter
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.TAGS;

    const { tags } = usePatron();
    const userTags = computed(() => tags.value.filter(Tag.FILTER_FUNCTIONS.nonSystemTags));

    const displayTags = computed<DisplayTag[]>(() => {
      const sorted = userTags.value.slice().sort(Tag.SORT_FUNCTIONS.alpha);
      const mapped = sorted.map((tag) => {
        return {
          id: tag.uuid,
          name: tag.name,
          count: tag.all?.length || 0,
          route: {
            name: RouteName.TagDetails,
            params: {
              tagSlug: tag.slug
            }
          }
        };
      });

      return mapped;
    });
    const hasTags = computed(() => displayTags.value.length > 0);

    const filterTags = (tag: DisplayTag, input: string) => {
      const tagNameLower = tag.name.toLocaleLowerCase();
      const inputLower = input.toLocaleLowerCase();

      return tagNameLower.includes(inputLower);
    };
    const { textFilter, filteredItems: filteredTags } = useTextFilter(displayTags, filterTags);

    return {
      helpLink,
      textFilter,
      hasTags,
      filteredTags
    };
  }
});
</script>

<style module>

.list-container {
  background-color: var(--c-white);
  border-radius: 0 0 .5rem .5rem;
}

.tag {
  display: flex;
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  border-radius: var(--form-border-radius);
  color: var(--c-primary-blue);
  margin-block-end: 1rem;
  padding: 1rem;
}

.tag :global(.badge) {
  color: var(--c-primary-blue);
  min-width: fit-content;
}

.link {
  font-weight: var(--fw-bold);
  text-decoration: underline;
  composes: ellipsis from global;
}

.text-filter {
  margin-left: auto;
  max-width: 16rem;
}

</style>
