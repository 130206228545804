import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.banner]: true,
      [_ctx.$style.mobileErrorBanner]: _ctx.status === 'error',
      [_ctx.$style.mobileCompleteBanner]: _ctx.status === 'complete'
    })
  }, [
    (_ctx.status === 'error')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.error)
        }, [
          _createVNode(_component_Icon, {
            name: "warning",
            class: _normalizeClass(_ctx.$style.errorIcon)
          }, null, 8, ["class"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.message)
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.label), 1),
      (_ctx.sublabel)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.$style.sublabel)
          }, _toDisplayString(_ctx.sublabel), 3))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.status === 'inProgress')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style.progress)
        }, [
          _createVNode(_component_Icon, {
            name: "spinner",
            class: _normalizeClass(_ctx.$style.progressIcon)
          }, null, 8, ["class"])
        ], 2))
      : _renderSlot(_ctx.$slots, "actions", { key: 2 })
  ], 2))
}