import { C } from 'app/base/common';
import { Item } from '../../models/item';
import { APP } from '../app';
import { Freshness } from './freshness';
export interface Freshable {
  freshness?: Freshness;
  freshen(): Promise<boolean>;
  arePropertiesFresh(): boolean;
}

/**
 * `FreshableItem` is a generic Item that supports
 * freshening from some remote call.
 *
 * Implementing classes should override `freshenCall`
 * for the remote call and `freshenMap` to deserialize
 * the response into the item, as well as
 * `arePropertiesFresh` to support `isFresh` calculations.
 *
 * `T` is the type of the remote response.
 */
export abstract class FreshableItem<T> extends Item implements Freshable {
  protected itemName: string;
  public freshness?: Freshness;


  constructor(itemName: string, ttl?: number, freshAt?: number) {
    super();
    this.itemName = itemName;
    this.freshness = new Freshness(this, ttl, freshAt);
  }


  public async freshen(raiseNetworkError = false, ...args: unknown[]): Promise<boolean> {
    this.freshness = this.freshness || new Freshness(this);

    if (this.freshness.isFresh()) {
      this.freshness.freshAt = C.epochMilliseconds();

      return true;
    }

    if (this.freshness.isFreshening()) {
      return true;
    }

    if (this.freshness.isFresh()) {
      // No longer fresh, but available as stale content
      this.freshness.freshAt = APP.freshHorizon - 1;
    }

    this.freshness.isAsyncFreshening = true;

    let retries = 2;

    let result: T | null = null;
    let error: string | null = null;

    while (retries > 0) {
      try {
        result = await this.freshenCall(args);
        this.freshness.freshAt = C.epochMilliseconds();

        break;
      } catch (err) {
        error = err.toString();

        if (retries > 0) {
          console.log('[FRESHEN] \u21BB', this.itemName, err);
          retries -= 1;
        }
      }
    }

    this.freshness.isAsyncFreshening = false;

    if (result) {
      this.freshenMap(result);

      return true;
    }

    if (this.freshness.freshAt && this.arePropertiesFresh()) {
      console.log('... falling back to stale content for', this.itemName);
      console.log('... due to service failure:', error);

      return true;
    }

    if (raiseNetworkError) {
      APP.services.thunder.onServiceFailure(this);
    }

    return false;
  }


  public abstract arePropertiesFresh(): boolean;


  protected abstract freshenCall(...args: unknown[]): Promise<T | null>;


  protected abstract freshenMap(response: T): void;


  protected _normalizeAttributes(attrs: {}) {
    if (Item.SERIAL_PROPERTY in attrs) {
      return C.absorb(attrs, {});
    }

    if (!this.freshness || !this.freshness.isFresh()) {
      return this._transformRemoteAttributes(attrs);
    }
  }
}
