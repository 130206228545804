<template>
  <section :class="$style.container">
    <div class="notch"></div>

    <h1 :class="$style.header">
      Debug console
    </h1>
    <form
      @submit.prevent="submit"
    >
      <input
        v-model.trim="command"
        type="text"
        required
        :class="$style.input"
        autocomplete="off"
        autocapitalize="off"
        placeholder="`list` to show all commands"
        spellcheck="false"
      />
    </form>

    <code
      :class="[$style.output, copied ? $style.copied : '']"
    >
      <div :class="$style.outputHeader">
        <h2>Result</h2>

        <button
          :class="$style.copy"
          @click="copy"
        >
          <Icon name="copy-to-clipboard" />
          {{ copied ? 'Copied!' : 'Copy' }}
        </button>
      </div>

      <pre>{{ output }}</pre>
    </code>
  </section>
</template>

<script lang='ts'>
import { ClipboardHelper } from 'app/base/clipboard';
import { ArgType, commands } from 'app/base/debugger';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'DebugConsole',
  setup: () => {
    const command = ref('');
    const output = ref('');

    const submit = async () => {
      const [commandName, ...argInputs] = command.value.split(' ');
      const commandConfig = commands[commandName];
      const args = argInputs.reduce((config, argInput) => {
        const [name, val] = argInput.split('=');

        const matchingArg = commandConfig.args.find((a) => name === a.name);
        if (!matchingArg) { return config; }

        switch(matchingArg.type) {
          case 'string':
            config[name] = val;
            break;
          case 'string[]':
            config[name] = val.split(',');
            break;
          case 'number':
            config[name] = parseInt(val, 10);
            break;
          case 'number[]':
            config[name] = val.split(',').map((v) => parseInt(v, 10));
            break;
          case 'boolean':
            config[name] = val !== 'false';
            break;
          default:
            throw new Error(`Bad command argument for ${name}`);
        }

        return config;
      }, {} as { [key: string]: ArgType });

      output.value = await commandConfig.func(args);
    };

    const copied = ref(false);
    const copy = async () => {
      if (output.value) {
        ClipboardHelper.copyToClipboard(output.value);
        copied.value = true;
        setTimeout(() => copied.value = false, 1500);
      }
    };

    return {
      command,
      copied,
      copy,
      output,
      submit
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  border-top-left-radius: 1rem;
  padding: 1rem;
  background-color: @c-dark-black;
  color: @c-white;
  min-width: 25rem;
  max-width: 100%;
  box-sizing: border-box;

  position: fixed;
  bottom: 0;
  right: 0;
}

.header {
  font-weight: @fw-bold;
}

.input {
  border: 1px solid @c-dark-gray;
  border-radius: 0.5rem;
  padding: 0.75rem;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: @fs-body;
  background-color: @c-medium-black;
  border: 0;
  color: inherit;
  margin-top: 1rem;
}

.output {
  background-color: @c-medium-black;
  border-radius: 0.5rem;
  width: 100%;
  transition: background 200ms ease;
  text-align: left;
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 1rem;

  &.copied {
    background-color: #3f7a94;
  }

  pre {
    max-height: 300px;
    overflow: auto;
    padding: 0.75rem;
  }
}

.output-header {
  display: flex;
  justify-content: space-between;

  padding: 0.5rem 0.75rem;
  background-color: @c-light-black;
}

.copy {
  display: flex;
  align-items: center;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: @c-white;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: @px-vp-narrow) {
  .container {
    min-width: unset;
    width: 100%;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 0;
    bottom: unset;
    right: unset;
  }
}
</style>
