<template>
  <Surface>
    <LoadingPage
      v-if="loading"
    />

    <Page
      v-else
      :header="$t('annotations.header')"
      :useBackToTop="true"
    >
      <template #toolbar>
        <FilterButton
          :options="availableSubjects"
          filterType="subject"
          :iconOnly="mobile"
          @input="updatePath"
        />
        <SortSelect
          v-model="selectedSort"
          :options="sortOptions"
          optionKey="id"
          optionLabel="label"
          :class="$style.sort"
        />
      </template>

      <template #default>
        <ul
          v-if="sortedTitles.length"
          :class="$style.cardList"
        >
          <li
            v-for="title in sortedTitles"
            :key="title.slug"
          >
            <NotesTitleCard
              :title="title"
              :copyJobsState="copyJobsState"
            />
          </li>
        </ul>

        <EmptyState
          v-else
          :class="$style.emptyState"
          :link="helpLink"
          :header="$t('annotations.emptystate.header')"
          :content="$t('annotations.emptystate.content')"
          :linkLabel="$t('annotations.emptystate.link')"
        />
      </template>
    </Page>
  </Surface>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';
import NotesTitleCard from 'app/components/cards/NotesTitleCard.vue';
import EmptyState from 'app/components/EmptyState.vue';
import FilterButton from 'app/components/FilterButton.vue';
import LoadingPage from 'app/components/LoadingPage.vue';
import Page from 'app/components/Page.vue';
import SortSelect from 'app/components/SortSelect.vue';
import Surface from 'app/components/Surface.vue';
import { useCopyJobs } from 'app/functions/use-copy-jobs';
import { useCopyJobsState } from 'app/functions/use-copy-jobs-state';
import { useI18n } from 'app/functions/use-i18n';
import { getQueryParametersFromSubjectFilters, SubjectFilters, useSubjectFilters } from 'app/functions/use-subject-filters';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Annotation } from 'app/models/annotation';
import { Title, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

export type NotesSortOption = {
  label: string;
  sort: (a: TitleRecord, b: TitleRecord) => number;
  id: string;
};

export default defineComponent({
  name: 'Notes',
  components: {
    Surface,
    NotesTitleCard,
    EmptyState,
    SortSelect,
    FilterButton,
    LoadingPage,
    Page
  },
  props: {
    subjectFilters: {
      type: Object as () => SubjectFilters,
      required: true
    },
    sort: {
      type: String,
      default: 'recentAsc'
    }
  },
  setup: (props, ctx) => {
    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.Narrow);

    const helpLink = Constants.HELP_PATHS.ANNOTATIONS;
    const loading = ref(true);
    const titlesWithAnnotations = ref<TitleRecord[]>([]);

    const { availableSubjects, filteredTitles } =
      useSubjectFilters(titlesWithAnnotations as Ref<TitleRecord[]>, computed(() => props.subjectFilters));

    const { t } = useI18n();

    const sortOptions: NotesSortOption[] = [
      {
        label: t('annotations.sortOptions.recent'),
        sort: (a: TitleRecord, b: TitleRecord) => {
          const aMostRecent = a.annotations().sort(Annotation.SORT_FUNCTIONS.syncstamp)[0];
          const bMostRecent = b.annotations().sort(Annotation.SORT_FUNCTIONS.syncstamp)[0];

          return bMostRecent.syncstamp - aMostRecent.syncstamp;
        },
        id: 'recentAsc'
      },
      {
        label: t('annotations.sortOptions.oldest'),
        sort: (a: TitleRecord, b: TitleRecord) => {
          const aMostRecent = a.annotations().sort(Annotation.SORT_FUNCTIONS.syncstamp)[0];
          const bMostRecent = b.annotations().sort(Annotation.SORT_FUNCTIONS.syncstamp)[0];

          return aMostRecent.syncstamp - bMostRecent.syncstamp;
        },
        id: 'recentDesc'
      },
      {
        label: t('annotations.sortOptions.alpha'),
        sort: Title.SORT_FUNCTIONS.title,
        id: 'titleAsc'
      },
      {
        label: t('annotations.sortOptions.reverseAlpha'),
        sort: Title.SORT_FUNCTIONS.descendingTitle,
        id: 'titleDesc'
      }
    ];

    const selectedSort = computed<NotesSortOption>({
      get: () => {
        return sortOptions.find((option) => option.id === props.sort) || sortOptions[0];
      } ,
      set: (newSort) => {
        updatePath(props.subjectFilters, newSort.id);
      }
    });

    const sortedTitles = computed(() => {
      return filteredTitles.value.slice().sort(selectedSort.value.sort);
    });

    const router = useRouter();
    const updatePath = (filters: SubjectFilters, sort: string = props.sort) => {
      const newRoute = {
        name: RouteName.Notes,
        query: {
          ...getQueryParametersFromSubjectFilters(filters),
          sort
        }
      };

      router.replace(newRoute);
    };

    const fillOut = async () => {
      loading.value = true;
      // sync annotations first to include any newly copied annotations
      await APP.patron.syncCoordinator.remoteSync({ type: ['annotations'] });
      loading.value = false;

      titlesWithAnnotations.value = (await APP.patron.annotations
        .getRecentByTitle())
        .filter((title) => title.mediaType !== 'audiobook');
    };
    fillOut();


    // Annotation Copying

    const {
      copyJobs,
      loading: copyJobsLoading,
      error: copyJobsError
    } = useCopyJobs();

    const {
      copyJobsState
    } = useCopyJobsState(copyJobs, copyJobsLoading, copyJobsError);

    return {
      copyJobsState,
      mobile,
      helpLink,
      loading,
      selectedSort,
      sortOptions,
      sortedTitles,
      availableSubjects,
      updatePath
    };
  }
});
</script>

<style module>
.sort {
  margin-left: auto;
}

.card-list {
  composes: list-grid from global;
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 3;
  --grid-item-min-width: var(--px-card-min-width);
}

@media screen and (max-width: 499px /* px-vp-very-narrow */) {
  .card-list {
    --grid-layout-gap: 0;
    grid-row-gap: 1.5rem;
  }
}
</style>
