import { useI18n } from 'app/functions/use-i18n';
import { getSubjectCategory, SubjectCategory } from 'app/models/subject';
import { TitleRecord } from 'app/models/title';
import { computed, Ref } from 'vue';

export type SubjectFilters = {
  [category in SubjectCategory]: string[] | undefined;
};

export function useSubjectFilters(
  titles: Ref<TitleRecord[]>,
  subjectFilterParams: Ref<SubjectFilters>
) {
  const appliedFilters = computed(() => {
    return Object.values(subjectFilterParams.value);
  });

  const subjectIdList = (currentTitles: TitleRecord[]) => {
    const ids = new Set<string>();

    currentTitles
      .flatMap((title) => title.subjects)
      .forEach((subject) => ids.add(subject.id));

    return ids;
  };

  const allSubjectIds = computed(() => subjectIdList(titles.value));

  const currentSubjectIds = computed(() => subjectIdList(filteredTitles.value));

  const appliedSubjectIds = computed<string[]>(() => {
    return appliedFilters.value
      .flatMap((ids) => ids as string[])
      .filter((id) => allSubjectIds.value.has(id));
  });

  const availableSubjects = computed(() => {
    const { t } = useI18n();

    return [...new Set<string> ([
      ...currentSubjectIds.value,
      ...appliedSubjectIds.value
    ])].map((id) => {
      return {
        id: id,
        name: t(`subjectIdOrBisacCode.${id}`),
        category: getSubjectCategory(id),
        selected: appliedFilters.value.flat().includes(id)
      };
    });
  });

  const filteredTitles = computed(() => {
    //filtering titles by keeping titles that have the subject the user selected.
    //compiles all unique subjects associated with all the titles, then filters for the selected subjects,
    //and returns the titles that have those subjects.
    return titles.value
      .filter((t) => {
        const titleSubjects = new Set(t.subjects.map((s) => s.id));

        return appliedFilters.value
          .every((subjectArray) => {
            return subjectArray?.length
              ? subjectArray.some((c) => titleSubjects.has(c))
              : true;
        });
      });
  });

  const filteredTitleIds = computed(() => {
    return new Set(filteredTitles.value.map((t) => t.slug));
  });

  return {
    availableSubjects,
    filteredTitles,
    filteredTitleIds
  };
}

export const getQueryParametersFromSubjectFilters = (filters: SubjectFilters | undefined) => {
  if (!filters) { return {}; }

  return {
    contentType: filters.Classification?.join(',') || undefined,
    jurisdiction: filters.Jurisdiction?.join(',') || undefined,
    practiceArea: filters.PracticeArea?.join(',') || undefined,
    otherSubjects: filters.Subject?.join(',') || undefined
  };
};

export const toSubjectFilters = (subjectIds: string[]) => {
  const filters: SubjectFilters = {
    Jurisdiction: undefined,
    Classification: undefined,
    PracticeArea: undefined,
    Subject: undefined
  };

  // subjectIds passed in can be either separate items (ex: ['1001', '1002'])
  // or comma-delimted items (ex: ['1001,1002']) or a combination of both.
  // So the two nested forEach() will account for both.
  subjectIds.forEach((subjectIdOrSubjectList) => {
    subjectIdOrSubjectList.split(',').forEach((subjectId) => {
      const category = getSubjectCategory(subjectId);
      const existing = filters[category] || [];
      filters[category] = [...existing, subjectId];
    });
  });

  return filters;
};
