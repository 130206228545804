  // The DataClass object allows you to overload an object's className
  // so that it can work like a data-* property.
  //
  // Rationale: Some Android browsers have problems efficiently
  // displaying style changes based on attribute values. They are
  // optimized for style-changes caused by className modifications.
  //
  // If your data-* property would have a string value, you can use
  // DataClass instead.
  //
  // Old code:
  //
  //  elem.setAttribute('data-state', 'loading');
  //
  // New code:
  //
  //  DataClass.set(elem, 'State', 'loading');
  //
  // This will give the element a classname of 'dataState_loading'. You
  // can change the state as simply as this:
  //
  //   DataClass.set(elem, 'State', 'loaded');
  //
  // That will remove the 'dataState_loading' className and add a new
  // 'dataState_loaded' classname.
  //
  // You get the value of the property like this:
  //
  //   DataClass.get(elem, 'State');
  //   //=> 'loaded'
  //
  // And you can clear it with:
  //
  //   DataClass.clear(elem, 'State');
  //
  //

  // TODO Should this be a name space or a class with all static methods???
  export class DataClass {
    public static set(elem: HTMLElement, attr: string, val?: string): void {
      if (DataClass.get(elem, attr) !== val) {
        DataClass.clear(elem, attr);
        const suffix = typeof val !== 'undefined' ? `_${val}` : '';
        elem.classList.add(`data-${attr}${suffix}`);
      }
    }


    public static get(elem: HTMLElement, attr: string): string | undefined {
      const re = DataClass._pattern(attr);
      for (let i = 0, ii = elem.classList.length; i < ii; ++i) {
        const m = elem.classList[i].match(re);
        if (m) {
          return m[2];
        }
      }

      return undefined;
    }


    public static clear(elem: HTMLElement, attr: string) {
      const re = DataClass._pattern(attr);
      const cl: string[] = [];
      for (let i = 0, ii = elem.classList.length; i < ii; ++i) {
        if (elem.classList[i].match(re)) {
          cl.push(elem.classList[i]);
        }
      }

      elem.classList.remove(...cl);
    }


  // Creates a convenient object where all the DataClass methods are
    // tied to an element:
    //
    //  var semaphore = C.DataClass.semaphore(document.body);
    //  semaphore.set('foo', 'bar');
    //  semaphore.get('foo'); // => 'bar'
    //
    public static semaphore(elem: HTMLElement) {
      return {
        set: DataClass.set.bind(DataClass, elem),
        get: DataClass.get.bind(DataClass, elem),
        clear: DataClass.clear.bind(DataClass, elem)
      };
    }


    public static _pattern(attr: string): RegExp {
      return new RegExp(`^data-${attr}(_(.*))?$`);
    }
  }
