import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(_ctx.$style.footer)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.overdrive)
    }, [
      _createVNode(_component_Icon, {
        class: _normalizeClass(_ctx.$style.odLogo),
        name: "od-logo"
      }, null, 8, ["class"]),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.$style.copyright)
      }, _toDisplayString(_ctx.$t('login.copyright', { year: _ctx.currentYear})), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.legal)
    }, [
      _createElementVNode("a", {
        href: _ctx.privacyPolicyLink,
        target: "_blank"
      }, _toDisplayString(_ctx.$t('login.privacyPolicy')), 9, _hoisted_1),
      _createElementVNode("a", {
        href: _ctx.termsLink,
        target: "_blank"
      }, _toDisplayString(_ctx.$t('login.termsAndConditions')), 9, _hoisted_2)
    ], 2)
  ], 2))
}