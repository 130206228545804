import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.group)
  }, [
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", {
          class: _normalizeClass({
            [_ctx.$style.underline]: !_ctx.description
          })
        }, [
          _createVNode(_component_router_link, {
            to: _ctx.navigateLink,
            class: _normalizeClass(_ctx.$style.name)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.name), 1)
            ], undefined, true),
            _: 1
          }, 8, ["to", "class"])
        ], 2),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass([_ctx.$style.description, _ctx.$style.underline])
            }, _toDisplayString(_ctx.description), 3))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_router_link, {
        class: _normalizeClass(_ctx.$style.button),
        to: _ctx.navigateLink
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
        ], undefined, true),
        _: 1
      }, 8, ["class", "to"])
    ], 2),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}