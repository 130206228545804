<template>
  <article
    :class="{
      [$style.card]: true,
      [$style.expanded]: expanded
    }"
  >
    <button
      ref="expansionButton"
      :class="$style.fullCardButton"
      :aria-label="$t('annotations.display.expandText')"
      @click="toggleExpansion"
    ></button>
    <div :class="$style.content">
      <span
        :class="$style.colorStrip"
        :style="`--highlight-color: var(--highlight-color-${snapshot.color_id})`"
        aria-hidden="true"
      ></span>
      <p
        :class="[$style.quote, $style.text, $style.clampedLong]"
      >
        {{ snapshot.quote }}
      </p>
      <dl :class="$style.table">
        <template v-if="!hideNote">
          <dt>{{ $t('annotations.display.property.note') }}</dt>
          <dd
            v-if="snapshot.note"
            :class="[$style.text, $style.clamped]"
          >
            {{ snapshot.note }}
          </dd>
          <dd
            v-else
            :class="$style.noNote"
          >
            {{ $t('annotations.display.noNote') }}
          </dd>
        </template>

        <dt>{{ $t('annotations.display.property.citation') }}</dt>
        <dd :class="[$style.text, $style.clamped]">
          {{ snapshot.citation }}
        </dd>

        <dt>{{ $t('annotations.display.property.release') }}</dt>
        <dd :class="[$style.text, $style.clamped]">
          {{ releaseDisplayName }}
        </dd>
      </dl>
      <dl :class="$style.extra">
        <div>
          <dt
            class="visually-hidden"
          >
            {{ $t('annotations.display.property.color') }}
          </dt>
          <dd>
            <HighlightColor
              :colorGroup="snapshot.color_id"
            />
          </dd>
        </div>

        <div>
          <dt
            class="visually-hidden"
          >
            {{ $t('annotations.display.property.lastUpdated') }}
          </dt>
          <dd>
            <span :class="$style.lastUpdated">
              <Icon
                name="expire-clock"
                aria-hidden="true"
              />
              <RelativeDate
                :timestamp="snapshot.syncstamp"
              />
            </span>
          </dd>
        </div>
      </dl>
    </div>
  </article>
</template>

<script lang="ts">
import { PostishCopyAnnotationSnapshot } from 'app/base/postish';
import HighlightColor from 'app/components/HighlightColor.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import { getReleaseDisplayNameAnnotationSnapshot } from 'app/functions/use-release-display-name';
import { PropType, defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  name: 'AnnotationCard',
  components: {
    HighlightColor,
    RelativeDate
  },
  props: {
    snapshot: {
      type: Object as PropType<PostishCopyAnnotationSnapshot>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const expansionButton = ref<HTMLElement | null>(null);

    const expanded = ref(false);
    const toggleExpansion = async () => {
      expanded.value = !expanded.value;

      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth'});
      expansionButton.value?.focus({ preventScroll: true });
    };

    const releaseDisplayName = getReleaseDisplayNameAnnotationSnapshot(props.snapshot);

    return {
      expanded,
      expansionButton,
      releaseDisplayName,
      toggleExpansion
    };
  }
});

</script>

<style module>
  .card {
    background-color: var(--c-white);
    border: 1px solid var(--c-card-border);
    border-radius: var(--form-border-radius);
    box-shadow: 0px 1px 6px 0px var(--c-shadow);
    position: relative;
  }

  .card.expanded {
    background-color: var(--c-lightest-gray);
  }

  .card .text {
    white-space: pre-wrap;
    word-break: break-all;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .card:not(.expanded) .text {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp, 1);
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card .text.clamped {
    --line-clamp: 1;
  }

  .card .text.clamped-long {
    --line-clamp: 3;
  }

  .full-card-button {
    position: absolute;
    width: 100%;
    height: 100%;

    scroll-margin: 1rem;
  }

  .content {
    display: grid;
    grid-template-areas:
      "color-strip quote"
      "empty table"
      "empty extra";
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(3, auto);
    gap: 1rem;
    padding: 1rem;
  }

  .color-strip {
    grid-area: color-strip;

    background-color: var(--highlight-color);
    border-radius: var(--form-border-radius);
    width: 4px;
  }

  .quote {
    grid-area: quote;
  }

  .table {
    grid-area: table;

    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.25rem 1rem;

    color: var(--c-light-black);
    font-size: var(--fs-metadata);
  }

  .table dt {
    text-transform: uppercase;
  }

  .extra {
    grid-area: extra;

    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .last-updated {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;

    color: var(--c-light-black);
    font-size: var(--fs-metadata);
  }

  .last-updated svg {
    width: 1rem;
    height: 1rem;
    stroke: var(--c-light-black);
  }


</style>
