<template>
  <article :class="$style.container">
    <Cover
      :item="title"
      :class="$style.cover"
    />

    <div>
      <span :class="$style.created">
        <span :class="$style.count">{{ $t('home.annotations.count', { n: numAnnotations }) }}</span>
        <RelativeDate
          v-if="timestamp"
          :timestamp="timestamp"
          :class="$style.date"
        />
      </span>
      <h1
        :class="$style.title"
        :title="title.title"
        v-html="title.title"
      ></h1>
      <p
        v-if="title.subtitle"
        :class="$style.subtitle"
        :title="title.subtitle"
        v-html="title.subtitle"
      ></p>
    </div>
  </article>
</template>

<script lang='ts'>
import { defineComponent, ref, onBeforeUnmount } from 'vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import Cover from 'app/components/Cover.vue';
import { Title } from 'app/models/title';
import { APP } from 'app/base/app';

export default defineComponent({
  name: 'HomeAnnotationCard',
  components: {
    RelativeDate,
    Cover
  },
  props: {
    title: {
      type: Title,
      required: true
    }
  },
  setup: (props) => {
    const numAnnotations = ref(0);
    const timestamp = ref(0);

    const fillOut = () => {
      const notes = props.title.annotations();

      numAnnotations.value = notes?.length || 0;
      timestamp.value = notes?.[0]?.syncstamp || 0;
    };

    const handler = APP.events.on('annotation:update:all', fillOut);
    fillOut();

    onBeforeUnmount(() => {
      handler.deafen();
    });

    return {
      numAnnotations,
      timestamp
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5rem;
}

@rem-cover-height: 5rem;
@rem-cover-width: 3 / 4 * @rem-cover-height;
.cover {
  height: @rem-cover-height;
  width: @rem-cover-width;
  box-shadow: 0px 3px 6px rgba(34, 34, 34, 0.15);
}

.created {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.count {
  font-weight: @fw-bold;
}

.date {
  color: @c-light-black;
  font-size: @fs-metadata;
}

.title {
  .lineClamp(2);
}

.subtitle {
  .lineClamp;
  color: @c-light-black;
}
</style>
