<template>
  <section>
    <button
      id="iconBtn"
      ref="iconBtn"
      type="button"
      :aria-label="$t('title.expirationIndicator.label')"
      aria-haspopup="dialog"
      :class="$style.indicatorButton"
      @click="isPopped = true"
    >
      <Icon
        name="expire-clock"
        :class="$style.icon"
      />
      <template v-if="!iconOnly">
        <RelativeDate
          :timestamp="timestamp"
          :numeric="true"
          :class="$style.label"
        />
      </template>
    </button>

    <PopoutDialog
      v-if="isPopped && iconBtn"
      ref="popout"
      :reference="iconBtn"
      :headerLabel="$t('title.expirationIndicator.label')"
      @close="isPopped = false"
    >
      <div :class="$style.description">
        <p id="loanPopoutLabel">
          {{ relativeTime
            ? $t('title.expirationIndicator.expiresOn.time', { relativeTime })
            : $t('title.expirationIndicator.expiresOn.date', { date })
          }}
        </p>
        <p v-if="isRenewable && isReturnable">
          {{ $t('title.expirationIndicator.message.both') }}
        </p>
        <p v-else-if="isRenewable">
          {{ $t('title.expirationIndicator.message.renew') }}
        </p>
        <p v-else-if="isReturnable">
          {{ $t('title.expirationIndicator.message.return') }}
        </p>
      </div>

      <div :class="$style.actions">
        <button
          v-if="isRenewable"
          type="button"
          @click="renewLoan"
        >
          {{ $t('title.expirationIndicator.renew.button') }}
        </button>
        <button
          v-if="isReturnable"
          type="button"
          @click="returnLoan"
        >
          {{ $t('title.expirationIndicator.return.button') }}
        </button>
      </div>
    </PopoutDialog>
  </section>
</template>

<script lang="ts">
import RelativeDate from 'app/components/RelativeDate.vue';
import PopoutDialog from 'app/components/dialogs/PopoutDialog.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { relativeTimeFormatter, toRelativeTimeOptions } from 'app/i18n/relative-time-formatter';
import { Loan } from 'app/models/loan';
import { PropType, computed, defineComponent, ref } from 'vue';

/**
 * An indicator (and label) that displays the amount of time until this
 * loan is expired. It also allows for early returns and renewals, when available
 * @displayName LoanExpirationIndicator
 */
export default defineComponent({
  name: 'LoanExpirationIndicator',
  components: {
    PopoutDialog,
    RelativeDate
  },
  props: {
    /**
     * The loan to display expiration information for
     */
    loan: {
      type: Object as PropType<Loan>,
      required: true
    },
    /**
     * When true, only the icon will be rendered. No date label.
     */
    iconOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { t } = useI18n();
    const { dispatch } = useAppEvents();

    const iconBtn = ref<HTMLButtonElement | null>(null);
    const popout = ref<InstanceType<typeof PopoutDialog> | null>(null);

    const isPopped = ref(false);

    const isRenewable = computed(() => props.loan.isRenewable() || false);
    const isReturnable = computed(() => props.loan.isReturnable() || false);

    const { locale } = useI18n();
    const timestamp = computed(() => props.loan.expireTime || 0);
    const relativeTime = computed(() => {
      const opts = toRelativeTimeOptions(timestamp.value);

      if (opts) {
        opts.numeric = 'always'; //always to show 'in 1 day' instead of 'tomorrow'

        return relativeTimeFormatter(opts, locale);
      }

      return undefined;
    });

    const date = computed(() => new Date(timestamp.value));

    const renewLoan = async () => {
      popout.value?.closeDialog();
      const renewedLoan = await props.loan.renewLoan();
      if (!renewedLoan) {
        dispatch('toast', {
          type: 'error',
          message: t('title.expirationIndicator.renew.error')
        });
      } else {
        dispatch('toast', {
          type: 'success',
          message: t('title.expirationIndicator.renew.success', { date: date.value })
        });
      }
    };

    const returnLoan = async () => {
      popout.value?.closeDialog();
      try {
        await props.loan.returnLoan();
        dispatch('toast', {
          type: 'success',
          message: t('title.expirationIndicator.return.success')
        });
      } catch (e) {
        dispatch('toast', {
          type: 'error',
          message: t('title.expirationIndicator.return.error')
        });
      }
    };

    return {
      date,
      iconBtn,
      popout,
      relativeTime,
      timestamp,

      isPopped,
      isRenewable,
      isReturnable,

      renewLoan,
      returnLoan
    };
  }
});
</script>

<style module>

.indicator-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.icon {
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  stroke: var(--c-secondary-gray);
}

.label {
  color: var(--c-secondary-gray);
}

.label:first-letter {
  text-transform: uppercase;
}

.icon-only {
  display: none;
}

.description {
  grid-area: desc;
}

.actions {
  display: flex;
  margin-top: 0.5rem;
  grid-area: actions;
}

.actions > button {
  text-transform: uppercase;
  margin-right: 1rem;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  padding: 0.25rem 0;
}
</style>
