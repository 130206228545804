<template>
  <div
    :class="$style.container"
  >
    <div
      ref="list"
      role="tablist"
      :aria-label="ariaLabel"
      :class="[$style.tabList, 'no-scrollbar']"
      @keydown.left.prevent="changeFocusToLeft"
      @keydown.right.prevent="changeFocusToRight"
    >
      <button
        v-for="(tab, i) in tabs"
        :id="`tab-${tab.id}`"
        :ref="setItemRef"
        :key="tab.id"
        role="tab"
        :aria-selected="activeTab === tab.id"
        :aria-controls="`panel-${tab.id}`"
        :aria-label="tabLabel(tab)"
        :tabindex="activeTab === tab.id ? '0' : '-1'"
        :class="[$style.tab, activeTab === tab.id ? $style.active : '']"
        @click="tabIndex = i"
      >
        <span>
          {{ tab.label }}
          <span
            v-if="typeof tab.count === 'number'"
            class="badge"
          >
            {{ $n(tab.count) }}
          </span>
        </span>
      </button>
    </div>

    <slot name="intermediary"></slot>

    <div
      v-for="tab in tabs"
      v-show="activeTab === tab.id"
      :id="`panel-${tab.id}`"
      :key="`panel-${tab.id}`"
      role="tabpanel"
      :aria-labelledby="`tab-${tab.id}`"
    >
      <slot :name="`panel-${tab.id}`"></slot>
    </div>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, getCurrentInstance, nextTick, ref, watch } from 'vue';
import { useForRefs } from 'app/functions/use-for-refs';
import { useI18n } from 'app/functions/use-i18n';
import { preserveFocus } from 'app/router/focus-management';

export type Tab = {
  id: string;
  label: string;
  count?: number;
};

export default defineComponent({
  name: 'TabView',
  props: {
    ariaLabel: {
      type: String,
      default: undefined
    },
    tabs: {
      type: Array as () => Tab[],
      required: true
    },
    initialTab: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'tab'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const { itemRefs: tabElements, setItemRef } = useForRefs(getCurrentInstance());
    const tabIndex = ref(0);
    if (props.initialTab) {
      const index = props.tabs
        .findIndex((tab: Tab) => tab.id === props.initialTab);
      if (index >= 0) {
        tabIndex.value = index;
      }
    }
    const activeTab = computed(() => props.tabs[tabIndex.value]!.id);

    const list = ref<HTMLElement | null>(null);

    const changeFocusToLeft = () => {
      tabIndex.value = tabIndex.value === 0
        ? props.tabs.length - 1
        : tabIndex.value - 1;
    };

    const changeFocusToRight = () => {
      tabIndex.value = tabIndex.value === props.tabs.length - 1
        ? 0
        : tabIndex.value + 1;
    };

    watch(tabIndex, () => {
      focus();
      scrollToHighlighted();
      ctx.emit('tab', props.tabs[tabIndex.value].id);
    });

    watch(() => props.initialTab, (newTab) => {
      if (newTab !== props.tabs[tabIndex.value].id) {
        tabIndex.value = props.tabs.findIndex((tab: Tab) => tab.id === newTab);
      }
    });

    const scrollToHighlighted = () => {
      const element = tabElements[tabIndex.value];

      if (!element || !list.value) { return; };

      const firstTabOffset = tabElements[0]?.offsetLeft || 0;

      if (
        element.offsetLeft < (list.value.scrollLeft || 0)
        || (element.offsetLeft + element.offsetWidth) > (list.value.clientWidth + list.value.scrollLeft)
      ) {
        list.value.scrollLeft = element.offsetLeft - firstTabOffset;
      }
    };

    // Public
    const focus = async () => {
      await nextTick();
      tabElements[tabIndex.value]?.focus(); //focus if route doesn't change
      preserveFocus(list.value?.children[tabIndex.value]); //to preserve focus if route does change
    };

    const tabLabel = (tab: Tab) => {
      return (typeof tab.count === 'number')
        ? t('form.tabs.tabWithCount', { n: tab.count.toString(), label: tab.label })
        : tab.label;
    };

    return {
      activeTab,
      list,
      changeFocusToLeft,
      changeFocusToRight,
      focus,
      setItemRef,
      tabIndex,
      tabLabel
    };
  }
});
</script>

<style module>
.container {
  display: grid;
}

.tab-list {
  overflow-x: auto;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem;

  border-bottom: 2px solid var(--c-dark-gray);
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  padding-bottom: 0;
  margin: 0 0 2.5rem;
}

.tab {
  padding: 0.5rem;
  font-size: var(--fs-medium);
  color: var(--c-dark-black);
  transform: translateZ(0); /* Hack for Safari not clearing active styling */
}

.tab.active {
  color: var(--c-primary-red);
  border-bottom: 4px solid var(--c-primary-red);
  font-weight: var(--fw-bold);
}

.tab:focus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab.active :global(.badge) {
  color: var(--c-primary-red);
  background-color: var(--c-primary-red-light);
  font-weight: var(--fw-bold);
}
</style>
