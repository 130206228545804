<template>
  <SidebarDialog
    ref="sidebar"
    :heading="$t('filters.header')"
    icon="refine-list"
    :resizable="false"
    side="right"
    @close="$emit('close')"
  >
    <template
      v-if="applied.length"
      #extra
    >
      <button
        :class="$style.clear"
        @click.prevent="clearFilters"
      >
        {{ $t('filters.clear') }}
      </button>
    </template>

    <template #default>
      <FilterContent
        :options="options"
        :categoryObjects="categoryObjects"
        @apply="apply"
      />
    </template>
  </SidebarDialog>
</template>

<script lang="ts">
import { AppliedFilters, FilterCategoryObject } from 'app/components/FilterButton.vue';
import FilterContent from 'app/components/FilterContent.vue';
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import { FilterObject } from 'app/models/filter-object';
import { PropType, computed, defineComponent, ref } from 'vue';


export default defineComponent({
  name: 'FilterSidebar',
  components: {
    FilterContent,
    SidebarDialog
},
  props: {
    options: {
      type:  Array as PropType<FilterObject[]>,
      required: true
    },
    categoryObjects: {
      type: Array as PropType<FilterCategoryObject[]>,
      required: true
    }
  },
  emits: [
    'apply',
    'close'
  ],
  setup: (props, ctx) => {
    const sidebar = ref<InstanceType<typeof SidebarDialog> | null>(null);

    const applied = computed(() => props.options.filter((option) => option.selected));

    const clearFilters = () => {
      const cleared = {} as AppliedFilters;
      props.categoryObjects.forEach((cat) => cleared[cat.id] = undefined);

      ctx.emit('apply', cleared);
      sidebar.value?.closeDialog();
    };

    const apply = (val: Event) => {
      ctx.emit('apply', val);
      sidebar.value?.closeDialog();
    };

    return {
      applied,
      apply,
      clearFilters,
      sidebar
    };
  }
});
</script>

<style module>

.clear {
  color: var(--c-primary-blue);
  composes: linked from global;
  padding: 0.25rem;
  margin: -0.25rem;
}
</style>
