<template>
  <h2 class="visually-hidden">
    {{ $t('annotations.label') }}
  </h2>
  <template v-if="!mobile">
    <div :class="$style.toolbar">
      <AnnotationSelectionToolbar
        :headerLabel="$t('annotations.contextMenu.header')"
        :selected="selected"
        :focusTargetId="focusTargetId"
        @selected:clear="deselectAll"
      />
      <p>
        {{ showingCount }}
      </p>
    </div>

    <AnnotationTable
      v-if="toShow.length"
      :annotations="toShow"
      :title="fullTitle"
      :hideNote="hideNote"
      :selectAllState="selectAllState"
      @annotations:update="updateAnnotations"
      @annotations:update:all="(select) => select ? selectAll() : deselectAll()"
    />

    <p v-else>
      {{ $t('annotations.display.empty') }}
    </p>
  </template>

  <template v-else>
    <div :class="$style.toolbar">
      <button
        :id="focusTargetId"
        :class="[$style.selectButton, selectMode ? $style.selectButtonActive : '']"
        :aria-label="$t('annotations.select.toggle')"
        @click="selectMode = !selectMode"
      >
        {{ $t('annotations.select.label') }}
      </button>
      <p>
        {{ showingCount }}
      </p>
    </div>
    <div
      v-if="selectMode"
      :class="$style.selectModeActions"
    >
      <FormCheckbox
        :modelValue="selectAllState"
        :showLabel="false"
        :label="$t('annotations.display.selectAll')"
        :ariaLabel="$t('annotations.display.selectAll')"
        :class="$style.checkbox"
        :disabled="toShow.length === 0"
        @update:modelValue="(checked) => checked === 'on' ? selectAll() : deselectAll()"
      />
      <AnnotationSelectionToolbar
        :headerLabel="$t('annotations.contextMenu.header')"
        :selected="selected"
        @selected:clear="deselectAll"
      />
    </div>
    <AnnotationCardList
      v-if="toShow.length"
      :annotations="toShow"
      :selectable="selectMode"
      :title="fullTitle"
      :hideNote="hideNote"
      @annotations:update="updateAnnotations"
    />

    <p v-else>
      {{ $t('annotations.display.empty') }}
    </p>
  </template>
</template>

<script lang="ts">
import AnnotationSelectionToolbar from 'app/components/AnnotationSelectionToolbar.vue';
import AnnotationTable from 'app/components/AnnotationTable.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import { watchMessage, watchResultCount } from 'app/functions/use-chatterbox';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { useI18n } from 'app/functions/use-i18n';
import { useLibrary } from 'app/functions/use-library';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { PropType, computed, defineComponent, ref, watch } from 'vue';
import AnnotationCardList from './AnnotationCardList.vue';

export type AnnotationDetailsAnnotation =
  SelectableAnnotation &
  {
    shown: boolean;
  };

export default defineComponent({
  components: {
    AnnotationTable,
    AnnotationSelectionToolbar,
    AnnotationCardList,
    FormCheckbox
},
  props: {
    annotations: {
      type: Array as PropType<AnnotationDetailsAnnotation[]>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    mobile: {
      type: Boolean,
      required: true
    },
    displayCounts: {
      type: Object as PropType<{ showing: number; total: number }>,
      required: true
    },
    selectAllState: {
      type: String as PropType<FormCheckboxState>,
      required: true
    },
    focusTargetId: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'annotations:update',
    'annotations:update:all'
  ],
  setup: (props, ctx) => {
    const fullTitle = (`${props.title} ${props.subtitle || ''}`).trim();
    const toShow = computed(() => props.annotations.filter((a) => a.shown));
    const selected = computed(() => props.annotations.filter((a) => a.selected));

    const { t } = useI18n();
    const showingCount = computed(() => t(
      'annotations.showingCount',
      {
        SHOWING: props.displayCounts.showing,
        TOTAL: props.displayCounts.total
      }
    ));
    watchResultCount(showingCount);

    const selectMode = ref(false);
    watchMessage(computed(() => selectMode.value ? t('annotations.select.on') : t('annotations.select.off')));
    watch(selectMode, () => {
      if (!selectMode.value) {
        deselectAll();
      }
    });

    const updateAnnotation = (annotations: AnnotationDetailsAnnotation[], update: AnnotationDetailsAnnotation) => {
      const index = annotations.findIndex((a) => a.uuid === update.uuid);
      annotations[index] = update;
    };

    const updateAnnotations = (updates: AnnotationDetailsAnnotation[]) => {
      const copy = props.annotations.slice();
      updates.forEach((u) => updateAnnotation(copy, u));
      ctx.emit('annotations:update', copy);
    };

    const selectAll = () => {
      ctx.emit('annotations:update:all', true);
    };

    const deselectAll = () => {
      ctx.emit('annotations:update:all', false);
    };

    const library = useLibrary();
    const hideNote = computed(() => library.value?.disableNotes);

    return {
      deselectAll,
      fullTitle,
      hideNote,
      selectAll,
      selected,
      selectMode,
      showingCount,
      toShow,
      updateAnnotations
    };
  }
});
</script>

<style lang="less" module>
.toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.select-button {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  padding: 0.5rem 1rem;
}

.select-button-active {
  background-color: var(--c-light-gray)
}

.select-mode-actions {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}
</style>
