import { APP } from 'app/base/app';
import { NtcProviderResponse } from 'app/base/ntc';
import { SentryNtcResponse } from 'app/base/sentry';
import { useAppEvents } from 'app/functions/use-app-events';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { LibraryCard } from 'app/models/library-card';
import Quirks from 'lib/gala/src/quirks';
import { computed, ComputedRef, readonly, Ref, ref } from 'vue';

export interface NtcContext {
  ntcAvailable: ComputedRef<boolean>;
  ntcState: ComputedRef<State>;
  ntcError: Ref<string>;
  providerId: Ref<string | null>;
  shouldShowPrompt: Ref<boolean>;
  getNtcContent: () => Promise<void>;
  isProviderUnavailable: (id: string) => boolean;
  show: (id: string) => void;
  hide: () => void;
  submit: () => void;
};

type State =
  {
    state: 'loading';
  }
  | {
    state: 'loaded';
    providers: NtcProviderResponse[];
  }
  | {
    state: 'notfound';
  };

export function useNtcContext(): NtcContext {
  const card = ref<LibraryCard>(APP.patron.currentCard());
  const libraryKey = ref(APP.library.key());
  useAppEvents({
    'card:update:all': () => {
      card.value = APP.patron.currentCard();
      libraryKey.value = APP.library.key();
      getNtcContent();
    }
  });
  const { t } = useI18n();

  const state = ref<State>({ state: 'loading' });

  const getNtcContent = async () => {
    try {
      if (card.value?.isSessionUser === undefined || libraryKey.value === undefined) {
        // Keep the state as loading until we have a value for isSessionUser and libraryKey

        return;
      }

      if (card.value?.isSessionUser) {
        // Guest/Session auths don't work with the NTC authentication right,
        // so we are disabling NTC for them
        state.value = {
          state: 'notfound'
        };

        return;
      }

      const providers = await APP.services.ntc.getNtcSubscriptions(libraryKey.value);

      if (!providers) {
        state.value = {
          state: 'notfound'
        };

        return;
      }

      state.value = {
        state: 'loaded',
        providers
      };
    } catch {
      state.value = {
        state: 'notfound'
      };
    }
  };

  getNtcContent();

  const ntcAvailable = computed(() => {
    return state.value.state === 'loaded' && state.value.providers.length > 0;
  });

  const ntcState = computed<State>(() => {
    return state.value;
  });

  const isProviderUnavailable = (id: string) => {
    // The Depostition video with provider id of 'lexisnexis'
    // is hosted by Kanopy and would require the Kanopy app to watch on mobile.
    // Rather than having our users deal with that workflow,
    // we will just prevent access to the video on mobile devices.

    return id === 'lexisnexis' && Quirks.ask('mobile');
  };

  const shouldShowPrompt = ref(false);
  const providerId = ref<string | null>(null);
  const ntcError = ref('');
  const ntcCode = ref<SentryNtcResponse | null>(null);

  const show = async (id: string) => {
    providerId.value = id;

    try {
      //grab code from Sentry early and instead of after clicking ACCESS to avoid popup blockers
      ntcCode.value = await APP.sentry.fetchNtcCode(card.value as LibraryCard);

      if (ntcCode.value) {
        shouldShowPrompt.value = true;
      } else {
        ntcError.value = providerId.value;
      }
    } catch {
      ntcError.value = providerId.value;
    }
  };

  const hide = () => {
    shouldShowPrompt.value = false;
    announceMessage(t('general.dialog.close'));
    ntcCode.value = null;
  };

  const submit = () => {
    // If we don't have a code or providerId something went wrong
    if (!ntcCode.value || !providerId.value) {
      ntcError.value = providerId.value ? providerId.value : '';

      return;
    }

    const code = ntcCode.value.code;

    hide();

    //redirect to ntc-website
    //since we already have the ntc code from Sentry, this open-in-a-new-tab action is a direct user interaction
    //see: https://stackoverflow.com/a/2587692
    APP.services.ntc.goToNtcWebsite(libraryKey.value!, providerId.value, code);
  };

  return {
    ntcAvailable,
    ntcState,
    ntcError,
    providerId: readonly(providerId),
    shouldShowPrompt: readonly(shouldShowPrompt),
    getNtcContent,
    isProviderUnavailable,
    show,
    hide,
    submit
  } as NtcContext;
};
