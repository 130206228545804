import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["aria-expanded", "aria-owns"]
const _hoisted_2 = ["placeholder", "aria-controls", "aria-activedescendant", "aria-label"]
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id", "aria-selected", "onMousedown"]
const _hoisted_6 = ["id", "aria-label", "aria-selected", "onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "combobox",
    class: _normalizeClass([_ctx.$style.searchBar, _ctx.mobile ? _ctx.$style.mobile : '']),
    role: "combobox",
    "aria-expanded": _ctx.popupShown,
    "aria-owns": _ctx.popupId,
    "aria-haspopup": "grid",
    onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", {
      ref: "searchContainer",
      class: _normalizeClass(_ctx.$style.searchContainer)
    }, [
      (!_ctx.mobile)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: _normalizeClass(_ctx.$style.searchIcon),
            name: "search"
          }, null, 8, ["class"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        id: "search-box",
        ref: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputQuery) = $event)),
        type: "text",
        class: _normalizeClass([_ctx.$style.searchBox, 'focus-outline']),
        placeholder: _ctx.$t('search.placeholder'),
        autocomplete: "off",
        role: "searchbox",
        "aria-autocomplete": "none",
        "aria-controls": _ctx.popupId,
        "aria-activedescendant": _ctx.activeId,
        "aria-label": _ctx.$t('search.inputLabel'),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        onKeydown: [
          _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSpace && _ctx.onSpace(...args)), ["space"])),
          _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
          _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEsc && _ctx.onEsc(...args)), ["esc"])),
          _cache[6] || (_cache[6] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onUp && _ctx.onUp(...args)), ["up"])),
          _cache[7] || (_cache[7] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onDown && _ctx.onDown(...args)), ["down"])),
          _cache[8] || (_cache[8] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onLeft && _ctx.onLeft(...args)), ["left"])),
          _cache[9] || (_cache[9] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onRight && _ctx.onRight(...args)), ["right"]))
        ]
      }, null, 42, _hoisted_2), [
        [_vModelText, _ctx.inputQuery]
      ]),
      (_ctx.showClear)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            ref: "clearButton",
            type: "button",
            class: _normalizeClass(_ctx.$style.clearIconButton),
            "aria-label": _ctx.$t('search.clearButton'),
            onFocus: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            onBlur: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
            onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.clearInput && _ctx.clearInput(...args)))
          }, [
            _createVNode(_component_Icon, { name: "dismiss" })
          ], 42, _hoisted_3))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("button", {
      type: "submit",
      class: _normalizeClass(_ctx.$style.searchButton)
    }, _toDisplayString(_ctx.$t('search.searchButton')), 3),
    (_ctx.popupShown)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          id: _ctx.popupId,
          ref: "popup",
          class: _normalizeClass(_ctx.$style.suggestions),
          role: "grid",
          tabindex: "-1",
          onFocus: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.onPopupFocus && _ctx.onPopupFocus(...args))),
          onBlur: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
        }, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('search.recentHeader')), 1),
          _createElementVNode("ol", {
            class: _normalizeClass(_ctx.$style.list),
            role: "rowgroup"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
              return (_openBlock(), _createElementBlock("li", {
                key: row.query,
                class: _normalizeClass(_ctx.$style.suggestion),
                role: "row"
              }, [
                _createVNode(_component_Icon, {
                  class: _normalizeClass(_ctx.$style.listRowIndicator),
                  name: "search"
                }, null, 8, ["class"]),
                _createElementVNode("button", {
                  id: row.buttons.text.id,
                  class: _normalizeClass([_ctx.$style.queryButton, row.buttons.text.active ? _ctx.$style.activeCell : '']),
                  type: "submit",
                  tabindex: "-1",
                  "aria-selected": row.buttons.text.active,
                  role: "gridcell",
                  onMousedown: _withModifiers(($event: any) => (_ctx.inputQuery = row.query), ["prevent"])
                }, _toDisplayString(row.query), 43, _hoisted_5),
                _createElementVNode("button", {
                  id: row.buttons.delete.id,
                  type: "button",
                  class: _normalizeClass([_ctx.$style.deleteButton, row.buttons.delete.active ? _ctx.$style.activeCell : '']),
                  tabindex: "-1",
                  role: "gridcell",
                  "aria-label": _ctx.$t('search.deleteButton', { query: row.query }),
                  "aria-selected": row.buttons.delete.active,
                  onMousedown: _withModifiers(() => _ctx.removeOption(row.query), ["prevent"])
                }, [
                  _createVNode(_component_Icon, { name: "trashcan" })
                ], 42, _hoisted_6)
              ], 2))
            }), 128))
          ], 2)
        ], 42, _hoisted_4))
      : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}