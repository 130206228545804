// For some reason, importing `onBeforeUpdate` from 'vue' doesn't work.
import { ComponentInternalInstance, onBeforeUpdate } from 'vue';

export function useForRefs<T = HTMLElement>(component: ComponentInternalInstance | null) {
  const itemRefs: T[] = [];

  const setItemRef = (el: T | null) => {
    if (el) {
      itemRefs.push(el);
    }
  };

  onBeforeUpdate(() => {
    itemRefs.splice(0);
  }, component);

  return {
    itemRefs,
    setItemRef
  };
}
