import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.$style.form),
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('gdpr.modal.intro')), 1),
    _createVNode(_component_FormCheckbox, {
      modelValue: "on",
      label: _ctx.$t('gdpr.modal.requiredCookies.header'),
      showLabel: true,
      disabled: true,
      description: _ctx.$t('gdpr.modal.requiredCookies.explanation')
    }, null, 8, ["label", "description"]),
    _createVNode(_component_FormCheckbox, {
      modelValue: _ctx.checkbox,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkbox) = $event)),
      label: _ctx.$t('gdpr.modal.researchCookies.header'),
      showLabel: true,
      description: _ctx.$t('gdpr.modal.researchCookies.explanation')
    }, null, 8, ["modelValue", "label", "description"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.links)
    }, [
      _createElementVNode("p", {
        innerHTML: _ctx.$t('gdpr.modal.learnMoreAboutCookies', {
          a: (val) => `<a class='linked focus-outline' href='${_ctx.cookiePolicyPath}' target='_blank'>${val}</a>`
        })
      }, null, 8, _hoisted_1),
      _createElementVNode("p", {
        innerHTML: _ctx.$t('gdpr.modal.learnMoreAboutPrivacy', {
          a: (val) => `<a class='linked focus-outline' href='${_ctx.privacyPolicyPath}' target='_blank'>${val}</a>`
        })
      }, null, 8, _hoisted_2)
    ], 2),
    _createVNode(_component_FormSubmitButton, {
      label: _ctx.$t('gdpr.modal.confirm'),
      size: "small"
    }, null, 8, ["label"])
  ], 34))
}