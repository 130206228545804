<template>
  <div :class="$style.statusBlock">
    <h2
      ref="header"
      :tabIndex="-1"
      class="visually-hidden"
    >
      {{ $t('annotationCopying.banner.header') }}
    </h2>
    <DynamicExpander
      :key="numInProgress"
      :numElements="filteredJobs.length || 0"
      :panelId="`copy-status-panel-${titleSlug}`"
      :headerId="titleSlug"
    >
      <transition-group
        v-if="copyState.status === 'loaded' && filteredJobs.length"
        tag="ol"
        role="list"
        name="list"
        :enterFromClass="$style.listEnter"
        :enterActiveClass="$style.listEnterActive"
        :leaveActiveClass="$style.listLeaveActive"
        :leaveToClass="$style.listLeaveTo"
      >
        <li
          v-for="status in filteredJobs"
          :key="status.id"
          :class="$style.listItem"
        >
          <CopyStatusBanner
            :status="status.status"
            :source="status.source_title"
            :target="status.target_title"
            @update="(val) => update(status.id, val)"
          />
        </li>
      </transition-group>

      <p
        v-else
        class="visually-hidden"
      >
        {{ $t('annotationCopying.banner.empty') }}
      </p>
    </DynamicExpander>
  </div>
</template>

<script lang="ts">
import CopyStatusBanner from 'app/components/CopyStatusBanner.vue';
import DynamicExpander from 'app/components/DynamicExpander.vue';
import { CopyUpdateType, useAnnotationCopying } from 'app/functions/use-annotation-copying';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { ADCopyJobState } from 'app/keys/injection-keys';
import { ComputedRef, computed, defineComponent, inject, ref } from 'vue';

export default defineComponent({
  name: 'AnnotationDetailsCopyContent',
  components: {
    CopyStatusBanner,
    DynamicExpander
  },
  props: {
    titleSlug: {
      type: String,
      required: true
    }
  },
  emits: [
  ],
  setup: () => {
    const header = ref<HTMLElement | null>(null);

    const copyState = inject(ADCopyJobState) as ComputedRef<CopyJobsState>;

    const filteredJobs = computed(() => {
      return copyState.value.status === 'loaded'
        ? copyState.value.jobs.filter((job) => !job.reviewed)
        : [];
    });

    // Use as :key for DynamicExpander.
    // Needed for small screens, where the number of banners doesnt change but the size of a banner may.
    // If number changes, reload the component so panel height readjusts.
    const numInProgress = computed(() => filteredJobs.value.map((job) => job.status === 'IN_PROGRESS').length);

    const { updateCopyJob } = useAnnotationCopying();

    const update = (id: number, updateType: CopyUpdateType) => {
      // Shift focus to visually hidden header
      header.value?.focus();

      updateCopyJob(id, updateType);
    };

    return {
      copyState,
      filteredJobs,
      header,
      numInProgress,
      update
    };
  }
});
</script>

<style module>
.status-block {
  padding-bottom: 1rem;
}

.list-item {
  transition: opacity .5s ease, transform .5s ease;
}

.list-leave-active {
  position: absolute;
  width: calc(100% - 2rem);
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
