import { C } from 'app/base/common';

export class WaitList {
  public original: any;
  public total: number;
  public position: number;
  public copies: number;
  public prerelease: boolean;

  public static MAX_ESTIMATE = 25;


  public estimate() {
    if (this.prerelease || this.copies < 1) {
      return Infinity;
    }
    const avgLoanWeeks = 2; // FIXME: add more intelligence about lendingPeriods
    const ahead = (this.position || this.total || 1) / this.copies;

    return Math.round(ahead * avgLoanWeeks);
  }


  public ratio() {
    return this.total && this.copies ? this.total / this.copies : 0;
  }


  public originalPosition() {
    return Math.max(this.position, this.original ? this.original.position : 0);
  }


  public acquiredCopies() {
    const originalCopies = this.original ? this.original.copies : this.copies;

    return Math.max(0, this.copies - originalCopies);
  }


  public roundsAhead() {
    if (!this.copies || !this.position) {
      return 0;
    }

    return Math.floor((this.position - 1) / this.copies);
  }


  public roundsBehind() {
    const copies = this.copies || 1;
    const pos = this.position || 0;
    const endOfOwnRound = Math.ceil(pos / copies) * copies;
    const surplus = this.total - endOfOwnRound;

    return Math.round(surplus / copies);
  }


  public deserialize(attrs: any = {}) {
    this.copies = attrs.ownedCopies || attrs.copies || 0;
    this.total = attrs.holdsCount || attrs.total || 0;
    this.position = attrs.holdListPosition || attrs.position;
    this.prerelease = attrs.isPreReleaseTitle || attrs.prerelease;
    if (attrs.original) {
      this.original = new WaitList().deserialize(attrs.original);
    }

    // TMP for testing
    // if (attrs.holdListPosition) {
    //   this.copies = 6;
    //   this.total = 27;
    //   this.position = 25;
    // }
    return this;
  }


  public serialize() {
    const out: any = {};
    C.each(
      ['copies', 'position', 'total', 'prerelease'],
      (attr) => {
        if (typeof this[attr] !== 'undefined') {
          out[attr] = this[attr];
        }
      }
    );
    if (this.original) {
      out.original = this.original.serialize();
    }

    return out;
  }
}
