import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseListPage = _resolveComponent("BaseListPage")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseListPage, {
        title: _ctx.title,
        subtitle: _ctx.resultCount,
        ariaTitle: _ctx.query ? _ctx.$t('list.searchResults', { query: _ctx.query }) : undefined,
        titleState: _ctx.titleState,
        seriesState: _ctx.seriesState,
        currentTab: _ctx.currentTab,
        currentSort: _ctx.currentSortOption,
        sortOptions: _ctx.sortOptions,
        allFilters: _ctx.allFilters,
        "onUpdate:tab": _cache[0] || (_cache[0] = (val) => _ctx.updatePath({ tab: val })),
        "onUpdate:page": _ctx.updatePage,
        "onUpdate:filters": _cache[1] || (_cache[1] = (val) => _ctx.updatePath({ filters: val, tPage: 1, sPage: 1 })),
        "onUpdate:sort": _ctx.updateSort
      }, null, 8, ["title", "subtitle", "ariaTitle", "titleState", "seriesState", "currentTab", "currentSort", "sortOptions", "allFilters", "onUpdate:page", "onUpdate:sort"])
    ], undefined, true),
    _: 1
  }))
}