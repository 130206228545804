<template>
  <Surface>
    <Collection
      v-if="state.state === 'success'"
      :title="state.title"
      :description="state.description"
      :searchType="state.searchType"
      :definition="state.definition"
      :currentSort="currentSort"
      :appliedFilters="appliedFilters"
      :titlePage="state.searchType === 'title' ? page : undefined"
      :seriesPage="state.searchType === 'series' ? page : undefined"
    />
    <LoadingPage v-else-if="state.state === 'loading'" />
    <p v-else>
      {{ $t('list.error') }}
    </p>
  </Surface>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { SearchDefinition, SearchSortOption, SearchType, toSearchDefinition } from 'app/base/search';
import LoadingPage from 'app/components/LoadingPage.vue';
import Surface from 'app/components/Surface.vue';
import { SubjectFilters } from 'app/functions/use-subject-filters';
import Collection from 'app/views/Collection.vue';
import { defineComponent, PropType, ref, watch } from 'vue';

type DefinitionRequestState = {
  state: 'loading';
} |
{
  state: 'success';
  title: string;
  searchType: SearchType;
  definition: SearchDefinition;
  description?: string;
} |
{
  state: 'error';
};

export default defineComponent ({
  name: 'CollectionDefinition',
  components : {
    Collection,
    LoadingPage,
    Surface
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    breakpoint: {
      type: String,
      default: undefined
    },
    currentSort: {
      type: String as PropType<SearchSortOption>,
      default: undefined
    },
    appliedFilters: {
      type: Object as PropType<SubjectFilters>,
      default: undefined
    },
    page: {
      type: Number,
      default: 1
    }
  },
  setup: (props, ctx) => {
    const state = ref<DefinitionRequestState>({
      state: 'loading'
    });

    watch(() => props.collectionId, async () => {
      try {
        state.value = { state: 'loading' };

        const listDefinition = await APP.services.thunder.getCollectionDefinition(APP.library.key(), props.collectionId);

        if (listDefinition) {
          state.value = {
            state: 'success',
            title: listDefinition.name,
            searchType: listDefinition.itemType === 'Series' ? SearchType.Series : SearchType.Title,
            definition: toSearchDefinition(listDefinition, props.breakpoint),
            description: listDefinition.description
          };
        } else {
          state.value = { state: 'error' };
        }
      } catch {
        state.value = { state: 'error' };
      }
    }, { immediate: true });

    return {
      state
    };
  }
});
</script>
