import { PostishCopyAnnotationSnapshot, PostishCopyTitleSnapshot } from 'app/base/postish';
import i18n from 'app/i18n/i18n';
import { Annotation } from 'app/models/annotation';
import { Title } from 'app/models/title';
import { releaseDateToDate } from 'lib/common/date';

type ReleaseInfo = {
  name?: string;
  date?: Date;
  slug?: string;
};

type AnnotationRequiredProps = Pick<Annotation, 'release' | 'releaseDate' | 'releaseSlug'>;
type TitleRequiredProps = Pick<Title, 'lexisMetadata' | 'slug'>;
type CopyJobRequiredProps = Pick<PostishCopyTitleSnapshot, 'release' | 'release_date' | 'title_id'>;
type AnnotationSnapshotRequiredProps = Pick<PostishCopyAnnotationSnapshot, 'release' | 'release_date'>;

function toDisplayName(info: ReleaseInfo) {
  const releaseName = info.name;

  const releaseDate = info.date
    ? i18n.t('time.date', { date: info.date })
    : undefined;

  if (releaseName && releaseDate) {
    return `${releaseName} - ${releaseDate}`;
  }

  if (releaseName) {
    return releaseName;
  }

  if (releaseDate) {
    return releaseDate;
  }

  return info.slug || '';
}


export function getReleaseDisplayNameAnnotation(annotation: AnnotationRequiredProps) {
  const releaseInfo: ReleaseInfo = {
    name: annotation.release,
    date: releaseDateToDate(annotation.releaseDate),
    slug: annotation.releaseSlug
  };

  return toDisplayName(releaseInfo);
};

export function getReleaseDisplayNameTitle(title: TitleRequiredProps) {
  const releaseInfo: ReleaseInfo = {
    name: title.lexisMetadata?.release,
    date: releaseDateToDate(title.lexisMetadata?.releaseDate),
    slug: title.slug
  };

  return toDisplayName(releaseInfo);
};

export function getReleaseDisplayNameCopyJob(titleSnapshot: CopyJobRequiredProps) {
  const releaseInfo = {
    name: titleSnapshot.release || undefined,
    date: releaseDateToDate(titleSnapshot.release_date || undefined),
    slug: titleSnapshot.title_id
  };

  return toDisplayName(releaseInfo);
}

export function getReleaseDisplayNameAnnotationSnapshot(annotationSnapshot: AnnotationSnapshotRequiredProps) {
  const releaseInfo = {
    name: annotationSnapshot.release || undefined,
    date: releaseDateToDate(annotationSnapshot.release_date || undefined)
  };

  return toDisplayName(releaseInfo);
}
