import { Events } from './events';
import { Quirks } from 'lib/quirkbase/quirkbase';

export class ScrollManager {
  public static lastScrollTimestamp: number;


  public static scrollable(element: any, axis: 'x' | 'y'): void {
    if (element._scrollableAxis === axis) {
      return;
    }
    element._scrollableAxis = axis;
    element.classList.add('native-scrollable');
    element.classList.add(`native-scrollable-${axis}`);
    Events.GLOBAL_EVENTS.setTouchAction(element, `pan-${axis}`);
    if (Quirks.has('legacy-ontap')) {
      Events.GLOBAL_EVENTS.on('scroll', () => {
        ScrollManager.lastScrollTimestamp = (new Date()).getTime();
      }, element);
    }
  }
}
