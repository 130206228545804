<template>
  <Teleport
    to="#toasts"
  >
    <button
      :class="$style.shield"
      :aria-label="$t('general.close')"
      @click="$emit('close')"
    ></button>
    <FocusTrap
      :escapeDeactivates="true"
      @deactivate="$emit('close')"
    >
      <div
        :class="[$style.toast, $style[type], animation ? $style[animation] : '']"
        role="alertdialog"
        aria-modal="true"
        :aria-labelledby="messageId"
      >
        <Icon
          :name="iconName"
        />

        <p
          :id="messageId"
          :class="$style.message"
        >
          {{ message }}
        </p>

        <div :class="$style.actions">
          <slot name="action"></slot>

          <button
            :class="$style.dismiss"
            @click="$emit('close')"
          >
            {{ $t('general.dismiss') }}
          </button>
        </div>
      </div>
    </FocusTrap>
  </Teleport>
</template>

<script lang="ts">
import FocusTrap from 'app/components/FocusTrap.vue';
import { generateUUID } from 'lib/common';
import { PropType, computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'ActionToast',
  components: {
    FocusTrap
  },
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String as PropType<'success' | 'error' | 'info'>,
      default: 'success'
    }
  },
  emits: [
    'close'
  ],

  setup: (props) => {
    const messageId = generateUUID();
    const animation = ref<'fadeIn' | null>(null);

    const iconName = computed(() => {
      return props.type === 'success' ? 'check'
        : props.type === 'error' ? 'dismiss'
        : 'info';
    });

    /**
     * Want to only mount the component when needed (v-if instead of v-show),
     * but can't use <Transition> with the <Teleport> and get it to work right,
     * so we need to force an animation once the component is mounted
     * Eventually this might help us: https://github.com/vuejs/core/pull/6548
     * And more info about it: https://github.com/vuejs/core/issues/5836
     */
    onMounted(() => {
      animation.value = 'fadeIn';
    });

    return {
      animation,
      iconName,
      messageId
    };
  }
});
</script>

<style module>

.shield {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 200;
}

.toast {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 4rem;
  line-height: normal;
  color: var(--c-white);
  display: flex;
  align-items: center;
  padding: 0 2rem;
  z-index: 200;

  display: grid;
  grid-template-areas:
    'icon message actions';
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
}

@media screen and (max-width: 499px) {
  .toast {
    display: grid;
    grid-template-areas:
      'icon'
      'message'
      'actions';
    grid-template-columns: auto;
    justify-items: center;
    gap: 0.75rem;
    padding: 1rem;
  }
}


.toast svg {
  stroke: currentColor;
  fill: currentColor;
  height: 1.25rem;
  width: 1.25rem;
  grid-area: 'icon';
}

.toast.fadeIn {
  animation: fadein 500ms;
}

.toast.success {
  background-color: var(--c-notif-success);
}

.toast.error {
  background-color: var(--c-notif-error);
}

.toast.info {
  color: var(--c-black);
  background-color: var(--c-notif-info);
}

.message {
  grid-area: 'message';
}

.actions {
  display: flex;
  gap: 1rem;
  grid-area: 'actions';
}

.actions > button {
  border: 1px solid;
  border-radius: var(--form-border-radius);
  padding: 0.5rem 1rem;
}

@-webkit-keyframes fadein {
  to { opacity: 1; }
  from { opacity: 0; }
}

@keyframes fadein {
  to { opacity: 1; }
  from { opacity: 0; }
}
</style>
