export const LIST_SOURCES = [
  'everything',
  'curated',
  'generated',
  'spotlight',
  'imprint',
  'series'
];

export enum Timeline {
  Home = 'home',
  Onboarding = 'onboarding',
  Browse = 'browse',
  MyBooks = 'mybooks',
  Notes = 'notes',
  Export = 'export',
  Tags = 'tags',
  Ntc = 'ntc',
  MySubscriptions = 'mysubscriptions'
}

export enum RouteName {
  Home = 'Home',
  SignOut = 'SignOut',
  SignedOut = 'SignedOut',
  Reset = 'Reset',
  Welcome = 'Welcome',
  Login = 'Login',
  Redirect = 'Redirect',
  LegacyRedirectFailed = 'LegacyRedirectFailed',
  LibraryHome = 'LibraryHome',
  TitleDetails = 'TitleDetails',
  SetDetails = 'SetDetails',
  OpenBook = 'OpenBook',
  NotFound = 'NotFound',
  Browse = 'Browse',
  Subject = 'Subject',
  Subjects = 'Subjects',
  Creator = 'Creator',
  Publisher = 'Publisher',
  Search = 'Search',
  SearchTitleDetails = 'SearchTitleDetails',
  SearchSetDetails = 'SearchSetDetails',
  MyBooks = 'MyBooks',
  Notes = 'Notes',
  NoteDetails = 'NoteDetails',
  CopyReview = 'CopyReview',
  Export = 'Export',
  Tags = 'Tags',
  TagDetails = 'TagDetails',
  Collection = 'Collection',
  Ntc = 'Ntc',
  MySubscriptions = 'MySubscriptions'
}
