import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["value", "maxlength", "placeholder", "aria-label"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FormError = _resolveComponent("FormError")!
  const _component_PopoutDialog = _resolveComponent("PopoutDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      ref: "tagActions",
      type: "button",
      "aria-haspopup": "dialog",
      class: _normalizeClass([_ctx.$style.tagActionsMenu, 'dark']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTagActions = true))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t('tag.actions.header')) + " ", 1),
      _createVNode(_component_Icon, { name: "overflow" })
    ], 2),
    (_ctx.showTagActions && _ctx.tagActions)
      ? (_openBlock(), _createBlock(_component_PopoutDialog, {
          ref: "popout",
          key: _ctx.state,
          headerLabel: _ctx.headerLabel,
          display: _ctx.state,
          reference: _ctx.tagActions,
          animate: _ctx.animate,
          onClose: _ctx.onClose
        }, {
          default: _withCtx(() => [
            (_ctx.state === 'menu')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.mainMenu)
                }, [
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass([_ctx.$style.tagActionButton, 'focus-outline']),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state = 'renaming'))
                  }, _toDisplayString(_ctx.$t('tag.actions.rename.header')), 3),
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass([_ctx.$style.tagActionButton, 'focus-outline']),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state = 'deleting'))
                  }, _toDisplayString(_ctx.$t('tag.actions.delete.header')), 3)
                ], 2))
              : (_ctx.state === 'renaming')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style.actionMenu)
                  }, [
                    _createElementVNode("input", {
                      value: _ctx.tagRenameVal,
                      type: "text",
                      name: "tagName",
                      class: _normalizeClass([_ctx.$style.renameInput,'focus-outline']),
                      autocapitalize: "off",
                      autocomplete: "off",
                      autocorrect: "off",
                      maxlength: _ctx.Tag.MAX_LENGTH,
                      placeholder: _ctx.$t('tag.actions.rename.placeholder'),
                      "aria-label": _ctx.$t('tag.actions.rename.header'),
                      required: "",
                      onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onRenameSubmit && _ctx.onRenameSubmit(...args)), ["prevent"]), ["enter"])),
                      onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.updateRenameValue && _ctx.updateRenameValue(...args)))
                    }, null, 42, _hoisted_1),
                    (_ctx.isInvalidRename)
                      ? (_openBlock(), _createBlock(_component_FormError, {
                          key: 0,
                          class: _normalizeClass(_ctx.$style.renameWarning),
                          contents: _ctx.$t('tag.actions.rename.invalid')
                        }, null, 8, ["class", "contents"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.actionActions)
                    }, [
                      _createElementVNode("button", {
                        class: _normalizeClass([_ctx.$style.tagActionButton, 'focus-outline', { 'disabled': _ctx.isInvalidRename }]),
                        disabled: _ctx.isInvalidRename,
                        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onRenameSubmit && _ctx.onRenameSubmit(...args)))
                      }, _toDisplayString(_ctx.$t('tag.actions.rename.confirm')), 11, _hoisted_2),
                      _createElementVNode("button", {
                        type: "button",
                        class: _normalizeClass([_ctx.$style.tagActionButton, 'focus-outline']),
                        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
                      }, _toDisplayString(_ctx.$t('tag.actions.rename.cancel')), 3)
                    ], 2)
                  ], 2))
                : (_ctx.state === 'deleting')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass(_ctx.$style.actionMenu)
                    }, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('tag.actions.delete.warning')), 1),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.actionActions)
                      }, [
                        _createElementVNode("button", {
                          type: "button",
                          class: _normalizeClass([_ctx.$style.tagActionButton, 'focus-outline']),
                          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onDeleteClick && _ctx.onDeleteClick(...args)))
                        }, _toDisplayString(_ctx.$t('tag.actions.delete.confirm')), 3),
                        _createElementVNode("button", {
                          type: "button",
                          class: _normalizeClass([_ctx.$style.tagActionButton, 'focus-outline']),
                          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
                        }, _toDisplayString(_ctx.$t('tag.actions.delete.cancel')), 3)
                      ], 2)
                    ], 2))
                  : _createCommentVNode("", true)
          ], undefined, true),
          _: 1
        }, 8, ["headerLabel", "display", "reference", "animate", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}