import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.selectedInternal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedInternal) = $event)),
    options: _ctx.transformedOptions,
    labelId: _ctx.labelId,
    placeholder: _ctx.prompt,
    buttonClass: _ctx.$style.button,
    iconClass: _ctx.$style.icon,
    listClass: _ctx.$style.list,
    optionClass: _ctx.classOverrides['option'] || _ctx.$style.option,
    highlightedClass: _ctx.classOverrides['highlighted'] || _ctx.$style.highlighted,
    selectedClass: _ctx.$style.selected
  }, _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx(() => [
          _renderSlot(_ctx.$slots, name)
        ])
      }
    })
  ]), 1032, ["modelValue", "options", "labelId", "placeholder", "buttonClass", "iconClass", "listClass", "optionClass", "highlightedClass", "selectedClass"]))
}