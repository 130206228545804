import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _component_TitleCardOptions = _resolveComponent("TitleCardOptions")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    item: _ctx.cardItem,
    link: _ctx.computedLink,
    borderless: _ctx.borderless
  }, {
    cover: _withCtx(() => [
      _createVNode(_component_Cover, {
        item: _ctx.title,
        bordered: true
      }, null, 8, ["item"])
    ]),
    actions: _withCtx(() => [
      _renderSlot(_ctx.$slots, "actions", {}, () => [
        _createVNode(_component_TitleCardOptions, {
          title: _ctx.title,
          actionsIconsOnly: _ctx.actionsIconsOnly
        }, null, 8, ["title", "actionsIconsOnly"])
      ])
    ]),
    _: 3
  }, 8, ["item", "link", "borderless"]))
}