import { APP } from 'app/base/app';
import { Annotation } from './annotation';
import { Collation } from './collation';

export class ExportQueue extends Collation<Annotation> {
  protected ITEM_CLASS = Annotation;
  protected ITEM_NAME = 'export-queue';
  protected SERIAL_VERSION = 1;
  protected SERIAL_VERSION_MIN = 1;


  constructor() {
    super();
    APP.events.on('export-queue:insert', ({ m }) => {
      const noteIdsToAdd = new Set(m);
      const notes = APP.patron.annotations.all.filter((note) => noteIdsToAdd.has(note.uuid));
      this.subsume(notes);
    });
    APP.events.on('export-queue:remove', ({ m }) => {
      if (!m.length) { return; } // collation.announce also dispatches this event name, but with the full annotation, so return here to prevent it from erroring
      m.forEach((uuid) => {
        this.remove({ uuid });
      });
    });
    APP.events.on('annotation:update:all', () => this._syncAnnotations());
  }


  protected _fullAttributesToQuery(itemAttributes: Annotation): any {
    return { uuid: itemAttributes.uuid };
  }


  private _syncAnnotations(): void {
    const annotationsInQueue = this.all.map((a) => a.uuid);

    const updatedAnnotations = APP.patron.annotations.filter({ uuid: annotationsInQueue });

    this.sync(updatedAnnotations);
  }
}
