import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import env from 'app/base/env';
import { Annotation } from 'app/models/annotation';
import { LibraryCard } from 'app/models/library-card';
import { EncodingContext } from 'lib/common';
import { AuthLoginPage, DervishResponse, LoginFormSubmission } from './interfaces';
import { FetchAsyncError, FetchOptions, HttpMethod } from './server';
import { IdNamePair, ThunderMediaResponse } from './thunder';

/**
 * Sentry is the backend service that integrates with OPAS to
 * authenticate patrons, and integrates with Thunder to perform
 * authorized transactions (such as borrowing, renewing,
 * returning, and synchronizing).
 *
 * Sentry also acts as a Dervish connector app, checking and
 * signing user requests to open ebooks in Read and audiobooks
 * in Listen.
 */
export class Sentry {
  private readonly CHECKOUT_RENEWAL_WINDOW_ERR = 'CheckoutNotWithinRenewalWindow';

  public chip!: string;
  public primaryChip!: string;
  public identityToken!: string;

  /**
   * Note: this should be the client name (aka codename), not the
   * product name. So 'dewey' not 'libby'. Sentry has an internal
   * method for mapping client names to product names when the
   * product name is expected by Thunder. See the Sentry thunder.rb
   * source file if you want to modify this mapping.
   */
  public static CLIENT_NAME = 'elrond';
  public static BANK_KEY_CHIP = 'sentry.chip';
  public static BANK_KEY_PRIMARY_CHIP = 'sentry.primary_chip';
  public static BANK_KEY_IDENTITY_TOKEN = 'sentry.identity_token';
  public static SYNC_DELAY_AFTER_CHIP = 3000;


  constructor() {
    this.acquireChip();
  }


  public async acquireChip(): Promise<void> {
    this.chip = APP.bank.get(Sentry.BANK_KEY_CHIP);
    this.identityToken = APP.bank.get(Sentry.BANK_KEY_IDENTITY_TOKEN);
    this.primaryChip = APP.bank.get(Sentry.BANK_KEY_PRIMARY_CHIP);

    try {
      const response = await APP.services.sentry.fetchAsync<SentryChipResponse>(this.requestOptions({
        url: `chip?client=${Sentry.CLIENT_NAME}`
      }));

      if (!response) {
        throw new Error('Received null response from Sentry');
      }

      if (this.chip !== response.chip) {
        this.chip = response.chip;
        APP.bank.set(Sentry.BANK_KEY_CHIP, this.chip);
        APP.events.dispatch('patron:chip:acquired', { chip: this.chip });
      }
      if (response.identity) {
        this._setIdentityToken(response.identity);
      }

      const qs = C.parseQueryString<{name: string}>(location.search);
      if ((response.syncable || APP.patron.isAuthenticated()) &&
          qs.name !== 'authentication:complete') {
        // Only do this sync if the user is already signed in
        setTimeout(
          () => APP.patron.syncCoordinator.remoteSync({
            force: true
          }),
          Sentry.SYNC_DELAY_AFTER_CHIP
        );
      }
    } catch (ex) {
      APP.semaphore.set('synchronize', 'canceled');
      if (this.chip) {
        console.warn(
          '[SENTRY] Remote chip acquisition failed. Loading from bank.'
        );
        this._setIdentityToken(this.identityToken);
      } else {
        // TODO: show error page, where only option is a restart
        throw new Error('Remote chip acquisition failed.');
      }
    }
  }


  public fetchLoginForms(websiteId: number, ghost: boolean): Promise<AuthLoginPage | null> {
    return APP.services.sentry.fetchAsync<AuthLoginPage>(this.requestOptions({
      url: `auth/forms/${websiteId}?ghost=${ghost}&email=true`,
      method: 'GET',
      timeout: 25000
    }));
  }


  public async fetchIdentityToken(): Promise<void> {
    const identityToken = await APP.services.sentry.fetchAsync<string>(this.requestOptions({
      url: 'chip/identity',
      method: 'GET',
      textRespone: true
    }));
    if (!identityToken) {
      throw new Error('Received null identity token');
    }

    this._setIdentityToken(identityToken);
  }


  /**
   * Sync data of a specific type.
   * // TODO: Fix sync type
   * @param plan 'cards' | 'loans' | 'holds'
   * @param onSuccess
   * @param onFailure
   */
  public async sync<K extends keyof SyncPlan>(plan: K): Promise<SyncPlan[K]> {
    const response = await APP.services.sentry.fetchAsync<SentrySyncResponse>(this.requestOptions({
      url: `chip/sync/${plan}`,
      method: 'GET',
      timeout: 25000
    }));

    if (!response) {
      throw new Error('Received null sync response from Sentry');
    }

    return response[plan];
  }


  public async unlinkCard(card: LibraryCard): Promise<void> {
    await APP.services.sentry.fetchAsync(this.requestOptions({
      url: `card/${card.cardId}`,
      method: 'DELETE'
    }));
  }


  public async reset(): Promise<void> {
    await APP.services.sentry.fetchAsync(this.requestOptions({
      url: 'chip',
      method: 'DELETE',
      textRespone: true
    }));
  }


  public async completeExternalAuthentication(commitURI: string): Promise<SentrySyncResponse> {
    const realUri = commitURI.replace(/^(?:\/\/|[^\/]+)*\//, '');

    const response = await APP.services.sentry.fetchAsync<SentrySyncResponse>(this.requestOptions({
      url: realUri
    }));

    if (!response) {
      throw new Error('Received null response from Sentry');
    }

    this._setIdentityToken(response.identity);

    return response;
  }


  public async localAuth(formData: LoginFormSubmission, websiteId: number): Promise<SentrySyncResponse> {
    const body: any = {
      website_id: websiteId,
      ils: formData.ilsName,
      type: 'card'
    };
    if (formData.username) {
      body.username = formData.username;
    }
    if (formData.password) {
      body.password = formData.password;
    }
    if (formData.captcha) {
      body.captcha = formData.captcha;
    }

    // TODO: IgnoreStatusCodes make sense with fetchAsync :thinking:.  If so Server class will need to be updated.
    // options.ignoreStatusCodes = [ 401, 404 ];

    const response = await APP.services.sentry.fetchAsync<SentrySyncResponse>(this.requestOptions({
      url: `auth/link/${websiteId}`,
      method: 'POST',
      bodyAsJson: JSON.stringify(body)
    }));

    if (!response) {
      throw new Error('Received null response from Sentry');
    }

    this._setIdentityToken(response.identity);

    return response;
  }


  public async ipAuth(
    formData: { websiteId: number; ilsName: string; captcha?: string }
  ): Promise<SentrySyncResponse> {
    const body: any = {
      website_id: formData.websiteId,
      ils: formData.ilsName
    };

    if (formData.captcha) {
      body.captcha = formData.captcha;
    }

    const response = await APP.services.sentry.fetchAsync<SentrySyncResponse>(this.requestOptions({
      url: `auth/link/ip/${formData.websiteId}`,
      method: 'POST',
      bodyAsJson: JSON.stringify(body)
    }));

    if (!response) {
      throw new Error('Received null response from Sentry');
    }

    this._setIdentityToken(response.identity);

    return response;
  }


  public async emailRegistrationRequestCode(emailAddress: string, websiteId: number, ils: string, emailSubject?: string): Promise<SentrySyncResponse> {
    const params = {
      email_address: emailAddress,
      websiteId: websiteId,
      email_subject: emailSubject || undefined,
      ils: ils
    };

    const codeResponse = await APP.services.sentry.fetchAsync<SentrySyncResponse>(this.requestOptions({
      url: `auth/link/email/${websiteId}`,
      method: 'POST',
      bodyAsJson: JSON.stringify(params)
    }));

    if (!codeResponse) {
      throw new Error('Received null response from Sentry');
    }

    return codeResponse;
  }


  public async emailRegistrationSubmitCode(emailAddress: string, websiteId: number, ils: string, securityCode = ''): Promise<SentrySyncResponse> {
    const params = {
      security_code: securityCode,
      email_address: emailAddress,
      ils: ils
    };

    const resp = await APP.services.sentry.fetchAsync<SentrySyncResponse>(this.requestOptions({
      url: `auth/link/email/${websiteId}`,
      method: 'POST',
      bodyAsJson: JSON.stringify(params)
    }));

    if (!resp) {
      throw new Error('Received null response from Sentry');
    }

    if (securityCode && resp.identity) {
      this._setIdentityToken(resp.identity);
    }

    return resp;
  }


  public fulfillLoan(titleSlug: string, fft: FulfillmentFormatType): Promise<SentryFulfillmentResponse | null> {
    const url = [
      `card/${APP.patron.currentCard().cardId}`, // TODO: Verify this is correct once we have multi-card support
      `loan/${titleSlug}`,
      `fulfill/${fft.thunderId}`
    ].join('/');

    return APP.services.sentry.fetchAsync<SentryFulfillmentResponse>(this.requestOptions({
      url: url,
      method: 'GET'
    }));
  }


  /**
   * A request with an implicit lending period (default) uses the default
   * lending period defined by the library, card, and title combination,
   * i.e., respects the lending period defaults set by the library on the
   * individual/group level as well as overrides for specific titles.
   * A request with an explicit lending period occurs when debug mode is enabled.
   * @param card
   * @param title
   * @param lendingPeriod
   * @param isRenewal
   */
  public async borrowTitle(
    card: LibraryCard,
    titleSlug: string,
    lendingPeriod?: [number, string],
    isRenewal = false
  ): Promise<SentryLoan | null> {
    try {
      return await APP.services.sentry.fetchAsync<SentryLoan>(this.requestOptions({
        url: `card/${card.cardId}/loan/${titleSlug}`,
        method: isRenewal ? 'PUT' : 'POST',
        bodyAsJson: lendingPeriod && JSON.stringify({ period: lendingPeriod[0], units: lendingPeriod[1] })
      }));
    } catch (ex) {
      if (ex instanceof FetchAsyncError) {
        if (lendingPeriod && ex.response.status === 400) {
          return this.borrowTitle(card, titleSlug, undefined, isRenewal);
        }

        // The UI should not have allowed this. Tsk tsk.
        if (ex.response.bodyText.indexOf(this.CHECKOUT_RENEWAL_WINDOW_ERR) >= 0) {
          console.log('Unable to renew title outside of renewal window.');
        }
      }
      throw ex;
    }
  }


  public async returnLoan(titleSlug: string): Promise<void> {
    await APP.services.sentry.fetchAsync(this.requestOptions({
      url: `card/${APP.patron.currentCard().cardId}/loan/${titleSlug}`,
      method: 'DELETE',
      textRespone: true
    }));
  }


  /**
   * Search for an anchor in a title
   * NOTE: Use the "searchAnchorByBUID()" over this method when possible,
   * as it has better performance and yields the same results
   * @param titleSlug Unique title ID
   * @param query The query to search for
   * @param path The path for the match
   */
  public async searchAnchorBySlug(titleSlug: string, query: string, path: string): Promise<SentrySearchAnchorResponse | null> {
    return APP.services.sentry.fetchAsync(this.requestOptions({
      url: `title/${titleSlug}/search_anchor?query=${C.encode(query, EncodingContext.UriComponent)}&path=${C.encode(path, EncodingContext.UriComponent)}`,
      method: 'GET'
    }));
  }


  /**
   * Search for an anchor in a title
   * @param buid Unique title buid
   * @param query The query to search for
   * @param path The path for the match
   */
  public async searchAnchorByBUID(buid: string, query: string, path: string): Promise<SentrySearchAnchorResponse | null> {
    return APP.services.sentry.fetchAsync(this.requestOptions({
      url: `book/${buid}/search_anchor?query=${C.encode(query, EncodingContext.UriComponent)}&path=${C.encode(path, EncodingContext.UriComponent)}`,
      method: 'GET'
    }));
  }


  public holdTitle(card: LibraryCard, titleSlug: string): Promise<SentryHold | null> {
    if (!titleSlug) {
      throw new Error('Error placing hold!  No title passed.');
    }

    const prefs = APP.patron.cardPreferences();

    return APP.services.sentry.fetchAsync<SentryHold>(this.requestOptions({
      url: `card/${card.cardId}/hold/${titleSlug}`,
      bodyAsJson: JSON.stringify({
        email_address: APP.patron.currentCard().emailAddress,
        auto_borrow: prefs.autoBorrowHolds ? 1 : 0,
        days_to_suspend: prefs.daysToSuspendHolds || 0
      })
    }));
  }


  public editHold(
    titleSlug: string,
    assignments: { emailAddress?: string; autoBorrowHolds?: boolean; daysToSuspendHolds?: number}
  ): Promise<SentryHold | null> {
    const bodyParts: { email_address?: string; auto_borrow?: 1 | 0; days_to_suspend?: number } = {};
    if (C.isString(assignments.emailAddress)) {
      bodyParts.email_address = assignments.emailAddress;
    }
    if (typeof assignments.autoBorrowHolds === 'boolean') {
      bodyParts.auto_borrow = assignments.autoBorrowHolds ? 1 : 0;
    }
    if (typeof assignments.daysToSuspendHolds === 'number') {
      bodyParts.days_to_suspend = assignments.daysToSuspendHolds;
    }

    return APP.services.sentry.fetchAsync<SentryHold>(this.requestOptions({
      url: `card/${APP.patron.currentCard().cardId}/hold/${titleSlug}`,
      method: 'PUT',
      bodyAsJson: JSON.stringify(bodyParts)
    }));
  }


  public async cancelHold(titleSlug: string): Promise<void> {
    await APP.services.sentry.fetchAsync(this.requestOptions({
      url: `card/${APP.patron.currentCard().cardId}/hold/${titleSlug}`,
      method: 'DELETE',
      textRespone: true
    }));
  }


  /**
   *
   * @param titleSlug
   * @param titleFormat
   * @param allowClipboard
   * @param allowPrint
   * @param timeout
   * @param parentTitleSlug
   * @param anchorID Optional anchor ID. When supplied, the title will open to the specified anchor
   */
  public fetchDervishURLs(
    titleSlug: string,
    titleFormat: string,
    allowClipboard: boolean,
    allowPrint: boolean,
    disableNotes: boolean,
    timeout?: number,
    parentTitleSlug?: string,
    anchorID?: string
  ): Promise<DervishResponse | null> {
    const card = APP.patron.currentCard();
    const access = card ? `card/${card.cardId}` : 'sample';

    let sharePath = `${APP.client.info.url}/library/${APP.library.baseKey}/open/${titleSlug}`;
    if (parentTitleSlug) {
      sharePath = `${sharePath}?parent=${parentTitleSlug}`;
    }

    const tData = {
      'spec': 'V' + (APP.shell.info.spec || APP.client.info.spec),
      'allow-clipboard': allowClipboard ? 1 : 0,
      'allow-print': allowPrint ? 1 : 0,
      'disable-notes': disableNotes ? 1 : 0,
      'approx-mode': 1,
      'title-id': titleSlug,
      'share-path': sharePath,
      'locale': 'en-US'
    };
    const url = C.parameterizeURL(
      ['open', titleFormat, access, 'title/' + titleSlug].join('/'),
      {
        a: anchorID,
        t: JSON.stringify(tData),
        parent_title_id: parentTitleSlug
      });

    return APP.services.sentry.fetchAsync<DervishResponse>(this.requestOptions({
      url: url,
      method: 'GET',
      timeout: timeout
    }));
  }


  /**
   * Updates the BUID associated with a possession.  Returns
   * an object with the old and new BUIDs if an update was made and
   * null if no update.
   */
  public async updatePossession(titleSlug: string, titleFormat: string): Promise<SentryUpdatePosessionResponse | null> {
    const card = APP.patron.currentCard();

    const response = await APP.services.sentry
      .fetchAsync<string>(
        this.requestOptions({
          url: `card/${card.cardId}/${titleFormat}/data/${titleSlug}?only_if_new_buid=true`,
          textRespone: true,
          method: 'DELETE'
        })
      );

    // Note: If no update was made (because the BUID did not change), a HEAD response
    // will be returned so response will be empty.
    if (response) {
      return JSON.parse(response) as SentryUpdatePosessionResponse;
    }

    return null;
  }


  /**
   * Fetch data from chip stash
   * TODO: Define create type for valid stash keys
   * @param stashKey Stash property to fetch
   * @param onSuccess Success callback
   * @param onFailure Failure callback
   */
  public fetchChipStash<T>(stashKey: '' | 'preferences' = ''): Promise<T | null> {
    return APP.services.sentry.fetchAsync(this.requestOptions({
      url: `chip/stash/${stashKey}`,
      method: 'GET'
    }));
  }


  /**
   * Update chip stash with data
   * @param stashKey Stash property to update
   * @param data Data to store in chip stash
   * @param onSuccess Success callback
   * @param onFailure Failure callback
   */
  public updateChipStash<T = any>(stashKey: string, data: T): Promise<T | null> {
    if (!data || !APP.patron.isAuthenticated()) {
      return Promise.resolve(null);
    }

    return APP.services.sentry.fetchAsync(this.requestOptions({
      url: `chip/stash/${stashKey}`,
      method: 'PUT',
      bodyAsJson: JSON.stringify({
        value: data
      })
    }));
  }


  public async renameCard(card: LibraryCard, newName: string): Promise<void> {
    await APP.services.sentry.fetchAsync(this.requestOptions({
      url: `card/${card.cardId}`,
      method: 'PUT',
      bodyAsJson: JSON.stringify({
        card_name: newName
      })
    }));
  }


  private _setIdentityToken(identityToken: string): void {
    const identity = C.parseJWT(identityToken) as IdentityToken;
    if (identity.chip.accounts && identity.chip.accounts.length) {
      const accountId = identity.chip.accounts[0].id;
      APP.patron.accountId = accountId;
      APP.events.dispatch('patron:accountId:acquired', { accountId });
    }
    if (identity.chip.pri) {
      this.primaryChip = identity.chip.pri;
      APP.bank.set(Sentry.BANK_KEY_PRIMARY_CHIP, this.primaryChip);
    }
    if (identityToken !== this.identityToken) {
      this.identityToken = identityToken;
      APP.bank.set(Sentry.BANK_KEY_IDENTITY_TOKEN, this.identityToken);
      APP.events.dispatch('patron:identityToken:acquired', { identityToken: this.identityToken });
    }
  }


  /**
   * Fetch a code to use for NTC website
   * @param card library card
   */
  public fetchNtcCode(card: LibraryCard): Promise<SentryNtcResponse | null> {
    return APP.services.sentry.fetchAsync<SentryNtcResponse>(this.requestOptions({
      url: `auth/card/${card.cardId}/ntc/${env.NTC_TARGET_CLIENT}`,
      method: 'GET'
    }));
  }


  /**
   * Fetch reading data for android auto information
   * @param card library card
   * @param format loan media type
   * @param titleId title slug
   */
    public fetchReadingData(card: LibraryCard, format: string, titleId: string): Promise<SentryReadingData | null> {
      return APP.services.sentry.fetchAsync<SentryReadingData>(this.requestOptions({
        url: `card/${card.cardId}/${format}/data/${titleId}`,
        method: 'GET'
      }));
    }


  /**
   * Helper for creating server request options using common Sentry options
   * @param options
   */
  public requestOptions(options: SentryRequestOptions): FetchOptions {
    const req: FetchOptions = {
      url: options.url,
      body: options.bodyAsJson,
      method: options.method || 'POST',
      credentials: true,
      timeout: options.timeout || 32000,
      textResponse: !!options.textRespone,
      headers: {
        Accept: 'application/json'
      }
    };
    if (options.bodyAsJson) {
      req.headers['Content-Type'] = 'application/json';
    }
    if (req.method === 'POST' && !req.body) {
      req.body = '';
    }

    // TODO: What todo if we want to send a token but we don't have one
    if (this.identityToken) {
      req.headers.Authorization = `Bearer ${this.identityToken}`;
    }

    return req;
  }
}

interface SentryRequestOptions {
  url: string;
  method?: HttpMethod;
  timeout?: number;
  sendToken?: boolean;
  textRespone?: boolean;
  bodyAsJson?: string;
}

export interface SyncPlan {
  'loans': SentryLoan[];
  'holds': SentryHold[];
  'cards': SentryCard[];
}


interface IdentityToken {
  aud: string;
  iss: string;
  chip: {
    /**
     * Chip UUID
     */
    id: string;
    /**
     * Primary Chip UUID
     */
    pri: string;
    /**
     * Default account group for Chip
     */
    ag: number;
    accounts: {
      /**
       * Account group
       */
      ag: number;
      /**
       * OPAS Account ID
       */
      id: string;
      /**
       * Account type (library or OD)
       */
      typ: 'library' | 'full';
      cards: {
        /**
         * OPAS card ID (not PUID!!)
         */
        id: string;
        /**
         * Card owner's name
         */
        name?: string;
        lib: {
          /**
           * Website ID
           */
          id: string;
          /**
           * Library key
           */
          key: string;
        };
      }[];
    }[];
  };
}

// TODO: This isn't really the sentry response sometimes
export interface SentryPossession extends ThunderMediaResponse {
  cardId: string;
  // This is the deprecated expire date returned by Thunder
  // which we might sometimes need to use if expireTime isn't there.
  expires: string; // ie: "10/6/06 2:24 PM"
  expireTime: number;
  createTime: number;
  isAssigned: boolean;
  overDriveFormat: IdNamePair & { hasAudioSynchronizedText: boolean };
  otherFormats: IdNamePair[];
  readiverseFormat: IdNamePair & { hasAudioSynchronizedText: boolean };
}

export interface SentryHold extends SentryPossession {
  autoCheckoutFlag: boolean;
  autoRenewFlag: boolean;
  suspensionFlag: boolean;
  email: string;
  holdListPosition: number;
  placedDate: string;
  suspensionEnd: string;
  patronHoldsRatio?: number;
}

export interface SentryLoan extends SentryPossession {
  expires: string;
  expireDate: string;
  checkoutDate: string;
  renewableOn: string;
  isReturnable: boolean;
  isFormatLockedIn: boolean;
  bundledContentTitleIds: string[];
}

export interface SentryCard {
  cardId: string;
  cardName: string;
  createDate: string;
  puid: number;
  library: {
    websiteId: number;
    name: string;
    logo: {
      url: string;
    };
  };
  advantageKey: string;
  limits: {
    hold: number;
    book: number;
    audiobook: number;
    loan: number;
  };
  counts: {
    loans: number;
    holds: number;
  };
  emailAddress: string;
  lendingPeriods: Dictionary<{
    options: [[number, string]];
    preference: [number, string];
  }>;
  contentMask: number;
  isVisitingCard: boolean;
  canPlaceHolds: boolean;
  canRecommendTitles: boolean;
  isSessionUser: boolean;
}

export interface SentrySyncResponse {
  cards: SentryCard[];
  holds: SentryHold[];
  loans: SentryLoan[];
  identity: string;
  result: 'synchronized' | 'authenticated';
  // The key for the summary is the card ID.  For each card returns info about
  // the success of the sync.
  summary: Dictionary<{ cards: SyncSummaryResult; loans: SyncSummaryResult; holds: SyncSummaryResult }>;
}

type SyncSummaryResult = 'skip' | 'done' | 'auth' | 'fail';

interface SentryChipResponse {
  chip: string;
  identity: string;
  syncable: boolean;
  primary: boolean;
}

export interface FulfillmentFormatType {
  id: string;
  thunderId: string;
  name: string;
}

export interface SentryFulfillmentResponse {
  fulfill: {
    href: string;
  };
}


export interface SentryUpdatePosessionResponse {
  oldBUID: string;
  oldBankScope: string;
  newBUID: string;
  newBankScope: string;
}

export interface SentrySearchAnchorResponse {
  result: {
    token: string;
  };
}

export interface SentryNtcResponse {
  code: string;
}

export interface SentryReadingData {
  timestamps: {
    generated: number;
    created: number;
    updated: number;
    accessed: number;
    stamped: number;
    expires: string;
  };
  position: {
    spinePosition: number;
    percentageOfComponent: number;
    percentageOfBook: number;
    chapterIndex: number | null;
    chapterTitle: string | null;
    citation: string | null;
    syncstamp: number | null;
    uuid: string;
    deviceId: string;
    timestamp: number;
    componentMilliseconds: number;
  };
  marks: {
    audiomarks: Annotation[];
  };
  statistics: {
    accesses: number;
    readingTime: number;
  };
  bankScope: string;
}
