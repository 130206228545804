import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseListPage = _resolveComponent("BaseListPage")!

  return (_openBlock(), _createBlock(_component_BaseListPage, {
    title: _ctx.title,
    subtitle: _ctx.description,
    titleState: _ctx.titleState,
    seriesState: _ctx.seriesState,
    currentSort: _ctx.currentSortOption,
    sortOptions: _ctx.sortOptions,
    allFilters: _ctx.allFilters,
    "onUpdate:page": _ctx.updatePage,
    "onUpdate:filters": _cache[0] || (_cache[0] = (val) => _ctx.updatePath({ filters: val, page: 1 })),
    "onUpdate:sort": _ctx.updateSort
  }, null, 8, ["title", "subtitle", "titleState", "seriesState", "currentSort", "sortOptions", "allFilters", "onUpdate:page", "onUpdate:sort"]))
}