<template>
  <section :class="$style.group">
    <header :class="$style.header">
      <div>
        <h2
          :class="{
            [$style.underline]: !description
          }"
        >
          <router-link
            :to="navigateLink"
            :class="$style.name"
          >
            {{ name }}
          </router-link>
        </h2>
        <p
          v-if="description"
          :class="[$style.description, $style.underline]"
        >
          {{ description }}
        </p>
      </div>
      <router-link
        :class="$style.button"
        :to="navigateLink"
      >
        {{ buttonLabel }}
      </router-link>
    </header>
    <slot></slot>
  </section>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BrowseGroup',
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: undefined
    },
    buttonLabel: {
      type: String,
      required: true
    },
    navigateLink: {
      type: Object,
      required: true
    }
  }
});
</script>

<style module>

.group {
  padding: 50px 0;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 50px;
  margin-bottom: 40px;
}

.underline {
  border-bottom: 2px solid var(--c-light-black);
  padding-bottom: 15px;
  width: fit-content;
}

.name {
  color: var(--c-dark-black);
  font-size: var(--fs-medium-head);
  font-weight: var(--fw-medium);
  width: fit-content;
  composes: focus-outline from global;
}

.description {
  margin-top: 5px;
  color: var(--c-light-black);
  font-size: var(--fs-large-body-head);
  width: fit-content;
}

.button {
  justify-self: end;
  height: fit-content;
  padding: 10px;
  color: var(--c-white);
  border-radius: var(--form-border-radius);
  background: var(--c-primary-red);
  font-weight: var(--fw-medium);
  text-transform: uppercase;
  composes: focus-outline from global;
}

@media screen and (max-width: 499px /* @px-vp-narrow */) {
  .header {
    grid-template-columns: 1fr;
    row-gap: 25px;
  }

  .button {
    justify-self: start;
  }
}
</style>
