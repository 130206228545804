<template>
  <div>
    <label
      :class="$style.switch"
    >
      <span
        class="visually-hidden"
      >
        {{ $t(ariaLabel) }}
      </span>

      <input
        v-model="checked"
        type="checkbox"
        class="visually-hidden"
      />

      <span :class="$style.slider"></span>
    </label>

    <span :class="$style.text">
      {{ $t(`switch.${ checked ? 'on' : 'off'}`) }}
    </span>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'FormSwitch',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    ariaLabel: {
      type: String,
      required: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const checked = computed({
      get: () => props.modelValue,
      set: (v) => ctx.emit('update:modelValue', v)
    });

    return {
      checked
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

@rem-slider-height: 1.5rem;
@rem-track-height: 1.25rem;

.switch {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: @rem-track-height;
  top: (@rem-slider-height - @rem-track-height) / 2;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 0.75rem;
  border: 1px solid var(--c-medium-black);
  transition: background-color 200ms ease;
}

.slider::before {
  position: absolute;
  content: ' ';
  height: @rem-slider-height;
  width: @rem-slider-height;
  left: -0.25rem;
  background-color: var(--c-black);
  border: 1px solid var(--c-medium-black);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 200ms ease;
}

.text {
  font-size: @fs-metadata;
  font-weight: @fw-bold;
  padding-left: 0.75rem;
  text-transform: uppercase;
  float: right;
  width: 1.5rem;
  line-height: @rem-slider-height;
}

.switch {
  input:checked + .slider {
    background-color: @c-light-green;
  }

  input:focus + .slider::before {
    .standard-outline;
    border-radius: 50%;
  }

  input:checked + .slider:before {
    transform: translateX(1.25rem) translateY(-50%);
  }
}
</style>
