import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { scope: "col" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { scope: "col" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { scope: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('title.publisher')), 1),
      _createVNode(_component_router_link, {
        to: _ctx.publisherLink(_ctx.title.publisher.id),
        class: _normalizeClass(_ctx.$style.publisherLink)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title.publisher.name), 1)
        ], undefined, true),
        _: 1
      }, 8, ["to", "class"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.title.formatInformation, (format) => {
      return (_openBlock(), _createElementBlock("div", {
        key: format.name
      }, [
        _createElementVNode("h3", null, _toDisplayString(format.name), 1),
        _createElementVNode("table", {
          class: _normalizeClass(_ctx.$style.formatInfo)
        }, [
          (_ctx.title.duration)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
                _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.$t('title.duration')), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.durationString), 1)
              ]))
            : _createCommentVNode("", true),
          (format.isbn)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
                _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('title.isbn')), 1),
                _createElementVNode("td", null, _toDisplayString(format.isbn), 1)
              ]))
            : _createCommentVNode("", true),
          (format.fileSize)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('title.fileSize')), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.$t('title.fileSizeInKB', { n: Math.ceil(format.fileSize / 1000) })), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    }), 128))
  ], 2))
}