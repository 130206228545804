const BuildEnvironmentMap = {
  VERSION_NUMBER: {
    tag: 'x-elrond-version',
    required: true
  },
  ELROND_ENV: {
    tag: 'x-elrond-env',
    required: true
  },
  CHANNEL: {
    tag: 'x-elrond-channel',
    required: true
  },
  ROOT_URI: {
    tag: 'x-roster-root',
    required: true
  },
  SENTRY_URI: {
    tag: 'x-sentry-uri',
    required: true
  },
  THUNDER_URI: {
    tag: 'x-thunder-uri',
    required: true
  },
  GEO_IP_URI: {
    tag: 'x-geo-ip-uri',
    required: true
  },
  NTC_API_URI: {
    tag: 'x-ntc-api-uri',
    required: true
  },
  NTC_SITE_URI: {
    tag: 'x-ntc-site-uri',
    required: true
  },
  NTC_TARGET_CLIENT: {
    tag: 'x-ntc-target-client',
    required: true
  },
  POSTISH_URI: {
    tag: 'x-postish-uri',
    required: true
  },
  TAGGISH_URI: {
    tag: 'x-taggish-uri',
    required: true
  },
  AUTOCOMPLETE_URI: {
    tag: 'x-autocomplete-uri',
    required: false
  },
  AUTOCOMPLETE_KEY: {
    tag: 'x-autocomplete-key',
    required: false
  },
  BUILD_SHA: {
    tag: 'x-elrond-sha',
    required: true
  },
  BUILD_TIMESTAMP: {
    tag: 'x-elrond-timestamp',
    required: true
  },
  SAGE_URI: {
    tag: 'x-sage-uri',
    required: false
  },
  SAMWISE_URI: {
    tag: 'x-samwise-uri',
    required: true
  },
  FIREBASE_API_KEY: {
    tag: 'x-firebase-api-key',
    required: false
  },
  FIREBASE_AUTH_DOMAIN: {
    tag: 'x-firebase-auth-domain',
    required: false
  },
  FIREBASE_DATABASE_URL: {
    tag: 'x-firebase-db-url',
    required: false
  },
  FIREBASE_PROJECT_ID: {
    tag: 'x-firebase-project-id',
    required: false
  },
  FIREBASE_STORAGE_BUCKET: {
    tag: 'x-firebase-storage-bucket',
    required: false
  },
  FIREBASE_MESSAGING_SENDER_ID: {
    tag: 'x-firebase-messaging-sender-id',
    required: false
  },
  FIREBASE_APP_ID: {
    tag: 'x-firebase-app-id',
    required: false
  },
  FIREBASE_MEASUREMENT_ID: {
    tag: 'x-firebase-measurement-id',
    required: false
  }
} as const;

function buildEnvironment(): BuildEnvironment {
  return Object.entries(BuildEnvironmentMap)
    .reduce(
      (env, [key, def]) => {
        env[key] = metaContentValue(def);

        return env;
      },
      {} as any
    );
}


export default buildEnvironment();


type BuildEnvironmentVariable = keyof typeof BuildEnvironmentMap;

type BuildEnvironmentValue<K extends BuildEnvironmentVariable> =
  (typeof BuildEnvironmentMap)[K]['required'] extends true
    ? string
    : string | null;

export type BuildEnvironment = {
  [k in BuildEnvironmentVariable]: BuildEnvironmentValue<k>;
};

/**
 * Retrieve the value of a meta content tag
 * @throws An Error if the value is not present.
 */
function metaContentValue<K extends BuildEnvironmentVariable>(
  definition: (typeof BuildEnvironmentMap)[K]
): BuildEnvironmentValue<K> {
  const metaContentName = definition.tag;
  const meta = document.querySelector(`meta[name="${metaContentName}"]`);
  if (meta) {
    return meta.getAttribute('content') as BuildEnvironmentValue<K>;
  }

  if (definition.required) {
    console.warn('[ENV] meta constant not found:', metaContentName);
    throw new Error(`Missing required meta constant:  ${metaContentName}`);

  }

  return null as BuildEnvironmentValue<K>;
}
