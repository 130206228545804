import { C } from 'app/base/common';
import { FreshableItem } from 'app/base/services/freshable';
import { ThunderSearchResponse } from 'app/base/thunder';
import { List } from './list';
import { SearchOptions } from './list-parameters';
import { Series } from './series';
import { TitleRecord } from './title';


/**
 * Represents a page of a list.
 * `T` is the list item type.
 * `R` is the server response type used to build the page.
 */
export abstract class BaseListPage<T extends TitleRecord | Series, R> extends FreshableItem<ThunderSearchResponse<R>> {
  public pageNumber: number;
  public firstPage: boolean;
  public lastPage: boolean;
  public readonly list: List<T, R>;
  public items: T[];
  protected _all: T[];
  protected _slice: number[];
  protected _perPage: number;
  protected _currentQueryUrl: string;
  protected _lastQueryUrl: string;


  constructor(list: List<T, R>, pageNum: number) {
    super('list-page');

    this.list = list;
    this.items = [];
    this._all = [];
    this.pageNumber = pageNum;
    this.firstPage = this.pageNumber === 1;
    this.lastPage = false;
    this.slice();
  }


  protected async freshenCall(options: SearchOptions): Promise<ThunderSearchResponse<R>> {
    await this.list.params.freshen();

    if (this.list.params.definition && this.list.params.definition.invalid) {
      console.warn(
        '[LIST] invalid params: %s - %s',
        this.list.params.definition.invalid,
        this.list.params.address()
      );

      return null;
    }

    this._currentQueryUrl = this._apiQueryURL(options);

    return this.requestListPage(this._currentQueryUrl);
  }


  protected abstract requestListPage(queryUrl: string): Promise<ThunderSearchResponse<R>>;


  protected freshenMap(response: ThunderSearchResponse<R>): void {
    this.parseFreshenResponse(response);
    this._lastQueryUrl = this._currentQueryUrl;
    this._sliceItems();
  }


  protected parseFreshenResponse(response: ThunderSearchResponse<R>): void {
    this.list.mergeSearchAttributes(response);
    this._all = [];
    C.each(response.items, (itemAttrs) => {
      const item = this.mapItem(itemAttrs);
      this._all.push(item);
    });
    if (!response.links || !response.links.next) {
      this.lastPage = true;
    }
  }


  protected abstract mapItem(item: R): T;


  public arePropertiesFresh() {
    try {
      return (
        this.list.params.freshness.isFresh() &&
        this._lastQueryUrl === this._apiQueryURL()
      );
    } catch (e) {
        return false;
    }
  }


  public totalItems(): number {
    return this.list ? this.list.totalTitles : 0;
  }


  // You can use slice() to configure the page to fetch a sub-range of
  // titles. For example, if your subject mini-block only needs the first 4
  // titles, it's slow and wasteful to load the entire 96 titles.
  //
  //   slice() - no arguments = 0 - 96
  //   slice(12) - one argument = 0 - 12
  //   slice(20, 30) - two arguments = 20 - 30
  //
  // In the third case, we'll actually load the first 30 results and then
  // exclude the first 20 from the titles array. Saves mucking about with
  // offsets.
  //
  // If the page is fresh, and has already loaded the entire sub-range,
  // the titles are available immediately. If not fresh or the sub-range is
  // outside the already-loaded range, you'll need to freshen() to get it.
  //
  public slice(a?: number, b?: number): this {
    if (typeof a === 'number' && typeof b === 'number') {
      this._slice = [a, b];
    } else if (typeof a === 'number') {
      this._slice = [0, a];
    } else {
      this._slice = [0, 48];
    }
    if (this._slice[1] > this._perPage || !this.arePropertiesFresh()) {
      this._perPage = this._slice[1];
    }
    this._sliceItems();

    return this;
  }


  protected _apiQueryURL(options: SearchOptions = {}) {
    const endpoint = this.list.params.apiEndpoint(this.list.itemType, options);
    const urlOptions = {
      page: this.pageNumber,
      perPage: typeof this._perPage === 'number' ? this._perPage : 48,
      includeFacets:  typeof options.includeFacets !== 'undefined' ? options.includeFacets : true
    };

    return C.parameterizeURL(endpoint, urlOptions);
  }


  protected _sliceItems() {
    this.items = this._all.slice(this._slice[0], this._slice[1]);
  }
}
