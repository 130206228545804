import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { LibraryCard } from 'app/models/library-card';
import { Collation } from './collation';

export type Device = 'here' | 'kindle' | 'ade' | 'pdf' | 'video';

export class LibraryCards extends Collation<LibraryCard> {
  private _activationOrder: number[];
  public listeningDevice: Device;
  public sendingDevice: Device;
  public readingDevice: Device;
  public daysToSuspendHolds: number;
  public autoBorrowHolds: boolean;
  protected ITEM_CLASS = LibraryCard;
  protected ITEM_NAME = 'card';
  protected SAVE_DELAY = 0;
  protected SERIAL_VERSION = 2;
  protected SERIAL_VERSION_MIN = 2;

  public emailAddress: string;


  public activateCard(card: LibraryCard) {
    const k = () => {
      return APP.library ? APP.library.key() : null;
    };
    const keyBefore = k();
    C.excise(this.all, card);
    this.all.unshift(card);
    this.save();
    card.library.activate();
    if (!keyBefore || keyBefore !== k()) {
      APP.refresh();
    }
  }


  public save() {
    this._writeActivationOrder();
    if (!this.emailAddress) {
      this._scanCardsForEmailAddress();
    }
    super.save();
  }


  protected _serializeAttributes() {
    const out = super._serializeAttributes();
    out.emailAddress = this.emailAddress;
    out.autoBorrowHolds = this.autoBorrowHolds;
    out.daysToSuspendHolds = this.daysToSuspendHolds;
    out.readingDevice = this.readingDevice;
    out.sendingDevice = this.sendingDevice;
    out.listeningDevice = this.listeningDevice;
    out.activationOrder = this._activationOrder;

    return out;
  }


  protected _deserializeAttributes(attrs) {
    super._deserializeAttributes(attrs);
    this.emailAddress = attrs.emailAddress || this.emailAddress;
    this.autoBorrowHolds = attrs.autoBorrowHolds;
    // autoBorrowHolds is true by default --
    if (this.autoBorrowHolds !== false) {
      this.autoBorrowHolds = true;
    }
    // daysToSuspendHolds is 0 by default --
    this.daysToSuspendHolds = attrs.daysToSuspendHolds || 0;
    // readingDevice and listeningDevice are "here" by default --
    this.readingDevice = attrs.readingDevice || 'here';
    this.listeningDevice = attrs.listeningDevice || 'here';
    // sendingDevice is "kindle" by default until more devices are added
    this.sendingDevice = attrs.sendingDevice || 'kindle';
    this._activationOrder = attrs.activationOrder || this._activationOrder;
  }


  protected _deserializeItems(attrs) {
    super._deserializeItems(attrs);
    this._readActivationOrder(this._activationOrder);
  }


  protected _scanCardsForEmailAddress() {
    this.emailAddress = null;
    for (let i = 0, ii = this.all.length; i < ii; ++i) {
      const card = this.all[i];
      if (!card.emailAddress) {
        continue;
      }
      const isActiveCard = card.library.isEquivalent(APP.library);
      if (isActiveCard || !this.emailAddress) {
        this.emailAddress = card.emailAddress;
      }
    }
  }


  protected _writeActivationOrder() {
    const order: number[] = [];
    C.each(this.all, (card) => {
      order.push(card.cardId);
    });
    this._activationOrder = order;
  }


  protected _readActivationOrder(order: number[]) {
    this._activationOrder = order;
    const cards = [];
    C.each(
      order,
      (cardId) => {
        const card = this.find({ cardId: cardId });
        cards.push(card);
        C.excise(this.all, card);
      }
    );
    this.all.unshift.apply(this.all, cards);
  }


  protected _fullAttributesToQuery(itemAttributes) {
    return { cardId: parseInt(itemAttributes.cardId, 10) };
  }
}
