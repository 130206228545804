
  // Walk the DOM tree, depth-first, start to end.
  // You can start at the given node (use document.documentElement
  // for the entire DOM) -- it will keep traversing until it gets
  // to the very end of the DOM tree.
  //
  // You can halt the traversal by throwing 'halt' -- this throw
  // will be caught and the function will stop recursing immediately.
  //
  export function walk(node: Node | null, fn: (node: Node) => void): void {
    if (!node) { return; }
    try {
      fn(node);
    } catch (e) {
      if (e instanceof Error && e.message === 'halt') {
        return;
      }

      throw(e);
    }
    walk(node.firstChild || _nextInWalk(node), fn);
  }


  // A protected method used to find the next node when walking the DOM tree.
  //
  export function _nextInWalk(node: Node | null): Node | null {
    return node ? (node.nextSibling || _nextInWalk(node.parentNode)) : null;
  }


  // We do this for two reasons. First, Chrome has a serious bug:
  //
  //   https://code.google.com/p/chromium/issues/detail?id=324437
  //
  // And second, the behavior for block elements that contain nodes is
  // undefined. In some browsers you get the block rectangle only (Gecko),
  // in some browsers you get the block plus all the inline rects
  // (Chrome?), and in some you get only the inline rects (IE).
  //
  // This function just gives you all the inline rects.
  //
  export function getRangeRects(range: { startContainer: Node, endContainer: Node, startOffset: number, endOffset: number }) {
    const rects: (ClientRect | (DOMRect | null))[]  = [];
    let offset = range.startOffset;
    const tooFar = _nextInWalk(range.endContainer);
    walk(range.startContainer, (node) => {
      if (node === tooFar) { throw(new Error('halt')); }
      if (!node.childNodes.length && node.ownerDocument) {
        const r = node.ownerDocument.createRange();
        r.selectNodeContents(node);
        if (offset) {
          r.setStart(node, offset);
          offset = 0;
        }
        // If the range is collapsed after selecting contents, it may
        // be an <img> or a <br> or another "void element". We extend
        // the range to the start of the next sibling node in order to
        // select the void element.
        if (r.collapsed) {
          const sib = _nextInWalk(r.endContainer);
          if (sib) {
            r.setEnd(sib, 0);
          }
        }
        if (node === range.endContainer) {
          r.setEnd(node, range.endOffset);
        }
        const clientRects = r.getClientRects();
        for (let i = 0; i < clientRects.length; i++) {
          rects.push(clientRects.item(i));
        }
      }
    });

    return rects;
  }
