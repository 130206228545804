import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  class: "visually-hidden",
  "aria-live": "polite"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressCircle = _resolveComponent("ProgressCircle")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DownloadIndicatorContent = _resolveComponent("DownloadIndicatorContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      ref: "indicatorButton",
      class: _normalizeClass(_ctx.$style.indicatorButton),
      "aria-label": _ctx.downloadInfo.buttonLabel || _ctx.downloadInfo.headerLabel,
      "aria-haspopup": "dialog",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopout = true))
    }, [
      (_ctx.downloadInfo.status === 'downloading')
        ? (_openBlock(), _createBlock(_component_ProgressCircle, {
            key: 0,
            value: _ctx.progress
          }, null, 8, ["value"]))
        : (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            name: _ctx.downloadInfo.iconLabel,
            class: _normalizeClass([_ctx.$style.icon, 'icon-complete'])
          }, null, 8, ["name", "class"])),
      (!_ctx.iconOnly)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.downloadInfo.buttonLabel || _ctx.downloadInfo.headerLabel), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.downloadAnnouncement), 1)
    ], 10, _hoisted_1),
    (_ctx.showPopout && _ctx.indicatorButton)
      ? (_openBlock(), _createBlock(_component_DownloadIndicatorContent, {
          key: 0,
          reference: _ctx.indicatorButton,
          headerLabel: _ctx.downloadInfo.headerLabel,
          menuActionLabel: _ctx.downloadInfo.action.label,
          menuAnnouncement: _ctx.menuAnnouncement,
          confirmationMessage: _ctx.downloadInfo.confirmation.message,
          confirmationAnnouncement: _ctx.downloadInfo.confirmation.announcement,
          confirmationRequired: _ctx.downloadInfo.confirmation.required,
          actionEvent: _ctx.downloadInfo.action.event,
          "onDownload:start": _ctx.startDownload,
          "onDownload:stop": _ctx.stopDownload,
          "onDownload:settings": _ctx.openSettings,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPopout = false))
        }, null, 8, ["reference", "headerLabel", "menuActionLabel", "menuAnnouncement", "confirmationMessage", "confirmationAnnouncement", "confirmationRequired", "actionEvent", "onDownload:start", "onDownload:stop", "onDownload:settings"]))
      : _createCommentVNode("", true)
  ], 64))
}