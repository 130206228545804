import { C } from 'app/base/common';
import env from 'app/base/env';
import { Service } from 'app/base/services/service';

export class GeoIp extends Service {
  constructor() {
    super('GEO_IP', (path: string) => {
      return { url: `${env.GEO_IP_URI}/v1/${path}`};
    });
  }


  public getGdprStatus() {
    const route = 'country';

    return this.fetchAsync<GdprStatus>(route);
  }


  public getGdprStatusByIp(ip: string) {
    const route = 'country/for';

    return this.fetchAsync<GdprStatus>(C.parameterizeURL(route, { ip }));
  }
}

type GdprStatus = {
  countryCode: string;
  isGdpr: boolean;
};
