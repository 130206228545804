import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-labelledby"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#toasts" }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.$style.shield),
      "aria-label": _ctx.$t('general.close'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, null, 10, _hoisted_1),
    _createVNode(_component_FocusTrap, {
      escapeDeactivates: true,
      onDeactivate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.toast, _ctx.$style[_ctx.type], _ctx.animation ? _ctx.$style[_ctx.animation] : '']),
          role: "alertdialog",
          "aria-modal": "true",
          "aria-labelledby": _ctx.messageId
        }, [
          _createVNode(_component_Icon, { name: _ctx.iconName }, null, 8, ["name"]),
          _createElementVNode("p", {
            id: _ctx.messageId,
            class: _normalizeClass(_ctx.$style.message)
          }, _toDisplayString(_ctx.message), 11, _hoisted_3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.actions)
          }, [
            _renderSlot(_ctx.$slots, "action"),
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.$style.dismiss),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
            }, _toDisplayString(_ctx.$t('general.dismiss')), 3)
          ], 2)
        ], 10, _hoisted_2)
      ], undefined, true),
      _: 3
    })
  ]))
}