import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "notch" }, null, -1)),
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.$style.header)
    }, " Debug console ", 2),
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.command) = $event)),
        type: "text",
        required: "",
        class: _normalizeClass(_ctx.$style.input),
        autocomplete: "off",
        autocapitalize: "off",
        placeholder: "`list` to show all commands",
        spellcheck: "false"
      }, null, 2), [
        [
          _vModelText,
          _ctx.command,
          void 0,
          { trim: true }
        ]
      ])
    ], 32),
    _createElementVNode("code", {
      class: _normalizeClass([_ctx.$style.output, _ctx.copied ? _ctx.$style.copied : ''])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.outputHeader)
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Result", -1)),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.$style.copy),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
        }, [
          _createVNode(_component_Icon, { name: "copy-to-clipboard" }),
          _createTextVNode(" " + _toDisplayString(_ctx.copied ? 'Copied!' : 'Copy'), 1)
        ], 2)
      ], 2),
      _createElementVNode("pre", null, _toDisplayString(_ctx.output), 1)
    ], 2)
  ], 2))
}