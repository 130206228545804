import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "panel",
    style: _normalizeStyle(`--duration: ${_ctx.duration + 'ms'}`),
    class: _normalizeClass(_ctx.$style.panelWrapper),
    role: "none"
  }, [
    _createVNode(_Transition, {
      name: "fade",
      onEnter: _ctx.changeHeight,
      onLeave: _ctx.clearHeight
    }, {
      default: _withCtx(() => [
        (_ctx.expanded)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: _ctx.panelId,
              class: _normalizeClass(_ctx.$style.panel),
              role: "none"
            }, [
              _renderSlot(_ctx.$slots, "default", { update: _ctx.update })
            ], 10, _hoisted_1))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 3
    }, 8, ["onEnter", "onLeave"])
  ], 6))
}