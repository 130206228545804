import { Base64 } from './base64';
/**
 * Parse a JWT and extract the payload into an object
 * Note: No validation is done on token to verify
 *    the signature or the dates
 */
export function parseJWT<T>(jwt: string): T | undefined {
  const parts = jwt.split('.');
  if (parts.length !== 3) {
    return undefined;
  }
  // The encoded data in JWTs is not expected to be padded
  // per the spec, so pass flag to decode that says it is
  // safe to pad the input with =s if needed
  const jsonData = Base64.decode(parts[1], true);

  return JSON.parse(jsonData);
}
