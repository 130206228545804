<template>
  <section
    v-if="titlesWithAnnotations.length"
    :class="$style.container"
  >
    <h1 :class="$style.header">
      {{ $t('home.annotations.header') }}
    </h1>
    <ol :class="$style.list">
      <li
        v-for="title in titlesWithAnnotations"
        :key="title.slug"
        :class="$style.title"
      >
        <router-link
          :to="{ name: 'NoteDetails', params: { titleSlug: title.slug } }"
          :class="$style.link"
        >
          <HomeAnnotationCard :title="title" />
        </router-link>
      </li>
    </ol>
    <router-link
      :to="{ name: 'Notes' }"
      :class="$style.viewAll"
    >
      {{ $t('home.annotations.viewAll') }}
    </router-link>
  </section>
  <EmptyState
    v-else
    :class="$style.emptyState"
    :link="helpLink"
    :isCompact="true"
    :header="$t('home.annotations.emptystate.header')"
    :content="$t('home.annotations.emptystate.content')"
    :linkLabel="$t('home.annotations.emptystate.link')"
  />
</template>

<script lang='ts'>
import { defineComponent, ref, onBeforeUnmount } from 'vue';
import EmptyState from 'app/components/EmptyState.vue';
import HomeAnnotationCard from 'app/components/HomeAnnotationCard.vue';
import { APP } from 'app/base/app';
import { TitleRecord } from 'app/models/title';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'HomeAnnotationList',
  components: {
    EmptyState,
    HomeAnnotationCard
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.ANNOTATIONS;
    const titlesWithAnnotations = ref<TitleRecord[]>([]);

    const fillOut = async () => {
      titlesWithAnnotations.value = (await APP.patron.annotations
        .getRecentByTitle(6))
        .filter((t) => t.mediaType !== 'audiobook');
    };
    const handler = APP.events.on('annotation:update:all', fillOut);
    fillOut();

    onBeforeUnmount(() => {
      handler.deafen();
    });

    return {
      helpLink,
      titlesWithAnnotations
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.header {
  font-weight: @fw-bold;
  font-size: @fs-large-head;
  font-family: @ff-head;

  margin-bottom: 2rem;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  border-bottom: 1px solid @c-darkest-gray;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;

  @media screen and (max-width: @px-vp-max) {
    grid-template-columns: auto;
  }
}

.title {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid @c-dark-gray;

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }

  @media screen and (min-width: @px-vp-max) {
    &:nth-last-child(2):nth-child(odd) {
      border: 0;
      padding-bottom: 0;
    }
  }
}

.link {
  display: block;
  position: relative;

  &:focus {
    outline: none;

    &::after {
      content: '';
      .standard-outline;
      position: absolute;
      width: calc(100% + 0.2rem);
      height: calc(100% + 0.2rem);
      top: -0.1rem;
      left: -0.1rem;
      pointer-events: none;
    }
  }
}

.view-all {
  display: inline-block;
  color: @c-primary-blue;
  .pop-strict(@c-primary-blue-light);
  .focus-outline;
}

.empty-state {
  text-align: left;
}
</style>
