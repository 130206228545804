import { buildCopyJobContext } from 'app/contexts/copy-job-context';
import { CopyJobContextSymbol } from 'app/keys/injection-keys';
import { computed, inject, isRef, readonly, Ref, ref, watch } from 'vue';


export function useCopyJob(jobIdRaw: Ref<string | null> | string) {
  const jobId = isRef(jobIdRaw) ? jobIdRaw : ref(jobIdRaw);

  const {
    copyJobMap,
    getCopyJob
  } = inject(CopyJobContextSymbol, buildCopyJobContext());

  const copyJob = computed(() => {
    if (!jobId.value) { return null; }

    return copyJobMap.value[jobId.value];
  });
  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const update = async () => {
    if (!jobId.value) { return; }

    try {
      loading.value = true;

      await getCopyJob(jobId.value);

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  watch(jobId, update, { immediate: true });

  return {
    copyJob: readonly(copyJob),
    loading: readonly(loading),
    error: readonly(error),
    update
  };

}
