import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FormError = _resolveComponent("FormError")!
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_LoginFormLocal = _resolveComponent("LoginFormLocal")!
  const _component_LoginFormExternal = _resolveComponent("LoginFormExternal")!
  const _component_EmailRegistration = _resolveComponent("EmailRegistration")!
  const _component_FormCaptcha = _resolveComponent("FormCaptcha")!

  return (_ctx.submitting)
    ? (_openBlock(), _createElementBlock("p", {
        key: 0,
        class: _normalizeClass(_ctx.$style.loading)
      }, [
        _createVNode(_component_Icon, {
          name: "spinner",
          "aria-live": "assertive",
          "aria-label": _ctx.$t('login.form.submitting')
        }, null, 8, ["aria-label"])
      ], 2))
    : (_ctx.auth.state === 'success')
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(_ctx.$style.loading)
        }, [
          _createVNode(_component_Icon, {
            name: "spinner",
            "aria-live": "assertive",
            "aria-label": _ctx.$t('login.form.complete')
          }, null, 8, ["aria-label"])
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("form", {
            class: _normalizeClass(_ctx.$style.form),
            onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.submit(_ctx.formData)), ["prevent"]))
          }, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createBlock(_component_FormError, {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.error),
                  contents: _ctx.errorMessage
                }, null, 8, ["class", "contents"]))
              : _createCommentVNode("", true),
            (_ctx.dropdownForms.length > 1 && !_ctx.emailSelected)
              ? (_openBlock(), _createBlock(_component_FormSelect, {
                  key: 1,
                  modelValue: _ctx.selected,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
                  options: _ctx.dropdownForms,
                  optionLabel: "displayName",
                  optionKey: (f) => f.ilsName + f.displayName,
                  prompt: _ctx.$t('login.form.prompt')
                }, null, 8, ["modelValue", "options", "optionKey", "prompt"]))
              : _createCommentVNode("", true),
            (_ctx.selected)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (_ctx.selected.type === 'Local' && _ctx.selected.local)
                    ? (_openBlock(), _createBlock(_component_LoginFormLocal, {
                        key: _ctx.selected.sortName,
                        usernameConfig: _ctx.selected.local.username,
                        passwordConfig: _ctx.selected.local.password,
                        loginHintUrl: _ctx.selected.loginHintUrl,
                        username: _ctx.username,
                        password: _ctx.password,
                        onUsername: _cache[1] || (_cache[1] = v => _ctx.username = v),
                        onPassword: _cache[2] || (_cache[2] = v => _ctx.password = v)
                      }, null, 8, ["usernameConfig", "passwordConfig", "loginHintUrl", "username", "password"]))
                    : (_ctx.selected.type === 'External')
                      ? (_openBlock(), _createBlock(_component_LoginFormExternal, { key: 1 }))
                      : (_ctx.emailSelected)
                        ? (_openBlock(), _createBlock(_component_EmailRegistration, {
                            key: 2,
                            showCode: _ctx.auth.state === 'code',
                            username: _ctx.username,
                            password: _ctx.password,
                            onUsername: _cache[3] || (_cache[3] = v => _ctx.username = v),
                            onPassword: _cache[4] || (_cache[4] = v => _ctx.password = v)
                          }, null, 8, ["showCode", "username", "password"]))
                        : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.captchaRequired)
              ? (_openBlock(), _createBlock(_component_FormCaptcha, {
                  key: 3,
                  siteKey: _ctx.page.captcha.key
                }, null, 8, ["siteKey"]))
              : _createCommentVNode("", true)
          ], 34),
          (_ctx.emailSelected)
            ? (_openBlock(), _createElementBlock("section", {
                key: 0,
                class: _normalizeClass(_ctx.$style.additional)
              }, [
                _createElementVNode("button", {
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.clearSelection && _ctx.clearSelection(...args)))
                }, _toDisplayString(_ctx.$t('login.email.cancel')), 1)
              ], 2))
            : (_ctx.guestForm || _ctx.emailForm)
              ? (_openBlock(), _createElementBlock("section", {
                  key: 1,
                  class: _normalizeClass(_ctx.$style.additional)
                }, [
                  (_ctx.guestForm)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "submit",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.submit(_ctx.guestForm)))
                      }, _toDisplayString(_ctx.$t('login.form.signInAsGuest')), 1))
                    : _createCommentVNode("", true),
                  (_ctx.emailForm)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        onClick: _cache[8] || (_cache[8] = () => _ctx.selected = _ctx.emailForm)
                      }, _toDisplayString(_ctx.$t('login.form.signInWithEmail')), 1))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true)
        ]))
}