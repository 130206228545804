import { APP } from 'app/base/app';
import { Loan } from 'app/models/loan';

export class Borrower {
  /**
   * Attempt to borrow a title
   * @param titleSlug The slug of the title to borrow
   * @param parentSlug The default parentID to use, if one could not be found
   * @returns The new loan or undefined if any issues occurred
   */
  public static async borrowIfPossible(titleSlug: string, parentSlug?: string): Promise<Loan | undefined> {
    const [ title ] = await APP.titleCache.getFreshTitles([titleSlug]);
    let slugToBorrow = titleSlug;

    const hasParent = title.isBundledChild || title.lexisMetadata?.parent || parentSlug;

    if (hasParent) {
      slugToBorrow = title.lexisMetadata.parent || parentSlug;

      title.lexisMetadata.parent = slugToBorrow;

      if (!slugToBorrow) {
        console.error(`[BORROWER] Tried to borrow child ${titleSlug} but missing parent slug.`);

        return undefined;
      }
    }

    const loan = APP.patron.loans.find({ titleSlug: slugToBorrow });
    if (loan) {
      return loan;
    }

    if (!hasParent && !title.isSimultaneousUse) {
      console.error('[BORROWER] Unable to find loan for title slug (non-SU, no-parent)', slugToBorrow);

      return undefined;
    }

    try {
      return APP.patron.borrowTitle(slugToBorrow);
    } catch (e) {
      console.error('[BORROWER] Could not borrow title', e);

      return undefined;
    }
  }
}

