import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled", "onClick"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecentlyReadTitleCard = _resolveComponent("RecentlyReadTitleCard")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('home.recentlyRead.header')), 1),
    (!_ctx.recentlyReadTitles || _ctx.recentlyReadTitles.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", {
            class: _normalizeClass(_ctx.$style.carousel)
          }, [
            _createElementVNode("ol", {
              ref: "carouselList",
              class: _normalizeClass(_ctx.$style.carouselList)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.recentlyReadTitles || []), (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.slug
                }, [
                  _createVNode(_component_RecentlyReadTitleCard, {
                    title: item,
                    onVfocus: ($event: any) => (_ctx.onSlideFocus(index))
                  }, null, 8, ["title", "onVfocus"])
                ]))
              }), 128))
            ], 2),
            _createElementVNode("div", {
              ref: "curtain",
              class: _normalizeClass(_ctx.$style.curtain)
            }, null, 2)
          ], 2),
          _createElementVNode("section", {
            class: _normalizeClass(_ctx.$style.carouselActions),
            "aria-hidden": "true"
          }, [
            _createElementVNode("button", {
              tabindex: "-1",
              disabled: _ctx.currentPage <= 1,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousPage && _ctx.previousPage(...args)))
            }, [
              _createVNode(_component_Icon, { name: "chevron-left" })
            ], 8, _hoisted_1),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.breadcrumbs)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberPages, (index) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: index,
                  class: _normalizeClass([_ctx.$style.dot, index === _ctx.currentPage ? _ctx.$style.currentPage : '']),
                  tabindex: "-1",
                  disabled: index == _ctx.currentPage,
                  onClick: ($event: any) => (_ctx.goToPage(index))
                }, null, 10, _hoisted_2))
              }), 128))
            ], 2),
            _createElementVNode("button", {
              tabindex: "-1",
              disabled: _ctx.currentPage >= _ctx.numberPages,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
            }, [
              _createVNode(_component_Icon, { name: "chevron-right" })
            ], 8, _hoisted_3)
          ], 2)
        ], 64))
      : (_openBlock(), _createBlock(_component_EmptyState, {
          key: 1,
          class: _normalizeClass(_ctx.$style.emptyState),
          link: _ctx.helpLink,
          isCompact: true,
          isDarkMode: true,
          header: _ctx.$t('home.recentlyRead.emptystate.header'),
          content: _ctx.$t('home.recentlyRead.emptystate.content'),
          linkLabel: _ctx.$t('home.recentlyRead.emptystate.link')
        }, null, 8, ["class", "link", "header", "content", "linkLabel"])),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.recentlyReadActions)
    }, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(_ctx.$style.viewAll),
        to: "mybooks#history"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('home.recentlyRead.viewAll')), 1)
        ], undefined, true),
        _: 1
      }, 8, ["class"])
    ], 2)
  ], 2))
}