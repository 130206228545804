import { APP } from 'app/base/app';

export class Haptics {
  private _supported?: boolean;


  public prepare(): void {
    this._hapticMessage('prepare');
  }


  public select(): void {
    this._hapticMessage('select');
  }


  /**
   *
   * @param weight 'light' (default), 'medium', 'heavy'
   */
  public impact(weight: 'light' | 'medium' | 'heavy' = 'light'): void {
    this._hapticMessage('impact:' + weight);
  }


  /**
   *
   * @param notificationType 'success' (default), 'warning', 'error'
   */
  public notify(notificationType: 'success' | 'warning' | 'error' = 'success'): void {
    this._hapticMessage('notify:' + notificationType);
  }


  protected _hapticMessage(hapticEventType: string): void {
    if (this._isSupported()) {
      APP.shell.transmit({
        name: 'haptic:' + hapticEventType,
        dest: 'shell'
      });
    }
  }


  protected _isSupported(): boolean {
    if (typeof this._supported === 'undefined') {
      this._supported = APP.shell.has('ui:haptics');
    }

    return this._supported;
  }
}
