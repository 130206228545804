import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.headerTag), {
          key: 0,
          class: _normalizeClass(_ctx.$style.header)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ], undefined, true),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    _createElementVNode("ol", {
      class: _normalizeClass(_ctx.$style.list)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjects, (subject) => {
        return (_openBlock(), _createElementBlock("li", {
          key: subject.id,
          class: _normalizeClass(_ctx.$style.subject)
        }, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(_ctx.$style.link),
            to: subject.route
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.name)
              }, _toDisplayString(subject.name), 3),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.count)
              }, _toDisplayString(subject.count), 3)
            ], undefined, true),
            _: 2
          }, 1032, ["class", "to"])
        ], 2))
      }), 128))
    ], 2)
  ], 2))
}