import { C } from 'app/base/common';
import { APP } from '../base/app';
import { Item } from './item';
import { TitleRecord } from './title';


/**
 * `ItemWithTitleCache` represents a generic `Item` that
 *  requires a title record. As Elrond tracks all
 * "important" titles through the title cache,
 * this class handles the wiring of storing and loading
 * the associated title in the title cache.
 *
 * `T` is the type of the remote response that
 * deserializes into the Item, and `B` is the
 * type of the banked item.
 */
export abstract class ItemWithTitleCache<T extends object, B extends object> extends Item {
  public titleSlug: string;

  public get titleRecord(): TitleRecord {
    return APP.titleCache.get(this.titleSlug);
  }


  protected _deserializeAttributes(attrs: T | (B & BankedItemWithTitle)): void {
    if (this.isBankedAttributes(attrs)) {
      this.mapFromBank(attrs);
    } else {
      this.map(attrs);
    }
  }


  protected _serializeAttributes(): B & BankedItemWithTitle {
    const out: BankedItemWithTitle = { titleSlug: this.titleSlug };

    return C.absorb(out, this.serializeInternal());
  }


  protected isBankedAttributes(attrs: T | B): attrs is (B & BankedItemWithTitle) {
    return 'titleSlug' in attrs;
  }


  protected mapFromBank(attrs: B & BankedItemWithTitle): void {
    this.titleSlug = attrs.titleSlug;

    this.mapItemFromBank(attrs);

    APP.titleCache.set(attrs.titleSlug);
  }


  protected map(response: T): void {
    this.mapItem(response);

    const title = this.mapTitle(response);
    this.titleSlug = C.isString(title) ? title : title.slug;

    APP.titleCache.set(title);

    if (!C.isString(title) && !title.arePropertiesFresh()) {
      APP.titleCache.freshenAll();
    }
  }


  protected abstract mapItem(response: T): void;


  protected abstract mapItemFromBank(attrs: B): void;


  /**
   * Maps out a title or a title slug from the
   * remote response. Usually, this is just mapping
   * a title from Thunder.
   *
   * @param response The remote response
   */
  protected abstract mapTitle(response: T): TitleRecord | string;


  protected abstract serializeInternal(): B;
}


interface BankedItemWithTitle {
  titleSlug: string;
}
