// NOTE: FormatJS imports must be in order: polyfill => locale data
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en'; // locale-data for en
import { C } from 'app/base/common';

type RelativeTimeOptions = {
  value: number;
  unit: Intl.RelativeTimeFormatUnit;
  numeric?: Intl.RelativeTimeFormatNumeric;
  style?: Intl.RelativeTimeFormatStyle;
};

export const toRelativeTimeOptions = (timestamp: number) => {
  const [val, sign, unit] = C.dateFromNow(timestamp);

  if (unit === 'date') {
    return null;
  }

  const options: RelativeTimeOptions = {
    value: val * sign,
    unit: unit,
    style: 'long'
  };

  return options;
};

export const relativeTimeFormatter = (options: RelativeTimeOptions, locale: string) => {
  const rtf = new Intl.RelativeTimeFormat(locale, {
    localeMatcher: 'best fit',
    numeric: options.numeric || 'auto',
    style: options.style || 'short'
  });

  return rtf.format(options.value, options.unit);
};


/* NOTE: Copied from Typescript definition files because it wasn't registering
 * and was breaking the build
 */

declare namespace Intl {
  type BCP47LanguageTag = string;

  type RelativeTimeFormatUnit =
      | 'year' | 'years'
      | 'quarter' | 'quarters'
      | 'month' | 'months'
      | 'week' | 'weeks'
      | 'day' | 'days'
      | 'hour' | 'hours'
      | 'minute' | 'minutes'
      | 'second' | 'seconds'
      ;

  type RelativeTimeFormatLocaleMatcher = 'lookup' | 'best fit';

  type RelativeTimeFormatNumeric = 'always' | 'auto';

  type RelativeTimeFormatStyle = 'long' | 'short' | 'narrow';

  interface RelativeTimeFormatOptions {
      localeMatcher?: RelativeTimeFormatLocaleMatcher;
      numeric?: RelativeTimeFormatNumeric;
      style?: RelativeTimeFormatStyle;
  }

  interface ResolvedRelativeTimeFormatOptions {
      locale: BCP47LanguageTag;
      style: RelativeTimeFormatStyle;
      numeric: RelativeTimeFormatNumeric;
      numberingSystem: string;
  }

  interface RelativeTimeFormatPart {
      type: string;
      value: string;
      unit?: RelativeTimeFormatUnit;
  }

  interface RelativeTimeFormat {
      format(
          value: number,
          unit: RelativeTimeFormatUnit,
      ): string;

      formatToParts(
          value: number,
          unit: RelativeTimeFormatUnit,
      ): RelativeTimeFormatPart[];

      resolvedOptions(): ResolvedRelativeTimeFormatOptions;
  }

  const RelativeTimeFormat: {
      new(
          locales?: BCP47LanguageTag | BCP47LanguageTag[],
          options?: RelativeTimeFormatOptions,
      ): RelativeTimeFormat;

      supportedLocalesOf(
          locales: BCP47LanguageTag | BCP47LanguageTag[],
          options?: RelativeTimeFormatOptions,
      ): BCP47LanguageTag[];
  };

  interface NumberFormatOptions {
      notation?: string;
      unit?: string;
      unitDisplay?: string;
  }

  interface ResolvedNumberFormatOptions {
      notation?: string;
      unit?: string;
      unitDisplay?: string;
  }
}
