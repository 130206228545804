<template>
  <div :class="$style.selectionToolbar">
    <span :class="$style.selectedCount">
      {{ selectedCount }}
    </span>
    <AnnotationContextMenuButton
      :id="focusTargetId"
      :annotations="selected"
      :buttonClass="$style.menuButton"
      :disabled="disabled"
      :attrs="menuType === 'export-queue' ? {
        type: menuType,
        exportOptions: true,
        showCount: true
      } : {
        type: menuType,
        showCount: true,
        showSearchOption: false
      }"
      @selected:clear="$emit('selected:clear')"
    >
      <template #buttonContent>
        {{ $t('annotations.contextMenu.button') }}
        <Icon
          :class="$style.menuButtonIcon"
          name="chevron-down"
        />
      </template>
    </AnnotationContextMenuButton>
  </div>
</template>

<script lang="ts">
import { AnnotationDetailsAnnotation } from 'app/components/AnnotationDetailsContent.vue';
import AnnotationContextMenuButton from 'app/components/contextMenus/AnnotationContextMenuButton.vue';
import { watchMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    AnnotationContextMenuButton
  },
  props: {
    headerLabel: {
      type: String,
      required: true
    },
    selected: {
      type: Array as PropType<AnnotationDetailsAnnotation[]>,
      required: true
    },
    menuType: {
      type: String as PropType<'annotation-details' | 'export-queue'>,
      required: false,
      default: 'annotation-details'
    },
    focusTargetId: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'selected:clear'
  ],
  setup: (props) => {
    const { t } = useI18n();

    const reference = ref<HTMLElement | null>(null);

    const disabled = computed(() => props.selected.length === 0);
    const showContextMenu = ref(false);
    const openMenu = () => {
      if (disabled.value) { return; }

      showContextMenu.value = true;
    };


    const selectedCount = computed(() => t('annotations.selectedCount', { COUNT: props.selected.length }));
    watchMessage(selectedCount);

    return {
      disabled,
      openMenu,
      reference,
      selectedCount
    };
  }
});
</script>

<style lang="less" module>
.selection-toolbar {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto auto;
  justify-content: start;
}

.selected-count {
  font-variant-numeric: tabular-nums;
}

.menu-button {
  align-items: center;
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto auto;
  padding: 0.5rem 1rem;
  position: relative;
}

.menu-button-icon {
  width: 1rem;
  height: 1rem;
}
</style>
