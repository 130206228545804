import { APP } from 'app/base/app';
import { Subscription } from 'app/base/samwise';
import i18n from 'app/i18n/i18n';
import { ref } from 'vue';

export function useSubscriptions() {

  const subscriptions = ref<Subscription[] | undefined>();
  const loading = ref(false);
  const error = ref(false);

  const updateSubscriptions = <T extends (...args: any[]) => Promise<Subscription[]>>(func: T) => async (...args: Parameters<T>) => {
    let succeeded = false;

    try {
      loading.value = true;

      if (!APP.patron.accountId) { throw new Error; }

      const subsUpdated = await func(...args);
      if (!subsUpdated) { throw new Error; }

      subscriptions.value = subsUpdated;
      error.value = false;

      succeeded = true;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }

    return succeeded;
  };

  const fetchAllSubscriptions = updateSubscriptions(async () => {
    const subsList = await APP.services.samwise.fetchAllSubscriptions();
    if (subsList === null) { throw new Error; }

    return subsList;
  });


  const subscribeToTitle = updateSubscriptions(async (titleId: number, triggerToast = true) => {
    try {
      const newSub = await APP.services.samwise.subscribeToTitle(titleId);
      if (newSub === null) { throw new Error; }

      if (triggerToast) {
        APP.events.dispatch('toast', {
          message: i18n.t('subscription.subscribe.success'),
          type: 'success'
        });
      }

      const subs = subscriptions.value || [];

      return [...subs, newSub];
    } catch(e) {
      if (triggerToast) {
        APP.events.dispatch('toast', {
          message: i18n.t('subscription.subscribe.failure'),
          type: 'error'
        });
      }

      throw e;
    }
  });

  const subscribeToSeries = updateSubscriptions(async (seriesId: number, triggerToast = true) => {
    try {
      const newSub = await APP.services.samwise.subscribeToSeries(seriesId);
      if (newSub === null) { throw new Error; }

      if (triggerToast) {
        APP.events.dispatch('toast', {
          message: i18n.t('subscription.subscribe.success'),
          type: 'success'
        });
      }

      const subs = subscriptions.value || [];

      return [...subs, newSub];
    } catch (e) {
      if (triggerToast) {
        APP.events.dispatch('toast', {
          message: i18n.t('subscription.subscribe.failure'),
          type: 'error'
        });
      }

      throw e;
    }
  });

  const setLastChecked = updateSubscriptions(async (subscriptionId: number) => {
    const updatedSub = await APP.services.samwise.updateSubscription(subscriptionId);
    if (updatedSub === null) { throw new Error; }

    const subs = subscriptions.value || [];

    const withoutSub = subs.filter((s) => s.id !== subscriptionId);

    return [...withoutSub, updatedSub];
  });

  const unsubscribe = updateSubscriptions(async (subscriptionId: number, triggerToast = true) => {
    try {
      const res = await APP.services.samwise.unsubscribe(subscriptionId);
      if (res === null) { throw new Error; }

      if (triggerToast) {
        APP.events.dispatch('toast', {
          message: i18n.t('subscription.unsubscribe.success'),
          type: 'success'
        });
      }

      const subs = subscriptions.value || [];

      return subs.filter((s) => s.id !== subscriptionId);
    } catch(e) {
      if (triggerToast) {
        APP.events.dispatch('toast', {
          message: i18n.t('subscription.unsubscribe.failure'),
          type: 'error'
        });
      }

      throw e;
    }
  });

  return {
    subscriptions,
    loading,
    error,

    fetchAllSubscriptions,
    setLastChecked,
    subscribeToTitle,
    subscribeToSeries,
    unsubscribe
  };
}
