<template>
  <nav
    role="navigation"
    :aria-label="$t('form.pagination.label')"
  >
    <ol :class="$style.pagination">
      <li
        v-if="showBackButton"
        :class="[$style.bookend, $style.left]"
      >
        <button
          type="button"
          :class="$style.bookend"
          :aria-label="$t('form.pagination.previousPage')"
          @click="back"
        >
          <Icon
            name="chevron-left"
            :class="$style.shiftButton"
          />
          <span :class="[$style.page, $style.ends]">
            {{ $t('nav.pagination.previous') }}
          </span>
        </button>
      </li>

      <li :class="$style.pages">
        <template
          v-for="(group, index) in groupedPages"
          :key="`group-${index}`"
        >
          <li
            v-if="index !== 0"
            aria-hidden="true"
            :class="$style.listItem"
          >
            <span :class="$style.separator">
              &hellip;
            </span>
          </li>
          <li
            v-for="page in group"
            :key="page"
            :class="$style.listItem"
          >
            <button
              type="button"
              :class="{
                [$style.page]: true,
                [$style.active]: page === currentPage
              }"
              :aria-label="$t(`form.pagination.${ page === currentPage ? 'currentPage' : 'goToPage' }`, { page })"
              :aria-current="page === currentPage ? 'page' : false"
              @click="goToPage(page)"
            >
              {{ page }}
            </button>
          </li>
        </template>
      </li>

      <li
        v-if="showForwardButton"
        :class="[$style.bookend, $style.right]"
      >
        <button
          type="button"
          :class="$style.bookend"
          :aria-label="$t('form.pagination.nextPage')"
          @click="forward"
        >
          <span :class="[$style.page, $style.ends]">
            {{ $t('nav.pagination.next') }}
          </span>
          <Icon
            name="chevron-right"
            :class="$style.shiftButton"
          />
        </button>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    maxPages: {
      type: Number,
      default: 5
    }
  },
  emits: [
    'update:currentPage'
  ],
  setup: (props, ctx) => {
    /**
     * All the pages we may want to show
     * - Always shows the first, current, and last pages
     * - Tries to evenly distribute pages around the current page as much as possible
     */
    const pages = computed(() => {
      const pagesToShow = new Set([1, props.currentPage, props.totalPages]);

      const maxPagesToShow = Math.min(props.maxPages, props.totalPages);
      let previous = props.currentPage;
      let next = props.currentPage;

      while (pagesToShow.size < maxPagesToShow) {
        previous = Math.max(1, previous - 1);
        pagesToShow.add(previous);

        if (pagesToShow.size < maxPagesToShow) {
          next = Math.min(props.totalPages, next + 1);
          pagesToShow.add(next);
        }
      }

      const sorted = [...pagesToShow].sort((a, b) => a - b);

      return sorted;
    });

    /**
     * Groups the pages into sequences of numbers so that we can show separators between sequences
     * - [1, 2, 3, 4, 6] => [[1, 2, 3, 4], [6]]
     * - [1, 4, 5, 6, 7] => [[1], [4, 5, 6, 7]]
     * - [1, 3, 4, 5, 8] => [[1], [3, 4, 5], [8]]
     * - [2, 3, 6, 7, 9] => [[2, 3], [6, 7], [9]] (not relevant to paging, but it's how the grouping works)
     */
    const groupedPages = computed(() => {
      // Split off the first value to start the first group.
      // Could do it as part of the reduce, but this saves an extra check.
      const [first, ...rest] = pages.value;

      // Iterate through all the pages we want to show, adding it to the last group
      // if it continues a sequence, or starting a new group if it doesn't.
      // Assumes that the list of pages is ordered.
      const groupedList = rest.reduce((groups, num) => {
        const finishedGroups = groups.slice(0, -1);
        const lastGroup = groups[groups.length - 1];

        const lastNum = lastGroup[lastGroup.length - 1];

        //Continue in the same group/sequence
        if (num === lastNum + 1) {
          return [...finishedGroups, [...lastGroup, num]];
        }

        //Continue in the same group/sequence filling in the one missing number
        if (num === lastNum + 2) {
          return [...finishedGroups, [...lastGroup, num - 1, num]];
        }

        //Start a new group/sequence
        return [...finishedGroups, lastGroup, [num]];
      }, [[first]] as number[][]);

      return groupedList;
    });

    const showBackButton = computed(() => props.currentPage > 1);
    const showForwardButton = computed(() => props.currentPage < props.totalPages);

    const goToPage = (page: number) => {
      const viablePage = Math.max(1, Math.min(props.totalPages, page));
      ctx.emit('update:currentPage', viablePage);
    };

    const back = () => {
      goToPage(props.currentPage - 1);
    };

    const forward = () => {
      goToPage(props.currentPage + 1);
    };

    return {
      back,
      forward,
      goToPage,
      groupedPages,
      showBackButton,
      showForwardButton
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.pagination {
  display: grid;
  grid-template-areas: "previous pages next";
  grid-template-columns: 1fr @px-vp-very-narrow 1fr;
  align-items: center;
  justify-items: center;

  @media screen and (max-width: @px-vp-tablet) {
    grid-template-columns: auto;
    grid-template-areas:
    "previous"
    "pages"
    "next";
  }
}

.bookend {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.bookend.left {
  justify-self: end;
  grid-area: previous;

  @media screen and (max-width: @px-vp-tablet) {
    margin-right: 1.5rem;
    margin-bottom: 0.6rem;
    justify-self: center;
  }
}

.bookend.right {
  justify-self: start;
  grid-area: next;

  @media screen and (max-width: @px-vp-tablet) {
    margin-left: 1.5rem;
    margin-top: 0.2rem;
    justify-self: center;
  }
}

.pages {
  grid-area: pages;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.page {
  border-radius: var(--form-border-radius);
  font-variant-numeric: tabular-nums;
  text-decoration: underline;
  text-underline-position: under;
  height: 2.5ch;
  min-width: 2.5ch;
  padding: 0.25rem;
  box-sizing: content-box;
  line-height: 1;
  color: var(--c-primary-blue);
}

.page.ends {
  text-align: center;
  margin-top: 0.4rem;
}

.page.active {
  text-decoration: none;
  font-weight: bold;
  color: var(--c-black);
  background-color: var(--c-light-gray);
}

.shift-button {
  fill: var(--c-primary-blue);
  display: flex;
  height: 2.5ch;
  width: 2.5ch;
  padding: 0.25rem;
}

.separator {
  display: inline-block;
  text-align: center;
  min-width: 3ch;
}
</style>
