<template>
  <SidebarDialog
    :heading="$t('title.tableOfContents.header')"
    @close="$emit('close')"
  >
    <template #secondHeader>
      <header :class="$style.header">
        <h3
          :class="$style.title"
          v-html="item.title"
        >
        </h3>

        <p
          v-if="'subtitle' in item"
          :class="[$style.subtitle, 'line-clamp']"
          v-html="item.subtitle"
        >
        </p>

        <table
          v-if="metadataList.length && !hideMetadata"
          :class="$style.metadata"
        >
          <caption class="visually-hidden">
            {{ $t('title.metadata.description') }}
          </caption>

          <tbody>
            <tr
              v-for="metadataItem of metadataList"
              :key="metadataItem.label"
            >
              <th
                class="metadata-label"
                scope="row"
              >
                {{ $t(metadataItem.label, metadataItem.subs) }}
              </th>
              <td class="metadata line-clamp">
                {{ metadataItem.value }}
              </td>
            </tr>
          </tbody>
        </table>
      </header>
    </template>

    <template #default>
      <component
        :is="itemType === 'series' ? 'SeriesTableOfContents' : 'TableOfContents'"
        :title="itemType === 'title' ? item : undefined"
        :series="itemType === 'series' ? item : undefined"
        :titleMap="volumes ? volumes.all : {}"
      />
    </template>
  </SidebarDialog>
</template>

<script lang="ts">
import { Dictionary } from 'app/base/common';
import SeriesTableOfContents from 'app/components/SeriesTableOfContents.vue';
import TableOfContents from 'app/components/TableOfContents.vue';
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'TableOfContentsSidebar',
  components: {
    TableOfContents,
    SeriesTableOfContents,
    SidebarDialog
},
  props: {
    item: {
      type: Object as () => Series | TitleRecord,
      required: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props) => {
    const { windowHeight } = useWindowSize();
    const hideMetadata = computed(() => {
      return windowHeight.value <= Breakpoint.VeryNarrow;
    });

    const itemType = computed(() => {
      return props.item instanceof Series
        ? 'series'
        : 'title';
    });

    const volumes = ref<{ id: number; all: Dictionary<TitleRecord> } | undefined>(undefined);

    onMounted(async () => {
      if (props.item instanceof Series) {
        if (volumes.value?.id === props.item.id) {
          return;
        }

        const volumeDictionary = await props.item.getAllTitleObjects();
        volumes.value = {
          id: props.item.id,
          all: volumeDictionary
        };
      } else {
        volumes.value = undefined;
      }
    });


    const metadataList = computed(() => {
      if (props.item instanceof Series) { return []; }

      const meta = props.item.lexisMetadata;

      const list = (['edition', 'release', 'releaseDate'] as const)
        .filter((m) => !!meta[m])
        .map((m) => {
          return {
            label: `title.${m}`,
            value: meta[m]!,
            subs: {}
          };
        });

      if (props.item.creators?.Author?.length) {
        list.push({
          label: 'title.creator',
          value: props.item.creators.Author[0].name,
          subs: { n: 1 }
        });
      }

      return list;
    });

    return {
      hideMetadata,
      itemType,
      metadataList,
      volumes
    };
  }
});
</script>

<style module>
.header {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-dark-gray);
  background-color: var(--c-white);
}

.title {
  font-weight: var(--fw-bold);
  font-size: var(--fs-large-body-head);
}

.subtitle {
  color: var(--c-light-black);
}

.metadata {
  text-align: left;
  margin: 0.25rem 0;
}

.metadata th {
  padding-right: 1rem;
  color: var(--c-light-black);
}
</style>
