import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { DataEvent } from 'lib/gala/src/events';
import { IdNamePair } from '../base/thunder';
import { Collation } from '../models/collation';
import { Loan } from './loan';
import { SharedTitle } from './shared-title';

export class SharedTitles extends Collation<SharedTitle> {
  protected ITEM_CLASS = SharedTitle;
  protected PERSISTENT = true;

  public static SORT_FUNCTIONS = SharedTitle.SORT_FUNCTIONS;


  constructor() {
    super();
    this.ITEM_NAME = 'shared-titles';
    this.BANK_SUFFIX = '';

    // Events
    APP.events.on('loan:update:all', () => this.syncFromBank());
    APP.events.on('title:open', (evt) => this._onTitleOpen(evt));
  }


  // --- PUBLIC METHODS ---

  public load() {
    if (this.PERSISTENT) {
      this.syncFromBank();
    }
  }


  public deserializeFromBank(): void {
    const attrs = APP.bank.get(this._bankName());
    this.all = [];
    this.deserialize(attrs);
  }


  public get(): SharedTitle[] {
    return this.all.slice();
  }


  public filterBySubjectIDs(subjectIDs: string | string[]): SharedTitle[] {
    if (!subjectIDs || !subjectIDs.length) {
      return this.all;
    }

    const targets = (C.isString(subjectIDs)) ? [subjectIDs] : subjectIDs;
    const output = [];

    C.each(this.all, (a) => {
      const freshTitle =  a.titleRecord;
      if (freshTitle && freshTitle.subjects) {
        C.each(freshTitle.subjects, (sub: IdNamePair) => {
          if (targets.indexOf(sub.id) >= 0) {
            output.push(a);
          }
        });
      }
    });

    return output;
  }


  /**
   * @desc Synchronize shared titles with server
   */
  public syncFromBank(): void {
    // FUTURE VERSION (maybe?)
    //const everAssigned = this.APP.patron.loans.filter((loan) => !loan.lastAssigned);
    //this.titles = everAssigned.map((t) => ({ titleSlug: t.titleSlug, title: t.title}));
    //this.save(); // Sync with local, in case we lose our connection

    // LOCAL BANK TEMP VERSION
    // This should fetch ALL titles that were shared with this user at ANY time.
    // Currently, we can only see the 'shares' we added to the bank AND current shares
    this.deserializeFromBank();
    const current = this._getCurrentAssignments();
    C.each(current, (ti) => this._upsert(ti));
    this.save(); // Sync with local, in case we lose our connection
  }


  // --- PRIVATE METHODS ---

  /**
   * @desc Iterate through all current loans and return currently assigned titles
   */
  private _getCurrentAssignments(): SharedTitle[] {
    if (!APP.patron.loans.all) {
      return [];
    }

    return APP.patron.loans.all
      .filter((loan) => loan.isAssigned) // DEBUG - Comment out for local "shared" testing
      .map((l) => {
        const output = new SharedTitle();
        output.createTime =  l.createTime;
        output.lastAccessTime =  l.lastAccessTime;
        output.assignTitle(l.titleSlug);

        return output;
      });
  }


  private _onTitleOpen(evt: DataEvent<{ slug: string; loan?: Loan }>) {
    if (!evt || !evt.m) { return; }

    const existingTitle = this._findSharedTitle(evt.m.slug);
    if (existingTitle) {
      // HACK: Manual set the "updated" access time
      // Since the API doesn't persist this value yet,
      // set it here and let it live in local storage
      existingTitle.lastAccessTime = C.epochMilliseconds();
      // END HACK
      this._upsert(existingTitle);
      this.save();
    }
  }


  /**
   * @desc Add a title to the collection
   * @param sharedTitle titleSlug/title of title to add
   */
  private _upsert(sharedTitle: SharedTitle): void {
    // Remove any existing entry for this title
    const excisedData = C.excise(this.all, (t) => t.titleSlug === sharedTitle.titleSlug) as SharedTitle;

    // HACK: Our "fresh" data won't have updated "lastAccessTime" values,
    // so we need to fall back to the BANK value when applicable
    if (excisedData && !sharedTitle.lastAccessTime) {
      sharedTitle.lastAccessTime = excisedData.lastAccessTime;
    }
    // END HACK

    // Set the updated value for this title
    this.all.push(sharedTitle);
  }


  private _findSharedTitle(titleSlug: string) {
    const existingTitle = this.all.filter((t) => (t.titleSlug === titleSlug));

    return (existingTitle && existingTitle.length) ? existingTitle[0] : null;
  }
}


export interface BankedSharedTitles {
  titles: {
    titleSlug: string;
    created: number;
    lastAccessTime: number;
  }[];
}
