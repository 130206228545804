<template>
  <div :class="$style.container">
    <label
      :id="labelElementId"
      :class="{
        [$style.label]: true,
        'visually-hidden': hideLabelOnSmallScreens && hideLabel
      }"
    >
      {{ label }}
    </label>
    <FormSelect
      v-model="selected"
      :class="$style.dropdown"
      :options="options"
      :optionLabel="optionLabel"
      :optionKey="optionKey"
      :labelId="labelElementId"
      :prompt="prompt"
    />
  </div>
</template>

<script lang="ts">
import FormSelect, { PropertySelectFunc } from 'app/components/FormSelect.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'LabeledDropdown',
  components: {
    FormSelect
  },
  props: {
    modelValue: {
      type: Object,
      default: undefined
    },
    options: {
      type: Array,
      required: true
    },
    optionLabel: {
      type: [String, Function] as PropType<string | PropertySelectFunc>,
      required: true
    },
    optionKey: {
      type: [String, Function] as PropType<string | PropertySelectFunc>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    prompt: {
      type: String,
      default: undefined
    },
    hideLabelOnSmallScreens: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const labelElementId = `formselect:label:${generateUUID()}`;

    const selected = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    const { windowWidth } = useWindowSize();

    const hideLabel = computed(() => windowWidth.value < Breakpoint.Narrow);

    return {
      labelElementId,
      hideLabel,
      selected
    };
  }
});
</script>

<style module>
.container {
  display: flex;
  box-shadow: 0 1px 6px 0 var(--c-shadow);
}

.label {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius) 0 0 var(--form-border-radius);
  color: var(--c-medium-black);
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
}

.label, .dropdown {
  border: 1px solid var(--c-dark-gray);
}

.label {
  border-right: 0;
}

.dropdown {
  flex-grow: 1;
  border-radius: 0 var(--form-border-radius) var(--form-border-radius) 0;
}

.label:global(.visually-hidden) + .dropdown {
  border-radius: var(--form-border-radius);
}

.dropdown button {
  padding: 0.5rem 0.75rem;
  background-color: var(--c-light-gray);
}
</style>
