<template>
  <div>
    <MobileSearchTrigger
      :class="$style.trigger"
      :query="searchQuery"
      @click="active = true"
    />
    <Overlay
      :show="active"
      :contentClass="$style.panel"
      initialFocus="#search-box"
      @close="active = false"
    >
      <div class="notch"></div>
      <div :class="$style.bar">
        <button
          type="button"
          :class="$style.cancelButton"
          @click="active = false"
        >
          <Icon name="chevron-left" />
        </button>
        <SearchBar
          v-model="searchQuery"
          :mobile="true"
          @search="active = false"
        />
      </div>
    </Overlay>
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref, computed } from 'vue';
import SearchBar from 'app/components/SearchBar.vue';
import MobileSearchTrigger from 'app/components/MobileSearchTrigger.vue';
import Overlay from './Overlay.vue';

export default defineComponent({
  components: {
    SearchBar,
    MobileSearchTrigger,
    Overlay
},
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const active = ref(false);
    const searchQuery = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    return { active, searchQuery };
  }
});
</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  .trigger {
    width: 100%;
  }

  .panel {
    background: var(--c-darkest-blue);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    padding: .5rem;
    z-index: 200;
  }

  .bar {
    align-items: center;
    border-bottom: 1px solid var(--c-primary-red);
    display: grid;
    grid-template-columns: auto 1fr;
    padding: .5rem 0;
  }

  .cancel-button {
    box-sizing: border-box;
    height: 2.75rem;
    margin: 0.25rem;
    padding: 0.5rem;
    width: 2.75rem;
    .focus-outline-dark;

    svg {
      fill: @c-white;
      height: 100%;
      width: 100%;
    }
  }
</style>
