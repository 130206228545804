<template>
  <ContextMenu
    :reference="reference"
    :options="contextMenuOptions"
    :headerLabel="$t('annotations.contextMenu.header')"
    :subHeaderLabel="showCount ? $t('annotations.selectedCount', { COUNT: annotations.length }) : undefined"
    @close="$emit('close')"
  />
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { ClipboardHelper } from 'app/base/clipboard';
import ContextMenu, { ContextMenuOption } from 'app/components/contextMenus/ContextMenu.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, nextTick, PropType } from 'vue';

export default defineComponent({
  name: 'AnnotationDetailsContextMenu',
  components: {
    ContextMenu
  },
  props: {
    reference: {
      type: Object as PropType<HTMLElement>,
      required: true
    },
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    showCount: {
      type: Boolean,
      default: true
    },
    showSearchOption: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close',
    'copied',
    'exported',
    'search'
  ],
  setup: (props, ctx) => {
    const { dispatch } = useAppEvents();
    const { t } = useI18n();

    const copyAnnotations = async () => {
      const toCopyString = (annotation: SelectableAnnotation) => [
        annotation.quote,
        annotation.citation,
        annotation.note
      ].filter((i) => !!i).join('\n\n');

      const copyString = props.annotations.map(toCopyString).join('\n\n\n-------------------------------\n\n\n');

      const success = APP.flags.get('force_error_toast') ? false : await ClipboardHelper.copyToClipboard(copyString);

      await nextTick();

      dispatch('toast', {
        type: success ? 'success' : 'error',
        message: t(`annotations.display.actions.copy.${success ? 'success' : 'failure'}`)
      });

      ctx.emit('copied');
      ctx.emit('close');
    };

    const exportAnnotations = async () => {
      dispatch('export-queue:insert', props.annotations.map((a) => a.uuid));

      await nextTick();

      dispatch('toast', {
        type: 'success',
        message: t('annotations.display.actions.addToExportQueue.success', { N: props.annotations.length })
      });

      ctx.emit('exported');
      ctx.emit('close');
    };

    const findHighlight = () => {
      ctx.emit('search');
      ctx.emit('close');
    };

    const contextMenuOptions = computed<ContextMenuOption[]>(() => {
      const opts = [
        {
          label: t('annotations.contextMenu.copy'),
          iconName: 'copy-to-clipboard',
          handler: copyAnnotations as (() => void) | (() => Promise<void>)
        },
        {
          label: t('annotations.contextMenu.export'),
          iconName: 'export',
          handler: exportAnnotations
        }
      ] as ContextMenuOption[];

      if (props.showSearchOption) {
        opts.push({
          label: t('annotations.contextMenu.findHighlight'),
          iconName: 'search',
          handler: findHighlight,
          hasPopup: 'dialog'
        });
      }

      return opts;
    });

    return {
      contextMenuOptions
    };
  }
});
</script>

<style module>

</style>
