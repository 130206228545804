import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeriesTableOfContents = _resolveComponent("SeriesTableOfContents")!
  const _component_AccordionSubjectList = _resolveComponent("AccordionSubjectList")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createBlock(_component_Accordion, { sections: _ctx.sections }, {
    toc: _withCtx(({ mobile }) => [
      _createVNode(_component_SeriesTableOfContents, {
        series: _ctx.series,
        titleMap: _ctx.volumeDictionary,
        appendDropdownToBody: false,
        showTitle: mobile
      }, null, 8, ["series", "titleMap", "showTitle"])
    ]),
    subjects: _withCtx(() => [
      _createVNode(_component_AccordionSubjectList, {
        subjects: Object.values(_ctx.series.subjects)
      }, null, 8, ["subjects"])
    ]),
    _: 1
  }, 8, ["sections"]))
}