<template>
  <Dropdown
    v-model="selectedInternal"
    :options="transformedOptions"
    :labelId="labelId"
    :placeholder="prompt"
    :buttonClass="$style.button"
    :iconClass="$style.icon"
    :listClass="$style.list"
    :optionClass="classOverrides['option'] || $style.option"
    :highlightedClass="classOverrides['highlighted'] || $style.highlighted"
    :selectedClass="$style.selected"
  >
    <template
      v-for="(_, name) in $slots"
      :key="name"
      #[name]
    >
      <slot :name="name"></slot>
    </template>
  </Dropdown>
</template>

<script lang='ts'>
import { computed, defineComponent, PropType } from 'vue';
import Dropdown from 'app/components/Dropdown.vue';

export type PropertySelectFunc = (obj: unknown) => string;

export default defineComponent({
  name: 'FormSelect',
  components: {
    Dropdown
  },
  props: {
    modelValue: {
      type: Object,
      default: undefined
    },
    options: {
      type: Array,
      required: true
    },
    optionLabel: {
      type: [String, Function] as PropType<string | PropertySelectFunc>,
      required: true
    },
    optionKey: {
      type: [String, Function] as PropType<string | PropertySelectFunc>,
      required: true
    },
    labelId: {
      type: String,
      default: undefined
    },
    prompt: {
      type: String,
      default: undefined
    },
    /**
     * override the classes that FormSelect provides to Dropdown
     * pass as dictionary with key = name of FormSelect class you want to override,
     * and value = your new class
     * Ex: { 'highlighted': $style.highlighted }
     */

    classOverrides: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({})
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const getId = computed<PropertySelectFunc>(() => typeof props.optionKey === 'string'
      ? (obj: any) => obj[props.optionKey as string]
      : props.optionKey);
    const getDisplayName = computed<PropertySelectFunc>(() => typeof props.optionLabel === 'string'
      ? (obj: any) => obj[props.optionLabel as string]
      : props.optionLabel);

    const selectedInternal = computed({
      get: () => props.modelValue ? getId.value(props.modelValue) : undefined,
      set: (v) => ctx.emit('update:modelValue', props.options.find((option) => getId.value(option) === v))
    });

    const transformedOptions = computed(() => {
      return props.options.map((option: any) => {
        return {
          id: getId.value(option),
          displayName: getDisplayName.value(option),
          count: option.count,
          ariaLabel: option.ariaLabel
        };
      });
    });

    return {
      selectedInternal,
      transformedOptions
    };
  }
});
</script>

<style module>
.button {
  color: var(--c-dark-black);
  background-color: var(--c-light-gray);
  border-radius: var(--form-border-radius);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
}

.icon {
  fill: var(--c-medium-black);
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
  flex-shrink: 0;
}

.list {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.25rem;
  max-height: 10em;
  overflow-y: auto;
  border-radius: var(--form-border-radius);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  background-color: var(--c-white);
  right: 0;
  min-width: min-content;
}

.option {
  color: var(--c-dark-black);
  padding: 1rem;
}

.selected {
  font-weight: var(--fw-bold);
  background-color: var(--c-lightest-gray);
}

.highlighted, .option:hover {
  background-color: var(--c-light-gray);
}
</style>
