<template>
  <PromptDialog
    ref="prompt"
    :heading="$t('circ.action.placeHold')"
    @close="$emit('close')"
    @submit="submitAndClose"
  >
    <p
      :id="`hold-explanation-${titleId}`"
    >
      {{ $t('circ.explanation.hold') }}
    </p>

    <FormInput
      v-model="email"
      autofocus
      type="email"
      :required="true"
      :label="$t('form.email')"
      formName="email"
      :class="$style.email"
    />

    <div
      :class="$style.actions"
    >
      <FormSubmitButton
        :label="$t('circ.action.placeHold')"
        :enabled="true"
        size="small"
      />

      <button
        :class="$style.cancel"
        type="button"
        @click="prompt?.closeModal()"
      >
        {{ $t('general.cancel') }}
      </button>
    </div>
  </PromptDialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import PromptDialog from './dialogs/PromptDialog.vue';
import FormInput from './FormInput.vue';
import FormSubmitButton from './FormSubmitButton.vue';

export default defineComponent({
  name: 'HoldPrompt',
  components: {
    FormInput,
    FormSubmitButton,
    PromptDialog
  },
  props: {
    titleId: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      default: ''
    }
  },
  emits: [
    'close',
    'hold'
  ],
  setup: (props, ctx) => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);

    const submitAndClose = () => {
      ctx.emit('hold', { email: email.value });
      prompt.value?.closeModal();
    };

    const email = ref(props.emailAddress);

    return {
      email,
      prompt,
      submitAndClose
    };
  }
});
</script>

<style module>
.email {
  min-width: 25ch;
}

.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.cancel {
  text-transform: uppercase;
  color: var(--c-medium-black);
  composes: linked from global;
}
</style>
