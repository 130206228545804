import { SeriesNewReleaseAlert, SeriesNotAvailableAlert, TitleAlert } from 'app/base/samwise';
import { useAlerts } from 'app/functions/use-alerts';
import { useSeries } from 'app/functions/use-series';
import { useTitles } from 'app/functions/use-titles';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { computed } from 'vue';

export type AlertWithMetadata =
  (TitleAlert & { title: TitleRecord }) |
  (SeriesNewReleaseAlert & { titles: TitleRecord[]; series: Series }) |
  (SeriesNotAvailableAlert & { series: Series });

export function useAlertsWithMetadata() {
  const {
    alerts,
    loading: alertsLoading,
    error: alertsError,
    ...modifyAlertsFuncs
  } = useAlerts();

  const titleIds = computed<Set<string>>(() => {
    if (!alerts.value) { return new Set(); }

    const ids = alerts.value.flatMap((alert) => {
      if (alert.type === 'TitleNewRelease' || alert.type === 'TitleNotAvailable') {
        return [alert.titleId];
      }

      if (alert.type === 'SeriesNewRelease') {
        return alert.titleIds;
      }

      return [];
    }).map((id) => id.toString());

    return new Set(ids);
  });

  const {
    titles,
    loading: titlesLoading,
    error: titlesError
  } = useTitles(titleIds);

  const seriesIds = computed<Set<number>>(() => {
    if (!alerts.value) { return new Set(); }

    const ids = alerts.value.flatMap((alert) => {
      if (alert.type === 'SeriesNewRelease' || alert.type === 'SeriesNotAvailable') {
        return [alert.seriesId];
      }

      return [];
    });

    return new Set(ids);
  });

  const {
    series,
    loading: seriesLoading,
    error: seriesError
  } = useSeries(seriesIds);

  const alertsWithMetadata = computed<AlertWithMetadata[]>(() => {
    if (!alerts.value) { return []; }
    if (!titles.value) { return []; }
    if (!series.value) { return []; }

    const titleData = titles.value;
    const seriesData = series.value;

    return alerts.value.flatMap((alert) => {
      if (alert.type === 'SeriesNewRelease') {
        const matchingSeries = seriesData.find((s) => s.id === alert.seriesId);
        if (!matchingSeries) { return []; }

        const matchingTitles = titleData.filter(
          (t) => alert.titleIds.map((id) => id.toString()).includes(t.slug)
        );
        if (matchingTitles.length !== alert.titleIds.length) { return []; }

        return [{
          ...alert,
          series: matchingSeries,
          titles: matchingTitles
        }] as AlertWithMetadata[];
      }

      if (alert.type === 'SeriesNotAvailable') {
        const matchingSeries = seriesData.find((s) => s.id === alert.seriesId);
        if (!matchingSeries) { return []; }

        return [{
          ...alert,
          series: matchingSeries
        }] as AlertWithMetadata[];
      }

      // Title alerts

      const matchingTitle = titleData.find((t) => t.slug === alert.titleId.toString());
      if (!matchingTitle) { return []; }

      return [{
        ...alert,
        title: matchingTitle
      }] as AlertWithMetadata[];

    });
  });

  const loading = computed(() =>
    alertsLoading.value ||
    titlesLoading.value ||
    seriesLoading.value
  );

  const error = computed(() =>
    alertsError.value ||
    titlesError.value ||
    seriesError.value
  );

  return {
    alertsWithMetadata,
    loading,
    error,
    ...modifyAlertsFuncs
  };
}
