<template>
  <time
    :datetime="new Date(timestamp).toISOString()"
    :title="new Date(timestamp).toString()"
  >
    <template v-if="relativeTime">
      {{ $t('time.relative', { relativeTime }) }}
    </template>
    <template v-else>
      {{ $t('time.date', { date }) }}
    </template>
  </time>
</template>

<script lang='ts'>
import { useI18n } from 'app/functions/use-i18n';
import { relativeTimeFormatter, toRelativeTimeOptions } from 'app/i18n/relative-time-formatter';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'RelativeDate',
  props: {
    timestamp: {
      type: Number,
      required: true
    },
    numeric: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const { locale } = useI18n();
    const date = computed(() => new Date(props.timestamp));
    const relativeTime = computed(() => {
      const opts = toRelativeTimeOptions(props.timestamp);
      if (opts) {
        if (props.numeric) {
          opts.numeric = 'always'; // 'always' to show 'in 1 day' instead of 'tomorrow'
        }

        return relativeTimeFormatter(opts, locale);
      }

      return undefined;
    });

    return {
      date,
      relativeTime
    };
  }
});
</script>
