export class Constants {
  // The className that will be assigned to an onTap element between
  // contact start and contact end.
  public static readonly TAPPABLE_CLASS = 'tappable';

  // If a tap moves more than this many pixels from the starting position
  // on the screen, we abort the contact.
  public static readonly TAP_MAX_CONTACT_DISTANCE = 10;

  // You could also set this to 'pan-y' if you want interactive elements
  // to work inside scrollable divs
  public static readonly DEFAULT_TOUCH_ACTION = 'pan-y'; // 'none'
}
