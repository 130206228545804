<template>
  <span
    :class="$style.annotationLink"
  >
    <Icon name="nav-notes" />

    <router-link
      :to="{ name: 'NoteDetails', params: { titleSlug } }"
    >
      {{ $t('annotations.label') }}
    </router-link>

    <span class="badge">{{ annotationCount }}</span>
  </span>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsAnnotationsLink',
  props: {
    titleSlug: {
      type: String,
      required: true
    },
    annotationCount: {
      type: Number,
      required: true
    }
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.annotation-link {
  display: flex;
  align-items: center;
  color: @c-light-black;
  font-weight: @fw-medium;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: currentColor;
    margin-right: .5rem;
  }

  a {
    .pop(@fgColor: @c-light-black);
    line-height: normal;
  }
}

// ## TABLET LAYOUT

@media screen and (max-width: @px-vp-very-wide) {
  .annotation-link {
    justify-content: center;
  }
}
</style>
