import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title", "innerHTML"]
const _hoisted_2 = ["title", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_Cover, {
      item: _ctx.title,
      class: _normalizeClass(_ctx.$style.cover)
    }, null, 8, ["item", "class"]),
    _createElementVNode("div", null, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.created)
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.count)
        }, _toDisplayString(_ctx.$t('home.annotations.count', { n: _ctx.numAnnotations })), 3),
        (_ctx.timestamp)
          ? (_openBlock(), _createBlock(_component_RelativeDate, {
              key: 0,
              timestamp: _ctx.timestamp,
              class: _normalizeClass(_ctx.$style.date)
            }, null, 8, ["timestamp", "class"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.$style.title),
        title: _ctx.title.title,
        innerHTML: _ctx.title.title
      }, null, 10, _hoisted_1),
      (_ctx.title.subtitle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.$style.subtitle),
            title: _ctx.title.subtitle,
            innerHTML: _ctx.title.subtitle
          }, null, 10, _hoisted_2))
        : _createCommentVNode("", true)
    ])
  ], 2))
}