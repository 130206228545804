<template>
  <table
    v-bind="$attrs"
    :class="$style.table"
  >
    <caption class="visually-hidden">
      {{ $t('annotations.display.header', { title }) }}
    </caption>
    <thead>
      <tr>
        <th>
          <span class="visually-hidden">
            {{ $t('annotations.display.property.select') }}
          </span>
        </th>
        <th>
          <span class="visually-hidden">
            {{ $t('annotations.display.property.expander') }}
          </span>
        </th>
        <th>
          {{ $t('annotations.display.property.color') }}
        </th>
        <th>
          {{ $t('annotations.display.property.highlight') }}
        </th>
        <th v-if="!hideNote">
          {{ $t('annotations.display.property.note') }}
        </th>
        <th>
          {{ $t('annotations.display.property.citation') }}
        </th>
        <th>
          {{ $t('annotations.display.property.release') }}
        </th>
        <th>
          {{ $t('annotations.display.property.lastUpdated') }}
        </th>
        <th :class="$style.centered">
          {{ $t('annotations.display.actions.label') }}
        </th>
      </tr>
    </thead>

    <tbody>
      <ExportQueueTableRow
        v-for="annotation in annotations"
        :key="annotation.uuid"
        :annotation="annotation"
        :hideNote="hideNote"
        @annotation:update="(checked) => updateAnnotation(checked, annotation.uuid)"
      />
    </tbody>
  </table>
</template>

<script lang="ts">
import ExportQueueTableRow from 'app/components/ExportQueueTableRow.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AnnotationTable',
  components: {
    ExportQueueTableRow
  },
  props: {
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  emits: [
    'annotations:update'
  ],
  setup: (props, ctx) => {
    const updateAnnotation = (checked: FormCheckboxState, uuid: string) => {

      ctx.emit('annotations:update', checked, uuid);
    };

    return {
      updateAnnotation
    };
  }
});
</script>

<style module>
.checkbox {
  row-gap: 0;
  column-gap: 0;
  margin-bottom: -3rem;
}

.table {
  width: 100%;
}

.table > thead {
  border-top: 1px solid var(--c-dark-gray);
  border-bottom: 1px solid var(--c-dark-gray);
  font-weight: var(--fw-bold);
  font-size: var(--fs-metadata);
  text-transform: uppercase;
  text-align: left;
  color: var(--c-medium-black);
  white-space: nowrap;
}

.table > thead th, .checkbox {
  padding: 12px 20px;
}

@media screen and (max-width: 1470px) {
  .table > thead th, .checkbox {
    padding: 12px 8px;
  }
}

.table > tbody > tr + tr > td {
  border-top: 1px solid var(--c-dark-gray);
}

.centered {
  text-align: center;
}
</style>
