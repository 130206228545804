import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { Loan } from 'app/models/loan';
import { DataEvent } from '../../../lib/gala/src/events';
import { EventHandler } from '../event-map';
import { DervishResponse, DervishURLs } from '../interfaces';
import { Delta, Network } from '../network';
import { Roster, RosterDocument } from './roster';

export class Downloader {
  public updateTime = 0;
  public downloadedTime?: number;
  public checking = false;
  public rosterIds: string[] = [];
  public rosters: Dictionary<Roster | null> = {};
  public loan: Loan;
  public overrideCellular?: boolean;

  // Have public get/set equivalents
  private _autoDownload: boolean;
  private _invalid: boolean;
  private _downloadPercent: number;
  private _totalBytes: number;

  private readonly _rostersInitializedOnThisCheck: Roster[];
  private _recheckOnUpdate = true;
  private _retryOnNetworkChange = false;
  private _callbacks: ((loan: Loan, urls: DervishURLs, downloading: boolean) => void)[] = [];
  private _rosterDocumentHandler?: EventHandler<'msg:roster:response'>;
  private _requestedRostersURL?: string;
  private readonly _dervishData: DervishResponse;
  private _requestPending: boolean;


  constructor(loan: Loan) {
    this.loan = loan;
    this.rosters = {};
    this.rosterIds = [];
    this._autoDownload = false;
    this._invalid = false;
    this._downloadPercent = 0;
    this._totalBytes = 0;
    this._dervishData = {
      urls: {
        web: null,
        openbook: null,
        rosters: null,
        possession: null,
        activity: null
      },
      message: ''
    };
    this._requestPending = false;
    this._rostersInitializedOnThisCheck = [];
    APP.events.on('network:info', (evt) => this._onNetworkInfo(evt.m));
  }

  public get autoDownload() {
    return this._autoDownload;
  }

  public set autoDownload(val) {
    this._autoDownload = val;
    APP.events.dispatch('downloads:autoDownload', { loan: this.loan, autoDownload: val });
  }

  public get invalid() {
    return this._invalid;
  }

  public set invalid(val) {
    this._invalid = val;
    APP.events.dispatch('downloads:invalid', { loan: this.loan, invalid: val });
  }

  public get downloadPercent() {
    return this._downloadPercent;
  }

  public set downloadPercent(val) {
    this._downloadPercent = val;
    APP.events.dispatch('downloads:downloadPercent', { loan: this.loan, downloadPercent: val });
  }

  public get totalBytes() {
    return this._totalBytes;
  }

  public set totalBytes(val) {
    this._totalBytes = val;
    APP.events.dispatch('downloads:totalBytes', { loan: this.loan, totalBytes: val });
  }


  /**
   * Options:
   *
   * - onChecked: function to call back when check is performed. Takes
   *     three arguments: loan, urls (hash of strings) and downloading (bool)
   *
   * - timeout: how long to wait on check before aborting and invoking
   *     onChecked callback immediately
   *
   * - recheckOnUpdate: boolean flag. if this check results in the title
   *    being downloaded, should we re-check all other downloaders too?
   *    defaults to true.
   */
  public check(options: CheckOptions = {}): void {
    if (this.checking && this._requestPending) {
      this._appendCallback(options.onChecked);
      this._recheckOnUpdate =
        this._recheckOnUpdate ||
        (options.recheckOnUpdate === false ? false : true);
    } else {
      this.invalid = false;
      this.checking = true;
      delete this._dervishData.urls.fetched;
      this._retryOnNetworkChange = false;
      this._callbacks = [];
      this._rostersInitializedOnThisCheck.splice(0);
      this._appendCallback(options.onChecked);
      this._recheckOnUpdate = options.recheckOnUpdate === false ? false : true;
      if (!APP.network.reachable) {
        this._retryOnNetworkChange = !!this._dervishData.urls.web || (!!this.downloadPercent && this.downloadPercent < 1);
        this._invokeCallbacks(this._dervishData.urls);
      } else if (this._isFilteredContent()) {
        this._invokeCallbacks(this._dervishData.urls);
      } else {
        this._fetchDervishURLs(options);
      }
    }
  }


  public isAwaitingCheck(minUpdateTime: number): boolean {
    if (this.autoDownload === false) {
      return false;
    }
    if (minUpdateTime && this.updateTime < minUpdateTime) {
      return true;
    }
    if (!this.rosterIds.length) {
      return true;
    }
    if (!this._dervishData.urls || this._dervishData.urls.expires !== this.loan.expireTime) {
      return true;
    }
    for (let i = 0, ii = this.rosterIds.length; i < ii; ++i) {
      if (!this.rosters[this.rosterIds[i]]) {
        return true;
      }
    }
    for (const id in this.rosters) {
      const roster = this.rosters[id];
      if (roster && roster.doc && roster.doc.health !== 'valid') {
        return true;
      }
    }

    return false;
  }


  /**
   * Determines if this download is pending download.
   */
  public isQueued(): boolean {
    return !this.invalid &&
    this.autoDownload &&
    this.downloadPercent <= 0 &&
    !APP.updateManager.paused;
  }


  /**
   * Determines if the title has been downloaded or not
   */
  public isDownloaded(): boolean {
    return (typeof this.downloadedTime !== 'undefined');
  }


  public assignURLs(dervishData: DervishResponse): void {
    C.absorb(dervishData, this._dervishData);
    this._dervishData.urls.fetched = C.epochMilliseconds();
    this._dervishData.urls.expires = this.loan.expireTime;
    this.loan.collation.save(true);
  }


  // Returns the cached URLs unless:
  //
  // - there are no cached URLs; or
  // - the loan is set to stream; or
  // - the URLs have expired
  //
  // I have decided not to supply cached URLs for streaming titles,
  // because there is a risk that the URL will go to a Dxxx Dervish
  // error (eg, if the title is early-returned elsewhere), which will
  // trigger a bifocal:view:failure.
  //
  public cachedURLs(): DervishResponse | null {
    if (!this._dervishData.urls) { return null; }
    if (!this.autoDownload) { return null; }
    if (this._dervishData.urls.expires
      && this._dervishData.urls.expires < C.epochMilliseconds()) {
      return null;
    }

    return {
      urls: this._dervishData.urls,
      message: null // Tell Nautilus to attempt to use cached copy of the book
    };
  }


  /**
   * Clear downloaded content and wipe cached urls
   */
  public purge(): void {
    this._dervishData.urls =  {
      web: null,
      openbook: null,
      rosters: null,
      possession: null,
      activity: null
    };
    this.setAutoDownload(false);
  }


  public updateProgress() {
    this._updateDownloadPercent(this._calculateDownloadPercent());
  }


  public serialize(): DownloaderAttributes {
    return {
      urls: this._dervishData.urls,
      message: this._dervishData.message,
      rosterIds: this.rosterIds,
      autoDownload: this.autoDownload,
      totalBytes: this.totalBytes,
      downloadPercent: this.downloadPercent,
      updateTime: this.updateTime,
      downloadTime: this.downloadedTime
    };
  }


  public deserialize(attrs: DownloaderAttributes): void {
    this._dervishData.urls = attrs.urls;
    this._dervishData.message = attrs.message;
    this.rosterIds = attrs.rosterIds;
    this.totalBytes = attrs.totalBytes;
    this.downloadPercent = attrs.downloadPercent || 0;
    this.updateTime = attrs.updateTime || 0;
    this.downloadedTime = attrs.downloadTime;

    // If the download is already started/there, leave it
    if (this.downloadPercent > 0) {
      this.autoDownload = attrs.autoDownload || APP.updateManager.autoDownloadForLoan(this.loan);
    } else { // otherwise, default to off until the user acts on it
      this.autoDownload = false;
    }
  }


  public setAutoDownload(autoDownload: boolean): void {
    if (this.autoDownload === autoDownload && !this.invalid) {
      return;
    }
    if (this._isFilteredContent()) {
      this.autoDownload = false;
    } else {
      this.autoDownload = autoDownload;
    }
    if (this.autoDownload) {
      this.check({ recheckOnUpdate: false });
    } else {
      this.wipe();
    }
    this.updateProgress();
    this.loan.collation.save(true);
  }


  public clearOverrideCellular(): void {
    if (this.overrideCellular) {
      this.overrideCellular = false;
      APP.updateManager.pause('wifi');
    }
  }


  public async getOpenBookURL(): Promise<string | null> {
    if (!this._dervishData.urls.openbook) {
      const options = {} as CheckOptions;
      const openbook = await this._fetchOpenBook(options);

      return openbook;
    }

    return this._dervishData.urls.openbook;
  }


  public wipe(): void {
    this.invalid = false;
    this.rosters = {};
    this.rosterIds = [];
    this.downloadedTime = undefined;
    APP.updateManager.wipeUnownedRosters();
    APP.events.dispatch('loan:download:wipe', { loan: this.loan });
  }


  protected _onRosterDocuments(
    urls: DervishResponse,
    evt: DataEvent<{ url: string; rosters: RosterDocument | RosterDocument[]; response: { status: number } }>
  ): void {
    if (evt.m.url !== this._requestedRostersURL) {
      return;
    }
    delete this._requestedRostersURL;
    APP.events.off(this._rosterDocumentHandler);
    if (evt.m.response.status < 400 && evt.m.rosters) {
      this._onRosterDocumentsSuccess(Array.isArray(evt.m.rosters) ? evt.m.rosters : [evt.m.rosters], urls);
    } else {
      this._onRosterDocumentsFailure();
    }
  }


  protected _onRosterDocumentsSuccess(rosterDocuments: RosterDocument[], dervishData: DervishResponse): void {
    // Calculate the total byte size of the download:
    this.totalBytes = this._sizeOfRosterDocuments(rosterDocuments);

    // If we CAN auto-download, start downloading the rosters immediately:
    if (this.autoDownload) {
      this._initializeRosters(rosterDocuments);
    }

    // Save our state:
    this.rosterIds = [];
    C.each(
      this.rosters,
      (id) => {
        this.rosterIds.push(id);
      }
    );
    this.updateTime = C.epochMilliseconds();
    this.assignURLs(dervishData);

    // Complete our check by invoking the callback:
    this._invokeCallbacks(this._dervishData.urls);
  }


  protected _onRosterDocumentsFailure(): void {
    this.clearOverrideCellular();
    if (this.downloadPercent && this.downloadPercent < 1) {
      this._markForRetry(APP.network.reachable);
    }
    this._invokeCallbacks();
  }


  protected _initializeRosters(rosterDocuments: RosterDocument[]): void {
    C.each(
      rosterDocuments,
      (doc) => {
        // Find the existing roster with this id or create it:
        const roster = (this.rosters[doc.id] = APP.updateManager.roster(doc.id));
        // If the roster is pending or obsolete,
        // reinitialize it with this document:
        const isInLimbo = !roster.finalized && !roster.updating;
        const isObsolete = roster.version.isOlderThan(doc.version);
        if (isInLimbo || isObsolete) {
          roster.registerCallbacks({
            initialize: this._onRosterProgress.bind(this, roster),
            progress: this._onRosterProgress.bind(this, roster),
            finalize: this._onRosterFinalize.bind(this, roster),
            invalidate: this._onRosterInvalidate.bind(this, roster)
          });
          // If the user chose to override the wifi-only setting,
          // that means we're on a cell connection and the queue
          // is paused. We need to resume it to begin downloading.
          if (this.overrideCellular) {
            APP.updateManager.resume('wifi', true);
          }
          roster.initializeUpdate(doc);
          this._rostersInitializedOnThisCheck.push(roster);
          if (this._recheckOnUpdate) {
            APP.updateManager.recheckDownloaders();
          }
        }
      }
    );
    if (this._rostersInitializedOnThisCheck.length > 0) {
      APP.events.dispatch('loan:download:commence', { loan: this.loan });
    }
  }


  protected _onRosterProgress(roster: Roster): void {
    // It may be that another download overrode our cellular preference,
    // finished downloading, and then paused the queue again, thereby
    // disrupting our download. This hopefully will catch that.
    if (APP.updateManager.paused && this.overrideCellular) {
      APP.updateManager.resume('wifi', true);
    }
    const pc = this._calculateDownloadPercent();
    if (pc !== this.downloadPercent) {
      this._updateDownloadPercent(pc);
    }
  }


  protected _onRosterFinalize(roster: Roster): void {
    this.invalid = false;
    this.updateProgress();
  }


  protected _onRosterInvalidate(roster: Roster): void {
    this.clearOverrideCellular();
    this._markForRetry(true);
  }


  protected _markForRetry(isInvalid: boolean): void {
    this.invalid = isInvalid || true;
    this._retryOnNetworkChange = true;
    this._updateDownloadPercent(0);
  }


  protected _sizeOfRosterDocuments(rosterDocuments: RosterDocument[]): number {
    let totalBytes = 0;
    C.each(rosterDocuments, (doc) => {
      C.each(doc.entries, (entry) => {
        totalBytes += entry.bytes || Roster.DEFAULT_ENTRY_BYTES;
      });
    });

    return totalBytes;
  }


  protected _calculateDownloadPercent(): number {
    let totalBytes = 0;
    let progressBytes = 0;
    C.each(
      this.rosters,
      (id, roster) => {
        if (!roster || !roster.doc || !roster.sizeInBytes) {
          return;
        }
        totalBytes += roster.sizeInBytes.total;
        if (roster.doc.health === 'valid') {
          progressBytes += roster.sizeInBytes.total;
        } else {
          progressBytes += roster.sizeInBytes.progress;
        }
      }
    );

    return totalBytes === 0 ? 0 : progressBytes / totalBytes;
  }


  protected _updateDownloadPercent(percent: number): void {
    this.downloadPercent = this.autoDownload ? percent : 0;
    const evtData = { loan: this.loan, downloadPercent: this.downloadPercent };
    if (this.downloadPercent === 1) {
      this.downloadedTime = C.epochMilliseconds();
      APP.events.dispatch('loan:download:complete', evtData);
      this.clearOverrideCellular();
    }
  }


  protected _appendCallback(callback: ((loan: Loan, urls: DervishURLs, downloading: boolean) => void) | undefined): void {
    this._callbacks = this._callbacks || [];
    if (callback) {
      this._callbacks.push(callback);
    }
  }


  protected _invokeCallbacks(urls?: DervishURLs): void {
    const dervishUrls = urls ? C.absorb(urls, {}) : {
        web: null,
        openbook: null,
        rosters: null,
        possession: null,
        activity: null
      };

    const downloading = this._rostersInitializedOnThisCheck.length > 0;
    C.each(
      this._callbacks,
      (callback) => {
        callback(this.loan, dervishUrls, downloading);
      }
    );
    this._callbacks.splice(0);
    this._rostersInitializedOnThisCheck.splice(0);
    this.checking = false;
  }


  protected _onNetworkInfo(evt: { network: Network; delta: Delta }): void {
    if (evt.network.reachable && this._retryOnNetworkChange) {
      this._retryOnNetworkChange = false;
      setTimeout(this.check.bind(this), 500);
    }
  }


  protected _isFilteredContent(): boolean {
    const title = APP.titleCache.get(this.loan.titleSlug);
    if (!title.hasODRFormat) {
      return true;
    }

    if (title.mediaType === 'magazine') {
      return true;
    }

    return false;
  }


  protected async _fetchOpenBook(options: CheckOptions) {
    if (APP.network.reachable) {
      const title = APP.titleCache.get(this.loan.titleSlug) || this.loan.titleRecord;
      const dervishUrls = await APP.sentry.fetchDervishURLs(
        this.loan.titleSlug,
        title.mediaType,
        title.isLexisPublished ? true : false,
        title.isLexisPublished ? true : false,
        APP.library.disableNotes,
        options.timeout,
        title.isBundledChild && title.lexisMetadata ? title.lexisMetadata.parent : undefined
      );

      return dervishUrls ? `${dervishUrls.urls.openbook}?${dervishUrls.message}` : null;
    }

    return null;
  }


  protected async _fetchDervishURLs(options: CheckOptions) {
    try {
      this._requestPending = true;
      const title = APP.titleCache.get(this.loan.titleSlug);
      const dervishUrls = await APP.sentry.fetchDervishURLs(
        this.loan.titleSlug,
        title.mediaType,
        title.isLexisPublished ? true : false,
        title.isLexisPublished ? true : false,
        APP.library.disableNotes,
        options.timeout,
        title.isBundledChild && title.lexisMetadata ? title.lexisMetadata.parent : undefined
      );
      if (!dervishUrls) {
        throw new Error('Received null dervish urls');
      }

      this._onURLForRosters(dervishUrls);
    } catch (ex) {
      this._onURLForRostersFailure();
    }
  }


  protected _onURLForRosters(dervishData: DervishResponse) {
    this._requestPending = false;
    if (APP.shell.has('rosters')) {
      APP.events.off(this._rosterDocumentHandler);
      this._rosterDocumentHandler = APP.events.on(
        'msg:roster:response',
        (evt) => this._onRosterDocuments(dervishData, evt)
      );
      this._requestedRostersURL = dervishData.urls.rosters + '?' + dervishData.message;
      APP.shell.transmit({
        name: 'roster:request',
        dest: 'shell',
        url: this._requestedRostersURL
      });
    } else {
      this._invokeCallbacks(dervishData.urls);
    }
  }


  protected _onURLForRostersFailure(): void {
    this._requestPending = false;
    this.clearOverrideCellular();
    if (this.downloadPercent && this.downloadPercent  < 1) {
      this._markForRetry(APP.network.reachable);
    }
    this._invokeCallbacks();
  }
}

interface CheckOptions {
  onChecked?: (loan: Loan, urls: DervishURLs, downloading: boolean) => void;
  timeout?: number;
  recheckOnUpdate?: boolean;
}

interface DownloaderAttributes {
  urls: DervishURLs;
  message: string | null;
  rosterIds: string[];
  autoDownload?: boolean;
  totalBytes: number;
  downloadPercent?: number;
  updateTime?: number;
  downloadTime?: number;
}
