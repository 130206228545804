<template>
  <FormCheckbox
    :modelValue="selectAllState"
    :showLabel="false"
    :label="$t('annotations.display.selectAll')"
    :ariaLabel="$t('annotations.display.selectAll')"
    :class="$style.checkbox"
    @update:modelValue="updateAllAnnotations"
  />
  <table
    v-bind="$attrs"
    :class="$style.table"
  >
    <caption class="visually-hidden">
      {{ $t('annotations.display.header', { title }) }}
    </caption>
    <thead>
      <tr>
        <th>
          <span class="visually-hidden">
            {{ $t('annotations.display.property.select') }}
          </span>
        </th>
        <th>
          <span class="visually-hidden">
            {{ $t('annotations.display.property.expander') }}
          </span>
        </th>
        <th>
          {{ $t('annotations.display.property.color') }}
        </th>
        <th>
          {{ $t('annotations.display.property.highlight') }}
        </th>
        <th v-if="!hideNote">
          {{ $t('annotations.display.property.note') }}
        </th>
        <th>
          {{ $t('annotations.display.property.citation') }}
        </th>
        <th>
          {{ $t('annotations.display.property.release') }}
        </th>
        <th>
          {{ $t('annotations.display.property.lastUpdated') }}
        </th>
        <th>
          {{ $t('annotations.display.actions.label') }}
        </th>
      </tr>
    </thead>

    <tbody>
      <AnnotationTableRow
        v-for="(annotation, index) in annotations"
        :key="annotation.uuid"
        :annotation="annotation"
        :hideNote="hideNote"
        @annotation:update="(a) => updateAnnotation(index, a)"
      />
    </tbody>
  </table>
</template>

<script lang="ts">
import AnnotationTableRow from 'app/components/AnnotationTableRow.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    AnnotationTableRow,
    FormCheckbox
  },
  props: {
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    selectAllState: {
      type: String as PropType<FormCheckboxState>,
      required: true
    }
  },
  emits: [
    'annotations:update',
    'annotations:update:all'
  ],
  setup: (props, ctx) => {
    const updateAllAnnotations = (checked: FormCheckboxState) => {
      ctx.emit('annotations:update:all', checked === 'on');
    };

    const updateAnnotation = (index: number, annotation: SelectableAnnotation) => {
      const copy = props.annotations.slice();
      copy[index] = annotation;

      ctx.emit('annotations:update', copy);
    };

    return {
      updateAllAnnotations,
      updateAnnotation
    };
  }
});
</script>

<style module>
.checkbox {
  row-gap: 0;
  column-gap: 0;
  margin-bottom: -3rem;
  isolation: isolate;
}

.table {
  width: 100%;
}

.table > thead {
  background-color: var(--c-light-gray);
  border-bottom: 20px solid var(--c-white);
  font-weight: var(--fw-bold);
  font-size: var(--fs-metadata);
  text-transform: uppercase;
  text-align: left;
  color: var(--c-medium-black);
  white-space: nowrap;
}

.table > thead th, .checkbox {
  padding: 12px 20px;
}

@media screen and (max-width: 1420px) {
  .table > thead th, .checkbox {
    padding: 12px 8px;
  }
}

.table > tbody > tr + tr > td {
  border-top: 1px solid var(--c-dark-gray);
}
</style>
