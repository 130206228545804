<template>
  <button
    :aria-label="$t('header.account.label')"
    :class="$style.accountDropdown"
    data-menu_focus
    aria-haspopup="dialog"
    @click="openMenu"
  >
    <Icon name="user" />
  </button>

  <SiteMenu
    v-if="showMenu"
    :heading="$t('header.account.label')"
    headingIcon="user"
    :showBack="currentMenu !== 'main'"
    @back="() => changeMenu('main')"
    @close="closeMenu"
  >
    <UserMenuMain
      v-if="currentMenu === 'main'"
      @close="closeMenu"
      @menu="changeMenu"
    />
    <UserMenuAbout v-else-if="currentMenu === 'about'" />
    <UserMenuDownload v-else-if="currentMenu === 'download'" />
  </SiteMenu>
</template>

<script lang="ts">
import SiteMenu from 'app/components/dialogs/SiteMenu.vue';
import UserMenuAbout from 'app/components/UserMenuAbout.vue';
import UserMenuDownload from 'app/components/UserMenuDownload.vue';
import UserMenuMain from 'app/components/UserMenuMain.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { defineComponent, nextTick, ref } from 'vue';

type Menu = 'main' | 'download' | 'about';

export default defineComponent({
  components: {
    SiteMenu,
    UserMenuMain,
    UserMenuDownload,
    UserMenuAbout
  },
  emits: [
    'close'
  ],
  setup: () => {
    const showMenu = ref<boolean>(false);

    const currentMenu = ref<Menu>('main');
    const changeMenu = (val: Menu) => currentMenu.value = val;

    const openMenu = () => {
      showMenu.value = true;
    };

    const closeMenu = () => {
      showMenu.value = false;
      currentMenu.value = 'main';
    };

    useAppEvents({
      'open:download:settings': async () => {
        showMenu.value = true;
        await nextTick();
        changeMenu('download');
      }
    });

    return {
      changeMenu,
      closeMenu,
      currentMenu,
      openMenu,
      showMenu
    };
  }
});
</script>

<style module>
.account-dropdown {
  border-radius: 0.25rem;
  line-height: 0;
  color: var(--c-white);
}

.account-dropdown svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  stroke: none;
}

@media screen and (max-width: 1200px /* px-vp-hide-nav */) {
  .account-dropdown svg {
    color: var(--c-black);
    width: 1.75rem;
    height: 1.75rem;
  }
}
</style>
