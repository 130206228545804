<template>
  <section
    v-if="tags.length"
    :class="$style.container"
  >
    <h1 :class="$style.header">
      {{ $t('home.tags.header') }}
    </h1>
    <ol :class="$style.list">
      <li
        v-for="tag in tags"
        :key="tag.slug"
      >
        <router-link
          :to="{ name: 'TagDetails', params: { tagSlug: tag.slug } }"
          :class="$style.link"
        >
          {{ tag.name }}
        </router-link>
      </li>
    </ol>
    <router-link
      :to="{ name: 'Tags' }"
      :class="$style.link"
    >
      {{ $t('home.tags.viewAll') }}
    </router-link>
  </section>
  <EmptyState
    v-else
    :class="$style.emptyState"
    :link="helpLink"
    :isCompact="true"
    :header="$t('tags.empty.header')"
    :content="$t('tags.empty.content')"
    :linkLabel="$t('tags.empty.link')"
  />
</template>

<script lang='ts'>
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import { usePatron } from 'app/functions/use-patron';
import { Tag } from 'app/models/tag';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeTagList',
  components: {
    EmptyState
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.TAGS;
    const { tags: allTags } = usePatron();

    const tags = computed(() => {
      return (allTags.value)
        .filter(Tag.FILTER_FUNCTIONS.nonSystemTags)
        .sort(Tag.SORT_FUNCTIONS.recent)
        .slice(0, 10);
    });

    return {
      helpLink,
      tags
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  max-width: 25rem;

  @media screen and (max-width: @px-vp-max) {
    max-width: auto;
  }
}

.header {
  font-weight: @fw-bold;
  font-size: @fs-large-head;
  font-family: @ff-head;

  margin-bottom: 1.5rem;
}

.list {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid @c-darkest-gray;

  li + li {
    margin-top: 0.5rem;
  }
}

.link {
  color: @c-primary-blue;
  .pop-strict(@c-primary-blue-light);
  .focus-outline;
  max-width: max-content;
  .lineClamp;
}

.empty-state {
  text-align: left;
}
</style>
