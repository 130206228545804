  export function elementSelector(elem: Element): string {
    const names: string[] = [];
    let currentElem: Element | null = elem;
    while (currentElem) {
      const baseDocumentElement: HTMLElement = (currentElem.ownerDocument
        ? currentElem.ownerDocument.documentElement
        : document.documentElement);

      if (currentElem === baseDocumentElement) {
        names.unshift(currentElem.tagName);
        break;
      }

      if (currentElem.id) {
        names.unshift(`#${currentElem.id}`);
        break;
      }

      let c = 1;
      for (
        let e = currentElem;
        e.previousElementSibling;
        e = e.previousElementSibling, c++
      ) {
        // This is a dumb for loop
      }

      names.unshift(`${currentElem.tagName}:nth-child(${c})`);
      currentElem = currentElem.parentElement;
    }

    return names.join(' > ');
  }

  export function elementClosest(elem: Element, selector: string): Element | null {
    if (typeof elem.closest === 'function') {
      return elem.closest(selector);
    }

    let scope = elem;
    while (scope.parentElement) {
      scope = scope.parentElement;
    }
    const candidates = scope.querySelectorAll(selector);
    let currentElem: Element | null = elem;
    while (currentElem && currentElem.nodeType === 1) {
      for (let i = 0, ii = candidates.length; i < ii; ++i) {
        if (candidates[i] === currentElem) {
          return currentElem;
        }
      }
      currentElem = currentElem.parentElement;
    }

    return null;
  }
