<template>
  <footer :class="$style.footer">
    <div :class="$style.overdrive">
      <Icon
        :class="$style.odLogo"
        name="od-logo"
      />
      <p :class="$style.copyright">
        {{ $t('login.copyright', { year: currentYear}) }}
      </p>
    </div>
    <div :class="$style.legal">
      <a
        :href="privacyPolicyLink"
        target="_blank"
      >
        {{ $t('login.privacyPolicy') }}
      </a>
      <a
        :href="termsLink"
        target="_blank"
      >
        {{ $t('login.termsAndConditions') }}
      </a>
    </div>
  </footer>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'WelcomeFooter',
  setup: (_, ctx) => {
    const currentYear = computed(() => (new Date()).getFullYear());

    const privacyPolicyLink = Constants.PRIVACY_POLICY_PATH;
    const termsLink = Constants.TERMS_AND_COND_PATH;

    return {
      currentYear,
      privacyPolicyLink,
      termsLink
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.footer {
  background-color: var(--c-darkest-blue);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.overdrive {
  margin-right: 1rem;
}

.od-logo {
  display: block;
  fill: @c-white;
  height: 1.75rem;
  margin-bottom: 1.5rem;
}

.copyright {
  color: @c-white;
}

.legal {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1rem;

  a {
    .pop-text(@c-white);
    color: @c-white;

    &:focus {
      .focus-outline-dark;
    }
  }

  a:not(:last-of-type) {
    margin-right: 1rem;
  }
}
</style>
