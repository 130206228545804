<template>
  <Surface :class="$style.page">
    <div :class="$style.initialView">
      <WelcomeHeader
        :backButtonLabel="backButtonLabel"
        @back="$emit('back')"
      />
      <div :class="$style.content">
        <slot></slot>
      </div>
    </div>
    <WelcomeFooter />
  </Surface>
</template>

<script lang='ts'>
import Surface from 'app/components/Surface.vue';
import WelcomeFooter from 'app/components/WelcomeFooter.vue';
import WelcomeHeader from 'app/components/WelcomeHeader.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WelcomeView',
  components: {
    Surface,
    WelcomeHeader,
    WelcomeFooter
  },
  props: {
    backButtonLabel: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'back'
  ]
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.initial-view {
  background-color: var(--c-white);
  margin: 0 -1rem;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-white);
  min-height: 100vh;
}
</style>
