<template>
  <form
    :class="$style.form"
    @submit.prevent="onSubmit"
  >
    <label
      :id="filtersId"
      :class="$style.label"
    >
      {{ $t('searchThisTitle.labels.filters') }}
    </label>
    <div :class="$style.dropdowns">
      <FormSelect
        v-model="searchType"
        :options="searchTypes"
        :optionKey="(s: SearchItem) => s.id"
        optionLabel="label"
        :labelId="filtersId"
      />
      <FormSelect
        v-if="isInSet"
        v-model="searchScope"
        :options="searchScopes"
        :optionKey="(s: SearchItem) => s.id"
        optionLabel="label"
        :labelId="filtersId"
      />
      <p
        v-else
        :class="$style.scope"
      >
        {{ searchScope.label }}
      </p>
    </div>

    <label
      :id="queryId"
      :class="$style.label"
    >
      {{ $t('searchThisTitle.labels.search') }}
    </label>
    <FormSearch
      v-model="searchTerm"
      :label="$t('searchThisTitle.labels.query')"
      :labelId="queryId"
      :placeholder="$t(`searchThisTitle.searchPlaceholder.${item.mediaType === 'series' ? 'series' : 'title'}`)"
      :focusId="focusId"
      :hasPopup="includeHasPopup ? 'dialog' : undefined"
      :class="$style.search"
    />
  </form>
</template>

<script lang="ts">
import { SearchThisTitleScope, SearchThisTitleType } from 'app/base/hudson';
import { MediaType } from 'app/base/interfaces';
import FormSearch from 'app/components/FormSearch.vue';
import FormSelect from 'app/components/FormSelect.vue';
import { useI18n } from 'app/functions/use-i18n';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, ref, watch } from 'vue';

type SearchItem = {
  id: string;
  label: string;
};

export default defineComponent({
  name: 'SearchThisTitleForm',
  components: { FormSelect, FormSearch },
  props: {
    item: {
      type: Object as () => { mediaType: MediaType | 'series'; seriesId?: number },
      required: true
    },
    type: {
      type: String as () => SearchThisTitleType | undefined,
      default: undefined
    },
    scope: {
      type: String as () => SearchThisTitleScope | undefined,
      default: undefined
    },
    query: {
      type: String,
      default: ''
    },
    focusId: {
      type: String,
      default: undefined
    },
    includeHasPopup: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'search'
  ],
  setup: (props, ctx) => {
    const uuid = generateUUID();
    const filtersId = `stt-filters-${uuid}`;
    const queryId = `stt-query-${uuid}`;

    const isSet = computed(() => {
      return props.item.mediaType === 'series';
    });

    const isInSet = computed(() => {
      if (props.item.mediaType === 'series') { return false; }

      return typeof props.item.seriesId !== 'undefined';
    });

    const { t } = useI18n();
    const searchTypes = [
      {
        id: 'phrase',
        label: t('searchThisTitle.type.phrase')
      },
      {
        id: 'keyword',
        label: t('searchThisTitle.type.keyword')
      }
    ] as SearchItem[];
    const searchType = ref(searchTypes[props.type === 'keyword' ? 1 : 0]);

    const searchScopes = [
      {
        id: 'series',
        label: t('searchThisTitle.scope.series')
      },
      {
        id: 'title',
        label: t('searchThisTitle.scope.title')
      }
    ] as SearchItem[];
    const searchScope = ref(
      searchScopes[((!props.scope || props.scope === 'series') && (isSet.value || isInSet.value)) ? 0 : 1]
    );

    const searchTerm = ref(props.query);

    const onSubmit = () => ctx.emit('search', {
      type: searchType.value.id,
      scope: searchScope.value.id,
      query: searchTerm.value
    });

    watch(() => props.query, () => {
      searchTerm.value = props.query;
    });

    return {
      filtersId,
      isInSet,
      isSet,
      onSubmit,
      queryId,
      searchTerm,
      searchScope,
      searchScopes,
      searchType,
      searchTypes
    };
  }
});
</script>

<style module>
.form {
  scroll-margin-top: 3rem; /* room for header */
}

.label {
  color: var(--c-medium-black);
}

.dropdowns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  font-size: var(--fs-medium);
  gap: 0.5rem;
}

.dropdowns > * + * {
  flex-grow: 1;
}

.dropdowns,
.search {
  margin: 0.5rem 0;
}

.scope {
  background-color: var(--c-light-gray);
  color: var(--c-dark-black);
  padding: 1rem;
  border-radius: var(--form-border-radius);
}
</style>
