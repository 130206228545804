import { Constants } from './constants';
import { ScrollManager } from './scroll-manager';
import { GlobalEventCallback } from './events';
import { TapState } from './tap-interfaces';

export class TapManager {
  protected tapState: TapState | null = null;


  public isTapValid(evt: PointerEvent): boolean {
    if (!this.tapState) { return false; }
    // If we've scrolled, it's no longer a tap.
    if (ScrollManager.lastScrollTimestamp) {
      const sinceScrollMS = this.tapState.time - ScrollManager.lastScrollTimestamp;
      const tapLengthMS = (new Date()).getTime() - this.tapState.time;
      if (sinceScrollMS < tapLengthMS) {
        console.log(
          'Ignoring tap due to recent scroll.\n' +
          'sinceScrollMS: %s is less than tapLengthMS: %s',
          sinceScrollMS,
          tapLengthMS
        );

        return false;
      }
    }
    // If we've changed position (due to scroll?), it's no longer a tap.
    if (this.getScrollSignature(this.tapState.element) !== this.tapState.scrollSig) {
      console.log('Ignoring tap because scroll signature differs');

      return false;
    }
    // If the tap extends beyond a few pixels, it's no longer a tap.
    const xDelta = Math.abs(evt.pageX - this.tapState.coords.x);
    const yDelta = Math.abs(evt.pageY - this.tapState.coords.y);
    const maxContact = Math.max(xDelta, yDelta);
    if (Constants.TAP_MAX_CONTACT_DISTANCE < maxContact) {
      console.log('Ignoring tap because pointer moved too much');

      return false;
    }

    return true;
  }


  public handleTap(evt: PointerEvent, timestamp: number, callback: Function): void {
    // If the timestamp has changed, something else is handling this.
    if (!this.tapState || this.tapState.time !== timestamp) { return; }
    const valid = this.isTapValid(evt);
    this.cancelTap();
    if (valid) { callback(evt); }
  }


  public cancelTap(): void {
    if (!this.tapState) { return; }
    if (this.tapState.endTimer) {
      clearTimeout(this.tapState.endTimer);
    }
    this.tapState = null;
  }


  public throttleInvocation(
    callback: GlobalEventCallback<'click'>,
    interval: number
  ): GlobalEventCallback<'click'> {
    let previous = 0;

    return (evt) => {
      const now: any = new Date();
      const remaining = interval - (now - previous);
      if (remaining <= 0) {
        previous = now;
        callback.apply(this, [evt]);
      }
    };
  }


  public getScrollSignature(element: HTMLElement | SVGElement): number {
    let ss = 0;
    let currentElement: (HTMLElement | SVGElement) | null = element;
    while (currentElement && currentElement.style) {
      ss += currentElement.scrollTop + currentElement.scrollLeft;
      currentElement = currentElement.parentElement;
    }

    return ss;
  }
}
