import { Dictionary } from './dictionary';

/**
 * Iterate over an array or an object
 */
export function eachUntyped(oIterable: any, fnIterator: any) {
  each(oIterable, fnIterator);
}

export function each<T extends Element>(
  iterable: NodeListOf<T>,
  fun: (value: T, index: number) => void
): void;

export function each<T>(
  iterable: T[] | Iterable<T>,
  fun: (value: T, index: number) => void
): void;

export function each<T>(
  d: Dictionary<T>,
  fun: (key: string, item: T, index: number) => void
): void;

export function each<T, K extends Extract<keyof T, string>>(
  obj: T,
  fun: (key: K, value: T[K]) => void
): void;

export function each<T>(
  oIterable: T[] | Iterable<T> | Dictionary<T>,
  fnIterator: ((value: T, index: number) => void) | ((key: string, item: T, index: number) => void)
): void {
  if (!oIterable) {
    return;
  }

  if (typeof oIterable.length === 'number') {
    Array.prototype.forEach.call(oIterable, <((value: T, index: number) => void)>fnIterator);
  } else {
    const names = Object.keys(oIterable);
    each(names, (name, index) => {
      (<((key: string, item: T, index: number) => void)>fnIterator)
        (name, (<Dictionary<T>>oIterable)[name], index);
    });
  }
}

/**
 * You probably shouldn't be using this, and just use Promise.all
 */
export async function eachAsync<T>(
  iterable: T[] | Iterable<T>,
  fun: (value: T, index: number) => Promise<void>,
  thisArg?: unknown
): Promise<void> {
  if (!iterable || iterable.length === 0) {
    return;
  }

  const promises: Promise<void>[] = [];

  for (let i = 0; i < iterable.length; i++) {
    promises.push(fun.call(thisArg, iterable[i], i));
  }

  await Promise.all(promises);
}

interface Iterable<T> {
  length: number;
  [key: number]: T;
}


/**
 * Remove an item from an array, or a property from an object.
 *
 * Returns the value of the removed item, or an array of values
 * if more than one removed.
 */
export function excise<T>(iterable: T[], itemOrPredicate: T | ((item: T) => boolean)): T | T[] | null;
export function excise<T, U extends keyof T>(o: NonNullable<T>, property: U): T[U];
export function excise<T, U extends keyof T>(
  iterable: NonNullable<T> | T[], itemOrPredicate: T | ((item: T) => boolean)
): T | T[] | T[U] | null {
  return isArray(iterable)
    ? exciseArray(iterable, itemOrPredicate)
    : exciseProperty(iterable, <U><unknown>itemOrPredicate);
}

function itemOrPredicateIsPredicate<T>(
  itemOrPredicate: T | ((item: T) => boolean)
): itemOrPredicate is (item: T) => boolean {
  return typeof itemOrPredicate === 'function';
}

function exciseArray<T>(iterable: T[], itemOrPredicate: T | ((item: T) => boolean)): T | T[] | null {
  const predicate = itemOrPredicateIsPredicate(itemOrPredicate)
    ? itemOrPredicate
    : (i: T) => i === itemOrPredicate;

  if (iterable) {
    let excisions: T[] = [];
    let i = 0;

    while (i < iterable.length) {
      if (predicate(iterable[i])) {
        excisions = excisions.concat(iterable.splice(i, 1));
      } else {
        i += 1;
      }
    }

    return excisions.length === 1 ? excisions[0] : excisions;
  }

  return null;
}

function exciseProperty<T, U extends keyof T>(o: NonNullable<T>, property: U): T[U] {
  const result: T[U] = o[property];
  delete o[property];

  return result;
}


/**
 *  Return the last item in an array.
 */
export function last<T>(array: T[]): T {
  return array[array.length - 1];
}


/**
 *  Shuffle an array (i.e. randomize order of elements)
 */
export function shuffle<T>(array: T[]): T[] {
  let i = array.length;
  let rnd: number;
  let tmp: T;

  while (0 !== i) {
    rnd = Math.floor(Math.random() * i);
    i -= 1;
    tmp = array[i];
    array[i] = array[rnd];
    array[rnd] = tmp;
  }

  return array;
}


/**
 *  Invert an associative array (hash)
 */
export function invertObject(obj: Dictionary<string>) {
  const inversion: Dictionary<string> = {};

  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      inversion[obj[prop]] = prop;
    }
  }

  return inversion;
}

/**
 * Given a list of any depth, flattens it to a single-depth array.
 * If given an object that is not a list, returns a single-item array.
 */
export function flatten<T>(obj: T | T[], out: T[] = []): T[] {
  if (obj instanceof Array) {
    each(obj, (item) => { flatten(item, out); });
  } else {
    out.push(obj);
  }

  return out;
}

/**
 * Return true if object is an array, else false
 * @param obj
 */
export function isArray<T>(obj: T | T[]): obj is T[] {
  if (Array.isArray) { return Array.isArray(obj); }

  return Object.prototype.toString.call(obj) === '[object Array]';
}

/**
 * Return true if object is a list, else false
 * @param obj
 */
export function isList(obj: unknown): boolean {
  return (
    isArray(obj) ||
    obj instanceof NodeList ||
    obj instanceof HTMLCollection
  );
}

/**
 * Return an array of arrays, each the size (or smaller) of the chunksize
 * @param arr The array to chunk
 * @param chunkSize This size of each chunk
 * @example
 *  chunk([1,2,3], 1) => [[1],[2],[3]]
 *  chunk([1,2,3,4,5,6,7], 2) => [[1,2],[3,4],[5,6],[7]]
 *  chunk([1,2,3,4,5,6,7,8,9], 3) => [[1,2,3],[4,5,6],[7,8,9]]
 */
export function chunk<T>(arr: T[], chunkSize: number): T[][] {
  const chunks = [];
  let i = 0;

  while (i < arr.length) {
    chunks.push(arr.slice(i, i += chunkSize));
  }

  return chunks;
}
