<template>
  <section :class="$style.container">
    <h2 :class="$style.header">
      {{ $t('header.account.downloadSettings.label') }}
    </h2>

    <section :class="$style.section">
      <div>
        <h3>
          {{ $t('header.account.downloadSettings.autoDownloads.label') }}
        </h3>

        <p>
          {{ $t('header.account.downloadSettings.autoDownloads.explanation') }}
        </p>
      </div>

      <FormSwitch
        v-model="autoDownloads"
        ariaLabel="header.account.downloadSettings.autoDownloads.label"
      />
    </section>

    <section
      v-if="hasCellular"
      :class="[$style.section, autoDownloads ? '' : $style.disabled]"
    >
      <div>
        <h3>
          {{ $t('header.account.downloadSettings.wifiDownloads.label') }}
        </h3>

        <p>
          {{ $t('header.account.downloadSettings.wifiDownloads.explanation') }}
        </p>
      </div>

      <FormSwitch
        v-model="wifiDownloads"
        ariaLabel="header.account.downloadSettings.wifiDownloads.label"
      />
    </section>
  </section>
</template>

<script lang='ts'>
import { computed, defineComponent, ref } from 'vue';
import { APP } from 'app/base/app';
import FormSwitch from 'app/components/FormSwitch.vue';

export default defineComponent({
  name: 'UserMenuDownload',
  components: {
    FormSwitch
  },
  setup: () => {
    const hasCellular = APP.network.isPrecise;
    const autoDownloadRule = ref(APP.updateManager.autoDownloadRule);
    const autoDownloads = computed({
      get: () => autoDownloadRule.value?.key === 'everything',
      set: (v) => APP.updateManager.setAutoDownloadRule({ key: v ? 'everything' : 'nothing'})
    });
    const downloadQueueRule = ref(APP.updateManager.downloadQueueRule);
    const wifiDownloads = computed({
      get: () => downloadQueueRule.value === 'wifi',
      set: (v) => APP.updateManager.setDownloadQueueRule(v ? 'wifi' : 'go')
    });

    return {
      autoDownloads,
      hasCellular,
      wifiDownloads
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.container {
  section {
    padding: 1rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid @c-medium-black;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.header {
  font-size: @fs-small-head;
  font-weight: @fw-bold;
  margin-bottom: 1rem;
}

.section {
  display: grid;
  grid-template-columns: 1fr auto;

  h3 {
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  p {
    color: var(--c-darkish-black);
  }
}
</style>
