import { ActiveTitle } from 'app/base/active-title';
import { Bank } from 'app/base/bank/bank';
import { C } from 'app/base/common';
import env from 'app/base/env';
import { EventMap } from 'app/base/event-map';
import { FeatureFlags } from 'app/base/feature-flags';
import { Haptics } from 'app/base/haptics';
import { Nav } from 'app/base/nav';
import { Network } from 'app/base/network';
import { Orient } from 'app/base/orient';
import 'app/base/quirks';
import { Sage } from 'app/base/sage';
import { Scribe } from 'app/base/scribe';
import { Semaphore } from 'app/base/semaphore';
import { Sentry } from 'app/base/sentry';
import { Server } from 'app/base/server';
import { Services } from 'app/base/services/services';
import { Shell } from 'app/base/shell/shell';
import { ShellChooser } from 'app/base/shell/shell-chooser';
import { Tracking } from 'app/base/tracking/tracking';
import { UpdateManager } from 'app/base/updates/update-manager';
import { Version } from 'app/base/updates/version';
import { Library } from 'app/models/library';
import { Patron } from 'app/models/patron';
import { TitleCache } from 'app/models/title-cache';
import { Arena } from 'app/views/core/arena';
import { Gala } from 'lib/gala/gala';
import 'lib/gala/src/quirks';

class App {
  public events = new Gala<EventMap>();
  public client = {
    name: 'elrond',
    info: {
      spec: 12,
      version: new Version(),
      build: undefined as string | undefined,
      date:  undefined as string | undefined,
      url:  undefined as string | undefined
    }
  };

  public semaphore!: Semaphore;
  public flags!: FeatureFlags;
  public server!: Server;
  public services!: Services;
  public haptics!: Haptics;
  public network!: Network;
  public freshHorizon = 0;
  public updateManager!: UpdateManager;
  public nav!: Nav;
  public sage!: Sage;
  public scribe!: Scribe;
  public sentry!: Sentry;
  public orient!: Orient;
  public patron!: Patron;
  public arena!: Arena;
  public library!: Library;
  public bank!: Bank;
  public shell!: Shell;
  public titleCache!: TitleCache;
  public activeTitle!: ActiveTitle;
  public tracking!: Tracking;


  constructor() {
    console.log('[APP] constructing...');
  }


  public start(): void {
    this.freshHorizon = 0;
    window.onerror = this._onAppError().bind(this);
    window.onunhandledrejection = this._onAppError().bind(this);
    this._prepareSingletons();
  }


  public async refresh(): Promise<void> {
    console.log('[APP] Refreshing...');
    this.freshHorizon = C.epochMilliseconds();
    this.events.dispatch('app:refresh');
    if (this.library) {
      if (await this.library.catalog.freshen(true)) {
        // TODO: VUE
        // this.nav.refresh();
      }
    } else {
      // this.nav.refresh();
    }
  }


  public reload(href?: string): void {
    this.semaphore.set('client', 'unloading');
    href ? (location.href = href) : location.reload();
  }


  protected _onAppError(reportOnly = false) {
    return (message: Event | string, url?: string, line?: number, column?: number, error?: Error) => {
      const strMessage = C.isString(message) ? message : message.type;

      const errorInfo = {
        errorMessage: strMessage,
        errorSource: ''
      };
      if (error && error.stack) {
        errorInfo.errorSource = error.stack;
      } else if (!errorInfo.errorSource && url) {
        errorInfo.errorSource = url + (line ? ':' + line : '');
      }
      this.events.dispatch('app:error', errorInfo);
      setTimeout(this._onLaunchError.bind(this, errorInfo, reportOnly), 2000);
    };
  }


  protected _onLaunchError(errorInfo: { errorMessage: string; errorSource: string }, reportOnly = false) {
    this._revealClientContext();
    if (window.NAUTILUS) {
      window.NAUTILUS.onScriptError(errorInfo.errorMessage, errorInfo.errorSource, undefined, reportOnly);
    }
  }


  protected _prepareSingletons(): void {
    this.flags = new FeatureFlags();

    this.client.info.version = new Version();
    this.client.info.version.assign(env.VERSION_NUMBER);
    this.client.info.build = env.BUILD_SHA;
    this.client.info.date = env.BUILD_TIMESTAMP;
    this.client.info.url = env.ROOT_URI;

    this.semaphore = new Semaphore(document.documentElement);
    this.server = new Server();
    this.services = new Services();
    this.tracking = new Tracking(env); // Dependent on server / services
    this.network = new Network();
    this.nav = new Nav();
    this.titleCache = new TitleCache();
    this.activeTitle = new ActiveTitle();

    const shellChooser = new ShellChooser();
    this.shell = shellChooser.select();
    this.shell.start(this._onShellStarted.bind(this));
  }


  protected async _onShellStarted(bank: Bank) {
    this.bank = bank;
    this.updateManager = new UpdateManager();
    this.sage = new Sage();
    this.scribe = new Scribe();
    this.patron = new Patron();
    this.sentry = new Sentry(); // Dependent on APP.Patron
    this.orient = new Orient();
    this.library = await Library.load();
    this.titleCache.load();
    this.patron.load();
    this.haptics = new Haptics();
    this.arena = new Arena();
    if (this.library) {
      this.library.activate();
    }
    this.flags.loadOverrides();

    this.activeTitle.restore();
    const simulateCrash = this.bank.get('crash');
    this.bank.clear('crash');
    if (simulateCrash && this.flags.get('debug')) {
      throw new Error(simulateCrash);
    }
    setTimeout(this._announceReady.bind(this), 100);
  }


  protected _announceReady(): void {
    this.semaphore.set('client', 'ready');
    this.events.dispatch('environment:ready');
    this.shell.transmit('environment:ready');

    window.onerror = this._onAppError(true).bind(this);
    window.onunhandledrejection = this._onAppError(true).bind(this);

    this._revealClientContext();
  }


  private _revealClientContext(): void {
    const revealFn = () => {
      this.events.off(deferHandler);
      clearTimeout(revealTimer);
      this.shell.transmit('client:view:reveal');
    };

    const deferHandler = this.events.on('router:navigate', (_) => {
      clearTimeout(revealTimer);
      revealTimer = setTimeout(revealFn, 200);
    });

    let revealTimer = setTimeout(revealFn, 100);
  }
}

export const APP = new App();
