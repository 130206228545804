<template>
  <div
    :class="$style.form"
  >
    <p
      v-if="showCode"
    >
      {{ $t('login.email.codeInstructions') }}
    </p>

    <FormInput
      v-if="!showCode"
      v-model="email"
      type="email"
      :required="true"
      :autofocus="true"
      :label="$t('login.email.emailAddress')"
    />

    <FormInput
      v-else
      v-model="code"
      type="text"
      :required="true"
      :autofocus="true"
      :label="$t('login.email.code')"
    />

    <FormSubmitButton :label="$t(showCode ? 'login.form.submit' : 'login.email.continue')" />
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue';
import FormInput from 'app/components/FormInput.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';

export default defineComponent({
  name: 'EmailRegistration',
  components: {
    FormInput,
    FormSubmitButton
  },
  props: {
    username: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    },
    showCode: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'username',
    'password'
  ],
  setup: (props, ctx) => {
    const email = computed({
      get: () => props.username,
      set: (val) => ctx.emit('username', val)
    });

    const code = computed({
      get: () => props.password,
      set: (val) => ctx.emit('password', val)
    });

    return {
      code,
      email
    };
  }
});
</script>

<style lang='less' module>
.form > * + * {
  margin-top: 1.5rem;
}
</style>
