

  export function regExpEscape(str: string): string {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }


  export function regExpPunctuation(): string {
    return '\\u2000-\\u206F\\u2E00-\\u2E7F' + // Unicode punctuation
      regExpEscape('\\\'!"#$%&()*+,-./:;<=>?@[]^_`{|}~'); // ASCII
  }


  /**
   *  Converts fooBarBaz to foo_bar_baz.
   */
  export function snakeCase(str: string): string {
    return str.replace(/([a-z][A-Z])/g, snakify);
  }


  function snakify(match: string): string {
    return `${match[0]}_${match[1].toLowerCase()}`;
  }

