<template>
  <Surface />
</template>

<script lang="ts">
import Surface from 'app/components/Surface.vue';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { mapQueryToSeekLocation } from 'app/router/query-mapper';
import { computed, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import { APP } from '../../app/base/app';
import { OpenController } from '../../app/controllers/open-controller';

const openController = new OpenController();

export default defineComponent({
  name: 'Open',
  components: {
    Surface
  },
  props: {
    titleSlug: {
      type: String,
      required: true
    },
    a: {
      type: String,
      default: undefined
    },
    parent: {
      type: String,
      default: undefined
    },
    p: {
      type: String,
      default: undefined
    },
    q: {
      type: String,
      default: undefined
    },
    h: {
      type: String,
      default: undefined
    }
  },
  setup: (props) => {
    const { t } = useI18n();
    onMounted(() => {
      announceMessage(t('circ.open'));
    });

    const anchorID = computed(() => {
      if (props.a) { return props.a; }

      if (APP.activeTitle.isActiveTitle(props.titleSlug)) {
        return APP.activeTitle.anchorID;
      }

      return undefined;
    });

    const parentID = computed(() => {
      if (props.parent) { return props.parent; }

      if (APP.activeTitle.isActiveTitle(props.titleSlug)) {
        return APP.activeTitle.parentID;
      }

      return undefined;
    });

    const seekTo = computed(() => {
      const querySeekTo = mapQueryToSeekLocation({p: props.p, q: props.q, h: props.h});

      return querySeekTo;
    });

    const openTitle = () => {
      if (openController.enter(props.titleSlug, anchorID.value, parentID.value, seekTo.value)) {
        openController.fill();
        openController.focus();
      }
    };

    const open = () => {
      if (APP.shell.bifocal) {
        openTitle();
      } else {
        APP.events.on('arena:ready', () => openTitle());
      }
    };

    const close = () => {
      openController.blur();
      openController.exit();
    };

    watch([() => props.titleSlug], () => {
      open();
    }, { immediate: true });

    onUnmounted(() => close());
  }
});
</script>
