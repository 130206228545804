<template>
  <div
    :class="$style.container"
  >
    <h3 :class="$style.type">
      {{ $t(typeName) }}
    </h3>
    <div
      v-if="type === 'SeriesNewRelease'"
      :class="$style.text"
    >
      {{ $t('alert.details.seriesUpdated', { series: name }) }}
    </div>
    <div :class="$style.cardList">
      <TitleCard
        v-for="title in titles"
        :key="title.slug"
        :class="$style.card"
        :title="title"
      >
        <template #actions>
          <TitleActionButton
            :class="$style.alertAction"
            :title="title"
            :focus="true"
          />
        </template>
      </TitleCard>
    </div>
  </div>
</template>

<script lang='ts'>
import TitleActionButton from 'app/components/TitleActionButton.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import { TitleRecord } from 'app/models/title';
import { PropType, computed, defineComponent } from 'vue';


export default defineComponent({
  name: 'AlertNewReleaseDetails',
  components: {
    TitleActionButton,
    TitleCard
  },
  props: {
    name: {
      type: String,
      required: true
    },
    titles: {
      type: Object as PropType<TitleRecord[]>,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const typeName = computed(() => 'alert.type.'+props.type);

    return {
      typeName
    };
  }
});
</script>

<style module>

  .container {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'type'
      'text'
      'card';
    padding-top: 0rem;
    width: 100%;
  }

  .type {
    grid-area: type;
    font-weight: var(--fw-medium-bold);
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .text {
    grid-area: text;
    margin-bottom: 1rem;
  }

  .card-list {
    grid-area: card;
  }

  .card {
    height: fit-content;
    width: calc(100% - 2rem);
    --rem-cover-height: 9rem;
    margin-bottom: 1rem;
  }

  .card:last-child {
    margin-bottom: 0rem;
  }

  .alert-action {
    color: var(--c-primary-blue);
    font-weight: var(--fw-bold);
    width: max-content;
  }

  .alert-action a, .alert-action button {
    font-size: var(--fs-body);

    min-width: calc(var(--rem-cover-width) - 1rem);
    background-color: rgba(var(--c-primary-blue-rgb), .05);
    border-radius: var(--form-border-radius);
    border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
    padding: .5rem;
    color: var(--c-primary-blue);
  }
</style>
