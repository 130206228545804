import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbNavigation = _resolveComponent("BreadcrumbNavigation")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(_ctx.$style.header)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.actions)
    }, [
      _createVNode(_component_BreadcrumbNavigation, {
        class: _normalizeClass(_ctx.$style.breadcrumbs),
        title: _ctx.header,
        breadcrumbList: _ctx.breadcrumbs
      }, null, 8, ["class", "title", "breadcrumbList"]),
      _renderSlot(_ctx.$slots, "context")
    ], 2),
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.$style.title)
    }, _toDisplayString(_ctx.header), 3),
    _renderSlot(_ctx.$slots, "subheader", {}, () => [
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.$style.subtitle)
          }, _toDisplayString(_ctx.subtitle), 3))
        : _createCommentVNode("", true)
    ])
  ], 2))
}