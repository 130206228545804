import { C, Dictionary } from 'app/base/common';
import { APP } from './app';
import { ServerRequest } from './interfaces';

export class Server {
  public async fetchAsync<T>(fetchOptions: FetchOptions): Promise<T | null> {
    const request = this._transformToReqeust(fetchOptions);
    const result = await this.fetchWithTimeoutAsync(request, fetchOptions.timeout);

    if (result.ok) {
      if (fetchOptions.textResponse) {
        // TODO: Better way to handle this typing??
        return <Promise<T>>(result.text() as Promise<unknown>);
      }

      return <Promise<T>>result.json();
    }

    if (result.status === 404) {
      return null;
    }

    // TODO: Not throwing raw error so downstream catch blocks don't have
    // to think about if the body has been used or not yet.  Is that a good
    // idea or NOT???
    throw new FetchAsyncError({
      status: result.status,
      statusText: result.statusText,
      url: result.url,
      bodyText: await result.text()
    });
  }


  private async fetchWithTimeoutAsync(request: Request, timeout = 15000): Promise<Response> {
    let controller: AbortController;
    let signal: AbortSignal;

    if (window.hasOwnProperty('AbortController')) {
      // If the browser doesn't support the abort controller, we will
      // still timeout the promise but it doesn't actually cancel
      // the network request.
      // Also of note -- Safari defined the AbortController
      // in Safari 11.1, but just as a shim.  It doesn't actually do
      // anything until Safari 12.1.
      controller = new AbortController();
      signal = controller.signal;
    }

    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new FetchAsyncError({
          status: 0,
          statusText: `Request timed out after ${timeout}ms`,
          url: request.url,
          bodyText: 'timeout'
        }));
        if (controller) {
          controller.abort();
        }
      }, timeout);
      fetch(request, { signal: signal })
        .then((result) => {
          clearTimeout(timer);
          resolve(result);
        }, reject);
    });
  }


  protected _transformToReqeust(fetchOptions: FetchOptions): Request {
    const requestInit: RequestInit = {
      method: fetchOptions.method || 'GET',
      headers: fetchOptions.headers || {},
      credentials: fetchOptions.credentials ? 'include' : 'omit',
      body: fetchOptions.body
    };

    if (APP.shell && APP.shell.has('network:request-override')) {
      if (this._isOverridable(fetchOptions.url, requestInit)) {
        return this._overridenRequest(fetchOptions.url, requestInit);
      }
    }

    return new Request(fetchOptions.url, requestInit);
  }


  protected _isOverridable(url: string, requestInit: RequestInit): boolean {
    // Only requests to the same origin are overridable
    if (url.indexOf('/') !== 0 && url.indexOf(location.origin) !== 0) {
      return false;
    }

    // Always override if non-GET
    if (requestInit.method !== 'GET') {
      return true;
    }

    // Always override if there are custom headers.
    for (const _ in requestInit.headers) {
      return true;
    }

    return false;
  }


  protected _overridenRequest(url: string, requestInit: RequestInit): Request {
    const overrideOptions = {
      method: requestInit.method,
      headers: requestInit.headers,
      body: requestInit.body
    };
    let requestUrl = url;
    requestUrl += requestUrl.match(/\?/) ? '&' : '?';
    requestUrl += `_override=${C.Base64.encode(JSON.stringify(overrideOptions))}`;

    return new Request(requestUrl);
  }


  public toRequestOptions(urlOrOptions: string | FetchOptions): FetchOptions {
    if (C.isString(urlOrOptions)) {
      return { url: urlOrOptions };
    }

    return C.absorb(urlOrOptions, {});
  }


  protected _isSuccessful(req: ServerRequest): boolean {
    if (req.responseText.match(/^<UWP_REQUEST_FAILURE/)) { return false; }
    if (req.status >= 300) { return false; }
    if (req.status < 200) { return false; }

    return true;
  }
}

export class FetchAsyncError extends Error {
  public response: FetchAsyncErrorResponse;
  public responseCode: string;


  constructor(response: FetchAsyncErrorResponse) {
    super(response.statusText);
    this.response = response;

    if (response.bodyText) {
      try {
        const formattedResp = JSON.parse(response.bodyText);
        if (formattedResp && formattedResp.result) {
          this.responseCode = formattedResp.result;
        }
      } catch (ex) {
        console.warn('Unexpected error parsing fetch response.', ex);
      }
    }
  }
}

export interface FetchAsyncErrorResponse {
  status: number;
  statusText: string;
  bodyText: string;
  url: string;
}

export type HttpMethod =  'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'HEAD';

export interface FetchOptions {
  url: string;
  method?: HttpMethod;
  headers?: any;
  body?: any;
  credentials?: boolean;
  /**
   * Report failures to Sage.  Default: true.
   */
  reportFailures?: boolean;
  /**
   * If status code is 400+ and reportFailures is true, you can list
   * status codes that you don't want to report back to Sage.
   * Default: [ 404 ]
   */
  ignoreStatusCodes?: number[];
  timeout?: number;
  retries?: number;
  textResponse?: boolean;
}

export interface RequestDocument {
  req: ServerRequest;
  url: string;
  method: string;
  headers: Dictionary<string>;
  body: string | null;
  credentials: any;
  onFailure: Function;
  onSuccess: Function;
  timeout: number;
  timedOut: boolean;
  reportFailures: boolean;
  ignoreStatusCodes: number[];
}
