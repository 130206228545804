import { C } from 'app/base/common';
import { onBeforeUnmount, onMounted, Ref, ref } from 'vue';

export const useClamped = (elRef: Ref<HTMLElement | null>) => {
  const clamped = ref(false);

  const checkClamped = () => {
    clamped.value = !!elRef.value && C.isOverflowing(elRef.value);
  };

  let observer: IntersectionObserver | null = null;
  if ('IntersectionObserver' in window) {
    observer = new IntersectionObserver((entries, o) => {
      entries.forEach((e) => {
        if (e.isIntersecting) {
          checkClamped();
          o.disconnect();
        }
      });
    });
  }

  onMounted(() => {
    checkClamped();

    if (elRef.value && !clamped.value) {
      observer?.observe(elRef.value);
    }

    window.addEventListener('resize', checkClamped);
    window.addEventListener('orientationchange', checkClamped);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkClamped);
    window.removeEventListener('orientationchange', checkClamped);
    observer?.disconnect();
  });

  return { clamped };
};
