import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeriesCover = _resolveComponent("SeriesCover")!
  const _component_SeriesCardOptions = _resolveComponent("SeriesCardOptions")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    item: _ctx.cardItem,
    link: _ctx.link
  }, {
    cover: _withCtx(() => [
      _createVNode(_component_SeriesCover, {
        series: _ctx.series,
        width: _ctx.compact ? _ctx.seriesCoverSizes.seriesCardCompact : _ctx.seriesCoverSizes.seriesCard
      }, null, 8, ["series", "width"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_SeriesCardOptions, {
        series: _ctx.series,
        link: _ctx.link
      }, null, 8, ["series", "link"])
    ]),
    _: 1
  }, 8, ["item", "link"]))
}