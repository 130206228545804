import { ComputedRef, watch } from 'vue';
import { useAppEvents } from 'app/functions/use-app-events';

export function useModalDialog(
  shown: ComputedRef<boolean>,
  dialogElementId: string
) {
  const { dispatch } = useAppEvents();

  watch(shown, (to) => {
    to
      ? dispatch('dialog:show', dialogElementId)
      : dispatch('dialog:hide', dialogElementId);
  });
}
