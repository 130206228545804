import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoutDialog = _resolveComponent("PopoutDialog")!

  return (_openBlock(), _createBlock(_component_PopoutDialog, {
    ref: "popout",
    reference: _ctx.reference,
    headerLabel: _ctx.headerLabel,
    display: _ctx.display,
    announcement: _ctx.announcement,
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit(_ctx.closeEvent)))
  }, {
    default: _withCtx(() => [
      (_ctx.display === 'menu')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.menu)
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.$style.action),
              onClick: _cache[0] || (_cache[0] = () => _ctx.confirmationRequired ? _ctx.showConfirmation() : _ctx.closeDialog(_ctx.actionEvent))
            }, _toDisplayString(_ctx.menuActionLabel), 3),
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.$style.action),
              "aria-haspopup": "dialog",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeDialog('download:settings')))
            }, _toDisplayString(_ctx.$t('title.downloadIndicator.configButton')), 3)
          ], 2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.confirmation)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.confirmationMessage), 1),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.confirmationActions)
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style.action),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog(_ctx.actionEvent)))
              }, _toDisplayString(_ctx.$t('title.downloadIndicator.confirmButton')), 3),
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style.action),
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showMenu && _ctx.showMenu(...args)))
              }, _toDisplayString(_ctx.$t('title.downloadIndicator.cancelButton')), 3)
            ], 2)
          ], 2))
    ], undefined, true),
    _: 1
  }, 8, ["reference", "headerLabel", "display", "announcement"]))
}