import { APP } from 'app/base/app';
import { AuthError, AuthErrorWithCustom, AuthResult } from 'app/base/auth-definitions';
import { C } from 'app/base/common';
import env from 'app/base/env';
import { LoginFormSubmission } from 'app/base/interfaces';
import { SentrySyncResponse } from 'app/base/sentry';
import { FetchAsyncError } from 'app/base/server';
import { Library } from 'app/models/library';

export async function authenticate(
  library: Library,
  data: LoginFormSubmission,
  origination: string | undefined
): Promise<AuthResult | undefined> {
    switch (data.type) {
      case 'Local':
        return authWithErrorHandling(data, (d) => APP.sentry.localAuth(d, library.websiteId));
      case 'Guest':
        return authWithErrorHandling({ ...data, websiteId: library.websiteId }, (d) => APP.sentry.ipAuth(d));

      case 'EmailRegistration': {
        // For email registration, the password represents
        // the verification code we send them.
        // If this is called without a password, then it's the
        // first request for a code.
        // If this is called with a password, then we can
        // fully authenticate the user.

        const authFunction = data.password
          ? (d: LoginFormSubmission & { websiteId: number }) =>
            APP.sentry.emailRegistrationSubmitCode(d.username!, d.websiteId, d.ilsName, d.password!)
          : (d: LoginFormSubmission & { websiteId: number }) =>
            APP.sentry.emailRegistrationRequestCode(d.username!, d.websiteId, d.ilsName);

        const result = await authWithErrorHandling({ ...data, websiteId: library.websiteId }, authFunction);

        if (!data.password && result.state === 'success') {
          return {
            state: 'code'
          };
        }

        return result;
      }

      case 'External': {
        const externalAuthScheme = APP.shell.capability('ui:oauth');

        const url = externalAuthUrl(
          env.ROOT_URI,
          library.baseKey,
          library.websiteId,
          data.ilsName,
          origination,
          externalAuthScheme
        );

        if (externalAuthScheme) {
          APP.shell.transmit({
            name: 'ui:oauth:request',
            url
          });
        } else {
          location.assign(url);
        }

        break;
      }

      default:
        throw new Error('Unexpected authentication type.');
    }

}


export async function completeExternalAuth(continuation: string): Promise<AuthResult> {
  const responseParameters = C.parseQueryString(continuation.replace(/^[^\?]*/, ''));
  if (responseParameters?.error) {
    return {
      state: 'error',
      code: AuthError.Custom,
      message: responseParameters.ilsMessage || responseParameters.error
    };
  }

  return authWithErrorHandling(continuation, (c) => APP.sentry.completeExternalAuthentication(c));
}


async function authWithErrorHandling<T>(
  data: T,
  auth: (d: T) => Promise<SentrySyncResponse>
): Promise<AuthResult> {
  try {
    const response = await auth(data);

    return {
      state: 'success',
      response
    };
  } catch (ex) {
    if (ex instanceof FetchAsyncError) {
      if (ex.response.status === 400
       || ex.response.status === 401
       || ex.response.status === 403
      ) {
        const error = parseErrorMessage(ex);

        return {
          state: 'error',
          ...error
        };
      }
    }

    return {
      state: 'error',
      code: AuthError.Unknown,
      data: ex
    };
  }
}


function externalAuthUrl(
  rootUri: string,
  libraryKey: string,
  websiteId: number,
  ilsName: string,
  origination: string | undefined,
  externalAuthScheme: string
): string {
  const path = C.parameterizeURL(`auth/link/${websiteId}`, {
    ils: ilsName,
    continuation: continuationUrl(
      rootUri,
      libraryKey,
      origination,
      externalAuthScheme
    )
  });

  return APP.services.sentry.pathToURL(path);
}


function continuationUrl(
  rootURI: string,
  libraryKey: string,
  origination: string | undefined,
  externalAuthScheme?: string
): string {
  const schemePattern = /^[^:]+/;
  const url = C.parameterizeURL(`${rootURI}/welcome/login/${libraryKey}/callback`, {
    origination
  });

  return externalAuthScheme
    ? url.replace(schemePattern, externalAuthScheme)
    : url;
}


function parseErrorMessage(ex: FetchAsyncError): AuthErrorWithCustom {
  const errorBody = JSON.parse(ex.response.bodyText);

  if (errorBody?.upstream?.errorCode?.match(/CaptchaRequired|CaptchaFailed/)) {
    return { code: AuthError.CaptchaRequired };
  }

  if (errorBody?.upstream?.errorCode?.match(/UserDenied/)) {
    return { code: AuthError.InvalidCredentials };
  }

  if (ex.responseCode === 'idc_registration_ineligible') {
    return { code: AuthError.RegistrationIneligible };
  }

  if (ex.responseCode === 'idc_registration_invalid_setup_code') {
    return { code: AuthError.RegistrationInvalidCode };
  }

  const message = errorBody.upstream.userExplanation;

  const userFacingErrorCodes = /ExcessiveFines|OverdueItems|ExpiredCard|LostCard|StolenCard|PrivilegesRevoked|InvalidAccount|RecordDeleted/g;
  const errorCode = message?.match(userFacingErrorCodes);
  if (errorCode?.[0]) {
    return { code: errorCode[0] };
  }

  if (message?.match(/InvalidUserPassword/)) {
    return { code: AuthError.InvalidCredentials };
  }

  if (message?.match(/UnsupportedAuthType/)) {
    return { code: AuthError.Unsupported };
  }

  if (message && !message.match(/Sentry::Opas/)) {
    return {
      code: AuthError.Custom,
      message
    };
  }

  return {
    code: AuthError.Unknown,
    data: message || JSON.stringify(errorBody)
  };
}
