
import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';
import { RouteName } from 'app/router/constants';
import router from 'app/router/router';
import { DataEvent } from '../../lib/gala/src/events';
import { RouteEvent } from './event-map';

export type Timeline = 'onboarding' | 'home' | 'browse' | 'mybooks' | 'modal' | 'notes' | 'tags' | 'export' | 'shelf' | 'activities' | 'ntc' | 'mysubscriptions';

export class Nav {
  private _length = 0;

  public get length() { return this._length; }


  constructor() {
    const onNavFn = (evt: DataEvent<RouteEvent>) => this._onNavMessage(evt);
    APP.events.on('msg:nav:back', onNavFn);
    APP.events.on('msg:nav:home:root', onNavFn);
    APP.events.on('msg:nav:library', onNavFn);
    APP.events.on('msg:nav:mybooks', onNavFn);
    APP.events.on('msg:nav:notes', onNavFn);
    APP.events.on('msg:nav:tags', onNavFn);
    APP.events.on('msg:nav:export', onNavFn);
    APP.events.on('msg:nav:library:root', onNavFn);
    APP.events.on('msg:nav:mybooks:root', onNavFn);
    APP.events.on('msg:nav:notes:root', onNavFn);
    APP.events.on('msg:nav:tags:root', onNavFn);
    APP.events.on('msg:nav:export:root', onNavFn);
    APP.events.on('msg:nav:title', onNavFn);
    APP.events.on('msg:nav:go', onNavFn);
    // Reroute to a nav go message
    APP.events.on('msg:title:open:background', (evt) => {
      const newEvent = { type: 'msg:nav:go', m: { path: evt.m.path} };
      onNavFn(newEvent as DataEvent<RouteEvent>);
    });

    APP.events.on('router:navigate', () => this._length++);

    APP.events.on('msg:client:view:background', () => {
      if (this._length < 0) {
        this._length = 0;
      }
    });
  }


  /**
   * Routes to a path. This subpaths off of the library path.
   * e.g. APP.nav.go('mybooks') routes to /library/mockingbird/mybooks.
   *
   * @param path The path to go to
   * @param isReplace Should we replace the last history entry instead of pushing a new entry
   */
  public go(path: string, isReplace?: boolean): void {
    let replaceRoute = isReplace;
    let fullPath = path.startsWith('/') ? path.substring(1) : path;
    if (APP.library && !fullPath.startsWith('library/')) {
      fullPath = `library/${APP.library.baseKey}/${fullPath}`;
    }
    fullPath = '/' + fullPath;
    if (router.currentRoute.value.fullPath.indexOf('open') === fullPath.indexOf('open')) {
      replaceRoute = true;
    }
    const route = () => replaceRoute ? router.replace(fullPath) : router.push(fullPath);

    route().catch((_) => Promise.resolve());
  }


  /**
   * Routes to a root path. This does not subpath off the library path.
   *
   * @param path The path to go to
   * @param isReplace Should we replace the last history entry instead of pushing a new entry
   */
  public goRoot(path: string, isReplace?: boolean) {
    const fullPath = path.startsWith('/') ? path : `/${path}`;
    const route = () => isReplace ? router.replace(fullPath) : router.push(fullPath);

    route().catch((_) => Promise.resolve());
  }


  /**
   * Navigates to the previous path in the active timeline.
   * Returns true if can navigate back, else false.
   */
  public back(): void {
    // Why -2?
    // We listen for each router navigation and
    // add one to the length for that.
    // However, navigating back is also a router
    // navigation, so we're taking one step forward
    // and two steps back.
    this._length -= 2;
    if (this._length < 0) {
      this._length = 0;
    }

    APP.events.dispatch('nav:back:close');
    router.go(-1);
  }


  public route404(path: string, reason: string): void {
    console.error(`[ROUTER] 404 path="${path}" ${Constants.text(reason)}`);
    APP.nav.go('404', true);
  }


  protected _onNavMessage(evt: DataEvent<RouteEvent>): void {
    const match = evt.type.match(/nav:(.+(:root)?)$/);
    if (!match) { return; }
    const dest = match[1];
    if (dest === 'back') {
      this._onNavBackMessage(evt);
    } else if (dest === 'title') {
      if (APP.activeTitle.isActive()) {
        this.go(`open/${APP.activeTitle.title.slug}`);
      }
    } else if (dest === 'home:root') {
      this.go('');
    } else if (dest === 'library:root') {
      this.go('browse');
    } else if (dest === 'mybooks:root') {
      this.go('mybooks');
    } else if (dest === 'notes:root') {
      this.go('notes');
    } else if (dest === 'tags:root') {
      this.go('tags');
    } else if (dest === 'tags:export') {
      this.go('export');
    } else if (dest === 'go' && evt.m.path) {
      if(evt.m.path.indexOf('open') >= 0) {
        APP.updateManager.auto = true;
      }

      this.go(evt.m.path);
    } else {
      this.go(dest);
    }
  }


  protected _onNavBackMessage(evt: DataEvent<RouteEvent>): void {
    if (this._length <= 1) {
      if (evt.m.source === 'bifocal') {
        router.push({
          name: RouteName.TitleDetails,
          params: {
            titleSlug: APP.activeTitle.parentID || APP.activeTitle.title.slug
          }
        });
      } else if (APP.shell?.info?.platform && APP.shell?.info?.platform !== 'PWA') {
        APP.shell.transmit('nav:back:noop');
      }
    } else {
      APP.nav.back();
    }
  }
}
