import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotFound = _resolveComponent("NotFound")!

  return (_openBlock(), _createBlock(_component_NotFound, {
    headerText: _ctx.$t('error.route.legacyRedirect.header')
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.$style.message),
        innerHTML: _ctx.$t(
        'error.route.legacyRedirect.instructions',
        {
          a: (help) => {
            return `<a href='https://help.lexisdl.com/3000.htm'>${help}</a>`;
          },
          b: (homePage) => {
            return `<a href='/'>${homePage}</a>`
          }
        }
      )
      }, null, 10, _hoisted_1)
    ], undefined, true),
    _: 1
  }, 8, ["headerText"]))
}