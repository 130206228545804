import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { ThunderCollectionDefinition, ThunderGeneratedCollectionDefinition } from '../base/thunder';
import { Library } from './library';
import { ListItemType, SeriesList, TitleList } from './list';

export class Lists {
  public library: Library;
  public all: (TitleList | SeriesList)[];
  public active: TitleList | SeriesList;
  public activeAddress: string;
  private readonly _listDefinitions: Dictionary<Dictionary<ListDefinition>>;
  private _cache: {
    set: Dictionary<SeriesList>;
    title: Dictionary<TitleList>;
  };


  constructor(library: Library) {
    this.library = library;
    this._listDefinitions = {};
    this._reset();
    APP.events.on('app:refresh', () => this._reset());
  }


  public fetchTitle(address: string): TitleList {
    return this.fetch('title', address) as TitleList;
  }


  public fetchSet(address: string): SeriesList {
    return this.fetch('set', address) as SeriesList;
  }


  public fetch(type: ListItemType, address: string): TitleList | SeriesList {
    return this._lookup(type, address) || this.add(this.make(type, address), address);
  }


  public make(type: ListItemType, address: string): TitleList | SeriesList {
    return this._lookup(type, address) ||
      (type === 'title' ? new TitleList(this.library, address) : new SeriesList(this.library, address));
  }


  public add(list: TitleList | SeriesList, address: string): TitleList | SeriesList {
    if (this.all.indexOf(list) < 0) {
      this.all.push(list);
      this._cache[list.itemType][address || list.params.address()] = list;
    }

    return list;
  }


  public activate(list: TitleList | SeriesList): TitleList | SeriesList {
    if (this.active !== list) {
      this.activeAddress = list.params.address();
      this.active = list;
    }

    return this.active;
  }


  public deactivate(list: TitleList | SeriesList): void {
    if (this.active.params.address() === list.params.address()) {
      this.activeAddress = null;
      this.active = null;
    }
  }


  public find(conds): (TitleList | SeriesList)[] {
    if (conds.source && conds.id && conds.format) {
      console.warn('Use lists.fetch, not lists.find for this:', conds);
    }
    const results: (TitleList | SeriesList)[] = [];
    const sep = ',';
    const cSource = conds.source;
    const cIds = conds.id && conds.id.join ? conds.id.join(sep) : conds.id;
    let cFormats = conds.format;
    if (cFormats === '*') {
      cFormats = '';
    }
    if (cFormats && cFormats.join) {
      cFormats = cFormats.join(sep);
    }
    C.each(this.all, (list) => {
      if (C.isString(cSource)) {
        if (cSource !== list.params.source) {
          return;
        }
      }
      if (C.isString(cIds)) {
        let listId = list.params.id;
        if (list.params.source === 'subject') {
          listId = list.params.subjects.join(sep);
        }
        if (cIds !== listId) {
          return;
        }
      }
      if (C.isString(cFormats)) {
        if (cFormats !== list.params.formats.join(sep)) {
          return;
        }
      }
      results.push(list);
    });
    results.sort(this._compareLists);

    return results;
  }


  public listDefinition(source: string, id: string): ListDefinition {
    if (this._listDefinitions[source]) {
      const defId = id + '';

      return this._listDefinitions[source][defId];
    }
  }


  public addListDefinition(source: string, listDef: ThunderCollectionDefinition): ListDefinition {
    const def = C.jsonClone(listDef);
    if (source === 'curated' && !this._isGeneratedCollection(def)) {
      C.absorb(C.excise(def, 'curatedCollectionDetails'), def);
    } else if (source === 'generated' && this._isGeneratedCollection(def)) {
      C.absorb(C.excise(def, 'generatedCollectionDetails'), def);
    }
    const defId = def.id + '';
    this._listDefinitions[source] = this._listDefinitions[source] || {};

    return (this._listDefinitions[source][defId] = def as unknown as ListDefinition);
  }


  protected _isGeneratedCollection(coll: ThunderCollectionDefinition): coll is ThunderGeneratedCollectionDefinition {
    return (coll as ThunderGeneratedCollectionDefinition).generatedCollectionDetails !== undefined;
  }


  protected _compareLists(a, b) {
    if (typeof a.priority === 'number' || typeof b.priority === 'number') {
      const aPri = typeof a.priority === 'number' ? a.priority : Infinity;
      const bPri = typeof b.priority === 'number' ? b.priority : Infinity;

      return aPri - bPri;
    }

    return a.name < b.name ? -1 : 1;
  }


  protected _reset(): void {
    this.all = [];
    this._cache = { set: {}, title: {}};
  }


  protected _lookup(type: ListItemType, addr: string): TitleList | SeriesList {
    if (addr === this.activeAddress && this.active.itemType === type) {
      return this.active;
    }

    return this._cache[type][addr];
  }
}

export interface ListDefinition {
  name: string;
  description: string;
  showOnlyAvailable?: boolean;
  sortByAvailability?: boolean;
  /** Deprecated method of setting mediaTypes
   * @deprecated use mediaTypes (plural) instead
   */
  mediaType?: string;
  mediaTypes?: string[];
  sortBy?: string;
  subjects?: string[];
  publisherEntityId?: number;
  language?: string;
  maxItems?: number;
  itemType: 'Title' | 'Series';
  isOrdered: boolean;
}
