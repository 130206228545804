import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["aria-label", "data-filter-category"]
const _hoisted_2 = {
  key: 1,
  class: "badge"
}
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextFilter = _resolveComponent("TextFilter")!
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TextFilter, {
      modelValue: _ctx.textFilter,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textFilter) = $event)),
      hideLabel: true,
      class: _normalizeClass(_ctx.$style.textFilter)
    }, null, 8, ["modelValue", "class"]),
    (_ctx.filteredOptions.length)
      ? (_openBlock(), _createElementBlock("ol", _mergeProps({ key: 0 }, _ctx.$attrs, {
          class: _ctx.$style.scroll,
          role: "list",
          "aria-label": _ctx.$t('filters.ariaLabels.filterList'),
          "data-filter-category": _ctx.category,
          onKeydown: [
            _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.setFocus(0)), ["prevent"]), ["home"])),
            _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.setFocus(_ctx.filteredOptions.length-1)), ["prevent"]), ["end"]))
          ]
        }), [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option) => {
            return (_openBlock(), _createElementBlock("li", {
              key: option.id
            }, [
              _createVNode(_component_FormCheckbox, {
                class: _normalizeClass(_ctx.$style.item),
                modelValue: _ctx.selected(option.id) ? 'on' : 'off',
                label: option.name,
                showLabel: true,
                ariaLabel: option.count ? _ctx.$t('filters.ariaLabels.nameWithCount', { NAME: option.name, N: option.count }) : option.name,
                "onUpdate:modelValue": ($event: any) => (_ctx.$emit('checked', option))
              }, {
                default: _withCtx(() => [
                  (_ctx.optionType === 'color')
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style.indicator),
                        style: _normalizeStyle(`--highlight-color: var(--highlight-color-${option.id})`)
                      }, null, 6))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(option.name) + " ", 1),
                  (option.count)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(option.count), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["class", "modelValue", "label", "ariaLabel", "onUpdate:modelValue"])
            ]))
          }), 128))
        ], 16, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style.empty),
          "aria-label": _ctx.$t('filters.ariaLabels.filterList')
        }, _toDisplayString(_ctx.$t('filters.noResults')), 11, _hoisted_3))
  ], 64))
}