<template>
  <button
    type="button"
    :class="$style.triggerButton"
    :aria-label="$t('search.triggerButton')"
    @click="$emit('click')"
  >
    <div :class="$style.grid">
      <span :class="$style.triggerInputContainer">
        <Icon
          :class="$style.searchIcon"
          name="search"
        />
        <span
          v-if="query"
          :class="[$style.triggerText, $style.query]"
        >
          {{ query }}
        </span>
        <span
          v-else
          :class="[$style.triggerText, $style.placeholder]"
        >
          {{ $t('search.placeholder') }}
        </span>
      </span>
      <span :class="$style.searchButton">
        {{ $t('search.searchButton') }}
      </span>
    </div>
  </button>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    query: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: [
    'click'
  ]
});
</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  .trigger-button {
    line-height: 1.75rem;
    background-color: var(--c-dark-blue);
    border-radius: .5rem;
  }

  .grid {
    display: grid;
    grid-template-columns: minmax(5rem, 1fr) auto;
  }

  .trigger-input-container {
    display: grid;
    grid-template-columns: auto minmax(3rem, 1fr);
    padding: 0.5rem;
  }

  .search-icon {
    line-height: 0;
    width: 1.75rem;
    height: 1.75rem;
    fill: @c-white;
    padding-right: 0.5rem;
  }

  .trigger-text {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    font-weight: @fw-medium;
    font-size: @fs-med;
    font-family: @ff-sans;
  }

  .query {
    color: @c-white;
  }

  .placeholder {
    color: var(--c-white);
    opacity: .75;
  }

  .search-button {
    font-size: @fs-body;
    font-family: @ff-sans;
    font-weight: @fw-medium;
    color: @c-white;
    text-shadow: none;
    text-transform: uppercase;

    padding: 0.5em 1em;

    border-radius: 0 .5rem .5rem 0;
    border: none;

    background: @c-primary-red;
  }
</style>
