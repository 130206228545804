import { APP } from 'app/base/app';
import { BankMigrator } from 'app/base/bank/bank-migrator';
import { BankScopeDomain } from 'app/base/bank/bank-scope-domain';
import { C } from 'app/base/common';
import { BifocalView } from 'app/views/open/bifocal-view';
import { Quirks } from 'lib/quirkbase/quirkbase';
import { BifocalViewProxy } from '../../views/open/bifocal-view-proxy';
import { Bank } from '../bank/bank';
import { EventId, IssuableEventId } from '../event-map';
import { Shell, ShellInfo, ShellTraits } from './shell';

export class ShellNone implements Shell {
  public bifocal!: BifocalView | BifocalViewProxy;
  public info: ShellInfo = {};
  public bridge: any = {};
  public traits?: ShellTraits;

  protected _onBankCallback?: (bank: Bank) => void;


  constructor() {
    this._detectCapabilityClasses();
    this.info.type = 'None';
  }


  /**
   * Given a string that represents a question whether the shell
   * does something (or has some quirk, etc), return a true or
   * false response.
   */
  public has(capability: string): boolean {
    return this.capability(capability) ? true : false;
  }


  public capability(capability: string): any {
    // A very rough heuristic for whether or not to move the furniture
    // when a text input is focused, so that it is visible above the soft
    // keyboard on these devices:
    if (capability === 'soft-keyboard') {
      return navigator.userAgent.match(/Android|iOS/) ? true : false;
    }

    return null;
  }


  /**
   * Dispatches the given message object to the given 'dest' --
   * or if no given dest, to the shell dest.
   */
  public transmit(object: any): void {
    const messageObject = C.isString(object) ? { name: object } : object;

    if (!C.isString(messageObject.dest)) {
      messageObject.dest = 'shell';
    }
    this._dispatchMessageObject(messageObject);
  }


  public immerse(value: boolean): void {
    // noop
  }


  public start(onStartedCallback: (bank: Bank) => void): void {
    this._listen();
    APP.network.prime(() => this._startBank(onStartedCallback));
  }


  public bridgeMessageEventType(evtType: 'send' | 'receive'): EventId {
    const spec = this.info.spec || APP.client.info.spec;
    if (spec > 9) {
      return evtType === 'send' ? 'bridge:send' : 'bridge:receive';
    }

    return evtType === 'send' ? 'elrond:bridge:send' : 'elrond:bridge:receive';
  }


  public dispatchMessageObjectToShell(detail: {}): void {
    // No-op
  }


  public openInNewView(url: string): Window | null | boolean {
    const windowContext = window.open(url, '_blank');

    return windowContext;
  }


  /**
   * Open a url in a new window while waiting for an async call.
   * This is a specific solution for iOS Safari.
   * @param getUrl An async function that returns a url
   * @returns Window context opened
   */
  public async openInNewViewAsync(getUrl: () => Promise<string>): Promise<Window | null> {
    const windowContext = window.open();
    const url = await getUrl();
    if (url) {
      windowContext?.location.assign(url);
    } else {
      windowContext?.close();
    }

    return windowContext;
  }


  protected _applyCompatibilityClass(kls: string): void {
    document.documentElement.classList.add('compat-' + kls);
  }


  protected _detectCapabilityClasses(): void {
    if (Quirks.ask('ios-standalone')) {
      this._applyCompatibilityClass('uiwebview');
    }
  }


  protected _listen(): void {
    APP.events.on('arena:ready', (evt) => this._createSpecialViews());
    APP.events.on('message', (evt) => this._onWindowMessageEvent(evt), window);
  }


  protected _createSpecialViews(): void {
    this.bifocal = new BifocalView(APP.arena.surface);
  }


  protected _startBank(onBankCallback: (bank: Bank) => void): void {
    this._onBankCallback = onBankCallback;
    this._onBankStarted(new BankScopeDomain('elrond'));
  }


  protected _onBankStarted(bank: Bank): void {
    const bankMigrator = new BankMigrator();
    bankMigrator.migrate(bank);
    if (typeof this._onBankCallback === 'function') {
      this._onBankCallback(bank);
      APP.events.dispatch('bank:start');
    }
  }


  protected _onWindowMessageEvent(evt: any): void {
    // TODO: verify that it is from bifocal or auth?
    let object = evt.data;
    try {
      object = JSON.parse(object);
    } catch (e) {
      object = { name: object };
    }
    // Verify we received a valid event (i.e. it has some data) before forwarding on
    if (!object) { return; }
    this._routeMessageObject(object, object.source || 'bifocal');
  }


  protected _routeMessageObject(object: any, source: string): void {
    if (object.dest && object.dest !== 'client') {
      this._dispatchMessageObject(object);
    } else {
      this._receiveMessageObject(object, source);
    }
  }


  protected _receiveMessageObject(object: any, source: string): void {
    const msg = C.absorb(object, {});
    delete msg.name;
    delete msg.dest;
    msg.source = source;
    APP.events.dispatch(<IssuableEventId>`msg:${object.name}`, msg);
  }


  protected _dispatchMessageObject(object: any): void {
    object.source = object.source || 'client';
    if (object.dest === 'bifocal') {
      this.bifocal.transmit(object);
    }
  }
}
