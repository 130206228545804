<template>
  <Surface>
    <BaseListPage
      :title="title"
      :subtitle="resultCount"
      :ariaTitle="query ? $t('list.searchResults', { query }) : undefined"
      :titleState="titleState"
      :seriesState="seriesState"
      :currentTab="currentTab"
      :currentSort="currentSortOption"
      :sortOptions="sortOptions"
      :allFilters="allFilters"
      @update:tab="(val) => updatePath({ tab: val })"
      @update:page="updatePage"
      @update:filters="(val) => updatePath({ filters: val, tPage: 1, sPage: 1 })"
      @update:sort="updateSort"
    />
  </Surface>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { SearchSortOption } from 'app/base/search';
import BaseListPage from 'app/components/BaseListPage.vue';
import Surface from 'app/components/Surface.vue';
import { useI18n } from 'app/functions/use-i18n';
import { ListPageConfig, ListTabId, useListPage } from 'app/functions/use-list-page';
import { SubjectFilters } from 'app/functions/use-subject-filters';
import { PropType, computed, defineComponent, watch } from 'vue';


export default defineComponent ({
  name: 'Search',
  components : {
    Surface,
    BaseListPage
  },
  props: {
    query: {
      type: String,
      default: undefined
    },
    currentTab: {
      type: String as PropType<ListTabId>,
      default: 'title'
    },
    currentSort: {
      type: String as PropType<SearchSortOption>,
      default: undefined
    },
    appliedFilters: {
      type: Object as PropType<SubjectFilters>,
      default: undefined
    },
    titlePage: {
      type: Number,
      default: undefined
    },
    seriesPage: {
      type: Number,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();
    const config = computed<ListPageConfig>(() => {
      return {
        libraryKey: APP.library.key(),
        definition: {
          query: props.query
        },
        tabOptions: ['title', 'set'],
        sortOptions: props.query ? ['relevance', 'title', 'publishdate'] : ['title', 'publishdate'],
        modifications: {
          currentTab: props.currentTab,
          currentSort: props.currentSort,
          appliedFilters: props.appliedFilters,
          titlePage: props.titlePage,
          seriesPage: props.seriesPage
        }
      };
    });

    const listPage = useListPage(config);

    const title = computed(() => {
      return listPage.titleState.value?.state === 'success'
        ? props.query || t('list.searchAll')
        : t('general.loading');
    });

    watch(() => props.query, (to) => {
      APP.tracking.log('search', { search_term: to });
    }, { immediate: true });

    // Track GA event when query changes and capture result counts
    // Don't track titleState and seriesState directly because they can change with sorts / paging
    watch(() => props.query, () => {
      const untrack = watch([listPage.titleState, listPage.seriesState], () => {
        if (
          props.query !== undefined &&
          listPage.titleState.value?.state === 'success' &&
          listPage.seriesState.value?.state === 'success'
        ) {
          const titleCount = listPage.titleState.value?.response.list.totalItems || 0;
          const setCount = listPage.seriesState.value?.response.list.totalItems || 0;

          APP.tracking.log('search_results_viewed', {
            search_term: props.query,
            total_results: titleCount + setCount,
            title_count: titleCount,
            set_count: setCount
          });

          untrack();
        }
      });
    }, { immediate: true });

    return {
      title,
      ...listPage
    };
  }
});
</script>
