
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { computed, Ref, ref } from 'vue';

export function useSelectable(ids: Ref<string[]>, selectedIds?: string[]) {
  const selected: Ref<Set<string>> = ref(new Set(selectedIds));

  const toggle = (id: string) => {
    selected.value.has(id)
      ? selected.value.delete(id)
      : selected.value.add(id);

    return selected.value.has(id);
  };

  const toggleMany = (checked: FormCheckboxState, toToggleUuids: string[]) => {
    toToggleUuids.forEach((annotationUuid) => {
      if (checked === 'off' && selected.value.has(annotationUuid)) {
        selected.value.delete(annotationUuid);
      } else if (checked !== 'off' && !selected.value.has(annotationUuid)) {
        selected.value.add(annotationUuid);
      }
    }
    );
  };

  const isSelected = (id: string) => {
    return selected.value.has(id);
  };

  const selectionState = computed<FormCheckboxState>(() => {
    return selected.value.size === ids.value.length
      ? 'on'
      : selected.value.size > 0
        ? 'partial'
        : 'off';
  });

  const toggleAll = () => {
    selected.value = selectionState.value === 'on'
      ? new Set()
      : new Set(ids.value);
  };

  const allSelected = computed(() => {
    return selected.value.size > 0
      ? [...selected.value]
      : [];
  });

  const clear = () => {
    selected.value = new Set();
  };

  return {
    allSelected,
    clear,
    isSelected,
    selectionState,
    toggle,
    toggleMany,
    toggleAll
  };
}
