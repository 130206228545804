import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WelcomeHeader = _resolveComponent("WelcomeHeader")!
  const _component_WelcomeFooter = _resolveComponent("WelcomeFooter")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, {
    class: _normalizeClass(_ctx.$style.page)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.initialView)
      }, [
        _createVNode(_component_WelcomeHeader, {
          backButtonLabel: _ctx.backButtonLabel,
          onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
        }, null, 8, ["backButtonLabel"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.content)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ], 2),
      _createVNode(_component_WelcomeFooter)
    ], undefined, true),
    _: 3
  }, 8, ["class"]))
}