import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TitleCard = _resolveComponent("TitleCard")!
  const _component_SeriesCard = _resolveComponent("SeriesCard")!
  const _component_BrowseGroup = _resolveComponent("BrowseGroup")!

  return (_openBlock(), _createBlock(_component_BrowseGroup, {
    name: _ctx.name,
    description: _ctx.description,
    buttonLabel: _ctx.buttonLabel,
    navigateLink: _ctx.navigateLink,
    class: _normalizeClass({
      [_ctx.$style.hide]: _ctx.searchState.state === 'success' && !_ctx.items.length
    }),
    "aria-label": _ctx.$t('browse.collection', { NAME: _ctx.name })
  }, {
    default: _withCtx(() => [
      (_ctx.searchState.state === 'loading')
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            name: "spinner",
            class: _normalizeClass(_ctx.$style.loading),
            "aria-label": _ctx.$t('general.loading')
          }, null, 8, ["class", "aria-label"]))
        : (_openBlock(), _createElementBlock("ul", {
            key: 1,
            class: _normalizeClass(_ctx.$style.content)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.id
              }, [
                (_ctx.searchType === 'title')
                  ? (_openBlock(), _createBlock(_component_TitleCard, {
                      key: 0,
                      title: item
                    }, null, 8, ["title"]))
                  : (_openBlock(), _createBlock(_component_SeriesCard, {
                      key: 1,
                      series: item
                    }, null, 8, ["series"]))
              ]))
            }), 128))
          ], 2))
    ], undefined, true),
    _: 1
  }, 8, ["name", "description", "buttonLabel", "navigateLink", "class", "aria-label"]))
}