import { SearchRequest, SearchResponse, searchSeries, searchTitle, SearchType } from 'app/base/search';
import { FilterSubject } from 'app/models/subject';
import { ref, Ref, watch } from 'vue';


export type SearchState<T extends SearchType> = {
  state: 'loading';
} | {
  state: 'success';
  response: SearchResponse<T>;
} | {
  state: 'error';
};


export function useSearch<T extends SearchType>(request: Ref<SearchRequest<T> | undefined>): { state: Ref<SearchState<T> | undefined> } {
  const state = ref<SearchState<T> | undefined>();

  watch(() => JSON.stringify(request.value), async () => {
    if (request.value) {
      state.value = {
        state: 'loading'
      };

      try {
        const result = request.value.searchType === 'series'
          ? await searchSeries(request.value as SearchRequest<SearchType.Series>)
          : await searchTitle(request.value as SearchRequest<SearchType.Title>);

        state.value = {
          state: 'success',
          response: result as SearchResponse<T>
        };
      } catch (error) {
        state.value = {
          state: 'error'
        };
      }
    } else {
      state.value = undefined;
    }
  }, { immediate: true });

  return {
    state
  };
};


// If using both title and series searches, can use a computed property running this function on both subjects values from responses
export const mergeFilterSubjects = (subjectsA: FilterSubject[] | undefined, subjectsB: FilterSubject[] | undefined): FilterSubject[] => {
  const asObj = [...(subjectsA || []), ...(subjectsB || [])].reduce((combined, filterSubject) => {
    const existing = combined[filterSubject.id];

    if (existing) {
      combined[filterSubject.id].selected = existing.selected || filterSubject.selected;
    } else {
      combined[filterSubject.id] = filterSubject;
    }

    return combined;
  }, {} as { [subjectId: string]: FilterSubject });

  return Object.values(asObj);
};

