import { Constants } from './constants';
import { Events, GlobalEventCallback } from './events';
import { TapManager } from './tap-manager';
import { ContactHandler } from './contact-handler';
import { TapOptions, TapState } from './tap-interfaces';

export class LegacyOnTap {
  /**
   * Listen for a tap or a click event.
   *
   * Options:
   * - 'tapClass' - Sets a class on the element. Defaults to 'tappable'.
   *      Can pass a falsey value (null, blank string) to avoid setting class.
   *
   * - 'ariaRole' - Sets the ARIA role. This is 'link' by default, but
   *      you can pass another string, or you can pass null to prevent
   *      any role being assigned.
   *
   * - 'propagate' - If false, the pointerdown event will not be propagated
   *      up the element stack. True by default.
   *      Note that setting this to false has the side-effect of firing
   *      the event immediately, rather than on a 0-ms delay. This can
   *      cause conflict with tap-to-stop-scrolling in iOS, but it may be
   *      necessary where an API must happen in the same cycle as user input
   *      (such as focusing on a text field).
   *
   * @param callback
   * @param element
   * @param options
   * @returns a handler on which you can call deafen() and listen()
   */
  public static onTap (
    callback: GlobalEventCallback<'click'>,
    element: HTMLElement | SVGElement, options: TapOptions = {}
  ): ContactHandler {
    const tapManager = new TapManager();
    let tapState: TapState | null = null;
    // Throttle the invocation to prevent double firing of the event in envs
    // that support touch and mouse. Particularly in Firefox and Chrome on
    // Windows 8, a mouse event and touch events are both fired.
    // Ref https://github.com/joseph/Monocle/pull/216#issuecomment-21424427
    const throttledCallback = tapManager.throttleInvocation(callback, 100);

    // Assign the tap class:
    if (typeof options.tapClass === 'undefined') {
      options.tapClass = Constants.TAPPABLE_CLASS;
    }
    if (options.tapClass) {
      element.classList.add(options.tapClass);
    }

    // Assign the ARIA role:
    if (options.ariaRole !== null) {
      element.setAttribute('role', options.ariaRole || 'link');
    }

    // Assign the default propagate value:
    if (typeof options.propagate === 'undefined') {
      options.propagate = true;
    }

    const fns = {
      start: (evt: PointerEvent) => {
        if (!options.propagate) {
          evt.stopPropagation();
        }
        if (tapState && tapState.element !== element) {
          tapManager.cancelTap();
        }
        tapState = {
          element: element,
          time: (new Date()).getTime(),
          scrollSig: tapManager.getScrollSignature(element),
          coords: { x: evt.pageX, y: evt.pageY },
          options: options
        };
      },
      move: (evt: PointerEvent) => {
        if (tapState && tapState.element !== element) { return; }
        if (!tapManager.isTapValid(evt)) {
          tapManager.cancelTap();
        }
      },
      end: (evt: PointerEvent & { tappedElement?: EventTarget | null }) => {
        if (!options.propagate) {
          evt.stopPropagation();
        }
        if (tapState && tapState.element !== element) {
          return;
        }
        Events.GLOBAL_EVENTS.stop(evt);
        if (tapManager.isTapValid(evt)) {
          evt.tappedElement = evt.currentTarget || <Element>evt.target;
          const invoke = () => tapManager.handleTap(evt, tapState!.time, throttledCallback);
          if (!options.propagate) {
            invoke();
          } else {
            // Delay invocation so we can check that no scrolling has occurred:
            const delay = evt.pointerType === 'mouse' ? 0 : 50;
            tapState!.endTimer = window.setTimeout(invoke, delay);
          }
        } else {
          tapManager.cancelTap();

          return;
        }
      },
      cancel: (evt: PointerEvent) => {
        if (tapState && tapState.element !== element) { return; }
        tapManager.cancelTap();
      }
    };

    return Events.GLOBAL_EVENTS.onContact(fns, element, { cancelOnLeave: true });
  }
}
