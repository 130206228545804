// Parse an int from a string or a number.
// This was added for safety because we have some places where we call
// parseInt() on a value that's already typed as a number, but there's a
// chance that the value might actually be a string at runtime. In those
// cases it will look pointless at the call site.
//
// Prefer ensuring the type is correct over using this function in new code.
export function parsePossibleInt(
  value: number | string,
  radix?: number
): number {
  if (typeof value === 'number') {
    return value;
  }

  return parseInt(value, radix);
}

// Parse a float from a string or a number.
// This was added for safety because we have some places where we call
// parseFloat() on a value that's already typed as a number, but there's a
// chance that the value might actually be a string at runtime. In those
// cases it will look pointless at the call site.
//
// Prefer ensuring the type is correct over using this function in new code.
export function parsePossibleFloat(value: number | string): number {
  if (typeof value === 'number') {
    return value;
  }

  return parseFloat(value);
}