import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  scope: "row",
  class: "metadata-label"
}
const _hoisted_4 = { class: "metadata" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  scope: "row",
  class: "metadata-label"
}
const _hoisted_7 = { class: "metadata" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.priorReleases)
    ? (_openBlock(), _createElementBlock("ol", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priorReleases, (release) => {
          return (_openBlock(), _createElementBlock("li", {
            key: release.title.slug,
            class: _normalizeClass(_ctx.$style.release)
          }, [
            _createElementVNode("table", {
              class: _normalizeClass(_ctx.$style.releaseInfo)
            }, [
              (release.release)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
                    _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t('title.release')), 1),
                    _createElementVNode("td", _hoisted_4, _toDisplayString(release.release), 1)
                  ]))
                : _createCommentVNode("", true),
              (release.releaseDate)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                    _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('title.releaseDate')), 1),
                    _createElementVNode("td", _hoisted_7, _toDisplayString(release.releaseDate), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.releaseActions)
            }, [
              _createVNode(_component_TitleActionButton, {
                title: release.title,
                parent: _ctx.title
              }, null, 8, ["title", "parent"]),
              (release.numAnnotations)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: _ctx.releaseNotesDetailsLink(release.title)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('title.notesAction', { n: release.numAnnotations })), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ], 2)
          ], 2))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("p", {
        key: 1,
        class: _normalizeClass(_ctx.$style.loading)
      }, [
        _createVNode(_component_Icon, {
          name: "spinner",
          "aria-label": _ctx.$t('general.loading')
        }, null, 8, ["aria-label"])
      ], 2))
}