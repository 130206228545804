import { APP } from 'app/base/app';
import { ThunderMediaResponse, ThunderSearchResponse } from '../base/thunder';
import { BaseListPage } from './base-list-page';
import { TitleMapper, TitleRecord } from './title';

export class TitleListPage extends BaseListPage<TitleRecord, ThunderMediaResponse> {
  public static PER_MULTI_SEARCH = {
    primary: 12,
    secondary: 8
  };


  protected async requestListPage(queryUrl: string): Promise<ThunderSearchResponse<ThunderMediaResponse>> {
    return APP.services.thunder.searchWithFormats(APP.library.key(), queryUrl);
  }


  protected mapItem(response: ThunderMediaResponse): TitleRecord {
    return TitleMapper.mapFromThunder(response);
  }
}
