import { C } from 'app/base/common';
import { BankedPossession, Possession } from 'app/models/possession';
import { WaitList } from 'app/models/wait-list';

export class Hold extends Possession {
  public waitList: WaitList;
  public autoBorrowHold: boolean;
  public emailAddress: string;
  public available: boolean;
  public suspendTime: number;


  constructor() {
    super();
    this.type = 'hold';
  }


  public isSuspended() {
    return this.suspendTime >= C.epochMilliseconds();
  }


  public suspendedDaysRemaining() {
    const now = C.epochMilliseconds();

    return Math.max(0, (this.suspendTime - now) / (1000 * 60 * 60 * 24));
  }


  protected _transformRemoteAttributes(attrs) {
    const out = super._transformRemoteAttributes(attrs);
    out.createTime = this._utcStringToTime(C.excise(attrs, 'placedDate'));
    out.expireTime = this._utcStringToTime(C.excise(attrs, 'expireDate'));
    if (attrs.suspensionFlag) {
      out.suspendTime = this._utcStringToTime(C.excise(attrs, 'suspensionEnd'));
    } else {
      out.suspendTime = 0;
    }
    out.available = attrs.isAvailable ? true : false;
    out.emailAddress = attrs.email;
    out.autoBorrowHold = attrs.autoCheckoutFlag;
    out.waitList = {
      ownedCopies: attrs.ownedCopies,
      holdsCount: attrs.holdsCount,
      holdListPosition: attrs.holdListPosition,
      isPreReleaseTitle: attrs.isPreReleaseTitle
    };

    return out;
  }


  protected _deserializeAttributes(attrs) {
    this.suspendTime = C.excise(attrs, 'suspendTime');
    this.available = C.excise(attrs, 'available');
    this.emailAddress = C.excise(attrs, 'emailAddress');
    this.autoBorrowHold = C.excise(attrs, 'autoBorrowHold');
    const orig = this.waitList ? this.waitList.original || this.waitList : null;
    this.waitList = new WaitList().deserialize(C.excise(attrs, 'waitList'));
    if (orig) {
      this.waitList.original = orig;
    }

    super._deserializeAttributes(attrs);

    return;
  }


  protected serializeInternal(): BankedHold {
    const out = <BankedHold>super.serializeInternal();
    out.suspendTime = this.suspendTime;
    out.available = this.available;
    out.emailAddress = this.emailAddress;
    out.autoBorrowHold = this.autoBorrowHold;
    out.waitList = this.waitList.serialize();

    return out;
  }
}

interface BankedHold extends BankedPossession {
  suspendTime: number;
  available: boolean;
  emailAddress: string;
  autoBorrowHold: boolean;
  waitList: any;
}
