<template>
  <ModalDialog
    ref="modal"
    :heading="$t('gdpr.modal.header')"
    :headingClass="$style.heading"
    :dismissible="false"
    @close="$emit('close')"
  >
    <GdprForm
      :defaultSetting="defaultSetting"
      @submit="onSubmit"
    />
  </ModalDialog>
</template>

<script lang="ts">
import GdprForm from 'app/components/GdprForm.vue';
import ModalDialog from 'app/components/dialogs/ModalDialog.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    ModalDialog,
    GdprForm
  },
  props: {
    defaultSetting: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'close',
    'submit'
  ],
  setup: (_, ctx) => {
    const modal = ref<InstanceType<typeof ModalDialog> | null>(null);

    const onSubmit = (value: boolean) => {
      ctx.emit('submit', value);
      modal.value?.closeDialog();
    };

    return {
      modal,
      onSubmit
    };
  }
});
</script>

<style module>
.heading {
  font-size: var(--fs-medium-head);
  font-weight: var(--fw-bold);
}
</style>
