<template>
  <div
    :class="{
      [$style.banner]: true,
      [$style.mobileErrorBanner]: status === 'error',
      [$style.mobileCompleteBanner]: status === 'complete'
    }"
  >
    <div
      v-if="status === 'error'"
      :class="$style.error"
    >
      <Icon
        name="warning"
        :class="$style.errorIcon"
      />
    </div>

    <div :class="$style.message">
      <p>
        {{ label }}
      </p>
      <p
        v-if="sublabel"
        :class="$style.sublabel"
      >
        {{ sublabel }}
      </p>
    </div>

    <div
      v-if="status === 'inProgress'"
      :class="$style.progress"
    >
      <Icon
        name="spinner"
        :class="$style.progressIcon"
      />
    </div>
    <slot
      v-else
      name="actions"
    ></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export type Status = 'inProgress' | 'complete' | 'error';

export default defineComponent({
  name: 'StatusBanner',
  props: {
    status: {
      type: String as PropType<Status>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    sublabel: {
      type: String,
      default: undefined
    }
  }
});
</script>

<style module>
.banner {
  background-color: var(--c-dark-black);
  border-radius: var(--form-border-radius);
  color: var(--c-white);
  display: flex;
  min-height: 4rem;
  margin-bottom: 1rem;
}

.error {
  display: inherit;
  background-color: var(--c-error-orange);
  border-radius: var(--form-border-radius) 0 0 var(--form-border-radius);
}

.error-icon {
  height: 2rem;
  width: 2rem;
  margin: 1rem;
  fill: white;
}

.message {
  margin: 0.5rem 1rem;
  margin-right: auto;
}

.sublabel {
  color: var(--c-darkest-gray);
  font-size: var(--fs-metadata);
}

.progress {
  display: inherit;
  margin-left: auto;
}

.progress-icon {
  height: 2rem;
  width: 2rem;
  margin: 1rem;
}

@media screen and (max-width: 499px) {
  .mobile-complete-banner {
    display: grid;
  }

  .mobile-error-banner {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .error {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .mobile-complete-banner .message,
  .mobile-error-banner .message {
    margin-right: 1rem;
  }
}
</style>
