import { APP } from 'app/base/app';
import env from 'app/base/env';
import flags from 'res/data/feature_flags.json';

export class FeatureFlags {
  private static readonly OVERRIDES_BANK_KEY = 'feature-flags:overrides';

  private overrides: Partial<typeof flags> = {};


  public loadOverrides() {
    const storedOverrides = APP.bank.get(FeatureFlags.OVERRIDES_BANK_KEY);

    this.overrides = storedOverrides || {};
  }


  /**
   * Get the given flag's value.
   *
   * If the flag's value is an object,
   * use the current env (alpha|beta|charlie)
   * to get the desired config.
   */
  public get<K extends keyof typeof flags>(flag: K): (typeof flags)[K] {
    if (typeof this.overrides[flag] !== 'undefined') {
      return this.overrides[flag];
    }

    const value = flags[flag];

    return typeof value === 'object'
     ? value[env.ELROND_ENV || 'alpha']
     : value;
  }


  /**
   * Set a temporary override for the given flag.
   */
  public override<K extends keyof typeof flags>(flag: K, value: any): void {
    this.overrides[flag] = value;
    this._serializeOverrides();
  }


  /**
   * Clear the specified flag override.
   * No arguments clears all overrides.
   */
  public clear<K extends keyof typeof flags>(flag?: K): void {
    if (flag) {
      delete this.overrides[flag];
    } else {
      this.overrides = {};
    }
    this._serializeOverrides();
  }


  private _serializeOverrides() {
    APP.bank.set(FeatureFlags.OVERRIDES_BANK_KEY, this.overrides);
  }
}

