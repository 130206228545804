import { isString } from './string';

// Creates a DOM element. Optionally with an id, a class name,
// some inner HTML, and appended or prepended to a parentNode.
//
// By default, if parentNode is supplied, the new element will be
// appended. If pos is 'prepend', it will be prepended.
//
// You can create an element like this:
//
//   var elem = C.element('span', 'mySpan', document.body, null, 'Hello');
//
// Or with an options object:
//
//   var elem = C.element({ tag: 'span', classes: 'myElemClass' });
//
// All arguments are optional -- called with no arguments, you
// get a div.
//
export function element<T extends keyof HTMLElementTagNameMap>(
  tag?: T | ElementOptions<T>,
  id?: string,
  parentNode?: HTMLElement,
  classes?: string,
  html?: string,
  pos?: 'prepend' | 'append'
): HTMLElementTagNameMap[T] {
  let opts: ElementOptions<T>;
  if (typeof arguments[0] === 'object') {
    opts = arguments[0];
  } else {
    opts = {
      tag: tag as T,
      id: id,
      parentNode: parentNode,
      classes: classes,
      html: html,
      pos: pos
    };
  }
  let doc = document;
  if (typeof opts.parentNode === 'object' && opts.parentNode) {
    doc = opts.parentNode.ownerDocument || document;
  }
  let elem: HTMLElementTagNameMap[T];
  // TODO: Move SVG creation?
  // if (opts.namespace === 'svg') {
  //   elem = doc.createElementNS('http://www.w3.org/2000/svg', opts.tag || 'g');
  // } else {
  elem = doc.createElement(opts.tag || 'div' as T);
  // }
  if (isString(opts.id) && opts.id) {
    elem.id = opts.id;
  }
  if (isString(opts.className) && opts.className) {
    elem.setAttribute('class', opts.className);
  } else if (isString(opts.classes) && opts.classes) {
    elem.setAttribute('class', opts.classes);
  }
  if (isString(opts.html) && opts.html) {
    elem.innerHTML = opts.html;
  }
  if (opts.attributes) {
    for (const attr in opts.attributes) {
      elem.setAttribute(attr, opts.attributes[attr]);
    }
  }
  if (typeof opts.parentNode === 'object' && opts.parentNode) {
    if (opts.pos === 'prepend' && opts.parentNode.firstChild) {
      opts.parentNode.insertBefore(elem, opts.parentNode.firstChild);
    } else {
      opts.parentNode.appendChild(elem);
    }
  }

  return elem;
}


// Create an iframe element, using the same options object as for
// C.element, plus 'src'. The second argument is a function to fire
// when the iframe is loaded -- you should only do something with
// the iframe in this function if it's on the same origin, obviously.
//
// If opts.src is a string, it is loaded as a URL, obviously. If it is
// a function, the frame is passed to the function so that the function
// can populate it (via document.write or 'javascript:...' or whatever).
//
export function iframe(opts: FrameOptions = {}, callback?: (frame: HTMLIFrameElement) => void): HTMLIFrameElement {
  opts.tag = 'iframe';
  const parentNode = opts.parentNode;
  delete opts.parentNode;
  const frame = element(opts);
  frame.setAttribute('frameborder', 'no');
  frame.setAttribute('scrolling', opts.scrolling || 'no');
  frame.style.setProperty('width', (opts.width || 0).toString());
  frame.style.setProperty('height', (opts.height || 0).toString());
  if (typeof opts.src === 'string') {
    frame.setAttribute('src', opts.src);
  }
  if (typeof callback === 'function') {
    _iframeOnLoad(frame, callback);
  }
  if (parentNode) {
    if (opts.pos === 'prepend' && parentNode.firstChild) {
      parentNode.insertBefore(frame, parentNode.firstChild);
    } else {
      parentNode.appendChild(frame);
    }
  }

  return frame;
}


// We assign the onload handler for a frame in a very particular
// way, so that it works in old versions of IE. It is also set up
// to listen for exactly one onload event on the frame.
//
export function _iframeOnLoad(
  frame: HTMLIFrameElement & Partial<Eventable>,
  callback: (frame: HTMLIFrameElement) => void
): void {
  const handler = () => {
    if (frame.removeEventListener) {
      frame.removeEventListener('load', handler, false);
    } else if (frame.detachEvent) {
      frame.detachEvent('onload', handler);
    }
    callback(frame);
  };
  if (frame.addEventListener) {
    frame.addEventListener('load', handler, false);
  } else if (frame.attachEvent) {
    frame.attachEvent('onload', handler);
  }
}


// IE broke normalize() and still has not fixed it.
//
// var Quirks = require('lib/quirkbase/quirkbase');
export function normalize(node: Node) {
  if (!node) { return; }

  // if (!Quirks('broken-node-normalize')) { return node.normalize(); }
  node.normalize();

  return;
  // if (node.nodeType == 3) {
  //   while (node.nextSibling && node.nextSibling.nodeType == 3) {
  //     node.nodeValue += node.nextSibling.nodeValue;
  //     node.parentNode.removeChild(node.nextSibling);
  //   }
  // } else {
  //   C.normalize(node.firstChild);
  // }
  // C.normalize(node.nextSibling);
}

interface ElementOptions<T extends keyof HTMLElementTagNameMap> {
  tag?: T;
  id?: string;
  parentNode?: HTMLElement;
  classes?: string;
  className?: string;
  html?: string;
  pos?: 'append' | 'prepend';
  namespace?: string;
  attributes?: { [key: string]: string };
}

interface FrameOptions extends ElementOptions<'iframe'> {
  src?: string;
  width?: string | number;
  height?: string | number;
  scrolling?: string;
}

interface Eventable {
  attachEvent: (evtType: string, handler: () => void) => void;

  detachEvent: (evtType: string, handler: () => void) => void;
}
