import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeaderTitle = _resolveComponent("PageHeaderTitle")!
  const _component_PageHeaderBasic = _resolveComponent("PageHeaderBasic")!
  const _component_BackToTopButton = _resolveComponent("BackToTopButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.page)
  }, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_PageHeaderTitle, {
          key: 0,
          ref: "pageHeader",
          class: _normalizeClass(_ctx.$style.header),
          header: _ctx.header,
          subtitle: _ctx.subtitle,
          title: _ctx.title
        }, null, 8, ["class", "header", "subtitle", "title"]))
      : (_openBlock(), _createBlock(_component_PageHeaderBasic, {
          key: 1,
          ref: "pageHeader",
          class: _normalizeClass(_ctx.$style.header),
          header: _ctx.header,
          subtitle: _ctx.subtitle
        }, {
          context: _withCtx(() => [
            _renderSlot(_ctx.$slots, "context")
          ]),
          subheader: _withCtx(() => [
            _renderSlot(_ctx.$slots, "subheader")
          ]),
          _: 3
        }, 8, ["class", "header", "subtitle"])),
    (_ctx.$slots.toolbar)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(_ctx.$style.toolbar)
        }, [
          _renderSlot(_ctx.$slots, "toolbar")
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.content)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _createVNode(_component_BackToTopButton, {
      show: _ctx.showBackToTop && _ctx.useBackToTop,
      scrollTo: _ctx.pageHeaderEl,
      focusOn: _ctx.focusOn,
      onShow: _cache[0] || (_cache[0] = (shouldShow) => _ctx.showBackToTop = shouldShow)
    }, null, 8, ["show", "scrollTo", "focusOn"])
  ], 2))
}