import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LibraryLogo = _resolveComponent("LibraryLogo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SurveyCallout = _resolveComponent("SurveyCallout")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "notch" }, null, -1)),
    _createVNode(_component_router_link, {
      to: { name: _ctx.targets.home.route },
      class: _normalizeClass(_ctx.$style.logoLink)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.logo)
        }, [
          _createVNode(_component_LibraryLogo)
        ], 2)
      ], undefined, true),
      _: 1
    }, 8, ["to", "class"]),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targets, (target, name) => {
        return (_openBlock(), _createElementBlock("li", { key: name }, [
          _createVNode(_component_router_link, {
            to: { name: target.route },
            class: _normalizeClass([_ctx.$style.link, _ctx.active === name ? _ctx.$style.active : ''])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Icon, {
                name: target.icon,
                class: _normalizeClass(_ctx.$style.icon)
              }, null, 8, ["name", "class"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(target.label)) + " ", 1),
              ('badge' in target && target.badge)
                ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(target.badge), 1))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 2
          }, 1032, ["to", "class"])
        ]))
      }), 128))
    ]),
    _createVNode(_Transition, {
      enterActiveClass: _ctx.$style.upEnterFrom,
      leaveActiveClass: _ctx.$style.upLeaveTo,
      appear: true
    }, {
      default: _withCtx(() => [
        false
          ? (_openBlock(), _createBlock(_component_SurveyCallout, {
              key: 0,
              class: _normalizeClass(_ctx.$style.survey),
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-survey')))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 1
    }, 8, ["enterActiveClass", "leaveActiveClass"])
  ], 2))
}