import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "visually-hidden" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["id"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_TextFilter = _resolveComponent("TextFilter")!
  const _component_AnnotationSelectionToolbar = _resolveComponent("AnnotationSelectionToolbar")!
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_ExportQueueGroup = _resolveComponent("ExportQueueGroup")!
  const _component_ExportQueueMobileGroup = _resolveComponent("ExportQueueMobileGroup")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_Page, {
        header: _ctx.$t('exportQueue.header'),
        useBackToTop: true
      }, _createSlots({
        default: _withCtx(() => [
          (_ctx.exportQueue.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!_ctx.mobile)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.toolbar)
                      }, [
                        _createVNode(_component_AnnotationSelectionToolbar, {
                          headerLabel: _ctx.$t('exportQueue.contextMenu.header'),
                          selected: _ctx.selectedAnnotations,
                          menuType: _ctx.menuType,
                          "onSelected:clear": _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateAllAnnotations('off')))
                        }, null, 8, ["headerLabel", "selected", "menuType"]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.showingCount), 1)
                      ], 2),
                      (_ctx.idsToShow.length)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style.groupsHeader)
                          }, [
                            _createElementVNode("span", {
                              class: _normalizeClass([_ctx.$style.selectAll, _ctx.$style.centered])
                            }, [
                              _createVNode(_component_FormCheckbox, {
                                modelValue: _ctx.selectionState,
                                showLabel: false,
                                label: _ctx.$t('exportQueue.selectAll'),
                                ariaLabel: _ctx.$t('exportQueue.selectAll'),
                                class: _normalizeClass(_ctx.$style.checkbox),
                                "onUpdate:modelValue": _ctx.updateAllAnnotations
                              }, null, 8, ["modelValue", "label", "ariaLabel", "class", "onUpdate:modelValue"])
                            ], 2),
                            _createElementVNode("span", {
                              id: _ctx.labelIds.select
                            }, [
                              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('exportQueue.groupsHeader.select')), 1)
                            ], 8, _hoisted_1),
                            _createElementVNode("span", {
                              id: _ctx.labelIds.expand
                            }, [
                              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('exportQueue.groupsHeader.expand')), 1)
                            ], 8, _hoisted_3),
                            _createElementVNode("span", {
                              id: _ctx.labelIds.title
                            }, _toDisplayString(_ctx.$t('exportQueue.groupsHeader.title')), 9, _hoisted_5),
                            _createElementVNode("span", {
                              id: _ctx.labelIds.count,
                              class: _normalizeClass(_ctx.$style.centered)
                            }, _toDisplayString(_ctx.$t('exportQueue.groupsHeader.count')), 11, _hoisted_6),
                            _createElementVNode("span", {
                              id: _ctx.labelIds.actions,
                              class: _normalizeClass(_ctx.$style.centered)
                            }, _toDisplayString(_ctx.$t('exportQueue.groupsHeader.actions')), 11, _hoisted_7)
                          ], 2))
                        : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('annotations.display.empty')), 1)),
                      _createElementVNode("ul", {
                        class: _normalizeClass(_ctx.$style.groupList)
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exportQueueByTitle, (titleSection) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: titleSection.titleId
                          }, [
                            _createVNode(_component_ExportQueueGroup, {
                              titleId: titleSection.titleId,
                              title: titleSection.title,
                              subtitle: titleSection.subtitle,
                              annotations: titleSection.annotations,
                              labelIds: _ctx.labelIds,
                              hideNote: _ctx.hideNote,
                              expanded: titleSection.expanded,
                              "onAnnotations:update": _cache[2] || (_cache[2] = (checked, uuids) => _ctx.onSelectedUpdate(checked, uuids)),
                              "onToggle:expansion": (expanded) => _ctx.onExpansionUpdate(titleSection.titleId, expanded)
                            }, null, 8, ["titleId", "title", "subtitle", "annotations", "labelIds", "hideNote", "expanded", "onToggle:expansion"])
                          ]))
                        }), 128))
                      ], 2)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.toolbar)
                      }, [
                        _createElementVNode("button", {
                          class: _normalizeClass([_ctx.$style.selectButton, _ctx.selectMode ? _ctx.$style.selectButtonActive : '']),
                          "aria-label": _ctx.$t('annotations.select.toggle'),
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectMode = !_ctx.selectMode))
                        }, _toDisplayString(_ctx.$t('annotations.select.label')), 11, _hoisted_9),
                        _createElementVNode("p", null, _toDisplayString(_ctx.showingCount), 1)
                      ], 2),
                      (_ctx.selectMode)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style.selectModeActions)
                          }, [
                            _createVNode(_component_FormCheckbox, {
                              modelValue: _ctx.selectionState,
                              showLabel: false,
                              label: _ctx.$t('exportQueue.selectAll'),
                              ariaLabel: _ctx.$t('exportQueue.selectAll'),
                              class: _normalizeClass(_ctx.$style.checkbox),
                              disabled: _ctx.idsToShow.length === 0,
                              "onUpdate:modelValue": _ctx.updateAllAnnotations
                            }, null, 8, ["modelValue", "label", "ariaLabel", "class", "disabled", "onUpdate:modelValue"]),
                            _createVNode(_component_AnnotationSelectionToolbar, {
                              selected: _ctx.selectedAnnotations,
                              menuType: _ctx.menuType,
                              class: _normalizeClass(_ctx.$style['selection-toolbar']),
                              "onSelected:clear": _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateAllAnnotations('off')))
                            }, null, 8, ["selected", "menuType", "class"])
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.idsToShow.length)
                        ? (_openBlock(), _createElementBlock("ul", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style.groupList)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exportQueueByTitle, (titleSection) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: titleSection.titleId
                              }, [
                                _createVNode(_component_ExportQueueMobileGroup, {
                                  titleId: titleSection.titleId,
                                  title: titleSection.title,
                                  subtitle: titleSection.subtitle,
                                  annotations: titleSection.annotations,
                                  selectable: _ctx.selectMode,
                                  hideNote: _ctx.hideNote,
                                  expanded: titleSection.expanded,
                                  "onAnnotations:update": _cache[5] || (_cache[5] = (checked, uuids) => _ctx.onSelectedUpdate(checked, uuids)),
                                  "onToggle:expansion": (expanded) => _ctx.onExpansionUpdate(titleSection.titleId, expanded)
                                }, null, 8, ["titleId", "title", "subtitle", "annotations", "selectable", "hideNote", "expanded", "onToggle:expansion"])
                              ]))
                            }), 128))
                          ], 2))
                        : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('annotations.display.empty')), 1))
                    ], 64))
              ], 64))
            : (_openBlock(), _createBlock(_component_EmptyState, {
                key: 1,
                link: _ctx.helpLink,
                header: _ctx.$t('exportQueue.emptyState.header'),
                content: _ctx.$t('exportQueue.emptyState.content'),
                linkLabel: _ctx.$t('exportQueue.emptyState.link')
              }, null, 8, ["link", "header", "content", "linkLabel"]))
        ]),
        _: 2
      }, [
        (_ctx.exportQueue.length)
          ? {
              name: "toolbar",
              fn: _withCtx(() => [
                _createVNode(_component_FilterButton, {
                  options: _ctx.filterObjects,
                  filterType: "export",
                  iconOnly: _ctx.mobile,
                  onInput: _ctx.updatePath
                }, null, 8, ["options", "iconOnly", "onInput"]),
                _createVNode(_component_TextFilter, {
                  modelValue: _ctx.textFilter,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textFilter) = $event)),
                    _ctx.expandShownTitles
                  ],
                  label: _ctx.$t('annotations.textFilter'),
                  class: _normalizeClass(_ctx.$style.textFilter)
                }, null, 8, ["modelValue", "label", "class", "onUpdate:modelValue"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["header"])
    ], undefined, true),
    _: 1
  }))
}