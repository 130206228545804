import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.$style.dismiss),
      "aria-label": _ctx.$t('general.close'),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
    }, [
      _createVNode(_component_Icon, { name: "dismiss" })
    ], 10, _hoisted_1),
    _createVNode(_component_Icon, {
      name: "feedback",
      class: _normalizeClass(_ctx.$style.image)
    }, null, 8, ["class"]),
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.$style.header)
    }, _toDisplayString(_ctx.$t('survey.header')), 3),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style.description)
    }, _toDisplayString(_ctx.$t('survey.description')), 3),
    _createElementVNode("a", {
      href: _ctx.link,
      target: "_blank",
      class: _normalizeClass(_ctx.$style.link),
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.clickedSurvey && _ctx.clickedSurvey(...args)), ["space"])),
        _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.clickedSurvey && _ctx.clickedSurvey(...args)), ["enter"]))
      ],
      onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clickedSurvey && _ctx.clickedSurvey(...args))),
      onTouchend: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clickedSurvey && _ctx.clickedSurvey(...args)))
    }, _toDisplayString(_ctx.$t('survey.callToAction')), 43, _hoisted_2)
  ], 2))
}