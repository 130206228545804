<template>
  <SidebarDialog
    :heading="$t('title.priorReleases')"
    @close="$emit('close')"
  >
    <template #secondHeader>
      <header
        :class="$style.header"
      >
        <h3 :class="$style.title">
          {{ title.title }}
        </h3>
        <p
          v-if="title.subtitle"
          :class="$style.subtitle"
        >
          {{ title.subtitle }}
        </p>
      </header>
    </template>

    <template #default>
      <TitlePriorReleaseList
        :title="title"
      />
    </template>
  </SidebarDialog>
</template>

<script lang="ts">
import TitlePriorReleaseList from 'app/components/TitlePriorReleaseList.vue';
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'PriorReleaseSidebar',
  components: {
    TitlePriorReleaseList,
    SidebarDialog
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    }
  },
  emits: [
    'close'
  ]
});
</script>

<style module>
.header {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-dark-gray);
  background-color: var(--c-white);
}

.title {
  font-weight: var(--fw-bold);
  font-size: var(--fs-large-body-head);
}

.subtitle {
  color: var(--c-light-black);
}
</style>
