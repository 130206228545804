import i18n from 'app/i18n/i18n';
import { TitleList } from 'app/models/list';
import { TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { QUERY_FROM_CATEGORY } from 'app/router/query-mapper';
import SUBJECT_DATA from 'res/data/subjects.json';
import { RouteLocationRaw } from 'vue-router';


export type SubjectCategory = 'Jurisdiction' | 'PracticeArea' | 'Classification' | 'Subject';

export type Subject = {
  id: string;
  name: string;
  category: SubjectCategory;
};

export type SubjectCollection = Subject & {
  count: number;
  route: RouteLocationRaw;
};

export type FilterSubject = Subject & {
  selected: boolean;
};

export const fromTitleList = (list: TitleList) => {
  // NOTE: TitleList.id can actually be a number sometimes despite claiming to be a string.
  const category = getSubjectCategory(list.id.toString());

  return {
    id: list.id,
    name: list.name,
    count: list.totalTitles,
    category,
    route: subjectRoute(list.id)
  };
};


export function getSubject(subjectId: string): Subject {
  const id = subjectId.toString();

  return {
    id: id,
    name: i18n.t(`subjectIdOrBisacCode.${id}`),
    category: getSubjectCategory(id)
  };
}


export function subjectRoute(subjectId: string): Exclude<RouteLocationRaw, string> {
  const subject = getSubject(subjectId);

  const urlCategory = QUERY_FROM_CATEGORY[subject.category] === 'otherSubjects'
    ? 'subject'
    : QUERY_FROM_CATEGORY[subject.category];

  return {
    name: RouteName.Subject,
    params: {
      subjectId: subjectId,
      category: urlCategory,
      name: routeableSubjectName(subject.name)
    }
  };
}


// TODO: is this necessary?
function routeableSubjectName(name: string) {
  return name.replace('/', '%2F');
}

export const fromTitleRecord = (record: TitleRecord) => {
  return record.subjects.map((subject) => {
    return {
      id: subject.id,
      name: subject.name,
      category: getSubjectCategory(subject.id)
    };
  });
};

export const getSubjectCategory = (id: string): SubjectCategory => {
  if (id in SUBJECT_DATA.jurisdiction) {
    return 'Jurisdiction';
  }

  if (id.startsWith('LAW') || id in SUBJECT_DATA.practiceArea) {
    return 'PracticeArea';
  }

  if (id in SUBJECT_DATA.classification) {
    return 'Classification';
  }

  return 'Subject';
};
