import { C } from 'app/base/common';
import { TaggishTagResponse, TagRemoteResponse } from 'app/base/taggish';
import { Collation } from 'app/models/collation';
import { Tag, TagPublishMessage } from 'app/models/tag';

export class Tags extends Collation<Tag> {
  /**
   * ID (name) of the Recently Read tag
   */
  public readonly RECENTLY_READ_TAG = '__SYSTEM_recently_read';

  /**
   * Collection of tag ID's (name) that are considered "system" tags
   */
  public readonly SYSTEM_TAGS = [this.RECENTLY_READ_TAG];

  /**
   * Currently active Tag
   */
  public active: Tag;

  protected ITEM_CLASS = Tag;
  protected ITEM_NAME = 'tag';
  protected SERIAL_VERSION = 3;
  protected SERIAL_VERSION_MIN = 3;

  private readonly _recordings: Recording[] = [];

  private readonly NORM_SYSTEM_TAGS = this.SYSTEM_TAGS.map((value) => {
    return value.toUpperCase();
  });


  public static SAME_NAME(nameA: string | undefined, nameB: string | undefined): boolean {
    if (!nameA || !nameB) { return false; }

    return nameA.toLocaleLowerCase() === nameB.toLocaleLowerCase();
  }


  public recordOperation(operation: RecordingOperation, details: TagPublishMessage): void {
    this._recordings.push({
      operation: operation,
      details: details
    });
  }


  public serialize() {
    const collationSerialization = super.serialize();

    return {
      recordings: this._recordings,
      collation: collationSerialization
    };
  }


  public deserialize(collationAttributes) {
    if (collationAttributes) {
      this._recordings.splice(0, this._recordings.length);
      this._recordings.push(...collationAttributes.recordings);

      return super.deserialize(collationAttributes.collation);
    }

    return super.deserialize(collationAttributes);
  }


  public sync(itemsAttributes: TaggishTagResponse[]): Tag[] {
    // Find the cutoff where the replay should start from.
    let horizon = 0;
    C.each(itemsAttributes, (tagResponse) => {
      horizon = Math.max(horizon, tagResponse.timestamp);
    });

    // Replay the recordings, tracking which ones are still relevant.
    const replayed: Recording[] = [];
    C.each(this._recordings, (recording) => {
      if (recording.details.tag.timestamp <= horizon) {
        return;
      }

      if (recording.operation === 'addTagging') {
        this._replayAddTagging(itemsAttributes, recording);
      } else if (recording.operation === 'removeTagging') {
        this._replayRemoveTagging(itemsAttributes, recording);
      } else if (recording.operation === 'renameTag') {
        this._replayRenameTag(itemsAttributes, recording);
      } else if (recording.operation === 'removeTag') {
        this._replayRemoveTag(itemsAttributes, recording);
      }

      replayed.push(recording);
    });

    // Save the new recordings, removing the outdated ones.
    this._recordings.splice(0, this._recordings.length);
    this._recordings.push(...replayed);

    return super.sync(itemsAttributes);
  }


  public deleteAll(): void {
    const tagsClone = [...this.all];
    let cnt = 0;
    while (cnt < tagsClone.length) {
      this.all[0].delete();
      cnt++;
    }
  }


  /**
   * Determines if the tag name is resserved for system use.
   * @param tagName The name of the tag to check
   * @returns True if the name is reserved and should not be used, otherwise; false
   */
  public isSystemTagName(tagName: string): boolean {
    if (!tagName) { return false; }

    return this.NORM_SYSTEM_TAGS.indexOf(tagName.toUpperCase()) >= 0;
  }


  /**
   * Determines if the tag name is already in use.
   * @param tagName The name of the tag to check
   * @returns True if the name is in use and should not be used, otherwise; false
   */
  public isExistingTagName(tagName: string): boolean {
    if (!tagName) { return false; }

    return this.all.some((tag) => Tags.SAME_NAME(tag.name, tagName));
  }


  protected _makingItem(tag: Tag, tagAttributes: any, stubbed = false) {
    if (stubbed) {
      tag.christen(tagAttributes.name);
    }
  }


  protected _fullAttributesToQuery(itemAttributes): any {
    return { uuid: itemAttributes.uuid };
  }


  private _replayAddTagging(dest: TaggishTagResponse[], recording: Recording) {
    // Find the right tag in the response
    let tag: TaggishTagResponse = null;
    C.each(dest, (destTag) => {
      if (destTag.tagUUID === recording.details.tag.uuid) {
        tag = destTag;
      }
    });

    if (!tag) {
      // Add the new tag.
      tag = {
        tagUUID: recording.details.tag.uuid,
        name: recording.details.tag.name,
        slug: recording.details.tag.slug,
        flags: recording.details.tag.flags,
        timestamp: recording.details.tag.timestamp,
        taggings: {
          count: 0,
          limit: 0,
          offset: 0,
          items: []
        }
      };
      dest.push(tag);
    }

    // Check if the tagging already exists
    let tagging: TagRemoteResponse = null;
    C.each(tag.taggings.items, (destTagging) => {
      if (destTagging.title.titleId === recording.details.tagging.titleSlug) {
        tagging = destTagging;
      }
    });

    if (tagging) { return; }

    // Add a tagging to its items
    tag.timestamp = recording.details.tag.timestamp;
    tag.taggings.count++;
    tag.taggings.items.push({
      timestamp: recording.details.tagging.timestamp,
      title: { titleId: recording.details.tagging.titleSlug }
    });
  }


  private _replayRemoveTagging(dest: TaggishTagResponse[], recording: Recording) {
    // Find the right tag in the response
    let tag: TaggishTagResponse = null;
    C.each(dest, (destTag) => {
      if (destTag.tagUUID === recording.details.tag.uuid) {
        tag = destTag;
      }
    });

    if (!tag) { return; }

    // Find the right tagging in the tag items
    let tagging: TagRemoteResponse = null;
    C.each(tag.taggings.items, (destTagging) => {
      if (destTagging.title.titleId === recording.details.tagging.titleSlug) {
        tagging = destTagging;
      }
    });

    if (!tagging) { return; }

    // Excise it from the items
    tag.timestamp = recording.details.tag.timestamp;
    tag.taggings.count--;
    C.excise(tag.taggings.items, tagging);
  }


  private _replayRenameTag(dest: TaggishTagResponse[], recording: Recording) {
    // Find the right tag in the response
    let tag: TaggishTagResponse = null;
    C.each(dest, (destTag) => {
      if (destTag.tagUUID === recording.details.tag.uuid) {
        tag = destTag;
      }
    });

    if (!tag) { return; }

    // Update the tag.
    tag.name = recording.details.tag.name;
    tag.slug = recording.details.tag.slug;
    tag.timestamp = recording.details.tag.timestamp;
  }


  private _replayRemoveTag(dest: TaggishTagResponse[], recording: Recording) {
    // Find the right tag in the response
    let tag: TaggishTagResponse = null;
    C.each(dest, (destTag) => {
      if (destTag.tagUUID === recording.details.tag.uuid) {
        tag = destTag;
      }
    });

    if (!tag) { return; }

    // Remove the tag.
    C.excise(dest, tag);
  }
}

type RecordingOperation = 'addTagging' | 'removeTagging' | 'renameTag' | 'removeTag';

interface Recording {
  operation: RecordingOperation;
  details: TagPublishMessage;
}
