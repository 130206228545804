import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertNewReleaseDetails = _resolveComponent("AlertNewReleaseDetails")!
  const _component_Cover = _resolveComponent("Cover")!
  const _component_AlertNotAvailableDetails = _resolveComponent("AlertNotAvailableDetails")!
  const _component_SeriesCover = _resolveComponent("SeriesCover")!

  return (_ctx.alert.type === 'TitleNewRelease')
    ? (_openBlock(), _createBlock(_component_AlertNewReleaseDetails, {
        key: 0,
        name: _ctx.alert.title.title,
        type: _ctx.alert.type,
        titles: [_ctx.alert.title]
      }, null, 8, ["name", "type", "titles"]))
    : (_ctx.alert.type === 'SeriesNewRelease')
      ? (_openBlock(), _createBlock(_component_AlertNewReleaseDetails, {
          key: 1,
          name: _ctx.alert.series.title,
          type: _ctx.alert.type,
          titles: _ctx.alert.titles
        }, null, 8, ["name", "type", "titles"]))
      : (_ctx.alert.type === 'TitleNotAvailable')
        ? (_openBlock(), _createBlock(_component_AlertNotAvailableDetails, {
            key: 2,
            id: _ctx.alert.title.slug,
            title: _ctx.alert.title.title,
            subtitle: _ctx.alert.title.subtitle,
            authors: _ctx.alert.title.creators?.Author,
            type: _ctx.alert.type
          }, {
            cover: _withCtx(() => [
              _createVNode(_component_Cover, {
                item: (_ctx.alert.title as TitleRecord)
              }, null, 8, ["item"])
            ]),
            _: 1
          }, 8, ["id", "title", "subtitle", "authors", "type"]))
        : (_ctx.alert.type === 'SeriesNotAvailable')
          ? (_openBlock(), _createBlock(_component_AlertNotAvailableDetails, {
              key: 3,
              id: _ctx.alert.series.id.toString(),
              title: _ctx.alert.series.title,
              authors: _ctx.alert.series.creators?.Author,
              type: _ctx.alert.type
            }, {
              cover: _withCtx(() => [
                _createVNode(_component_SeriesCover, {
                  series: (_ctx.alert.series as Series),
                  width: _ctx.seriesCoverSizes.alertDetails
                }, null, 8, ["series", "width"])
              ]),
              _: 1
            }, 8, ["id", "title", "authors", "type"]))
          : _createCommentVNode("", true)
}