<template>
  <aside :class="$style.container">
    <button
      :class="$style.dismiss"
      :aria-label="$t('general.close')"
      @click="close"
    >
      <Icon name="dismiss" />
    </button>

    <Icon
      name="feedback"
      :class="$style.image"
    />

    <h2 :class="$style.header">
      {{ $t('survey.header') }}
    </h2>

    <p :class="$style.description">
      {{ $t('survey.description') }}
    </p>

    <a
      :href="link"
      target="_blank"
      :class="$style.link"
      @keydown.space="clickedSurvey"
      @keydown.enter="clickedSurvey"
      @mouseup="clickedSurvey"
      @touchend="clickedSurvey"
    >
      {{ $t('survey.callToAction') }}
    </a>
  </aside>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'SurveyCallout',
  emits: [
    'close'
  ],
  setup: (_, ctx) => {
    const link = Constants.SURVEY_LINK;

    const close = () => {
      setSurveyFlag(Constants.SURVEY_CLOSED_RESPITE_DAYS);
      ctx.emit('close');
    };

    const clickedSurvey = () => {
      setSurveyFlag(Constants.SURVEY_TAKEN_RESPITE_DAYS);
      ctx.emit('close');
    };

    const setSurveyFlag = (numberOfDays: number) => {
      const date = new Date();
      date.setDate(numberOfDays + date.getDate());

      APP.bank.set('survey:ask-again', date.toISOString());
    };

    return {
      clickedSurvey,
      close,
      link
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

@px-vp-hide-survey: 850px;

.container {
  box-shadow: 0 1px 6px @c-shadow;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 1rem;
  transition: transform 300ms ease;
  background-color: @c-white;
}

.dismiss {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;
  display: block;
  margin-left: auto;
  line-height: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.image {
  height: 3.5rem;
  margin: auto;
  display: block;
}

.header {
  font-weight: @fw-bold;
  font-size: @fs-small-head;
  text-align: center;
}

.description {
  text-align: center;
}

.link {
  text-align: center;
  width: 100%;
  background-color: @c-primary-red;
  color: @c-white;
  text-transform: uppercase;
  border-radius: 0.25rem;
  display: block;
  padding: 0.5rem 0;
  font-weight: @fw-medium;
}

.header,
.description,
.link {
  margin: 1rem 0;
}

@media screen and (max-height: @px-vp-hide-survey) {
  .image {
    display: none;
  }

  .header,
  .description,
  .link {
    margin: 0.5rem 0;
  }
}
</style>
