export class ClipboardHelper {
  public static async copyToClipboard(text: string): Promise<boolean> {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);

        return true;
      } catch (err) {
        console.warn('[CLIPBOARD] could not copy with writeText:', err);
      }
    }

    return ClipboardHelper._attemptCopyToClipboardExecCommand(text);
  }


  private static _attemptCopyToClipboardExecCommand(text: string): boolean {
    let success = false;
    const textarea = ClipboardHelper._selectTextArea(text);
    try {
      const result = document.execCommand('copy');

      if (result) {
        success = true;
      }
    } catch (err) {
      console.warn('[CLIPBOARD] could not copy with execCommand:', err);
    } finally {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }

      textarea.blur();
      document.body.removeChild(textarea);
    }

    return success;
  }


  private static _selectTextArea(text: string): HTMLTextAreaElement {
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = text;
    textarea.focus();
    textarea.select();
    textarea.setSelectionRange(0, text.length);

    return textarea;
  }
}
