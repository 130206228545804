import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import env from 'app/base/env';
import { Service } from './services/service';

export class Samwise extends Service {

  constructor() {
    super('SAMWISE', (path) => {
      return { url: `${env.SAMWISE_URI}/${path}` };
    });
  }

  // Subscriptions

  public async fetchAllSubscriptions() {
    return APP.services.samwise.fetchAsync<Subscription[]>({
      url: 'subscriptions',
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true
    });
  }


  public async subscribeToTitle(titleId: number) {
    return APP.services.samwise.fetchAsync<Subscription>({
      url: 'subscriptions/title',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        titleId
      })
    });
  }


  public async subscribeToSeries(seriesId: number) {
    return APP.services.samwise.fetchAsync<Subscription>({
      url: 'subscriptions/series',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        seriesId
      })
    });
  }


  // lastChecked in seconds
  public async updateSubscription(subscriptionId: number, lastChecked?: number) {
    return APP.services.samwise.fetchAsync<Subscription>({
      url: `subscriptions/${subscriptionId}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        lastChecked: Math.floor(lastChecked || C.epochSeconds())
      })
    });
  }


  public async unsubscribe(subscriptionId: number) {
    return APP.services.samwise.fetchAsync<void>({
      url: `subscriptions/${subscriptionId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      textResponse: true
    });
  }

  // Alerts

  public async fetchAllAlerts() {
    return APP.services.samwise.fetchAsync<Alert[]>({
      url: 'alerts',
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true
    });
  }

  public async addTitleNewReleaseAlert(titleId: number) {
    return APP.services.samwise.fetchAsync<Alert>({
      url: 'alerts/title-new-release',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        titleId
      })
    });
  }

  public async addTitleNotAvailableAlert(titleId: number) {
    return APP.services.samwise.fetchAsync<Alert>({
      url: 'alerts/title-not-available',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        titleId
      })
    });
  }

  public async addSeriesNewReleaseAlert(seriesId: number, titleIds: number[]) {
    return APP.services.samwise.fetchAsync<Alert>({
      url: 'alerts/series-new-release',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        seriesId,
        titleIds
      })
    });
  }

  public async addSeriesNotAvailableAlert(seriesId: number) {
    return APP.services.samwise.fetchAsync<Alert>({
      url: 'alerts/series-not-available',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        seriesId
      })
    });
  }

  public async reviewAlert(alertId: number) {
    return APP.services.samwise.fetchAsync<Alert>({
      url: `alerts/${alertId}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      body: JSON.stringify({
        reviewed: true
      })
    });
  }

  public async deleteAlert(alertId: number) {
    return APP.services.samwise.fetchAsync<void>({
      url: `alerts/${alertId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      textResponse: true
    });
  }

  public async deleteAllAlerts() {
    return APP.services.samwise.fetchAsync<void>({
      url: 'alerts/all',
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${APP.sentry.identityToken}`
      },
      credentials: true,
      textResponse: true
    });
  }
}

type BaseSubscription = {
  id: number;
  lastChecked: number;
};

export type TitleSubscription = BaseSubscription & {
  type: 'Title';
  titleId: number;
};

export type SeriesSubscription = BaseSubscription & {
  type: 'Series';
  seriesId: number;
};

export type Subscription = TitleSubscription | SeriesSubscription;

type BaseAlert = {
  id: number;
  reviewed: boolean;
  timestamp: number;
};

export type TitleNewReleaseAlert = BaseAlert & {
  type: 'TitleNewRelease';
  titleId: number;
};

export type TitleNotAvailableAlert = BaseAlert & {
  type: 'TitleNotAvailable';
  titleId: number;
};

export type SeriesNewReleaseAlert = BaseAlert & {
  type: 'SeriesNewRelease';
  seriesId: number;
  titleIds: number[];
};

export type SeriesNotAvailableAlert = BaseAlert & {
  type: 'SeriesNotAvailable';
  seriesId: number;
};

export type TitleAlert = TitleNewReleaseAlert | TitleNotAvailableAlert;

export type SeriesAlert = SeriesNewReleaseAlert | SeriesNotAvailableAlert;

export type Alert = TitleAlert | SeriesAlert;
