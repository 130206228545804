import { PostishCopyTitleSnapshot } from 'app/base/postish';
import { buildCopyJobContext } from 'app/contexts/copy-job-context';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { getReleaseDisplayNameCopyJob } from 'app/functions/use-release-display-name';
import { CopyJobContextSymbol } from 'app/keys/injection-keys';
import { RouteName } from 'app/router/constants';
import router from 'app/router/router';
import { inject } from 'vue';

export type CopyUpdateType = 'review' | 'dismiss' | 'retry' | 'clear';

export function useAnnotationCopying() {
  const { t } = useI18n();

  const {
    startCopyJob,
    deleteCopyJob,
    retryCopyJob,
    reviewCopyJob
  } = inject(CopyJobContextSymbol, buildCopyJobContext());

  const updateCopyJob = (jobId: string | number, updateType: CopyUpdateType) => {
    const id = typeof jobId === 'number' ? jobId.toString() : jobId;

    if (updateType === 'clear') {
      announceMessage(t('annotationCopying.banner.ariaLabels.clear'));
      deleteCopyJob(id);

      return undefined;
    }

    if (updateType === 'retry') {
      announceMessage(t('annotationCopying.banner.ariaLabels.retry'));

      retryCopyJob(id);
    }

    if (updateType === 'review') {
      router.push({
        name: RouteName.CopyReview,
        params: {
          jobId: id
        }
      });
    }

    announceMessage(t('annotationCopying.banner.ariaLabels.dismiss'));

    return reviewCopyJob(id);
  };

  const getReleaseLabel = (source: PostishCopyTitleSnapshot, target: PostishCopyTitleSnapshot) => {
    return t('annotationCopying.releaseLabel', {
      SOURCE: getReleaseDisplayNameCopyJob(source),
      TARGET: getReleaseDisplayNameCopyJob(target)
    });
  };

  return {
    startCopyJob,
    updateCopyJob,
    getReleaseLabel
  };
};
