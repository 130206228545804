import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: _normalizeClass({
        [_ctx.$style.label]: true,
        'visually-hidden': _ctx.noLabel
      })
    }, _toDisplayString(_ctx.$t('general.textFilter.placeholder')), 11, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.inputWrapper)
    }, [
      _createElementVNode("input", {
        id: _ctx.id,
        value: _ctx.internalInput,
        type: "search",
        autocomplete: "off",
        spellcheck: "false",
        autocapitalize: "off",
        placeholder: _ctx.labelWithDefault,
        class: _normalizeClass({
          [_ctx.$style.input]: true,
          [_ctx.$style.noLabel]: _ctx.noLabel
        }),
        onKeydown: [
          _cache[0] || (_cache[0] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
          _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keydown && _ctx.keydown(...args)))
        ],
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setInput && _ctx.setInput(...args)))
      }, null, 42, _hoisted_2),
      _createVNode(_component_Icon, {
        name: "search",
        class: _normalizeClass(_ctx.$style.icon)
      }, null, 8, ["class"])
    ], 2)
  ], 2))
}