import { APP } from 'app/base/app';
import { ThunderSearchResponse, ThunderSeriesResponse } from 'app/base/thunder';
import { BaseListPage } from './base-list-page';
import { SearchOptions } from './list-parameters';
import { Series, SeriesMapper } from './series';

export class SeriesListPage extends BaseListPage<Series, ThunderSeriesResponse> {
  protected async requestListPage(queryUrl: string): Promise<ThunderSearchResponse<ThunderSeriesResponse>> {
    return APP.services.thunder.seriesSearch(APP.library.key(), queryUrl);
  }


  protected mapItem(response: ThunderSeriesResponse): Series {
    return SeriesMapper.mapFromThunder(response);
  }


  protected async freshenCall(options: SearchOptions): Promise<ThunderSearchResponse<ThunderSeriesResponse>> {
    // Elrond Sets do not have bisac codes. If we are filtering on bisac codes,
    // we don't need to ask the API, we know the list is empty
    if (this.list.itemType === 'set' &&
      this.list.params?.practiceAreas?.length &&
      this.list.params.practiceAreas.find((pa) => pa.startsWith('LAW'))) {
      console.log('[LIST] Supressing freshenCall for series with bisaccodes');

      return null;
    }

    return super.freshenCall(options);
  }
}

