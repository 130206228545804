import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!
  const _component_StatusBanner = _resolveComponent("StatusBanner")!

  return (_openBlock(), _createBlock(_component_StatusBanner, {
    status: _ctx.mappedStatus,
    label: _ctx.statusLabel,
    sublabel: _ctx.releaseLabel
  }, {
    actions: _withCtx(() => [
      (_ctx.mappedStatus === 'complete')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.actions)
          }, [
            _createVNode(_component_FormSubmitButton, {
              label: _ctx.$t('annotationCopying.banner.actions.review'),
              size: "small",
              class: _normalizeClass(_ctx.$style.submit),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', 'review')))
            }, null, 8, ["label", "class"]),
            _createVNode(_component_FormSubmitButton, {
              label: _ctx.$t('annotationCopying.banner.actions.dismiss'),
              size: "small",
              class: _normalizeClass(_ctx.$style.dismiss),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update', 'dismiss')))
            }, null, 8, ["label", "class"])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.mappedStatus === 'error')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.actions)
          }, [
            _createVNode(_component_FormSubmitButton, {
              label: _ctx.$t('annotationCopying.banner.actions.retry'),
              size: "small",
              class: _normalizeClass(_ctx.$style.submit),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update', 'retry')))
            }, null, 8, ["label", "class"]),
            _createVNode(_component_FormSubmitButton, {
              label: _ctx.$t('annotationCopying.banner.actions.clear'),
              size: "small",
              class: _normalizeClass(_ctx.$style.dismiss),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update', 'clear')))
            }, null, 8, ["label", "class"])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["status", "label", "sublabel"]))
}