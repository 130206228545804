import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!

  return (_openBlock(), _createElementBlock("fieldset", {
    class: _normalizeClass(_ctx.$style.fields)
  }, [
    _createVNode(_component_FormInput, {
      ref: "initialInput",
      modelValue: _ctx.usernameInput,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.usernameInput) = $event)),
      type: "username",
      label: _ctx.$t(`login.form.username.${_ctx.usernameConfig.key || 'Username'}`),
      required: true
    }, null, 8, ["modelValue", "label"]),
    (_ctx.passwordConfig.enabled)
      ? (_openBlock(), _createBlock(_component_FormInput, {
          key: 0,
          modelValue: _ctx.passwordInput,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordInput) = $event)),
          type: "password",
          label: _ctx.$t(`login.form.password.${_ctx.passwordConfig.key || 'Password'}`),
          required: true
        }, null, 8, ["modelValue", "label"]))
      : _createCommentVNode("", true),
    _createVNode(_component_FormSubmitButton, {
      label: _ctx.$t('login.form.submit')
    }, null, 8, ["label"]),
    (_ctx.loginHintUrl)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.loginHintUrl,
          rel: "noreferrer noopener",
          target: "_blank",
          class: _normalizeClass(_ctx.$style.helpLink)
        }, _toDisplayString(_ctx.$t('login.form.help')), 11, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}