import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#toasts" }, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.$style.toast, _ctx.$style[_ctx.type], _ctx.animate ? _ctx.$style.animate : ''])
        }, [
          _createVNode(_component_Icon, { name: _ctx.iconName }, null, 8, ["name"]),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.message)
          }, _toDisplayString(_ctx.message), 3),
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.$style.dismiss),
            "aria-label": _ctx.$t('close'),
            "aria-hidden": true,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
          }, _toDisplayString(_ctx.$t('general.dismiss')), 11, _hoisted_1)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}