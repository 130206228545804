<template>
  <ul :class="$style.subjectList">
    <li
      v-for="subject in subjects"
      :key="subject.id"
    >
      <router-link
        :to="subjectLink(subject.id)"
      >
        {{ subject.name }}
      </router-link>
    </li>
  </ul>
</template>

<script lang='ts'>
import { IdNamePair } from 'app/base/thunder';
import { subjectRoute } from 'app/models/subject';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccordionSubjectList',
  props: {
    subjects: {
      type: Array as () => IdNamePair[],
      required: true
    }
  },
  setup: () => {
    return {
      subjectLink: subjectRoute
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.subject-list {
  li:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  a {
    .pop-text();
    .focus-outline;
  }
}
</style>
