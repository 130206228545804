import formatter from 'app/i18n/i18n';
import { getCurrentInstance } from 'vue';

export function useI18n(): typeof formatter {
  const instance = getCurrentInstance();

  let i18n = formatter;

  const instanceI18n = (instance as any)?.root?.ctx?._i18n;
  if (instanceI18n) {
    i18n = {
      t: instanceI18n.t.bind(instanceI18n),
      n: instanceI18n.n.bind(instanceI18n),
      locale: instanceI18n.locale
    };
  }

  return i18n;
}
