<template>
  <div :class="$style.wrapper">
    <p
      v-if="!labelId && placeholder"
      :id="fallbackLabelId"
      class="visually-hidden"
    >
      {{ placeholder }}
    </p>
    <input
      :id="searchFocusId"
      v-model.trim="input"
      type="search"
      :aria-labelledby="`${labelId || fallbackLabelId}`"
      :placeholder="placeholder"
      :class="$style.input"
      :required="required"
      :aria-haspopup="hasPopup || false"
      @invalid.prevent="onInvalid"
    />

    <button
      type="submit"
      :aria-label="$t('form.search')"
      :aria-haspopup="hasPopup || false"
      :class="$style.button"
    >
      <Icon name="search" />
    </button>

    <FormError
      v-if="shownError"
      :class="$style.error"
      :contents="shownError"
    />
  </div>
</template>

<script lang="ts">
import FormError from 'app/components/FormError.vue';
import Icon from 'app/components/Icon.vue';
import { useI18n } from 'app/functions/use-i18n';
import { generateUUID } from 'lib/common';
import { PropType, computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'FormSearch',
  components: {
    Icon,
    FormError
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    labelId: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    focusId: {
      type: String,
      default: undefined
    },
    hasPopup: {
      type: String as PropType<'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'>,
      default: undefined
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const fallbackLabelId = `form-search-label-${generateUUID()}`;
    const searchFocusId = props.focusId || `form-search-button-${generateUUID()}`;

    const input = computed({
      get: () => props.modelValue,
      set: (val) => {
        shownError.value = null;
        ctx.emit('update:modelValue', val);
      }
    });

    const { t } = useI18n();
    const shownError = ref<string | null>(null);
    const onInvalid = () => {
      shownError.value = t(
        'form.error.missing',
        { name: props.label }
      ).toString();
    };

    return {
      fallbackLabelId,
      input,
      onInvalid,
      searchFocusId,
      shownError
    };
  }
});
</script>

<style module>

.wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
}

.input {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--c-light-gray);
  padding: 1rem;
  font-size: var(--fs-medium);
  border-top-left-radius: var(--form-border-radius);
  border-bottom-left-radius: var(--form-border-radius);
  border: none;
  margin: 0;
  min-width: 0;

  composes: focus-outline from global;
  composes: ellipsis from global;
}

/* Remove magnifying glass icon in iOS / iPad */
.input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.button {
  background-color: var(--c-dark-black);
  color: var(--c-white);
  padding: 1rem;
  border-top-right-radius: var(--form-border-radius);
  border-bottom-right-radius: var(--form-border-radius);
  line-height: 1;
}

.button >  svg {
  width: 1.5rem;
  height: 1.5rem;
  stroke: currentColor;
  fill: currentColor;
}

.error {
  font-size: var(--fs-mini);
  margin-top: 0.5rem;
}
</style>
