<template>
  <div
    ref="coverContainer"
    :class="$style.container"
    :style="[`--c-set-color: ${series.coverColor || ''}`, `--rem-cover-width: ${width + 'rem'}`]"
  >
    <div :class="[$style.stack, $style.stack3, 'relieve']"></div>
    <div :class="[$style.stack, $style.stack2, 'relieve']"></div>
    <div :class="[$style.stack, $style.stack1, 'relieve']"></div>
    <Cover
      :item="series"
      :lazy="false"
      :class="[$style.cover, $style.stack0, 'relieve']"
    />
  </div>
</template>

<script lang='ts'>
import { C } from 'app/base/common';
import Cover from 'app/components/Cover.vue';
import { Series } from 'app/models/series';
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'SeriesCover',
  components: {
    Cover
  },
  props: {
    series: {
      type: Series,
      required: true
    },
    width: {
      type: Number,
      default: 8.625
    }
  },
  setup: (props) => {
    // Global css classes defined under 'styles/core/common.less'
    const TINT_PROPERTY = 'tint-background-color';
    const DARK_TINT = 'dark';
    const LIGHT_TINT = 'light';
    const TINT_THRESHOLD_YIQ = 190;

    const coverContainer = ref<HTMLElement | null>(null);

    /**
     * Determines if the cover is 'light' or 'dark'
     */
    const tint = computed(() => {
      if (!props.series.coverColor) {
        return LIGHT_TINT;
      }
      const rgb = C.hexToRGB(props.series.coverColor);
      if (!rgb) {
        return LIGHT_TINT;
      }

      return C.colorYIQ(rgb) >= TINT_THRESHOLD_YIQ ? LIGHT_TINT : DARK_TINT;
    });

    onMounted(() => {
      if (!coverContainer.value?.children) { return; }
      const tintValue = tint.value;

      Array.from(coverContainer.value.children).forEach((child) => {
        C.DataClass.set(child as HTMLElement, TINT_PROPERTY, tintValue);
      });
    });

    return {
      coverContainer
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

@cover-stack-mult: 0.95;

.container {
  --c-set-color: @c-cover;

  --rem-set-cover-width: calc(0.9 * var(--rem-cover-width));
  --rem-set-cover-height: calc(4 / 3 * var(--rem-set-cover-width));
  --rem-set-cover-sliver-width: 0.25rem;
}

.stack {
  position: absolute;
  background-color: var(--c-set-color);
}

.stack,
.cover {
  width: var(--rem-set-cover-width);
  height: var(--rem-set-cover-height);
  border: 1px solid @c-cover-border;
  box-sizing: border-box;
}

.stack(0);
.stack(1);
.stack(2);
.stack(3);

.stack(@n) {
  .stack-@{n} {
    transform: scaleY(pow(@cover-stack-mult, @n))
      translateX(calc((3 - @n) * (var(--rem-cover-width) - var(--rem-set-cover-width)) / 3))
      translateY(@n * 0.1rem)
  }
}
</style>
