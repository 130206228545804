import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _component_ModalDialog = _resolveComponent("ModalDialog")!

  return (_openBlock(), _createBlock(_component_ModalDialog, {
    heading: _ctx.title.title,
    headingClass: _ctx.$style.title,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.coverLabel), 1),
      _createVNode(_component_Cover, { item: _ctx.title }, null, 8, ["item"])
    ], undefined, true),
    _: 1
  }, 8, ["heading", "headingClass"]))
}