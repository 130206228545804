import { SentryPossession } from '../base/sentry';
import { ItemWithTitleCache } from './item-with-title';
import { Title, TitleInfoForShell, TitleMapper, TitleRecord } from './title';

export class RecentlyReadTitle extends ItemWithTitleCache<SentryPossession, BankedRecentlyRead> {
  public titleSlug: string;
  public lastAccessTime: number;

  public static SORT_FUNCTIONS = {
    title: (a: RecentlyReadTitle, b: RecentlyReadTitle) => {
      return Title.SORT_FUNCTIONS.title(a.titleRecord as Title, b.titleRecord as Title);
    },
    accessed: (a: RecentlyReadTitle, b: RecentlyReadTitle) => {
      if (!a) { return 1; }
      if (!b) { return -1; }

      if (a.lastAccessTime && b.lastAccessTime) { return b.lastAccessTime - a.lastAccessTime; }
      if (a.lastAccessTime && !b.lastAccessTime) { return -1; }
      if (!a.lastAccessTime && b.lastAccessTime) { return 1; }

      return RecentlyReadTitle.SORT_FUNCTIONS.title(a, b);
    },
    accessedShell: (a: TitleInfoForShell, b: TitleInfoForShell) => {
      if (!a) { return 1; }
      if (!b) { return -1; }

      if (a.accessTime && b.accessTime) { return b.accessTime - a.accessTime; }
      if (a.accessTime && !b.accessTime) { return -1; }
      if (!a.accessTime && b.accessTime) { return 1; }

      return Title.SORT_FUNCTIONS.titleShell(a, b);
    }
  };

  public static FILTER_FUNCTIONS = {
    filterByTitle: (s: RecentlyReadTitle, filterValue: string, caseSensitive = false) => {
      if (filterValue && s && s.titleRecord && s.titleRecord.title) {
        const normTitle = caseSensitive ? s.titleRecord.title : s.titleRecord.title.toLowerCase();
        const normFilter = caseSensitive ? filterValue : filterValue.toLowerCase();

        return normTitle.indexOf(normFilter) >= 0;
      }

      return true;
    },
    filterByTitles: (s: RecentlyReadTitle, filterValue: string, caseSensitive = false) => {
      if (filterValue && s && s.titleRecord && s.titleRecord.title) {
        let normTitle = caseSensitive ? s.titleRecord.title : s.titleRecord.title.toLowerCase();
        if (s.titleRecord.subtitle) { // include subtitle in this text compare
          const normSubtitle = caseSensitive ? s.titleRecord.subtitle : s.titleRecord.subtitle.toLowerCase();
          normTitle = `${normTitle} ${normSubtitle}`;
        }
        const normFilter = caseSensitive ? filterValue : filterValue.toLowerCase();

        return normTitle.indexOf(normFilter) >= 0;
      }

      return true;
    }
  };


  protected mapItemFromBank(attrs: BankedRecentlyRead): void {
    //this.mapFromBank(attrs);
    if (!attrs) {
      return;
    }

    this.titleSlug = attrs.titleSlug;
    this.lastAccessTime =  attrs.lastAccessTime;
  }


  protected mapItem(response: SentryPossession): void {
    this.titleSlug = response.id;
    // this.lastAccessTime =  response.lastAccessTime;
  }


  protected mapTitle(response: SentryPossession): TitleRecord {
    return TitleMapper.mapFromThunder(response);
  }


  protected serializeInternal(): BankedRecentlyRead {
    const out: BankedRecentlyRead = {
      titleSlug: this.titleSlug,
      lastAccessTime: this.lastAccessTime
    };

    return out;
  }
}

export interface BankedRecentlyRead {
  lastAccessTime?: number;
  titleSlug: string;
}
