<template>
  <LabeledDropdown
    v-model="selected"
    :options="options"
    :optionKey="(v) => v.slug"
    optionLabel="subtitle"
    :label="$t('series.volume')"
  />
</template>

<script lang='ts'>
import { defineComponent, computed, watch } from 'vue';
import { Title, TitleRecord } from 'app/models/title';
import LabeledDropdown from 'app/components/LabeledDropdown.vue';


export default defineComponent({
  name: 'VolumeSelect',
  components: {
    LabeledDropdown
  },
  props: {
    volumes: {
      type: Array as () => TitleRecord[],
      required: true
    },
    modelValue: {
      type: Object as () => TitleRecord,
      required: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const options = computed(() => props.volumes.slice().sort(Title.SORT_FUNCTIONS.seriesRank));
    const selected = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    watch(options, () => {
      const matchingVolume = selected.value && options.value.find((v) => v.slug === selected.value.slug);
      const defaultSelected = matchingVolume || options.value[0];
      selected.value = defaultSelected;
    }, { immediate: true, deep: true });

    return {
      options,
      selected
    };
  }
});
</script>

<style lang='less' module>
@import '../../app/views/core/base.less';

.volume-select {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;

  .label {
    align-self: center;
    text-transform: uppercase;
    color: @c-medium-black;
  }

  .dropdown {
    text-transform: none;
    display: inline-block;
  }
}

.dropdown-toggle {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.dropdown-header {
  text-transform: uppercase;
  font-size: @fs-metadata;
  color: @c-light-black;
}

.option-text {
  .pop-text(@c-primary-blue);
  color: @c-primary-blue;
  line-height: 25px;
  margin-right: 15px;
}

.option-text,
.selected-option-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
