import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "loading"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    ref: "img",
    src: _ctx.src,
    loading: _ctx.lazy ? 'lazy' : 'auto',
    "aria-hidden": true,
    class: _normalizeClass([_ctx.$style.cover, _ctx.bordered ? _ctx.$style.bordered : '']),
    style: _normalizeStyle(`--c-title: ${_ctx.coverColor}`),
    onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.error && _ctx.error(...args)))
  }, null, 46, _hoisted_1))
}