import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CopyAnnotationSnapshotCard = _resolveComponent("CopyAnnotationSnapshotCard")!
  const _component_CopyAnnotationSnapshotTable = _resolveComponent("CopyAnnotationSnapshotTable")!
  const _component_Collapsible = _resolveComponent("Collapsible")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass({
      [_ctx.$style.reviewSection]: true,
      [_ctx.$style.expanded]: _ctx.expanded
    })
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.expandable ? 'button' : 'div'), {
      id: `header-${_ctx.uuid}`,
      class: _normalizeClass({
        [_ctx.$style.reviewSectionHeader]: true,
        [_ctx.$style.expandable]: _ctx.expandable
      }),
      onClick: _ctx.toggleExpansion
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.icon)
        }, [
          _renderSlot(_ctx.$slots, "icon")
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.message)
        }, _toDisplayString(_ctx.message), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.description)
        }, _toDisplayString(_ctx.description), 3),
        (_ctx.expandable)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              class: _normalizeClass(_ctx.$style.chevron),
              name: "chevron-down"
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 3
    }, 8, ["id", "class", "onClick"])),
    _createVNode(_component_Collapsible, {
      class: _normalizeClass(_ctx.$style.reviewSectionPanel),
      expanded: _ctx.expanded,
      panelId: `panel-${_ctx.uuid}`,
      headerId: `header-${_ctx.uuid}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.reviewSectionPanelContent)
        }, [
          (_ctx.mobile)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 0,
                class: _normalizeClass(_ctx.$style.cardList)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.snapshots, (snapshot) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: snapshot.uuid
                  }, [
                    _createVNode(_component_CopyAnnotationSnapshotCard, {
                      snapshot: snapshot,
                      hideNote: _ctx.hideNote
                    }, null, 8, ["snapshot", "hideNote"])
                  ]))
                }), 128))
              ], 2))
            : (_openBlock(), _createBlock(_component_CopyAnnotationSnapshotTable, {
                key: 1,
                snapshots: _ctx.snapshots,
                hideNote: _ctx.hideNote
              }, null, 8, ["snapshots", "hideNote"]))
        ], 2)
      ], undefined, true),
      _: 1
    }, 8, ["class", "expanded", "panelId", "headerId"])
  ], 2))
}