import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingPage = _resolveComponent("LoadingPage")!
  const _component_TextFilter = _resolveComponent("TextFilter")!
  const _component_SortSelect = _resolveComponent("SortSelect")!
  const _component_SubjectsList = _resolveComponent("SubjectsList")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      (_ctx.loadingState.state === 'loading')
        ? (_openBlock(), _createBlock(_component_LoadingPage, {
            key: 0,
            includeToolbar: true
          }))
        : (_openBlock(), _createBlock(_component_Page, {
            key: 1,
            header: _ctx.$t('subjects.header'),
            useBackToTop: true
          }, {
            toolbar: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.toolbar)
              }, [
                _createVNode(_component_TextFilter, {
                  modelValue: _ctx.textFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textFilter) = $event)),
                  label: _ctx.$t('subjects.textFilter')
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_SortSelect, {
                  modelValue: _ctx.selectedSort,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSort) = $event)),
                  options: _ctx.sortOptions,
                  optionLabel: "label",
                  optionKey: "label"
                }, null, 8, ["modelValue", "options"])
              ], 2)
            ]),
            default: _withCtx(() => [
              (_ctx.loadingState.state === 'ready')
                ? (_openBlock(), _createBlock(_component_TabView, {
                    key: 0,
                    tabs: _ctx.tabs,
                    "aria-label": _ctx.$t('subjects.header')
                  }, _createSlots({ _: 2 }, [
                    _renderList(_ctx.tabs, (tab) => {
                      return {
                        name: `panel-${tab.id}`,
                        fn: _withCtx(() => [
                          (tab.count > 0)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(tab.lists, (list) => {
                                return (_openBlock(), _createBlock(_component_SubjectsList, {
                                  key: list.id,
                                  class: _normalizeClass(_ctx.$style.list),
                                  headerTag: "h2",
                                  label: tab.id === 'all' ? _ctx.$t(list.label) : undefined,
                                  subjects: list.items
                                }, null, 8, ["class", "label", "subjects"]))
                              }), 128))
                            : (_openBlock(), _createBlock(_component_EmptyState, {
                                key: tab.id,
                                class: _normalizeClass(_ctx.$style.empty),
                                header: _ctx.$t('subjects.emptystate.header'),
                                content: tab.id == 'all' ? _ctx.$t('subjects.emptystate.contentAll') : _ctx.$t('subjects.emptystate.contentCategory')
                              }, null, 8, ["class", "header", "content"]))
                        ])
                      }
                    })
                  ]), 1032, ["tabs", "aria-label"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('subjects.error')), 1))
            ]),
            _: 1
          }, 8, ["header"]))
    ], undefined, true),
    _: 1
  }))
}