import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.breadcrumbs)
    ? (_openBlock(), _createElementBlock("nav", {
        key: 0,
        class: _normalizeClass(_ctx.$style.breadcrumbContainer),
        "aria-label": _ctx.$t('breadcrumbs.label'),
        role: "navigation"
      }, [
        (_ctx.singleDisplay)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_component_Icon, {
                name: "chevron-left",
                class: _normalizeClass(_ctx.$style.arrow)
              }, null, 8, ["class"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createElementVNode("button", {
                    class: _normalizeClass(_ctx.$style.label),
                    "data-breadcrumb_focus": "",
                    onClick: ($event: any) => (_ctx.goBack(index))
                  }, [
                    _createElementVNode("span", {
                      innerHTML: breadcrumb.label
                    }, null, 8, _hoisted_4)
                  ], 10, _hoisted_3)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("ol", {
              key: 1,
              class: _normalizeClass(_ctx.$style.breadcrumbList)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  _createElementVNode("button", {
                    class: _normalizeClass(_ctx.$style.label),
                    "data-breadcrumb_focus": "",
                    onClick: ($event: any) => (_ctx.goBack(index))
                  }, [
                    _createElementVNode("span", {
                      innerHTML: breadcrumb.label
                    }, null, 8, _hoisted_6)
                  ], 10, _hoisted_5),
                  _createVNode(_component_Icon, {
                    name: "chevron-right",
                    class: _normalizeClass(_ctx.$style.arrow)
                  }, null, 8, ["class"])
                ]))
              }), 128)),
              _createElementVNode("li", null, [
                _createElementVNode("span", {
                  "aria-current": "page",
                  innerHTML: _ctx.pageTitle
                }, null, 8, _hoisted_7)
              ])
            ], 2))
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}