import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { FreshableItem } from '../base/services/freshable';
import { ThunderCollectionDefinition } from '../base/thunder';
import { LibraryCard } from './library-card';
import { Device } from './library-cards';
import { ListItemType } from './list';
import { ListDefinition } from './lists';
/**
 * Represents all the list parameters specified in a list address string.
 *
 * Address schema:
 *
 *  source/[flags/flags/flags]/[format,format]/[params...]
 *
 *  sources:
 *    - curated-nn
 *    - generated-nn
 *    - spotlight-nn
 *    - publisher-nn
 *    - imprint-nn
 *    - creator-nn
 *    - search
 *
 *  flags:
 *    - available
 *    - prerelease
 *    - readalong
 *
 *  formats:
 *    - books
 *    - audiobooks
 *    - (videos)
 *    - (magazines)
 *
 *  subject params:
 *    - subject-19,25 (multi)
 *      comma-separated subject ids
 *
 *  search params:
 *    - query-escaped+search+query
 *    - title-escaped+title+query
 *    - creator-escaped+creator+query
 *    - series-escaped+series+query
 *    - identifier-escaped+isbn+query
 *
 *  date range:
 *    - days-0-7
 *    - days-0-14
 *    - days-0-30
 *    - days-0-90
 *    - days-0-180
 *
 *  bisac codes:
 *    - bisac-FIC053000 - Amish Fiction
 *    - bisac-FIC053000,FIC019000 - Amish Fiction that is ALSO Literary Fiction
 *
 *  scope: owned|unowned|recommendable|global
 *
 *  preferences:
 *    - language-en,es,ch (multi)
 *      comma-separated ISO 3166-1-alpha-2 country codes
 *      https: *en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 *    - device-kindle,kobo (multi)
 *
 *
 * Valid addresses:
 *
 *   All titles in curated collection 32:
 *     curated-32
 *
 *   All books in generated collection 37:
 *     generated-37/books
 *
 *   All audiobooks from publisher 118:
 *     publisher-118/audiobooks
 *
 *   All titles by creator 3245 with subject 17:
 *     creator-3245/subject-17
 *
 *   All available titles in both subjects 88 AND 9 in Spanish:
 *     subject-88,9/available/language-es
 *
 *   A search with some filtering parameters:
 *     search/query-my+query/available/audiobooks
 *
 *   An advanced search with no general search query:
 *     search/readalong/title-Olivia
 *
 *   An improbable query on a series, with a bit of everything:
 *     search/available/books/series-唐家三少/subject-115/language-cn/audience-4
 *     (All ready-to-borrow adult-only Chinese-language books in the 唐家三少 series)
 *
 *
 * Notes:
 *
 *  Subject can be 1) a list source, or 2) a filter on another list source:
 *    1) subject-88,9/book
 *    2) curated-32/subject-88
 *
 *  Formats: multiple formats can be specified, but Thunder does not yet
 *    support returning results for more than one media type. Only the first
 *    media type will be used.
 *
 *  Languages: multiple languages can be specified. Only one is currently
 *    supported by Thunder, but multiples are planned. It is not yet known
 *    whether they will be ANDed or ORed by Thunder.
 */

export class ListParameters extends FreshableItem<ThunderCollectionDefinition> {
  public series: any;
  public dateRange: any;
  public bisacCodes: any[];
  public identifier: any;
  public title: any;
  public creator: any;
  public publisher: any;
  public imprint: any;
  public query: any;
  public sort: any;
  public maxItems: any;
  public breakpoint: string;
  public devices: any[];
  public languages: any[];
  public flagReadalong: any;
  public flagPrerelease: any;
  public flagAvailableFirst: any;
  public flagAvailable: any;
  public formats: any[];
  public subjects: string[];
  public practiceAreas: string[];
  public jurisdictions: string[];
  public classifications: string[];
  public id: string;
  public source: ListSource;
  public definition: any;
  public contentMask: number;

  public get canBeSetOrTitle(): boolean {
    return ['search', 'subject', 'publisher', 'creator', 'imprint'].indexOf(this.source) >= 0;
  }

  public static MULTI_PARAMS = ['language', 'audience', 'device'];
  public static QUERY_PARAMS = ['query', 'title', 'creator', 'series', 'identifier'];
  public static ALLOWED_SORTS = ['relevance', 'title', 'releasedate', 'listorder', 'seriesname'];
  public static DEFAULT_SORT = {
    default: ['relevance', 'title', 'releasedate'],
    everything: ['title', 'releasedate'],
    publisher: ['title', 'releasedate'],
    imprint: ['title', 'releasedate'],
    subject: ['title', 'releasedate'],
    search: ['relevance', 'title', 'releasedate'],
    curated: ['listorder', 'title', 'releasedate']
  };
  public static RELATED_PARAMS = [
    'creator',
    'publisher',
    'imprint',
    'series'
  ];


  constructor() {
    super('list-params', C.MS_DAY * 7);
    this.source = 'unknown';
    this.id = null;
    this.subjects = [];
    this.formats = [];
    this.flagAvailable = null;
    this.flagAvailableFirst = null;
    this.flagPrerelease = null;
    this.flagReadalong = null;
    this.languages = [];
    this.devices = [];
    this.query = null;
    this.title = null;
    this.creator = null;
    this.publisher = null;
    this.imprint = null;
    this.series = null;
    this.identifier = null;
    this.bisacCodes = [];
    this.dateRange = null;
    this.sort = null;
    this.maxItems = null;
    this.breakpoint = null;
    this.definition = null;
    this.contentMask = null;
  }


  public loadFromAddress(addr) {
    return C.absorb(this._parseAddress(addr), this);
  }


  public async freshen(raiseNetworkError?: boolean): Promise<boolean> {
    if (this.source === 'generated' || this.source === 'curated') {
      console.assert(APP.library !== null, '[LIST] need APP.library for: %o', this);
      const listDef = APP.library.lists.listDefinition(this.source, this.id);
      if (listDef) {
        this._applyThunderListDefinition(listDef);
      } else {
        return super.freshen(raiseNetworkError);
      }
    } else if (this.source === 'spotlight') {
      console.assert(APP.library !== null, '[LIST] need APP.library for: %o', this);
      const listDef = APP.library.lists.listDefinition(this.source, this.id);
      console.assert(!!listDef, '[LIST] spotlight list not found', this.id);
      this._applySpotlightListDefinition(listDef);
    }

    this.freshness.freshAt = C.epochMilliseconds();

    return true;
  }


  protected freshenCall(): Promise<ThunderCollectionDefinition | null> {
    return APP.services.thunder.getCollectionDefinition(APP.library.key(), this.id);
  }


  protected freshenMap(response: ThunderCollectionDefinition): void {
    this.parseFreshenResponse(response);
  }


  public arePropertiesFresh(): boolean {
    return true;
  }


  /**
   * Helper to determine if a specified subjectID is within any of the subject groups.
   * @param subjectID The subject ID to search for
   * @returns true if the specified subject ID is found, otherwise; false
   */
  public isSubjectApplicable(subjectID: string): boolean {
    if (!this.subjects || !this.subjects.length) {
      return false;
    }

    let found = false;
    C.each(this.subjects, (subjectGroup) => {
      if (subjectGroup.indexOf(subjectID) >= 0) {
        found = true;
      }
    });

    return found;
  }


  /**
   * The 'composite' is only available after freshening, and represents
   * the full set of parameters:
   * - base (refinements)
   * - preferences (list preferences)
   * - definition (collection defaults)
   */
  public composite(options?: SearchOptions): SearchOptions {
    const comp = this._baseLayer();
    if (this.definition) {
      this._applyLayer(this.definition, comp, 'append');
    }

    return comp;
  }


  /**
   * Generates the list-parameter portion of the nav path to this list, eg:
   * - subject-11,23/books/most-popular
   * @param includePreferences
   */
  public address() {
    const segs = [];
    const sep = ',';
    const base = this._baseLayer();

    segs.push(base.source + (base.id ? '-' + base.id : ''));

    if (base.scope) {
      segs.push('scope-' + base.scope);
    }
    if (base.flagAvailable) {
      segs.push('available');
    } else if (base.flagAvailable === false) {
      segs.push('everything');
    }
    if (base.flagPrerelease) {
      segs.push('prerelease');
    }
    if (base.flagReadalong) {
      segs.push('readalong');
    }
    if (base.formats && base.formats.length) {
      const fmts = [];
      for (const format of base.formats) {
        fmts.push(C.pluralize(format));
      }
      segs.push(fmts.join(sep));
    }
    C.each(ListParameters.QUERY_PARAMS, (key) => {
        const val = base[key];
        if (val && base.source !== key) {
          segs.push(key + '-' + val);
        }
      }
    );
    C.each(ListParameters.RELATED_PARAMS, (key) => {
      const val = base[key];
      if (val && base.source !== key && base.source !== 'generated') {
        segs.push(key + '-' + val);
      }
    });
    C.each(
      ListParameters.MULTI_PARAMS,
      (key) => {
        const vals = base[key + 's'];
        if (vals && vals.length && base.source !== key) {
          segs.push(key + '-' + vals.join(sep));
        }
      }
    );
    if (base.bisacCodes && base.bisacCodes.length) {
      segs.push('bisac-' + base.bisacCodes.join(sep));
    }
    if (base.dateRange) {
      segs.push(base.dateRange);
    }
    if (base.sort) {
      segs.push('sort-' + base.sort);
    }
    if (base.maxItems) {
      segs.push('max-' + base.maxItems);
    }
    if (base.breakpoint) {
      segs.push('contd-' + base.breakpoint);
    }

    if (base.subjects && base.subjects.length) {
      // Remove the string quotes to reduce the number of characters in the url
      segs.push(`subjects-${JSON.stringify(base.subjects).replace(/"/g, '')}`);
    }

    if (base.practiceAreas && base.practiceAreas.length) {
      segs.push('practice-' + base.practiceAreas.join(sep));
    }

    if (base.jurisdictions && base.jurisdictions.length) {
      segs.push('jurisdiction-' + base.jurisdictions.join(sep));
    }

    if (base.classifications && base.classifications.length) {
      segs.push('classification-' + base.classifications.join(sep));
    }

    return segs.join('/');
  }


  public rootAddress() {
    if (this.source === 'search') {
      const segs = [`query-${this.query}`];
      // Parse parameters from the address that can come from advanced search
      // and would affect the list description.
      const advancedParams = [
        'prerelease',
        'readalong',
        'days'
      ].concat(ListParameters.QUERY_PARAMS);
      const pattern = new RegExp(`^(${advancedParams.join('|')})`);
      const addressParams = this.address().split('/');
      for (const param of addressParams) {
        if (param.match(pattern)) {
          segs.push(param);
        }
      }

      return segs.join('/');
    }

    return this.address().match(/[^\/,]+/)[0];
  }


  public addressWithRefinements(properties, includePreferences) {
    const clone = new ListParameters();
    clone.loadFromAddress(this.address());
    C.absorb(properties, clone);

    return clone.address();
  }


  /**
   * Generates a Thunder API url based on all the list parameters
   * @param lib
   * @param options Options for the list parameters to be built
   */
  public apiEndpoint(itemType: ListItemType, options?: SearchOptions) {
    const args = [];
    const base = this.composite(options);

    if (itemType === 'title') {
      this._addTitleArgs(base, args);
    } else if (itemType === 'set') {
      this._addSetArgs(base, args);
    }
    // Source + ID
    if (base.id) {
      if (base.source === 'curated') {
        args.push('collectionId=' + base.id.replace(/-.*/, ''));
      }
    }

    // Publisher
    if (base.publisher) {
      let publisherParam = 'publisherId';
      let publisherId = base.publisher;
      if (base.publisher.match(/^ent-/)) {
        publisherParam = 'publisherEntityId';
        publisherId = base.publisher.replace(/^ent-/, '');
      }
      args.push(publisherParam + '=' + publisherId);
    }
    // Formats
    C.each(base.formats, (fmt) => {
      args.push('mediaType=' + Constants.toThunderMediaType(fmt));
    });
    // Search queries, title, creator, series, identifier (ISBN)
    C.each(ListParameters.QUERY_PARAMS, (q) => {
      if (base[q]) {
        if (q === 'creator') {
          if (base[q].match(/^\d+$/)) {
            args.push('creatorId=' + base.creator);
          } else {
            args.push('creator=' + base.creator);
          }
        } else {
          args.push(q + '=' + base[q]);
        }
      }
    });
    // Languages
    C.each(base.languages, (lang) => {
      if (lang !== '0') {
        args.push('language=' + lang);
      }
    });
    // Date range
    if (base.dateRange) {
      args.push('addedDate=' + base.dateRange);
    }
    // Sort
    const sorts = (ListParameters.DEFAULT_SORT[base.source]) ?
      ListParameters.DEFAULT_SORT[base.source].slice(0) :
      ListParameters.DEFAULT_SORT.default.slice(0);

    if (base.maxItems || !base.query) {
      C.excise(sorts, 'relevance');
    }

    if (base.sort) {
      const baseSortIndex = sorts.indexOf(base.sort);
      if (baseSortIndex >= 0) {
        sorts.splice(baseSortIndex, 1);
      }
      sorts.unshift(base.sort);
    }

    if (itemType === 'set') {
      sorts.splice(1);
      if (sorts[0] === 'title') {
        sorts[0] = 'seriesname';
      }
    }

    C.each(sorts, (s) => {
      args.push(`sortBy=${s}`);
    });

    return args.length ? '?' + args.join('&') : '';
  }


  protected _addTitleArgs(base: SearchOptions, args: any[]): any[] {
    const card: LibraryCard = APP.patron.currentCard();
    if (base.id && base.source === 'subject') {
      args.push(`subject=${base.id}`);
    } else if (base.subjects?.length) {
      args.push(`subject=${base.subjects.join(',')}`);
    }

    // Imprint
    if (base.imprint) {
      args.push('imprintId=' + base.imprint);
    }
    // Scope
    if (base.scope) {
      args.push('show=' + ({ global: 'all' }[base.scope] || base.scope));
    }
    // Flags
    if (!card.canPlaceHolds || base.flagAvailable) {
      args.push('showOnlyAvailable=true');
    }
    if (base.flagAvailableFirst) {
      args.push('availableFirst=true');
    }
    if (base.flagPrerelease) {
      args.push('showOnlyPrerelease=true');
    }
    if (base.flagReadalong) {
      args.push('hasAudioSynchronizedText=true');
    }
    // Devices
    C.each(
      base.devices,
      (device) => {
        const fft = Constants.FULFILLMENT_FORMAT_TYPES[device];
        if (fft) {
          args.push('format=' + fft.thunderId);
        }
      }
    );
    // BISAC code
    C.each(
      base.bisacCodes,
      (bisacCode) => {
        args.push('bisacCode=' + bisacCode);
      }
    );

    // Max items
    if (base.maxItems) {
      args.push('maxItems=' + base.maxItems);
    }
    // Breakpoint (for maxItems continuation)
    if (base.breakpoint) {
      args.push('breakpoint=' + base.breakpoint);
    }

    // On title searches, we fake the lexis categories
    // using subjects
    if (base.practiceAreas?.length) {
      args.push(`subjectOrBisac=${base.practiceAreas.join(',')}`);
    }
    if (base.classifications?.length) {
      args.push(`subject=${base.classifications.join(',')}`);
    }
    if (base.jurisdictions?.length) {
      args.push(`subject=${base.jurisdictions.join(',')}`);
    }

    return args;
  }


  protected _addSetArgs(base: SearchOptions, args: any[]): any[] {
    if (base.id && base.source === 'subject') {
      const c = APP.library.catalog.getSubjectCategory(base.id);
      if (c && c !== 'Subject') {
        args.push(`${c[0].toLowerCase() + c.slice(1)}=${base.id}`);
      } else {
        args.push(`subject=${base.id}`);
      }
    } else if (base.subjects?.length) {
      C.each(base.subjects, (p) => {
        args.push(`subject=${p}`);
      });
    }

    if (base.practiceAreas?.length) {
      C.each(base.practiceAreas, (p) => {
        args.push(`practiceArea=${p}`);
      });
    }
    if (base.classifications?.length) {
      C.each(base.classifications, (p) => {
        args.push(`classification=${p}`);
      });
    }
    if (base.jurisdictions?.length) {
      C.each(base.jurisdictions, (p) => {
        args.push(`jurisdiction=${p}`);
      });
    }

    return args;
  }


  protected _parseAddress(addr: string) {
    const segs = addr.split('/');
    const out: any = {};
    if (['everything', 'search'].indexOf(segs[0]) >= 0) {
      out.source = segs.shift();
    } else {
      const sourceMatch = segs[0].match(/^([^-]+)-(.+)$/);
      if (sourceMatch) {
        out.source = sourceMatch[1];
        if (out.source !== 'subjects') {
          out.id = sourceMatch[2];
          segs.shift();
          if (typeof this[out.source] !== 'undefined') {
            out[out.source] = out.id;
          }
        }
      }
    }
    C.each(
      segs,
      (seg) => {
        const parts = seg.split('-');
        const key = parts.shift();
        const val = parts.join('-');
        this._parseKeyVal(key, val, out);
      }
    );

    return out;
  }


  protected _parseKeyVal(key: string, val, out) {
    if (key === 'scope') {
      out.scope = val;
    } else if (key === 'available' && !val) {
      out.flagAvailable = true;
    } else if (key === 'everything' && !val) {
      out.flagAvailable = false;
    } else if (key === 'prerelease' && !val) {
      out.flagPrerelease = true;
    } else if (key === 'readalong' && !val) {
      out.flagReadalong = true;
    } else if (key.match(/^(books)|(audiobooks)/)) {
      out.formats = [];
      C.each(key.split(','), (fmt) => {
        const depluralizedFmt = fmt.replace(/s$/, '');
        if (Constants.formats.indexOf(depluralizedFmt) >= 0) {
          out.formats.push(depluralizedFmt);
        }
      });
    } else if (key.match(/^(magazines)|(videos)/)) {
      out.invalid = 'unsupported mediatype - ' + key;
    } else if (key === 'bisac') {
      out.bisacCodes = val.split(',');
    } else if (key === 'days') {
      out.dateRange = key + '-' + val;
    } else if (key === 'sort') {
      out.sort = val;
    } else if (key === 'max') {
      out.maxItems = parseFloat(val);
    } else if (key === 'contd') {
      out.breakpoint = val;
    } else if (key === 'subject') {
      out.subject = val;
    } else if (key === 'subjects') {
      out.subjects = JSON.parse(val, (_, jsonVal) => {
        // Convert our subjectID's back to strings instead of numbers
        return (Array.isArray(jsonVal)) ? jsonVal : jsonVal.toString();
      });
    } else if (key === 'practice') {
      out.practiceAreas = val.split(',');
    } else if (key === 'classification') {
      out.classifications = val.split(',');
    } else if (key === 'jurisdiction') {
      out.jurisdictions = val.split(',');
    } else if (ListParameters.MULTI_PARAMS.indexOf(key) > -1) {
      out[`${key}s`] = val.split(',');
    } else if (
      ListParameters.QUERY_PARAMS.indexOf(key) > -1
      || ListParameters.RELATED_PARAMS.indexOf(key) > -1
    ) {
      out[key] = val;
    }
  }


  protected parseFreshenResponse(response: ThunderCollectionDefinition) {
    const listDef = APP.library.lists.addListDefinition(this.source, response);
    this._applyThunderListDefinition(listDef);
  }


  protected _applyThunderListDefinition(def: ListDefinition) {
    const out: any = {};
    out.name = def.name;
    out.description = def.description;
    if (def.showOnlyAvailable) {
      out.flagAvailable = true;
    }
    if (def.sortByAvailability) {
      out.flagAvailableFirst = true;
    }

    if (def.mediaTypes?.length || def.mediaType) {
      const mediaTypes = def.mediaTypes || [def.mediaType];
      try {
        out.formats = mediaTypes.map((mediaType) => {
          const fmt = Constants.toMediaType(mediaType || '');
          if (!fmt) { throw new Error(`unknown mediatype: ${mediaType}`); }

          return fmt;
        });
      } catch (ex) {
        out.invalid = ex.message;
      }
    }

    if (def.sortBy) {
      out.sort = def.sortBy;
    }
    if (def.subjects) {
      out.subjects = def.subjects;
    }
    if (def.publisherEntityId) {
      out.publisher = 'ent-' + def.publisherEntityId;
    }
    if (def.language) {
      out.languages = [def.language];
    }
    if (def.maxItems) {
      out.maxItems = def.maxItems;
    }
    if (def.isOrdered) {
      out.isOrdered = true;
    }
    out.itemType = def.itemType
      ? def.itemType === 'Series' ? 'set' : 'title'
      : 'title';
    this.definition = out;
  }


  protected _applySpotlightListDefinition(def) {
    this.definition = this._parseAddress(def.address);
    this.definition.name = def.head;
    this.definition.description = def.lede;
  }


  protected _baseLayer() {
    const layer: any = {};
    C.each(this, (key, val) => {
      if (
        !key.match(/^_/) &&
        ['definition', 'freshness'].indexOf(key) < 0 &&
        typeof val !== 'function'
      ) {
        layer[key] = val;
      }
    });

    return C.jsonClone(layer);
  }


  protected _applyLayer(layer, base, mode) {
    C.each(layer, (key, val) => {
      if (typeof base[key] !== 'undefined') {
        if (mode === 'replace') {
          base[key] = val;
        } else if (mode === 'preserve') {
          if (typeof val !== 'undefined' && (base[key] === null || base[key].length === 0)) {
            base[key] = val;
          }
        } else if (mode === 'append') {
          if (base[key] instanceof Array) {
            C.each(<unknown[]>val, (v) => {
              const strV = v + ''; // Normalize to string for comparison
              if (base[key].indexOf(strV) < 0) {
                base[key].push(strV);
              }
            });
          } else if (key === 'sort' && base[key] && base[key] !== '0') {
            // Ignore the collection's default sort if a sort refinement/list preference is applied.
          } else if (typeof val !== 'undefined') {
            base[key] = val;
          }
        }
      }
    });
  }
}

export interface SearchOptions {
  ignorePreferences?: boolean;
  makeStale?: boolean;
  limit?: number;

  // List options
  source?: ListSource;
  id?: string;
  maxItems?: string;
  breakpoint?: string;

  // General search options
  query?: string;
  includeFacets?: boolean;
  publisher?: string;
  imprint?: string;
  scope?: 'global';
  flagAvailable?: boolean;
  flagAvailableFirst?: boolean;
  flagPrerelease?: boolean;
  flagReadalong?: boolean;
  formats?: string[];
  creator?: string;
  subjects?: string[];
  practiceAreas?: string[];
  jurisdictions?: string[];
  classifications?: string[];
  languages?: string[];
  devices?: Device[];
  bisacCodes?: string[];
  dateRange?: string;
  sort?: string;
}

type ListSource =
   'generated'
  | 'curated'
  | 'spotlight'
  | 'search'
  | 'subject'
  | 'publisher'
  | 'creator'
  | 'imprint'
  | 'series'
  | 'everything'
  | 'collection'
  | 'unknown';
