import MemoryStorage from 'app/base/storage/memory-storage';

type CacheOptions = {
  // Maximum age of entries in milliseconds.
  // No data will be returned if the entry is too old.
  maxAge?: number;

  // Maximum number of items to store at once.
  // Oldest items will be deleted to free space.
  maxItems?: number;
};

type CacheItem = {
  // Epoch millisecond timestamp of last update
  timestamp: number;

  // Stringified data entry
  entry: string;
};

/* MemoryStorage variant that adds some cache maintenance functionality.
 * - managing entry timestamps and expiration
 * - limiting maximum entries
 *
 * Warnings:
 * - key and length may return results including expired entries
 * - getItem will remove the accessed item if it's expired as a side effect
 * - setItem may remove items when out of space
 */
export default class CachedStorage extends MemoryStorage {
  private readonly maxAge: number;
  private readonly maxItems: number;

  constructor(
    {
      maxItems = Infinity,
      maxAge = 1000 * 60 * 5
    }: CacheOptions = {}
  ) {
    super();

    this.maxAge = maxAge;
    this.maxItems = maxItems;
  }

  public getItem(key: string): string | null {
    const stored = super.getItem(key);
    if (!stored) { return null; }

    const cacheItem: CacheItem = JSON.parse(stored);
    if (Date.now() - cacheItem.timestamp > this.maxAge) {
      this.removeItem(key);

      return null;
    }

    return cacheItem.entry;
  }

  public setItem(key: string, value: string): void {
    // If we're out of room, remove the first inserted key
    // Note: We may want to optimize this to clear expired entries first and
    // track the oldest items, but this is less expensive and less logic
    if (this.length >= this.maxItems && !this.store.has(key)) {
      const earliestInserted = this.key(0);

      if (earliestInserted) {
        this.removeItem(earliestInserted);
      }
    }

    const cacheItem: CacheItem = {
      timestamp: Date.now(),
      entry: value
    };

    super.setItem(key, JSON.stringify(cacheItem));
  }
}
