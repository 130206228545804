<template>
  <WelcomeView>
    <section :class="$style.container">
      <h1 :class="$style.header">
        {{ $t('signedOut.header') }}
      </h1>
      <p :class="$style.message">
        {{ $t('signedOut.message', { LIBRARY: libraryName }) }}
      </p>
      <button
        :class="$style.action"
        type="button"
        @click="goToTarget"
      >
        {{ $t('signedOut.action') }}
      </button>
    </section>
  </WelcomeView>
</template>

<script lang='ts'>
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { Library } from 'app/models/library';
import WelcomeView from 'app/components/WelcomeView.vue';
import { RouteName } from '../router/constants';
import { useRouter } from 'vue-router';
import env from 'app/base/env';

export default defineComponent({
  components: {
    WelcomeView
  },
  props: {
    libraryId: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup: (props, ctx) => {
    const library = ref<Library | null>(null);
    const libraryName = computed(() => library.value?.name || '');

    const router = useRouter();
    const targetLocation = computed(() => {
      return props.target
        || router.resolve({ name: RouteName.LibraryHome, params: { libraryId: props.libraryId } }).href
        || env.ROOT_URI;
    });

    const goToTarget = () => {
      window.location.replace(targetLocation.value);
    };

    watchEffect(async () => {
      if (props.libraryId) {
        library.value = await Library.resolve({
          baseKey: props.libraryId
        });
      }
    });

    return {
      goToTarget,
      libraryName
    };
  }
});
</script>

<style lang='less' module>
  @import '../../app/views/core/base.less';

  .container {
    .welcome-panel;
  }

  .header {
    text-align: center;
    font-size: @fs-medium-head;
    font-weight: @fw-bold;
    color: @c-dark-black;
    margin-bottom: 2rem;
  }

  .message {
    color: @c-light-black;
    margin-bottom: 2rem;
    text-align: center;
  }

  .action {
    display: block;
    width: 100%;
    background: @c-primary-red;
    border-radius: @px-border-radius;
    border: none;
    color: @c-white;
    font-size: @fs-med;
    font-weight: @fw-bold;
    padding: 1rem;
    text-shadow: none;
    text-transform: uppercase;
  }
</style>
