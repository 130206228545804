<template>
  <PromptDialog
    ref="prompt"
    :heading="$t('circ.newRelease.header')"
    :dismissible="false"
    @submit="prompt?.closeModal()"
    @close="$emit('open')"
  >
    <p
      :id="`newrelease-explanation-${titleId}`"
    >
      {{ $t('circ.newRelease.description') }}
    </p>

    <p>
      {{ $t('circ.newRelease.downloadMessage') }}
    </p>

    <FormSubmitButton
      autofocus
      :label="$t('general.continue')"
      :enabled="true"
      size="small"
    />
  </PromptDialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import FormSubmitButton from './FormSubmitButton.vue';
import PromptDialog from './dialogs/PromptDialog.vue';

export default defineComponent({
  name: 'NewReleasePrompt',
  components: {
    PromptDialog,
    FormSubmitButton
  },
  props: {
    titleId: {
      type: String,
      default: 'titleId'
    }
  },
  emits: [
    'open'
  ],
  setup: () => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);

    return {
      prompt
    };
  }
});
</script>

<style module>
</style>
