import { APP } from 'app/base/app';
import { C } from 'app/base/common';
export class Network {
  public static MIN_CHECK_INTERVAL_MS = 1000;
  public reachable = false;
  public isPrecise = false;
  public metered = false;
  public connection: any;
  private _onPrimedCallback?: Function;
  private _lastCheckMS?: number;
  private _checkPending = false;


  public prime(onPrimedCallback: Function): void {
    this._onPrimedCallback = onPrimedCallback;
    this.isPrecise = APP.shell.has('network:info') && APP.shell.info.spec! >= 7;
    if (this.isPrecise) {
      APP.events.on('msg:network:info', (evt) => this._onInfo(evt.m));
    } else {
      APP.events.on('online', () => this._webInfo(), window);
      APP.events.on('offline', () => this._webInfo(), window);
    }
    this.check();
  }


  /**
   * Asks the shell for the current network information. If we've requested
   * or received network information in the last second, don't pester the
   * shell, just re-use what we were given.
   * @param force
   */
  public check(force?: boolean): void {
    if (this._checkPending) {
      return;
    }
    if (this.isPrecise) {
      const now = C.epochMilliseconds();
      const checkInterval = now - (this._lastCheckMS || 0);
      if (force || checkInterval > Network.MIN_CHECK_INTERVAL_MS) {
        this._lastCheckMS = now;
        this._checkPending = true;
        APP.shell.transmit('network:info');
      } else {
        this._announce();
      }
    } else {
      this._webInfo();
    }
  }


  protected _onInfo(evt: Network): void {
    this._lastCheckMS = C.epochMilliseconds();
    this._checkPending = false;
    const info = evt;
    const delta: Delta = {
      reachable: [this.reachable, info.reachable],
      metered: [this.metered, info.metered],
      connection: [this.connection, info.connection]
    };
    this.reachable = info.reachable;
    this.metered = info.metered;
    this.connection = info.connection;
    APP.semaphore.set(
      'network',
      info.reachable ? (info.metered ? 'metered' : 'unmetered') : 'unreachable'
    );
    this._announce(delta);
  }


  protected _webInfo(): void {
    const delta: Delta = {
      reachable: [this.reachable, navigator.onLine]
    };
    this.reachable = navigator.onLine;
    this.metered = false;
    APP.semaphore.set('network', this.reachable ? 'unmetered' : 'unreachable');
    this._announce(delta);
  }


  protected _announce(delta: Delta = {}): void {
    APP.events.dispatch('network:info', { network: this, delta: delta });
    if (typeof this._onPrimedCallback === 'function') {
      const onPrimedCallback = this._onPrimedCallback;
      delete this._onPrimedCallback;
      onPrimedCallback(this);
    }
  }
}

export interface Delta {
  reachable?: [boolean, boolean];
  metered?: [boolean, boolean];
  connection?: [boolean, boolean];
}
