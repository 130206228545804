import { APP } from 'app/base/app';
import { Catfish } from 'app/base/catfish';
import { C, Dictionary } from 'app/base/common';
import env from 'app/base/env';
import { GeoIp } from 'app/base/geo-ip';
import { NTC } from 'app/base/ntc';
import { Quirks } from 'lib/quirkbase/quirkbase';
import { AutocompleteService } from '../autocomplete-service';
import { Hudson } from '../hudson';
import { Postish } from '../postish';
import { Taggish } from '../taggish';
import { Thunder } from '../thunder';
import { Service } from './service';
import { Samwise } from '../samwise';

export class Services {
  public elrond!: Service;
  public sentry!: Service;
  public hudson!: Hudson;
  public catfish!: Catfish;
  public thunder!: Thunder;
  public geoIp!: GeoIp;
  public ntc!: NTC;
  public autocomplete!: AutocompleteService;
  public postish!: Postish;
  public taggish!: Taggish;
  public sage!: Service;
  public samwise!: Samwise;


  constructor() {
    this._assembleFleet();
  }


  protected _assembleFleet(): void {
    this.elrond = new Service('ELROND-SERVICE', '^', `${env.ROOT_URI}/api/`);
    this.sentry = new Service('SENTRY', (path) => {
      const sentryURI = env.SENTRY_URI + '/';
      const serviceParams: Dictionary<string> = {};
      if (Quirks.has('send-nautilus-origin-for-cors')) {
        serviceParams._nautilus_origin = location.origin;
      }
      if (Quirks.has('send-chip-as-query-param') && APP.sentry) {
        serviceParams._chip = APP.sentry.chip;
      }

      return { url: C.parameterizeURL(sentryURI + path, serviceParams) };
    });
    this.thunder = new Thunder();
    this.geoIp = new GeoIp();
    this.ntc = new NTC();
    this.hudson = new Hudson();
    this.catfish = new Catfish();
    this.autocomplete = new AutocompleteService();
    this.postish = new Postish();
    this.taggish = new Taggish();
    this.samwise = new Samwise();
    if (env.SAGE_URI) {
      this.sage = new Service('SAGE', (path) => {
        const uri = env.SAGE_URI + '/';
        const serviceParams = {};

        return { url: C.parameterizeURL(uri + path, serviceParams) };
      });
    }
  }
}
