import { SeriesSubscription, TitleSubscription } from 'app/base/samwise';
import { useSeries } from 'app/functions/use-series';
import { useSubscriptions } from 'app/functions/use-subscriptions';
import { useTitles } from 'app/functions/use-titles';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { computed } from 'vue';

export type TitleSubscriptionWithMetadata = TitleSubscription & { title: TitleRecord };
export type SeriesSubscriptionWithMetadata = SeriesSubscription & { series: Series; titleIds: string[] };
export type SubscriptionWithMetadata = TitleSubscriptionWithMetadata | SeriesSubscriptionWithMetadata;

export function useSubscriptionsWithMetadata() {
  const {
    subscriptions,
    loading: subscriptionsLoading,
    error: subscriptionsError,
    ...modifySubscriptionsFuncs
  } = useSubscriptions();

  const titleIds = computed<Set<string>>(() => {
    if (!subscriptions.value) { return new Set(); }

    const ids = subscriptions.value.flatMap((sub) => {
      if (sub.type === 'Title') {
        return [sub.titleId];
      }

      return [];
    }).map((id) => id.toString());

    return new Set(ids);
  });

  const {
    titles,
    loading: titlesLoading,
    error: titlesError
  } = useTitles(titleIds);

  const seriesIds = computed<Set<number>>(() => {
    if (!subscriptions.value) { return new Set(); }

    const ids = subscriptions.value.flatMap((sub) => {
      if (sub.type === 'Series') {
        return [sub.seriesId];
      }

      return [];
    });

    return new Set(ids);
  });

  const {
    series,
    loading: seriesLoading,
    error: seriesError
  } = useSeries(seriesIds);

  const subscriptionsWithMetadata = computed<SubscriptionWithMetadata[]>(() => {
    if (!subscriptions.value) { return []; }
    if (!titles.value) { return []; }
    if (!series.value) { return []; }

    const titlesData = titles.value;
    const seriesData = series.value;

    return subscriptions.value.flatMap((sub) => {
      if (sub.type === 'Series') {
        const matchingSeries = seriesData.find((s) => s.id === sub.seriesId);
        if (!matchingSeries) { return []; }

        return {
          ...sub,
          series: matchingSeries,
          titleIds: matchingSeries.items.map((i) => i.id)
        } as SubscriptionWithMetadata;
      }

      // Title subscriptions

      const matchingTitle = titlesData.find((t) => t.slug === sub.titleId.toString());

      return {
        ...sub,
        title: matchingTitle
      } as SubscriptionWithMetadata;

    });
  });

  const loading = computed(() =>
    subscriptionsLoading.value ||
    titlesLoading.value ||
    seriesLoading.value
  );

  const error = computed(() =>
    subscriptionsError.value ||
    titlesError.value ||
    seriesError.value
  );

  return {
    subscriptionsWithMetadata,
    loading,
    error,
    ...modifySubscriptionsFuncs
  };
}
