  // Credit: http://stackoverflow.com/a/5624139
  //
  export function hexToRGB(hex: string): [number, number, number] | undefined {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    const rgbHex = hex.replace(shorthandRegex, (m, r: string, g: string, b: string) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(rgbHex);

    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : undefined;
  }


  export function rgbToHex(rgb: [number, number, number]): string {
    const nToHex = (n: number) => {
      const hex = n.toString(16);

      return hex.length === 1 ? `0${hex}` : hex;
    };

    return `#${nToHex(rgb[0])}${nToHex(rgb[1])}${nToHex(rgb[2])}`;
  }


  export function rgbStringToHex(str: string): string | undefined {
    const match = str.match(/rgba?\((\d+),\s?(\d+),\s?(\d+)/);

    if (!match) {
      return undefined;
    }

    const rgb = match.map((s) => parseInt(s, 10)) as [number, number, number];

    return rgbToHex(rgb);
  }


  export function rgbToString(rgb: [number, number, number]): string {
    return `rgb(${rgb.join(',')})`;
  }


  export function colorYIQ(rgb: [number, number, number]): number {
    return ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000;
  }


  // Lighten or darken a color by a given percentage.
  //
  // 1 to make white, -1 to make black, 0 to leave unchanged.
  //
  export function shadeColor(color: [number, number, number], percent: number): [number, number, number] {
    const r = color[0];
    const g = color[1];
    const b = color[2];
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;

    return [
      (Math.round((t - r) * p) + r),
      (Math.round((t - g) * p) + g),
      (Math.round((t - b) * p) + b)
    ];
  }
