import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VolumeSelect = _resolveComponent("VolumeSelect")!
  const _component_TableOfContents = _resolveComponent("TableOfContents")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.showTitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.header)
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.title),
            innerHTML: _ctx.series.title
          }, null, 10, _hoisted_1),
          (_ctx.volumes.length > 1)
            ? (_openBlock(), _createBlock(_component_VolumeSelect, {
                key: 0,
                modelValue: _ctx.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
                class: _normalizeClass(_ctx.$style.dropdown),
                volumes: _ctx.volumes
              }, null, 8, ["modelValue", "class", "volumes"]))
            : _createCommentVNode("", true)
        ], 2))
      : (_ctx.volumes.length > 1)
        ? (_openBlock(), _createBlock(_component_VolumeSelect, {
            key: 1,
            modelValue: _ctx.title,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
            class: _normalizeClass(_ctx.$style.dropdown),
            volumes: _ctx.volumes
          }, null, 8, ["modelValue", "class", "volumes"]))
        : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_TableOfContents, {
          key: _ctx.title.slug,
          title: _ctx.title
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 2))
}