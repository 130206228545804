import { ref } from 'vue';

// Matching ambience.less CSS breakpoints
export enum Breakpoint {
  VeryNarrow = 360,
  Narrow = 499,
  Wide = 500,
  Tablet = 768,
  VeryWide = 1024,
  HideNav = 1200,
  Max = 1366,
}

export const useWindowSize = () => {
  const windowWidth = ref<number>(window.innerWidth);
  const windowHeight = ref<number>(window.innerHeight);

  const handler = () => {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
  };

  window.addEventListener('resize', handler);
  window.addEventListener('orientationchange', handler);

  return { windowWidth, windowHeight };
};

