import en from 'res/text/en';
import { App } from 'vue';
import IcuFormatter from './icu-formatter';

export type TranslateFunction = (key: string, values?: unknown) => string;

const icuFormatter = new IcuFormatter('en-US', { 'en-US': en });

const formatter = {
  t: icuFormatter.translate.bind(icuFormatter) as TranslateFunction,
  n: (num: number) => num.toLocaleString(undefined),
  locale: 'en-US'
};


const createIntlWrapper = () => ({
  install: (app: App) => {
    app.config.globalProperties.$t = formatter.t;
    app.config.globalProperties.$n = formatter.n;
  }
});


export const plugin = createIntlWrapper();

export default formatter;
