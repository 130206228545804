import { Title } from 'app/models/title';
import { Ref, isRef, readonly, ref, watch } from 'vue';
import { useRoute } from 'vue-router';


export function usePriorReleases(parentTitleRaw: Ref<Title | null> | Title, cached = false) {
  const parentTitle = isRef(parentTitleRaw) ? parentTitleRaw : ref(parentTitleRaw);

  const priorReleases = ref<Title[] | null>(null);
  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const timer = ref<ReturnType<typeof setTimeout> | null>(null);

  const update = async () => {
    if (!parentTitle.value) { return; }

    try {
      loading.value = true;

      priorReleases.value = await parentTitle.value.getPriorReleases() || [];

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  watch(parentTitle, update, { immediate: true });

  const poll = () => {
    return setTimeout(async () => {
      await update();

      timer.value = poll();
    }, 2000);
  };

  const stopPolling = () => {
    if (timer.value) {
      clearTimeout(timer.value);
      timer.value = null;
    }
  };

  const startPolling = () => {
    timer.value = poll();

    const route = useRoute();
    watch(route, stopPolling);
  };

  return {
    priorReleases: readonly(priorReleases),
    loading: readonly(loading),
    error: readonly(error),
    update,
    startPolling,
    stopPolling
  };

}
