
/**
 * Determine if an element has overflowed it's container
 * @param el The element to test for overflow
 */
export function isOverflowing(el: HTMLElement): boolean {
  const curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === 'visible') {
    el.style.overflow = 'hidden';
  }

  const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverflow;

  return isOverflowing;
}

/**
 * @desc Truncate the text of an element, based on the elements height
 * @param container The container element
 * @param textElement The inner element containing the text to truncate
 * @param suffix The string to inject at the point of truncation. Deafults to ellipsis (...)
 * @notes This method is a one-time adjustment. If the element is resized, this method will need to be rerun.
 * @see {@link https://medium.com/mofed/css-line-clamp-the-good-the-bad-and-the-straight-up-broken-865413f16e5}
 */
export function truncateElementText(container: HTMLElement, textElement: HTMLElement, suffix = '…'): boolean {
  const divHeight = container.clientHeight;
  let overflowed = false;
  container.style.overflowY = 'visible';

  if (textElement.offsetHeight > divHeight) {
    overflowed = true;
    truncateForwardInternal(divHeight, textElement, suffix);
  }

  container.style.overflowY = 'hidden';

  return overflowed;
}

/**
 * Truncate the text of an element by adding one word+suffix at a time
 * until the element overflows and then removing the last word added.
 */
function truncateForwardInternal(divHeight: number, textElement: HTMLElement, suffix: string): void {
  let text = textElement.textContent;

  if (!text) {
    return;
  }

  textElement.textContent = '';
  let currentText = '';

  const wordBoundary = /\w*\W*/;
  const lastWord = /\W*\w*\W*$/;

  do {
    const match = text.match(wordBoundary);

    if (!match || !match[0]) {
      break;
    }

    const nextWord = match[0];
    currentText += nextWord;
    textElement.textContent = currentText.replace(lastWord, suffix);
    text = text.substring(nextWord.length);
  } while (textElement.offsetHeight <= divHeight);

  textElement.textContent = textElement.textContent.replace(lastWord, suffix);
}
