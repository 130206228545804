import { C, Dictionary } from 'app/base/common';
import env from 'app/base/env';
import { Service } from './services/service';

export class AutocompleteService extends Service {
  private readonly queryParameters: Dictionary<string> = {
    'api-key': env.AUTOCOMPLETE_KEY || '',
    'x-client-id': 'elrond'
  };


  constructor() {
    super('AUTOCOMPLETE', (path: string) => {
      return { url: C.parameterizeURL(`${env.AUTOCOMPLETE_URI || ''}/v1/${path}`, this.queryParameters)};
    });
  }


  public getSuggestions(query: AutocompleteQuery): Promise<AutocompleteResponse | null> {
    const route = C.parameterizeURL('autocomplete', query);

    return this.fetchAsync<AutocompleteResponse>(route);
  }
}

export interface AutocompleteQuery {
  query: string;
  sortBy?: 'text' | 'field' | 'score';
  categorySize?: number;
  maxSize?: number;
  fields?: ('title' | 'creator' | 'series')[];
  mediaType?: ('ebook' | 'audiobook')[];
  fuzzy?: boolean;
  deduplicate?: boolean;
}

export interface AutocompleteResponse {
  items: AutocompleteItem[];
}

interface AutocompleteItem {
  text: string;
  field: string;
  score: number;
}
