<template>
  <p :id="id">
    {{ $t('annotationCopying.prompt.inProgress.explanation') }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InProgressContent',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup: () => {
    return {};
  }
});
</script>

<style module>
</style>
