import { APP } from 'app/base/app';
import { AuthError, AuthErrorWithCustom, AuthResult } from 'app/base/auth-definitions';
import { authenticate as performAuthentication, completeExternalAuth } from 'app/base/authenticator';
import { AuthLoginPage, LoginFormSubmission } from 'app/base/interfaces';
import { resolve } from 'app/base/library-resolver';
import type { SentrySyncResponse } from 'app/base/sentry';
import i18n from 'app/i18n/i18n';
import { Library } from 'app/models/library';
import { RouteName } from 'app/router/constants';
import router from 'app/router/router';
import { Ref, ref } from 'vue';

type LoginState =
  | {
    libraryState: 'resolving';
  }
  | {
    libraryState: 'error';
    libraryError: string;
  }
  | {
    libraryState: 'resolved';
    library: Library;
    loginPage: LoginPageState;
  };

type LoginPageState =
  | {
    state: 'loading';
  }
  | {
    state: 'error';
    error: string;
  }
  | {
    state: 'loaded';
    loginPage: AuthLoginPage;
    authenticationState: AuthenticationState;
  };

export type AuthenticationState =
  | {
    state: 'idle';
  }
  | {
    state: 'submitting';
  }
  | {
    state: 'error';
    code: AuthError;
    message: string;
  }
  | {
    state: 'success';
    response: SentrySyncResponse;
  }
  | {
    state: 'code';
    error?: {
      code: AuthError;
      message: string;
    };
  };


/**
 * This scaffolds the authentication state and state management
 * functions needed to authenticate users with a library.
 *
 * This function (and those it returns) intentionally do not contain
 * user interface implementation details. Vue components should be able
 * to build and manage an interface by consuming the returned state.
 *
 * Likewise, this function attempts to not contain any actual authentication
 * logic, delegating that to `authentication.ts`.
 *
 * @param libraryKey The library to authenticate with.
 * @param origination Where to take the user after a successful authentication.
 *
 * @returns An object containing several useful authentication hooks and the authentication state ref.
 */
export function useAuthentication(libraryKey: string, origination: string | undefined) {
  const state = ref({ libraryState: 'resolving' }) as Ref<LoginState>;

  const resolveLibrary = async () => {
    if (APP.library && APP.library.baseKey === libraryKey) {
      state.value = {
        libraryState: 'resolved',
        library: APP.library,
        loginPage: { state: 'loading' }
      };

      return;
    }

    const resolveResult = await resolve(libraryKey);

    if (resolveResult.state === 'error') {
      state.value = {
        libraryState: 'error',
        libraryError: `libraryCode.error.${resolveResult.error}`
      };
    } else {
      state.value = {
        libraryState: 'resolved',
        library: resolveResult.library,
        loginPage: { state: 'loading' }
      };
    }
  };

  const loadLoginPage = async (library: Library) => {
    const ghostLogin = APP.bank.get('auth:ghost');

    try {
      const loginPage = await APP.sentry.fetchLoginForms(
        library.websiteId,
        ghostLogin && ghostLogin !== 'none'
      );

      if (!loginPage) {
        state.value = {
          libraryState: 'resolved',
          library: library,
          loginPage: {
            state: 'error',
            error: 'login.error.noAuth'
          }
        };
      } else {
        state.value = {
          libraryState: 'resolved',
          library: library,
          loginPage: {
            state: 'loaded',
            loginPage: loginPage,
            authenticationState: {
              state: 'idle'
            }
          }
        };
      }
    } catch (error) {
      console.error('[AUTH] Could not load login page', error);
      APP.sage.submit('error', {
        errorMessage: 'Error loading login page',
        errorSource: 'use-authentication#loadLoginPage',
        errorData: {
          message: error.name + ': ' + error.message
        },
        submissionContext: {
          library: library
        }
      });

      state.value = {
        libraryState: 'resolved',
        library: library,
        loginPage: {
          state: 'error',
          error: 'login.error.generic'
        }
      };
    }
  };

  const authenticate = async (data: LoginFormSubmission): Promise<AuthenticationState> => {
    if (state.value.libraryState !== 'resolved' || state.value.loginPage.state !== 'loaded') {
      return { state: 'idle' };
    }

    const isCodeEntry = state.value.loginPage.authenticationState.state === 'code';

    state.value.loginPage.authenticationState = {
      state: 'submitting'
    };

    const authResult = await performAuthentication(state.value.library, data, origination);

    if (!authResult) {
      return {
        state: 'submitting'
      };
    }

    const authState = handleAuthResult(authResult);

    if (authState.state === 'error' && isCodeEntry) {
      state.value.loginPage.authenticationState = {
        state: 'code',
        error: {
          code: authState.code,
          message: authState.message
        }
      };
    } else {
      state.value.loginPage.authenticationState = authState;
    }

    return authState;
  };


  const authenticateExternal = async (continuation: string): Promise<AuthenticationState> => {
    if (state.value.libraryState !== 'resolved' || state.value.loginPage.state !== 'loaded') {
      return { state: 'idle' };
    }

    state.value.loginPage.authenticationState = {
      state: 'submitting'
    };

    const authResult = await completeExternalAuth(continuation);

    const authState = handleAuthResult(authResult);

    state.value.loginPage.authenticationState = authState;

    return authState;
  };


  const cancelAuthentication = () => {
    if (state.value.libraryState !== 'resolved' || state.value.loginPage.state !== 'loaded') {
      return;
    }

    state.value.loginPage.authenticationState = { state: 'idle' };
  };


  const handleAuthResult = (authResult: AuthResult): AuthenticationState => {
    let authenticationState: AuthenticationState;

    if (authResult.state === 'success' || authResult.state === 'code') {
      authenticationState = authResult;
    } else {
      authenticationState = {
        state: 'error',
        code: authResult.code,
        message: authErrorToMessage(authResult)
      };

      if (authResult.code === AuthError.Unknown) {
        console.error('[AUTH] Unknown error in authentication', authResult.data);
        APP.sage.submit('error', {
          errorMessage: 'Authentication error',
          errorSource: 'use-authentication#handleAuthResult',
          errorData: {
            message: typeof authResult.data === 'string'
              ? authResult.data
              : authResult.data.name + ': ' + authResult.data.message
          },
          submissionContext: {
            library: state.value.libraryState === 'resolved' ? state.value.library : undefined
          }
        });
      }
    }

    return authenticationState;
  };


  const completeAuthentication = async (response: SentrySyncResponse): Promise<void> => {
    if (state.value.libraryState !== 'resolved' || state.value.loginPage.state !== 'loaded') {
      return;
    }

    console.log('[AUTH] Completing authentication...');

    state.value.library.activate();
    await APP.patron.syncCoordinator.syncWithAuthData(response);

    APP.events.dispatch('auth:complete');

    const redirect = origination ? origination
      : response.cards?.[0]?.isSessionUser ? { name: RouteName.Browse }
      : { name: RouteName.Home };

    router.replace(redirect);
  };


  return {
    authenticate,
    authenticateExternal,
    cancelAuthentication,
    completeAuthentication,
    loadLoginPage,
    resolveLibrary,
    state
  };
}


function authErrorToMessage(error: AuthErrorWithCustom): string {
  if (error.code === AuthError.Custom) {
    return i18n.t('login.error.custom', { message: error.message });
  }

  return i18n.t(`login.error.${error.code}`);
}
