import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchThisTitleResult = _resolveComponent("SearchThisTitleResult")!

  return (_openBlock(), _createElementBlock("ol", {
    class: _normalizeClass(_ctx.$style.list)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createVNode(_component_SearchThisTitleResult, {
          result: result,
          title: _ctx.titles[result.titleId.toString()],
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, null, 8, ["result", "title"])
      ]))
    }), 128))
  ], 2))
}