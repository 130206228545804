import { C } from 'app/base/common';
import { Timeline } from './nav';

export class Semaphore {
  private readonly _elem: HTMLElement;


  constructor(elem: HTMLElement) {
    this._elem = elem;
  }


  public set<K extends keyof SemaphoreFlags>(attr: K, val: SemaphoreFlags[K] | undefined): void {
    C.DataClass.set(this._elem, attr, val);
  }


  public get<K extends keyof SemaphoreFlags>(attr: K): string | undefined {
    return C.DataClass.get(this._elem, attr);
  }


  public clear<K extends keyof SemaphoreFlags>(attr: K): void {
    C.DataClass.clear(this._elem, attr);
  }
}

export type SemaphoreFlags = {
  [scene in SceneKey]: string;
} & {
  'client': 'ready' | 'resolving' | 'unloading' | 'signing-out';
  'book': 'opening' | 'reopening' | 'ready';
  'scrollable': 'on' | 'off';
  'locate': 'validating';
  'scrollfocus': 'update' | 'popover';
  'timeline': Timeline;
  'user-menu': 'raise' | 'lower';
  'sidebar': 'raise' | 'lower';
  'network': 'metered' | 'unmetered' | 'unreachable';
  'animation': 'off';
  'synchronize': 'canceled';
  'update': 'updating';
};

// TODO: Better way to handle scenes?
export type SceneKey = 'scene-onboarding' | 'scene-home' | 'scene-library' | 'scene-mybooks' | 'scene-modal' | 'scene-notes' | 'scene-tags' | 'scene-export' | 'scene-shelf' | 'scene-activities';
