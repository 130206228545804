import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { MediaType } from 'app/base/interfaces';
import { SeriesList, TitleList } from 'app/models/list';
import { SubjectCategory } from 'app/models/subject';
import SUBJECT_DATA from 'res/data/subjects.json'; // TODO: Stop using hardcoded source
import { FreshableItem } from '../base/services/freshable';
import { ThunderCollectionDefinition, ThunderFacetItem, ThunderGeneratedCollectionDefinition, ThunderMediaParams, ThunderMediaResponse, ThunderRoom, ThunderSearchResponse, ThunderView } from '../base/thunder';
import { Library } from './library';

export class Catalog extends FreshableItem<[ThunderRoom, ThunderSearchResponse<ThunderMediaResponse>]> {
  public everything: TitleList;
  public sorts: { id: string }[];
  public languages: any[];
  public formats: any[];
  public hasKindle: boolean;
  public library: Library;

  public subjectsData: {
    jurisdiction: Dictionary<string>;
    practiceArea: Dictionary<string>;
    classification: Dictionary<string>;
  };


  constructor(library: Library) {
    super('catalog', C.MS_HOUR);
    this.library = library;
    this.formats = Constants.formats;
    this.languages = [];
    this.sorts = [
      { id: 'default' },
      { id: 'relevance' },
      { id: 'mostpopular' },
      { id: 'newlyadded' },
      { id: 'releasedate' },
      { id: 'author' },
      { id: 'title' }
    ];
    this.hasKindle = false;
    this.everything = this.library.lists.fetchTitle('everything');

    this.subjectsData = SUBJECT_DATA;
  }


  protected freshenCall(): Promise<[ThunderRoom, ThunderSearchResponse<ThunderMediaResponse>]> {
    const rooms = APP.services.thunder.getHomePage(this.library.key());

    const mediaParams = {
      includedFacets: ['mediatypes', 'languages', 'subjects']
    };
    this._updateMediaParams(mediaParams);
    const media = APP.services.thunder.searchWithFormats(this.library.key(), mediaParams);

    return Promise.all([rooms, media]);
  }


  protected freshenMap(response: [ThunderRoom, ThunderSearchResponse<ThunderMediaResponse>]): void {
    this._parsePagesResponse(response[0]);
    this._parseMediaResponse(response[1]);
  }


  public arePropertiesFresh(): boolean {
    return true;
  }


  public language(id, value?) {
    return this._addToArray(
      this.languages,
      value || { id: id, name: 'Language [' + id + ']', error: true }
    );
  }


  public async odCollections(callback: (lists: TitleList[]) => void) {
    if (await this.freshen(true)) {
      const generatedLists: any = this.library.lists.find({
        source: 'generated'
      }) || [];
      const curatedLists: any = this.library.lists.find({
        source: 'curated'
      }) || [];
      const lists = generatedLists.concat(curatedLists);
      lists.totalTitles = this.everything.totalTitles;
      callback(lists);
    }
  }


  /**
   *
   * @param filter Derived from the Constants.FORMAT_MAPPINGS
   */
  public async subjects(filter: MediaType | 'all' | 'block'): Promise<TitleList[] & { totalTitles?: number }> {
    // If the mediaTypeFilter is not a MediaType: mediaParams.mediaTypes => undefined
    const mediaParams = {
      mediaTypes: [Constants.toThunderMediaType(filter)]
    };

    this._updateMediaParams(mediaParams);

    try {
      const results = await APP.services.thunder.searchWithFormats(this.library.key(), mediaParams);
      if (!results) {
        return [];
      }

      let subjectLists: TitleList[] & { totalTitles?: number } = [];

      if (filter === 'all') {
        this.freshen(true);
        subjectLists = this.getSubjects(true);
      } else if (filter === 'block') {
        subjectLists = this.getSubjects(true);
      } else {
        C.each(
          results.facets.subjects.items,
          (subj) => {
            const addr = 'subject-' + subj.id + '/' + C.pluralize(filter);
            const list = new TitleList(this.library, addr);
            list.id = subj.id;
            list.name = subj.name;
            list.totalTitles = subj.totalItems || 0;
            list.category = this.getSubjectCategory(subj.id);
            subjectLists.push(list);
          }
        );
      }

      subjectLists.totalTitles = results.totalItems;

      return subjectLists;
    } catch {
      // If we error we'll just return no subjects
    }

    return [];
  }


  /**
   * @desc - Get the category associated to this subject
   * @param subjectID - Unique ID of the subject to look up.
   * @returns The unique key for the coorseponding subject, or null if not found.
   * @notes - Using a static data file to designate categories for now.
   * Currently these subjects are all stored in the same data table and have no
   * way of distinguishing category in their current state.
   */
  public getSubjectCategory(subjectID: string): SubjectCategory {
    if (!subjectID) { return 'Subject'; }

    if (this.subjectsData.jurisdiction[subjectID]) {
      return 'Jurisdiction';
    }

    if (subjectID.toString().startsWith('LAW') || this.subjectsData.practiceArea[subjectID]) {
      return 'PracticeArea';
    }

    if (this.subjectsData.classification[subjectID]) {
      return 'Classification';
    }

    return 'Subject';
  }


  protected _parsePagesResponse(homePage: ThunderRoom) {
    if (homePage.views) {
      const homeView =  homePage.views[0];
      this._parseViewCollections(homeView);
    }
  }


  protected _parseViewCollections(view: ThunderView) {
    C.each(view.collectionDefinitions, (coll) => {
      const source = this._isGeneratedCollection(coll) ? 'generated' : 'curated';
      this.library.lists.addListDefinition(source, coll);
      const attrs: { name: string; description?: string; priority?: number } = {
        name: coll.name,
        description: coll.description
      };
      for (const collection of view.collections) {
        if (coll.id === collection.id) {
          attrs.priority = collection.order;
          break;
        }
      }
      this.library.lists.fetch(coll.itemType === 'Series' ? 'set' : 'title', source + '-' + coll.id)
        .mergeCollectionAttributes(attrs);
    });
  }


  protected _isGeneratedCollection(coll: ThunderCollectionDefinition): coll is ThunderGeneratedCollectionDefinition {
    return (coll as ThunderGeneratedCollectionDefinition).generatedCollectionDetails !== undefined;
  }


  protected _parseMediaResponse(results: ThunderSearchResponse<ThunderMediaResponse>) {
    this.everything.totalTitles = results.totalItems;
    this.formats = [];
    C.each(
      results.facets.mediaTypes.items,
      (mt) => {
        if (mt.totalItems > 0) {
          const fmt = Constants.toMediaType(mt.id);
          if (fmt) {
            this.formats.push(fmt);
          }
        }
      }
    );
    this.languages = [];
    C.each(
      results.facets.languages.items,
      (lang) => {
        this.language(lang.id, {
          id: lang.id,
          name: lang.name,
          totalTitles: lang.totalItems
        });
      }
    );
    C.each(
      results.facets.subjects.items,
      (subj) => {
        const list = this.library.lists.fetchTitle('subject-' + subj.id);
        list.name = subj.name;
        list.totalTitles = subj.totalItems;
      }
    );
    this.hasKindle = results.facets.formats.items.find((item: ThunderFacetItem) => {
      return item.id === 'ebook-kindle' && item.totalItems > 0;
    }) ? true : false;
  }


  protected _addToArray(array, value) {
    for (let i = 0, ii = array.length; i < ii; ++i) {
      if (array[i].id === value.id) {
        if (value.error) {
          return array[i];
        }

        array[i] = value;

        return value;
      }
    }
    array.push(value);

    return value;
  }


  /**
   * @desc Get the subjects for this catalog
   * @param subjectID - The subject to retrieve
   * @param {boolean} [includeCategories=false] - True to include category metadata
   */
  public getSubject(subjectID: string, includeCategories?: boolean): TitleList {
    const subject = this.library.lists.fetchTitle(`subject-${subjectID}`);

    return subject;
  }


  /**
   * @desc Get the subjects for this catalog
   * @param {boolean} [includeCategories=false] - True to include category metadata
   */
  public getSubjects(includeCategories?: boolean): TitleList[] {
    const output: TitleList[] = this.library.lists.find({
      source: 'subject',
      format: '*'
    }) as TitleList[];

    // Massage data
    if (includeCategories) {
      for (const subj of output) {
        subj.category = this.getSubjectCategory(subj.id);
      }
    }

    return output;
  }


  /**
   * @desc Get the collections for this catalog
   */
  public getCollections(): (TitleList | SeriesList)[] {
    let output: (TitleList | SeriesList)[] = [];

    this.freshen();
    const generatedLists = this.library.lists.find({
      source: 'generated'
    });
    if (generatedLists) {
      output = output.concat(generatedLists);
    }

    const curatedLists = this.library.lists.find({
      source: 'curated'
    });
    if (curatedLists) {
      output = output.concat(curatedLists);
    }

    return output;
  }


  protected _updateMediaParams(params: ThunderMediaParams): void {
    const base: ThunderMediaParams = {
      perPage: 0
    };
    C.absorb(base, params);
  }
}
