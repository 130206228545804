const bisacCodes = {
  /* LAW BISAC CODES */
  LAW000000: 'General',
  LAW001000: 'Administrative Law & Regulatory Practice',
  LAW002000: 'Air & Space',
  LAW003000: 'Alternative Dispute Resolution',
  LAW004000: 'Annotations & Citations',
  LAW005000: 'Antitrust',
  LAW006000: 'Arbitration, Negotiation, Mediation',
  LAW007000: 'Banking',
  LAW008000: 'Bankruptcy & Insolvency',
  LAW009000: 'Business & Financial',
  LAW010000: 'Child Advocacy',
  LAW011000: 'Civil Law',
  LAW012000: 'Civil Procedure',
  LAW013000: 'Civil Rights',
  LAW014000: 'Commercial / General',
  LAW014010: 'Commercial / International Trade',
  LAW015000: 'Communications',
  LAW016000: 'Comparative',
  LAW017000: 'Conflict of Laws',
  LAW018000: 'Constitutional',
  LAW019000: 'Construction',
  LAW020000: 'Consumer',
  LAW021000: 'Contracts',
  LAW022000: 'Corporate',
  LAW023000: 'Court Records',
  LAW024000: 'Court Rules',
  LAW025000: 'Courts',
  LAW026000: 'Criminal Law / General',
  LAW026010: 'Criminal Law / Juvenile Offenders',
  LAW026020: 'Criminal Law / Sentencing',
  LAW027000: 'Criminal Procedure',
  LAW028000: 'Customary',
  LAW029000: 'Depositions',
  LAW030000: 'Dictionaries & Terminology',
  LAW031000: 'Disability',
  LAW032000: 'Emigration & Immigration',
  LAW033000: 'Entertainment',
  LAW034000: 'Environmental',
  LAW035000: 'Estates & Trusts',
  LAW036000: 'Ethics & Professional Responsibility',
  LAW037000: 'Evidence',
  LAW038000: 'Family Law / General',
  LAW038010: 'Family Law / Children',
  LAW038020: 'Family Law / Divorce & Separation',
  LAW038030: 'Family Law / Marriage',
  LAW039000: 'Government / Federal',
  LAW040000: 'Fidelity & Surety',
  LAW041000: 'Forensic Science',
  LAW042000: 'Franchising',
  LAW043000: 'Gender & the Law',
  LAW044000: 'General Practice',
  LAW045000: 'Grant',
  LAW046000: 'Health',
  LAW047000: 'Housing & Urban Development',
  LAW048000: 'Human Rights',
  LAW049000: 'Insurance',
  LAW050000: 'Intellectual Property / General',
  LAW050010: 'Intellectual Property / Copyright',
  LAW050020: 'Intellectual Property / Patent',
  LAW050030: 'Intellectual Property / Trademark',
  LAW051000: 'International',
  LAW052000: 'Jurisprudence',
  LAW053000: 'Jury',
  LAW054000: 'Labor & Employment',
  LAW055000: 'Land Use',
  LAW056000: 'Law Office Management',
  LAW057000: 'Law Office Marketing & Advertising',
  LAW058000: 'Law Office Technology',
  LAW059000: 'Legal Education',
  LAW060000: 'Legal History',
  LAW061000: 'Legal Profession',
  LAW062000: 'Legal Services',
  LAW063000: 'Legal Writing',
  LAW064000: 'Litigation',
  LAW065000: 'Living Wills',
  LAW066000: 'Maritime',
  LAW067000: 'Mental Health',
  LAW068000: 'Military',
  LAW069000: 'Natural Law',
  LAW070000: 'Natural Resources',
  LAW071000: 'Paralegals & Paralegalism',
  LAW072000: 'Patent, Trademark, Copyright',
  LAW073000: 'Professional Responsibility',
  LAW074000: 'Property',
  LAW075000: 'Public',
  LAW076000: 'Public Contract',
  LAW077000: 'Public Utilities',
  LAW078000: 'Real Estate',
  LAW079000: 'Reference',
  LAW080000: 'Remedies & Damages',
  LAW081000: 'Research',
  LAW082000: 'Right to Die',
  LAW083000: 'Securities',
  LAW084000: 'Sports',
  LAW085000: 'Study & Teaching',
  LAW086000: 'Taxation',
  LAW087000: 'Torts',
  LAW088000: 'Trial Practice',
  LAW089000: 'Government / State, Provincial & Municipal',
  LAW090000: 'Wills',
  LAW091000: 'Witnesses',
  LAW092000: 'Educational Law & Legislation',
  LAW093000: 'Medical Law & Legislation',
  LAW094000: 'Discrimination',
  LAW095000: 'Malpractice',
  LAW096000: 'Media & the Law',
  LAW097000: 'Personal Injury',
  LAW098000: 'Practical Guides',
  LAW099000: 'Science & Technology',
  LAW100000: 'Living Trusts',
  LAW101000: 'Essays',
  LAW102000: 'Agricultural',
  LAW103000: 'Common',
  LAW104000: 'Computer & Internet',
  LAW105000: 'Corporate Governance',
  LAW106000: 'Defamation',
  LAW107000: 'Elder Law',
  LAW108000: 'Election Law',
  LAW109000: 'Government / General',
  LAW110000: 'Indigenous Peoples',
  LAW111000: 'Judicial Power',
  LAW112000: 'Landlord & Tenant',
  LAW113000: 'Liability',
  LAW114000: 'Mergers & Acquisitions',
  LAW115000: 'Pension Law',
  LAW116000: 'Privacy',
  LAW117000: 'Transportation',
  LAW118000: 'Drugs & the Law',
  LAW119000: 'Islamic'

  /* NON-LAW BISAC CODES */
  /*
  ANT000000: 'Antiques & Collectibles / General',
  ANT001000: 'Antiques & Collectibles / Americana',
  ANT002000: 'Antiques & Collectibles / Art',
  ANT003000: 'Antiques & Collectibles / Autographs',
  ANT004000: 'Antiques & Collectibles/ Baskets',
  ANT005000: 'Antiques & Collectibles / Books',
  ANT006000: 'Antiques & Collectibles / Bottles',
  ANT007000: 'Antiques & Collectibles / Buttons & Pins',
  ANT008000: 'Antiques & Collectibles / Care & Restoration',
  ANT009000: 'Antiques & Collectibles / Transportation',
  ANT010000: 'Antiques & Collectibles / Clocks & Watches',
  ANT011000: 'Antiques & Collectibles / Coins, Currency & Medals',
  ANT012000: 'Antiques & Collectibles / Comics',
  ANT013000: 'Antiques & Collectibles/ Dance',
  ANT014000: 'Antiques & Collectibles/ Disneyana',
  ANT015000: 'Antiques & Collectibles / Dolls',
  ANT016000: 'Antiques & Collectibles / Firearms & Weapons',
  ANT017000: 'Antiques & Collectibles / Furniture',
  ANT018000: 'Antiques & Collectibles / Glass & Glassware',
  ANT019000: 'Antiques & Collectibles/ Gold',
  ANT020000: 'Antiques & Collectibles/ Hummels',
  ANT021000: 'Antiques & Collectibles / Jewelry',
  ANT022000: 'Antiques & Collectibles / Kitchenware',
  ANT023000: 'Antiques & Collectibles / Magazines & Newspapers',
  ANT024000: 'Antiques & Collectibles / Military',
  ANT025000: 'Antiques & Collectibles / Performing Arts',
  ANT026000: 'Antiques & Collectibles/ Musical Instruments',
  ANT027000: 'Antiques & Collectibles/ Nautical',
  ANT028000: 'Antiques & Collectibles / Non-Sports Cards',
  ANT029000: 'Antiques & Collectibles / Paper Ephemera',
  ANT030000: 'Antiques & Collectibles/ Pewter',
  ANT031000: 'Antiques & Collectibles / Political',
  ANT032000: 'Antiques & Collectibles / Porcelain & China',
  ANT033000: 'Antiques & Collectibles / Postcards',
  ANT034000: 'Antiques & Collectibles / Posters',
  ANT035000: 'Antiques & Collectibles / Pottery & Ceramics',
  ANT036000: 'ANTIQUES & COLLECTIBLES / Radios & Televisions',
  ANT037000: 'Antiques & Collectibles / Records',
  ANT038000: 'Antiques & Collectibles / Reference',
  ANT039000: 'Antiques & Collectibles/ Royalty',
  ANT040000: 'Antiques & Collectibles / Rugs',
  ANT041000: 'Antiques & Collectibles / Silver, Gold & Other Metals',
  ANT042000: 'Antiques & Collectibles / Sports Cards / General',
  ANT042010: 'Antiques & Collectibles / Sports Cards / Baseball',
  ANT042020: 'Antiques & Collectibles / Sports Cards / Basketball',
  ANT042030: 'Antiques & Collectibles / Sports Cards / Football',
  ANT042040: 'Antiques & Collectibles / Sports Cards / Hockey',
  ANT043000: 'Antiques & Collectibles / Sports ',
  ANT044000: 'Antiques & Collectibles / Stamps',
  ANT045000: 'Antiques & Collectibles / Teddy Bears & Stuffed Animals',
  ANT046000: 'Antiques & Collectibles/ Televisions & Television-Related',
  ANT047000: 'Antiques & Collectibles / Textiles & Costume',
  ANT048000: 'Antiques & Collectibles/ Theater',
  ANT049000: 'Antiques & Collectibles / Toy Animals',
  ANT050000: 'Antiques & Collectibles / Toys',
  ANT051000: 'Antiques & Collectibles / Wine',
  ANT052000: 'Antiques & Collectibles / Popular Culture',
  ANT053000: 'Antiques & Collectibles / Figurines',
  ANT054000: 'Antiques & Collectibles / Canadiana',
  ANT055000: 'Antiques & Collectibles / Tobacco-Related',
  ARC000000: 'Architecture / General',
  ARC001000: 'Architecture / Criticism',
  ARC002000: 'Architecture / Decoration & Ornament',
  ARC003000: 'Architecture / Buildings / Residential',
  ARC004000: 'Architecture / Design, Drafting, Drawing & Presentation',
  ARC005000: 'Architecture / History / General',
  ARC005010: 'Architecture/ History / Prehistoric',
  ARC005020: 'Architecture / History / Ancient & Classical',
  ARC005030: 'Architecture / History / Medieval',
  ARC005040: 'Architecture / History / Renaissance',
  ARC005050: 'Architecture / History / Baroque & Rococo',
  ARC005060: 'Architecture / History / Romanticism',
  ARC005070: 'Architecture / History / Modern (late 19th Century to 1945)',
  ARC005080: 'Architecture / History / Contemporary (1945-)',
  ARC006000: 'Architecture / Individual Architects & Firms / General',
  ARC006010: 'Architecture / Individual Architects & Firms / Essays',
  ARC006020: 'Architecture / Individual Architects & Firms / Monographs',
  ARC007000: 'Architecture / Interior Design / General',
  ARC007010: 'Architecture / Interior Design / Lighting',
  ARC008000: 'Architecture / Landscape',
  ARC009000: 'Architecture / Methods & Materials',
  ARC010000: 'ARCHITECTURE / Urban & Land Use Planning',
  ARC011000: 'Architecture / Buildings / Public, Commercial & Industrial',
  ARC012000: 'Architecture / Reference',
  ARC013000: 'Architecture / Study & Teaching',
  ARC014000: 'Architecture / Historic Preservation / General',
  ARC014010: 'Architecture / Historic Preservation / Restoration Techniques',
  ARC015000: 'Architecture / Professional Practice',
  ARC016000: 'Architecture / Buildings / Religious',
  ARC017000: 'Architecture / Project Management',
  ARC018000: 'Architecture / Sustainability & Green Design',
  ARC019000: 'Architecture / Codes & Standards',
  ARC020000: 'Architecture / Regional',
  ARC021000: 'Architecture / Security Design',
  ARC022000: 'Architecture / Adaptive Reuse & Renovation',
  ARC023000: 'Architecture / Annuals',
  ARC024000: 'Architecture / Buildings / General',
  ARC024010: 'Architecture / Buildings / Landmarks & Monuments',
  ARC025000: 'Architecture / Vernacular ',
  ART000000: 'Art / General',
  ART001000: 'Art/ Activity',
  ART002000: 'Art / Techniques / Airbrush',
  ART003000: 'Art / Techniques / Calligraphy',
  ART004000: 'Art / Techniques / Cartooning',
  ART005000: 'Art / Clip Art',
  ART006000: 'Art / Collections, Catalogs, Exhibitions / General',
  ART006010: 'Art / Collections, Catalogs, Exhibitions / Group Shows',
  ART006020: 'Art / Collections, Catalogs, Exhibitions / Permanent Collections',
  ART007000: 'ART / Color Theory',
  ART008000: 'Art / Conceptual',
  ART009000: 'Art / Criticism & Theory',
  ART010000: 'ART / Techniques / Drawing',
  ART011000: 'Art / Fashion',
  ART012000: 'Art/ Fine Arts',
  ART013000: 'Art / Folk & Outsider Art',
  ART014000: 'Art / Graphic Arts',
  ART015000: 'Art / History / General',
  ART015010: 'Art / African',
  ART015020: 'Art / American / General',
  ART015030: 'Art / European',
  ART015040: 'Art / Canadian',
  ART015050: 'Art/ History / Prehistoric',
  ART015060: 'Art / History / Ancient & Classical',
  ART015070: 'Art / History / Medieval',
  ART015080: 'Art / History / Renaissance',
  ART015090: 'Art / History / Baroque & Rococo',
  ART015100: 'Art / History / Modern (late 19th Century to 1945)',
  ART015110: 'Art / History / Contemporary (1945-)',
  ART015120: 'Art / History / Romanticism',
  ART016000: 'Art / Individual Artists / General',
  ART016010: 'Art / Individual Artists / Artists\u2019 Books',
  ART016020: 'Art / Individual Artists / Essays',
  ART016030: 'Art / Individual Artists / Monographs',
  ART017000: 'Art / Mixed Media',
  ART018000: 'ART / Techniques / Oil Painting',
  ART019000: 'ART / Asian / General',
  ART019010: 'Art / Asian / Chinese ',
  ART019020: 'Art / Asian / Indian & South Asian ',
  ART019030: 'Art / Asian / Japanese ',
  ART020000: 'ART / Techniques / Painting',
  ART021000: 'Art / Techniques / Pastel Drawing',
  ART022000: 'Art/ Pictorial',
  ART023000: 'Art / Popular Culture',
  ART024000: 'Art / Techniques / Printmaking',
  ART025000: 'Art / Reference',
  ART026000: 'Art/ Sculpture & Installation',
  ART027000: 'Art / Study & Teaching',
  ART028000: 'Art / Techniques / General',
  ART029000: 'Art / Techniques / Watercolor Painting',
  ART030000: 'Art / Design / General',
  ART030010: 'Art / Design / Book',
  ART030020: 'Art / Design / Decorative',
  ART030030: 'Art / Design / Furniture',
  ART030040: 'Art / Design / Textile & Costume',
  ART030050: 'Art / Design / Product',
  ART031000: 'Art / Techniques / Acrylic Painting',
  ART032000: 'Art / Commercial / General',
  ART032010: 'Art / Commercial / Advertising',
  ART032020: 'Art / Commercial / Illustration',
  ART033000: 'Art / Techniques / Pen & Ink Drawing',
  ART034000: 'Art / Techniques / Pencil Drawing',
  ART035000: 'Art / Subjects & Themes / Religious',
  ART036000: 'Art / Typography',
  ART037000: 'Art / Art & Politics',
  ART038000: 'Art/ American / African American & Black',
  ART039000: 'Art / American / Asian American',
  ART040000: 'Art/ American / Hispanic & Latino',
  ART041000: 'Art/ Indigenous Art of the Americas',
  ART042000: 'Art / Australian & Oceanian',
  ART043000: 'Art / Business Aspects',
  ART044000: 'Art / Caribbean & Latin American',
  ART045000: 'Art / Ceramics',
  ART046000: 'Art/ Digital',
  ART047000: 'Art / Middle Eastern',
  ART048000: 'Art / Prints',
  ART049000: 'Art / Russian & Former Soviet Union',
  ART050000: 'Art / Subjects & Themes / General',
  ART050010: 'Art / Subjects & Themes / Human Figure',
  ART050020: 'Art / Subjects & Themes / Landscapes & Seascapes',
  ART050030: 'Art / Subjects & Themes / Plants & Animals',
  ART050040: 'Art / Subjects & Themes / Portraits',
  ART050050: 'Art / Subjects & Themes / Erotica',
  ART050060: 'Art / Subjects & Themes / Science Fiction & Fantasy',
  ART051000: 'Art / Techniques / Color',
  ART052000: 'Art / Techniques / Life Drawing',
  ART053000: 'Art / Techniques / Sculpting',
  ART054000: 'Art / Annuals',
  ART055000: 'Art / Body Art & Tattooing',
  ART056000: 'Art / Conservation & Preservation',
  ART057000: 'Art / Film & Video',
  ART058000: 'Art / Graffiti & Street Art',
  ART059000: 'Art / Museum Studies',
  ART060000: 'Art / Performance',
  ART061000: 'Art / Glass ',
  ART062000: 'Art / Public Art ',
  BIB000000: 'Bibles / General',
  BIB001000: 'Bibles / Christian Standard Bible / General',
  BIB001010: 'Bibles / Christian Standard Bible / Children',
  BIB001020: 'Bibles / Christian Standard Bible / Devotional',
  BIB001030: 'Bibles / Christian Standard Bible / New Testament & Portions',
  BIB001040: 'Bibles / Christian Standard Bible / Reference',
  BIB001050: 'Bibles / Christian Standard Bible / Study',
  BIB001060: 'Bibles / Christian Standard Bible / Text',
  BIB001070: 'Bibles / Christian Standard Bible / Youth & Teen',
  BIB002000: 'Bibles / Contemporary English Version / General',
  BIB002010: 'Bibles / Contemporary English Version / Children',
  BIB002020: 'Bibles / Contemporary English Version / Devotional',
  BIB002030: 'Bibles / Contemporary English Version / New Testament & Portions',
  BIB002040: 'Bibles / Contemporary English Version / Reference',
  BIB002050: 'Bibles / Contemporary English Version / Study',
  BIB002060: 'Bibles / Contemporary English Version / Text',
  BIB002070: 'Bibles / Contemporary English Version / Youth & Teen',
  BIB003000: 'Bibles / English Standard Version / General',
  BIB003010: 'Bibles / English Standard Version / Children',
  BIB003020: 'Bibles / English Standard Version / Devotional',
  BIB003030: 'Bibles / English Standard Version / New Testament & Portions',
  BIB003040: 'Bibles / English Standard Version / Reference',
  BIB003050: 'Bibles / English Standard Version / Study',
  BIB003060: 'Bibles / English Standard Version / Text',
  BIB003070: 'Bibles / English Standard Version / Youth & Teen',
  BIB004000: 'Bibles / God\u2019s Word / General',
  BIB004010: 'Bibles / God\u2019s Word / Children',
  BIB004020: 'Bibles / God\u2019s Word / Devotional',
  BIB004030: 'Bibles / God\u2019s Word / New Testament & Portions',
  BIB004040: 'Bibles / God\u2019s Word / Reference',
  BIB004050: 'Bibles / God\u2019s Word / Study',
  BIB004060: 'Bibles / God\u2019s Word / Text',
  BIB004070: 'Bibles / God\u2019s Word / Youth & Teen',
  BIB005000: 'Bibles / International Children\u2019s Bible / General',
  BIB005010: 'Bibles / International Children\u2019s Bible / Children',
  BIB005020: 'Bibles / International Children\u2019s Bible / Devotional',
  BIB005030: 'Bibles / International Children\u2019s Bible / New Testament & Portions',
  BIB005040: 'Bibles / International Children\u2019s Bible / Reference',
  BIB005050: 'Bibles / International Children\u2019s Bible / Study',
  BIB005060: 'Bibles / International Children\u2019s Bible / Text',
  BIB005070: 'Bibles / International Children\u2019s Bible / Youth & Teen',
  BIB006000: 'Bibles / King James Version / General',
  BIB006010: 'Bibles / King James Version / Children',
  BIB006020: 'Bibles / King James Version / Devotional',
  BIB006030: 'Bibles / King James Version / New Testament & Portions',
  BIB006040: 'Bibles / King James Version / Reference',
  BIB006050: 'Bibles / King James Version / Study',
  BIB006060: 'Bibles / King James Version / Text',
  BIB006070: 'Bibles / King James Version / Youth & Teen',
  BIB007000: 'Bibles / La Biblia de las Americas / General',
  BIB007010: 'Bibles / La Biblia de las Americas / Children',
  BIB007020: 'Bibles / La Biblia de las Americas / Devotional',
  BIB007030: 'Bibles / La Biblia de las Americas / New Testament & Portions',
  BIB007040: 'Bibles / La Biblia de las Americas / Reference',
  BIB007050: 'Bibles / La Biblia de las Americas / Study',
  BIB007060: 'Bibles / La Biblia de las Americas / Text',
  BIB007070: 'Bibles / La Biblia de las Americas / Youth & Teen',
  BIB008000: 'Bibles / Multiple Translations / General',
  BIB008010: 'Bibles / Multiple Translations / Children',
  BIB008020: 'Bibles / Multiple Translations / Devotional',
  BIB008030: 'Bibles / Multiple Translations / New Testament & Portions',
  BIB008040: 'Bibles / Multiple Translations / Reference',
  BIB008050: 'Bibles / Multiple Translations / Study',
  BIB008060: 'Bibles / Multiple Translations / Text',
  BIB008070: 'Bibles / Multiple Translations / Youth & Teen',
  BIB009000: 'Bibles / New American Bible / General',
  BIB009010: 'Bibles / New American Bible / Children',
  BIB009020: 'Bibles / New American Bible / Devotional',
  BIB009030: 'Bibles / New American Bible / New Testament & Portions',
  BIB009040: 'Bibles / New American Bible / Reference',
  BIB009050: 'Bibles / New American Bible / Study',
  BIB009060: 'Bibles / New American Bible / Text',
  BIB009070: 'Bibles / New American Bible / Youth & Teen',
  BIB010000: 'Bibles / New American Standard Bible / General',
  BIB010010: 'Bibles / New American Standard Bible / Children',
  BIB010020: 'Bibles / New American Standard Bible / Devotional',
  BIB010030: 'Bibles / New American Standard Bible / New Testament & Portions',
  BIB010040: 'Bibles / New American Standard Bible / Reference',
  BIB010050: 'Bibles / New American Standard Bible / Study',
  BIB010060: 'Bibles / New American Standard Bible / Text',
  BIB010070: 'Bibles / New American Standard Bible / Youth & Teen',
  BIB011000: 'Bibles / New Century Version / General',
  BIB011010: 'Bibles / New Century Version / Children',
  BIB011020: 'Bibles / New Century Version / Devotional',
  BIB011030: 'Bibles / New Century Version / New Testament & Portions',
  BIB011040: 'Bibles / New Century Version / Reference',
  BIB011050: 'Bibles / New Century Version / Study',
  BIB011060: 'Bibles / New Century Version / Text',
  BIB011070: 'Bibles / New Century Version / Youth & Teen',
  BIB012000: 'Bibles / New International Reader\u2019s Version / General',
  BIB012010: 'Bibles / New International Reader\u2019s Version / Children',
  BIB012020: 'Bibles / New International Reader\u2019s Version / Devotional',
  BIB012030: 'Bibles / New International Reader\u2019s Version / New Testament & Portions',
  BIB012040: 'Bibles / New International Reader\u2019s Version / Reference',
  BIB012050: 'Bibles / New International Reader\u2019s Version / Study',
  BIB012060: 'Bibles / New International Reader\u2019s Version / Text',
  BIB012070: 'Bibles / New International Reader\u2019s Version / Youth & Teen',
  BIB013000: 'Bibles / New International Version / General',
  BIB013010: 'Bibles / New International Version / Children',
  BIB013020: 'Bibles / New International Version / Devotional',
  BIB013030: 'Bibles / New International Version / New Testament & Portions',
  BIB013040: 'Bibles / New International Version / Reference',
  BIB013050: 'Bibles / New International Version / Study',
  BIB013060: 'Bibles / New International Version / Text',
  BIB013070: 'Bibles / New International Version / Youth & Teen',
  BIB014000: 'Bibles / New King James Version / General',
  BIB014010: 'Bibles / New King James Version / Children',
  BIB014020: 'Bibles / New King James Version / Devotional',
  BIB014030: 'Bibles / New King James Version / New Testament & Portions',
  BIB014040: 'Bibles / New King James Version / Reference',
  BIB014050: 'Bibles / New King James Version / Study',
  BIB014060: 'Bibles / New King James Version / Text',
  BIB014070: 'Bibles / New King James Version / Youth & Teen',
  BIB015000: 'Bibles / New Living Translation / General',
  BIB015010: 'Bibles / New Living Translation / Children',
  BIB015020: 'Bibles / New Living Translation / Devotional',
  BIB015030: 'Bibles / New Living Translation / New Testament & Portions',
  BIB015040: 'Bibles / New Living Translation / Reference',
  BIB015050: 'Bibles / New Living Translation / Study',
  BIB015060: 'Bibles / New Living Translation / Text',
  BIB015070: 'Bibles / New Living Translation / Youth & Teen',
  BIB016000: 'Bibles / New Revised Standard Version / General',
  BIB016010: 'Bibles / New Revised Standard Version / Children',
  BIB016020: 'Bibles / New Revised Standard Version / Devotional',
  BIB016030: 'Bibles / New Revised Standard Version / New Testament & Portions',
  BIB016040: 'Bibles / New Revised Standard Version / Reference',
  BIB016050: 'Bibles / New Revised Standard Version / Study',
  BIB016060: 'Bibles / New Revised Standard Version / Text',
  BIB016070: 'Bibles / New Revised Standard Version / Youth & Teen',
  BIB017000: 'Bibles / Nueva Version International / General',
  BIB017010: 'Bibles / Nueva Version International / Children',
  BIB017020: 'Bibles / Nueva Version International / Devotional',
  BIB017030: 'Bibles / Nueva Version International / New Testament & Portions',
  BIB017040: 'Bibles / Nueva Version International / Reference',
  BIB017050: 'Bibles / Nueva Version International / Study',
  BIB017060: 'Bibles / Nueva Version International / Text',
  BIB017070: 'Bibles / Nueva Version International / Youth & Teen',
  BIB018000: 'Bibles/ Other English Translations / General',
  BIB018010: 'Bibles/ Other English Translations / Children',
  BIB018020: 'Bibles/ Other English Translations / Devotional',
  BIB018030: 'Bibles/ Other English Translations / New Testament & Portions',
  BIB018040: 'Bibles/ Other English Translations / Reference',
  BIB018050: 'Bibles/ Other English Translations / Study',
  BIB018060: 'Bibles/ Other English Translations / Text',
  BIB018070: 'Bibles/ Other English Translations / Youth & Teen',
  BIB019000: 'Bibles / Reina Valera / General',
  BIB019010: 'Bibles / Reina Valera / Children',
  BIB019020: 'Bibles / Reina Valera / Devotional',
  BIB019030: 'Bibles / Reina Valera / New Testament & Portions',
  BIB019040: 'Bibles / Reina Valera / Reference',
  BIB019050: 'Bibles / Reina Valera / Study',
  BIB019060: 'Bibles / Reina Valera / Text',
  BIB019070: 'Bibles / Reina Valera / Youth & Teen',
  BIB020000: 'Bibles / The Message / General',
  BIB020010: 'Bibles / The Message / Children',
  BIB020020: 'Bibles / The Message / Devotional',
  BIB020030: 'Bibles / The Message / New Testament & Portions',
  BIB020040: 'Bibles / The Message / Reference',
  BIB020050: 'Bibles / The Message / Study',
  BIB020060: 'Bibles / The Message / Text',
  BIB020070: 'Bibles / The Message / Youth & Teen',
  BIB021000: 'Bibles / Today\u2019s New International Version / General',
  BIB021010: 'Bibles / Today\u2019s New International Version / Children',
  BIB021020: 'Bibles / Today\u2019s New International Version / Devotional',
  BIB021030: 'Bibles / Today\u2019s New International Version / New Testament & Portions',
  BIB021040: 'Bibles / Today\u2019s New International Version / Reference',
  BIB021050: 'Bibles / Today\u2019s New International Version / Study',
  BIB021060: 'Bibles / Today\u2019s New International Version / Text',
  BIB021070: 'Bibles / Today\u2019s New International Version / Youth & Teen',
  BIB022000: 'Bibles / Common English Bible / General',
  BIB022010: 'Bibles / Common English Bible / Children',
  BIB022020: 'Bibles / Common English Bible / Devotional',
  BIB022030: 'Bibles / Common English Bible / New Testament & Portions',
  BIB022040: 'Bibles / Common English Bible / Reference',
  BIB022050: 'Bibles / Common English Bible / Study',
  BIB022060: 'Bibles / Common English Bible / Text',
  BIB022070: 'Bibles / Common English Bible / Youth & Teen',
  BIB023000: 'Bibles / The Amplified Bible / General',
  BIB023010: 'Bibles / The Amplified Bible / Children',
  BIB023020: 'Bibles / The Amplified Bible / Devotional',
  BIB023030: 'Bibles / The Amplified Bible / New Testament & Portions',
  BIB023040: 'Bibles / The Amplified Bible / Reference',
  BIB023050: 'Bibles / The Amplified Bible / Study',
  BIB023060: 'Bibles / The Amplified Bible / Text',
  BIB023070: 'Bibles / The Amplified Bible / Youth & Teen',
  BIO000000: 'Biography & Autobiography / General',
  BIO001000: 'Biography & Autobiography / Artists, Architects, Photographers',
  BIO002000: 'BIOGRAPHY & AUTOBIOGRAPHY / Cultural, Ethnic & Regional / General',
  BIO003000: 'Biography & Autobiography / Business',
  BIO004000: 'Biography & Autobiography / Composers & Musicians',
  BIO005000: 'Biography & Autobiography / Entertainment & Performing Arts',
  BIO006000: 'Biography & Autobiography / Historical',
  BIO007000: 'Biography & Autobiography / Literary',
  BIO008000: 'Biography & Autobiography / Military',
  BIO009000: 'Biography & Autobiography / Philosophers',
  BIO010000: 'Biography & Autobiography / Political',
  BIO011000: 'Biography & Autobiography / Presidents & Heads of State',
  BIO012000: 'Biography & Autobiography / Reference',
  BIO013000: 'Biography & Autobiography / Rich & Famous',
  BIO014000: 'Biography & Autobiography / Royalty',
  BIO015000: 'Biography & Autobiography / Science & Technology',
  BIO016000: 'Biography & Autobiography / Sports',
  BIO017000: 'Biography & Autobiography / Medical',
  BIO018000: 'Biography & Autobiography / Religious',
  BIO019000: 'Biography & Autobiography / Educators',
  BIO020000: 'Biography & Autobiography / Lawyers & Judges',
  BIO021000: 'Biography & Autobiography / Social Scientists & Psychologists',
  BIO022000: 'Biography & Autobiography / Women',
  BIO023000: 'Biography & Autobiography / Adventurers & Explorers',
  BIO024000: 'Biography & Autobiography / Criminals & Outlaws',
  BIO025000: 'Biography & Autobiography / Editors, Journalists, Publishers',
  BIO026000: 'Biography & Autobiography / Personal Memoirs',
  BIO027000: 'Biography & Autobiography / Law Enforcement',
  BIO028000: 'Biography & Autobiography/ Cultural, Ethnic & Regional / Indigenous',
  BIO029000: 'Biography & Autobiography / Culinary',
  BIO030000: 'Biography & Autobiography / Environmentalists & Naturalists',
  BIO031000: 'Biography & Autobiography / LGBTQ+',
  BIO032000: 'Biography & Autobiography / Social Activists',
  BIO033000: 'Biography & Autobiography / People with Disabilities',
  BUS000000: 'Business & Economics / General',
  BUS001000: 'Business & Economics / Accounting / General',
  BUS001010: 'Business & Economics / Accounting / Financial',
  BUS001020: 'Business & Economics / Accounting / Governmental',
  BUS001030: 'Business & Economics / International / Accounting',
  BUS001040: 'Business & Economics / Accounting / Managerial',
  BUS001050: 'Business & Economics / Accounting / Standards (GAAP, IFRS, etc.)',
  BUS002000: 'Business & Economics / Advertising & Promotion',
  BUS003000: 'Business & Economics / Auditing',
  BUS004000: 'Business & Economics / Banks & Banking',
  BUS005000: 'Business & Economics / Bookkeeping',
  BUS006000: 'Business & Economics / Budgeting',
  BUS007000: 'Business & Economics / Business Communication / General',
  BUS007010: 'Business & Economics / Business Communication / Meetings & Presentations',
  BUS008000: 'Business & Economics / Business Ethics',
  BUS009000: 'Business & Economics / Business Etiquette',
  BUS010000: 'Business & Economics / Business Law',
  BUS011000: 'Business & Economics / Business Writing',
  BUS012000: 'Business & Economics / Careers / General',
  BUS012010: 'Business & Economics / Careers / Internships',
  BUS013000: 'Business & Economics / Commercial Policy',
  BUS014000: 'Business & Economics/ Investments & Securities / Commodities / General',
  BUS014010: 'Business & Economics / Investments & Securities / Commodities / Energy',
  BUS014020: 'Business & Economics / Investments & Securities / Commodities / Metals',
  BUS015000: 'Business & Economics / Mergers & Acquisitions',
  BUS016000: 'Business & Economics / Consumer Behavior',
  BUS017000: 'Business & Economics/ Corporate Finance / General',
  BUS017010: 'Business & Economics / Corporate Finance / Private Equity',
  BUS017020: 'Business & Economics / Corporate Finance / Valuation',
  BUS017030: 'Business & Economics / Corporate Finance / Venture Capital',
  BUS018000: 'Business & Economics / Customer Relations',
  BUS019000: 'Business & Economics / Decision-Making & Problem Solving',
  BUS020000: 'BUSINESS & ECONOMICS / Development / Business Development',
  BUS021000: 'Business & Economics / Econometrics',
  BUS022000: 'Business & Economics / Economic Conditions',
  BUS023000: 'Business & Economics / Economic History',
  BUS024000: 'Business & Economics / Education',
  BUS025000: 'Business & Economics / Entrepreneurship',
  BUS026000: 'Business & Economics / Exports & Imports',
  BUS027000: 'BUSINESS & ECONOMICS / Finance / General',
  BUS027010: 'Business & Economics / Finance / Financial Engineering',
  BUS027020: 'Business & Economics / Finance / Financial Risk Management',
  BUS027030: 'Business & Economics / Finance / Wealth Management',
  BUS028000: 'Business & Economics / Foreign Exchange',
  BUS029000: 'Business & Economics / Free Enterprise',
  BUS030000: 'Business & Economics / Human Resources & Personnel Management',
  BUS031000: 'Business & Economics / Inflation',
  BUS032000: 'Business & Economics / Infrastructure',
  BUS033000: 'Business & Economics / Insurance / General',
  BUS033010: 'Business & Economics / Insurance / Automobile',
  BUS033020: 'Business & Economics / Insurance / Casualty',
  BUS033030: 'Business & Economics/ Insurance / Group',
  BUS033040: 'Business & Economics / Insurance / Health',
  BUS033050: 'Business & Economics / Insurance / Liability',
  BUS033060: 'Business & Economics / Insurance / Life',
  BUS033070: 'Business & Economics / Insurance / Risk Assessment & Management',
  BUS033080: 'Business & Economics / Insurance / Property',
  BUS034000: 'Business & Economics / Interest',
  BUS035000: 'Business & Economics / International / General',
  BUS036000: 'Business & Economics/ Investments & Securities / General',
  BUS036010: 'Business & Economics / Investments & Securities / Bonds',
  BUS036020: 'Business & Economics / Investments & Securities / Futures',
  BUS036030: 'Business & Economics / Investments & Securities / Mutual Funds',
  BUS036040: 'Business & Economics / Investments & Securities / Options',
  BUS036050: 'Business & Economics / Investments & Securities / Real Estate',
  BUS036060: 'Business & Economics / Investments & Securities / Stocks',
  BUS036070: 'Business & Economics / Investments & Securities / Analysis & Trading Strategies',
  BUS036080: 'Business & Economics / Investments & Securities / Derivatives',
  BUS036090: 'Business & Economics / Investments & Securities / Portfolio Management',
  BUS037020: 'Business & Economics / Careers / Job Hunting',
  BUS038000: 'Business & Economics / Labor',
  BUS039000: 'Business & Economics / Economics / Macroeconomics',
  BUS040000: 'Business & Economics / Mail Order',
  BUS041000: 'Business & Economics / Management',
  BUS042000: 'Business & Economics / Management Science',
  BUS043000: 'Business & Economics / Marketing / General',
  BUS043010: 'Business & Economics / Marketing / Direct',
  BUS043020: 'Business & Economics / Marketing / Industrial',
  BUS043030: 'Business & Economics / International / Marketing',
  BUS043040: 'Business & Economics / Marketing / Multilevel',
  BUS043050: 'Business & Economics / Marketing / Telemarketing',
  BUS043060: 'Business & Economics / Marketing / Research',
  BUS044000: 'Business & Economics / Economics / Microeconomics',
  BUS045000: 'Business & Economics / Money & Monetary Policy',
  BUS046000: 'Business & Economics / Motivational',
  BUS047000: 'Business & Economics / Negotiating',
  BUS048000: 'Business & Economics / New Business Enterprises',
  BUS049000: 'Business & Economics / Operations Research',
  BUS050000: 'Business & Economics / Personal Finance / General',
  BUS050010: 'Business & Economics / Personal Finance / Budgeting',
  BUS050020: 'Business & Economics / Personal Finance / Investing',
  BUS050030: 'Business & Economics / Personal Finance / Money Management',
  BUS050040: 'Business & Economics / Personal Finance / Retirement Planning',
  BUS050050: 'Business & Economics / Personal Finance / Taxation',
  BUS051000: 'Business & Economics / Public Finance',
  BUS052000: 'Business & Economics / Public Relations',
  BUS053000: 'Business & Economics / Quality Control',
  BUS054000: 'BUSINESS & ECONOMICS / Real Estate / General',
  BUS054010: 'Business & Economics / Real Estate / Buying & Selling Homes',
  BUS054020: 'Business & Economics / Real Estate / Commercial',
  BUS054030: 'Business & Economics / Real Estate / Mortgages',
  BUS055000: 'Business & Economics / Reference',
  BUS056030: 'Business & Economics / Careers / Resumes',
  BUS057000: 'Business & Economics / Industries / Retailing',
  BUS058000: 'BUSINESS & ECONOMICS / Sales & Selling / General',
  BUS058010: 'Business & Economics / Sales & Selling / Management',
  BUS059000: 'Business & Economics / Skills',
  BUS060000: 'Business & Economics / Small Business',
  BUS061000: 'Business & Economics / Statistics',
  BUS062000: 'Business & Economics / Structural Adjustment',
  BUS063000: 'BUSINESS & ECONOMICS / Strategic Planning',
  BUS064000: 'Business & Economics / Taxation / General',
  BUS064010: 'Business & Economics / Taxation / Corporate',
  BUS064020: 'Business & Economics / International / Taxation',
  BUS064030: 'Business & Economics / Taxation / Small Business',
  BUS065000: 'Business & Economics / Total Quality Management',
  BUS066000: 'Business & Economics / Training',
  BUS067000: 'Business & Economics / Urban & Regional',
  BUS068000: 'Business & Economics / Development / Economic Development',
  BUS069000: 'Business & Economics / Economics / General',
  BUS069010: 'Business & Economics / Economics / Comparative',
  BUS069020: 'Business & Economics/ International / Economics & Trade',
  BUS069030: 'Business & Economics / Economics / Theory',
  BUS070000: 'Business & Economics / Industries / General',
  BUS070010: 'Business & Economics / Industries / Agribusiness',
  BUS070020: 'Business & Economics / Industries / Automobile Industry',
  BUS070030: 'Business & Economics / Industries / Computers & Information Technology',
  BUS070040: 'Business & Economics / Industries / Energy',
  BUS070050: 'Business & Economics / Industries / Manufacturing',
  BUS070060: 'Business & Economics / Industries / Media & Communications',
  BUS070070: 'Business & Economics / Industries / Park & Recreation Management',
  BUS070080: 'Business & Economics / Industries / Service',
  BUS070090: 'Business & Economics / Industries / Fashion & Textile Industry',
  BUS070100: 'Business & Economics / Industries / Transportation',
  BUS070110: 'Business & Economics / Industries / Entertainment',
  BUS070120: 'Business & Economics / Industries / Food Industry',
  BUS070130: 'Business & Economics / Industries / Pharmaceutical & Biotechnology',
  BUS070140: 'Business & Economics / Industries / Financial Services',
  BUS070150: 'Business & Economics / Industries / Natural Resource Extraction',
  BUS070160: 'Business & Economics / Industries / Construction ',
  BUS071000: 'Business & Economics / Leadership',
  BUS072000: 'Business & Economics / Development / Sustainable Development',
  BUS073000: 'Business & Economics / Commerce',
  BUS074000: 'BUSINESS & ECONOMICS / Nonprofit Organizations & Charities / General',
  BUS074010: 'Business & Economics / Nonprofit Organizations & Charities / Finance & Accounting',
  BUS074020: 'Business & Economics / Nonprofit Organizations & Charities / Fundraising & Grants',
  BUS074030: 'Business & Economics / Nonprofit Organizations & Charities / Management & Leadership',
  BUS074040: 'Business & Economics / Nonprofit Organizations & Charities / Marketing & Communications',
  BUS075000: 'Business & Economics / Consulting',
  BUS076000: 'Business & Economics / Purchasing & Buying',
  BUS077000: 'Business & Economics / Corporate & Business History',
  BUS078000: 'Business & Economics / Distribution',
  BUS079000: 'Business & Economics / Government & Business',
  BUS080000: 'Business & Economics / Home-Based Businesses',
  BUS081000: 'Business & Economics / Industries / Hospitality, Travel & Tourism',
  BUS082000: 'Business & Economics / Industrial Management',
  BUS083000: 'Business & Economics / Information Management',
  BUS084000: 'Business & Economics / Office Automation',
  BUS085000: 'Business & Economics / Organizational Behavior',
  BUS086000: 'BUSINESS & ECONOMICS / Forecasting',
  BUS087000: 'Business & Economics / Production & Operations Management',
  BUS088000: 'Business & Economics / Time Management',
  BUS089000: 'Business & Economics / Secretarial Aids & Training',
  BUS090000: 'Business & Economics / E-Commerce / General ',
  BUS090010: 'Business & Economics/ E-Commerce / Digital Marketing',
  BUS090020: 'Business & Economics / E-Commerce / Online Banking',
  BUS090030: 'Business & Economics / E-Commerce / Online Trading',
  BUS090040: 'Business & Economics / E-Commerce / Auctions & Small Business',
  BUS091000: 'Business & Economics / Business Mathematics',
  BUS092000: 'Business & Economics / Development / General',
  BUS093000: 'Business & Economics / Facility Management',
  BUS094000: 'Business & Economics / Green Business',
  BUS095000: 'Business & Economics / Office Equipment & Supplies',
  BUS096000: 'Business & Economics / Office Management',
  BUS097000: 'Business & Economics / Workplace Culture',
  BUS098000: 'Business & Economics / Knowledge Capital',
  BUS099000: 'Business & Economics / Environmental Economics',
  BUS100000: 'Business & Economics / Museum Administration & Museology',
  BUS101000: 'Business & Economics / Project Management',
  BUS102000: 'Business & Economics / Outsourcing',
  BUS103000: 'Business & Economics / Organizational Development',
  BUS104000: 'Business & Economics / Corporate Governance',
  BUS105000: 'Business & Economics / Franchises',
  BUS106000: 'Business & Economics / Mentoring & Coaching',
  BUS107000: 'Business & Economics / Personal Success',
  BUS108000: 'Business & Economics / Research & Development',
  BUS109000: 'Business & Economics / Women in Business',
  BUS110000: 'Business & Economics / Conflict Resolution & Mediation',
  BUS111000: 'Business & Economics / Crowdfunding',
  BUS112000: 'Business & Economics / Islamic Banking & Finance',
  CGN000000: 'Comics & Graphic Novels / General',
  CGN001000: 'Comics & Graphic Novels / Anthologies',
  CGN002000: 'Comics & Graphic Novels / Comics & Cartoons',
  CGN003000: 'Comics & Graphic Novels / Educational',
  CGN004000: 'Comics & Graphic Novels / Graphic Novels / General',
  CGN004010: 'Comics & Graphic Novels / Crime & Mystery',
  CGN004020: 'Comics & Graphic Novels / Erotica',
  CGN004030: 'Comics & Graphic Novels / Fantasy',
  CGN004040: 'Comics & Graphic Novels / Horror',
  CGN004050: 'COMICS & GRAPHIC NOVELS / Manga / General',
  CGN004060: 'Comics & Graphic Novels / Media Tie-In',
  CGN004070: 'Comics & Graphic Novels / Science Fiction',
  CGN004080: 'COMICS & GRAPHIC NOVELS / Superheroes',
  CGN004090: 'Comics & Graphic Novels / Romance',
  CGN004100: 'Comics & Graphic Novels / Manga / Crime & Mystery',
  CGN004110: 'Comics & Graphic Novels / Manga / Erotica & Hentai',
  CGN004120: 'Comics & Graphic Novels / Manga / Fantasy',
  CGN004130: 'Comics & Graphic Novels / Manga / LGBTQ+',
  CGN004140: 'Comics & Graphic Novels / Manga / Historical Fiction',
  CGN004150: 'Comics & Graphic Novels / Manga / Horror',
  CGN004160: 'Comics & Graphic Novels / Manga / Media Tie-In',
  CGN004170: 'Comics & Graphic Novels / Manga / Nonfiction',
  CGN004180: 'Comics & Graphic Novels / Manga / Romance',
  CGN004190: 'Comics & Graphic Novels / Manga / Science Fiction',
  CGN004200: 'Comics & Graphic Novels / Manga / Sports',
  CGN004210: 'Comics & Graphic Novels / Manga / Yaoi',
  CGN004220: 'Comics & Graphic Novels / Manga / Religious',
  CGN004230: 'Comics & Graphic Novels / Manga / Dystopian',
  CGN005000: 'Comics & Graphic Novels / History & Criticism',
  CGN006000: 'Comics & Graphic Novels / Literary',
  CGN007000: 'Comics & Graphic Novels/ Nonfiction / General',
  CGN007010: 'Comics & Graphic Novels / Nonfiction / Biography & Memoir ',
  CGN008000: 'Comics & Graphic Novels / Contemporary Women',
  CGN009000: 'Comics & Graphic Novels / LGBTQ+',
  CGN010000: 'Comics & Graphic Novels / Historical Fiction',
  CGN011000: 'Comics & Graphic Novels / Religious',
  CGN012000: 'Comics & Graphic Novels / Adaptations',
  CGN013000: 'Comics & Graphic Novels / Dystopian',
  CKB000000: 'Cooking / General',
  CKB001000: 'Cooking / Regional & Ethnic / African',
  CKB002000: 'Cooking / Regional & Ethnic / American / General',
  CKB002010: 'Cooking / Regional & Ethnic / American / California Style',
  CKB002020: 'Cooking / Regional & Ethnic / American / Middle Atlantic States',
  CKB002030: 'Cooking / Regional & Ethnic / American / Middle Western States',
  CKB002040: 'Cooking / Regional & Ethnic / American / New England',
  CKB002050: 'Cooking / Regional & Ethnic / American / Northwestern States',
  CKB002060: 'Cooking / Regional & Ethnic / American / Southern States',
  CKB002070: 'Cooking / Regional & Ethnic / American / Southwestern States',
  CKB002080: 'Cooking / Regional & Ethnic / American / Western States',
  CKB003000: 'Cooking / Courses & Dishes / Appetizers',
  CKB004000: 'Cooking / Methods / Baking',
  CKB005000: 'Cooking / Methods / Barbecue & Grilling',
  CKB006000: 'Cooking / Beverages / Alcoholic / Bartending',
  CKB007000: 'Cooking / Beverages / Alcoholic / Beer',
  CKB008000: 'Cooking / Beverages / Non-Alcoholic',
  CKB009000: 'Cooking / Courses & Dishes / Bread',
  CKB010000: 'Cooking / Courses & Dishes / Breakfast',
  CKB011000: 'Cooking / Regional & Ethnic / English, Scottish & Welsh',
  CKB012000: 'Cooking / Courses & Dishes / Brunch & Tea Time',
  CKB013000: 'Cooking / Regional & Ethnic / Cajun & Creole',
  CKB014000: 'Cooking / Courses & Dishes / Cakes',
  CKB015000: 'Cooking / Methods / Canning & Preserving',
  CKB016000: 'Cooking / Regional & Ethnic / Caribbean & West Indian',
  CKB017000: 'Cooking / Regional & Ethnic / Chinese',
  CKB018000: 'Cooking / Specific Ingredients / Chocolate',
  CKB019000: 'Cooking / Beverages / Coffee & Tea',
  CKB020000: 'Cooking / Methods / Cookery for One',
  CKB021000: 'Cooking / Courses & Dishes / Cookies',
  CKB022000: 'Cooking/ Creole',
  CKB023000: 'Cooking / Methods / General',
  CKB024000: 'Cooking / Courses & Dishes / Desserts',
  CKB025000: 'Cooking / Health & Healing / Diabetic & Sugar-Free',
  CKB026000: 'Cooking / Health & Healing / Weight Control',
  CKB027000: 'Cooking/ Dutch',
  CKB028000: 'Cooking/ Eastern European',
  CKB029000: 'Cooking / Entertaining',
  CKB030000: 'Cooking / Essays & Narratives',
  CKB031000: 'Cooking / Regional & Ethnic / General',
  CKB032000: 'Cooking / Specific Ingredients / Game',
  CKB033000: 'Cooking / Methods / Garnishing & Food Presentation',
  CKB034000: 'Cooking / Regional & Ethnic / French',
  CKB035000: 'Cooking / Specific Ingredients / Fruit',
  CKB036000: 'Cooking / Regional & Ethnic / German',
  CKB037000: 'Cooking / Methods / Gourmet',
  CKB038000: 'Cooking / Regional & Ethnic / Greek',
  CKB039000: 'Cooking / Health & Healing / General',
  CKB040000: 'Cooking / Specific Ingredients / Herbs, Spices, Condiments',
  CKB041000: 'Cooking / History',
  CKB042000: 'Cooking / Holiday',
  CKB043000: 'Cooking / Regional & Ethnic / Hungarian',
  CKB044000: 'Cooking / Regional & Ethnic / Indian & South Asian',
  CKB045000: 'Cooking / Regional & Ethnic / International',
  CKB046000: 'Cooking / Regional & Ethnic / Irish',
  CKB047000: 'Cooking / Regional & Ethnic / Italian',
  CKB048000: 'Cooking / Regional & Ethnic / Japanese',
  CKB049000: 'Cooking / Regional & Ethnic / Jewish & Kosher',
  CKB050000: 'Cooking / Health & Healing / Low Cholesterol',
  CKB051000: 'Cooking / Health & Healing / Low Fat',
  CKB052000: 'Cooking / Health & Healing / Low Salt',
  CKB053000: 'Cooking/ Low Sugar',
  CKB054000: 'Cooking / Specific Ingredients / Meat',
  CKB055000: 'Cooking / Regional & Ethnic / Mediterranean',
  CKB056000: 'Cooking / Regional & Ethnic / Mexican',
  CKB057000: 'Cooking / Methods / Microwave',
  CKB058000: 'Cooking/ Regional & Ethnic / Indigenous Food of the Americas',
  CKB059000: 'Cooking / Specific Ingredients / Natural Foods',
  CKB060000: 'Cooking / Methods / Outdoor',
  CKB061000: 'Cooking / Specific Ingredients / Pasta',
  CKB062000: 'Cooking / Courses & Dishes / Pastry',
  CKB063000: 'Cooking / Courses & Dishes / Pies',
  CKB064000: 'Cooking / Courses & Dishes / Pizza',
  CKB065000: 'Cooking / Regional & Ethnic / Polish',
  CKB066000: 'Cooking / Regional & Ethnic / Portuguese',
  CKB067000: 'Cooking / Specific Ingredients / Poultry',
  CKB068000: 'Cooking / Methods / Professional',
  CKB069000: 'Cooking / Methods / Quantity',
  CKB070000: 'Cooking / Methods / Quick & Easy',
  CKB071000: 'Cooking / Reference',
  CKB072000: 'Cooking / Regional & Ethnic / Russian',
  CKB073000: 'Cooking / Courses & Dishes / Salads',
  CKB074000: 'Cooking / Regional & Ethnic / Scandinavian',
  CKB075000: 'Cooking/ Scottish',
  CKB076000: 'Cooking / Specific Ingredients / Seafood',
  CKB077000: 'Cooking / Seasonal',
  CKB078000: 'Cooking / Regional & Ethnic / Soul Food',
  CKB079000: 'Cooking / Courses & Dishes / Soups & Stews',
  CKB080000: 'Cooking / Regional & Ethnic / Spanish',
  CKB081000: 'Cooking / Methods / Special Appliances',
  CKB082000: 'Cooking / Tablesetting',
  CKB083000: 'Cooking / Regional & Ethnic / Thai',
  CKB084000: 'Cooking / Regional & Ethnic / Turkish',
  CKB085000: 'Cooking / Specific Ingredients / Vegetables',
  CKB086000: 'COOKING / Vegetarian',
  CKB087000: 'Cooking/ Welsh',
  CKB088000: 'Cooking/ Beverages / Alcoholic / General',
  CKB089000: 'Cooking / Methods / Wok',
  CKB090000: 'Cooking / Regional & Ethnic / Asian',
  CKB091000: 'Cooking / Regional & Ethnic / Canadian',
  CKB092000: 'Cooking / Regional & Ethnic / European',
  CKB093000: 'Cooking / Regional & Ethnic / Middle Eastern',
  CKB094000: 'Cooking / Regional & Ethnic / Vietnamese',
  CKB095000: 'Cooking / Courses & Dishes / Confectionery',
  CKB096000: 'Cooking / Specific Ingredients / Dairy',
  CKB097000: 'Cooking / Regional & Ethnic / Australian & Oceanian',
  CKB098000: 'Cooking / Specific Ingredients / Rice & Grains',
  CKB099000: 'Cooking / Regional & Ethnic / Central American & South American',
  CKB100000: 'Cooking / Beverages / General',
  CKB101000: 'Cooking / Courses & Dishes / General',
  CKB102000: 'Cooking / Courses & Dishes / Sauces & Dressings',
  CKB103000: 'Cooking / Health & Healing / Cancer',
  CKB104000: 'Cooking / Health & Healing / Heart',
  CKB105000: 'Cooking / Specific Ingredients / General',
  CKB106000: 'Cooking / Health & Healing / Allergy',
  CKB107000: 'Cooking / Baby Food',
  CKB108000: 'Cooking / Health & Healing / Low Carbohydrate',
  CKB109000: 'Cooking / Methods / Slow Cooking',
  CKB110000: 'Cooking / Methods / Raw Food',
  CKB111000: 'Cooking / Health & Healing / Gluten-Free',
  CKB112000: 'Cooking / Courses & Dishes / Casseroles',
  CKB113000: 'Cooking / Methods / Low Budget',
  CKB114000: 'Cooking / Health & Healing / High Protein',
  CKB115000: 'Cooking / Individual Chefs & Restaurants',
  CKB116000: 'Cooking / Methods / Frying',
  CKB117000: 'Cooking / Pet Food',
  CKB118000: 'Cooking / Beverages / Juices & Smoothies',
  CKB119000: 'Cooking / Cooking for Kids',
  CKB120000: 'Cooking / Cooking with Kids',
  CKB121000: 'Cooking / Courses & Dishes / Sandwiches',
  CKB122000: 'Cooking / Courses & Dishes / Ice Cream, Ices, etc. ',
  CKB123000: 'Cooking / Regional & Ethnic / Korean ',
  CKB124000: 'Cooking / Regional & Ethnic / Southeast Asian ',
  CKB125000: 'Cooking / Vegan ',
  COM000000: 'Computers / General',
  COM001000: 'Computers/ Accessories',
  COM002000: 'Computers/ Advanced Applications',
  COM003000: 'Computers/ Application Software / General',
  COM003010: 'Computers/ Application Software / IBM-Compatible',
  COM003020: 'Computers/ Application Software / Macintosh',
  COM004000: 'Computers / Intelligence (AI) & Semantics',
  COM005000: 'Computers/ Business & Productivity Software / General',
  COM005010: 'Computers/ Business Software / IBM-Compatible',
  COM005020: 'Computers/ Business Software / Macintosh',
  COM005030: 'Computers/ Business & Productivity Software / Business Intelligence',
  COM006000: 'Computers / Buyer\u2019s Guides',
  COM007000: 'Computers / CAD-CAM',
  COM007010: 'Computers/ CAD-CAM / IBM-Compatible',
  COM007020: 'Computers/ CAD-CAM / Macintosh',
  COM008000: 'Computers / Calculators',
  COM009000: 'Computers / CD-DVD Technology',
  COM009010: 'Computers/ CD-ROM Technology / IBM-Compatible',
  COM009020: 'Computers/ CD-ROM Technology / Macintosh',
  COM010000: 'Computers / Compilers',
  COM011000: 'Computers / Systems Architecture / General',
  COM012000: 'Computers / Computer Graphics',
  COM012010: 'Computers/ Computer Graphics / IBM-Compatible',
  COM012020: 'Computers/ Computer Graphics / Macintosh',
  COM012030: 'Computers/ Computer Graphics / Design',
  COM012040: 'Computers / Programming / Games',
  COM012050: 'Computers / Image Processing',
  COM013000: 'Computers / Computer Literacy',
  COM014000: 'Computers / Computer Science',
  COM015000: 'Computers / Security / Viruses & Malware',
  COM016000: 'Computers / Computer Vision & Pattern Recognition',
  COM017000: 'Computers / Cybernetics',
  COM018000: 'Computers / Data Processing',
  COM019000: 'Computers / System Administration / Disaster & Recovery',
  COM020000: 'Computers / Data Transmission Systems / General',
  COM020010: 'Computers / Data Transmission Systems / Electronic Data Interchange',
  COM020020: 'Computers / System Administration / Email Administration',
  COM020030: 'Computers/ Data Transmission Systems / Facsimile Transmission',
  COM020040: 'Computers/ Data Transmission Systems / Image Transmission',
  COM020050: 'Computers / Data Transmission Systems / Broadband',
  COM020060: 'Computers/ Data Transmission Systems / Modems',
  COM020070: 'Computers/ Data Transmission Systems / Videotext Systems',
  COM020080: 'Computers/ Data Transmission Systems / ATM (Asynchronous Transfer Mode)',
  COM020090: 'Computers / Data Transmission Systems / Wireless',
  COM021000: 'Computers / Databases / General',
  COM021010: 'Computers/ Database Management / IBM-Compatible',
  COM021020: 'Computers/ Database Management / Macintosh',
  COM021030: 'Computers / Databases / Data Mining',
  COM021040: 'Computers / Databases / Data Warehousing',
  COM021050: 'Computers / Databases / Servers',
  COM022000: 'Computers / Desktop Applications / Desktop Publishing',
  COM022010: 'Computers/ Desktop Publishing / IBM-Compatible',
  COM022020: 'Computers/ Desktop Publishing / Macintosh',
  COM023000: 'Computers / Educational Software',
  COM023010: 'Computers/ Educational Software / IBM-Compatible',
  COM023020: 'Computers/ Educational Software / Macintosh',
  COM024000: 'Computers/ Entertainment & Games',
  COM024010: 'Computers/ Entertainment & Games / IBM-Compatible',
  COM024020: 'Computers/ Entertainment & Games / Macintosh',
  COM025000: 'Computers / Expert Systems',
  COM026000: 'Computers/ Fault-Tolerant Computing',
  COM027000: 'Computers/ Business & Productivity Software / Accounting & Finance',
  COM027010: 'Computers/ Financial Applications / IBM-Compatible',
  COM027020: 'Computers/ Financial Applications / Macintosh',
  COM028000: 'Computers/ Hard Disk Management',
  COM029000: 'Computers/ Hypertext Systems',
  COM030000: 'Computers / System Administration / Storage & Retrieval',
  COM031000: 'Computers / Information Theory',
  COM032000: 'Computers / Information Technology',
  COM033000: 'Computers/ Integrated Software / General',
  COM033010: 'Computers/ Integrated Software / IBM-Compatible',
  COM033020: 'Computers/ Integrated Software / Macintosh',
  COM034000: 'Computers / Interactive & Multimedia',
  COM035000: 'Computers / Keyboarding',
  COM036000: 'Computers / Logic Design',
  COM037000: 'Computers / Machine Theory',
  COM038000: 'Computers / Hardware / Mainframes & Minicomputers',
  COM039000: 'Computers / Management Information Systems',
  COM040000: 'Computers/ Memory Management',
  COM041000: 'Computers / Microprocessors',
  COM042000: 'Computers / Natural Language Processing',
  COM043000: 'Computers / Networking / General',
  COM043010: 'Computers/ Networking / Bulletin Boards',
  COM043020: 'Computers / Networking / Local Area Networks (LANs)',
  COM043030: 'Computers/ Networking / Wide Area Networks (WANs)',
  COM043040: 'Computers / Networking / Network Protocols',
  COM043050: 'Computers / Security / Networking',
  COM043060: 'Computers / Networking / Vendor Specific',
  COM044000: 'Computers / Neural Networks',
  COM045000: 'Computers/ Online Data Processing',
  COM046000: 'Computers / Operating Systems / General',
  COM046010: 'Computers/ Operating Systems / IBM-Compatible',
  COM046020: 'Computers / Operating Systems / Apple (Mac OS & iOS)',
  COM046030: 'Computers / Operating Systems / UNIX',
  COM046040: 'Computers / Operating Systems / Windows Desktop',
  COM046050: 'Computers / Operating Systems / Windows Server',
  COM046060: 'Computers / Operating Systems / DOS',
  COM046070: 'Computers / Operating Systems / Linux',
  COM046080: 'Computers / Operating Systems / Mainframe & Midrange',
  COM046090: 'Computers / Operating Systems / Virtualization',
  COM047000: 'Computers / Optical Data Processing',
  COM048000: 'Computers / Systems Architecture / Distributed Systems & Computing',
  COM049000: 'Computers / Hardware / Peripherals',
  COM050000: 'Computers / Hardware / Personal Computers / General',
  COM050010: 'Computers / Hardware / Personal Computers / PCs',
  COM050020: 'Computers / Hardware / Personal Computers / Macintosh',
  COM050030: 'Computers/ Personal Computers & Microcomputers / Upgrading',
  COM051000: 'Computers / Programming / General',
  COM051010: 'Computers / Programming Languages / General',
  COM051020: 'Computers / Programming Languages / Ada',
  COM051030: 'Computers/ Programming Languages / ANSI C',
  COM051040: 'Computers / Programming Languages / Assembly Language',
  COM051050: 'Computers / Programming Languages / BASIC',
  COM051060: 'Computers / Programming Languages / C',
  COM051070: 'Computers / Programming Languages / C++',
  COM051080: 'Computers / Programming Languages / COBOL',
  COM051090: 'Computers / Programming Languages / FORTRAN',
  COM051100: 'Computers / Programming Languages / LISP',
  COM051110: 'Computers/ Programming Languages / Microsoft C',
  COM051120: 'Computers/ Programming Languages / Modula-2',
  COM051130: 'Computers / Programming Languages / Pascal',
  COM051140: 'Computers / Programming Languages / Prolog',
  COM051150: 'Computers/ Programming Languages / Quick C',
  COM051160: 'Computers / Programming Languages / Smalltalk',
  COM051170: 'Computers / Programming Languages / SQL',
  COM051180: 'Computers/ Programming Languages / Turbo C',
  COM051190: 'Computers/ Programming Languages / Turbo Pascal',
  COM051200: 'Computers / Programming Languages / Visual BASIC',
  COM051210: 'Computers / Programming / Object Oriented',
  COM051220: 'Computers / Programming / Parallel',
  COM051230: 'Computers / Software Development & Engineering / General',
  COM051240: 'Computers / Software Development & Engineering / Systems Analysis & Design',
  COM051250: 'Computers/ Programming / Visual',
  COM051260: 'Computers/ Languages / JavaScript',
  COM051270: 'COMPUTERS / Programming Languages / HTML',
  COM051280: 'Computers / Programming Languages / Java',
  COM051290: 'Computers / Programming Languages / RPG',
  COM051300: 'Computers / Programming / Algorithms',
  COM051310: 'Computers / Programming Languages / C#',
  COM051320: 'Computers / Programming Languages / XML',
  COM051330: 'Computers / Software Development & Engineering / Quality Assurance & Testing',
  COM051340: 'Computers / Programming Languages / CGI',
  COM051350: 'Computers / Programming Languages / Perl',
  COM051360: 'Computers / Programming Languages / Python',
  COM051370: 'Computers / Programming / Macintosh',
  COM051380: 'Computers / Programming / Microsoft',
  COM051390: 'Computers / Programming / Open Source',
  COM051400: 'Computers / Programming Languages / PHP',
  COM051410: 'Computers / Programming Languages / Ruby',
  COM051420: 'Computers / Programming Languages / VBScript',
  COM051430: 'Computers / Software Development & Engineering / Project Management',
  COM051440: 'Computers / Software Development & Engineering / Tools',
  COM051450: 'Computers / Programming Languages / UML',
  COM051460: 'Computers / Programming / Mobile Devices',
  COM051470: 'Computers / Programming Languages / ASP.NET',
  COM052000: 'Computers / Reference',
  COM053000: 'Computers / Security / General',
  COM054000: 'Computers/ Business & Productivity Software / Spreadsheets',
  COM054010: 'Computers/ Spreadsheets / IBM-Compatible',
  COM054020: 'Computers/ Spreadsheets / Macintosh',
  COM055000: 'COMPUTERS / Certification Guides / General',
  COM055010: 'Computers/ Certification Guides / CompTIA (incl. A+)',
  COM055020: 'Computers/ Certification Guides / Microsoft',
  COM056000: 'Computers / Utilities',
  COM057000: 'Computers / Virtual Worlds',
  COM058000: 'Computers/ Business & Productivity Software / Word Processing',
  COM058010: 'Computers/ Word Processing / IBM-Compatible',
  COM058020: 'Computers/ Word Processing / Macintosh',
  COM059000: 'Computers / Computer Engineering',
  COM060000: 'Computers / Internet / General',
  COM060010: 'Computers / Web / Browsers',
  COM060020: 'Computers/ Internet / Hardware',
  COM060030: 'Computers / Networking / Intranets & Extranets',
  COM060040: 'Computers / Security / Online Safety & Privacy',
  COM060050: 'Computers/ Internet / Server Maintenance',
  COM060060: 'Computers / Web / Page Design',
  COM060070: 'Computers / Web / Site Directories',
  COM060080: 'COMPUTERS / Web / General',
  COM060090: 'Computers / Internet / Application Development',
  COM060100: 'Computers / Web / Blogging',
  COM060110: 'Computers / Web / Podcasting & Webcasting',
  COM060120: 'Computers / Web / Search Engines',
  COM060130: 'Computers / Web / Design',
  COM060140: 'Computers / Web / Social Media',
  COM060150: 'Computers / Web / User-Generated Content',
  COM060160: 'Computers / Web / Web Programming',
  COM060170: 'Computers / Web / Content Management Systems',
  COM060180: 'Computers / Web / Web Services & APIs',
  COM061000: 'Computers / Client-Server Computing',
  COM062000: 'Computers / Data Modeling & Design',
  COM063000: 'Computers / Document Management',
  COM064000: 'Computers / Electronic Commerce ',
  COM065000: 'Computers / Electronic Publishing',
  COM066000: 'Computers/ Business & Productivity Software / Collaboration',
  COM067000: 'Computers / Hardware / General',
  COM068000: 'Computers/ Multimedia',
  COM069000: 'Computers / Online Services',
  COM069010: 'Computers / Online Services / Resource Directories',
  COM070000: 'Computers / User Interfaces',
  COM071000: 'Computers / Digital Media / Video & Animation',
  COM072000: 'Computers / Computer Simulation',
  COM073000: 'Computers / Speech & Audio Processing',
  COM074000: 'Computers / Hardware / Mobile Devices',
  COM075000: 'Computers / Networking / Hardware',
  COM076000: 'Computers / Hardware / Workstations',
  COM077000: 'Computers / Mathematical & Statistical Software',
  COM078000: 'Computers/ Business & Productivity Software / Presentations',
  COM079000: 'Computers / Social Aspects / General',
  COM079010: 'Computers / Social Aspects / Human-Computer Interaction',
  COM080000: 'Computers / History',
  COM081000: 'Computers/ Business & Productivity Software / Project Management Tools',
  COM082000: 'Computers / Bioinformatics',
  COM083000: 'Computers / Security / Cryptography',
  COM084000: 'Computers / Desktop Applications / General',
  COM084010: 'Computers/ Business & Productivity Software / Databases',
  COM084020: 'Computers/ Business & Productivity Software / Email Clients',
  COM084030: 'Computers/ Business & Productivity Software / Office Suites',
  COM085000: 'Computers / Documentation & Technical Writing',
  COM086000: 'Computers / Computerized Home & Entertainment',
  COM087000: 'Computers / Digital Media / General',
  COM087010: 'Computers / Digital Media / Audio',
  COM087020: 'Computers / Desktop Applications / Design & Graphics',
  COM087030: 'Computers / Digital Media / Photography ',
  COM088000: 'Computers / System Administration / General',
  COM088010: 'Computers / System Administration / Linux & UNIX Administration',
  COM088020: 'Computers / System Administration / Windows Administration',
  COM089000: 'Computers / Data Visualization',
  COM090000: 'Computers / Hardware / Tablets',
  COM091000: 'Computers / Cloud Computing',
  CRA000000: 'Crafts & Hobbies / General',
  CRA001000: 'Crafts & Hobbies / Applique',
  CRA002000: 'Crafts & Hobbies / Baskets',
  CRA003000: 'Crafts & Hobbies / Carving',
  CRA004000: 'Crafts & Hobbies / Needlework / Crocheting',
  CRA005000: 'Crafts & Hobbies / Decorating',
  CRA006000: 'Crafts & Hobbies / Dough',
  CRA007000: 'Crafts & Hobbies / Dye',
  CRA008000: 'Crafts & Hobbies / Needlework / Embroidery',
  CRA009000: 'Crafts & Hobbies / Fashion',
  CRA010000: 'Crafts & Hobbies / Flower Arranging',
  CRA011000: 'Crafts & Hobbies / Framing',
  CRA012000: 'Crafts & Hobbies / Glass & Glassware',
  CRA013000: 'Crafts & Hobbies/ Graphic Arts',
  CRA014000: 'Crafts & Hobbies / Jewelry',
  CRA015000: 'Crafts & Hobbies / Needlework / Knitting',
  CRA016000: 'Crafts & Hobbies / Needlework / Lace & Tatting',
  CRA017000: 'Crafts & Hobbies / Metal Work',
  CRA018000: 'Crafts & Hobbies / Miniatures',
  CRA019000: 'Crafts & Hobbies / Mobiles',
  CRA020000: 'Crafts & Hobbies / Models',
  CRA021000: 'Crafts & Hobbies / Needlework / Needlepoint',
  CRA022000: 'Crafts & Hobbies / Needlework / General',
  CRA023000: 'Crafts & Hobbies / Origami',
  CRA024000: 'Crafts & Hobbies / Painting',
  CRA025000: 'Crafts & Hobbies / Papercrafts',
  CRA026000: 'Crafts & Hobbies / Patchwork',
  CRA027000: 'Crafts & Hobbies / Potpourri',
  CRA028000: 'Crafts & Hobbies / Pottery & Ceramics',
  CRA029000: 'Crafts & Hobbies / Printmaking',
  CRA030000: 'Crafts & Hobbies / Puppets & Puppetry',
  CRA031000: 'Crafts & Hobbies / Quilts & Quilting',
  CRA032000: 'Crafts & Hobbies / Reference',
  CRA033000: 'Crafts & Hobbies / Rugs',
  CRA034000: 'Crafts & Hobbies / Seasonal',
  CRA035000: 'Crafts & Hobbies / Sewing',
  CRA036000: 'Crafts & Hobbies / Stenciling',
  CRA037000: 'Crafts & Hobbies / Stuffed Animals',
  CRA038000: 'Crafts & Hobbies/ Tatting',
  CRA039000: 'Crafts & Hobbies / Toymaking',
  CRA040000: 'Crafts & Hobbies / Weaving',
  CRA041000: 'Crafts & Hobbies / Wood Toys',
  CRA042000: 'Crafts & Hobbies / Woodwork',
  CRA043000: 'Crafts & Hobbies / Crafts for Children',
  CRA044000: 'Crafts & Hobbies / Needlework / Cross-Stitch',
  CRA045000: 'Crafts & Hobbies / Model Railroading',
  CRA046000: 'Crafts & Hobbies / Book Printing & Binding',
  CRA047000: 'Crafts & Hobbies / Folkcrafts',
  CRA048000: 'Crafts & Hobbies / Beadwork',
  CRA049000: 'Crafts & Hobbies/ Candle Making',
  CRA050000: 'Crafts & Hobbies / Leatherwork',
  CRA051000: 'Crafts & Hobbies / Polymer Clay',
  CRA052000: 'Crafts & Hobbies / Scrapbooking',
  CRA053000: 'Crafts & Hobbies / Nature Crafts',
  CRA054000: 'Crafts & Hobbies / Mixed Media',
  CRA055000: 'Crafts & Hobbies / Knots, Macrame & Rope Work',
  CRA056000: 'Crafts & Hobbies / Dollhouses',
  CRA057000: 'Crafts & Hobbies / Dolls & Doll Clothing',
  CRA058000: 'Crafts & Hobbies / Ribbon Work',
  CRA059000: 'Crafts & Hobbies / Wirework',
  CRA060000: 'Crafts & Hobbies / Felting',
  CRA061000: 'Crafts & Hobbies / Fiber Arts & Textiles',
  CUR000000: 'Current Events/ General',
  CUR001000: 'Current Events/ American',
  CUR002000: 'Current Events/ Government',
  CUR003000: 'Current Events/ Homelessness',
  CUR004000: 'Current Events/ International',
  CUR005000: 'Current Events/ Law',
  CUR006000: 'Current Events/ Mass Media',
  CUR007000: 'Current Events/ Military',
  CUR008000: 'Current Events/ Peace',
  CUR009000: 'Current Events/ Political',
  CUR010000: 'Current Events/ Poverty',
  DES000000: 'Design / General',
  DES001000: 'Design / Book',
  DES002000: 'Design / Clip Art',
  DES003000: 'Design / Decorative Arts',
  DES004000: 'Design / Essays',
  DES005000: 'Design / Fashion & Accessories',
  DES006000: 'Design / Furniture',
  DES007000: 'Design / Graphic Arts / General',
  DES007010: 'Design / Graphic Arts / Advertising',
  DES007020: 'Design / Graphic Arts / Branding & Logo Design',
  DES007030: 'Design / Graphic Arts / Commercial & Corporate',
  DES007040: 'Design / Graphic Arts / Illustration',
  DES007050: 'Design / Graphic Arts / Typography',
  DES008000: 'Design / History & Criticism',
  DES009000: 'Design / Industrial',
  DES010000: 'Design / Interior Decorating',
  DES011000: 'Design / Product',
  DES012000: 'Design / Reference',
  DES013000: 'Design / Textile & Costume',
  DES014000: 'Design / Jewelry ',
  DRA000000: 'Drama / General',
  DRA001000: 'Drama / American / General',
  DRA001010: 'Drama/ American / African American & Black',
  DRA002000: 'Drama / Anthologies (multiple authors)',
  DRA003000: 'Drama / European / English, Irish, Scottish, Welsh',
  DRA004000: 'Drama / European / General',
  DRA004010: 'Drama / European / French',
  DRA004020: 'Drama / European / German',
  DRA004030: 'Drama / European / Italian',
  DRA004040: 'Drama / European / Spanish & Portuguese',
  DRA005000: 'Drama / Asian / General',
  DRA005010: 'Drama / Asian / Japanese',
  DRA006000: 'Drama/ Ancient & Classical',
  DRA007000: 'Drama/ History & Criticism',
  DRA008000: 'Drama / Religious & Liturgical',
  DRA009000: 'Drama/ Reference',
  DRA010000: 'DRAMA / Shakespeare',
  DRA011000: 'Drama / African',
  DRA012000: 'Drama / Australian & Oceanian',
  DRA013000: 'Drama / Canadian',
  DRA014000: 'Drama / Caribbean & Latin American',
  DRA015000: 'Drama / Middle Eastern',
  DRA016000: 'Drama / Russian & Former Soviet Union',
  DRA017000: 'Drama / LGBTQ+',
  DRA018000: 'Drama / Medieval',
  DRA019000: 'Drama / Women Authors',
  EDU000000: 'Education / General',
  EDU001000: 'Education / Administration / General',
  EDU001010: 'Education / Administration / Facility Management',
  EDU001020: 'Education / Administration / Elementary & Secondary',
  EDU001030: 'Education / Administration / Higher',
  EDU001040: 'Education / Administration / School Superintendents & Principals',
  EDU002000: 'Education / Adult & Continuing Education',
  EDU003000: 'Education / Aims & Objectives',
  EDU004000: 'Education/ Aids & Device [sic]',
  EDU005000: 'Education / Bilingual Education',
  EDU006000: 'Education / Counseling / General',
  EDU007000: 'Education / Curricula',
  EDU008000: 'Education / Decision-Making & Problem Solving',
  EDU009000: 'Education / Educational Psychology',
  EDU010000: 'Education / Elementary',
  EDU011000: 'Education / Evaluation & Assessment',
  EDU012000: 'Education / Experimental Methods',
  EDU013000: 'Education / Finance',
  EDU014000: 'Education/ Counseling / Academic Development',
  EDU015000: 'Education / Higher',
  EDU016000: 'Education / History',
  EDU017000: 'Education / Home Schooling',
  EDU018000: 'Education / Language Experience Approach',
  EDU019000: 'Education/ Library & Information Science',
  EDU020000: 'Education / Multicultural Education',
  EDU021000: 'Education / Non-Formal Education',
  EDU022000: 'Education / Parent Participation',
  EDU023000: 'Education / Early Childhood (incl. Preschool & Kindergarten)',
  EDU024000: 'Education / Reference',
  EDU025000: 'Education / Secondary',
  EDU026000: 'Education / Special Education / General',
  EDU026010: 'Education / Special Education / Communicative Disorders',
  EDU026020: 'Education / Special Education / Learning Disabilities',
  EDU026030: 'Education / Special Education / Developmental & Intellectual Disabilities',
  EDU026040: 'Education / Special Education / Physical Disabilities',
  EDU026050: 'Education / Special Education / Behavioral, Emotional & Social Disabilities',
  EDU026060: 'Education / Special Education / Gifted',
  EDU027000: 'Education / Statistics',
  EDU028000: 'Education / Study Skills',
  EDU029000: 'Education / Teaching Methods & Materials / General',
  EDU029010: 'Education / Teaching Methods & Materials / Mathematics',
  EDU029020: 'Education / Teaching Methods & Materials / Reading & Phonics',
  EDU029030: 'Education / Teaching Methods & Materials / Science & Technology',
  EDU029040: 'Education / Teaching Methods & Materials / Social Science',
  EDU029050: 'Education / Teaching Methods & Materials / Arts & Humanities',
  EDU029060: 'Education / Teaching Methods & Materials / Library Skills',
  EDU029070: 'Education / Teaching Methods & Materials / Health & Sexuality',
  EDU029080: 'Education / Teaching Methods & Materials / Language Arts',
  EDU030000: 'Education / Testing & Measurement',
  EDU031000: 'EDUCATION / Counseling / Career Development',
  EDU032000: 'Education / Leadership',
  EDU033000: 'Education / Physical Education',
  EDU034000: 'Education / Educational Policy & Reform / General',
  EDU034010: 'Education/ Educational Policy & Reform / School Safety',
  EDU034020: 'Education / Educational Policy & Reform / Charter Schools',
  EDU034030: 'Education / Educational Policy & Reform / Federal Legislation',
  EDU035000: 'Education/ Funding',
  EDU036000: 'Education / Organizations & Institutions',
  EDU037000: 'Education / Research',
  EDU038000: 'Education / Student Life & Student Affairs',
  EDU039000: 'Education / Computers & Technology',
  EDU040000: 'Education / Philosophy, Theory & Social Aspects',
  EDU041000: 'Education / Distance, Open & Online Education',
  EDU042000: 'Education / Essays',
  EDU043000: 'Education / Comparative',
  EDU044000: 'Education / Classroom Management',
  EDU045000: 'Education / Counseling / Crisis Management',
  EDU046000: 'Education / Professional Development',
  EDU047000: 'Education / Driver Education',
  EDU048000: 'Education / Inclusive Education',
  EDU049000: 'Education / Behavioral Management',
  EDU050000: 'Education / Collaborative & Team Teaching',
  EDU051000: 'Education / Learning Styles',
  EDU052000: 'Education / Rural',
  EDU053000: 'Education / Training & Certification',
  EDU054000: 'Education / Urban',
  EDU055000: 'Education / Violence & Harassment',
  EDU056000: 'Education / Vocational',
  EDU057000: 'Education / Arts in Education',
  EDU058000: 'Education / Standards (incl. Common Core)',
  EDU059000: 'Education / Teacher & Student Mentoring',
  FAM000000: 'Family & Relationships / General',
  FAM001000: 'Family & Relationships / Abuse / General',
  FAM001010: 'Family & Relationships / Abuse / Child Abuse',
  FAM001020: 'Family & Relationships / Abuse / Elder Abuse',
  FAM001030: 'Family & Relationships / Abuse / Domestic Partner Abuse',
  FAM002000: 'Family & Relationships / Activities',
  FAM003000: 'Family & Relationships / Life Stages / Adolescence',
  FAM004000: 'Family & Relationships / Adoption & Fostering',
  FAM005000: 'Family & Relationships / Life Stages / Later Years',
  FAM006000: 'Family & Relationships / Alternative Family',
  FAM007000: 'Family & Relationships / Anger ',
  FAM008000: 'Family & Relationships / Baby Names',
  FAM009000: 'Family & Relationships/ Breastfeeding',
  FAM010000: 'Family & Relationships / Parenting / Child Rearing',
  FAM011000: 'Family & Relationships / Child Development',
  FAM012000: 'Family & Relationships / Children with Special Needs',
  FAM013000: 'Family & Relationships / Conflict Resolution',
  FAM014000: 'Family & Relationships / Death, Grief, Bereavement',
  FAM015000: 'Family & Relationships / Divorce & Separation',
  FAM016000: 'Family & Relationships / Education',
  FAM017000: 'Family & Relationships / Eldercare',
  FAM018000: 'Family & Relationships / Emotions',
  FAM019000: 'Family & Relationships / Family Relationships',
  FAM020000: 'Family & Relationships / Parenting / Fatherhood',
  FAM021000: 'Family & Relationships / Friendship',
  FAM022000: 'Family & Relationships / Parenting / Grandparenting',
  FAM023000: 'Family & Relationships/ Health',
  FAM024000: 'Family & Relationships/ Humorous',
  FAM025000: 'Family & Relationships / Life Stages / Infants & Toddlers',
  FAM026000: 'Family & Relationships/ Infertility',
  FAM027000: 'Family & Relationships / Interpersonal Relations',
  FAM028000: 'Family & Relationships / Learning Disabilities',
  FAM029000: 'Family & Relationships / Love & Romance',
  FAM030000: 'Family & Relationships / Marriage & Long-Term Relationships',
  FAM031000: 'Family & Relationships / Ethics & Morals',
  FAM032000: 'Family & Relationships / Parenting / Motherhood',
  FAM033000: 'Family & Relationships / Parenting / Parent & Adult Child',
  FAM034000: 'Family & Relationships / Parenting / General',
  FAM034010: 'Family & Relationships / Parenting / Single Parent',
  FAM035000: 'Family & Relationships / Peer Pressure',
  FAM036000: 'Family & Relationships/ Pregnancy & Childbirth',
  FAM037000: 'Family & Relationships / Prejudice',
  FAM038000: 'Family & Relationships / Reference',
  FAM039000: 'Family & Relationships / Life Stages / School Age',
  FAM040000: 'Family & Relationships/ Sexuality',
  FAM041000: 'Family & Relationships / Siblings',
  FAM042000: 'Family & Relationships / Parenting / Stepparenting',
  FAM043000: 'Family & Relationships / Life Stages / Teenagers',
  FAM044000: 'Family & Relationships / Toilet Training',
  FAM045000: 'Family & Relationships/ Essays',
  FAM046000: 'Family & Relationships / Life Stages / General',
  FAM047000: 'Family & Relationships / Attention Deficit Disorder (ADD-ADHD)',
  FAM048000: 'Family & Relationships / Autism Spectrum Disorders',
  FAM049000: 'Family & Relationships / Bullying',
  FAM050000: 'Family & Relationships / Babysitting, Day Care & Child Care',
  FAM051000: 'Family & Relationships / Dating',
  FAM052000: 'Family & Relationships / Dysfunctional Families',
  FAM053000: 'Family & Relationships / Extended Family',
  FAM054000: 'Family & Relationships / Life Stages / Mid-Life',
  FAM055000: 'Family & Relationships / Military Families',
  FIC000000: 'Fiction / General',
  FIC001000: 'Fiction/ Action',
  FIC002000: 'Fiction / Action & Adventure',
  FIC003000: 'Fiction / Anthologies (multiple authors)',
  FIC004000: 'Fiction / Classics',
  FIC005000: 'FICTION / Erotica / General',
  FIC005010: 'Fiction / Erotica / BDSM',
  FIC005020: 'Fiction / Erotica / Collections & Anthologies',
  FIC005030: 'Fiction / Erotica / LGBTQ+ / Gay',
  FIC005040: 'Fiction / Erotica / LGBTQ+ / Lesbian',
  FIC005050: 'Fiction / Erotica / Science Fiction, Fantasy & Horror',
  FIC005060: 'Fiction / Erotica / Traditional Victorian',
  FIC006000: 'Fiction / Thrillers / Espionage',
  FIC007000: 'Fiction/ Fairy Tales',
  FIC008000: 'Fiction / Sagas',
  FIC009000: 'Fiction / Fantasy / General',
  FIC009010: 'Fiction / Fantasy / Contemporary',
  FIC009020: 'Fiction / Fantasy / Epic',
  FIC009030: 'Fiction / Fantasy / Historical',
  FIC009040: 'Fiction / Fantasy / Collections & Anthologies',
  FIC009050: 'Fiction / Fantasy / Paranormal',
  FIC009060: 'Fiction / Fantasy / Urban',
  FIC009070: 'Fiction / Fantasy / Dark Fantasy',
  FIC009080: 'Fiction / Fantasy / Humorous',
  FIC009090: 'Fiction / Fantasy / Romantic ',
  FIC010000: 'Fiction / Fairy Tales, Folk Tales, Legends & Mythology',
  FIC011000: 'Fiction / LGBTQ+ / Gay',
  FIC012000: 'Fiction / Ghost',
  FIC013000: 'Fiction/ Graphic Novels',
  FIC014000: 'FICTION / Historical / General',
  FIC015000: 'Fiction / Horror',
  FIC016000: 'Fiction / Humorous / General',
  FIC017000: 'Fiction/ Interactive',
  FIC018000: 'Fiction / LGBTQ+ / Lesbian',
  FIC019000: 'Fiction / Literary',
  FIC020000: 'Fiction / Men\u2019s Adventure',
  FIC021000: 'Fiction / Media Tie-In',
  FIC022000: 'Fiction / Mystery & Detective / General',
  FIC022010: 'Fiction / Mystery & Detective / Hard-Boiled',
  FIC022020: 'Fiction / Mystery & Detective / Police Procedural',
  FIC022030: 'Fiction / Mystery & Detective / Traditional',
  FIC022040: 'Fiction / Mystery & Detective / Women Sleuths',
  FIC022050: 'Fiction / Mystery & Detective / Collections & Anthologies',
  FIC022060: 'Fiction / Mystery & Detective / Historical',
  FIC022070: 'Fiction/ Mystery & Detective / Cozy / General',
  FIC022080: 'FICTION / Mystery & Detective / International Crime & Mystery',
  FIC022090: 'Fiction / Mystery & Detective / Private Investigators',
  FIC022100: 'Fiction / Mystery & Detective / Amateur Sleuth',
  FIC023000: 'Fiction/ Mythology',
  FIC024000: 'Fiction / Occult & Supernatural',
  FIC025000: 'Fiction / Psychological',
  FIC026000: 'FICTION / Religious',
  FIC027000: 'Fiction / Romance / General',
  FIC027010: 'Fiction / Romance / Erotica',
  FIC027020: 'Fiction / Romance / Contemporary',
  FIC027030: 'Fiction / Romance / Fantasy',
  FIC027040: 'Fiction / Gothic',
  FIC027050: 'Fiction / Romance / Historical / General',
  FIC027060: 'Fiction/ Romance / Regional',
  FIC027070: 'Fiction / Romance / Historical / Regency',
  FIC027080: 'Fiction / Romance / Collections & Anthologies',
  FIC027090: 'Fiction / Romance / Time Travel',
  FIC027100: 'Fiction / Romance / Western',
  FIC027110: 'Fiction / Romance / Suspense',
  FIC027120: 'FICTION / Romance / Paranormal / General',
  FIC027130: 'Fiction / Romance / Science Fiction',
  FIC027140: 'Fiction / Romance / Historical / Ancient World',
  FIC027150: 'Fiction / Romance / Historical / Medieval',
  FIC027160: 'Fiction / Romance / Historical / Scottish',
  FIC027170: 'Fiction / Romance / Historical / Victorian',
  FIC027180: 'Fiction / Romance / Historical / Viking',
  FIC027190: 'Fiction / Romance / LGBTQ+ / Gay',
  FIC027200: 'Fiction / Romance / Historical / 20th Century',
  FIC027210: 'Fiction / Romance / LGBTQ+ / Lesbian',
  FIC027220: 'Fiction / Romance / Military',
  FIC027230: 'Fiction / Romance / Multicultural & Interracial',
  FIC027240: 'Fiction / Romance / New Adult',
  FIC027250: 'Fiction / Romance / Romantic Comedy',
  FIC027260: 'Fiction / Romance / Action & Adventure',
  FIC027270: 'Fiction / Romance / Clean & Wholesome',
  FIC027280: 'Fiction / Romance / Historical / Tudor ',
  FIC027290: 'Fiction / Romance / Holiday ',
  FIC027300: 'Fiction / Romance / LGBTQ+ / General',
  FIC027310: 'Fiction / Romance / Paranormal / Shifters ',
  FIC027320: 'Fiction / Romance / Paranormal / Vampires ',
  FIC027330: 'Fiction / Romance / Sports ',
  FIC028000: 'Fiction / Science Fiction / General',
  FIC028010: 'Fiction / Science Fiction / Action & Adventure',
  FIC028020: 'Fiction / Science Fiction / Hard Science Fiction',
  FIC028030: 'Fiction / Science Fiction / Space Opera',
  FIC028040: 'Fiction / Science Fiction / Collections & Anthologies',
  FIC028050: 'Fiction / Science Fiction / Military',
  FIC028060: 'Fiction / Science Fiction / Steampunk',
  FIC028070: 'Fiction / Science Fiction / Apocalyptic & Post-Apocalyptic',
  FIC028080: 'Fiction / Science Fiction / Time Travel',
  FIC028090: 'Fiction / Science Fiction / Alien Contact',
  FIC028100: 'Fiction / Science Fiction / Cyberpunk',
  FIC028110: 'Fiction / Science Fiction / Genetic Engineering',
  FIC029000: 'Fiction / Short Stories (single author)',
  FIC030000: 'Fiction / Thrillers / Suspense',
  FIC031000: 'Fiction / Thrillers / General',
  FIC031010: 'Fiction / Thrillers / Crime',
  FIC031020: 'Fiction / Thrillers / Historical',
  FIC031030: 'Fiction / Thrillers / Legal',
  FIC031040: 'Fiction / Thrillers / Medical',
  FIC031050: 'Fiction / Thrillers / Military',
  FIC031060: 'Fiction / Thrillers / Political',
  FIC031070: 'Fiction / Thrillers / Supernatural',
  FIC031080: 'Fiction / Thrillers / Psychological',
  FIC032000: 'Fiction / War & Military',
  FIC033000: 'Fiction / Westerns',
  FIC034000: 'Fiction / Legal',
  FIC035000: 'Fiction / Medical',
  FIC036000: 'Fiction / Thrillers / Technological',
  FIC037000: 'Fiction / Political',
  FIC038000: 'Fiction / Sports',
  FIC039000: 'Fiction / Visionary & Metaphysical',
  FIC040000: 'Fiction / Alternative History',
  FIC041000: 'Fiction / Biographical',
  FIC042000: 'Fiction / Christian / General',
  FIC042010: 'Fiction / Christian / Classic & Allegory',
  FIC042020: 'Fiction / Christian / Futuristic',
  FIC042030: 'Fiction / Christian / Historical',
  FIC042040: 'Fiction/ Christian / Romance / General',
  FIC042050: 'Fiction / Christian / Collections & Anthologies',
  FIC042060: 'Fiction / Christian / Suspense',
  FIC042070: 'Fiction / Christian / Western',
  FIC042080: 'Fiction / Christian / Fantasy',
  FIC043000: 'Fiction / Coming of Age',
  FIC044000: 'Fiction / Contemporary Women',
  FIC045000: 'Fiction/ Family Life / General',
  FIC046000: 'Fiction / Jewish',
  FIC047000: 'Fiction / Sea Stories',
  FIC048000: 'Fiction/ Urban & Street Lit',
  FIC049000: 'Fiction/ African American & Black / General',
  FIC049010: 'Fiction/ African American & Black / Christian',
  FIC049020: 'Fiction/ African American & Black / Women',
  FIC049030: 'Fiction/ African American & Black / Erotica',
  FIC049040: 'Fiction/ African American & Black / Historical',
  FIC049050: 'Fiction/ African American & Black / Mystery & Detective',
  FIC049060: 'Fiction/ Romance / African American & Black',
  FIC049070: 'Fiction/ African American & Black / Urban & Street Lit',
  FIC050000: 'Fiction / Crime',
  FIC051000: 'Fiction / Cultural Heritage',
  FIC052000: 'Fiction / Satire',
  FIC053000: 'Fiction / Amish & Mennonite',
  FIC054000: 'Fiction / Asian American',
  FIC055000: 'Fiction / Dystopian',
  FIC056000: 'Fiction / Hispanic & Latino',
  FIC057000: 'Fiction / Mashups',
  FIC058000: 'Fiction / Holidays',
  FIC059000: 'Fiction/ Indigenous',
  FIC060000: 'Fiction / Humorous / Black Humor',
  FIC061000: 'Fiction / Magical Realism',
  FIC062000: 'Fiction / Noir',
  FIC063000: 'FICTION / Superheroes',
  FIC064000: 'Fiction / Absurdist ',
  FIC065000: 'Fiction / Epistolary ',
  FIC066000: 'Fiction / Small Town & Rural ',
  FOR000000: 'Foreign Language Study / General',
  FOR001000: 'FOREIGN LANGUAGE STUDY / African Languages',
  FOR002000: 'Foreign Language Study / Arabic',
  FOR003000: 'Foreign Language Study / Chinese',
  FOR004000: 'Foreign Language Study / Danish',
  FOR005000: 'Foreign Language Study / Multi-Language Dictionaries',
  FOR006000: 'Foreign Language Study / Dutch',
  FOR007000: 'Foreign Language Study / English as a Second Language',
  FOR008000: 'Foreign Language Study / French',
  FOR009000: 'Foreign Language Study / German',
  FOR010000: 'FOREIGN LANGUAGE STUDY / Greek (Modern)',
  FOR011000: 'Foreign Language Study / Hebrew',
  FOR012000: 'Foreign Language Study / Hungarian',
  FOR013000: 'Foreign Language Study / Italian',
  FOR014000: 'Foreign Language Study / Japanese',
  FOR015000: 'Foreign Language Study / Korean',
  FOR016000: 'Foreign Language Study / Latin',
  FOR017000: 'Foreign Language Study / Miscellaneous',
  FOR018000: 'Foreign Language Study / Multi-Language Phrasebooks',
  FOR019000: 'Foreign Language Study / Polish',
  FOR020000: 'Foreign Language Study / Portuguese',
  FOR021000: 'Foreign Language Study / Russian',
  FOR022000: 'FOREIGN LANGUAGE STUDY / Scandinavian Languages (Other)',
  FOR023000: 'Foreign Language Study / Serbian & Croatian',
  FOR024000: 'FOREIGN LANGUAGE STUDY / Slavic Languages (Other)',
  FOR025000: 'FOREIGN LANGUAGE STUDY / Southeast Asian Languages',
  FOR026000: 'Foreign Language Study / Spanish',
  FOR027000: 'Foreign Language Study / Turkish & Turkic Languages',
  FOR028000: 'Foreign Language Study / Yiddish',
  FOR029000: 'Foreign Language Study / Celtic Languages',
  FOR030000: 'Foreign Language Study / Indic Languages',
  FOR031000: 'Foreign Language Study/ Indigenous Languages of the Americas',
  FOR032000: 'Foreign Language Study / Oceanic & Australian Languages',
  FOR033000: 'Foreign Language Study / Ancient Languages ',
  FOR034000: 'Foreign Language Study / Baltic Languages',
  FOR035000: 'Foreign Language Study / Creole Languages',
  FOR036000: 'Foreign Language Study / Czech',
  FOR037000: 'Foreign Language Study / Finnish',
  FOR038000: 'Foreign Language Study / Hindi',
  FOR039000: 'Foreign Language Study / Norwegian',
  FOR040000: 'Foreign Language Study / Persian',
  FOR041000: 'Foreign Language Study / Romance Languages (Other)',
  FOR042000: 'Foreign Language Study / Swahili',
  FOR043000: 'Foreign Language Study / Swedish',
  FOR044000: 'Foreign Language Study / Vietnamese',
  FOR045000: 'Foreign Language Study / Old & Middle English',
  GAM000000: 'Games & Activities / General',
  GAM001000: 'Games & Activities / Board Games',
  GAM001010: 'Games & Activities / Backgammon',
  GAM001020: 'Games / Checkers',
  GAM001030: 'Games & Activities / Chess',
  GAM002000: 'Games & Activities / Card Games / General',
  GAM002010: 'Games & Activities / Card Games / Bridge',
  GAM002020: 'Games / Card Games / Solitaire',
  GAM002030: 'Games & Activities / Card Games / Blackjack',
  GAM002040: 'Games & Activities / Card Games / Poker',
  GAM003000: 'Games & Activities / Crosswords / General',
  GAM003010: 'Games/ Crosswords / Crostic',
  GAM003020: 'Games/ Crosswords / Cryptic',
  GAM003030: 'Games/ Crosswords / Diagramless',
  GAM003040: 'Games & Activities / Crosswords / Dictionaries',
  GAM004000: 'Games & Activities / Gambling / General ',
  GAM004010: 'Games/ Gambling / Card Games',
  GAM004020: 'Games & Activities / Gambling / Lotteries',
  GAM004030: 'Games & Activities / Gambling / Table',
  GAM004040: 'Games & Activities / Gambling / Track Betting',
  GAM004050: 'Games & Activities / Gambling / Sports',
  GAM005000: 'Games & Activities / Logic & Brain Teasers',
  GAM006000: 'Games & Activities / Magic',
  GAM007000: 'Games & Activities / Puzzles',
  GAM008000: 'Games & Activities / Quizzes',
  GAM009000: 'Games & Activities / Reference',
  GAM010000: 'Games & Activities / Role Playing & Fantasy',
  GAM011000: 'Games & Activities / Travel Games',
  GAM012000: 'Games & Activities / Trivia',
  GAM013000: 'Games & Activities / Video & Electronic',
  GAM014000: 'Games & Activities / Word & Word Search',
  GAM015000: 'Games/ Word Search',
  GAM016000: 'Games & Activities / Fantasy Sports',
  GAM017000: 'Games & Activities / Sudoku',
  GAM018000: 'Games & Activities / Optical Illusions',
  GAM019000: 'Games & Activities/ Coloring Books',
  GAR000000: 'Gardening / General',
  GAR001000: 'Gardening / Container',
  GAR002000: 'Gardening / Essays & Narratives',
  GAR003000: 'Gardening/ Flower Arranging',
  GAR004000: 'Gardening / Flowers / General',
  GAR004010: 'Gardening / Flowers / Annuals',
  GAR004020: 'Gardening/ Flowers / Azaleas',
  GAR004030: 'Gardening / Flowers / Bulbs',
  GAR004040: 'Gardening / Flowers / Orchids',
  GAR004050: 'Gardening / Flowers / Perennials',
  GAR004060: 'Gardening / Flowers / Roses',
  GAR004070: 'Gardening/ Flowers / Violets',
  GAR004080: 'Gardening / Flowers / Wildflowers',
  GAR005000: 'Gardening / Fruit',
  GAR006000: 'Gardening / Garden Design',
  GAR007000: 'Gardening / Garden Furnishings',
  GAR008000: 'Gardening / Greenhouses',
  GAR009000: 'Gardening / Herbs',
  GAR010000: 'Gardening / House Plants & Indoor',
  GAR011000: 'Gardening / Hydroponics',
  GAR012000: 'Gardening/ Indoor',
  GAR013000: 'Gardening / Japanese Gardens',
  GAR014000: 'Gardening / Landscape',
  GAR015000: 'Gardening / Lawns',
  GAR016000: 'Gardening / Organic',
  GAR017000: 'Gardening / Ornamental Plants',
  GAR018000: 'Gardening / Reference',
  GAR019000: 'Gardening / Regional / General',
  GAR019010: 'Gardening / Regional / Canada',
  GAR019020: 'Gardening / Regional / Middle Atlantic (DC, DE, MD, NJ, NY, PA)',
  GAR019030: 'Gardening / Regional / Midwest (IA, IL, IN, KS, MI, MN, MO, ND, NE, OH, SD, WI)',
  GAR019040: 'Gardening / Regional / New England (CT, MA, ME, NH, RI, VT)',
  GAR019050: 'Gardening / Regional / Pacific Northwest (OR, WA)',
  GAR019060: 'Gardening / Regional / South (AL, AR, FL, GA, KY, LA, MS, NC, SC, TN, VA, WV)',
  GAR019070: 'Gardening / Regional / Southwest (AZ, NM, OK, TX)',
  GAR019080: 'Gardening / Regional / West (AK, CA, CO, HI, ID, MT, NV, UT, WY)',
  GAR020000: 'Gardening / Shade',
  GAR021000: 'Gardening / Shrubs',
  GAR022000: 'Gardening / Techniques',
  GAR023000: 'Gardening / Topiary',
  GAR024000: 'Gardening / Trees',
  GAR025000: 'Gardening / Vegetables',
  GAR026000: 'Gardening/ Xeriscaping',
  GAR027000: 'Gardening / Climatic / General',
  GAR027010: 'Gardening / Climatic / Desert',
  GAR027020: 'Gardening / Climatic / Temperate',
  GAR027030: 'Gardening / Climatic / Tropical',
  GAR028000: 'Gardening / Urban',
  GAR029000: 'Gardening / Water Gardens',
  GAR030000: 'Gardening / Pictorial ',
  HEA000000: 'Health & Fitness / General',
  HEA001000: 'Health & Fitness / Acupressure & Acupuncture ',
  HEA002000: 'HEALTH & FITNESS / Exercise / Aerobics',
  HEA003000: 'Health & Fitness / Beauty & Grooming',
  HEA004000: 'Health & Fitness/ Calorie-Content Guides',
  HEA005000: 'Health & Fitness/ Cholesterol-Content Guides',
  HEA006000: 'Health & Fitness / Diet & Nutrition / Diets',
  HEA007000: 'HEALTH & FITNESS / Exercise / General',
  HEA008000: 'Health & Fitness/ Fat-Content Guides',
  HEA009000: 'Health & Fitness / Healing',
  HEA010000: 'Health & Fitness / Healthy Living',
  HEA011000: 'Health & Fitness / Herbal Medications',
  HEA012000: 'Health & Fitness / Holism',
  HEA013000: 'Health & Fitness / Diet & Nutrition / Macrobiotics',
  HEA014000: 'Health & Fitness / Massage & Reflexotherapy',
  HEA015000: 'Health & Fitness / Men\u2019s Health',
  HEA016000: 'Health & Fitness / Naturopathy',
  HEA017000: 'Health & Fitness / Diet & Nutrition / Nutrition',
  HEA018000: 'Health & Fitness / Physical Impairments',
  HEA019000: 'Health & Fitness / Diet & Nutrition / Weight Loss',
  HEA020000: 'Health & Fitness / Reference',
  HEA021000: 'Health & Fitness / Safety',
  HEA022000: 'Health & Fitness/ Exercise / Stretching',
  HEA023000: 'Health & Fitness / Diet & Nutrition / Vitamins',
  HEA024000: 'Health & Fitness / Women\u2019s Health',
  HEA025000: 'Health & Fitness / Yoga',
  HEA026000: 'Health & Fitness / Naprapathy',
  HEA027000: 'Health & Fitness / Allergies',
  HEA028000: 'Health & Fitness / Health Care Issues',
  HEA029000: 'Health & Fitness / Aromatherapy',
  HEA030000: 'Health & Fitness / Homeopathy',
  HEA031000: 'Health & Fitness/ Hygiene',
  HEA032000: 'Health & Fitness / Alternative Therapies',
  HEA033000: 'Health & Fitness / First Aid',
  HEA034000: 'Health & Fitness / Diet & Nutrition / Food Content Guides',
  HEA035000: 'Health & Fitness / Hearing & Speech',
  HEA036000: 'Health & Fitness / Pain Management',
  HEA037000: 'Health & Fitness / Vision',
  HEA038000: 'Health & Fitness / Work-Related Health',
  HEA039000: 'Health & Fitness / Diseases / General',
  HEA039010: 'Health & Fitness / Diseases / Gastrointestinal',
  HEA039020: 'Health & Fitness / Diseases / AIDS & HIV',
  HEA039030: 'Health & Fitness / Diseases / Cancer',
  HEA039040: 'Health & Fitness/ Diseases / Contagious (incl. Pandemics)',
  HEA039050: 'Health & Fitness / Diseases / Diabetes',
  HEA039060: 'Health & Fitness / Diseases / Genetic',
  HEA039070: 'Health & Fitness / Diseases / Genitourinary & STDs',
  HEA039080: 'Health & Fitness / Diseases / Heart',
  HEA039090: 'Health & Fitness / Diseases / Immune & Autoimmune',
  HEA039100: 'Health & Fitness / Diseases / Musculoskeletal',
  HEA039110: 'Health & Fitness / Diseases / Nervous System (incl. Brain)',
  HEA039120: 'Health & Fitness / Diseases / Respiratory',
  HEA039130: 'Health & Fitness / Diseases / Skin',
  HEA039140: 'Health & Fitness / Diseases / Alzheimer\u2019s & Dementia',
  HEA039150: 'Health & Fitness / Diseases / Chronic Fatigue Syndrome',
  HEA039160: 'Health & Fitness / Diseases / Endocrine System',
  HEA040000: 'Health & Fitness / Oral Health',
  HEA041000: 'Health & Fitness / Pregnancy & Childbirth',
  HEA042000: 'Health & Fitness / Sexuality',
  HEA043000: 'HEALTH & FITNESS / Sleep',
  HEA044000: 'Health & Fitness / Breastfeeding',
  HEA045000: 'HEALTH & FITNESS / Fertility & Infertility',
  HEA046000: 'Health & Fitness / Children\u2019s Health',
  HEA047000: 'Health & Fitness / Body Cleansing & Detoxification',
  HEA048000: 'Health & Fitness / Diet & Nutrition / General',
  HEA049000: 'Health & Fitness / Longevity',
  HEA050000: 'Health & Fitness / Vaccinations ',
  HIS000000: 'History / General',
  HIS001000: 'History / Africa / General',
  HIS001010: 'History / Africa / Central',
  HIS001020: 'History / Africa / East',
  HIS001030: 'History / Africa / North',
  HIS001040: 'History / Africa / South / General',
  HIS001050: 'History / Africa / West',
  HIS002000: 'History / Ancient / General',
  HIS002010: 'History / Ancient / Greece',
  HIS002020: 'History / Ancient / Rome',
  HIS002030: 'History / Ancient / Egypt',
  HIS003000: 'History / Asia / General',
  HIS004000: 'HISTORY / Australia & New Zealand',
  HIS005000: 'History / Europe / Baltic States',
  HIS006000: 'History / Canada / General',
  HIS006010: 'History / Canada / Pre-Confederation (to 1867)',
  HIS006020: 'History / Canada / Post-Confederation (1867-)',
  HIS007000: 'History / Latin America / Central America',
  HIS008000: 'History / Asia / China',
  HIS009000: 'History / Middle East / Egypt ',
  HIS010000: 'History / Europe / General',
  HIS010010: 'History / Europe / Eastern',
  HIS010020: 'History / Europe / Western',
  HIS011000: 'History/ Far East',
  HIS012000: 'History / Europe / Former Soviet Republics',
  HIS013000: 'History / Europe / France',
  HIS014000: 'History / Europe / Germany',
  HIS015000: 'HISTORY / Europe / Great Britain / General',
  HIS016000: 'History / Historiography',
  HIS017000: 'History / Asia / India & South Asia',
  HIS018000: 'History / Europe / Ireland',
  HIS019000: 'History / Middle East / Israel & Palestine',
  HIS020000: 'History / Europe / Italy',
  HIS021000: 'History / Asia / Japan',
  HIS022000: 'History / Jewish',
  HIS023000: 'History / Asia / Korea',
  HIS024000: 'History / Latin America / General',
  HIS025000: 'History / Latin America / Mexico',
  HIS026000: 'History / Middle East / General',
  HIS026010: 'History / Middle East / Arabian Peninsula',
  HIS026020: 'History / Middle East / Iran',
  HIS026030: 'History / Middle East / Iraq',
  HIS027000: 'History / Military / General',
  HIS027010: 'History / Military / Biological & Chemical Warfare',
  HIS027020: 'History / Military / Korean War',
  HIS027030: 'History / Military / Nuclear Warfare',
  HIS027040: 'HISTORY / Military / Persian Gulf War (1991)',
  HIS027050: 'History / Military / Pictorial',
  HIS027060: 'History / Military / Strategy',
  HIS027070: 'History / Military / Vietnam War',
  HIS027080: 'History / Military / Weapons',
  HIS027090: 'History / Military / World War I',
  HIS027100: 'History / Military / World War II',
  HIS027110: 'History / Military / United States',
  HIS027120: 'History / Military / Veterans',
  HIS027130: 'History / Military / Wars & Conflicts (Other)',
  HIS027140: 'History / Military / Aviation',
  HIS027150: 'History / Military / Naval',
  HIS027160: 'History / Military / Canada',
  HIS027170: 'History / Military / Iraq War (2003-2011)',
  HIS027180: 'History / Military / Special Forces',
  HIS027190: 'History / Military / Afghan War (2001-)',
  HIS027200: 'History / Military / Napoleonic Wars',
  HIS027210: 'History / Military / War of 1812',
  HIS027220: 'History / Military / Ancient ',
  HIS027230: 'History / Military / Medieval ',
  HIS027240: 'History / Military / Vehicles ',
  HIS028000: 'History/ Indigenous Peoples of the Americas',
  HIS029000: 'History / North America',
  HIS030000: 'History / Reference',
  HIS031000: 'History / Revolutionary',
  HIS032000: 'History / Russia & the Former Soviet Union',
  HIS033000: 'History / Latin America / South America',
  HIS034000: 'History/ Soviet Union',
  HIS035000: 'History / Study & Teaching',
  HIS036000: 'History / United States / General',
  HIS036010: 'History / United States / State & Local / General',
  HIS036020: 'History / United States / Colonial Period (1600-1775)',
  HIS036030: 'History / United States / Revolutionary Period (1775-1800)',
  HIS036040: 'History / United States / 19th Century',
  HIS036050: 'History / United States / Civil War Period (1850-1877)',
  HIS036060: 'History / United States / 20th Century',
  HIS036070: 'History / United States / 21st Century',
  HIS036080: 'History / United States / State & Local / Middle Atlantic (DC, DE, MD, NJ, NY, PA)',
  HIS036090: 'History / United States / State & Local / Midwest (IA, IL, IN, KS, MI, MN, MO, ND, NE, OH, SD, WI)',
  HIS036100: 'History / United States / State & Local / New England (CT, MA, ME, NH, RI, VT)',
  HIS036110: 'History / United States / State & Local / Pacific Northwest (OR, WA)',
  HIS036120: 'History / United States / State & Local / South (AL, AR, FL, GA, KY, LA, MS, NC, SC, TN, VA, WV)',
  HIS036130: 'History / United States / State & Local / Southwest (AZ, NM, OK, TX)',
  HIS036140: 'History / United States / State & Local / West (AK, CA, CO, HI, ID, MT, NV, UT, WY)',
  HIS037000: 'History / World',
  HIS037010: 'HISTORY / Europe / Medieval',
  HIS037020: 'HISTORY / Europe / Renaissance',
  HIS037030: 'History / Modern / General',
  HIS037040: 'History / Modern / 17th Century',
  HIS037050: 'History / Modern / 18th Century',
  HIS037060: 'History / Modern / 19th Century',
  HIS037070: 'History / Modern / 20th Century',
  HIS037080: 'History / Modern / 21st Century',
  HIS037090: 'History / Modern / 16th Century',
  HIS038000: 'History / Americas (North, Central, South, West Indies)',
  HIS039000: 'History / Civilization',
  HIS040000: 'History / Europe / Austria & Hungary',
  HIS041000: 'History / Caribbean & West Indies / General',
  HIS041010: 'History / Caribbean & West Indies / Cuba',
  HIS042000: 'History / Europe / Greece ',
  HIS043000: 'History / Holocaust',
  HIS044000: 'History / Europe / Scandinavia',
  HIS045000: 'History / Europe / Spain & Portugal',
  HIS046000: 'History / Polar Regions',
  HIS047000: 'History / Africa / South / Republic of South Africa',
  HIS048000: 'History / Asia / Southeast Asia',
  HIS049000: 'History / Essays',
  HIS050000: 'History / Asia / Central Asia',
  HIS051000: 'History / Expeditions & Discoveries',
  HIS052000: 'History / Historical Geography',
  HIS053000: 'History / Oceania',
  HIS054000: 'History / Social History',
  HIS055000: 'History / Middle East / Turkey & Ottoman Empire',
  HIS056000: 'History/ African American & Black',
  HIS057000: 'History / Maritime History & Piracy',
  HIS058000: 'History / Women',
  HOM000000: 'House & Home / General',
  HOM001000: 'House & Home/ Do-It-Yourself / Carpentry',
  HOM002000: 'House & Home/ Contracting',
  HOM003000: 'HOUSE & HOME / Decorating & Furnishings',
  HOM004000: 'House & Home / Design & Construction',
  HOM005000: 'House & Home / Do-It-Yourself / General',
  HOM006000: 'House & Home/ Do-It-Yourself / Electrical',
  HOM007000: 'House & Home/ Estimating',
  HOM008000: 'House & Home / Furniture',
  HOM009000: 'House & Home / Hand Tools',
  HOM010000: 'HOUSE & HOME / Maintenance & Repair',
  HOM011000: 'House & Home / House Plans',
  HOM012000: 'House & Home/ Do-It-Yourself / Masonry',
  HOM013000: 'House & Home / Outdoor & Recreational Areas',
  HOM014000: 'House & Home/ Do-It-Yourself / Plumbing',
  HOM015000: 'House & Home / Power Tools',
  HOM016000: 'House & Home / Reference',
  HOM017000: 'House & Home / Remodeling & Renovation',
  HOM018000: 'House & Home / Woodworking',
  HOM019000: 'House & Home / Cleaning, Caretaking & Organizing',
  HOM020000: 'House & Home / Equipment, Appliances & Supplies',
  HOM021000: 'House & Home / Security',
  HOM022000: 'House & Home / Sustainable Living',
  HOM023000: 'House & Home / Small Spaces ',
  HUM000000: 'Humor / General',
  HUM001000: 'Humor / Form / Comic Strips & Cartoons',
  HUM002000: 'Humor/ Comic Books, Strips, etc.',
  HUM003000: 'Humor / Form / Essays',
  HUM004000: 'Humor / Form / Jokes & Riddles',
  HUM005000: 'Humor / Form / Limericks & Verse',
  HUM006000: 'Humor / Topic / Politics',
  HUM007000: 'Humor / Form / Parodies',
  HUM008000: 'Humor / Topic / Adult',
  HUM009000: 'Humor / Topic / Animals',
  HUM010000: 'Humor / Topic / Business & Professional',
  HUM011000: 'Humor / Topic / Marriage & Family',
  HUM012000: 'Humor / Topic / Men, Women & Relationships',
  HUM013000: 'Humor / Topic / Sports',
  HUM014000: 'Humor / Topic / Religion',
  HUM015000: 'Humor / Form / Anecdotes & Quotations',
  HUM016000: 'Humor / Form / Trivia',
  HUM017000: 'Humor / Form / Pictorial',
  HUM018000: 'Humor / Form / Puns & Wordplay',
  HUM019000: 'Humor / Topic / Language',
  JNF000000: 'Juvenile Nonfiction / General',
  JNF001000: 'JUVENILE NONFICTION / Activity Books / General',
  JNF002000: 'Juvenile Nonfiction / Adventure & Adventurers',
  JNF003000: 'Juvenile Nonfiction / Animals / General',
  JNF003010: 'Juvenile Nonfiction / Animals / Apes, Monkeys, etc.',
  JNF003020: 'Juvenile Nonfiction / Animals / Bears',
  JNF003030: 'Juvenile Nonfiction / Animals / Birds',
  JNF003040: 'Juvenile Nonfiction / Animals / Cats',
  JNF003050: 'Juvenile Nonfiction / Animals / Dinosaurs & Prehistoric Creatures',
  JNF003060: 'Juvenile Nonfiction / Animals / Dogs',
  JNF003070: 'Juvenile Nonfiction / Animals / Elephants',
  JNF003080: 'Juvenile Nonfiction / Animals / Farm Animals',
  JNF003090: 'JUVENILE NONFICTION / Animals / Fish',
  JNF003100: 'JUVENILE NONFICTION / Animals / Foxes',
  JNF003110: 'Juvenile Nonfiction / Animals / Horses',
  JNF003120: 'Juvenile Nonfiction / Animals / Insects, Spiders, etc.',
  JNF003130: 'Juvenile Nonfiction / Animals / Lions, Tigers, Leopards, etc.',
  JNF003140: 'Juvenile Nonfiction / Animals / Mammals',
  JNF003150: 'Juvenile Nonfiction / Animals / Marine Life',
  JNF003160: 'Juvenile Nonfiction / Animals / Mice, Hamsters, Guinea Pigs, Squirrels, etc.',
  JNF003170: 'Juvenile Nonfiction / Animals / Pets',
  JNF003180: 'Juvenile Nonfiction / Animals / Rabbits',
  JNF003190: 'Juvenile Nonfiction / Animals / Reptiles & Amphibians',
  JNF003200: 'Juvenile Nonfiction / Animals / Zoos',
  JNF003210: 'Juvenile Nonfiction / Animals / Ducks, Geese, etc.',
  JNF003220: 'Juvenile Nonfiction / Animals / Animal Welfare',
  JNF003230: 'Juvenile Nonfiction / Animals / Deer, Moose & Caribou',
  JNF003240: 'JUVENILE NONFICTION / Animals / Wolves, Coyotes & Wild Dogs',
  JNF003250: 'Juvenile Nonfiction / Animals / Butterflies, Moths & Caterpillars',
  JNF003260: 'Juvenile Nonfiction / Animals / Cows',
  JNF003270: 'Juvenile Nonfiction / Animals / Endangered',
  JNF003280: 'Juvenile Nonfiction / Animals / Giraffes',
  JNF003290: 'Juvenile Nonfiction / Animals / Hippos & Rhinos',
  JNF003300: 'Juvenile Nonfiction / Animals / Jungle Animals',
  JNF003310: 'Juvenile Nonfiction / Animals / Kangaroos',
  JNF003320: 'Juvenile Nonfiction / Animals / Nocturnal',
  JNF003330: 'Juvenile Nonfiction / Animals / Baby Animals',
  JNF004000: 'Juvenile Nonfiction / Antiques & Collectibles',
  JNF005000: 'Juvenile Nonfiction / Architecture',
  JNF006000: 'Juvenile Nonfiction / Art / General',
  JNF006010: 'Juvenile Nonfiction / Art / Cartooning',
  JNF006020: 'Juvenile Nonfiction / Art / Drawing',
  JNF006030: 'Juvenile Nonfiction / Art / Fashion',
  JNF006040: 'Juvenile Nonfiction / Art / History',
  JNF006050: 'Juvenile Nonfiction / Art / Painting',
  JNF006060: 'Juvenile Nonfiction / Art / Sculpture',
  JNF006070: 'Juvenile Nonfiction / Art / Techniques',
  JNF007000: 'Juvenile Nonfiction / Biography & Autobiography / General',
  JNF007010: 'Juvenile Nonfiction / Biography & Autobiography / Art',
  JNF007020: 'Juvenile Nonfiction / Biography & Autobiography / Historical',
  JNF007030: 'Juvenile Nonfiction / Biography & Autobiography / Literary',
  JNF007040: 'Juvenile Nonfiction / Biography & Autobiography / Music',
  JNF007050: 'JUVENILE NONFICTION / Biography & Autobiography / Cultural, Ethnic & Regional',
  JNF007060: 'Juvenile Nonfiction / Biography & Autobiography / Performing Arts',
  JNF007070: 'Juvenile Nonfiction / Biography & Autobiography / Political',
  JNF007080: 'Juvenile Nonfiction / Biography & Autobiography / Religious ',
  JNF007090: 'Juvenile Nonfiction / Biography & Autobiography / Science & Technology',
  JNF007100: 'Juvenile Nonfiction / Biography & Autobiography / Sports & Recreation',
  JNF007110: 'Juvenile Nonfiction / Biography & Autobiography / Social Activists',
  JNF007120: 'Juvenile Nonfiction / Biography & Autobiography / Women',
  JNF007130: 'Juvenile Nonfiction / Biography & Autobiography / Presidents & First Families (U.S.)',
  JNF007140: 'Juvenile Nonfiction / Biography & Autobiography / Royalty',
  JNF008000: 'Juvenile Nonfiction / Paranormal & Supernatural',
  JNF009000: 'Juvenile Nonfiction / Boys & Men',
  JNF010000: 'Juvenile Nonfiction / Business & Economics',
  JNF011000: 'Juvenile Nonfiction / Careers',
  JNF012000: 'Juvenile Nonfiction / Computers / General',
  JNF012010: 'Juvenile Nonfiction / Computers / Entertainment & Games',
  JNF012020: 'Juvenile Nonfiction / Computers / Hardware',
  JNF012030: 'Juvenile Nonfiction / Computers / Internet',
  JNF012040: 'JUVENILE NONFICTION / Computers / Coding & Programming',
  JNF012050: 'Juvenile Nonfiction / Computers / Software',
  JNF013000: 'Juvenile Nonfiction / Concepts / General',
  JNF013010: 'Juvenile Nonfiction / Concepts / Alphabet',
  JNF013020: 'Juvenile Nonfiction / Concepts / Colors',
  JNF013030: 'Juvenile Nonfiction / Concepts / Counting & Numbers',
  JNF013040: 'Juvenile Nonfiction / Concepts / Money',
  JNF013050: 'Juvenile Nonfiction / Concepts / Opposites',
  JNF013060: 'Juvenile Nonfiction / Concepts / Senses & Sensation',
  JNF013070: 'Juvenile Nonfiction / Concepts / Size & Shape',
  JNF013080: 'Juvenile Nonfiction / Concepts / Date & Time',
  JNF013090: 'Juvenile Nonfiction / Concepts / Seasons',
  JNF013100: 'Juvenile Nonfiction / Concepts / Sounds',
  JNF013110: 'Juvenile Nonfiction / Concepts / Body',
  JNF014000: 'Juvenile Nonfiction / Cooking & Food',
  JNF015000: 'Juvenile Nonfiction / Crafts & Hobbies',
  JNF016000: 'Juvenile Nonfiction / Curiosities & Wonders',
  JNF017000: 'Juvenile Nonfiction / Drama',
  JNF018000: 'Juvenile Nonfiction/ Ethnic / General',
  JNF018010: 'Juvenile Nonfiction/ People & Places / United States / African American & Black',
  JNF018020: 'Juvenile Nonfiction / People & Places / United States / Asian American',
  JNF018030: 'Juvenile Nonfiction / People & Places / United States / Hispanic & Latino',
  JNF018040: 'Juvenile Nonfiction / People & Places / United States / Native American',
  JNF018050: 'Juvenile Nonfiction / People & Places / United States / Other',
  JNF019000: 'Juvenile Nonfiction / Family / General ',
  JNF019010: 'Juvenile Nonfiction / Family / Adoption',
  JNF019020: 'Juvenile Nonfiction / Family / Marriage & Divorce',
  JNF019030: 'Juvenile Nonfiction / Family / Multigenerational',
  JNF019040: 'Juvenile Nonfiction / Family / New Baby',
  JNF019050: 'Juvenile Nonfiction / Family / Orphans & Foster Homes',
  JNF019060: 'Juvenile Nonfiction / Family / Parents',
  JNF019070: 'Juvenile Nonfiction / Family / Siblings',
  JNF019080: 'Juvenile Nonfiction / Family / Blended Families',
  JNF019090: 'Juvenile Nonfiction / Family / Alternative Family',
  JNF020000: 'Juvenile Nonfiction / Foreign Language Study / General',
  JNF020010: 'Juvenile Nonfiction / Foreign Language Study / English as a Second Language',
  JNF020020: 'Juvenile Nonfiction / Foreign Language Study / French',
  JNF020030: 'Juvenile Nonfiction / Foreign Language Study / Spanish',
  JNF021000: 'Juvenile Nonfiction / Games & Activities / General',
  JNF021010: 'Juvenile Nonfiction / Games & Activities / Board Games',
  JNF021020: 'Juvenile Nonfiction / Games & Activities / Card Games',
  JNF021030: 'Juvenile Nonfiction / Games & Activities / Magic',
  JNF021040: 'Juvenile Nonfiction / Games & Activities / Puzzles',
  JNF021050: 'Juvenile Nonfiction / Games & Activities / Questions & Answers',
  JNF021060: 'Juvenile Nonfiction / Games & Activities / Video & Electronic Games',
  JNF021070: 'Juvenile Nonfiction / Games & Activities / Word Games',
  JNF022000: 'Juvenile Nonfiction / Gardening',
  JNF023000: 'Juvenile Nonfiction / Girls & Women',
  JNF024000: 'Juvenile Nonfiction / Health & Daily Living / General',
  JNF024010: 'Juvenile Nonfiction / Health & Daily Living / Diet & Nutrition',
  JNF024020: 'Juvenile Nonfiction / Health & Daily Living / Diseases, Illnesses & Injuries',
  JNF024030: 'Juvenile Nonfiction / Health & Daily Living / First Aid',
  JNF024040: 'Juvenile Nonfiction / Health & Daily Living / Fitness & Exercise',
  JNF024050: 'Juvenile Nonfiction / Health & Daily Living / Maturing',
  JNF024060: 'Juvenile Nonfiction / Health & Daily Living / Personal Hygiene',
  JNF024070: 'Juvenile Nonfiction / Health & Daily Living / Physical Impairments',
  JNF024080: 'Juvenile Nonfiction / Health & Daily Living / Safety',
  JNF024090: 'Juvenile Nonfiction / Health & Daily Living / Sexuality & Pregnancy',
  JNF024100: 'JUVENILE NONFICTION / Health & Daily Living / Drugs, Alcohol, Substance Abuse',
  JNF024110: 'Juvenile Nonfiction / Health & Daily Living / Toilet Training',
  JNF024120: 'Juvenile Nonfiction / Health & Daily Living / Daily Activities',
  JNF025000: 'Juvenile Nonfiction / History / General',
  JNF025010: 'Juvenile Nonfiction / History / Africa',
  JNF025020: 'Juvenile Nonfiction / History / Ancient',
  JNF025030: 'Juvenile Nonfiction / History / Asia',
  JNF025040: 'Juvenile Nonfiction / History / Australia & Oceania',
  JNF025050: 'Juvenile Nonfiction / History / Canada / General',
  JNF025060: 'Juvenile Nonfiction / History / Central & South America',
  JNF025070: 'Juvenile Nonfiction / History / Europe',
  JNF025080: 'Juvenile Nonfiction / History / Exploration & Discovery',
  JNF025090: 'Juvenile Nonfiction / History / Holocaust',
  JNF025100: 'Juvenile Nonfiction / History / Medieval',
  JNF025110: 'Juvenile Nonfiction / History / Mexico',
  JNF025120: 'Juvenile Nonfiction / History / Middle East',
  JNF025130: 'Juvenile Nonfiction / History / Military & Wars',
  JNF025140: 'Juvenile Nonfiction / History / Modern',
  JNF025150: 'Juvenile Nonfiction / History / Prehistoric',
  JNF025160: 'Juvenile Nonfiction / History / Renaissance',
  JNF025170: 'Juvenile Nonfiction / History / United States / General',
  JNF025180: 'Juvenile Nonfiction / History / United States / State & Local',
  JNF025190: 'Juvenile Nonfiction / History / United States / Colonial & Revolutionary Periods',
  JNF025200: 'Juvenile Nonfiction / History / United States / 19th Century',
  JNF025210: 'Juvenile Nonfiction / History / United States / 20th Century',
  JNF025220: 'Juvenile Nonfiction / History / Other',
  JNF025230: 'Juvenile Nonfiction / History / Canada / Pre-Confederation (to 1867)',
  JNF025240: 'Juvenile Nonfiction / History / Canada / Post-Confederation (1867-)',
  JNF025250: 'Juvenile Nonfiction / History / United States / 21st Century',
  JNF025260: 'Juvenile Nonfiction / History / Symbols, Monuments, National Parks, etc.',
  JNF025270: 'Juvenile Nonfiction / History / United States / Civil War Period (1850-1877)',
  JNF026000: 'Juvenile Nonfiction / Holidays & Celebrations / General ',
  JNF026010: 'Juvenile Nonfiction / Holidays & Celebrations / Christmas & Advent',
  JNF026020: 'Juvenile Nonfiction / Holidays & Celebrations / Easter & Lent',
  JNF026030: 'Juvenile Nonfiction / Holidays & Celebrations / Halloween',
  JNF026040: 'Juvenile Nonfiction/ Holidays & Festivals / Jewish',
  JNF026050: 'Juvenile Nonfiction / Holidays & Celebrations / Kwanzaa',
  JNF026060: 'Juvenile Nonfiction / Holidays & Celebrations / Thanksgiving',
  JNF026070: 'Juvenile Nonfiction / Holidays & Celebrations / Valentine\u2019s Day',
  JNF026080: 'Juvenile Nonfiction / Holidays & Celebrations / Other, Non-Religious',
  JNF026090: 'Juvenile Nonfiction / Holidays & Celebrations / Other, Religious',
  JNF026100: 'Juvenile Nonfiction / Holidays & Celebrations / Birthdays',
  JNF026110: 'Juvenile Nonfiction / Holidays & Celebrations / Hanukkah',
  JNF026120: 'Juvenile Nonfiction / Holidays & Celebrations / Passover',
  JNF026130: 'Juvenile Nonfiction / Holidays & Celebrations / Patriotic Holidays',
  JNF027000: 'Juvenile Nonfiction / House & Home',
  JNF028000: 'Juvenile Nonfiction / Humor / General',
  JNF028010: 'Juvenile Nonfiction / Humor / Comic Strips & Cartoons',
  JNF028020: 'Juvenile Nonfiction / Humor / Jokes & Riddles',
  JNF029000: 'Juvenile Nonfiction / Language Arts / General',
  JNF029010: 'Juvenile Nonfiction / Language Arts / Composition & Creative Writing',
  JNF029020: 'Juvenile Nonfiction / Language Arts / Grammar',
  JNF029030: 'Juvenile Nonfiction / Language Arts / Handwriting',
  JNF029040: 'Juvenile Nonfiction / Language Arts / Vocabulary & Spelling',
  JNF029050: 'Juvenile Nonfiction / Language Arts / Sign Language',
  JNF029060: 'Juvenile Nonfiction / Language Arts / Journal Writing',
  JNF030000: 'Juvenile Nonfiction / Law & Crime',
  JNF031000: 'Juvenile Nonfiction / Lifestyles / City & Town Life',
  JNF032000: 'Juvenile Nonfiction / Lifestyles / Country Life',
  JNF033000: 'Juvenile Nonfiction / Lifestyles / Farm & Ranch Life',
  JNF034000: 'Juvenile Nonfiction / Literary Criticism & Collections',
  JNF035000: 'Juvenile Nonfiction / Mathematics / General',
  JNF035010: 'Juvenile Nonfiction / Mathematics / Advanced',
  JNF035020: 'Juvenile Nonfiction / Mathematics / Algebra',
  JNF035030: 'Juvenile Nonfiction / Mathematics / Arithmetic',
  JNF035040: 'Juvenile Nonfiction / Mathematics / Fractions',
  JNF035050: 'Juvenile Nonfiction / Mathematics / Geometry',
  JNF036000: 'Juvenile Nonfiction / Music / General',
  JNF036010: 'Juvenile Nonfiction / Music / Classical',
  JNF036020: 'Juvenile Nonfiction / Music / History',
  JNF036030: 'Juvenile Nonfiction / Music / Instruction & Study',
  JNF036040: 'Juvenile Nonfiction / Music / Jazz',
  JNF036050: 'Juvenile Nonfiction / Music / Popular',
  JNF036060: 'Juvenile Nonfiction / Music / Rap & Hip Hop',
  JNF036070: 'Juvenile Nonfiction / Music / Rock',
  JNF036080: 'Juvenile Nonfiction / Music / Songbooks',
  JNF036090: 'Juvenile Nonfiction / Music / Instruments',
  JNF037000: 'Juvenile Nonfiction/ Nature / General ',
  JNF037010: 'Juvenile Nonfiction / Science & Nature / Earth Sciences / Earthquakes & Volcanoes',
  JNF037020: 'Juvenile Nonfiction / Science & Nature / Environmental Conservation & Protection',
  JNF037030: 'Juvenile Nonfiction / Science & Nature / Flowers & Plants',
  JNF037040: 'Juvenile Nonfiction / Science & Nature / Trees & Forests',
  JNF037050: 'Juvenile Nonfiction / Science & Nature / Fossils',
  JNF037060: 'Juvenile Nonfiction / Science & Nature / Earth Sciences / Rocks & Minerals',
  JNF037070: 'Juvenile Nonfiction / Science & Nature / Earth Sciences / Water (Oceans, Lakes, etc.)',
  JNF037080: 'Juvenile Nonfiction / Science & Nature / Earth Sciences / Weather',
  JNF038000: 'Juvenile Nonfiction / People & Places / General',
  JNF038010: 'Juvenile Nonfiction / People & Places / Africa',
  JNF038020: 'Juvenile Nonfiction / People & Places / Asia',
  JNF038030: 'Juvenile Nonfiction / People & Places / Australia & Oceania',
  JNF038040: 'Juvenile Nonfiction / People & Places / Canada / General',
  JNF038050: 'Juvenile Nonfiction / People & Places / Caribbean & Latin America',
  JNF038060: 'Juvenile Nonfiction / People & Places / Europe',
  JNF038070: 'Juvenile Nonfiction / People & Places / Mexico',
  JNF038080: 'Juvenile Nonfiction / People & Places / Middle East',
  JNF038090: 'Juvenile Nonfiction / People & Places / Polar Regions',
  JNF038100: 'Juvenile Nonfiction / People & Places / United States / General',
  JNF038110: 'Juvenile Nonfiction / People & Places / Other',
  JNF038120: 'Juvenile Nonfiction / People & Places / Canada / Native Canadian',
  JNF039000: 'Juvenile Nonfiction / Performing Arts / General',
  JNF039010: 'Juvenile Nonfiction / Performing Arts / Circus',
  JNF039020: 'Juvenile Nonfiction / Performing Arts / Dance',
  JNF039030: 'Juvenile Nonfiction / Performing Arts / Film',
  JNF039040: 'Juvenile Nonfiction / Performing Arts / Television & Radio',
  JNF039050: 'JUVENILE NONFICTION / Performing Arts / Theater & Musicals',
  JNF040000: 'Juvenile Nonfiction / Philosophy',
  JNF041000: 'Juvenile Nonfiction / Photography',
  JNF042000: 'Juvenile Nonfiction / Poetry / General',
  JNF042010: 'Juvenile Nonfiction / Poetry / Humorous',
  JNF042020: 'Juvenile Nonfiction / Poetry / Nursery Rhymes',
  JNF043000: 'Juvenile Nonfiction / Social Science / Politics & Government',
  JNF044000: 'Juvenile Nonfiction / Social Science / Psychology',
  JNF045000: 'Juvenile Nonfiction / Readers / Beginner',
  JNF046000: 'Juvenile Nonfiction / Readers / Intermediate',
  JNF047000: 'Juvenile Nonfiction / Readers / Chapter Books',
  JNF048000: 'Juvenile Nonfiction / Reference / General',
  JNF048010: 'Juvenile Nonfiction / Reference / Almanacs',
  JNF048020: 'Juvenile Nonfiction / Reference / Atlases',
  JNF048030: 'Juvenile Nonfiction / Reference / Dictionaries',
  JNF048040: 'Juvenile Nonfiction / Reference / Encyclopedias',
  JNF048050: 'Juvenile Nonfiction / Reference / Thesauri',
  JNF049000: 'Juvenile Nonfiction / Religion / General',
  JNF049010: 'Juvenile Nonfiction / Religion / Biblical Studies',
  JNF049020: 'Juvenile Nonfiction / Religion / Biblical Biography',
  JNF049030: 'Juvenile Nonfiction / Religion / Biblical Commentaries & Interpretation',
  JNF049040: 'Juvenile Nonfiction / Religion / Bible Stories / General',
  JNF049050: 'Juvenile Nonfiction/ Religion / Bible / Study',
  JNF049060: 'Juvenile Nonfiction / Religion / Bibles / General',
  JNF049070: 'Juvenile Nonfiction / Religion / Bibles / Picture',
  JNF049080: 'Juvenile Nonfiction / Religion / Christianity',
  JNF049090: 'Juvenile Nonfiction / Religion / Eastern',
  JNF049100: 'Juvenile Nonfiction / Religion / Islam',
  JNF049110: 'Juvenile Nonfiction / Religion / Judaism',
  JNF049120: 'Juvenile Nonfiction / Religious / Christian / Devotional & Prayer',
  JNF049130: 'Juvenile Nonfiction / Religious / Christian / General',
  JNF049140: 'Juvenile Nonfiction / Religion / Bible Stories / Old Testament',
  JNF049150: 'Juvenile Nonfiction / Religion / Bible Stories / New Testament',
  JNF049160: 'Juvenile Nonfiction / Religion / Biblical History',
  JNF049170: 'Juvenile Nonfiction / Religion / Biblical Reference',
  JNF049180: 'Juvenile Nonfiction / Religious / Christian / Biography & Autobiography ',
  JNF049190: 'Juvenile Nonfiction / Religious / Christian / Comics & Graphic Novels',
  JNF049200: 'Juvenile Nonfiction / Religious / Christian / Early Readers',
  JNF049210: 'Juvenile Nonfiction / Religious / Christian / Family & Relationships',
  JNF049220: 'Juvenile Nonfiction / Religious / Christian / Games & Activities',
  JNF049230: 'Juvenile Nonfiction / Religious / Christian / Health & Daily Living',
  JNF049240: 'Juvenile Nonfiction / Religious / Christian / Holidays & Celebrations',
  JNF049250: 'Juvenile Nonfiction / Religious / Christian / Inspirational',
  JNF049260: 'Juvenile Nonfiction / Religious / Christian / Learning Concepts',
  JNF049270: 'Juvenile Nonfiction / Religious / Christian / People & Places',
  JNF049280: 'Juvenile Nonfiction / Religious / Christian / Science & Nature',
  JNF049290: 'Juvenile Nonfiction / Religious / Christian / Social Topics',
  JNF049300: 'Juvenile Nonfiction / Religious / Christian / Sports & Recreation',
  JNF049310: 'Juvenile Nonfiction / Religious / Christian / Values & Virtues',
  JNF049320: 'Juvenile Nonfiction / Religion / Buddhism ',
  JNF049330: 'Juvenile Nonfiction / Religion / Hinduism ',
  JNF050000: 'Juvenile Nonfiction / School & Education',
  JNF051000: 'JUVENILE NONFICTION / Science & Nature / General',
  JNF051010: 'Juvenile Nonfiction / Technology / Aeronautics, Astronautics & Space Science',
  JNF051020: 'Juvenile Nonfiction / Technology / Agriculture',
  JNF051030: 'Juvenile Nonfiction / Science & Nature / Anatomy & Physiology',
  JNF051040: 'Juvenile Nonfiction / Science & Nature / Astronomy',
  JNF051050: 'Juvenile Nonfiction / Science & Nature / Biology',
  JNF051060: 'Juvenile Nonfiction / Science & Nature / Botany',
  JNF051070: 'Juvenile Nonfiction / Science & Nature / Chemistry',
  JNF051080: 'Juvenile Nonfiction / Science & Nature / Earth Sciences / General',
  JNF051090: 'Juvenile Nonfiction / Technology / Electricity & Electronics',
  JNF051100: 'Juvenile Nonfiction / Science & Nature / Environmental Science & Ecosystems',
  JNF051110: 'Juvenile Nonfiction / Science & Nature / Experiments & Projects',
  JNF051120: 'Juvenile Nonfiction / Technology / How Things Work-Are Made',
  JNF051130: 'Juvenile Nonfiction / Technology / Machinery & Tools',
  JNF051140: 'Juvenile Nonfiction / Science & Nature / Physics',
  JNF051150: 'Juvenile Nonfiction / Science & Nature / Zoology',
  JNF051160: 'Juvenile Nonfiction / Science & Nature / Disasters',
  JNF051170: 'Juvenile Nonfiction / Science & Nature / Discoveries',
  JNF051180: 'Juvenile Nonfiction / Science & Nature / Earth Sciences / Geography',
  JNF051190: 'Juvenile Nonfiction / Science & Nature / History of Science',
  JNF051200: 'Juvenile Nonfiction / Science & Nature / Weights & Measures',
  JNF052000: 'Juvenile Nonfiction / Social Science / General',
  JNF052010: 'Juvenile Nonfiction / Social Science / Archaeology',
  JNF052020: 'Juvenile Nonfiction / Social Science / Customs, Traditions, Anthropology',
  JNF052030: 'Juvenile Nonfiction / Social Science / Folklore & Mythology',
  JNF052040: 'Juvenile Nonfiction / Social Science / Sociology',
  JNF053000: 'Juvenile Nonfiction / Social Topics / General ',
  JNF053010: 'Juvenile Nonfiction / Social Topics / Adolescence',
  JNF053020: 'Juvenile Nonfiction / Social Topics / Dating & Relationships',
  JNF053030: 'JUVENILE NONFICTION / Social Topics / Death, Grief, Bereavement',
  JNF053040: 'Juvenile Nonfiction / Social Topics / Drugs, Alcohol, Substance Abuse',
  JNF053050: 'Juvenile Nonfiction / Social Topics / Emotions & Feelings',
  JNF053060: 'Juvenile Nonfiction / Social Topics / Friendship',
  JNF053070: 'Juvenile Nonfiction / Social Topics / Homelessness & Poverty',
  JNF053080: 'Juvenile Nonfiction / LGBTQ+',
  JNF053090: 'Juvenile Nonfiction / Social Topics / Manners & Etiquette',
  JNF053100: 'Juvenile Nonfiction / Social Topics / New Experience',
  JNF053110: 'Juvenile Nonfiction / Social Topics / Peer Pressure',
  JNF053120: 'Juvenile Nonfiction / Social Topics / Physical & Emotional Abuse ',
  JNF053130: 'Juvenile Nonfiction / Social Issues / Pregnancy',
  JNF053140: 'Juvenile Nonfiction / Social Topics / Prejudice & Racism',
  JNF053150: 'Juvenile Nonfiction / Social Issues / Runaways',
  JNF053160: 'Juvenile Nonfiction / Social Topics / Self-Esteem & Self-Reliance',
  JNF053170: 'Juvenile Nonfiction / Social Topics / Sexual Abuse',
  JNF053180: 'JUVENILE NONFICTION / Disabilities & Special Needs',
  JNF053190: 'Juvenile Nonfiction / Social Topics / Suicide',
  JNF053200: 'Juvenile Nonfiction / Social Topics / Values & Virtues',
  JNF053210: 'Juvenile Nonfiction / Social Topics / Violence',
  JNF053220: 'Juvenile Nonfiction / Social Topics / Bullying',
  JNF053230: 'Juvenile Nonfiction / Social Topics / Depression & Mental Illness',
  JNF053240: 'Juvenile Nonfiction / Social Topics / Emigration & Immigration',
  JNF053250: 'Juvenile Nonfiction / Social Issues / Self-Mutilation',
  JNF053260: 'Juvenile Nonfiction / Social Topics / Strangers',
  JNF054000: 'Juvenile Nonfiction / Sports & Recreation / General',
  JNF054010: 'Juvenile Nonfiction / Sports & Recreation / Baseball & Softball',
  JNF054020: 'Juvenile Nonfiction / Sports & Recreation / Basketball',
  JNF054030: 'Juvenile Nonfiction / Sports & Recreation / Camping & Outdoor Activities',
  JNF054040: 'Juvenile Nonfiction / Sports & Recreation / Cycling',
  JNF054050: 'Juvenile Nonfiction / Sports & Recreation / Football',
  JNF054060: 'Juvenile Nonfiction / Sports & Recreation / Gymnastics',
  JNF054070: 'Juvenile Nonfiction / Sports & Recreation / Hockey',
  JNF054080: 'Juvenile Nonfiction / Sports & Recreation / Martial Arts',
  JNF054090: 'Juvenile Nonfiction / Sports & Recreation / Miscellaneous',
  JNF054100: 'Juvenile Nonfiction / Sports & Recreation / Motor Sports',
  JNF054110: 'Juvenile Nonfiction/ Sports & Recreation / Olympics & Paralympics',
  JNF054120: 'Juvenile Nonfiction / Sports & Recreation / Racket Sports',
  JNF054130: 'Juvenile Nonfiction / Sports & Recreation / Soccer',
  JNF054140: 'Juvenile Nonfiction / Sports & Recreation / Track & Field',
  JNF054150: 'Juvenile Nonfiction / Sports & Recreation / Water Sports',
  JNF054160: 'Juvenile Nonfiction / Sports & Recreation / Winter Sports',
  JNF054170: 'Juvenile Nonfiction / Sports & Recreation / Equestrian',
  JNF054180: 'Juvenile Nonfiction / Sports & Recreation / Extreme Sports',
  JNF054190: 'Juvenile Nonfiction / Sports & Recreation / Ice Skating',
  JNF054200: 'Juvenile Nonfiction / Sports & Recreation / Roller & In-Line Skating',
  JNF054210: 'Juvenile Nonfiction / Sports & Recreation / Skateboarding',
  JNF054220: 'Juvenile Nonfiction / Sports & Recreation / Wrestling',
  JNF054230: 'Juvenile Nonfiction / Sports & Recreation / Golf',
  JNF055000: 'Juvenile Nonfiction / Study Aids / General',
  JNF055010: 'Juvenile Nonfiction / Study Aids / Book Notes ',
  JNF055020: 'Juvenile Nonfiction/ Study Aids / College Guides',
  JNF055030: 'Juvenile Nonfiction / Study Aids / Test Preparation',
  JNF056000: 'Juvenile Nonfiction / Toys, Dolls & Puppets',
  JNF057000: 'Juvenile Nonfiction / Transportation / General',
  JNF057010: 'Juvenile Nonfiction / Transportation / Aviation',
  JNF057020: 'Juvenile Nonfiction / Transportation / Boats, Ships & Underwater Craft',
  JNF057030: 'Juvenile Nonfiction / Transportation / Cars & Trucks',
  JNF057040: 'Juvenile Nonfiction / Transportation / Motorcycles',
  JNF057050: 'Juvenile Nonfiction / Transportation / Railroads & Trains',
  JNF058000: 'Juvenile Nonfiction / Travel',
  JNF059000: 'Juvenile Nonfiction / Clothing & Dress',
  JNF060000: 'Juvenile Nonfiction / Media Studies',
  JNF061000: 'Juvenile Nonfiction / Technology / General',
  JNF061010: 'Juvenile Nonfiction / Technology / Inventions',
  JNF062000: 'Juvenile Nonfiction / Comics & Graphic Novels / General',
  JNF062010: 'Juvenile Nonfiction / Comics & Graphic Novels / Biography',
  JNF062020: 'Juvenile Nonfiction / Comics & Graphic Novels / History',
  JNF063000: 'Juvenile Nonfiction / Books & Libraries',
  JNF064000: 'Juvenile Nonfiction / Media Tie-In',
  JNF065000: 'Juvenile Nonfiction / Recycling & Green Living',
  JNF066000: 'Juvenile Nonfiction / Pirates',
  JUV000000: 'Juvenile Fiction / General',
  JUV001000: 'Juvenile Fiction / Action & Adventure / General',
  JUV001010: 'Juvenile Fiction / Action & Adventure / Survival Stories',
  JUV001020: 'Juvenile Fiction / Action & Adventure / Pirates',
  JUV002000: 'Juvenile Fiction / Animals / General',
  JUV002010: 'Juvenile Fiction / Animals / Alligators & Crocodiles',
  JUV002020: 'Juvenile Fiction / Animals / Apes, Monkeys, etc.',
  JUV002030: 'Juvenile Fiction / Animals / Bears',
  JUV002040: 'Juvenile Fiction / Animals / Birds',
  JUV002050: 'Juvenile Fiction / Animals / Cats',
  JUV002060: 'Juvenile Fiction / Animals / Dinosaurs & Prehistoric Creatures',
  JUV002070: 'Juvenile Fiction / Animals / Dogs',
  JUV002080: 'Juvenile Fiction / Animals / Elephants',
  JUV002090: 'Juvenile Fiction / Animals / Farm Animals',
  JUV002100: 'JUVENILE FICTION / Animals / Fish',
  JUV002110: 'Juvenile Fiction / Animals / Foxes',
  JUV002120: 'Juvenile Fiction / Animals / Frogs & Toads',
  JUV002130: 'Juvenile Fiction / Animals / Horses',
  JUV002140: 'Juvenile Fiction / Animals / Insects, Spiders, etc.',
  JUV002150: 'Juvenile Fiction / Animals / Lions, Tigers, Leopards, etc.',
  JUV002160: 'Juvenile Fiction / Animals / Mammals',
  JUV002170: 'Juvenile Fiction / Animals / Marine Life',
  JUV002180: 'Juvenile Fiction / Animals / Mice, Hamsters, Guinea Pigs, etc.',
  JUV002190: 'Juvenile Fiction / Animals / Pets',
  JUV002200: 'Juvenile Fiction / Animals / Pigs',
  JUV002210: 'Juvenile Fiction / Animals / Rabbits',
  JUV002220: 'Juvenile Fiction / Animals / Reptiles & Amphibians',
  JUV002230: 'Juvenile Fiction / Animals / Squirrels',
  JUV002240: 'Juvenile Fiction / Animals / Turtles',
  JUV002250: 'JUVENILE FICTION / Animals / Wolves, Coyotes & Wild Dogs',
  JUV002260: 'Juvenile Fiction / Animals / Zoos',
  JUV002270: 'Juvenile Fiction / Animals / Dragons, Unicorns & Mythical',
  JUV002280: 'Juvenile Fiction / Animals / Ducks, Geese, etc.',
  JUV002290: 'Juvenile Fiction / Animals / Deer, Moose & Caribou',
  JUV002300: 'Juvenile Fiction / Animals / Butterflies, Moths & Caterpillars',
  JUV002310: 'Juvenile Fiction / Animals / Cows',
  JUV002320: 'Juvenile Fiction / Animals / Giraffes',
  JUV002330: 'Juvenile Fiction / Animals / Hippos & Rhinos',
  JUV002340: 'Juvenile Fiction / Animals / Jungle Animals',
  JUV002350: 'Juvenile Fiction / Animals / Kangaroos',
  JUV002360: 'Juvenile Fiction / Animals / Nocturnal',
  JUV002370: 'Juvenile Fiction / Animals / Baby Animals',
  JUV003000: 'Juvenile Fiction/ Art',
  JUV004000: 'Juvenile Fiction / Biographical / General',
  JUV004010: 'Juvenile Fiction / Biographical / Europe',
  JUV004020: 'Juvenile Fiction / Biographical / United States',
  JUV004030: 'Juvenile Fiction / Biographical / Other',
  JUV004040: 'Juvenile Fiction / Biographical / Canada',
  JUV004050: 'Juvenile Fiction / Biographical / Africa ',
  JUV004060: 'Juvenile Fiction / Biographical / Asia ',
  JUV004070: 'Juvenile Fiction / Biographical / Latin America ',
  JUV005000: 'Juvenile Fiction / Boys & Men',
  JUV006000: 'Juvenile Fiction / Business, Careers, Occupations',
  JUV007000: 'Juvenile Fiction / Classics',
  JUV008000: 'Juvenile Fiction / Comics & Graphic Novels / General',
  JUV008010: 'Juvenile Fiction / Comics & Graphic Novels / Manga',
  JUV008020: 'Juvenile Fiction / Comics & Graphic Novels / Superheroes',
  JUV008030: 'Juvenile Fiction / Comics & Graphic Novels / Media Tie-In',
  JUV009000: 'Juvenile Fiction / Concepts / General',
  JUV009010: 'Juvenile Fiction / Concepts / Alphabet',
  JUV009020: 'Juvenile Fiction / Concepts / Colors',
  JUV009030: 'Juvenile Fiction / Concepts / Counting & Numbers',
  JUV009040: 'Juvenile Fiction / Concepts / Opposites',
  JUV009050: 'Juvenile Fiction / Concepts / Senses & Sensation',
  JUV009060: 'Juvenile Fiction / Concepts / Size & Shape',
  JUV009070: 'Juvenile Fiction / Concepts / Date & Time',
  JUV009080: 'Juvenile Fiction / Concepts / Words',
  JUV009090: 'Juvenile Fiction / Concepts / Money',
  JUV009100: 'Juvenile Fiction / Concepts / Seasons',
  JUV009110: 'Juvenile Fiction / Concepts / Sounds',
  JUV009120: 'Juvenile Fiction / Concepts / Body',
  JUV010000: 'Juvenile Fiction / Bedtime & Dreams',
  JUV011000: 'Juvenile Fiction/ Ethnic / General',
  JUV011010: 'Juvenile Fiction/ People & Places / United States / African American & Black',
  JUV011020: 'Juvenile Fiction / People & Places / United States / Asian American',
  JUV011030: 'Juvenile Fiction / People & Places / United States / Hispanic & Latino',
  JUV011040: 'Juvenile Fiction / People & Places / United States / Native American',
  JUV011050: 'Juvenile Fiction / People & Places / United States / Other',
  JUV012000: 'Juvenile Fiction / Fairy Tales & Folklore / Anthologies',
  JUV012010: 'Juvenile Fiction/ Fairy Tales & Folklore / Collections by a Single Author',
  JUV012020: 'Juvenile Fiction / Fairy Tales & Folklore / Country & Ethnic',
  JUV012030: 'Juvenile Fiction / Fairy Tales & Folklore / General',
  JUV012040: 'Juvenile Fiction / Fairy Tales & Folklore / Adaptations',
  JUV012050: 'Juvenile Fiction / Legends, Myths, Fables / African',
  JUV012060: 'Juvenile Fiction / Legends, Myths, Fables / Asian',
  JUV012070: 'Juvenile Fiction / Legends, Myths, Fables / Caribbean & Latin American',
  JUV012080: 'Juvenile Fiction/ Legends, Myths, Fables / Indigenous Peoples of the Americas',
  JUV013000: 'Juvenile Fiction / Family / General ',
  JUV013010: 'Juvenile Fiction / Family / Adoption',
  JUV013020: 'Juvenile Fiction / Family / Marriage & Divorce',
  JUV013030: 'Juvenile Fiction / Family / Multigenerational',
  JUV013040: 'Juvenile Fiction / Family / New Baby',
  JUV013050: 'Juvenile Fiction / Family / Orphans & Foster Homes',
  JUV013060: 'Juvenile Fiction / Family / Parents',
  JUV013070: 'Juvenile Fiction / Family / Siblings',
  JUV013080: 'Juvenile Fiction / Family / Blended Families',
  JUV013090: 'Juvenile Fiction / Family / Alternative Family',
  JUV014000: 'Juvenile Fiction / Girls & Women',
  JUV015000: 'Juvenile Fiction / Health & Daily Living / General',
  JUV015010: 'Juvenile Fiction / Health & Daily Living / Daily Activities',
  JUV015020: 'Juvenile Fiction / Health & Daily Living / Diseases, Illnesses & Injuries',
  JUV016000: 'Juvenile Fiction / Historical / General',
  JUV016010: 'Juvenile Fiction / Historical / Africa',
  JUV016020: 'Juvenile Fiction / Historical / Ancient Civilizations',
  JUV016030: 'Juvenile Fiction / Historical / Asia',
  JUV016040: 'Juvenile Fiction / Historical / Europe',
  JUV016050: 'Juvenile Fiction / Historical / Exploration & Discovery',
  JUV016060: 'Juvenile Fiction / Historical / Holocaust',
  JUV016070: 'Juvenile Fiction / Historical / Medieval',
  JUV016080: 'Juvenile Fiction / Historical / Military & Wars',
  JUV016090: 'Juvenile Fiction / Historical / Prehistory',
  JUV016100: 'Juvenile Fiction / Historical / Renaissance',
  JUV016110: 'Juvenile Fiction / Historical / United States / General',
  JUV016120: 'Juvenile Fiction / Historical / United States / Colonial & Revolutionary Periods',
  JUV016130: 'Juvenile Fiction / Historical / Other',
  JUV016140: 'Juvenile Fiction / Historical / United States / 19th Century',
  JUV016150: 'Juvenile Fiction / Historical / United States / 20th Century',
  JUV016160: 'Juvenile Fiction / Historical / Canada / General',
  JUV016170: 'Juvenile Fiction / Historical / Canada / Pre-Confederation (to 1867)',
  JUV016180: 'Juvenile Fiction / Historical / Canada / Post-Confederation (1867-)',
  JUV016190: 'Juvenile Fiction / Historical / United States / 21st Century',
  JUV016200: 'Juvenile Fiction / Historical / United States / Civil War Period (1850-1877)',
  JUV016210: 'Juvenile Fiction / Historical / Middle East',
  JUV017000: 'Juvenile Fiction / Holidays & Celebrations / General ',
  JUV017010: 'Juvenile Fiction / Holidays & Celebrations / Christmas & Advent',
  JUV017020: 'Juvenile Fiction / Holidays & Celebrations / Easter & Lent',
  JUV017030: 'Juvenile Fiction / Holidays & Celebrations / Halloween',
  JUV017040: 'Juvenile Fiction/ Holidays & Festivals / Jewish',
  JUV017050: 'Juvenile Fiction / Holidays & Celebrations / Kwanzaa',
  JUV017060: 'Juvenile Fiction / Holidays & Celebrations / Thanksgiving',
  JUV017070: 'Juvenile Fiction / Holidays & Celebrations / Valentine\u2019s Day',
  JUV017080: 'Juvenile Fiction / Holidays & Celebrations / Other, Non-Religious',
  JUV017090: 'Juvenile Fiction / Holidays & Celebrations / Other, Religious',
  JUV017100: 'Juvenile Fiction / Holidays & Celebrations / Birthdays',
  JUV017110: 'Juvenile Fiction / Holidays & Celebrations / Hanukkah',
  JUV017120: 'Juvenile Fiction / Holidays & Celebrations / Passover',
  JUV017130: 'Juvenile Fiction / Holidays & Celebrations / Patriotic Holidays',
  JUV018000: 'Juvenile Fiction/ Horror',
  JUV019000: 'Juvenile Fiction / Humorous Stories',
  JUV020000: 'Juvenile Fiction / Interactive Adventures',
  JUV021000: 'Juvenile Fiction / Law & Crime',
  JUV022000: 'Juvenile Fiction / Legends, Myths, Fables / General',
  JUV022010: 'Juvenile Fiction / Legends, Myths, Fables / Arthurian',
  JUV022020: 'Juvenile Fiction / Legends, Myths, Fables / Greek & Roman',
  JUV022030: 'Juvenile Fiction / Legends, Myths, Fables / Norse',
  JUV022040: 'Juvenile Fiction / Legends, Myths, Fables / Other',
  JUV023000: 'Juvenile Fiction / Lifestyles / City & Town Life',
  JUV024000: 'Juvenile Fiction / Lifestyles / Country Life',
  JUV025000: 'Juvenile Fiction / Lifestyles / Farm & Ranch Life',
  JUV026000: 'Juvenile Fiction / Love & Romance',
  JUV027000: 'Juvenile Fiction / Media Tie-In',
  JUV028000: 'Juvenile Fiction / Mysteries & Detective Stories',
  JUV029000: 'JUVENILE FICTION / Science & Nature / General',
  JUV029010: 'JUVENILE FICTION / Science & Nature / Environment',
  JUV029020: 'JUVENILE FICTION / Science & Nature / Weather',
  JUV030000: 'Juvenile Fiction / People & Places / General',
  JUV030010: 'Juvenile Fiction / People & Places / Africa',
  JUV030020: 'Juvenile Fiction / People & Places / Asia',
  JUV030030: 'Juvenile Fiction / People & Places / Canada / General',
  JUV030040: 'Juvenile Fiction / People & Places / Caribbean & Latin America',
  JUV030050: 'Juvenile Fiction / People & Places / Europe',
  JUV030060: 'Juvenile Fiction / People & Places / United States / General',
  JUV030070: 'Juvenile Fiction / People & Places / Other',
  JUV030080: 'Juvenile Fiction / People & Places / Australia & Oceania',
  JUV030090: 'Juvenile Fiction / People & Places / Canada / Native Canadian',
  JUV030100: 'Juvenile Fiction / People & Places / Mexico',
  JUV030110: 'Juvenile Fiction / People & Places / Middle East',
  JUV030120: 'Juvenile Fiction / People & Places / Polar Regions',
  JUV031000: 'Juvenile Fiction / Performing Arts / General',
  JUV031010: 'Juvenile Fiction / Performing Arts / Circus',
  JUV031020: 'Juvenile Fiction / Performing Arts / Dance',
  JUV031030: 'Juvenile Fiction / Performing Arts / Film',
  JUV031040: 'Juvenile Fiction / Performing Arts / Music',
  JUV031050: 'Juvenile Fiction / Performing Arts / Television & Radio',
  JUV031060: 'JUVENILE FICTION / Performing Arts / Theater & Musicals',
  JUV032000: 'Juvenile Fiction / Sports & Recreation / General',
  JUV032010: 'Juvenile Fiction / Sports & Recreation / Baseball & Softball',
  JUV032020: 'Juvenile Fiction / Sports & Recreation / Basketball',
  JUV032030: 'Juvenile Fiction / Sports & Recreation / Football',
  JUV032040: 'Juvenile Fiction / Sports & Recreation / Games',
  JUV032050: 'Juvenile Fiction / Sports & Recreation / Miscellaneous',
  JUV032060: 'Juvenile Fiction / Sports & Recreation / Water Sports',
  JUV032070: 'Juvenile Fiction / Sports & Recreation / Martial Arts',
  JUV032080: 'Juvenile Fiction / Sports & Recreation / Winter Sports',
  JUV032090: 'Juvenile Fiction / Sports & Recreation / Equestrian',
  JUV032100: 'Juvenile Fiction / Sports & Recreation / Extreme Sports',
  JUV032110: 'Juvenile Fiction / Sports & Recreation / Hockey',
  JUV032120: 'Juvenile Fiction / Sports & Recreation / Ice Skating',
  JUV032130: 'Juvenile Fiction / Sports & Recreation / Roller & In-Line Skating',
  JUV032140: 'Juvenile Fiction / Sports & Recreation / Skateboarding',
  JUV032150: 'Juvenile Fiction / Sports & Recreation / Soccer',
  JUV032160: 'Juvenile Fiction / Sports & Recreation / Wrestling',
  JUV032170: 'Juvenile Fiction / Sports & Recreation / Camping & Outdoor Activities',
  JUV032180: 'Juvenile Fiction / Sports & Recreation / Cycling',
  JUV032190: 'Juvenile Fiction / Sports & Recreation / Golf',
  JUV032200: 'Juvenile Fiction / Sports & Recreation / Gymnastics ',
  JUV032210: 'Juvenile Fiction / Sports & Recreation / Track & Field ',
  JUV033000: 'Juvenile Fiction / Religious / General',
  JUV033010: 'Juvenile Fiction / Religious / Christian / General',
  JUV033020: 'Juvenile Fiction / Religious / Jewish',
  JUV033030: 'Juvenile Fiction / Religious / Other',
  JUV033040: 'Juvenile Fiction / Religious / Christian / Action & Adventure',
  JUV033050: 'Juvenile Fiction / Religious / Christian / Animals',
  JUV033060: 'Juvenile Fiction / Religious / Christian / Bedtime & Dreams',
  JUV033070: 'Juvenile Fiction / Religious / Christian / Comics & Graphic Novels',
  JUV033080: 'Juvenile Fiction / Religious / Christian / Early Readers',
  JUV033090: 'Juvenile Fiction / Religious / Christian / Emotions & Feelings',
  JUV033100: 'Juvenile Fiction / Religious / Christian / Family',
  JUV033110: 'Juvenile Fiction / Religious / Christian / Fantasy & Science Fiction',
  JUV033120: 'Juvenile Fiction / Religious / Christian / Friendship',
  JUV033130: 'Juvenile Fiction / Religious / Christian / Health & Daily Living',
  JUV033140: 'Juvenile Fiction / Religious / Christian / Historical',
  JUV033150: 'Juvenile Fiction / Religious / Christian / Holidays & Celebrations',
  JUV033160: 'Juvenile Fiction / Religious / Christian / Humorous',
  JUV033170: 'Juvenile Fiction / Religious / Christian / Learning Concepts',
  JUV033180: 'Juvenile Fiction / Religious / Christian / Mysteries & Detective Stories',
  JUV033190: 'Juvenile Fiction / Religious / Christian / People & Places',
  JUV033200: 'Juvenile Fiction / Religious / Christian / Relationships',
  JUV033210: 'Juvenile Fiction / Religious / Christian / Science Fiction',
  JUV033220: 'Juvenile Fiction / Religious / Christian / Social Issues',
  JUV033230: 'Juvenile Fiction / Religious / Christian / Sports & Recreation',
  JUV033240: 'Juvenile Fiction / Religious / Christian / Values & Virtues',
  JUV033250: 'Juvenile Fiction / Religious / Buddhist ',
  JUV033260: 'Juvenile Fiction / Religious / Hindu ',
  JUV033270: 'Juvenile Fiction / Religious / Muslim ',
  JUV034000: 'Juvenile Fiction / Royalty',
  JUV035000: 'Juvenile Fiction / School & Education',
  JUV036000: 'Juvenile Fiction/ Technology / General',
  JUV037000: 'JUVENILE FICTION / Fantasy & Magic',
  JUV038000: 'Juvenile Fiction / Short Stories',
  JUV039000: 'Juvenile Fiction / Social Themes / General ',
  JUV039010: 'Juvenile Fiction / Social Themes / Physical & Emotional Abuse ',
  JUV039020: 'JUVENILE FICTION / Social Themes / Adolescence & Coming of Age',
  JUV039030: 'JUVENILE FICTION / Social Themes / Death, Grief, Bereavement',
  JUV039040: 'Juvenile Fiction / Social Themes / Drugs, Alcohol, Substance Abuse',
  JUV039050: 'Juvenile Fiction / Social Themes / Emotions & Feelings',
  JUV039060: 'Juvenile Fiction / Social Themes / Friendship',
  JUV039070: 'Juvenile Fiction / Social Themes / Homelessness & Poverty',
  JUV039080: 'Juvenile Fiction / Social Issues / Homosexuality',
  JUV039090: 'Juvenile Fiction / Social Themes / New Experience',
  JUV039100: 'Juvenile Fiction / Social Themes / Peer Pressure',
  JUV039110: 'Juvenile Fiction / Social Issues / Pregnancy',
  JUV039120: 'Juvenile Fiction / Social Themes / Prejudice & Racism',
  JUV039130: 'Juvenile Fiction / Social Themes / Runaways',
  JUV039140: 'Juvenile Fiction / Social Themes / Self-Esteem & Self-Reliance',
  JUV039150: 'JUVENILE FICTION / Disabilities & Special Needs',
  JUV039160: 'Juvenile Fiction / Social Themes / Suicide',
  JUV039170: 'Juvenile Fiction / Health & Daily Living / Toilet Training',
  JUV039180: 'Juvenile Fiction / Social Themes / Violence',
  JUV039190: 'Juvenile Fiction / Social Themes / Dating & Relationships',
  JUV039200: 'Juvenile Fiction / Social Themes / Manners & Etiquette',
  JUV039210: 'Juvenile Fiction / Social Themes / Sexual Abuse',
  JUV039220: 'Juvenile Fiction / Social Themes / Values & Virtues',
  JUV039230: 'Juvenile Fiction / Social Themes / Bullying',
  JUV039240: 'Juvenile Fiction / Social Themes / Depression & Mental Illness',
  JUV039250: 'Juvenile Fiction / Social Themes / Emigration & Immigration',
  JUV039260: 'Juvenile Fiction / Social Issues / Self-Mutilation',
  JUV039270: 'Juvenile Fiction / Social Themes / Strangers',
  JUV039280: 'Juvenile Fiction / Social Themes / Religion & Faith ',
  JUV040000: 'Juvenile Fiction / Toys, Dolls & Puppets',
  JUV041000: 'Juvenile Fiction / Transportation / General',
  JUV041010: 'Juvenile Fiction / Transportation / Aviation',
  JUV041020: 'Juvenile Fiction / Transportation / Boats, Ships & Underwater Craft',
  JUV041030: 'Juvenile Fiction / Transportation / Cars & Trucks',
  JUV041040: 'Juvenile Fiction / Transportation / Motorcycles',
  JUV041050: 'Juvenile Fiction / Transportation / Railroads & Trains',
  JUV042000: 'Juvenile Fiction / Westerns',
  JUV043000: 'Juvenile Fiction / Readers / Beginner',
  JUV044000: 'Juvenile Fiction / Readers / Intermediate',
  JUV045000: 'Juvenile Fiction / Readers / Chapter Books',
  JUV046000: 'Juvenile Fiction / Visionary & Metaphysical',
  JUV047000: 'Juvenile Fiction / Books & Libraries',
  JUV048000: 'Juvenile Fiction / Clothing & Dress',
  JUV049000: 'Juvenile Fiction / Computers & Digital Media',
  JUV050000: 'Juvenile Fiction / Cooking & Food',
  JUV051000: 'Juvenile Fiction / Imagination & Play',
  JUV052000: 'Juvenile Fiction / Monsters',
  JUV053000: 'JUVENILE FICTION / Science Fiction / General',
  JUV054000: 'JUVENILE FICTION / Activity Books / General',
  JUV055000: 'Juvenile Fiction / Nursery Rhymes',
  JUV056000: 'Juvenile Fiction / Robots',
  JUV057000: 'JUVENILE FICTION / Stories in Verse',
  JUV058000: 'Juvenile Fiction / Paranormal, Occult & Supernatural',
  JUV059000: 'Juvenile Fiction / Dystopian',
  JUV060000: 'Juvenile Fiction / LGBTQ+',
  JUV061000: 'Juvenile Fiction / Politics & Government',
  JUV062000: 'Juvenile Fiction / Steampunk',
  JUV063000: 'Juvenile Fiction / Recycling & Green Living',
  JUV064000: 'JUVENILE FICTION / Science Fiction / Time Travel',
  JUV065000: 'Juvenile Fiction / Light Novel (Ranobe)',
  JUV066000: 'Juvenile Fiction / Mermaids',
  JUV067000: 'Juvenile Fiction / Thrillers & Suspense',
  JUV068000: 'Juvenile Fiction / Travel',
  JUV069000: 'Juvenile Fiction / Ghost Stories ',
  JUV070000: 'Juvenile Fiction / Poetry  ',
  JUV071000: 'Juvenile Fiction / Superheroes ',
  LAN000000: 'Language Arts & Disciplines / General',
  LAN001000: 'Language Arts & Disciplines / Alphabets & Writing Systems',
  LAN002000: 'LANGUAGE ARTS & DISCIPLINES / Writing / Authorship',
  LAN003000: 'Language Arts & Disciplines / Braille',
  LAN004000: 'Language Arts & Disciplines / Communication Studies',
  LAN005000: 'Language Arts & Disciplines/ Writing / General',
  LAN006000: 'Language Arts & Disciplines / Grammar & Punctuation',
  LAN007000: 'Language Arts & Disciplines / Handwriting',
  LAN008000: 'Language Arts & Disciplines / Journalism',
  LAN009000: 'LANGUAGE ARTS & DISCIPLINES / Linguistics / General',
  LAN009010: 'Language Arts & Disciplines / Linguistics / Historical & Comparative',
  LAN009020: 'Language Arts & Disciplines / Linguistics / Morphology',
  LAN009030: 'Language Arts & Disciplines / Linguistics / Pragmatics',
  LAN009040: 'Language Arts & Disciplines / Linguistics / Psycholinguistics',
  LAN009050: 'Language Arts & Disciplines / Linguistics / Sociolinguistics',
  LAN009060: 'Language Arts & Disciplines / Linguistics / Syntax',
  LAN010000: 'Language Arts & Disciplines / Literacy',
  LAN011000: 'Language Arts & Disciplines / Linguistics / Phonetics & Phonology',
  LAN012000: 'Language Arts & Disciplines / Readers',
  LAN013000: 'Language Arts & Disciplines / Reading Skills',
  LAN014000: 'Language Arts & Disciplines / Reference',
  LAN015000: 'Language Arts & Disciplines / Rhetoric',
  LAN016000: 'Language Arts & Disciplines / Linguistics / Semantics',
  LAN017000: 'Language Arts & Disciplines / Sign Language',
  LAN018000: 'LANGUAGE ARTS & DISCIPLINES / Speech & Pronunciation',
  LAN019000: 'Language Arts & Disciplines / Spelling',
  LAN020000: 'Language Arts & Disciplines / Study & Teaching',
  LAN021000: 'LANGUAGE ARTS & DISCIPLINES / Spelling & Vocabulary',
  LAN022000: 'Language Arts & Disciplines / Editing & Proofreading',
  LAN023000: 'Language Arts & Disciplines / Translating & Interpreting',
  LAN024000: 'Language Arts & Disciplines / Linguistics / Etymology',
  LAN025000: 'LANGUAGE ARTS & DISCIPLINES / Library & Information Science / General',
  LAN025010: 'Language Arts & Disciplines / Library & Information Science / Administration & Management',
  LAN025020: 'Language Arts & Disciplines / Library & Information Science / Archives & Special Libraries',
  LAN025030: 'Language Arts & Disciplines / Library & Information Science / Cataloging & Classification',
  LAN025040: 'Language Arts & Disciplines / Library & Information Science / Collection Development',
  LAN025050: 'Language Arts & Disciplines / Library & Information Science / School Media',
  LAN025060: 'Language Arts & Disciplines / Library & Information Science / Digital & Online Resources',
  LAN026000: 'LANGUAGE ARTS & DISCIPLINES / Public Speaking & Speech Writing',
  LAN027000: 'LANGUAGE ARTS & DISCIPLINES / Publishers & Publishing Industry',
  LAN028000: 'Language Arts & Disciplines / Style Manuals',
  LAN029000: 'Language Arts & Disciplines / Lexicography',
  LCO000000: 'Literary Collections / General',
  LCO001000: 'Literary Collections / African',
  LCO002000: 'Literary Collections / American / General',
  LCO002010: 'Literary Collections/ American / African American & Black',
  LCO003000: 'Literary Collections/ Ancient & Classical',
  LCO004000: 'Literary Collections / Asian / General',
  LCO004010: 'Literary Collections / Asian / Chinese',
  LCO004020: 'Literary Collections / Asian / Indic',
  LCO004030: 'Literary Collections / Asian / Japanese',
  LCO005000: 'Literary Collections / Australian & Oceanian',
  LCO006000: 'Literary Collections / Canadian',
  LCO007000: 'Literary Collections / Caribbean & Latin American',
  LCO008000: 'Literary Collections / European / General',
  LCO008010: 'Literary Collections / European / Eastern ',
  LCO008020: 'Literary Collections / European / French',
  LCO008030: 'Literary Collections / European / German',
  LCO008040: 'Literary Collections / European / Italian',
  LCO008050: 'Literary Collections / European / Scandinavian',
  LCO008060: 'Literary Collections / European / Spanish & Portuguese',
  LCO009000: 'Literary Collections / European / English, Irish, Scottish, Welsh',
  LCO010000: 'Literary Collections / Essays',
  LCO011000: 'Literary Collections / Letters',
  LCO012000: 'Literary Collections / Middle Eastern',
  LCO013000: 'Literary Collections/ Indigenous Peoples of the Americas',
  LCO014000: 'Literary Collections / Russian & Former Soviet Union',
  LCO015000: 'Literary Collections / Diaries & Journals',
  LCO016000: 'Literary Collections / LGBTQ+',
  LCO017000: 'Literary Collections / Medieval',
  LCO018000: 'Literary Collections / Speeches',
  LCO019000: 'Literary Collections / Women Authors',
  LIT000000: 'LITERARY CRITICISM / General',
  LIT001000: 'Literary Criticism & Collections/ Belles Lettres',
  LIT002000: 'Literary Criticism & Collections/ Essays',
  LIT003000: 'Literary Criticism / Feminist',
  LIT004000: 'Literary Criticism & Collections/ History & Criticism',
  LIT004010: 'LITERARY CRITICISM / African',
  LIT004020: 'LITERARY CRITICISM / American / General',
  LIT004030: 'Literary Criticism/ American / Asian American',
  LIT004040: 'Literary Criticism/ American / African American & Black',
  LIT004050: 'Literary Criticism/ American / Hispanic & Latino',
  LIT004060: 'Literary Criticism/ Indigenous Peoples of the Americas',
  LIT004070: 'LITERARY CRITICISM / Australian & Oceanian',
  LIT004080: 'LITERARY CRITICISM / Canadian',
  LIT004090: 'Literary Criticism & Collections/ Caribbean & West Indian',
  LIT004100: 'LITERARY CRITICISM / Caribbean & Latin American',
  LIT004110: 'Literary Criticism/ European / Eastern ',
  LIT004120: 'LITERARY CRITICISM / European / English, Irish, Scottish, Welsh',
  LIT004130: 'LITERARY CRITICISM / European / General',
  LIT004140: 'Literary Criticism & Collections/ Far Eastern',
  LIT004150: 'LITERARY CRITICISM / European / French',
  LIT004160: 'Literary Criticism / LGBTQ+',
  LIT004170: 'LITERARY CRITICISM / European / German',
  LIT004180: 'LITERARY CRITICISM / Gothic & Romance',
  LIT004190: 'LITERARY CRITICISM / Ancient & Classical',
  LIT004200: 'LITERARY CRITICISM / European / Italian',
  LIT004210: 'LITERARY CRITICISM / Jewish',
  LIT004220: 'LITERARY CRITICISM / Middle Eastern',
  LIT004230: 'LITERARY CRITICISM / Mystery & Detective',
  LIT004240: 'LITERARY CRITICISM / Russian & Former Soviet Union',
  LIT004250: 'LITERARY CRITICISM / European / Scandinavian',
  LIT004260: 'LITERARY CRITICISM / Science Fiction & Fantasy',
  LIT004270: 'Literary Criticism & Collections/ South & Southeast Asian',
  LIT004280: 'LITERARY CRITICISM / European / Spanish & Portuguese',
  LIT004290: 'LITERARY CRITICISM / Women Authors',
  LIT005000: 'Literary Criticism & Collections/ Semiotics',
  LIT006000: 'Literary Criticism / Semiotics & Theory',
  LIT007000: 'Literary Criticism / Books & Reading',
  LIT008000: 'LITERARY CRITICISM / Asian / General',
  LIT008010: 'Literary Criticism / Asian / Chinese',
  LIT008020: 'Literary Criticism / Asian / Indic',
  LIT008030: 'Literary Criticism / Asian / Japanese',
  LIT009000: 'Literary Criticism/ Children\u2019s & Young Adult Literature',
  LIT010000: 'Literary Criticism & Collections/ Letters',
  LIT011000: 'LITERARY CRITICISM / Medieval',
  LIT012000: 'Literary Criticism / Reference',
  LIT013000: 'Literary Criticism / Drama',
  LIT014000: 'Literary Criticism / Poetry',
  LIT015000: 'Literary Criticism / Shakespeare',
  LIT016000: 'Literary Criticism / Humor',
  LIT017000: 'Literary Criticism / Comics & Graphic Novels',
  LIT018000: 'Literary Criticism / Short Stories',
  LIT019000: 'Literary Criticism / Renaissance',
  LIT020000: 'Literary Criticism / Comparative Literature',
  LIT021000: 'Literary Criticism / Horror & Supernatural',
  LIT022000: 'Literary Criticism / Fairy Tales, Folk Tales, Legends & Mythology',
  LIT023000: 'Literary Criticism / American / Regional',
  LIT024000: 'Literary Criticism / Modern / General',
  LIT024010: 'Literary Criticism / Modern / 16th Century',
  LIT024020: 'Literary Criticism / Modern / 17th Century',
  LIT024030: 'Literary Criticism / Modern / 18th Century',
  LIT024040: 'Literary Criticism / Modern / 19th Century',
  LIT024050: 'Literary Criticism / Modern / 20th Century',
  LIT024060: 'Literary Criticism / Modern / 21st Century',
  LIT025000: 'Literary Criticism / Subjects & Themes / General',
  LIT025010: 'Literary Criticism / Subjects & Themes / Historical Events',
  LIT025020: 'Literary Criticism / Subjects & Themes / Nature',
  LIT025030: 'Literary Criticism / Subjects & Themes / Politics',
  LIT025040: 'Literary Criticism / Subjects & Themes / Religion',
  LIT025050: 'Literary Criticism / Subjects & Themes / Women',
  MAT000000: 'Mathematics / General',
  MAT001000: 'Mathematics / Advanced',
  MAT002000: 'Mathematics / Algebra / General',
  MAT002010: 'Mathematics / Algebra / Abstract',
  MAT002020: 'Mathematics/ Algebra / Boolean',
  MAT002030: 'Mathematics / Algebra / Elementary',
  MAT002040: 'Mathematics / Algebra / Intermediate',
  MAT002050: 'Mathematics / Algebra / Linear',
  MAT003000: 'Mathematics / Applied',
  MAT004000: 'Mathematics / Arithmetic',
  MAT005000: 'Mathematics / Calculus',
  MAT006000: 'Mathematics / Counting & Numeration',
  MAT007000: 'MATHEMATICS / Differential Equations / General',
  MAT007010: 'Mathematics / Differential Equations / Ordinary',
  MAT007020: 'Mathematics / Differential Equations / Partial',
  MAT008000: 'Mathematics / Discrete Mathematics',
  MAT009000: 'Mathematics / Finite Mathematics',
  MAT010000: 'Mathematics/ Fractions',
  MAT011000: 'Mathematics / Game Theory',
  MAT012000: 'Mathematics / Geometry / General',
  MAT012010: 'Mathematics / Geometry / Algebraic',
  MAT012020: 'Mathematics / Geometry / Analytic',
  MAT012030: 'Mathematics / Geometry / Differential',
  MAT012040: 'Mathematics / Geometry / Non-Euclidean',
  MAT013000: 'Mathematics / Graphic Methods',
  MAT014000: 'Mathematics / Group Theory',
  MAT015000: 'Mathematics / History & Philosophy',
  MAT016000: 'Mathematics / Infinity',
  MAT017000: 'Mathematics / Linear & Nonlinear Programming',
  MAT018000: 'Mathematics / Logic',
  MAT019000: 'Mathematics / Matrices',
  MAT020000: 'Mathematics / Measurement',
  MAT021000: 'Mathematics / Number Systems',
  MAT022000: 'Mathematics / Number Theory',
  MAT023000: 'Mathematics / Pre-Calculus',
  MAT024000: 'Mathematics/ Probability',
  MAT025000: 'Mathematics / Recreations & Games',
  MAT026000: 'Mathematics / Reference',
  MAT027000: 'Mathematics / Research',
  MAT028000: 'Mathematics / Set Theory',
  MAT029000: 'Mathematics / Probability & Statistics / General',
  MAT029010: 'Mathematics / Probability & Statistics / Bayesian Analysis',
  MAT029020: 'Mathematics / Probability & Statistics / Multivariate Analysis',
  MAT029030: 'Mathematics / Probability & Statistics / Regression Analysis',
  MAT029040: 'Mathematics / Probability & Statistics / Stochastic Processes',
  MAT029050: 'Mathematics / Probability & Statistics / Time Series',
  MAT030000: 'Mathematics / Study & Teaching',
  MAT031000: 'Mathematics / Transformations',
  MAT032000: 'Mathematics / Trigonometry',
  MAT033000: 'Mathematics / Vector Analysis',
  MAT034000: 'Mathematics / Mathematical Analysis',
  MAT035000: 'Mathematics/ Computer Mathematics',
  MAT036000: 'Mathematics / Combinatorics',
  MAT037000: 'Mathematics / Functional Analysis',
  MAT038000: 'Mathematics / Topology',
  MAT039000: 'Mathematics / Essays',
  MAT040000: 'Mathematics / Complex Analysis',
  MAT041000: 'Mathematics / Numerical Analysis',
  MAT042000: 'Mathematics / Optimization',
  MED000000: 'Medical / General',
  MED001000: 'Medical / Acupuncture',
  MED002000: 'Medical / Administration',
  MED003000: 'Medical / Allied Health Services / General',
  MED003010: 'Medical / Allied Health Services / Emergency Medical Services',
  MED003020: 'Medical / Allied Health Services / Hypnotherapy',
  MED003030: 'Medical / Allied Health Services / Medical Assistants',
  MED003040: 'Medical / Allied Health Services / Medical Technology',
  MED003050: 'Medical / Allied Health Services / Occupational Therapy',
  MED003060: 'Medical / Allied Health Services / Physical Therapy',
  MED003070: 'Medical / Allied Health Services / Imaging Technologies',
  MED003080: 'Medical / Allied Health Services / Respiratory Therapy',
  MED003090: 'Medical / Allied Health Services / Massage Therapy',
  MED003100: 'Medical / Allied Health Services / Medical Coding & Billing ',
  MED004000: 'Medical / Alternative & Complementary Medicine',
  MED005000: 'Medical / Anatomy',
  MED006000: 'Medical / Anesthesiology',
  MED007000: 'Medical / Audiology & Speech Pathology',
  MED008000: 'Medical / Biochemistry',
  MED009000: 'Medical / Biotechnology',
  MED010000: 'Medical / Cardiology',
  MED011000: 'Medical / Caregiving',
  MED012000: 'Medical / Chemotherapy',
  MED013000: 'Medical / Chiropractic',
  MED014000: 'Medical / Clinical Medicine',
  MED015000: 'Medical / Critical Care',
  MED016000: 'Medical / Dentistry / General',
  MED016010: 'Medical / Dentistry / Dental Assisting',
  MED016020: 'Medical / Dentistry / Dental Hygiene',
  MED016030: 'Medical / Dentistry / Orthodontics',
  MED016040: 'Medical / Dentistry / Periodontics',
  MED016050: 'Medical / Dentistry / Oral Surgery',
  MED016060: 'Medical / Dentistry / Endodontics',
  MED016070: 'Medical / Dentistry / Prosthodontics',
  MED016080: 'Medical / Dentistry / Dental Implants',
  MED016090: 'Medical / Dentistry / Practice Management',
  MED017000: 'Medical / Dermatology',
  MED018000: 'Medical / Diagnosis',
  MED019000: 'Medical / Diagnostic Imaging / General',
  MED019010: 'Medical / Diagnostic Imaging / Radiography',
  MED020000: 'Medical / Dictionaries & Terminology',
  MED021000: 'Medical / Diet Therapy',
  MED022000: 'Medical / Diseases',
  MED022010: 'Medical/ Diseases / Abdominal',
  MED022020: 'Medical / AIDS & HIV',
  MED022030: 'Medical/ Diseases / Bacterial',
  MED022040: 'Medical/ Diseases / Cancer',
  MED022050: 'Medical/ Diseases / Cardiovascular',
  MED022060: 'Medical/ Diseases / Cardiopulmonary',
  MED022070: 'Medical/ Diseases / Brain',
  MED022080: 'Medical/ Diseases / Chronic',
  MED022090: 'Medical / Infectious Diseases',
  MED022100: 'Medical/ Diseases / Cutaneous',
  MED022110: 'Medical/ Diseases / Digestive Organs',
  MED022120: 'Medical/ Diseases / Endocrine Glands',
  MED022130: 'Medical/ Diseases / Extremities',
  MED022140: 'Medical/ Diseases / Gastrointestinal',
  MED022150: 'Medical/ Diseases / Genitourinary',
  MED022160: 'Medical/ Diseases / Immunological',
  MED022170: 'Medical/ Diseases / Viral',
  MED022180: 'Medical/ Diseases / Diabetes',
  MED022190: 'Medical/ Diseases / Genetic',
  MED022200: 'Medical/ Diseases / Neuromuscular',
  MED022210: 'Medical/ Diseases / Nutritional',
  MED022220: 'Medical/ Diseases / Respiratory',
  MED023000: 'Medical / Drug Guides',
  MED024000: 'Medical / Education & Training',
  MED025000: 'Medical / Embryology',
  MED026000: 'Medical / Emergency Medicine',
  MED027000: 'Medical / Endocrinology & Metabolism',
  MED028000: 'Medical / Epidemiology',
  MED029000: 'Medical / Family & General Practice',
  MED030000: 'Medical / Forensic Medicine',
  MED031000: 'Medical / Gastroenterology',
  MED032000: 'Medical / Geriatrics',
  MED033000: 'Medical / Gynecology & Obstetrics',
  MED034000: 'Medical / Healing',
  MED035000: 'Medical / Health Care Delivery',
  MED036000: 'Medical / Health Policy',
  MED037000: 'Medical / Health Risk Assessment',
  MED038000: 'Medical / Hematology',
  MED039000: 'Medical / History',
  MED040000: 'Medical / Holistic Medicine',
  MED041000: 'Medical / Home Care',
  MED042000: 'Medical / Terminal Care',
  MED043000: 'Medical / Hospital Administration & Care',
  MED044000: 'Medical / Immunology',
  MED045000: 'Medical / Internal Medicine',
  MED046000: 'Medical/ Iridology',
  MED047000: 'Medical / Laboratory Medicine',
  MED048000: 'Medical / Lasers in Medicine',
  MED049000: 'Medical / Medicaid & Medicare',
  MED050000: 'Medical / Ethics',
  MED051000: 'Medical / Medical History & Records',
  MED052000: 'Medical / Microbiology',
  MED053000: 'Medical/ Mind-Body Medicine (Psychoneuroimmunology)',
  MED054000: 'Medical/ Naturopathy',
  MED055000: 'Medical / Nephrology',
  MED056000: 'Medical / Neurology',
  MED057000: 'Medical / Neuroscience',
  MED058000: 'Medical / Nursing / General',
  MED058010: 'Medical / Nursing / Anesthesia',
  MED058020: 'Medical / Nursing / Assessment & Diagnosis',
  MED058030: 'Medical / Nursing / Critical & Intensive Care',
  MED058040: 'Medical / Nursing / Emergency',
  MED058050: 'Medical / Nursing / Fundamentals & Skills',
  MED058060: 'Medical / Nursing / Gerontology',
  MED058070: 'Medical / Nursing / Home & Community Care',
  MED058080: 'Medical / Nursing / Pediatric & Neonatal',
  MED058090: 'Medical / Nursing / Social, Ethical & Legal Issues',
  MED058100: 'Medical / Nursing / LPN & LVN',
  MED058110: 'Medical / Nursing / Management & Leadership',
  MED058120: 'Medical / Nursing / Maternity, Perinatal, Women\u2019s Health',
  MED058130: 'Medical / Nursing / Mental Health',
  MED058140: 'Medical / Nursing / Nurse & Patient',
  MED058150: 'Medical / Nursing / Nutrition',
  MED058160: 'Medical / Nursing / Oncology & Cancer',
  MED058170: 'Medical / Nursing / Pharmacology',
  MED058180: 'Medical / Nursing / Psychiatric & Mental Health',
  MED058190: 'Medical / Nursing / Reference',
  MED058200: 'Medical / Nursing / Research & Theory',
  MED058210: 'Medical/ Nursing / Test Preparation & Review ',
  MED058220: 'Medical / Nursing / Medical & Surgical',
  MED058230: 'Medical / Nursing / Palliative Care ',
  MED059000: 'Medical / Nursing Home Care',
  MED060000: 'Medical / Nutrition',
  MED061000: 'Medical / Occupational & Industrial Medicine',
  MED062000: 'MEDICAL / Oncology / General',
  MED063000: 'Medical / Ophthalmology',
  MED064000: 'Medical / Optometry',
  MED065000: 'Medical / Orthopedics',
  MED066000: 'MEDICAL / Otolaryngology',
  MED067000: 'Medical / Pathology',
  MED068000: 'Medical / Pathophysiology',
  MED069000: 'Medical / Pediatrics',
  MED070000: 'Medical / Perinatology & Neonatology',
  MED071000: 'Medical / Pharmacology',
  MED072000: 'Medical / Pharmacy',
  MED073000: 'Medical / Physical Medicine & Rehabilitation',
  MED074000: 'Medical / Physician & Patient',
  MED075000: 'Medical / Physiology',
  MED076000: 'Medical / Preventive Medicine',
  MED077000: 'Medical / Prosthesis',
  MED078000: 'Medical / Public Health',
  MED079000: 'Medical / Pulmonary & Thoracic Medicine',
  MED080000: 'Medical / Radiology, Radiotherapy & Nuclear Medicine',
  MED081000: 'Medical / Reference',
  MED082000: 'Medical / Reproductive Medicine & Technology',
  MED083000: 'Medical / Rheumatology',
  MED084000: 'Medical / Sports Medicine',
  MED085000: 'Medical / Surgery / General',
  MED085010: 'Medical / Surgery / Neurosurgery',
  MED085020: 'Medical / Surgery / Oral & Maxillofacial',
  MED085030: 'MEDICAL / Surgery / Cosmetic & Reconstructive',
  MED085040: 'MEDICAL / Surgery / Cardiothoracic',
  MED085050: 'Medical / Surgery / Vascular',
  MED085060: 'Medical / Surgery / Colon & Rectal',
  MED085070: 'Medical / Surgery / Transplant',
  MED085080: 'Medical / Surgery / Laparoscopic & Robotic',
  MED086000: 'Medical / Test Preparation & Review',
  MED087000: 'Medical / Transportation',
  MED088000: 'Medical / Urology',
  MED089000: 'Medical / Veterinary Medicine / General',
  MED089010: 'Medical / Veterinary Medicine / Equine',
  MED089020: 'Medical / Veterinary Medicine / Food Animal',
  MED089030: 'Medical / Veterinary Medicine / Small Animal',
  MED090000: 'Medical / Biostatistics',
  MED091000: 'Medical / Nosology',
  MED092000: 'Medical / Osteopathy',
  MED093000: 'Medical/ Pain Management',
  MED094000: 'Medical / Pediatric Emergencies',
  MED095000: 'Medical / Practice Management & Reimbursement',
  MED096000: 'Medical / Toxicology',
  MED097000: 'Medical / Tropical Medicine',
  MED098000: 'Medical / Diagnostic Imaging / Ultrasonography',
  MED099000: 'Medical/ Vascular Medicine',
  MED100000: 'Medical / Podiatry',
  MED101000: 'Medical / Atlases',
  MED102000: 'Medical / Mental Health',
  MED103000: 'Medical / Parasitology',
  MED104000: 'Medical / Physicians',
  MED105000: 'Medical / Psychiatry / General',
  MED105010: 'Medical / Psychiatry / Child & Adolescent',
  MED105020: 'Medical / Psychiatry / Psychopharmacology',
  MED106000: 'Medical / Research',
  MED107000: 'Medical / Genetics',
  MED108000: 'Medical / Instruments & Supplies',
  MED109000: 'Medical / Essays',
  MED110000: 'Medical / Histology',
  MED111000: 'Medical / Bariatrics',
  MED112000: 'Medical / Evidence-Based Medicine',
  MED113000: 'Medical / Long-Term Care',
  MED114000: 'Medical / Hepatology',
  MED115000: 'Medical / Infection Control',
  MUS000000: 'Music / General',
  MUS001000: 'Music / Instruction & Study / Appreciation',
  MUS002000: 'Music / Genres & Styles / Ballet',
  MUS003000: 'Music / Genres & Styles / Blues',
  MUS004000: 'Music / Business Aspects',
  MUS005000: 'Music / Genres & Styles / Chamber',
  MUS006000: 'Music / Genres & Styles / Classical',
  MUS007000: 'Music / Instruction & Study / Composition',
  MUS008000: 'Music / Instruction & Study / Conducting',
  MUS009000: 'Music / Religious / Contemporary Christian',
  MUS010000: 'Music / Genres & Styles / Country & Bluegrass',
  MUS011000: 'Music / Genres & Styles / Dance',
  MUS012000: 'Music / Discography & Buyer\u2019s Guides',
  MUS013000: 'Music / Genres & Styles / Electronic',
  MUS014000: 'Music / Ethnic',
  MUS015000: 'Music / Ethnomusicology',
  MUS016000: 'Music / Instruction & Study / Exercises',
  MUS017000: 'Music / Genres & Styles / Folk & Traditional',
  MUS018000: 'Music / Religious / Gospel',
  MUS019000: 'Music / Genres & Styles / Heavy Metal',
  MUS020000: 'Music / History & Criticism',
  MUS021000: 'Music / Religious / Hymns',
  MUS022000: 'Music / Instruction & Study / General',
  MUS023000: 'Music / Musical Instruments / General',
  MUS023010: 'Music / Musical Instruments / Brass',
  MUS023020: 'Music / Musical Instruments / Percussion',
  MUS023030: 'Music / Musical Instruments / Piano & Keyboard',
  MUS023040: 'Music / Musical Instruments / Strings',
  MUS023050: 'Music / Musical Instruments / Woodwinds',
  MUS023060: 'Music / Musical Instruments / Guitar',
  MUS024000: 'Music / Genres & Styles / International',
  MUS025000: 'Music / Genres & Styles / Jazz',
  MUS026000: 'Music / Genres & Styles / Children\u2019s',
  MUS027000: 'Music / Genres & Styles / New Age',
  MUS028000: 'Music / Genres & Styles / Opera',
  MUS029000: 'MUSIC / Genres & Styles / Pop Vocal',
  MUS030000: 'Music / Genres & Styles / Punk',
  MUS031000: 'Music / Genres & Styles / Rap & Hip Hop',
  MUS032000: 'Music / Recording & Reproduction',
  MUS033000: 'Music / Reference',
  MUS034000: 'Music/ Rhythm & Blues',
  MUS035000: 'Music / Genres & Styles / Rock',
  MUS036000: 'Music / Genres & Styles / Latin',
  MUS037000: 'Music / Printed Music / General',
  MUS037010: 'Music / Printed Music / Artist Specific',
  MUS037020: 'Music / Printed Music / Band & Orchestra',
  MUS037030: 'Music / Printed Music / Choral',
  MUS037040: 'Music / Printed Music / Guitar & Fretted Instruments',
  MUS037050: 'Music / Printed Music / Mixed Collections',
  MUS037060: 'Music / Printed Music / Musicals, Film & TV',
  MUS037070: 'Music / Printed Music / Opera & Classical Scores',
  MUS037080: 'Music / Printed Music / Percussion',
  MUS037090: 'Music / Printed Music / Piano & Keyboard Repertoire',
  MUS037100: 'Music / Printed Music / Piano-Vocal-Guitar',
  MUS037110: 'Music / Printed Music / Vocal',
  MUS037120: 'Music / Printed Music / Brass',
  MUS037130: 'Music / Printed Music / Strings',
  MUS037140: 'Music / Printed Music / Woodwinds',
  MUS038000: 'Music / Instruction & Study / Songwriting',
  MUS039000: 'Music / Genres & Styles / Soul & R \u2019n B',
  MUS040000: 'Music / Instruction & Study / Techniques',
  MUS041000: 'Music / Instruction & Study / Theory',
  MUS042000: 'Music / Instruction & Study / Voice',
  MUS043000: 'Music/ World Beat',
  MUS044000: 'Music/ Bluegrass',
  MUS045000: 'Music / Genres & Styles / Military & Marches',
  MUS046000: 'Music / Genres & Styles / Musicals',
  MUS047000: 'Music / Genres & Styles / Reggae',
  MUS048000: 'Music / Religious / General',
  MUS048010: 'Music / Religious / Christian',
  MUS048020: 'Music / Religious / Jewish',
  MUS048030: 'Music / Religious / Muslim',
  MUS049000: 'Music / Genres & Styles / General',
  MUS050000: 'Music / Individual Composer & Musician',
  MUS051000: 'Music / Genres & Styles / Choral',
  MUS052000: 'Music / Lyrics',
  MUS053000: 'Music / Genres & Styles / Big Band & Swing',
  MUS054000: 'Music / Philosophy & Social Aspects ',
  NAT000000: 'Nature / General',
  NAT001000: 'Nature / Animals / General',
  NAT002000: 'Nature / Animals / Primates',
  NAT003000: 'Nature / Animals / Bears',
  NAT004000: 'Nature/ Birdwatching Guides',
  NAT005000: 'Nature / Animals / Butterflies & Moths',
  NAT006000: 'Nature/ Cats',
  NAT007000: 'Nature / Animals / Dinosaurs & Prehistoric Creatures',
  NAT008000: 'Nature / Dogs',
  NAT009000: 'Nature / Earthquakes & Volcanoes',
  NAT010000: 'Nature / Ecology',
  NAT011000: 'Nature / Environmental Conservation & Protection',
  NAT012000: 'Nature / Animals / Fish',
  NAT013000: 'Nature / Plants / Flowers',
  NAT014000: 'Nature / Ecosystems & Habitats / Forests & Rainforests',
  NAT015000: 'Nature / Fossils',
  NAT016000: 'Nature / Animals / Horses',
  NAT017000: 'Nature / Animals / Insects & Spiders',
  NAT018000: 'Nature / Ecosystems & Habitats / Lakes, Ponds & Swamps',
  NAT019000: 'Nature / Animals / Mammals',
  NAT020000: 'Nature / Animals / Marine Life',
  NAT021000: 'Nature/ Mice',
  NAT022000: 'Nature / Plants / Mushrooms',
  NAT023000: 'Nature / Natural Disasters',
  NAT024000: 'Nature / Essays',
  NAT025000: 'Nature / Ecosystems & Habitats / Oceans & Seas',
  NAT026000: 'Nature / Plants / General',
  NAT027000: 'Nature / Reference',
  NAT028000: 'Nature / Animals / Reptiles & Amphibians',
  NAT029000: 'Nature / Ecosystems & Habitats / Rivers',
  NAT030000: 'Nature / Rocks & Minerals',
  NAT031000: 'Nature / Seashells',
  NAT032000: 'Nature / Seasons',
  NAT033000: 'Nature / Sky Observation',
  NAT034000: 'Nature/ Plants / Trees',
  NAT035000: 'Nature/ Volcanoes',
  NAT036000: 'Nature / Weather',
  NAT037000: 'Nature / Animals / Wildlife',
  NAT038000: 'Nature / Natural Resources',
  NAT039000: 'Nature / Animal Rights',
  NAT040000: 'Nature / Water Supply',
  NAT041000: 'Nature / Ecosystems & Habitats / Mountains',
  NAT042000: 'Nature / Animals / Big Cats',
  NAT043000: 'Nature / Animals / Birds',
  NAT044000: 'Nature / Animals / Wolves',
  NAT045000: 'Nature / Ecosystems & Habitats / General',
  NAT045010: 'Nature / Ecosystems & Habitats / Deserts',
  NAT045020: 'Nature / Ecosystems & Habitats / Plains & Prairies',
  NAT045030: 'Nature / Ecosystems & Habitats / Polar Regions',
  NAT045040: 'Nature / Ecosystems & Habitats / Wilderness',
  NAT045050: 'Nature / Ecosystems & Habitats / Coastal Regions & Shorelines',
  NAT046000: 'Nature / Endangered Species',
  NAT047000: 'Nature / Plants / Aquatic',
  NAT048000: 'Nature / Plants / Cacti & Succulents',
  NAT049000: 'Nature / Regional',
  NON000000: 'Non-Classifiable',
  OCC000000: 'Body, Mind & Spirit / General',
  OCC001000: 'Body, Mind & Spirit/ Analysis',
  OCC002000: 'Body, Mind & Spirit / Astrology / General',
  OCC003000: 'Body, Mind & Spirit / Channeling & Mediumship',
  OCC004000: 'Body, Mind & Spirit / Crystals',
  OCC005000: 'Body, Mind & Spirit / Divination / General',
  OCC006000: 'Body, Mind & Spirit / Dreams',
  OCC007000: 'Body, Mind & Spirit / Parapsychology / ESP (Clairvoyance, Precognition, Telepathy)',
  OCC008000: 'Body, Mind & Spirit / Divination / Fortune Telling',
  OCC009000: 'Body, Mind & Spirit / Astrology / Horoscopes',
  OCC010000: 'Body, Mind & Spirit / Mindfulness & Meditation',
  OCC011000: 'Body, Mind & Spirit / Healing / General',
  OCC011010: 'Body, Mind & Spirit / Healing / Energy (Qigong, Reiki, Polarity)',
  OCC011020: 'Body, Mind & Spirit / Healing / Prayer & Spiritual',
  OCC012000: 'Body, Mind & Spirit / Mysticism',
  OCC013000: 'Body, Mind & Spirit/ New Age',
  OCC014000: 'Body, Mind & Spirit / New Thought',
  OCC015000: 'Body, Mind & Spirit / Numerology',
  OCC016000: 'Body, Mind & Spirit / Occultism',
  OCC017000: 'Body, Mind & Spirit / Divination / Palmistry',
  OCC018000: 'Body, Mind & Spirit / Parapsychology / General',
  OCC019000: 'Body, Mind & Spirit / Inspiration & Personal Growth',
  OCC020000: 'Body, Mind & Spirit / Prophecy',
  OCC021000: 'Body, Mind & Spirit / Reference',
  OCC022000: 'Body, Mind & Spirit / Afterlife & Reincarnation',
  OCC023000: 'Body, Mind & Spirit / Supernatural',
  OCC024000: 'Body, Mind & Spirit / Divination / Tarot',
  OCC025000: 'Body, Mind & Spirit / UFOs & Extraterrestrials',
  OCC026000: 'BODY, MIND & SPIRIT / Witchcraft',
  OCC027000: 'Body, Mind & Spirit / Spiritualism',
  OCC028000: 'Body, Mind & Spirit / Magick Studies',
  OCC029000: 'Body, Mind & Spirit / Unexplained Phenomena',
  OCC030000: 'Body, Mind & Spirit / Astrology / Eastern',
  OCC031000: 'Body, Mind & Spirit / Ancient Mysteries & Controversial Knowledge',
  OCC032000: 'Body, Mind & Spirit / Angels & Spirit Guides',
  OCC033000: 'Body, Mind & Spirit / Gaia & Earth Energies',
  OCC034000: 'Body, Mind & Spirit / Parapsychology / Near-Death Experience',
  OCC035000: 'Body, Mind & Spirit / Parapsychology / Out-of-Body Experience',
  OCC036000: 'Body, Mind & Spirit / Spirituality / General',
  OCC036010: 'Body, Mind & Spirit / Celtic Spirituality',
  OCC036020: 'Body, Mind & Spirit / Spirituality / Paganism & Neo-Paganism',
  OCC036030: 'Body, Mind & Spirit / Shamanism',
  OCC036040: 'Body, Mind & Spirit / Spirituality / Greco-Roman',
  OCC036050: 'Body, Mind & Spirit / Goddess Worship',
  OCC037000: 'Body, Mind & Spirit / Feng Shui',
  OCC038000: 'Body, Mind & Spirit / I Ching',
  OCC039000: 'Body, Mind & Spirit / Entheogens & Visionary Substances',
  OCC040000: 'Body, Mind & Spirit / Hermetism & Rosicrucianism',
  OCC041000: 'Body, Mind & Spirit / Sacred Sexuality',
  PER000000: 'Performing Arts / General',
  PER001000: 'Performing Arts / Acting & Auditioning',
  PER002000: 'Performing Arts / Circus',
  PER003000: 'Performing Arts / Dance / General',
  PER003010: 'Performing Arts / Dance / Classical & Ballet',
  PER003020: 'Performing Arts / Dance / Folk',
  PER003030: 'Performing Arts / Dance / Jazz',
  PER003040: 'Performing Arts / Dance / Modern',
  PER003050: 'Performing Arts / Dance / Choreography & Dance Notation',
  PER003060: 'Performing Arts / Dance / Popular',
  PER003070: 'Performing Arts / Dance / Reference',
  PER003080: 'Performing Arts / Dance / Tap',
  PER003090: 'Performing Arts / Dance / Ballroom',
  PER003100: 'Performing Arts / Dance / History & Criticism',
  PER004000: 'Performing Arts / Film / General',
  PER004010: 'Performing Arts / Film / Direction & Production',
  PER004020: 'Performing Arts / Film / Guides & Reviews',
  PER004030: 'Performing Arts / Film / History & Criticism',
  PER004040: 'Performing Arts / Film / Reference',
  PER004050: 'Performing Arts / Film / Screenwriting',
  PER005000: 'Performing Arts/ Mass Media',
  PER006000: 'Performing Arts / Theater / Miming',
  PER007000: 'Performing Arts / Puppets & Puppetry',
  PER008000: 'Performing Arts / Radio / General',
  PER008010: 'Performing Arts / Radio / History & Criticism',
  PER008020: 'Performing Arts / Radio / Reference',
  PER009000: 'Performing Arts / Reference',
  PER010000: 'Performing Arts / Television / General',
  PER010010: 'Performing Arts / Television / Direction & Production',
  PER010020: 'Performing Arts / Television / Guides & Reviews',
  PER010030: 'Performing Arts / Television / History & Criticism',
  PER010040: 'Performing Arts / Television / Reference',
  PER010050: 'Performing Arts / Television / Screenwriting',
  PER011000: 'Performing Arts / Theater / General',
  PER011010: 'Performing Arts / Theater / Direction & Production',
  PER011020: 'Performing Arts / Theater / History & Criticism',
  PER011030: 'Performing Arts / Theater / Playwriting',
  PER011040: 'Performing Arts / Theater / Stagecraft & Scenography',
  PER012000: 'Performing Arts/ Video / General',
  PER012010: 'Performing Arts/ Video / Direction & Production',
  PER012020: 'Performing Arts/ Video / Guides & Reviews',
  PER012030: 'Performing Arts/ Video / Reference',
  PER013000: 'Performing Arts / Theater / Broadway & Musicals',
  PER014000: 'Performing Arts / Business Aspects',
  PER015000: 'Performing Arts / Comedy',
  PER016000: 'Performing Arts / Screenplays',
  PER017000: 'Performing Arts / Animation ',
  PER018000: 'Performing Arts / Individual Director ',
  PER019000: 'Performing Arts / Storytelling',
  PER020000: 'Performing Arts / Monologues & Scenes',
  PER021000: 'Performing Arts / Dance / Regional & Ethnic ',
  PET000000: 'Pets / General',
  PET001000: 'Pets/ Aquarium',
  PET002000: 'Pets / Birds',
  PET003000: 'Pets / Cats / General',
  PET003010: 'Pets / Cats / Breeds',
  PET004000: 'Pets / Dogs / General',
  PET004010: 'Pets / Dogs / Breeds',
  PET004020: 'Pets / Dogs / Training',
  PET005000: 'Pets / Fish & Aquariums',
  PET006000: 'Pets / Horses',
  PET006010: 'Pets / Horses / Riding',
  PET007000: 'Pets/ Pigs',
  PET008000: 'Pets / Reference',
  PET009000: 'Pets / Reptiles, Amphibians & Terrariums',
  PET010000: 'Pets / Essays & Narratives',
  PET011000: 'Pets / Rabbits, Mice, Hamsters, Guinea Pigs, etc.',
  PET012000: 'Pets / Food & Nutrition',
  PET013000: 'Pets / Insects & Spiders ',
  PHI000000: 'Philosophy / General',
  PHI001000: 'Philosophy / Aesthetics',
  PHI002000: 'Philosophy / History & Surveys / Ancient & Classical',
  PHI003000: 'Philosophy / Eastern',
  PHI004000: 'Philosophy / Epistemology',
  PHI005000: 'Philosophy / Ethics & Moral Philosophy',
  PHI006000: 'Philosophy / Movements / Existentialism',
  PHI007000: 'Philosophy / Free Will & Determinism',
  PHI008000: 'Philosophy / Good & Evil',
  PHI009000: 'PHILOSOPHY / History & Surveys / General',
  PHI010000: 'Philosophy / Movements / Humanism',
  PHI011000: 'Philosophy / Logic',
  PHI012000: 'Philosophy / History & Surveys / Medieval',
  PHI013000: 'Philosophy / Metaphysics',
  PHI014000: 'Philosophy / Methodology',
  PHI015000: 'Philosophy / Mind & Body',
  PHI016000: 'Philosophy / History & Surveys / Modern',
  PHI017000: 'Philosophy/ Mysticism',
  PHI018000: 'Philosophy / Movements / Phenomenology',
  PHI019000: 'Philosophy / Political',
  PHI020000: 'Philosophy / Movements / Pragmatism',
  PHI021000: 'Philosophy / Reference',
  PHI022000: 'Philosophy / Religious',
  PHI023000: 'Philosophy / Taoist',
  PHI024000: 'Philosophy/ Western',
  PHI025000: 'Philosophy / Zen',
  PHI026000: 'Philosophy / Criticism',
  PHI027000: 'Philosophy / Movements / Deconstruction',
  PHI028000: 'Philosophy / Buddhist',
  PHI029000: 'Philosophy / Movements / Structuralism',
  PHI030000: 'Philosophy / Movements / Utilitarianism',
  PHI031000: 'Philosophy / Movements / General',
  PHI032000: 'Philosophy / Movements / Rationalism',
  PHI033000: 'Philosophy / Hindu',
  PHI034000: 'Philosophy / Social',
  PHI035000: 'Philosophy / Essays',
  PHI036000: 'Philosophy / Hermeneutics',
  PHI037000: 'Philosophy / History & Surveys / Renaissance',
  PHI038000: 'Philosophy / Language',
  PHI039000: 'Philosophy / Movements / Analytic',
  PHI040000: 'Philosophy / Movements / Critical Theory',
  PHI041000: 'Philosophy / Movements / Empiricism',
  PHI042000: 'Philosophy / Movements / Idealism',
  PHI043000: 'Philosophy / Movements / Post-Structuralism',
  PHI044000: 'Philosophy / Movements / Realism',
  PHI045000: 'Philosophy / Movements / Transcendentalism',
  PHI046000: 'Philosophy / Individual Philosophers ',
  PHO000000: 'Photography / General',
  PHO001000: 'Photography / Subjects & Themes / Architectural & Industrial',
  PHO002000: 'Photography/ Camera Specific',
  PHO003000: 'Photography / Business Aspects',
  PHO004000: 'Photography / Collections, Catalogs, Exhibitions / General',
  PHO004010: 'Photography / Collections, Catalogs, Exhibitions / Group Shows',
  PHO004020: 'Photography / Collections, Catalogs, Exhibitions / Permanent Collections',
  PHO005000: 'Photography / Criticism',
  PHO006000: 'Photography / Techniques / Darkroom',
  PHO007000: 'Photography / Techniques / Equipment',
  PHO008000: 'Photography/ Essays',
  PHO009000: 'Photography / Subjects & Themes / Fashion',
  PHO010000: 'Photography / History',
  PHO011000: 'Photography / Individual Photographers / General',
  PHO011010: 'Photography / Individual Photographers / Artists\u2019 Books',
  PHO011020: 'Photography / Individual Photographers / Essays',
  PHO011030: 'Photography / Individual Photographers / Monographs',
  PHO012000: 'Photography / Techniques / Lighting',
  PHO013000: 'Photography / Subjects & Themes / Plants & Animals',
  PHO014000: 'Photography / Photoessays & Documentaries',
  PHO015000: 'Photography / Photojournalism',
  PHO016000: 'Photography / Subjects & Themes / Portraits & Selfies',
  PHO017000: 'Photography / Reference',
  PHO018000: 'Photography / Techniques / General',
  PHO019000: 'PHOTOGRAPHY / Subjects & Themes / Regional',
  PHO020000: 'Photography / Techniques / Color',
  PHO021000: 'Photography / Commercial',
  PHO022000: 'Photography / Techniques / Cinematography & Videography',
  PHO023000: 'Photography / Subjects & Themes / General',
  PHO023010: 'Photography / Subjects & Themes / Aerial',
  PHO023020: 'Photography / Subjects & Themes / Children',
  PHO023030: 'Photography / Subjects & Themes / Erotica',
  PHO023040: 'Photography / Subjects & Themes / Landscapes',
  PHO023050: 'Photography / Subjects & Themes / Nudes',
  PHO023060: 'Photography / Subjects & Themes / Sports',
  PHO023070: 'Photography / Subjects & Themes / Celebrations & Events',
  PHO023080: 'Photography / Subjects & Themes / Celebrity',
  PHO023090: 'Photography / Subjects & Themes / Lifestyles',
  PHO023100: 'Photography / Subjects & Themes / Historical',
  PHO023110: 'Photography / Subjects & Themes / Food ',
  PHO023120: 'Photography / Subjects & Themes / Street Photography ',
  PHO023130: 'Photography / Subjects & Themes / Underwater ',
  PHO024000: 'Photography / Techniques / Digital ',
  PHO025000: 'Photography / Annuals',
  PHO026000: 'Photography / Astrophotography ',
  PHO027000: 'Photography / Forensic ',
  POE000000: 'Poetry / General',
  POE001000: 'Poetry / Anthologies (multiple authors)',
  POE002000: 'Poetry/ History & Criticism',
  POE003000: 'Poetry / Subjects & Themes / Inspirational & Religious',
  POE004000: 'Poetry/ Nursery Rhymes',
  POE005000: 'Poetry/ Single Author / General',
  POE005010: 'POETRY / American / General',
  POE005020: 'Poetry / European / English, Irish, Scottish, Welsh',
  POE005030: 'Poetry / European / General',
  POE005040: 'Poetry/ Single Author / Other',
  POE005050: 'Poetry/ American / African American & Black',
  POE005060: 'Poetry / American / Asian American',
  POE005070: 'Poetry/ American / Hispanic & Latino',
  POE006000: 'Poetry/ Reference',
  POE007000: 'Poetry / African',
  POE008000: 'Poetry/ Ancient & Classical',
  POE009000: 'Poetry / Asian / General',
  POE009010: 'Poetry / Asian / Chinese',
  POE009020: 'Poetry / Asian / Japanese',
  POE010000: 'Poetry / Australian & Oceanian',
  POE011000: 'Poetry / Canadian',
  POE012000: 'Poetry / Caribbean & Latin American',
  POE013000: 'Poetry / Middle Eastern',
  POE014000: 'Poetry / Epic',
  POE015000: 'Poetry / Native American',
  POE016000: 'Poetry / Russian & Former Soviet Union',
  POE017000: 'Poetry / European / French',
  POE018000: 'Poetry / European / German',
  POE019000: 'Poetry / European / Italian',
  POE020000: 'Poetry / European / Spanish & Portuguese',
  POE021000: 'Poetry / LGBTQ+',
  POE022000: 'Poetry / Medieval',
  POE023000: 'Poetry / Subjects & Themes / General',
  POE023010: 'Poetry / Subjects & Themes / Death, Grief, Loss',
  POE023020: 'Poetry / Subjects & Themes / Love & Erotica',
  POE023030: 'Poetry / Subjects & Themes / Nature',
  POE023040: 'Poetry / Subjects & Themes / Places',
  POE023050: 'Poetry / Subjects & Themes / Family',
  POE024000: 'Poetry / Women Authors',
  POE025000: 'Poetry / Haiku ',
  POL000000: 'Political Science / General',
  POL001000: 'Political Science / International Relations / Arms Control',
  POL002000: 'Political Science / Public Policy / City Planning & Urban Development',
  POL003000: 'Political Science / Civics & Citizenship',
  POL004000: 'Political Science / Civil Rights',
  POL005000: 'Political Science / Political Ideologies / Communism, Post-Communism & Socialism',
  POL006000: 'POLITICAL SCIENCE / American Government / Legislative Branch',
  POL007000: 'Political Science / Political Ideologies / Democracy',
  POL008000: 'Political Science / Political Process / Campaigns & Elections',
  POL009000: 'Political Science / Comparative Politics',
  POL010000: 'Political Science / History & Theory',
  POL011000: 'Political Science / International Relations / General',
  POL011010: 'Political Science / International Relations / Diplomacy',
  POL011020: 'Political Science / International Relations / Trade & Tariffs',
  POL012000: 'Political Science / Security (National & International)',
  POL013000: 'Political Science / Labor & Industrial Relations',
  POL014000: 'Political Science / Law Enforcement',
  POL015000: 'Political Science / Political Process / Political Parties',
  POL016000: 'Political Science / Political Process / General',
  POL017000: 'Political Science / Public Affairs & Administration',
  POL018000: 'Political Science / Reference',
  POL019000: 'Political Science / Public Policy / Social Services & Welfare',
  POL020000: 'Political Science/ American Government / State',
  POL021000: 'Political Science / International Relations / Treaties',
  POL022000: 'Political Science / Constitutions',
  POL023000: 'Political Science / Political Economy',
  POL024000: 'Political Science / Public Policy / Economic Policy',
  POL025000: 'Political Science / Political Process / Leadership',
  POL026000: 'Political Science / Public Policy / Regional Planning',
  POL027000: 'Political Science / Public Policy / Social Security',
  POL028000: 'Political Science / Public Policy / General',
  POL029000: 'Political Science / Public Policy / Social Policy',
  POL030000: 'POLITICAL SCIENCE / American Government / National',
  POL031000: 'Political Science / Political Ideologies / Nationalism & Patriotism',
  POL032000: 'Political Science / Essays',
  POL033000: 'Political Science / Globalization',
  POL034000: 'Political Science / Peace',
  POL035000: 'Political Science/ Political Freedom',
  POL035010: 'Political Science / Human Rights',
  POL036000: 'Political Science / Intelligence & Espionage',
  POL037000: 'Political Science / Terrorism',
  POL038000: 'Political Science / Public Policy / Cultural Policy',
  POL039000: 'Political Science / Censorship',
  POL040000: 'Political Science/ American Government / General',
  POL040010: 'POLITICAL SCIENCE / American Government / Executive Branch',
  POL040020: 'Political Science / World / General',
  POL040030: 'POLITICAL SCIENCE / American Government / Judicial Branch',
  POL040040: 'POLITICAL SCIENCE / American Government / Local',
  POL041000: 'Political Science / NGOs (Non-Governmental Organizations)',
  POL042000: 'Political Science / Political Ideologies / General',
  POL042010: 'Political Science / Political Ideologies / Anarchism',
  POL042020: 'Political Science / Political Ideologies / Conservatism & Liberalism',
  POL042030: 'Political Science / Political Ideologies / Fascism & Totalitarianism',
  POL042040: 'Political Science / Political Ideologies / Radicalism',
  POL042050: 'Political Science / Political Ideologies / Libertarianism',
  POL043000: 'Political Science / Political Process / Political Advocacy',
  POL044000: 'Political Science / Public Policy / Environmental Policy',
  POL045000: 'Political Science / Colonialism & Post-Colonialism',
  POL046000: 'Political Science / Commentary & Opinion',
  POL047000: 'Political Science / Imperialism',
  POL048000: 'Political Science / Intergovernmental Organizations',
  POL049000: 'Political Science / Propaganda',
  POL050000: 'Political Science / Public Policy / Communication Policy',
  POL051000: 'Political Science / Utopias',
  POL052000: 'Political Science / Women in Politics',
  POL053000: 'Political Science / World / African',
  POL054000: 'Political Science / World / Asian',
  POL055000: 'Political Science / World / Australian & Oceanian',
  POL056000: 'Political Science / World / Canadian',
  POL057000: 'Political Science / World / Caribbean & Latin American',
  POL058000: 'Political Science / World / European',
  POL059000: 'Political Science / World / Middle Eastern',
  POL060000: 'Political Science / World / Russian & Former Soviet Union',
  POL061000: 'Political Science / Genocide & War Crimes',
  POL062000: 'Political Science / Geopolitics',
  POL063000: 'Political Science / Public Policy / Science & Technology Policy',
  POL064000: 'Political Science / Corruption & Misconduct',
  POL065000: 'Political Science / Political Process / Media & Internet',
  POL066000: 'Political Science / Privacy & Surveillance ',
  POL067000: 'Political Science / Public Policy / Agriculture & Food Policy ',
  POL068000: 'Political Science / Public Policy / Energy Policy ',
  POL069000: 'Political Science / Public Policy / Military Policy ',
  PSY000000: 'PSYCHOLOGY / General',
  PSY001000: 'Psychology & Psychiatry/ Adolescent Psychiatry',
  PSY002000: 'Psychology / Developmental / Adolescent',
  PSY003000: 'Psychology / Applied Psychology',
  PSY004000: 'Psychology / Developmental / Child',
  PSY005000: 'Psychology & Psychiatry/ Child Psychiatry',
  PSY006000: 'PSYCHOLOGY / Psychotherapy / Child & Adolescent',
  PSY007000: 'Psychology / Clinical Psychology',
  PSY008000: 'Psychology / Cognitive Psychology & Cognition',
  PSY009000: 'Psychology / Psychopathology / Compulsive Behavior',
  PSY010000: 'Psychology / Psychotherapy / Counseling',
  PSY011000: 'Psychology / Psychopathology / Eating Disorders',
  PSY012000: 'PSYCHOLOGY / Education & Training',
  PSY013000: 'Psychology / Emotions',
  PSY014000: 'Psychology / Forensic Psychology',
  PSY015000: 'PSYCHOLOGY / History',
  PSY016000: 'Psychology / Human Sexuality ',
  PSY017000: 'Psychology / Interpersonal Relations',
  PSY018000: 'Psychology / Mental Illness',
  PSY019000: 'Psychology & Psychiatry/ Methodology',
  PSY020000: 'Psychology / Neuropsychology',
  PSY021000: 'Psychology / Industrial & Organizational Psychology',
  PSY022000: 'Psychology / Psychopathology / General',
  PSY022010: 'Psychology / Psychopathology / Attention-Deficit Disorder (ADD-ADHD)',
  PSY022020: 'Psychology / Psychopathology / Autism Spectrum Disorders',
  PSY022030: 'Psychology / Psychopathology / Bipolar Disorder',
  PSY022040: 'Psychology / Psychopathology / Post-Traumatic Stress Disorder (PTSD)',
  PSY022050: 'Psychology / Psychopathology / Schizophrenia',
  PSY022060: 'Psychology / Psychopathology / Anxieties & Phobias',
  PSY022070: 'Psychology / Psychopathology / Dissociative Identity Disorder',
  PSY022080: 'Psychology / Psychopathology / Personality Disorders',
  PSY023000: 'Psychology / Personality',
  PSY024000: 'Psychology / Physiological Psychology',
  PSY025000: 'Psychology & Psychiatry/ Psychiatry',
  PSY026000: 'Psychology / Movements / Psychoanalysis',
  PSY027000: 'Psychology & Psychiatry/ Psychopharmacology',
  PSY028000: 'Psychology / Psychotherapy / General',
  PSY029000: 'PSYCHOLOGY / Reference',
  PSY030000: 'PSYCHOLOGY / Research & Methodology',
  PSY031000: 'Psychology / Social Psychology',
  PSY032000: 'PSYCHOLOGY / Statistics',
  PSY033000: 'Psychology & Psychiatry/ Substance Abuse',
  PSY034000: 'Psychology / Creative Ability',
  PSY035000: 'Psychology / Hypnotism',
  PSY036000: 'Psychology / Mental Health',
  PSY037000: 'Psychology / Suicide',
  PSY038000: 'Psychology / Psychopathology / Addiction',
  PSY039000: 'Psychology / Developmental / General',
  PSY040000: 'Psychology / Experimental Psychology',
  PSY041000: 'Psychology / Psychotherapy / Couples & Family',
  PSY042000: 'PSYCHOLOGY / Assessment, Testing & Measurement',
  PSY043000: 'Psychology / Developmental / Adulthood & Aging',
  PSY044000: 'Psychology / Developmental / Lifespan Development',
  PSY045000: 'Psychology / Movements / General',
  PSY045010: 'Psychology / Movements / Behaviorism',
  PSY045020: 'Psychology / Movements / Humanistic',
  PSY045030: 'Psychology / Movements / Transpersonal',
  PSY045040: 'Psychology / Movements / Existential',
  PSY045050: 'Psychology / Movements / Gestalt',
  PSY045060: 'Psychology / Movements / Jungian',
  PSY045070: 'Psychology / Movements / Cognitive Behavioral Therapy (CBT)',
  PSY046000: 'Psychology / Practice Management',
  PSY047000: 'Psychology/ Psychopathology / Abnormal',
  PSY048000: 'Psychology / Psychotherapy / Group',
  PSY049000: 'Psychology / Psychopathology / Depression',
  PSY050000: 'Psychology / Ethnopsychology',
  PSY051000: 'Psychology / Cognitive Neuroscience & Cognitive Neuropsychology',
  PSY052000: 'Psychology / Grief & Loss',
  PSY053000: 'Psychology / Evolutionary Psychology ',
  REF000000: 'Reference / General',
  REF001000: 'Reference / Almanacs',
  REF002000: 'Reference / Atlases, Gazetteers & Maps ',
  REF003000: 'Reference/ Basic Skills',
  REF004000: 'Reference / Bibliographies & Indexes',
  REF005000: 'Reference/ Business Skills',
  REF006000: 'Reference / Catalogs',
  REF007000: 'Reference / Curiosities & Wonders',
  REF008000: 'Reference / Dictionaries',
  REF009000: 'Reference / Directories',
  REF010000: 'Reference / Encyclopedias',
  REF011000: 'Reference / Etiquette',
  REF012000: 'Reference/ Etymology',
  REF013000: 'Reference / Genealogy & Heraldry',
  REF014000: 'Reference/ Maps',
  REF015000: 'Reference / Personal & Practical Guides',
  REF016000: 'Reference/ Public Speaking',
  REF017000: 'Reference/ Publishing',
  REF018000: 'Reference / Questions & Answers',
  REF019000: 'Reference / Quotations',
  REF020000: 'Reference / Research',
  REF021000: 'Reference/ Secretarial Aids & Training',
  REF022000: 'Reference / Thesauri',
  REF023000: 'Reference / Trivia',
  REF024000: 'Reference / Weddings',
  REF025000: 'Reference / Word Lists',
  REF026000: 'Reference / Writing Skills',
  REF027000: 'Reference / Yearbooks & Annuals',
  REF028000: 'Reference / Handbooks & Manuals',
  REF029000: 'Reference / Problems & Exercises',
  REF030000: 'Reference / Consumer Guides',
  REF031000: 'Reference / Survival & Emergency Preparedness',
  REF032000: 'Reference / Event Planning ',
  REL000000: 'Religion / General',
  REL001000: 'Religion / Agnosticism',
  REL002000: 'Religion / Christianity / Amish',
  REL003000: 'Religion / Christianity / Anglican',
  REL004000: 'Religion / Atheism',
  REL005000: 'Religion / Baha\u2019i',
  REL006000: 'Religion / Biblical Studies / General',
  REL006010: 'Religion/ Bible / Accessories',
  REL006020: 'Religion / Biblical Biography / General',
  REL006030: 'Religion / Biblical Biography / Old Testament',
  REL006040: 'Religion / Biblical Biography / New Testament',
  REL006050: 'Religion / Biblical Commentary / General',
  REL006060: 'Religion/ Biblical Commentary / Old Testament / General',
  REL006070: 'Religion/ Biblical Commentary / New Testament / General',
  REL006080: 'Religion / Biblical Criticism & Interpretation / General',
  REL006090: 'Religion / Biblical Criticism & Interpretation / Old Testament',
  REL006100: 'Religion / Biblical Criticism & Interpretation / New Testament',
  REL006110: 'Religion / Biblical Meditations / General',
  REL006120: 'Religion / Biblical Meditations / Old Testament',
  REL006130: 'Religion / Biblical Meditations / New Testament',
  REL006140: 'Religion / Biblical Studies / Prophecy',
  REL006150: 'Religion / Biblical Reference / Quotations',
  REL006160: 'Religion / Biblical Reference / General',
  REL006170: 'Religion/ Bible / Stories / General',
  REL006180: 'Religion/ Bible / Stories / Old Testament',
  REL006190: 'Religion/ Bible / Stories / New Testament',
  REL006200: 'Religion/ Bible / Study / General',
  REL006210: 'RELIGION / Biblical Studies / Old Testament / General',
  REL006220: 'RELIGION / Biblical Studies / New Testament / General',
  REL006230: 'Religion / Bibles / Catholic',
  REL006240: 'Religion / Bibles / Contemporary English',
  REL006250: 'Religion / Bibles / Evangelical',
  REL006260: 'Religion / Bibles / Greek',
  REL006270: 'Religion / Bibles / Hebrew',
  REL006280: 'Religion / Bibles / King James',
  REL006290: 'Religion / Bibles / Living',
  REL006300: 'Religion / Bibles / New American Standard',
  REL006310: 'Religion/ Bibles / New Century',
  REL006320: 'Religion / Bibles / New International',
  REL006330: 'Religion/ Bibles / New Jerusalem',
  REL006340: 'Religion / Bibles / New King James',
  REL006350: 'Religion / Bibles / New Revised Standard',
  REL006360: 'Religion / Bibles / Parallel Editions',
  REL006370: 'Religion / Bibles / Revised Standard',
  REL006380: 'Religion / Bibles / Today\u2019s English',
  REL006390: 'Religion / Bibles / Other',
  REL006400: 'Religion / Biblical Studies / Exegesis & Hermeneutics',
  REL006410: 'Religion / Biblical Reference / Language Study',
  REL006420: 'Religion/ Bible / Theology / General',
  REL006430: 'Religion/ Bible / Theology / Old Testament',
  REL006440: 'Religion/ Bible / Theology / New Testament',
  REL006450: 'Religion/ Bibles / American Standard',
  REL006460: 'Religion / Bibles / Amplified',
  REL006470: 'Religion / Bibles / Basic English',
  REL006480: 'Religion/ Bibles / Clear Word Translation',
  REL006490: 'Religion/ Bibles / Douay',
  REL006500: 'Religion / Bibles / God\u2019s Word',
  REL006510: 'Religion / Bibles / International Children\u2019s',
  REL006520: 'Religion/ Bibles / Jerusalem',
  REL006530: 'Religion/ Bibles / Message',
  REL006540: 'Religion / Bibles / New American',
  REL006550: 'Religion / Bibles / New American Standard Update',
  REL006560: 'Religion/ Bibles / New English',
  REL006570: 'Religion / Bibles / New International Readers',
  REL006580: 'Religion/ Bibles / New Living',
  REL006590: 'Religion / Bibles / New Living Translation',
  REL006600: 'Religion/ Bibles / Phillips Paraphrase',
  REL006610: 'Religion / Bibles / Revised English',
  REL006620: 'Religion/ Bibles / 21st Century King James',
  REL006630: 'Religion / Biblical Studies / History & Culture',
  REL006640: 'Religion / Bibles / New Century',
  REL006650: 'Religion / Biblical Reference / Atlases',
  REL006660: 'Religion / Biblical Reference / Concordances',
  REL006670: 'Religion / Biblical Reference / Dictionaries & Encyclopedias',
  REL006680: 'Religion / Biblical Reference / Handbooks',
  REL006700: 'Religion / Biblical Studies / Bible Study Guides',
  REL006710: 'RELIGION / Biblical Studies / New Testament / Jesus, the Gospels & Acts',
  REL006720: 'RELIGION / Biblical Studies / New Testament / Paul\u2019s Letters',
  REL006730: 'RELIGION / Biblical Studies / Old Testament / Prophets',
  REL006740: 'RELIGION / Biblical Studies / Old Testament / Poetry & Wisdom Literature',
  REL007000: 'Religion / Buddhism / General ',
  REL007010: 'Religion / Buddhism / History',
  REL007020: 'Religion / Buddhism / Rituals & Practice',
  REL007030: 'Religion / Buddhism / Sacred Writings',
  REL007040: 'Religion / Buddhism / Theravada',
  REL007050: 'Religion / Buddhism / Tibetan',
  REL008000: 'Religion / Christian Church / Canon & Ecclesiastical Law',
  REL009000: 'RELIGION / Christianity / Catechisms',
  REL010000: 'Religion / Christianity / Catholic',
  REL011000: 'Religion / Christian Education / General',
  REL012000: 'RELIGION / Christian Living / General',
  REL012010: 'RELIGION / Christian Living / Death, Grief, Bereavement',
  REL012020: 'RELIGION / Christian Living / Devotional',
  REL012030: 'RELIGION / Christian Living / Family & Relationships',
  REL012040: 'RELIGION / Christian Living / Inspirational',
  REL012050: 'RELIGION / Christian Living / Love & Marriage',
  REL012060: 'RELIGION / Christian Living / Men\u2019s Interests',
  REL012070: 'RELIGION / Christian Living / Personal Growth',
  REL012080: 'RELIGION / Christian Living / Prayer',
  REL012090: 'RELIGION / Christian Living / Professional Growth',
  REL012100: 'Religion / Christian Life / Relationships',
  REL012110: 'RELIGION / Christian Living / Social Issues',
  REL012120: 'RELIGION / Christian Living / Spiritual Growth',
  REL012130: 'RELIGION / Christian Living / Women\u2019s Interests',
  REL013000: 'Religion / Christianity / Literature & the Arts',
  REL014000: 'Religion / Christian Church / Administration',
  REL015000: 'Religion / Christianity / History',
  REL016000: 'Religion / Institutions & Organizations',
  REL017000: 'Religion / Comparative Religion',
  REL018000: 'Religion / Confucianism',
  REL019000: 'Religion / Counseling',
  REL020000: 'RELIGION / Cults',
  REL021000: 'Religion / Deism',
  REL022000: 'Religion / Devotional',
  REL023000: 'Religion / Christian Ministry / Discipleship',
  REL024000: 'Religion / Eastern',
  REL025000: 'Religion / Ecumenism & Interfaith',
  REL026000: 'Religion / Education',
  REL027000: 'Religion / Christianity / Episcopalian',
  REL028000: 'Religion / Ethics',
  REL029000: 'Religion / Ethnic & Tribal',
  REL030000: 'Religion / Christian Ministry / Evangelism',
  REL031000: 'Religion/ Freemasonry',
  REL032000: 'Religion / Hinduism / General',
  REL032010: 'Religion / Hinduism / History',
  REL032020: 'Religion / Hinduism / Rituals & Practice',
  REL032030: 'Religion / Hinduism / Sacred Writings',
  REL032040: 'Religion / Hinduism / Theology',
  REL033000: 'Religion / History',
  REL034000: 'Religion / Holidays / General',
  REL034010: 'Religion / Holidays / Christian',
  REL034020: 'Religion / Holidays / Christmas & Advent',
  REL034030: 'Religion / Holidays / Easter & Lent',
  REL034040: 'Religion / Holidays / Jewish',
  REL034050: 'Religion / Holidays / Other',
  REL035000: 'Religion/ I Ching',
  REL036000: 'Religion / Inspirational',
  REL037000: 'Religion / Islam / General',
  REL037010: 'Religion / Islam / History',
  REL037020: 'Religion / Islam / Law',
  REL037030: 'Religion / Islam / Rituals & Practice',
  REL037040: 'Religion / Islam / Shi\u2019a',
  REL037050: 'Religion / Islam / Sunni',
  REL037060: 'Religion / Islam / Theology',
  REL038000: 'Religion / Jainism',
  REL039000: 'Religion/ Jewish Life',
  REL040000: 'Religion / Judaism / General',
  REL040010: 'Religion / Judaism / Rituals & Practice',
  REL040020: 'Religion/ Judaism / Movements',
  REL040030: 'Religion / Judaism / History',
  REL040040: 'Religion / Judaism / Sacred Writings',
  REL040050: 'Religion / Judaism / Conservative',
  REL040060: 'Religion / Judaism / Kabbalah & Mysticism',
  REL040070: 'Religion / Judaism / Orthodox',
  REL040080: 'Religion / Judaism / Reform',
  REL040090: 'Religion / Judaism / Theology',
  REL041000: 'Religion / Islam / Koran & Sacred Writings',
  REL042000: 'Religion / Meditations',
  REL043000: 'Religion / Christianity / Mennonite',
  REL044000: 'Religion / Christianity / Methodist',
  REL045000: 'RELIGION / Christian Ministry / Missions',
  REL046000: 'Religion / Christianity / Church of Jesus Christ of Latter-day Saints (Mormon)',
  REL047000: 'Religion / Mysticism',
  REL048000: 'Religion/ New Parish Ministry',
  REL049000: 'Religion / Christianity / Orthodox',
  REL050000: 'Religion / Christian Ministry / Counseling & Recovery',
  REL051000: 'Religion / Philosophy',
  REL052000: 'Religion / Prayerbooks / General',
  REL052010: 'Religion / Prayerbooks / Christian',
  REL052020: 'Religion / Prayerbooks / Jewish',
  REL052030: 'Religion / Prayerbooks / Islamic',
  REL053000: 'Religion / Christianity / Protestant',
  REL054000: 'Religion / Reference',
  REL055000: 'RELIGION / Christian Rituals & Practice / General',
  REL055010: 'Religion / Christian Rituals & Practice / Sacraments',
  REL055020: 'Religion / Christian Rituals & Practice / Worship & Liturgy',
  REL056000: 'Religion/ Roman Catholicism',
  REL057000: 'Religion / Rosicrucianism',
  REL058000: 'Religion / Sermons / General',
  REL058010: 'Religion / Sermons / Christian',
  REL058020: 'Religion / Sermons / Jewish',
  REL059000: 'Religion / Christianity / Shaker',
  REL060000: 'Religion / Shintoism',
  REL061000: 'Religion / Sikhism',
  REL062000: 'Religion / Spirituality',
  REL063000: 'RELIGION / Christian Living / Stewardship & Giving',
  REL064000: 'Religion / Judaism / Talmud',
  REL065000: 'Religion / Taoism ',
  REL066000: 'Religion / Theism',
  REL067000: 'RELIGION / Christian Theology / General',
  REL067010: 'Religion / Christian Theology / Angelology & Demonology',
  REL067020: 'Religion / Christian Theology / Anthropology',
  REL067030: 'Religion / Christian Theology / Apologetics',
  REL067040: 'Religion / Christian Theology / Christology',
  REL067050: 'Religion / Christian Theology / Ecclesiology',
  REL067060: 'Religion / Christian Theology / Eschatology',
  REL067070: 'Religion / Christian Theology / Ethics',
  REL067080: 'Religion / Christian Theology / History',
  REL067090: 'Religion / Christian Theology / Pneumatology',
  REL067100: 'Religion / Christian Theology / Soteriology',
  REL067110: 'Religion / Christian Theology / Systematic',
  REL067120: 'Religion / Christian Theology / Liberation',
  REL067130: 'Religion / Christian Theology / Process',
  REL068000: 'Religion / Theosophy',
  REL069000: 'Religion / Zoroastrianism',
  REL070000: 'Religion / Christianity / General',
  REL071000: 'Religion / Leadership',
  REL072000: 'Religion / Antiquities & Archaeology',
  REL073000: 'Religion / Christianity / Baptist',
  REL074000: 'Religion / Christian Ministry / Pastoral Resources',
  REL075000: 'Religion / Psychology of Religion',
  REL076000: 'Religion/ Sociology of Religion',
  REL077000: 'Religion / Faith',
  REL078000: 'Religion / Fundamentalism',
  REL079000: 'Religion / Christianity / Pentecostal & Charismatic',
  REL080000: 'RELIGION / Christian Ministry / Preaching',
  REL081000: 'Religion / Clergy',
  REL082000: 'Religion / Christianity / Lutheran',
  REL083000: 'Religion / Christianity / Christian Science',
  REL084000: 'Religion / Religion, Politics & State',
  REL085000: 'Religion / Eschatology',
  REL086000: 'Religion / Monasticism',
  REL087000: 'Religion / Prayer',
  REL088000: 'Religion / Christianity / Quaker',
  REL089000: 'Religion / Scientology',
  REL090000: 'Religion / Islam / Sufi',
  REL091000: 'Religion / Christian Education / Children & Youth',
  REL092000: 'Religion / Buddhism / Zen ',
  REL093000: 'Religion / Christianity / Calvinist',
  REL094000: 'Religion / Christianity / Denominations',
  REL095000: 'Religion / Christian Education / Adult',
  REL096000: 'Religion / Christianity / Jehovah\u2019s Witnesses',
  REL097000: 'Religion / Christianity / Presbyterian',
  REL098000: 'Religion / Christianity / Seventh-Day Adventist',
  REL099000: 'RELIGION / Christian Living / Spiritual Warfare',
  REL100000: 'Religion / Demonology & Satanism',
  REL101000: 'Religion / Messianic Judaism',
  REL102000: 'Religion / Theology',
  REL103000: 'Religion / Unitarian Universalism',
  REL104000: 'Religion / Christian Theology / Mariology',
  REL105000: 'Religion / Sexuality & Gender Studies',
  REL106000: 'Religion / Religion & Science',
  REL107000: 'Religion / Eckankar',
  REL108000: 'Religion / Christian Church / General',
  REL108010: 'Religion / Christian Church / Growth',
  REL108020: 'Religion / Christian Church / History',
  REL108030: 'RELIGION / Christian Living / Leadership & Mentoring',
  REL109000: 'Religion / Christian Ministry / General',
  REL109010: 'Religion / Christian Ministry / Adult',
  REL109020: 'Religion / Christian Ministry / Children',
  REL109030: 'Religion / Christian Ministry / Youth',
  REL110000: 'Religion / Christianity / Saints & Sainthood',
  REL111000: 'Religion / Christianity / United Church of Christ',
  REL112000: 'Religion / Gnosticism',
  REL113000: 'Religion / Essays',
  REL114000: 'Religion / Ancient',
  REL115000: 'Religion / Blasphemy, Heresy & Apostasy',
  REL116000: 'Religion / Religious Intolerance, Persecution & Conflict',
  REL117000: 'Religion / Paganism & Neo-Paganism',
  REL118000: 'Religion / Wicca ',
  SCI000000: 'Science / General',
  SCI001000: 'Science / Acoustics & Sound',
  SCI002000: 'Science/ Anatomy ',
  SCI003000: 'Science / Applied Sciences',
  SCI004000: 'Science / Astronomy',
  SCI005000: 'Science/ Physics / Astrophysics',
  SCI006000: 'Science / Life Sciences / Bacteriology',
  SCI007000: 'Science / Life Sciences / Biochemistry',
  SCI008000: 'Science / Life Sciences / Biology',
  SCI009000: 'Science / Life Sciences / Biophysics',
  SCI010000: 'Science / Biotechnology',
  SCI011000: 'Science / Life Sciences / Botany',
  SCI012000: 'Science / Chaotic Behavior in Systems',
  SCI013000: 'Science / Chemistry / General',
  SCI013010: 'Science / Chemistry / Analytic',
  SCI013020: 'Science / Chemistry / Clinical',
  SCI013030: 'Science / Chemistry / Inorganic',
  SCI013040: 'Science / Chemistry / Organic',
  SCI013050: 'Science / Chemistry / Physical & Theoretical',
  SCI013060: 'Science / Chemistry / Industrial & Technical',
  SCI013070: 'Science / Chemistry / Computational & Molecular Modeling',
  SCI013080: 'Science / Chemistry / Environmental ',
  SCI013090: 'Science / Chemistry / Toxicology',
  SCI013100: 'Science / Chemistry / Electrochemistry ',
  SCI014000: 'Science/ Clinical Science',
  SCI015000: 'Science / Cosmology',
  SCI016000: 'Science / Physics / Crystallography',
  SCI017000: 'Science / Life Sciences / Cell Biology',
  SCI018000: 'Science / Mechanics / Dynamics',
  SCI019000: 'Science / Earth Sciences / General',
  SCI020000: 'Science / Life Sciences / Ecology',
  SCI021000: 'Science / Physics / Electricity',
  SCI022000: 'Science / Physics / Electromagnetism',
  SCI023000: 'Science / Electron Microscopes & Microscopy',
  SCI024000: 'Science / Energy',
  SCI025000: 'Science / Life Sciences / Zoology / Entomology',
  SCI026000: 'Science / Environmental Science ',
  SCI027000: 'Science / Life Sciences / Evolution',
  SCI028000: 'Science / Experiments & Projects',
  SCI029000: 'Science / Life Sciences / Genetics & Genomics',
  SCI030000: 'Science / Earth Sciences / Geography',
  SCI031000: 'Science / Earth Sciences / Geology',
  SCI032000: 'Science / Physics / Geophysics',
  SCI033000: 'Science / Gravity',
  SCI034000: 'Science / History',
  SCI035000: 'Science/ Human Anatomy',
  SCI036000: 'Science / Life Sciences / Human Anatomy & Physiology',
  SCI037000: 'Science / Light',
  SCI038000: 'Science / Physics / Magnetism',
  SCI039000: 'Science / Life Sciences / Marine Biology',
  SCI040000: 'Science / Physics / Mathematical & Computational',
  SCI041000: 'Science / Mechanics / General',
  SCI042000: 'Science / Earth Sciences / Meteorology & Climatology',
  SCI043000: 'Science / Research & Methodology',
  SCI044000: 'Science / Metric System',
  SCI045000: 'Science / Life Sciences / Microbiology',
  SCI046000: 'Science/ Microchemistry',
  SCI047000: 'Science / Microscopes & Microscopy',
  SCI048000: 'Science / Earth Sciences / Mineralogy',
  SCI049000: 'Science / Life Sciences / Molecular Biology',
  SCI050000: 'Science / Nanoscience',
  SCI051000: 'Science / Physics / Nuclear',
  SCI052000: 'Science / Earth Sciences / Oceanography',
  SCI053000: 'Science / Physics / Optics & Light',
  SCI054000: 'Science / Paleontology',
  SCI055000: 'Science / Physics / General',
  SCI056000: 'Science / Life Sciences / Anatomy & Physiology ',
  SCI057000: 'Science / Physics / Quantum Theory',
  SCI058000: 'Science / Radiation',
  SCI059000: 'Science / Radiography',
  SCI060000: 'Science / Reference',
  SCI061000: 'Science / Physics / Relativity',
  SCI062000: 'Science/ Research',
  SCI063000: 'Science / Study & Teaching',
  SCI064000: 'Science / System Theory',
  SCI065000: 'Science / Mechanics / Thermodynamics',
  SCI066000: 'Science / Time',
  SCI067000: 'Science / Waves & Wave Mechanics',
  SCI068000: 'Science / Weights & Measures',
  SCI069000: 'Science/ Xenobiotics',
  SCI070000: 'Science / Life Sciences / Zoology / General',
  SCI070010: 'Science / Life Sciences / Zoology / Ichthyology & Herpetology',
  SCI070020: 'Science / Life Sciences / Zoology / Invertebrates',
  SCI070030: 'Science / Life Sciences / Zoology / Mammals',
  SCI070040: 'Science / Life Sciences / Zoology / Ornithology',
  SCI070050: 'Science / Life Sciences / Zoology / Primatology',
  SCI071000: 'Science/ Cellular Biology',
  SCI072000: 'Science / Life Sciences / Developmental Biology',
  SCI073000: 'Science / Life Sciences / Horticulture',
  SCI074000: 'Science / Physics / Atomic & Molecular',
  SCI075000: 'Science / Philosophy & Social Aspects',
  SCI076000: 'Science / Scientific Instruments',
  SCI077000: 'Science / Physics / Condensed Matter',
  SCI078000: 'Science / Spectroscopy & Spectrum Analysis',
  SCI079000: 'Science / Mechanics / Statics',
  SCI080000: 'Science / Essays',
  SCI081000: 'Science / Earth Sciences / Hydrology',
  SCI082000: 'Science / Earth Sciences / Seismology & Volcanism',
  SCI083000: 'Science / Earth Sciences / Limnology',
  SCI084000: 'Science / Mechanics / Aerodynamics',
  SCI085000: 'Science / Mechanics / Fluids',
  SCI086000: 'Science / Life Sciences / General',
  SCI087000: 'Science / Life Sciences / Taxonomy',
  SCI088000: 'Science / Life Sciences / Biological Diversity',
  SCI089000: 'Science / Life Sciences / Neuroscience',
  SCI090000: 'Science / Cognitive Science',
  SCI091000: 'Science / Earth Sciences / Sedimentology & Stratigraphy',
  SCI092000: 'Science / Global Warming & Climate Change',
  SCI093000: 'Science / Laboratory Techniques',
  SCI094000: 'Science / Life Sciences / Mycology',
  SCI095000: 'Science / Mechanics / Hydrodynamics',
  SCI096000: 'Science / Mechanics / Solids',
  SCI097000: 'Science / Physics / Polymer',
  SCI098000: 'Science / Space Science',
  SCI099000: 'Science / Life Sciences / Virology',
  SCI100000: 'Science / Natural History',
  SEL000000: 'Self-Help / General',
  SEL001000: 'Self-Help / Abuse',
  SEL002000: 'Self-Help/ Addiction',
  SEL003000: 'Self-Help / Adult Children of Substance Abusers',
  SEL004000: 'Self-Help / Affirmations',
  SEL005000: 'Self-Help / Aging',
  SEL006000: 'Self-Help / Substance Abuse & Addictions / Alcohol',
  SEL007000: 'Self-Help/ Chemical Dependence',
  SEL008000: 'Self-Help / Codependency',
  SEL009000: 'Self-Help / Creativity',
  SEL010000: 'Self-Help / Death, Grief, Bereavement',
  SEL011000: 'Self-Help / Mood Disorders / Depression',
  SEL012000: 'Self-Help / Dreams',
  SEL013000: 'Self-Help / Substance Abuse & Addictions / Drugs',
  SEL014000: 'Self-Help / Eating Disorders & Body Image',
  SEL015000: 'Self-Help / Handwriting Analysis',
  SEL016000: 'Self-Help / Personal Growth / Happiness',
  SEL017000: 'Self-Help / Self-Hypnosis',
  SEL018000: 'Self-Help / Inner Child',
  SEL019000: 'Self-Help / Meditations',
  SEL020000: 'Self-Help / Mood Disorders / General',
  SEL020010: 'Self-Help / Mood Disorders / Bipolar Disorder',
  SEL021000: 'Self-Help / Motivational & Inspirational',
  SEL022000: 'Self-Help/ Recovery',
  SEL023000: 'Self-Help / Personal Growth / Self-Esteem',
  SEL024000: 'Self-Help / Self-Management / Stress Management',
  SEL025000: 'Self-Help/ Subliminal',
  SEL026000: 'Self-Help / Substance Abuse & Addictions / General',
  SEL026010: 'Self-Help / Substance Abuse & Addictions / Tobacco',
  SEL027000: 'Self-Help / Personal Growth / Success',
  SEL028000: 'Self-Help/ Treatment',
  SEL029000: 'Self-Help / Twelve-Step Programs',
  SEL030000: 'Self-Help / Personal Growth / Memory Improvement',
  SEL031000: 'Self-Help / Personal Growth / General',
  SEL032000: 'Self-Help / Spiritual',
  SEL033000: 'Self-Help / Self-Management / Anger Management ',
  SEL034000: 'Self-Help / Sexual Instruction',
  SEL035000: 'Self-Help / Self-Management / Time Management',
  SEL036000: 'Self-Help / Anxieties & Phobias',
  SEL037000: 'Self-Help / Neuro-Linguistic Programming (NLP)',
  SEL038000: 'Self-Help / Fashion & Style',
  SEL039000: 'Self-Help / Green Lifestyle',
  SEL040000: 'Self-Help / Communication & Social Skills',
  SEL041000: 'Self-Help / Compulsive Behavior / General',
  SEL041010: 'Self-Help / Compulsive Behavior / Gambling',
  SEL041020: 'Self-Help / Compulsive Behavior / Hoarding',
  SEL041030: 'Self-Help / Compulsive Behavior / Obsessive Compulsive Disorder (OCD)',
  SEL041040: 'Self-Help / Compulsive Behavior / Sex & Pornography Addiction',
  SEL042000: 'Self-Help / Emotions',
  SEL043000: 'Self-Help / Post-Traumatic Stress Disorder (PTSD)',
  SEL044000: 'Self-Help / Self-Management / General',
  SEL045000: 'Self-Help / Journaling',
  SOC000000: 'Social Science / General',
  SOC001000: 'Social Science / Ethnic Studies / African American Studies',
  SOC002000: 'Social Science / Anthropology / General',
  SOC002010: 'Social Science / Anthropology / Cultural & Social',
  SOC002020: 'Social Science / Anthropology / Physical',
  SOC003000: 'Social Science / Archaeology',
  SOC004000: 'Social Science / Criminology',
  SOC005000: 'Social Science / Customs & Traditions',
  SOC006000: 'Social Science / Demography',
  SOC007000: 'Social Science / Emigration & Immigration',
  SOC008000: 'Social Science / Ethnic Studies / General',
  SOC009000: 'Social Science/ Ethnology',
  SOC010000: 'Social Science / Feminism & Feminist Theory',
  SOC011000: 'Social Science / Folklore & Mythology',
  SOC012000: 'Social Science / LGBTQ+ Studies / Gay Studies',
  SOC013000: 'Social Science / Gerontology',
  SOC014000: 'Social Science / Holidays (non-religious)',
  SOC015000: 'Social Science / Human Geography',
  SOC016000: 'Social Science / Human Services',
  SOC017000: 'Social Science / LGBTQ+ Studies / Lesbian Studies',
  SOC018000: 'Social Science / Men\u2019s Studies',
  SOC019000: 'Social Science / Methodology',
  SOC020000: 'Social Science / Minority Studies',
  SOC021000: 'Social Science / Ethnic Studies / Native American Studies',
  SOC022000: 'Social Science / Popular Culture',
  SOC023000: 'Social Science / Reference',
  SOC024000: 'Social Science / Research',
  SOC025000: 'Social Science / Social Work',
  SOC026000: 'Social Science / Sociology / General',
  SOC026010: 'Social Science / Sociology / Marriage & Family',
  SOC026020: 'Social Science / Sociology / Rural',
  SOC026030: 'Social Science / Sociology / Urban',
  SOC026040: 'Social Science / Sociology / Social Theory ',
  SOC027000: 'Social Science / Statistics',
  SOC028000: 'Social Science / Women\u2019s Studies',
  SOC029000: 'Social Science / People with Disabilities',
  SOC030000: 'Social Science / Penology',
  SOC031000: 'Social Science/ Discrimination',
  SOC032000: 'Social Science / Gender Studies',
  SOC033000: 'Social Science / Philanthropy & Charity',
  SOC034000: 'Social Science / Pornography',
  SOC035000: 'Social Science / Volunteer Work',
  SOC036000: 'Social Science / Death & Dying',
  SOC037000: 'Social Science / Future Studies',
  SOC038000: 'Social Science / Freemasonry & Secret Societies',
  SOC039000: 'Social Science / Sociology of Religion',
  SOC040000: 'Social Science / Disasters & Disaster Relief',
  SOC041000: 'Social Science / Essays',
  SOC042000: 'Social Science / Developing & Emerging Countries',
  SOC043000: 'Social Science / Ethnic Studies / Asian American Studies',
  SOC044000: 'Social Science / Ethnic Studies / Hispanic American Studies',
  SOC045000: 'Social Science / Poverty & Homelessness',
  SOC046000: 'Social Science / Abortion & Birth Control',
  SOC047000: 'Social Science / Children\u2019s Studies',
  SOC048000: 'Social Science / Islamic Studies',
  SOC049000: 'Social Science / Jewish Studies',
  SOC050000: 'Social Science / Social Classes & Economic Disparity',
  SOC051000: 'Social Science / Violence in Society',
  SOC052000: 'Social Science / Media Studies',
  SOC053000: 'Social Science / Regional Studies',
  SOC054000: 'Social Science / Slavery',
  SOC055000: 'Social Science / Agriculture & Food ',
  SOC056000: 'Social Science / Black Studies (Global)',
  SOC057000: 'Social Science / Disease & Health Issues',
  SOC058000: 'Social Science / Conspiracy Theories',
  SOC059000: 'Social Science / Prostitution & Sex Trade',
  SOC060000: 'Social Science / Sexual Abuse & Harassment',
  SOC061000: 'Social Science / Body Language & Nonverbal Communication',
  SOC062000: 'Social Science / Indigenous Studies',
  SOC063000: 'Social Science / Privacy & Surveillance ',
  SOC064000: 'Social Science / LGBTQ+ Studies / General',
  SPO000000: 'Sports & Recreation / General',
  SPO001000: 'Sports & Recreation / Air Sports',
  SPO002000: 'Sports & Recreation / Archery',
  SPO003000: 'Sports & Recreation / Baseball / General',
  SPO003010: 'Sports & Recreation / Coaching / Baseball',
  SPO003020: 'Sports & Recreation / Baseball / Essays & Writings',
  SPO003030: 'Sports & Recreation / Baseball / History',
  SPO003040: 'Sports & Recreation / Baseball / Statistics',
  SPO004000: 'Sports & Recreation / Basketball',
  SPO005000: 'Sports & Recreation / Boating',
  SPO006000: 'Sports & Recreation / Bodybuilding & Weight Training',
  SPO007000: 'Sports & Recreation / Bowling',
  SPO008000: 'Sports & Recreation / Boxing',
  SPO009000: 'Sports & Recreation / Camping',
  SPO010000: 'Sports & Recreation / Canoeing',
  SPO011000: 'Sports & Recreation / Cycling',
  SPO012000: 'Sports & Recreation / Essays',
  SPO013000: 'Sports & Recreation / Field Sports',
  SPO014000: 'Sports & Recreation / Fishing',
  SPO015000: 'Sports & Recreation / Football',
  SPO016000: 'Sports & Recreation / Golf',
  SPO017000: 'Sports & Recreation / Gymnastics',
  SPO018000: 'Sports & Recreation / Hiking',
  SPO019000: 'Sports & Recreation / History',
  SPO020000: 'Sports & Recreation / Hockey',
  SPO021000: 'Sports & Recreation / Horse Racing',
  SPO022000: 'Sports & Recreation / Hunting',
  SPO023000: 'Sports & Recreation / Ice & Figure Skating',
  SPO024000: 'Sports & Recreation / Juggling',
  SPO025000: 'Sports & Recreation / Kayaking',
  SPO026000: 'Sports & Recreation / Lacrosse',
  SPO027000: 'Sports & Recreation/ Martial Arts / General',
  SPO028000: 'Sports & Recreation / Motor Sports',
  SPO029000: 'Sports & Recreation / Mountaineering',
  SPO030000: 'Sports & Recreation / Outdoor Skills',
  SPO031000: 'Sports & Recreation / Racket Sports',
  SPO032000: 'Sports & Recreation / Racquetball',
  SPO033000: 'Sports & Recreation / Reference',
  SPO034000: 'Sports & Recreation / Roller & In-Line Skating',
  SPO035000: 'Sports & Recreation / Running & Jogging',
  SPO036000: 'Sports & Recreation / Sailing',
  SPO037000: 'Sports & Recreation / Shooting',
  SPO038000: 'Sports & Recreation / Skateboarding',
  SPO039000: 'Sports & Recreation / Skiing',
  SPO040000: 'Sports & Recreation / Soccer',
  SPO041000: 'Sports & Recreation / Sports Psychology',
  SPO042000: 'Sports & Recreation / Squash',
  SPO043000: 'Sports & Recreation/ Water Sports / Swimming & Diving',
  SPO044000: 'Sports & Recreation / Table Tennis',
  SPO045000: 'Sports & Recreation / Tennis',
  SPO046000: 'Sports & Recreation / Track & Field',
  SPO047000: 'Sports & Recreation / Training',
  SPO048000: 'Sports & Recreation / Triathlon',
  SPO049000: 'Sports & Recreation / Volleyball',
  SPO050000: 'Sports & Recreation / Walking',
  SPO051000: 'Sports & Recreation / Water Sports',
  SPO052000: 'Sports & Recreation / Winter Sports',
  SPO053000: 'Sports & Recreation / Wrestling',
  SPO054000: 'Sports & Recreation / Cricket',
  SPO055000: 'Sports & Recreation / Polo',
  SPO056000: 'Sports & Recreation / Rugby',
  SPO057000: 'Sports & Recreation / Equestrian',
  SPO058000: 'Sports & Recreation / Olympics & Paralympics',
  SPO059000: 'Sports & Recreation / Scuba & Snorkeling',
  SPO060000: 'Sports & Recreation / Pool, Billiards, Snooker',
  SPO061000: 'Sports & Recreation / Coaching / General',
  SPO061010: 'Sports & Recreation / Coaching / Basketball',
  SPO061020: 'Sports & Recreation / Coaching / Football',
  SPO061030: 'Sports & Recreation / Coaching / Soccer',
  SPO062000: 'Sports & Recreation / Dog Racing',
  SPO063000: 'Sports & Recreation / Equipment & Supplies',
  SPO064000: 'Sports & Recreation / Extreme Sports',
  SPO065000: 'Sports & Recreation / Rodeos',
  SPO066000: 'Sports & Recreation / Sociology of Sports',
  SPO067000: 'Sports & Recreation / Softball',
  SPO068000: 'Sports & Recreation / Business Aspects',
  SPO069000: 'Sports & Recreation / Surfing',
  SPO070000: 'Sports & Recreation / Cheerleading',
  SPO071000: 'Sports & Recreation / Fencing',
  SPO072000: 'Sports & Recreation / Snowboarding',
  SPO073000: 'Sports & Recreation / Field Hockey',
  SPO074000: 'Sports & Recreation / Caving',
  SPO075000: 'Sports & Recreation / Health & Safety',
  SPO076000: 'Sports & Recreation / Disability Sports',
  SPO077000: 'Sports & Recreation / Children\u2019s & Youth Sports ',
  STU000000: 'Study Aids / General',
  STU001000: 'Study Aids / ACT',
  STU002000: 'Study Aids / Advanced Placement',
  STU003000: 'Study Aids / Armed Forces',
  STU004000: 'Study Aids / Book Notes ',
  STU005000: 'Study Aids/ CBAT',
  STU006000: 'Study Aids / Citizenship',
  STU007000: 'Study Aids / Civil Service',
  STU008000: 'Study Aids / CLEP (College-Level Examination Program)',
  STU009000: 'Study Aids / College Entrance',
  STU010000: 'Study Aids / College Guides',
  STU011000: 'Study Aids / CPA (Certified Public Accountant)',
  STU012000: 'Study Aids / GED (General Educational Development Tests)',
  STU013000: 'Study Aids / GMAT (Graduate Management Admission Test)',
  STU014000: 'Study Aids / Graduate Preparation',
  STU015000: 'Study Aids / Graduate School Guides',
  STU016000: 'Study Aids / GRE (Graduate Record Examination)',
  STU017000: 'Study Aids / LSAT (Law School Admission Test)',
  STU018000: 'Study Aids / MAT (Miller Analogies Test)',
  STU019000: 'Study Aids / NTE (National Teacher Examinations)',
  STU020000: 'Study Aids/ Outlines',
  STU021000: 'Study Aids / Professional',
  STU022000: 'Study Aids / Regents',
  STU023000: 'Study Aids/ Remedial',
  STU024000: 'STUDY AIDS / SAT',
  STU025000: 'Study Aids / High School Entrance',
  STU026000: 'Study Aids / Study Guides',
  STU027000: 'Study Aids / Tests',
  STU028000: 'Study Aids / TOEFL (Test of English as a Foreign Language) & IELTS (International English Language Testing System)',
  STU029000: 'Study Aids / Vocational',
  STU030000: 'Study Aids / Workbooks',
  STU031000: 'Study Aids / Financial Aid',
  STU032000: 'Study Aids / MCAT (Medical College Admission Test)',
  STU033000: 'Study Aids / PSAT & NMSQT (National Merit Scholarship Qualifying Test)',
  STU034000: 'Study Aids / Bar Exam',
  TEC000000: 'Technology & Engineering / General',
  TEC001000: 'Technology & Engineering / Acoustics & Sound',
  TEC002000: 'Technology & Engineering / Aeronautics & Astronautics',
  TEC003000: 'Technology & Engineering / Agriculture / General',
  TEC003010: 'Technology & Engineering / Agriculture / Tropical Agriculture',
  TEC003020: 'Technology & Engineering / Agriculture / Animal Husbandry',
  TEC003030: 'Technology & Engineering / Agriculture / Agronomy / Crop Science',
  TEC003040: 'Technology & Engineering / Agriculture / Forestry',
  TEC003050: 'Technology & Engineering / Agriculture / Irrigation',
  TEC003060: 'Technology & Engineering / Agriculture / Agronomy / Soil Science',
  TEC003070: 'Technology & Engineering / Agriculture / Sustainable Agriculture',
  TEC003080: 'Technology & Engineering / Agriculture / Agronomy / General',
  TEC003090: 'Technology & Engineering / Agriculture / Organic',
  TEC003100: 'Technology & Engineering / Agriculture / Beekeeping',
  TEC003110: 'Technology & Engineering / Agriculture / Enology & Viticulture',
  TEC004000: 'Technology & Engineering / Automation',
  TEC005000: 'Technology & Engineering / Construction / General',
  TEC005010: 'Technology & Engineering / Construction / Carpentry',
  TEC005020: 'Technology & Engineering / Construction / Contracting',
  TEC005030: 'Technology & Engineering / Construction / Electrical',
  TEC005040: 'Technology & Engineering / Construction / Estimating',
  TEC005050: 'Technology & Engineering / Construction / Heating, Ventilation & Air Conditioning',
  TEC005060: 'Technology & Engineering / Construction / Masonry',
  TEC005070: 'Technology & Engineering / Construction / Plumbing',
  TEC005080: 'Technology & Engineering / Construction / Roofing',
  TEC006000: 'Technology & Engineering / Drafting & Mechanical Drawing',
  TEC007000: 'Technology & Engineering / Electrical',
  TEC008000: 'Technology & Engineering / Electronics / General',
  TEC008010: 'Technology & Engineering / Electronics / Circuits / General',
  TEC008020: 'Technology & Engineering / Electronics / Circuits / Integrated',
  TEC008030: 'Technology & Engineering / Electronics / Circuits / Logic',
  TEC008040: 'Technology / Electronics / Circuits / Printed',
  TEC008050: 'Technology & Engineering / Electronics / Circuits / VLSI & ULSI',
  TEC008060: 'Technology & Engineering / Electronics / Digital',
  TEC008070: 'Technology & Engineering / Electronics / Microelectronics',
  TEC008080: 'Technology & Engineering / Electronics / Optoelectronics',
  TEC008090: 'Technology & Engineering / Electronics / Semiconductors',
  TEC008100: 'Technology & Engineering / Electronics / Solid State',
  TEC008110: 'Technology & Engineering / Electronics / Transistors',
  TEC009000: 'Technology & Engineering / Engineering (General)',
  TEC009010: 'Technology & Engineering / Chemical & Biochemical',
  TEC009020: 'TECHNOLOGY & ENGINEERING / Civil / General',
  TEC009030: 'Technology / Engineering / Electrical',
  TEC009040: 'Technology/ Engineering / Environmental',
  TEC009050: 'Technology / Engineering / Hydraulic',
  TEC009060: 'Technology & Engineering / Industrial Engineering',
  TEC009070: 'Technology & Engineering / Mechanical',
  TEC009080: 'Technology / Engineering / Nuclear',
  TEC009090: 'Technology & Engineering / Automotive',
  TEC009100: 'Technology & Engineering / Civil / Bridges',
  TEC009110: 'Technology & Engineering / Civil / Dams & Reservoirs',
  TEC009120: 'Technology & Engineering / Civil / Earthquake',
  TEC009130: 'Technology & Engineering / Civil / Flood Control',
  TEC009140: 'Technology & Engineering / Civil / Highway & Traffic',
  TEC009150: 'Technology & Engineering / Civil / Soil & Rock',
  TEC009160: 'Technology & Engineering / Civil / Transportation',
  TEC010000: 'Technology & Engineering/ Environmental / General',
  TEC010010: 'Technology & Engineering / Environmental / Pollution Control',
  TEC010020: 'Technology & Engineering / Environmental / Waste Management',
  TEC010030: 'Technology & Engineering / Environmental / Water Supply',
  TEC011000: 'Technology & Engineering / Fiber Optics',
  TEC012000: 'Technology & Engineering/ Food Science / General',
  TEC013000: 'Technology & Engineering / Fracture Mechanics',
  TEC014000: 'Technology & Engineering / Hydraulics',
  TEC015000: 'Technology & Engineering / Imaging Systems',
  TEC016000: 'Technology & Engineering / Industrial Design / General',
  TEC016010: 'Technology & Engineering / Industrial Design / Packaging',
  TEC016020: 'Technology & Engineering / Industrial Design / Product',
  TEC017000: 'Technology & Engineering / Industrial Health & Safety',
  TEC018000: 'Technology & Engineering / Industrial Technology',
  TEC019000: 'Technology & Engineering / Lasers & Photonics',
  TEC020000: 'Technology & Engineering / Manufacturing',
  TEC021000: 'Technology & Engineering/ Materials Science / General',
  TEC022000: 'Technology & Engineering / Measurement',
  TEC023000: 'Technology & Engineering / Metallurgy',
  TEC024000: 'Technology & Engineering / Microwaves',
  TEC025000: 'Technology & Engineering / Military Science',
  TEC026000: 'Technology & Engineering / Mining',
  TEC027000: 'Technology & Engineering / Nanotechnology & MEMS',
  TEC028000: 'Technology & Engineering / Power Resources / Nuclear',
  TEC029000: 'Technology & Engineering / Operations Research',
  TEC030000: 'Technology & Engineering / Optics',
  TEC031000: 'TECHNOLOGY & ENGINEERING / Power Resources / General',
  TEC031010: 'Technology & Engineering / Power Resources / Alternative & Renewable',
  TEC031020: 'Technology & Engineering / Power Resources / Electrical',
  TEC031030: 'Technology & Engineering / Power Resources / Fossil Fuels',
  TEC032000: 'Technology & Engineering / Quality Control',
  TEC033000: 'Technology & Engineering / Radar',
  TEC034000: 'Technology & Engineering / Radio',
  TEC035000: 'Technology & Engineering / Reference',
  TEC036000: 'Technology & Engineering / Remote Sensing & Geographic Information Systems',
  TEC037000: 'Technology & Engineering / Robotics',
  TEC038000: 'Technology / Scanning Systems',
  TEC039000: 'Technology & Engineering / Superconductors & Superconductivity',
  TEC040000: 'Technology & Engineering / Technical & Manufacturing Industries & Trades',
  TEC041000: 'Technology & Engineering / Telecommunications',
  TEC042000: 'Technology/ Telephone Systems',
  TEC043000: 'Technology & Engineering / Television & Video',
  TEC044000: 'Technology & Engineering / Technical Writing',
  TEC045000: 'Technology & Engineering / Fire Science',
  TEC046000: 'Technology & Engineering / Machinery',
  TEC047000: 'Technology & Engineering / Petroleum',
  TEC048000: 'Technology & Engineering / Cartography',
  TEC049000: 'Technology & Engineering / Fisheries & Aquaculture',
  TEC050000: 'Technology & Engineering / Holography',
  TEC051000: 'Technology/ Hydrology',
  TEC052000: 'Technology & Engineering / Social Aspects',
  TEC053000: 'Technology/ Spectroscopy',
  TEC054000: 'Technology & Engineering / Surveying',
  TEC055000: 'Technology & Engineering / Textiles & Polymers',
  TEC056000: 'Technology & Engineering / History',
  TEC057000: 'Technology & Engineering / Inventions',
  TEC058000: 'Technology & Engineering / Pest Control',
  TEC059000: 'Technology & Engineering / Biomedical',
  TEC060000: 'Technology & Engineering / Marine & Naval',
  TEC061000: 'Technology & Engineering / Mobile & Wireless Communications',
  TEC062000: 'Technology & Engineering / Project Management',
  TEC063000: 'Technology & Engineering / Structural',
  TEC064000: 'Technology & Engineering / Sensors',
  TEC065000: 'Technology & Engineering / Emergency Management',
  TEC066000: 'Technology & Engineering / Research',
  TEC067000: 'Technology & Engineering / Signals & Signal Processing',
  TEC068000: 'Technology & Engineering / Tribology',
  TEC069000: 'Technology & Engineering / Welding ',
  TRA000000: 'Transportation / General',
  TRA001000: 'Transportation / Automotive / General',
  TRA001010: 'Transportation / Automotive / Antique & Classic',
  TRA001020: 'Transportation / Automotive / Buyer\u2019s Guides',
  TRA001030: 'Transportation / Automotive / Customizing',
  TRA001040: 'Transportation/ Automotive / Domestic / General',
  TRA001050: 'Transportation / Automotive / History',
  TRA001060: 'Transportation / Automotive / Pictorial',
  TRA001070: 'Transportation/ Automotive / Domestic / Repair & Maintenance',
  TRA001080: 'Transportation / Automotive / Driver Education',
  TRA001090: 'Transportation/ Automotive / Foreign / General',
  TRA001100: 'Transportation/ Automotive / Foreign / History',
  TRA001110: 'Transportation/ Automotive / Foreign / Pictorial',
  TRA001120: 'Transportation/ Automotive / Foreign / Repair & Maintenance',
  TRA001130: 'Transportation/ Automotive / High Performance & Engine Rebuilding',
  TRA001140: 'Transportation / Automotive / Repair & Maintenance',
  TRA001150: 'Transportation / Automotive / Trucks',
  TRA002000: 'Transportation / Aviation / General',
  TRA002010: 'Transportation / Aviation / History',
  TRA002020: 'Transportation/ Aviation / Pictorial',
  TRA002030: 'Transportation / Aviation / Repair & Maintenance',
  TRA002040: 'Transportation / Aviation / Commercial',
  TRA002050: 'Transportation / Aviation / Piloting & Flight Instruction',
  TRA003000: 'Transportation / Motorcycles / General',
  TRA003010: 'Transportation / Motorcycles / History',
  TRA003020: 'Transportation / Motorcycles / Pictorial',
  TRA003030: 'Transportation / Motorcycles / Repair & Maintenance',
  TRA004000: 'Transportation / Railroads / General',
  TRA004010: 'Transportation / Railroads / History',
  TRA004020: 'Transportation / Railroads / Pictorial',
  TRA005000: 'Transportation/ Reference',
  TRA006000: 'Transportation / Ships & Shipbuilding / General',
  TRA006010: 'Transportation / Ships & Shipbuilding / History',
  TRA006020: 'Transportation / Ships & Shipbuilding / Pictorial',
  TRA006030: 'Transportation / Ships & Shipbuilding / Repair & Maintenance',
  TRA006040: 'Transportation / Ships & Shipbuilding / Submarines ',
  TRA007000: 'Transportation/ Commercial',
  TRA008000: 'Transportation / Navigation',
  TRA009000: 'Transportation / Public Transportation',
  TRA010000: 'Transportation / Bicycles',
  TRU000000: 'True Crime / General',
  TRU001000: 'True Crime / Espionage',
  TRU002000: 'True Crime / Murder / General',
  TRU002010: 'True Crime / Murder / Serial Killers',
  TRU003000: 'True Crime / Organized Crime',
  TRU004000: 'True Crime / Hoaxes & Deceptions',
  TRU005000: 'True Crime / White Collar Crime',
  TRV000000: 'Travel / General',
  TRV001000: 'Travel / Special Interest / Adventure',
  TRV002000: 'TRAVEL / Africa / General',
  TRV002010: 'Travel / Africa / Central',
  TRV002020: 'Travel / Africa / East',
  TRV002030: 'Travel / Africa / Kenya',
  TRV002040: 'Travel / Africa / Morocco',
  TRV002050: 'Travel / Africa / North',
  TRV002060: 'Travel / Africa / Republic of South Africa',
  TRV002070: 'Travel / Africa / South',
  TRV002080: 'Travel / Africa / West',
  TRV003000: 'Travel / Asia / General',
  TRV003010: 'Travel / Asia / Central',
  TRV003020: 'Travel/ Asia / East / China',
  TRV003030: 'Travel/ Asia / East / General',
  TRV003040: 'Travel / Asia / India & South Asia',
  TRV003050: 'Travel/ Asia / East / Japan',
  TRV003060: 'Travel / Asia / Southeast',
  TRV003070: 'Travel / Asia / Southwest',
  TRV004000: 'Travel / Australia & Oceania',
  TRV005000: 'Travel / Food, Lodging & Transportation / Bed & Breakfast',
  TRV006000: 'Travel / Canada / General',
  TRV006010: 'Travel / Canada / Atlantic Provinces (NB, NL, NS, PE)',
  TRV006020: 'TRAVEL / Canada / Ontario (ON)',
  TRV006030: 'Travel / Canada / Prairie Provinces (MB, SK)',
  TRV006040: 'Travel / Canada / Territories & Nunavut (NT, NU, YT)',
  TRV006050: 'Travel / Canada / Western Provinces (AB, BC)',
  TRV006060: 'Travel / Canada / Quebec (QC)',
  TRV007000: 'Travel / Caribbean & West Indies',
  TRV008000: 'Travel / Central America',
  TRV009000: 'Travel / Europe / General',
  TRV009010: 'Travel / Europe / Austria',
  TRV009020: 'Travel / Europe / Benelux Countries (Belgium, Netherlands, Luxembourg)',
  TRV009030: 'Travel / Europe / Denmark',
  TRV009040: 'Travel / Europe / Eastern',
  TRV009050: 'Travel / Europe / France',
  TRV009060: 'Travel / Europe / Germany',
  TRV009070: 'Travel / Europe / Great Britain',
  TRV009080: 'Travel / Europe / Greece',
  TRV009090: 'Travel / Europe / Iceland & Greenland',
  TRV009100: 'Travel / Europe / Ireland',
  TRV009110: 'Travel / Europe / Italy',
  TRV009120: 'Travel / Europe / Scandinavia (Finland, Norway, Sweden)',
  TRV009130: 'Travel / Europe / Spain & Portugal',
  TRV009140: 'Travel / Europe / Switzerland',
  TRV009150: 'Travel / Europe / Western',
  TRV009160: 'Travel / Europe / Cyprus',
  TRV010000: 'Travel / Essays & Travelogues',
  TRV011000: 'Travel / Special Interest / Family',
  TRV012000: 'Travel / Former Soviet Republics',
  TRV013000: 'Travel / Food, Lodging & Transportation / Hotels, Inns & Hostels',
  TRV014000: 'Travel / Mexico',
  TRV015000: 'Travel / Middle East / General',
  TRV015010: 'Travel / Middle East / Egypt',
  TRV015020: 'Travel / Middle East / Israel',
  TRV015030: 'Travel / Middle East / Turkey',
  TRV016000: 'Travel / Museums, Tours, Points of Interest',
  TRV017000: 'Travel / North America',
  TRV018000: 'Travel / Parks & Campgrounds',
  TRV019000: 'Travel / Pictorials ',
  TRV020000: 'Travel / Polar Regions',
  TRV021000: 'Travel / Reference',
  TRV022000: 'Travel / Food, Lodging & Transportation / Restaurants',
  TRV023000: 'Travel / Russia',
  TRV024000: 'TRAVEL / South America / General',
  TRV024010: 'Travel / South America / Argentina',
  TRV024020: 'Travel / South America / Brazil',
  TRV024030: 'Travel / South America / Chile & Easter Island',
  TRV024040: 'Travel / South America / Ecuador & Galapagos Islands',
  TRV024050: 'Travel / South America / Peru',
  TRV025000: 'Travel / United States / General',
  TRV025010: 'Travel / United States / Midwest / General',
  TRV025020: 'Travel / United States / Midwest / East North Central (IL, IN, MI, OH, WI)',
  TRV025030: 'Travel / United States / Midwest / West North Central (IA, KS, MN, MO, ND, NE, SD)',
  TRV025040: 'Travel / United States / Northeast / General',
  TRV025050: 'Travel / United States / Northeast / Middle Atlantic (NJ, NY, PA)',
  TRV025060: 'Travel / United States / Northeast / New England (CT, MA, ME, NH, RI, VT)',
  TRV025070: 'Travel / United States / South / General',
  TRV025080: 'Travel / United States / South / East South Central (AL, KY, MS, TN)',
  TRV025090: 'Travel / United States / South / South Atlantic (DC, DE, FL, GA, MD, NC, SC, VA, WV)',
  TRV025100: 'Travel / United States / South / West South Central (AR, LA, OK, TX)',
  TRV025110: 'Travel / United States / West / General',
  TRV025120: 'Travel / United States / West / Mountain (AZ, CO, ID, MT, NM, NV, UT, WY)',
  TRV025130: 'Travel/ United States / West / Pacific (AK, CA, HI, OR, WA)',
  TRV026000: 'Travel / Special Interest / General',
  TRV026010: 'Travel / Special Interest / Business',
  TRV026020: 'Travel / Special Interest / Ecotourism',
  TRV026030: 'Travel / Special Interest / Disabilities & Special Needs',
  TRV026040: 'Travel / Special Interest / Pets',
  TRV026050: 'Travel / Special Interest / Senior',
  TRV026060: 'Travel / Special Interest / Religious',
  TRV026070: 'Travel / Special Interest / LGBTQ+',
  TRV026080: 'Travel / Special Interest / Sports',
  TRV026090: 'Travel / Special Interest / Literary',
  TRV026100: 'Travel / Special Interest / Bicycling',
  TRV026110: 'Travel / Special Interest / Military',
  TRV027000: 'Travel / Maps & Road Atlases ',
  TRV028000: 'Travel / Food, Lodging & Transportation / Cruises',
  TRV029000: 'Travel / Special Interest / Amusement & Theme Parks',
  TRV030000: 'Travel / Food, Lodging & Transportation / Resorts & Spas',
  TRV031000: 'Travel / Food, Lodging & Transportation / Road Travel',
  TRV032000: 'Travel / Special Interest / Shopping',
  TRV033000: 'Travel / Special Interest / Budget',
  TRV034000: 'Travel / Special Interest / Hikes & Walks',
  TRV035000: 'Travel / Food, Lodging & Transportation / Rail Travel',
  TRV036000: 'Travel / Food, Lodging & Transportation / General',
  YAF000000: 'Young Adult Fiction / General ',
  YAF001000: 'Young Adult Fiction / Action & Adventure / General ',
  YAF001010: 'Young Adult Fiction / Action & Adventure / Pirates ',
  YAF001020: 'Young Adult Fiction / Action & Adventure / Survival Stories ',
  YAF002000: 'Young Adult Fiction / Animals / General ',
  YAF002010: 'Young Adult Fiction / Animals / Horses ',
  YAF002020: 'Young Adult Fiction / Animals / Marine Life ',
  YAF002030: 'Young Adult Fiction / Animals / Mythical Creatures ',
  YAF002040: 'Young Adult Fiction / Animals / Pets ',
  YAF003000: 'YOUNG ADULT FICTION / Science Fiction / Apocalyptic & Post-Apocalyptic',
  YAF004000: 'Young Adult Fiction / Art ',
  YAF005000: 'Young Adult Fiction / Biographical ',
  YAF006000: 'Young Adult Fiction / Books & Libraries ',
  YAF007000: 'Young Adult Fiction / Boys & Men ',
  YAF008000: 'Young Adult Fiction / Careers, Occupations, Internships ',
  YAF009000: 'Young Adult Fiction / Classics ',
  YAF010000: 'Young Adult Fiction / Comics & Graphic Novels / General ',
  YAF010010: 'Young Adult Fiction / Comics & Graphic Novels / Manga ',
  YAF010020: 'Young Adult Fiction / Comics & Graphic Novels / Media Tie-In ',
  YAF010030: 'YOUNG ADULT FICTION / Comics & Graphic Novels / Science Fiction',
  YAF010040: 'Young Adult Fiction / Comics & Graphic Novels / Superheroes ',
  YAF011000: 'Young Adult Fiction / Coming of Age ',
  YAF012000: 'Young Adult Fiction / Computers & Digital Media ',
  YAF013000: 'Young Adult Fiction / Cooking & Food ',
  YAF014000: 'Young Adult Fiction / Diversity & Multicultural ',
  YAF015000: 'Young Adult Fiction / Dystopian ',
  YAF016000: 'Young Adult Fiction / Epistolary (Letters & Diaries) ',
  YAF017000: 'Young Adult Fiction / Fairy Tales & Folklore / General ',
  YAF017010: 'Young Adult Fiction / Fairy Tales & Folklore / Adaptations ',
  YAF017020: 'Young Adult Fiction / Fairy Tales & Folklore / Anthologies ',
  YAF017030: 'Young Adult Fiction / Fairy Tales & Folklore / Country & Ethnic ',
  YAF018000: 'Young Adult Fiction / Family / General  ',
  YAF018010: 'Young Adult Fiction / Family / Adoption ',
  YAF018020: 'Young Adult Fiction / Family / Alternative Family ',
  YAF018030: 'Young Adult Fiction / Family / Marriage & Divorce ',
  YAF018040: 'Young Adult Fiction / Family / Multigenerational ',
  YAF018050: 'Young Adult Fiction / Family / Orphans & Foster Homes ',
  YAF018060: 'Young Adult Fiction / Family / Parents ',
  YAF018070: 'Young Adult Fiction / Family / Siblings ',
  YAF018080: 'Young Adult Fiction / Family / Blended Families',
  YAF019000: 'Young Adult Fiction / Fantasy / General ',
  YAF019010: 'Young Adult Fiction / Fantasy / Contemporary ',
  YAF019020: 'Young Adult Fiction / Fantasy / Dark Fantasy ',
  YAF019030: 'Young Adult Fiction / Fantasy / Epic ',
  YAF019040: 'Young Adult Fiction / Fantasy / Historical ',
  YAF019050: 'Young Adult Fiction / Fantasy / Wizards & Witches ',
  YAF020000: 'Young Adult Fiction / Fashion & Beauty ',
  YAF021000: 'Young Adult Fiction / Ghost Stories ',
  YAF022000: 'Young Adult Fiction / Girls & Women ',
  YAF023000: 'Young Adult Fiction / Health & Daily Living / General ',
  YAF023010: 'Young Adult Fiction / Health & Daily Living / Diseases, Illnesses & Injuries ',
  YAF024000: 'Young Adult Fiction / Historical / General ',
  YAF024010: 'Young Adult Fiction / Historical / Africa ',
  YAF024020: 'Young Adult Fiction / Historical / Ancient Civilizations ',
  YAF024030: 'Young Adult Fiction / Historical / Asia ',
  YAF024040: 'Young Adult Fiction / Historical / Canada ',
  YAF024050: 'Young Adult Fiction / Historical / Europe ',
  YAF024060: 'Young Adult Fiction / Historical / Exploration & Discovery ',
  YAF024070: 'Young Adult Fiction / Historical / Holocaust ',
  YAF024080: 'Young Adult Fiction / Historical / Medieval ',
  YAF024090: 'Young Adult Fiction / Historical / Middle East ',
  YAF024100: 'Young Adult Fiction / Historical / Military & Wars ',
  YAF024110: 'Young Adult Fiction / Historical / Prehistory ',
  YAF024120: 'Young Adult Fiction / Historical / Renaissance ',
  YAF024130: 'Young Adult Fiction / Historical / United States / General ',
  YAF024140: 'Young Adult Fiction / Historical / United States / Colonial & Revolutionary Periods ',
  YAF024150: 'Young Adult Fiction / Historical / United States / 19th Century ',
  YAF024160: 'Young Adult Fiction / Historical / United States / Civil War Period (1850-1877) ',
  YAF024170: 'Young Adult Fiction / Historical / United States / 20th Century ',
  YAF024180: 'Young Adult Fiction / Historical / United States / 21st Century ',
  YAF025000: 'Young Adult Fiction / Holidays & Celebrations ',
  YAF026000: 'Young Adult Fiction / Horror ',
  YAF027000: 'Young Adult Fiction / Humorous / General ',
  YAF027010: 'Young Adult Fiction / Humorous / Black Comedy ',
  YAF027020: 'Young Adult Fiction / Satire',
  YAF028000: 'Young Adult Fiction / Interactive Adventures ',
  YAF029000: 'Young Adult Fiction / Law & Crime ',
  YAF030000: 'Young Adult Fiction / Legends, Myths, Fables / General ',
  YAF030010: 'Young Adult Fiction / Legends, Myths, Fables / Arthurian ',
  YAF030020: 'Young Adult Fiction / Legends, Myths, Fables / Greek & Roman ',
  YAF031000: 'Young Adult Fiction / LGBTQ+',
  YAF032000: 'Young Adult Fiction / Lifestyles / City & Town Life ',
  YAF033000: 'Young Adult Fiction / Lifestyles / Country Life ',
  YAF034000: 'Young Adult Fiction / Lifestyles / Farm & Ranch Life ',
  YAF035000: 'Young Adult Fiction / Light Novel (Ranobe) ',
  YAF036000: 'Young Adult Fiction / Literary ',
  YAF037000: 'Young Adult Fiction / Loners & Outcasts ',
  YAF038000: 'Young Adult Fiction / Magical Realism ',
  YAF039000: 'Young Adult Fiction / Media Tie-In ',
  YAF040000: 'Young Adult Fiction / Mermaids ',
  YAF041000: 'Young Adult Fiction / Monsters ',
  YAF042000: 'Young Adult Fiction / Mysteries & Detective Stories ',
  YAF043000: 'YOUNG ADULT FICTION / Science & Nature / General',
  YAF043010: 'YOUNG ADULT FICTION / Science & Nature / Environment',
  YAF044000: 'Young Adult Fiction / Novels in Verse ',
  YAF045000: 'Young Adult Fiction / Paranormal, Occult & Supernatural ',
  YAF046000: 'Young Adult Fiction / People & Places / General ',
  YAF046010: 'Young Adult Fiction/ People & Places / Indigenous',
  YAF046020: 'Young Adult Fiction / People & Places / Africa ',
  YAF046030: 'Young Adult Fiction / People & Places / Asia ',
  YAF046040: 'Young Adult Fiction / People & Places / Australia & Oceania ',
  YAF046050: 'Young Adult Fiction / People & Places / Canada ',
  YAF046060: 'Young Adult Fiction / People & Places / Caribbean & Latin America ',
  YAF046070: 'Young Adult Fiction / People & Places / Europe ',
  YAF046080: 'Young Adult Fiction / People & Places / Mexico ',
  YAF046090: 'Young Adult Fiction / People & Places / Middle East ',
  YAF046100: 'Young Adult Fiction / People & Places / Polar Regions ',
  YAF046110: 'Young Adult Fiction / People & Places / United States / General ',
  YAF046120: 'Young Adult Fiction/ People & Places / United States / African American & Black',
  YAF046130: 'Young Adult Fiction / People & Places / United States / Asian American ',
  YAF046140: 'Young Adult Fiction / People & Places / United States / Hispanic & Latino ',
  YAF046150: 'Young Adult Fiction / People & Places / United States / Native American ',
  YAF047000: 'Young Adult Fiction / Performing Arts / General ',
  YAF047010: 'Young Adult Fiction / Performing Arts / Dance ',
  YAF047020: 'Young Adult Fiction / Performing Arts / Film ',
  YAF047030: 'Young Adult Fiction / Performing Arts / Music ',
  YAF047040: 'Young Adult Fiction / Performing Arts / Television & Radio ',
  YAF047050: 'Young Adult Fiction / Performing Arts / Theater & Musicals ',
  YAF048000: 'Young Adult Fiction / Poetry ',
  YAF049000: 'Young Adult Fiction / Politics & Government ',
  YAF050000: 'Young Adult Fiction / Recycling & Green Living ',
  YAF051000: 'Young Adult Fiction / Religious / General ',
  YAF051010: 'Young Adult Fiction / Religious / Agnostic & Atheist ',
  YAF051020: 'Young Adult Fiction / Religious / Buddhist ',
  YAF051030: 'Young Adult Fiction / Religious / Christian / General ',
  YAF051040: 'Young Adult Fiction / Religious / Christian / Action & Adventure ',
  YAF051050: 'Young Adult Fiction / Religious / Christian / Comics & Graphic Novels ',
  YAF051060: 'Young Adult Fiction / Religious / Christian / Fantasy ',
  YAF051070: 'Young Adult Fiction / Religious / Christian / Historical ',
  YAF051080: 'Young Adult Fiction / Religious / Christian / Mysteries & Detective Stories ',
  YAF051090: 'Young Adult Fiction / Religious / Christian / Relationships ',
  YAF051100: 'Young Adult Fiction / Religious / Christian / Science Fiction ',
  YAF051110: 'Young Adult Fiction / Religious / Christian / Social Issues ',
  YAF051120: 'Young Adult Fiction / Religious / Hindu ',
  YAF051130: 'Young Adult Fiction / Religious / Jewish ',
  YAF051140: 'Young Adult Fiction / Religious / Muslim ',
  YAF052000: 'Young Adult Fiction / Romance / General ',
  YAF052010: 'Young Adult Fiction / Romance / Clean & Wholesome ',
  YAF052020: 'Young Adult Fiction / Romance / Contemporary ',
  YAF052030: 'Young Adult Fiction / Romance / Historical ',
  YAF052040: 'Young Adult Fiction / Romance / LGBTQ+',
  YAF052050: 'Young Adult Fiction / Romance / Paranormal ',
  YAF052060: 'Young Adult Fiction / Romance / Romantic Comedy ',
  YAF053000: 'Young Adult Fiction / Royalty ',
  YAF054000: 'Young Adult Fiction / School & Education / General ',
  YAF054010: 'Young Adult Fiction / School & Education / Boarding School & Prep School ',
  YAF054020: 'Young Adult Fiction / School & Education / College & University ',
  YAF055000: 'YOUNG ADULT FICTION / Technology',
  YAF056000: 'Young Adult Fiction / Science Fiction / General ',
  YAF056010: 'Young Adult Fiction / Science Fiction / Alien Contact ',
  YAF056020: 'Young Adult Fiction / Science Fiction / Space Opera ',
  YAF057000: 'Young Adult Fiction / Short Stories ',
  YAF058000: 'Young Adult Fiction / Social Themes / General  ',
  YAF058010: 'Young Adult Fiction / Social Themes / Assimilation ',
  YAF058020: 'Young Adult Fiction / Social Themes / Bullying ',
  YAF058030: 'Young Adult Fiction / Social Themes / Class Differences ',
  YAF058040: 'Young Adult Fiction / Social Themes / Dating & Sex ',
  YAF058050: 'YOUNG ADULT FICTION / Social Themes / Death, Grief, Bereavement',
  YAF058060: 'Young Adult Fiction / Social Themes / Depression ',
  YAF058070: 'YOUNG ADULT FICTION / Disabilities & Special Needs',
  YAF058080: 'Young Adult Fiction / Social Themes / Drugs, Alcohol, Substance Abuse ',
  YAF058090: 'Young Adult Fiction / Social Themes / Eating Disorders & Body Image ',
  YAF058100: 'Young Adult Fiction / Social Themes / Emigration & Immigration ',
  YAF058110: 'Young Adult Fiction / Social Themes / Emotions & Feelings ',
  YAF058120: 'Young Adult Fiction / Social Themes / Friendship ',
  YAF058130: 'Young Adult Fiction / Social Themes / Homelessness & Poverty ',
  YAF058140: 'Young Adult Fiction / Social Themes / Mental Illness ',
  YAF058150: 'Young Adult Fiction / Social Themes / New Experience ',
  YAF058160: 'Young Adult Fiction / Social Themes / Peer Pressure ',
  YAF058170: 'Young Adult Fiction / Social Themes / Physical & Emotional Abuse  ',
  YAF058180: 'Young Adult Fiction / Social Themes / Pregnancy ',
  YAF058190: 'Young Adult Fiction / Social Themes / Prejudice & Racism ',
  YAF058200: 'Young Adult Fiction / Social Themes / Religion & Faith ',
  YAF058210: 'Young Adult Fiction / Social Themes / Runaways ',
  YAF058220: 'Young Adult Fiction / Social Themes / Self-Esteem & Self-Reliance ',
  YAF058230: 'Young Adult Fiction/ Social Themes / Cutting & Self-Harm',
  YAF058240: 'Young Adult Fiction / Social Themes / Sexual Abuse ',
  YAF058250: 'Young Adult Fiction / Social Themes / Suicide ',
  YAF058260: 'Young Adult Fiction / Social Themes / Values & Virtues ',
  YAF058270: 'Young Adult Fiction / Social Themes / Violence ',
  YAF059000: 'Young Adult Fiction / Sports & Recreation / General ',
  YAF059010: 'Young Adult Fiction / Sports & Recreation / Baseball & Softball ',
  YAF059020: 'Young Adult Fiction / Sports & Recreation / Basketball ',
  YAF059030: 'Young Adult Fiction / Sports & Recreation / Camping & Outdoor Activities ',
  YAF059040: 'Young Adult Fiction / Sports & Recreation / Equestrian ',
  YAF059050: 'Young Adult Fiction / Sports & Recreation / Extreme Sports ',
  YAF059060: 'Young Adult Fiction / Sports & Recreation / Football ',
  YAF059070: 'Young Adult Fiction / Sports & Recreation / Gymnastics ',
  YAF059080: 'Young Adult Fiction / Sports & Recreation / Hockey ',
  YAF059090: 'Young Adult Fiction / Sports & Recreation / Martial Arts ',
  YAF059100: 'Young Adult Fiction / Sports & Recreation / Skateboarding ',
  YAF059110: 'Young Adult Fiction / Sports & Recreation / Soccer ',
  YAF059120: 'Young Adult Fiction / Sports & Recreation / Track & Field ',
  YAF059130: 'Young Adult Fiction / Sports & Recreation / Water Sports ',
  YAF059140: 'Young Adult Fiction / Sports & Recreation / Winter Sports ',
  YAF060000: 'Young Adult Fiction / Steampunk ',
  YAF061000: 'Young Adult Fiction / Superheroes ',
  YAF062000: 'Young Adult Fiction / Thrillers & Suspense ',
  YAF063000: 'YOUNG ADULT FICTION / Science Fiction / Time Travel',
  YAF064000: 'Young Adult Fiction / Travel & Transportation / General ',
  YAF064010: 'Young Adult Fiction / Travel & Transportation / Car & Road Trips ',
  YAF065000: 'Young Adult Fiction / Vampires ',
  YAF066000: 'Young Adult Fiction / Visionary & Metaphysical ',
  YAF067000: 'Young Adult Fiction / War & Military ',
  YAF068000: 'Young Adult Fiction / Werewolves & Shifters ',
  YAF069000: 'Young Adult Fiction / Westerns ',
  YAF070000: 'Young Adult Fiction / Zombies ',
  YAN000000: 'Young Adult Nonfiction / General ',
  YAN001000: 'Young Adult Nonfiction / Activity Books ',
  YAN002000: 'Young Adult Nonfiction / Adventure & Adventurers ',
  YAN003000: 'Young Adult Nonfiction / Animals / General ',
  YAN003010: 'Young Adult Nonfiction / Animals / Animal Welfare ',
  YAN003020: 'Young Adult Nonfiction / Animals / Birds ',
  YAN003030: 'Young Adult Nonfiction / Animals / Marine Life ',
  YAN004000: 'Young Adult Nonfiction / Architecture ',
  YAN005000: 'Young Adult Nonfiction / Art / General ',
  YAN005010: 'Young Adult Nonfiction / Art / Cartooning ',
  YAN005020: 'Young Adult Nonfiction / Art / Drawing ',
  YAN005030: 'Young Adult Nonfiction / Art / Fashion ',
  YAN005040: 'Young Adult Nonfiction / Art / History ',
  YAN005050: 'Young Adult Nonfiction / Art / Painting ',
  YAN005060: 'Young Adult Nonfiction / Art / Sculpture ',
  YAN005070: 'Young Adult Nonfiction / Art / Techniques ',
  YAN006000: 'Young Adult Nonfiction / Biography & Autobiography / General ',
  YAN006010: 'Young Adult Nonfiction / Biography & Autobiography / Art ',
  YAN006020: 'YOUNG ADULT NONFICTION / Biography & Autobiography / Cultural, Ethnic & Regional',
  YAN006030: 'Young Adult Nonfiction / Biography & Autobiography / Historical ',
  YAN006040: 'Young Adult Nonfiction / Biography & Autobiography / Literary ',
  YAN006050: 'Young Adult Nonfiction / Biography & Autobiography / Music ',
  YAN006060: 'Young Adult Nonfiction / Biography & Autobiography / Performing Arts ',
  YAN006070: 'Young Adult Nonfiction / Biography & Autobiography / Political ',
  YAN006080: 'Young Adult Nonfiction / Biography & Autobiography / Presidents & First Families (U.S.) ',
  YAN006090: 'Young Adult Nonfiction / Biography & Autobiography / Religious ',
  YAN006100: 'Young Adult Nonfiction / Biography & Autobiography / Royalty ',
  YAN006110: 'Young Adult Nonfiction / Biography & Autobiography / Science & Technology ',
  YAN006120: 'Young Adult Nonfiction / Biography & Autobiography / Social Activists ',
  YAN006130: 'Young Adult Nonfiction / Biography & Autobiography / Sports & Recreation ',
  YAN006140: 'Young Adult Nonfiction / Biography & Autobiography / Women ',
  YAN007000: 'Young Adult Nonfiction / Paranormal & Supernatural',
  YAN008000: 'Young Adult Nonfiction / Books & Libraries ',
  YAN009000: 'Young Adult Nonfiction / Boys & Men ',
  YAN010000: 'Young Adult Nonfiction / Business & Economics ',
  YAN011000: 'Young Adult Nonfiction / Careers ',
  YAN012000: 'Young Adult Nonfiction / Comics & Graphic Novels / General ',
  YAN012010: 'Young Adult Nonfiction / Comics & Graphic Novels / Biography ',
  YAN012020: 'Young Adult Nonfiction / Comics & Graphic Novels / History ',
  YAN013000: 'Young Adult Nonfiction / Computers / General ',
  YAN013010: 'Young Adult Nonfiction / Computers / Entertainment & Games ',
  YAN013020: 'Young Adult Nonfiction / Computers / Internet ',
  YAN013030: 'YOUNG ADULT NONFICTION / Computers / Coding & Programming',
  YAN013040: 'Young Adult Nonfiction / Computers / Software ',
  YAN014000: 'Young Adult Nonfiction / Cooking & Food ',
  YAN015000: 'Young Adult Nonfiction / Crafts & Hobbies ',
  YAN016000: 'Young Adult Nonfiction / Curiosities & Wonders ',
  YAN017000: 'Young Adult Nonfiction / Drama ',
  YAN018000: 'Young Adult Nonfiction / Family / General  ',
  YAN018010: 'Young Adult Nonfiction / Family / Adoption ',
  YAN018020: 'Young Adult Nonfiction / Family / Alternative Family ',
  YAN018030: 'Young Adult Nonfiction / Family / Marriage & Divorce ',
  YAN018040: 'Young Adult Nonfiction / Family / Multigenerational ',
  YAN018050: 'Young Adult Nonfiction / Family / Orphans & Foster Homes ',
  YAN018060: 'Young Adult Nonfiction / Family / Parents ',
  YAN018070: 'Young Adult Nonfiction / Family / Siblings ',
  YAN018080: 'Young Adult Nonfiction / Family / Blended Families',
  YAN019000: 'Young Adult Nonfiction / Fashion ',
  YAN020000: 'Young Adult Nonfiction / Foreign Language Study / General ',
  YAN020010: 'Young Adult Nonfiction / Foreign Language Study / English as a Second Language ',
  YAN020020: 'Young Adult Nonfiction / Foreign Language Study / French ',
  YAN020030: 'Young Adult Nonfiction / Foreign Language Study / Spanish ',
  YAN021000: 'Young Adult Nonfiction / Games & Activities / General ',
  YAN021010: 'Young Adult Nonfiction / Games & Activities / Puzzles & Word Games ',
  YAN021020: 'Young Adult Nonfiction / Games & Activities / Questions & Answers ',
  YAN022000: 'Young Adult Nonfiction / Gardening ',
  YAN023000: 'Young Adult Nonfiction / Girls & Women ',
  YAN024000: 'Young Adult Nonfiction / Health & Daily Living / General ',
  YAN024010: 'Young Adult Nonfiction / Health & Daily Living / Beauty & Grooming ',
  YAN024020: 'Young Adult Nonfiction / Health & Daily Living / Diet & Nutrition ',
  YAN024030: 'Young Adult Nonfiction / Health & Daily Living / Diseases, Illnesses & Injuries ',
  YAN024040: 'Young Adult Nonfiction / Health & Daily Living / Fitness & Exercise ',
  YAN024050: 'Young Adult Nonfiction / Health & Daily Living / Maturing ',
  YAN024060: 'Young Adult Nonfiction / Health & Daily Living / Physical Impairments ',
  YAN024070: 'Young Adult Nonfiction / Health & Daily Living / Safety ',
  YAN025000: 'Young Adult Nonfiction / History / General ',
  YAN025010: 'Young Adult Nonfiction / History / Africa ',
  YAN025020: 'Young Adult Nonfiction / History / Ancient ',
  YAN025030: 'Young Adult Nonfiction / History / Asia ',
  YAN025040: 'Young Adult Nonfiction / History / Australia & Oceania ',
  YAN025050: 'Young Adult Nonfiction / History / Canada ',
  YAN025060: 'Young Adult Nonfiction / History / Central & South America ',
  YAN025070: 'Young Adult Nonfiction / History / Europe ',
  YAN025080: 'Young Adult Nonfiction / History / Exploration & Discovery ',
  YAN025090: 'Young Adult Nonfiction / History / Holocaust ',
  YAN025100: 'Young Adult Nonfiction / History / Medieval ',
  YAN025110: 'Young Adult Nonfiction / History / Mexico ',
  YAN025120: 'Young Adult Nonfiction / History / Middle East ',
  YAN025130: 'Young Adult Nonfiction / History / Military & Wars ',
  YAN025140: 'Young Adult Nonfiction / History / Modern ',
  YAN025150: 'Young Adult Nonfiction / History / Prehistoric ',
  YAN025160: 'Young Adult Nonfiction / History / Renaissance ',
  YAN025170: 'Young Adult Nonfiction / History / United States / General ',
  YAN025180: 'Young Adult Nonfiction / History / United States / State & Local ',
  YAN025190: 'Young Adult Nonfiction / History / United States / Colonial & Revolutionary Periods ',
  YAN025200: 'Young Adult Nonfiction / History / United States / 19th Century ',
  YAN025210: 'Young Adult Nonfiction / History / United States / Civil War Period (1850-1877) ',
  YAN025220: 'Young Adult Nonfiction / History / United States / 20th Century ',
  YAN025230: 'Young Adult Nonfiction / History / United States / 21st Century ',
  YAN026000: 'Young Adult Nonfiction / Holidays & Celebrations ',
  YAN027000: 'Young Adult Nonfiction / House & Home ',
  YAN028000: 'Young Adult Nonfiction / Humor ',
  YAN029000: 'Young Adult Nonfiction / Inspirational & Personal Growth ',
  YAN030000: 'Young Adult Nonfiction / Language Arts / General ',
  YAN030010: 'Young Adult Nonfiction / Language Arts / Communication ',
  YAN030020: 'Young Adult Nonfiction / Language Arts / Composition & Creative Writing ',
  YAN030030: 'Young Adult Nonfiction / Language Arts / Grammar ',
  YAN030040: 'Young Adult Nonfiction / Language Arts / Journal Writing ',
  YAN030050: 'Young Adult Nonfiction / Language Arts / Vocabulary & Spelling ',
  YAN031000: 'Young Adult Nonfiction / Law & Crime ',
  YAN032000: 'Young Adult Nonfiction / LGBTQ+',
  YAN033000: 'Young Adult Nonfiction / Literary Criticism & Collections ',
  YAN034000: 'Young Adult Nonfiction / Mathematics / General ',
  YAN034010: 'Young Adult Nonfiction / Mathematics / Algebra ',
  YAN034020: 'Young Adult Nonfiction / Mathematics / Geometry ',
  YAN035000: 'Young Adult Nonfiction / Media Studies ',
  YAN036000: 'Young Adult Nonfiction / Media Tie-In ',
  YAN037000: 'Young Adult Nonfiction / Music / General ',
  YAN037010: 'Young Adult Nonfiction / Music / History ',
  YAN037020: 'Young Adult Nonfiction / Music / Instruction & Study ',
  YAN037030: 'Young Adult Nonfiction / Music / Popular ',
  YAN037040: 'Young Adult Nonfiction / Music / Rap & Hip Hop ',
  YAN037050: 'Young Adult Nonfiction / Music / Rock ',
  YAN038000: 'Young Adult Nonfiction / People & Places / General ',
  YAN038010: 'Young Adult Nonfiction/ People & Places / Indigenous',
  YAN038020: 'Young Adult Nonfiction / People & Places / Africa ',
  YAN038030: 'Young Adult Nonfiction / People & Places / Asia ',
  YAN038040: 'Young Adult Nonfiction / People & Places / Australia & Oceania ',
  YAN038050: 'Young Adult Nonfiction / People & Places / Canada ',
  YAN038060: 'Young Adult Nonfiction / People & Places / Caribbean & Latin America ',
  YAN038070: 'Young Adult Nonfiction / People & Places / Europe ',
  YAN038080: 'Young Adult Nonfiction / People & Places / Mexico ',
  YAN038090: 'Young Adult Nonfiction / People & Places / Middle East ',
  YAN038100: 'Young Adult Nonfiction / People & Places / United States / General ',
  YAN038110: 'Young Adult Nonfiction/ People & Places / United States / African American & Black',
  YAN038120: 'Young Adult Nonfiction / People & Places / United States / Asian American ',
  YAN038130: 'Young Adult Nonfiction / People & Places / United States / Hispanic & Latino ',
  YAN038140: 'Young Adult Nonfiction / People & Places / United States / Native American ',
  YAN039000: 'Young Adult Nonfiction / Performing Arts / General ',
  YAN039010: 'Young Adult Nonfiction / Performing Arts / Dance ',
  YAN039020: 'Young Adult Nonfiction / Performing Arts / Film ',
  YAN039030: 'Young Adult Nonfiction / Performing Arts / Television & Radio ',
  YAN039040: 'Young Adult Nonfiction / Performing Arts / Theater & Musicals ',
  YAN040000: 'Young Adult Nonfiction / Personal Finance ',
  YAN041000: 'Young Adult Nonfiction / Philosophy ',
  YAN042000: 'Young Adult Nonfiction / Photography ',
  YAN043000: 'Young Adult Nonfiction / Poetry ',
  YAN044000: 'Young Adult Nonfiction / Public Speaking & Presentation ',
  YAN045000: 'Young Adult Nonfiction / Recycling & Green Living ',
  YAN046000: 'Young Adult Nonfiction / Reference ',
  YAN047000: 'Young Adult Nonfiction / Religion / General ',
  YAN047010: 'Young Adult Nonfiction / Religion / Agnosticism & Atheism ',
  YAN047020: 'Young Adult Nonfiction / Religion / Biblical Stories & Studies ',
  YAN047030: 'Young Adult Nonfiction / Religion / Buddhism ',
  YAN047040: 'Young Adult Nonfiction / Religion / Christianity ',
  YAN047050: 'Young Adult Nonfiction / Religion / Eastern ',
  YAN047060: 'Young Adult Nonfiction / Religion / Hinduism ',
  YAN047070: 'Young Adult Nonfiction / Religion / Islam ',
  YAN047080: 'Young Adult Nonfiction / Religion / Judaism ',
  YAN048000: 'Young Adult Nonfiction / Religious / Christian / General ',
  YAN048010: 'Young Adult Nonfiction / Religious / Christian / Dating ',
  YAN048020: 'Young Adult Nonfiction / Religious / Christian / Devotional & Prayer ',
  YAN048030: 'Young Adult Nonfiction / Religious / Christian / Family & Relationships ',
  YAN048040: 'Young Adult Nonfiction / Religious / Christian / Inspirational ',
  YAN049000: 'Young Adult Nonfiction / School & Education ',
  YAN050000: 'Young Adult Nonfiction / Science & Nature / General  ',
  YAN050010: 'Young Adult Nonfiction / Science & Nature / Anatomy & Physiology ',
  YAN050020: 'Young Adult Nonfiction / Science & Nature / Astronomy ',
  YAN050030: 'Young Adult Nonfiction / Science & Nature / Biology ',
  YAN050040: 'Young Adult Nonfiction / Science & Nature / Botany ',
  YAN050050: 'Young Adult Nonfiction / Science & Nature / Chemistry ',
  YAN050060: 'Young Adult Nonfiction / Science & Nature / Disasters ',
  YAN050070: 'Young Adult Nonfiction / Science & Nature / Earth Sciences ',
  YAN050080: 'Young Adult Nonfiction / Science & Nature / Environmental Conservation & Protection ',
  YAN050090: 'Young Adult Nonfiction / Science & Nature / Environmental Science & Ecosystems ',
  YAN050100: 'Young Adult Nonfiction / Science & Nature / Experiments & Projects ',
  YAN050110: 'Young Adult Nonfiction / Science & Nature / History of Science ',
  YAN050120: 'Young Adult Nonfiction / Science & Nature / Physics ',
  YAN050130: 'Young Adult Nonfiction / Science & Nature / Zoology ',
  YAN051000: 'Young Adult Nonfiction / Social Topics / General  ',
  YAN051010: 'Young Adult Nonfiction / Social Topics / Assimilation ',
  YAN051020: 'Young Adult Nonfiction / Social Topics / Bullying ',
  YAN051030: 'Young Adult Nonfiction / Social Topics / Class Differences ',
  YAN051040: 'Young Adult Nonfiction / Social Topics / Dating & Sex ',
  YAN051050: 'YOUNG ADULT NONFICTION / Social Topics / Death, Grief, Bereavement',
  YAN051060: 'Young Adult Nonfiction / Social Topics / Depression & Mental Illness ',
  YAN051070: 'Young Adult Nonfiction / Social Topics / Drugs, Alcohol, Substance Abuse ',
  YAN051080: 'Young Adult Nonfiction / Social Topics / Eating Disorders & Body Image ',
  YAN051090: 'Young Adult Nonfiction / Social Topics / Emigration & Immigration ',
  YAN051100: 'Young Adult Nonfiction / Social Topics / Emotions & Feelings ',
  YAN051110: 'Young Adult Nonfiction / Social Topics / Friendship ',
  YAN051120: 'Young Adult Nonfiction / Social Topics / Homelessness & Poverty ',
  YAN051140: 'Young Adult Nonfiction / Social Topics / Manners & Etiquette ',
  YAN051150: 'Young Adult Nonfiction / Social Topics / Peer Pressure ',
  YAN051160: 'Young Adult Nonfiction / Social Topics / Physical & Emotional Abuse  ',
  YAN051170: 'Young Adult Nonfiction / Social Topics / Pregnancy ',
  YAN051180: 'Young Adult Nonfiction / Social Topics / Prejudice & Racism ',
  YAN051190: 'Young Adult Nonfiction / Social Topics / Runaways ',
  YAN051200: 'Young Adult Nonfiction / Social Topics / Self-Esteem & Self-Reliance ',
  YAN051210: 'Young Adult Nonfiction/ Social Topics / Cutting & Self-Harm',
  YAN051220: 'Young Adult Nonfiction / Social Topics / Sexual Abuse ',
  YAN051230: 'YOUNG ADULT NONFICTION / Disabilities & Special Needs',
  YAN051240: 'Young Adult Nonfiction / Social Topics / Suicide ',
  YAN051250: 'Young Adult Nonfiction / Social Topics / Values & Virtues ',
  YAN051260: 'Young Adult Nonfiction / Social Topics / Violence ',
  YAN052000: 'Young Adult Nonfiction / Social Science / General ',
  YAN052010: 'Young Adult Nonfiction / Social Science / Archaeology ',
  YAN052020: 'Young Adult Nonfiction / Social Science / Customs, Traditions, Anthropology ',
  YAN052030: 'Young Adult Nonfiction / Social Science / Folklore & Mythology ',
  YAN052040: 'Young Adult Nonfiction / Social Science / Politics & Government ',
  YAN052050: 'Young Adult Nonfiction / Social Science / Psychology ',
  YAN052060: 'Young Adult Nonfiction / Social Science / Sociology ',
  YAN053000: 'Young Adult Nonfiction / Sports & Recreation / General ',
  YAN053010: 'Young Adult Nonfiction / Sports & Recreation / Baseball & Softball ',
  YAN053020: 'Young Adult Nonfiction / Sports & Recreation / Basketball ',
  YAN053030: 'Young Adult Nonfiction / Sports & Recreation / Camping & Outdoor Activities ',
  YAN053040: 'Young Adult Nonfiction / Sports & Recreation / Extreme Sports ',
  YAN053050: 'Young Adult Nonfiction / Sports & Recreation / Football ',
  YAN053060: 'Young Adult Nonfiction / Sports & Recreation / Hockey ',
  YAN053070: 'Young Adult Nonfiction / Sports & Recreation / Martial Arts ',
  YAN053080: 'Young Adult Nonfiction / Sports & Recreation / Motor Sports ',
  YAN053090: 'Young Adult Nonfiction / Sports & Recreation / Olympics & Paralympics ',
  YAN053100: 'Young Adult Nonfiction / Sports & Recreation / Soccer ',
  YAN053110: 'Young Adult Nonfiction / Sports & Recreation / Track & Field ',
  YAN053120: 'Young Adult Nonfiction / Sports & Recreation / Winter Sports ',
  YAN054000: 'Young Adult Nonfiction / Study Aids / General ',
  YAN054010: 'Young Adult Nonfiction / Study Aids / Book Notes  ',
  YAN054020: 'Young Adult Nonfiction / Study Aids / Test Preparation ',
  YAN055000: 'Young Adult Nonfiction / Technology / General ',
  YAN055010: 'Young Adult Nonfiction / Technology / Aeronautics, Astronautics & Space Science ',
  YAN055020: 'Young Adult Nonfiction / Technology / Agriculture ',
  YAN055030: 'Young Adult Nonfiction / Technology / Electricity & Electronics ',
  YAN055040: 'Young Adult Nonfiction / Technology / Inventions ',
  YAN055050: 'Young Adult Nonfiction / Technology / Machinery & Tools ',
  YAN056000: 'Young Adult Nonfiction / Transportation / General ',
  YAN056010: 'Young Adult Nonfiction / Transportation / Aviation ',
  YAN056020: 'Young Adult Nonfiction / Transportation / Boats, Ships & Underwater Craft ',
  YAN056030: 'Young Adult Nonfiction / Transportation / Cars & Trucks ',
  YAN057000: 'Young Adult Nonfiction / Travel ',
  ANT056000: 'Antiques & Collectibles / Advertising',
  ART063000: 'Art / Environmental & Land Art',
  BUS113000: 'Business & Economics / Globalization',
  CKB126000: 'Cooking / Beverages / Alcoholic / Wine',
  COM046100: 'Computers / Operating Systems / Android',
  FIC009100: 'Fiction / Fantasy / Action & Adventure',
  FIC009110: 'Fiction / Fantasy / Arthurian',
  FIC009120: 'Fiction / Fantasy / Dragons & Mythical Creatures',
  FIC009130: 'Fiction / Fantasy / Gaslamp',
  FIC009140: 'Fiction / Fantasy / Military',
  FIC028120: 'Fiction / Science Fiction / Humorous',
  FIC028130: 'Fiction / Science Fiction / Space Exploration',
  FIC031090: 'Fiction / Thrillers / Terrorism',
  FIC067000: 'Fiction / Animals',
  FIC068000: 'Fiction / LGBTQ+ / General',
  GAM020000: 'Games & Activities / Activity Books',
  GAM021000: 'Games & Activities / Guided Journals',
  GAR031000: 'Gardening / Marijuana Cultivation',
  HIS015010: 'History / Europe / Great Britain / Middle Ages (449-1066)',
  HIS015020: 'History / Europe / Great Britain / Norman Conquest to Late Medieval (1066-1485)',
  HIS015030: 'History / Europe / Great Britain / Tudor & Elizabethan Era (1485-1603)',
  HIS015040: 'History / Europe / Great Britain / Stuart Era (1603-1714)',
  HIS015050: 'History / Europe / Great Britain / Georgian Era (1714-1837)',
  HIS015060: 'History / Europe / Great Britain / Victorian Era (1837-1901)',
  HIS015070: 'History / Europe / Great Britain / 20th Century',
  HIS015080: 'History / Europe / Great Britain / 21st Century',
  HIS015090: 'History / Europe / Great Britain / Scotland',
  HIS015100: 'History / Europe / Great Britain / Wales',
  HIS059000: 'History / Byzantine Empire',
  HIS060000: 'History / Europe / Poland',
  HIS061000: 'History / Latin America / Pre-Columbian Era',
  HUM020000: 'Humor / Topic / Celebrity & Popular Culture',
  HUM021000: 'Humor / Topic / Cultural, Ethnic & Regional',
  HUM022000: 'Humor / Topic / History',
  HUM023000: 'Humor / Topic / Internet & Social Media',
  HUM024000: 'Humor / Topic / LGBTQ+',
  HUM025000: 'Humor / Topic / School & Education',
  HUM026000: 'Humor / Topic / Travel',
  JNF062030: 'Juvenile Nonfiction / Comics & Graphic Novels / Science & Nature',
  JNF062040: 'Juvenile Nonfiction / Comics & Graphic Novels / Social Topics',
  JUV008040: 'Juvenile Fiction / Comics & Graphic Novels / Action & Adventure',
  JUV008050: 'Juvenile Fiction / Comics & Graphic Novels / Animals',
  JUV008060: 'Juvenile Fiction / Comics & Graphic Novels / Classic Adaptation',
  JUV008070: 'Juvenile Fiction / Comics & Graphic Novels / Fairy Tales, Folklore, Legends & Mythology',
  JUV008080: 'Juvenile Fiction / Comics & Graphic Novels / Fantasy',
  JUV008090: 'Juvenile Fiction / Comics & Graphic Novels / Historical',
  JUV008100: 'Juvenile Fiction / Comics & Graphic Novels / Horror',
  JUV008110: 'Juvenile Fiction / Comics & Graphic Novels / Humorous',
  JUV008120: 'Juvenile Fiction / Comics & Graphic Novels / Mystery & Detective',
  JUV008130: 'Juvenile Fiction / Comics & Graphic Novels / Paranormal',
  JUV008140: 'Juvenile Fiction / Comics & Graphic Novels / Science Fiction',
  PER004060: 'Performing Arts / Film / Genres / General',
  PER004070: 'Performing Arts / Film / Genres / Action & Adventure',
  PER004080: 'Performing Arts / Film / Genres / Animated',
  PER004090: 'Performing Arts / Film / Genres / Comedy',
  PER004100: 'Performing Arts / Film / Genres / Crime',
  PER004110: 'Performing Arts / Film / Genres / Documentary',
  PER004120: 'Performing Arts / Film / Genres / Historical',
  PER004130: 'Performing Arts / Film / Genres / Horror',
  PER004140: 'Performing Arts / Film / Genres / Science Fiction & Fantasy',
  PER004150: 'Performing Arts / Film / Genres / Westerns',
  PER010060: 'Performing Arts / Television / Genres / General',
  PER010070: 'Performing Arts / Television / Genres / Children\u2019s',
  PER010080: 'Performing Arts / Television / Genres / Comedy',
  PER010090: 'Performing Arts / Television / Genres / Drama',
  PER010100: 'Performing Arts / Television / Genres / Reality, Game Shows & Talk Shows',
  PER010110: 'Performing Arts / Television / Genres / Science Fiction, Fantasy & Horror',
  POL070000: 'Political Science / Public Policy / Immigration',
  PSY054000: 'Psychology / Animal & Comparative Psychology',
  SCI070060: 'Science / Life Sciences / Zoology / Ethology (Animal Behavior)',
  SOC065000: 'Social Science / Human Sexuality ',
  TEC021010: 'Technology & Engineering / Materials Science / Ceramics',
  TEC021020: 'Technology & Engineering / Materials Science / Electronic Materials',
  TEC021030: 'Technology & Engineering / Materials Science / Metals & Alloys',
  TEC021040: 'Technology & Engineering / Materials Science / Thin Films, Surfaces & Interfaces',
  YAF010050: 'Young Adult Fiction / Comics & Graphic Novels / Action & Adventure',
  YAF010060: 'Young Adult Fiction / Comics & Graphic Novels / Classic Adaptation',
  YAF010070: 'Young Adult Fiction / Comics & Graphic Novels / Coming of Age',
  YAF010080: 'Young Adult Fiction / Comics & Graphic Novels / Dystopian',
  YAF010090: 'Young Adult Fiction / Comics & Graphic Novels / Fairy Tales, Folklore, Legends & Mythology',
  YAF010100: 'Young Adult Fiction / Comics & Graphic Novels / Fantasy',
  YAF010110: 'Young Adult Fiction / Comics & Graphic Novels / Historical',
  YAF010120: 'Young Adult Fiction / Comics & Graphic Novels / Horror',
  YAF010130: 'Young Adult Fiction / Comics & Graphic Novels / Humorous',
  YAF010140: 'Young Adult Fiction / Comics & Graphic Novels / LGBTQ+',
  YAF010150: 'Young Adult Fiction / Comics & Graphic Novels / Mystery & Detective',
  YAF010160: 'Young Adult Fiction / Comics & Graphic Novels / Paranormal',
  YAF010170: 'Young Adult Fiction / Comics & Graphic Novels / Romance',
  YAN012030: 'Young Adult Nonfiction / Comics & Graphic Novels / Science & Nature',
  YAN012040: 'Young Adult Nonfiction / Comics & Graphic Novels / Social Topics',
  YAN024080: 'Young Adult Nonfiction / Health & Daily Living / Sexuality & Pregnancy',
  YAN051270: 'Young Adult Nonfiction / Social Topics / Civil & Human Rights',
  YAN051280: 'Young Adult Nonfiction / Social Topics / Compulsive Behavior',
  ART064000: 'Art / Video Game Art',
  ART065000: 'Art / Women Artists',
  BIO002010: 'Biography & Autobiography / Cultural, Ethnic & Regional / African American & Black',
  BIO002020: 'Biography & Autobiography / Cultural, Ethnic & Regional / Asian & Asian American',
  BIO002030: 'Biography & Autobiography / Cultural, Ethnic & Regional / Hispanic & Latino',
  BIO034000: 'Biography & Autobiography / Aviation & Nautical',
  BIO035000: 'Biography & Autobiography / Fashion',
  BIO036000: 'Biography & Autobiography / Fire & Emergency Services',
  BIO037000: 'Biography & Autobiography / Jewish',
  BIO038000: 'Biography & Autobiography / Survival',
  'BISACCode': 'Bisac Literal',
  CGN004240: 'Comics & Graphic Novels / Manga / Action & Adventure',
  CGN004250: 'Comics & Graphic Novels / Manga / Humorous',
  CGN004260: 'Comics & Graphic Novels / Manga / Magical Girl',
  CGN004270: 'Comics & Graphic Novels / Manga / Mecha',
  CGN004280: 'Comics & Graphic Novels / Manga / School Life',
  CGN004290: 'Comics & Graphic Novels / Manga / Supernatural',
  CGN014000: 'Comics & Graphic Novels / Humorous',
  CGN015000: 'Comics & Graphic Novels / Reference',
  CGN016000: 'Comics & Graphic Novels / Supernatural',
  CGN017000: 'Comics & Graphic Novels / Supervillains',
  CKB127000: 'Cooking / Comfort Food',
  CKB128000: 'Cooking / Media Tie-In',
  CKB129000: 'Cooking / Methods / Pressure Cooking',
  CRA062000: 'Crafts & Hobbies / Furniture & Cabinetry',
  CRA063000: 'Crafts & Hobbies / Upcycling & Repurposing',
  FIC014010: 'Fiction / Historical / Ancient',
  FIC014020: 'Fiction / Historical / Medieval',
  FIC014030: 'Fiction / Historical / Renaissance',
  FIC014040: 'Fiction / Historical / World War I',
  FIC014050: 'Fiction / Historical / World War II',
  FIC022110: 'Fiction / Mystery & Detective / Cozy / Cats & Dogs',
  FIC022120: 'Fiction / Mystery & Detective / Cozy / Crafts',
  FIC022130: 'Fiction / Mystery & Detective / Cozy / Culinary',
  FIC027340: 'Fiction / Romance / Billionaires',
  FIC027350: 'Fiction / Romance / Firefighters',
  FIC027360: 'Fiction / Romance / Historical / American',
  FIC027370: 'Fiction / Romance / Historical / Renaissance',
  FIC027380: 'Fiction / Romance / Later in Life',
  FIC027390: 'Fiction / Romance / LGBTQ+ / Bisexual',
  FIC027400: 'Fiction / Romance / LGBTQ+ / Transgender',
  FIC027410: 'Fiction / Romance / Medical',
  FIC027420: 'Fiction / Romance / Police & Law Enforcement',
  FIC027430: 'Fiction / Romance / Workplace',
  FIC031100: 'Fiction / Thrillers / Domestic',
  FIC045010: 'Fiction / Family Life / Marriage & Divorce',
  FIC045020: 'Fiction / Family Life / Siblings',
  FIC069000: 'Fiction / City Life',
  FIC070000: 'Fiction / Disaster',
  FIC071000: 'Fiction / Friendship',
  FIC072000: 'Fiction / LGBTQ+ / Bisexual',
  FIC073000: 'Fiction / LGBTQ+ / Transgender',
  FIC074000: 'Fiction / Southern',
  HEA051000: 'Health & Fitness / Menopause',
  JNF024130: 'Juvenile Nonfiction / Health & Daily Living / Mindfulness & Meditation',
  JNF067000: 'Juvenile Nonfiction / Bedtime & Dreams',
  JNF068000: 'Juvenile Nonfiction / Spies & Spying',
  JUV029030: 'JUVENILE FICTION / Science & Nature / Disasters',
  JUV072000: 'Juvenile Fiction / Mathematics',
  LCO020000: 'Literary Collections / Interviews',
  MUS055000: 'Music / Essays',
  OCC042000: 'Body, Mind & Spirit / Alchemy',
  POL042060: 'Political Science / Political Ideologies / Capitalism',
  PSY055000: 'Psychology / Essays',
  REF033000: 'Reference / Personal & Private Investigations',
  REF034000: 'Reference / Signs & Symbols',
  SCI101000: 'Science / Ethics',
  SOC066000: 'Social Science / Refugees',
  STU035000: 'Study Aids / NCLEX',
  STU036000: 'Study Aids / Study & Test-Taking Skills',
  STU037000: 'Study Aids / USMLE (United States Medical Licensure Examination)',
  TEC012010: 'Technology & Engineering / Food Science / Chemistry & Biotechnology',
  TEC012020: 'Technology & Engineering / Food Science / Food Packaging & Processing',
  TEC012030: 'Technology & Engineering / Food Science / Food Safety & Security',
  TEC012040: 'Technology & Engineering / Food Science / Food Types',
  TEC070000: 'Technology & Engineering / 3D Printing',
  TRU002020: 'True Crime / Murder / Mass Murder',
  TRU006000: 'True Crime / Abductions, Kidnappings & Missing Persons',
  TRU007000: 'True Crime / Forensics',
  TRU008000: 'True Crime / Heists & Robberies',
  TRU009000: 'True Crime / Sexual Assault',
  TRV026120: 'Travel / Special Interest / Culinary',
  TRV026130: 'Travel / Special Interest / Haunted & Unexplained',
  TRV026140: 'Travel / Special Interest / Roadside Attractions',
  YAF071000: 'Young Adult Fiction / Alternative History',
  YAN024090: 'Young Adult Nonfiction / Health & Daily Living / Mindfulness & Meditation',
  BIB001080: 'BIBLES / Christian Standard Bible / Dramatized',
  BIB001090: 'BIBLES / Christian Standard Bible / Journaling',
  BIB001100: 'BIBLES / Christian Standard Bible / Outreach',
  BIB001110: 'BIBLES / Christian Standard Bible / Reading',
  BIB022080: 'BIBLES / Common English Bible / Dramatized',
  BIB022090: 'BIBLES / Common English Bible / Journaling',
  BIB022100: 'BIBLES / Common English Bible / Outreach',
  BIB022110: 'BIBLES / Common English Bible / Reading',
  BIB002080: 'BIBLES / Contemporary English Version / Dramatized',
  BIB002090: 'BIBLES / Contemporary English Version / Journaling',
  BIB002100: 'BIBLES / Contemporary English Version / Outreach',
  BIB002110: 'BIBLES / Contemporary English Version / Reading',
  BIB003080: 'BIBLES / English Standard Version / Dramatized',
  BIB003090: 'BIBLES / English Standard Version / Journaling',
  BIB003100: 'BIBLES / English Standard Version / Outreach',
  BIB003110: 'BIBLES / English Standard Version / Reading',
  BIB004080: 'BIBLES / God\u2019s Word / Dramatized',
  BIB004090: 'BIBLES / God\u2019s Word / Journaling',
  BIB004100: 'BIBLES / God\u2019s Word / Outreach',
  BIB004110: 'BIBLES / God\u2019s Word / Reading',
  BIB005080: 'BIBLES / International Children\u2019s Bible / Dramatized',
  BIB005090: 'BIBLES / International Children\u2019s Bible / Journaling',
  BIB005100: 'BIBLES / International Children\u2019s Bible / Outreach',
  BIB005110: 'BIBLES / International Children\u2019s Bible / Reading',
  BIB006080: 'BIBLES / King James Version / Dramatized',
  BIB006090: 'BIBLES / King James Version / Journaling',
  BIB006100: 'BIBLES / King James Version / Outreach',
  BIB006110: 'BIBLES / King James Version / Reading',
  BIB007080: 'BIBLES / La Biblia de las Americas / Dramatized',
  BIB007090: 'BIBLES / La Biblia de las Americas / Journaling',
  BIB007100: 'BIBLES / La Biblia de las Americas / Outreach',
  BIB007110: 'BIBLES / La Biblia de las Americas / Reading',
  BIB008080: 'BIBLES / Multiple Translations / Dramatized',
  BIB008090: 'BIBLES / Multiple Translations / Journaling',
  BIB008100: 'BIBLES / Multiple Translations / Outreach',
  BIB008110: 'BIBLES / Multiple Translations / Reading',
  BIB009080: 'BIBLES / New American Bible / Dramatized',
  BIB009090: 'BIBLES / New American Bible / Journaling',
  BIB009100: 'BIBLES / New American Bible / Outreach',
  BIB009110: 'BIBLES / New American Bible / Reading',
  BIB010080: 'BIBLES / New American Standard Bible / Dramatized',
  BIB010090: 'BIBLES / New American Standard Bible / Journaling',
  BIB010100: 'BIBLES / New American Standard Bible / Outreach',
  BIB010110: 'BIBLES / New American Standard Bible / Reading',
  BIB011080: 'BIBLES / New Century Version / Dramatized',
  BIB011090: 'BIBLES / New Century Version / Journaling',
  BIB011100: 'BIBLES / New Century Version / Outreach',
  BIB011110: 'BIBLES / New Century Version / Reading',
  BIB012080: 'BIBLES / New International Reader\u2019s Version / Dramatized',
  BIB012090: 'BIBLES / New International Reader\u2019s Version / Journaling',
  BIB012100: 'BIBLES / New International Reader\u2019s Version / Outreach',
  BIB012110: 'BIBLES / New International Reader\u2019s Version / Reading',
  BIB013080: 'BIBLES / New International Version / Dramatized',
  BIB013090: 'BIBLES / New International Version / Journaling',
  BIB013100: 'BIBLES / New International Version / Outreach',
  BIB013110: 'BIBLES / New International Version / Reading',
  BIB014080: 'BIBLES / New King James Version / Dramatized',
  BIB014090: 'BIBLES / New King James Version / Journaling',
  BIB014100: 'BIBLES / New King James Version / Outreach',
  BIB014110: 'BIBLES / New King James Version / Reading',
  BIB015080: 'BIBLES / New Living Translation / Dramatized',
  BIB015090: 'BIBLES / New Living Translation / Journaling',
  BIB015100: 'BIBLES / New Living Translation / Outreach',
  BIB015110: 'BIBLES / New Living Translation / Reading',
  BIB016080: 'BIBLES / New Revised Standard Version / Dramatized',
  BIB016090: 'BIBLES / New Revised Standard Version / Journaling',
  BIB016100: 'BIBLES / New Revised Standard Version / Outreach',
  BIB016110: 'BIBLES / New Revised Standard Version / Reading',
  BIB024000: 'BIBLES / Nueva Traduccion Viviente / General',
  BIB024010: 'BIBLES / Nueva Traduccion Viviente / Children',
  BIB024020: 'BIBLES / Nueva Traduccion Viviente / Devotional',
  BIB024030: 'BIBLES / Nueva Traduccion Viviente / Dramatized',
  BIB024040: 'BIBLES / Nueva Traduccion Viviente / Journaling',
  BIB024050: 'BIBLES / Nueva Traduccion Viviente / New Testament & Portions',
  BIB024060: 'BIBLES / Nueva Traduccion Viviente / Outreach',
  BIB024070: 'BIBLES / Nueva Traduccion Viviente / Reading',
  BIB024080: 'BIBLES / Nueva Traduccion Viviente / Reference',
  BIB024090: 'BIBLES / Nueva Traduccion Viviente / Study',
  BIB024100: 'BIBLES / Nueva Traduccion Viviente / Text',
  BIB024110: 'BIBLES / Nueva Traduccion Viviente / Youth & Teen',
  BIB017080: 'BIBLES / Nueva Version International / Dramatized',
  BIB017090: 'BIBLES / Nueva Version International / Journaling',
  BIB017100: 'BIBLES / Nueva Version International / Outreach',
  BIB017110: 'BIBLES / Nueva Version International / Reading',
  BIB018080: 'BIBLES / Other English Translations / Dramatized',
  BIB018090: 'BIBLES / Other English Translations / Journaling',
  BIB018100: 'BIBLES / Other English Translations / Outreach',
  BIB018110: 'BIBLES / Other English Translations / Reading',
  BIB025000: 'BIBLES / Other Non-English Translations / General',
  BIB025010: 'BIBLES / Other Non-English Translations / Children',
  BIB025020: 'BIBLES / Other Non-English Translations / Devotional',
  BIB025030: 'BIBLES / Other Non-English Translations / Dramatized',
  BIB025040: 'BIBLES / Other Non-English Translations / Journaling',
  BIB025050: 'BIBLES / Other Non-English Translations / New Testament & Portions',
  BIB025060: 'BIBLES / Other Non-English Translations / Outreach',
  BIB025070: 'BIBLES / Other Non-English Translations / Reading',
  BIB025080: 'BIBLES / Other Non-English Translations / Reference',
  BIB025090: 'BIBLES / Other Non-English Translations / Study',
  BIB025100: 'BIBLES / Other Non-English Translations / Text',
  BIB025110: 'BIBLES / Other Non-English Translations / Youth & Teen',
  BIB019080: 'BIBLES / Reina Valera / Dramatized',
  BIB019090: 'BIBLES / Reina Valera / Journaling',
  BIB019100: 'BIBLES / Reina Valera / Outreach',
  BIB019110: 'BIBLES / Reina Valera / Reading',
  BIB023080: 'BIBLES / The Amplified Bible / Dramatized',
  BIB023090: 'BIBLES / The Amplified Bible / Journaling',
  BIB023100: 'BIBLES / The Amplified Bible / Outreach',
  BIB023110: 'BIBLES / The Amplified Bible / Reading',
  BIB020080: 'BIBLES / The Message / Dramatized',
  BIB020090: 'BIBLES / The Message / Journaling',
  BIB020100: 'BIBLES / The Message / Outreach',
  BIB020110: 'BIBLES / The Message / Reading',
  BUS070170: 'BUSINESS & ECONOMICS / Industries / Healthcare',
  CGN004300: 'COMICS & GRAPHIC NOVELS / Manga / Isekai',
  COM092000: 'COMPUTERS / Embedded Computer Systems',
  CRA064000: 'CRAFTS & HOBBIES / Soap Making',
  FAM056000: 'Family & Relationships / LGBTQ+',
  FIC075000: 'FICTION / Adaptations & Pastiche',
  FIC042090: 'FICTION / Christian / Biblical',
  FIC042100: 'FICTION / Christian / Contemporary',
  FIC042110: 'FICTION / Christian / Romance / Historical',
  FIC042120: 'FICTION / Christian / Romance / Suspense',
  FIC005070: 'Fiction / Erotica / LGBTQ+ / General',
  FIC005080: 'Fiction / Erotica / LGBTQ+ / Bisexual',
  FIC005090: 'Fiction / Erotica / LGBTQ+ / Transgender',
  FIC027440: 'FICTION / Romance / Paranormal / Witches',
  FIC027450: 'FICTION / Romance / Royalty',
  FIC028140: 'FICTION / Science Fiction / Crime & Mystery',
  HEA007010: 'HEALTH & FITNESS / Exercise / Aquatic',
  HEA007020: 'HEALTH & FITNESS / Exercise / Dance',
  HEA007030: 'HEALTH & FITNESS / Exercise / Pilates',
  HEA052000: 'HEALTH & FITNESS / Tai Chi',
  HOM024000: 'HOUSE & HOME / Energy',
  HOM025000: 'HOUSE & HOME / Moving & Relocation',
  JUV054010: 'JUVENILE FICTION / Activity Books / Coloring',
  JUV054020: 'JUVENILE FICTION / Activity Books / Sticker',
  JUV002380: 'JUVENILE FICTION / Animals / Penguins',
  JUV073000: 'JUVENILE FICTION / Architecture',
  JUV074000: 'JUVENILE FICTION / Diversity & Multicultural',
  JUV017140: 'JUVENILE FICTION / Holidays & Celebrations / Father\u2019s Day',
  JUV017150: 'JUVENILE FICTION / Holidays & Celebrations / Mother\u2019s Day',
  JUV033280: 'JUVENILE FICTION / Religious / Christian / Inspirational',
  JUV053010: 'JUVENILE FICTION / Science Fiction / Alien Contact',
  JUV053020: 'JUVENILE FICTION / Science Fiction / Space Exploration',
  JUV036010: 'JUVENILE FICTION / Technology / Astronauts & Space',
  JUV036020: 'JUVENILE FICTION / Technology / Inventions',
  JNF001010: 'JUVENILE NONFICTION / Activity Books / Coloring',
  JNF001020: 'JUVENILE NONFICTION / Activity Books / Sticker',
  JNF003340: 'JUVENILE NONFICTION / Animals / Frogs & Toads',
  JNF003350: 'JUVENILE NONFICTION / Animals / Penguins',
  JNF003360: 'JUVENILE NONFICTION / Animals / Turtles',
  JNF007150: 'Juvenile Nonfiction / Biography & Autobiography / LGBTQ+',
  JNF013120: 'JUVENILE NONFICTION / Concepts / Words',
  JNF069000: 'JUVENILE NONFICTION / Diversity & Multicultural',
  JNF070000: 'JUVENILE NONFICTION / Inspirational & Personal Growth',
  JNF071000: 'JUVENILE NONFICTION / Social Activism & Volunteering',
  JNF061020: 'JUVENILE NONFICTION / Technology / Robotics',
  LAN005010: 'LANGUAGE ARTS & DISCIPLINES / Writing / Academic & Scholarly',
  LAN005020: 'LANGUAGE ARTS & DISCIPLINES / Writing / Business Aspects',
  LAN005030: 'LANGUAGE ARTS & DISCIPLINES / Writing / Children\u2019s & Young Adult',
  LAN005040: 'LANGUAGE ARTS & DISCIPLINES / Writing / Composition',
  LAN005050: 'LANGUAGE ARTS & DISCIPLINES / Writing / Fiction Writing',
  LAN005060: 'LANGUAGE ARTS & DISCIPLINES / Writing / Nonfiction (incl. Memoirs)',
  LAN005070: 'LANGUAGE ARTS & DISCIPLINES / Writing / Poetry',
  MED116000: 'MEDICAL / Environmental Health',
  MED117000: 'MEDICAL / Informatics',
  MED118000: 'MEDICAL / Military Medicine',
  MED058240: 'MEDICAL / Nursing / Care Plans',
  MED062010: 'MEDICAL / Oncology / Breast Cancer',
  MED062020: 'MEDICAL / Oncology / Leukemia & Lymphoma',
  MED062030: 'MEDICAL / Oncology / Lung Cancer',
  MED062040: 'MEDICAL / Oncology / Pediatric',
  MED062050: 'MEDICAL / Oncology / Prostate Cancer',
  MED062060: 'MEDICAL / Oncology / Skin Cancer',
  MED119000: 'MEDICAL / Sleep Medicine',
  MED085090: 'MEDICAL / Surgery / Abdominal',
  MED085100: 'MEDICAL / Surgery / Eye',
  MED085110: 'MEDICAL / Surgery / Oncology',
  MED085120: 'MEDICAL / Surgery / Orthopedic',
  MED085130: 'MEDICAL / Surgery / Otolaryngology',
  MED085140: 'MEDICAL / Surgery / Pediatric',
  MED085150: 'MEDICAL / Surgery / Reproductive & Urological',
  PER022000: 'PERFORMING ARTS / Improvisation',
  PSY022090: 'PSYCHOLOGY / Psychopathology / Memory Disorders',
  REL006750: 'RELIGION / Biblical Commentary / Old Testament / Pentateuch',
  REL006760: 'RELIGION / Biblical Commentary / Old Testament / Historical Books',
  REL006770: 'RELIGION / Biblical Commentary / Old Testament / Poetry & Wisdom Literature',
  REL006780: 'RELIGION / Biblical Commentary / Old Testament / Prophets',
  REL006790: 'RELIGION / Biblical Commentary / Old Testament / Apocrypha & Deuterocanonical',
  REL006800: 'RELIGION / Biblical Commentary / New Testament / Jesus, the Gospels & Acts',
  REL006810: 'RELIGION / Biblical Commentary / New Testament / Paul\u2019s Letters',
  REL006820: 'RELIGION / Biblical Commentary / New Testament / General Epistles',
  REL006830: 'RELIGION / Biblical Commentary / New Testament / Revelation',
  REL006840: 'RELIGION / Biblical Studies / Old Testament / Pentateuch',
  REL006850: 'RELIGION / Biblical Studies / Old Testament / Historical Books',
  REL006860: 'RELIGION / Biblical Studies / New Testament / General Epistles',
  REL006870: 'RELIGION / Biblical Studies / New Testament / Revelation',
  REL012140: 'RELIGION / Christian Living / Calling & Vocation',
  REL012150: 'RELIGION / Christian Living / Devotional Journal',
  REL012160: 'RELIGION / Christian Living / Parenting',
  REL012170: 'RELIGION / Christian Living / Personal Memoirs',
  SEL046000: 'SELF-HELP / Gender & Sexuality',
  SOC067000: 'SOCIAL SCIENCE / Capital Punishment',
  SOC064010: 'Social Science / LGBTQ+ Studies / Bisexual Studies',
  SOC064020: 'Social Science / LGBTQ+ Studies / Transgender Studies',
  TRU010000: 'TRUE CRIME / Historical',
  YAF052070: 'YOUNG ADULT FICTION / Romance / Multicultural & Interracial',
  YAN006150: 'Young Adult Nonfiction / Biography & Autobiography / LGBTQ+',
  YAN058000: 'YOUNG ADULT NONFICTION / Social Activism & Volunteering',
  YAN055060: 'YOUNG ADULT NONFICTION / Technology / Robotics',
  OCC043000: 'Body, Mind & Spirit/ Nature Therapy',
  BUS114000: 'Business & Economics/ Bitcoin & Cryptocurrencies',
  BUS090050: 'Business & Economics/ E-Commerce / Search Engine Optimization',
  COM093000: 'Computers/ Blockchain',
  COM055030: 'Computers/ Certification Guides / Cisco',
  COM055040: 'Computers/ Certification Guides / Oracle',
  COM094000: 'Computers/ Data Science / Machine Learning',
  COM095000: 'Computers/ Internet of Things (IoT)',
  COM051480: 'Computers/ Languages / JSON',
  COM046110: 'Computers/ Operating Systems / iOS',
  COM096000: 'Computers/ Parallel Processing',
  COM097000: 'Computers/ Quantum Computing',
  COM098000: 'Computers/ Voice Search & Digital Assistants',
  CRA065000: 'Crafts & Hobbies/ Taxidermy',
  FAM057000: 'Family & Relationships/ Multiracial Families',
  FIC076000: 'Fiction/ Feminist',
  FIC014060: 'Fiction/ Historical / Civil War Era',
  FIC014070: 'Fiction/ Historical / Colonial America & Revolution',
  FIC077000: 'Fiction/ Nature & the Environment',
  FIC027460: 'Fiction/ Romance / Historical / Gilded Age',
  FIC027470: 'Fiction/ Romance / Polyamory',
  HEA053000: 'Health & Fitness/ Cannabis & CBD',
  HOM026000: 'House & Home/ Entertainment Systems',
  HOM027000: 'House & Home/ Smart Home',
  JUV075000: 'Juvenile Fiction/ War & Military',
  MED089040: 'Medical/ Veterinary Medicine / Dentistry',
  POE011010: 'Poetry/ Canadian / Indigenous',
  POE026000: 'Poetry/ Shakespeare',
  POL071000: 'Political Science/ Public Opinion Polling',
  REF035000: 'Reference/ Planners',
  SEL047000: 'Self-Help/ Personal Safety & Self-Defense',
  SOC068000: 'Social Science/ Biracial & Multiracial Studies',
  SOC008010: 'Social Science/ Ethnic Studies / African Studies',
  SOC069000: 'Social Science/ Ethnic Studies / American / General',
  SOC008020: 'Social Science/ Ethnic Studies / Asian Studies',
  SOC008030: 'Social Science/ Ethnic Studies / Australian & Oceanian Studies',
  SOC008040: 'Social Science/ Ethnic Studies / Canadian Studies',
  SOC008050: 'Social Science/ Ethnic Studies / Caribbean & Latin American Studies',
  SOC008060: 'Social Science/ Ethnic Studies / European Studies',
  SOC008070: 'Social Science/ Ethnic Studies / Middle Eastern Studies',
  SOC070000: 'Social Science/ Race & Ethnic Relations',
  SOC071000: 'Social Science/ Technology Studies',
  TEC071000: 'Technology & Engineering/ Data Transmission Systems / General',
  TEC071010: 'Technology & Engineering/ Data Transmission Systems / Broadband',
  TRU011000: 'True Crime/ Cybercrime',
  ART066000: 'Art / LGBTQ+ Artists',
  BIO002040: 'Biography & Autobiography/ Cultural, Ethnic & Regional / Arab & Middle Eastern',
  BUS012020: 'Business & Economics/ Careers / Interviewing',
  BUS038010: 'Business & Economics/ Labor / Unions',
  BUS038020: 'Business & Economics/ Labor / Wages & Compensation',
  BUS069040: 'Business & Economics/ Economics / Social & Behavioral',
  BUS115000: 'Business & Economics/ Freelance & Self-Employment',
  BUS116000: 'Business & Economics/ Logistics & Supply Chain',
  BUS117000: 'Business & Economics/ Workplace Harassment & Discrimination',
  CGN004310: 'Comics & Graphic Novels/ Manga / Yuri',
  CGN007020: 'Comics & Graphic Novels/ Nonfiction / History',
  CKB130000: 'Cooking/ Beverages / Alcoholic / Spirits',
  CKB131000: 'Cooking/ Equipment & Utensils',
  COM099000: 'Computers/ Forensics',
  DRA020000: 'Drama/ Indigenous Peoples of the Americas',
  FIC027480: 'Fiction/ Romance / Rock Stars',
  FIC078000: 'Fiction/ Buddhist',
  FIC079000: 'Fiction/ Disabilities & Special Needs',
  FIC080000: 'Fiction/ Multiple Timelines',
  FIC081000: 'Fiction/ Muslim',
  FIC082000: 'Fiction/ Own Voices',
  FIC083000: 'Fiction/ World Literature / Africa / General',
  FIC083010: 'Fiction/ World Literature / Africa / East Africa',
  FIC083020: 'Fiction/ World Literature / Africa / Nigeria',
  FIC083030: 'Fiction/ World Literature / Africa / Southern Africa',
  FIC083040: 'Fiction/ World Literature / Africa / West Africa',
  FIC084000: 'Fiction/ World Literature / American / General',
  FIC084010: 'Fiction/ World Literature / American / Colonial & Revolutionary Periods',
  FIC084020: 'Fiction/ World Literature / American / 19th Century',
  FIC084030: 'Fiction/ World Literature / American / 20th Century',
  FIC084040: 'Fiction/ World Literature / American / 21st Century',
  FIC085000: 'Fiction/ World Literature / Argentina',
  FIC086000: 'Fiction/ World Literature / Asia (General)',
  FIC087000: 'Fiction/ World Literature / Australia',
  FIC088000: 'Fiction/ World Literature / Austria',
  FIC089000: 'Fiction/ World Literature / Brazil',
  FIC090000: 'Fiction/ World Literature / Canada / General',
  FIC090010: 'Fiction/ World Literature / Canada / Colonial & 19th Century',
  FIC090020: 'Fiction/ World Literature / Canada / 20th Century',
  FIC090030: 'Fiction/ World Literature / Canada / 21st Century',
  FIC091000: 'Fiction/ World Literature / Caribbean & West Indies',
  FIC092000: 'Fiction/ World Literature / Central America',
  FIC093000: 'Fiction/ World Literature / Chile',
  FIC094000: 'Fiction/ World Literature / China / General',
  FIC094010: 'Fiction/ World Literature / China / 19th Century',
  FIC094020: 'Fiction/ World Literature / China / 20th Century',
  FIC094030: 'Fiction/ World Literature / China / 21st Century',
  FIC095000: 'Fiction/ World Literature / Colombia',
  FIC096000: 'Fiction/ World Literature / Czech Republic',
  FIC097000: 'Fiction/ World Literature / Denmark',
  FIC098000: 'Fiction/ World Literature / England / General',
  FIC098010: 'Fiction/ World Literature / England / Early & Medieval Periods',
  FIC098020: 'Fiction/ World Literature / England / 16th & 17th Century',
  FIC098030: 'Fiction/ World Literature / England / 18th Century',
  FIC098040: 'Fiction/ World Literature / England / 19th Century',
  FIC098050: 'Fiction/ World Literature / England / 20th Century',
  FIC098060: 'Fiction/ World Literature / England / 21st Century',
  FIC099000: 'Fiction/ World Literature / Europe (General)',
  FIC100000: 'Fiction/ World Literature / Finland',
  FIC101000: 'Fiction/ World Literature / France / General',
  FIC101010: 'Fiction/ World Literature / France / 18th Century',
  FIC101020: 'Fiction/ World Literature / France / 19th Century',
  FIC101030: 'Fiction/ World Literature / France / 20th Century',
  FIC101040: 'Fiction/ World Literature / France / 21st Century',
  FIC102000: 'Fiction/ World Literature / Germany / General',
  FIC102010: 'Fiction/ World Literature / Germany / 20th Century',
  FIC102020: 'Fiction/ World Literature / Germany / 21st Century',
  FIC103000: 'Fiction/ World Literature / Greece',
  FIC104000: 'Fiction/ World Literature / Hungary',
  FIC105000: 'Fiction/ World Literature / India / General',
  FIC105010: 'Fiction/ World Literature / India / 19th Century',
  FIC105020: 'Fiction/ World Literature / India / 20th Century',
  FIC105030: 'Fiction/ World Literature / India / 21st Century',
  FIC106000: 'Fiction/ World Literature / Ireland / General',
  FIC106010: 'Fiction/ World Literature / Ireland / 19th Century',
  FIC106020: 'Fiction/ World Literature / Ireland / 20th Century',
  FIC106030: 'Fiction/ World Literature / Ireland / 21st Century',
  FIC107000: 'Fiction/ World Literature / Italy',
  FIC108000: 'Fiction/ World Literature / Japan',
  FIC109000: 'Fiction/ World Literature / Korea',
  FIC110000: 'Fiction/ World Literature / Mexico',
  FIC111000: 'Fiction/ World Literature / Middle East / General',
  FIC111010: 'Fiction/ World Literature / Middle East / Arabian Peninsula',
  FIC111020: 'Fiction/ World Literature / Middle East / Egypt & North Africa',
  FIC111030: 'Fiction/ World Literature / Middle East / Israel',
  FIC112000: 'Fiction/ World Literature / Netherlands',
  FIC113000: 'Fiction/ World Literature / New Zealand',
  FIC114000: 'Fiction/ World Literature / Norway',
  FIC115000: 'Fiction/ World Literature / Oceania',
  FIC116000: 'Fiction/ World Literature / Pakistan',
  FIC117000: 'Fiction/ World Literature / Peru',
  FIC118000: 'Fiction/ World Literature / Poland',
  FIC119000: 'Fiction/ World Literature / Portugal',
  FIC120000: 'Fiction/ World Literature / Russia / General',
  FIC120010: 'Fiction/ World Literature / Russia / 19th Century',
  FIC120020: 'Fiction/ World Literature / Russia / 20th Century',
  FIC120030: 'Fiction/ World Literature / Russia / 21st Century',
  FIC121000: 'Fiction/ World Literature / Scotland / General',
  FIC121010: 'Fiction/ World Literature / Scotland / 19th Century',
  FIC121020: 'Fiction/ World Literature / Scotland / 20th Century',
  FIC121030: 'Fiction/ World Literature / Scotland / 21st Century',
  FIC122000: 'Fiction/ World Literature / South America (General)',
  FIC123000: 'Fiction/ World Literature / Southeast Asia',
  FIC124000: 'Fiction/ World Literature / Spain / General',
  FIC124010: 'Fiction/ World Literature / Spain / 19th Century',
  FIC124020: 'Fiction/ World Literature / Spain / 20th Century',
  FIC124030: 'Fiction/ World Literature / Spain / 21st Century',
  FIC125000: 'Fiction/ World Literature / Sweden',
  FIC126000: 'Fiction/ World Literature / Turkey',
  FIC127000: 'Fiction/ World Literature / Uruguay',
  FIC128000: 'Fiction/ World Literature / Wales',
  GAM022000: 'Games & Activities/ Juggling',
  HEA054000: 'Health & Fitness / LGBTQ+ Health',
  HEA055000: 'Health & Fitness/ Mental Health',
  JNF038130: 'Juvenile Nonfiction/ People & Places / United States / Middle Eastern & Arab American',
  JNF054240: 'Juvenile Nonfiction/ Sports & Recreation / Cheerleading',
  JUV030130: 'Juvenile Fiction/ People & Places / United States / Middle Eastern & Arab American',
  JUV032220: 'Juvenile Fiction/ Sports & Recreation / Cheerleading',
  JUV032230: 'Juvenile Fiction/ Sports & Recreation / Motor Sports',
  JUV032240: 'Juvenile Fiction/ Sports & Recreation / Olympics & Paralympics',
  JUV076000: 'Juvenile Fiction/ Spies & Spying',
  MED120000: 'Medical/ Telemedicine',
  POL072000: 'Political Science/ Religion, Politics & State',
  PSY056000: 'Psychology / Psychotherapy / LGBTQ+',
  SCI098010: 'Science/ Space Science / Planetary',
  SCI098020: 'Science/ Space Science / Space Exploration',
  SCI098030: 'Science/ Space Science / Stellar & Solar',
  SCI102000: 'Science/ Bioinformatics',
  SCI103000: 'Science/ Physics / Particle',
  SEL048000: 'Self-Help/ Cutting & Self-Harm',
  SOC008080: 'Social Science/ Ethnic Studies / American / European American Studies',
  SPO027010: 'Sports & Recreation/ Martial Arts / Karate',
  SPO028010: 'Sports & Recreation/ Motor Sports / Automobile Racing',
  SPO028020: 'Sports & Recreation/ Motor Sports / Motorcycle Racing',
  SPO078000: 'Sports & Recreation/ Animal Sports / General',
  SPO079000: 'Sports & Recreation/ Rock Climbing',
  SPO080000: 'Sports & Recreation/ Water Sports / Rowing',
  SPO081000: 'Sports & Recreation/ Winter Sports / Curling',
  TEC072000: 'Technology & Engineering/ Pharmaceutical',
  TEC073000: 'Technology & Engineering/ Systems Engineering',
  TRV003080: 'Travel/ Asia / East / Korea',
  TRV003090: 'Travel/ Asia / East / Mongolia',
  TRV003100: 'Travel/ Asia / East / Taiwan',
  YAF010180: 'Young Adult Fiction/ Comics & Graphic Novels / Diversity & Multicultural',
  YAF019060: 'Young Adult Fiction/ Fantasy / Romance',
  YAF046160: 'Young Adult Fiction/ People & Places / United States / Middle Eastern & Arab American',
  YAF056030: 'Young Adult Fiction/ Science Fiction / Romance',
  YAF062010: 'Young Adult Fiction/ Thrillers & Suspense / Crime',
  YAF062020: 'Young Adult Fiction/ Thrillers & Suspense / Espionage',
  YAF062030: 'Young Adult Fiction/ Thrillers & Suspense / Psychological',
  YAF062040: 'Young Adult Fiction/ Thrillers & Suspense / Supernatural',
  YAF072000: 'Young Adult Fiction/ Clean & Nonviolent',
  YAF073000: 'Young Adult Fiction/ Own Voices',
  YAN024100: 'Young Adult Nonfiction/ Health & Daily Living / Mental Health',
  YAN038150: 'Young Adult Nonfiction/ People & Places / United States / Middle Eastern & Arab American'
  */
};

export default bisacCodes as typeof bisacCodes;
