
import { HighlightColor, HighlightColorGroup } from 'app/base/constants';
import { useI18n } from 'app/functions/use-i18n';
import { FilterObject } from 'app/models/filter-object';
import { computed, Ref, ref } from 'vue';

type ColorFilterable = {
  colorID: HighlightColor;
};

export function useColorFilter<T extends ColorFilterable>(items: Ref<T[]>) {
  const { t } = useI18n();

  const colorFilter = ref<HighlightColorGroup[] | undefined>(undefined);

  const filteredItems = computed<T[]>(() => {
    return colorFilter.value && colorFilter.value.length
      ? items.value.filter((i) => colorFilter.value?.includes(HighlightColor[i.colorID] as HighlightColorGroup))
      : items.value;
  });

  const colorGroups = Object.keys(HighlightColor).filter((k) => isNaN(Number(k))) as HighlightColorGroup[];

  const filterObjects = computed(() => colorGroups.map((colorGroup) => {
    return {
      id: colorGroup,
      category: 'Color',
      name: t(`annotations.color.${colorGroup}`),
      selected: !!colorFilter.value?.includes(colorGroup)
    } as FilterObject;
  }));

  /* We don't directly compute this off of filteredItems,
   * because we may want to layer multiple filters before we generate the options
   */
  const getFilterObjects = (fullyFilteredItems: T[]) => {
    const itemColorGroups = new Set(fullyFilteredItems.map((i) => HighlightColor[i.colorID] as HighlightColorGroup));

    return filterObjects.value.filter((fo) => itemColorGroups.has(fo.id as HighlightColorGroup) || fo.selected);
  };

  return {
    colorFilter,
    filteredItems,
    getFilterObjects
  };
};
