// Mirrors the Web Storage API for an in-memory store.
export default class MemoryStorage implements Storage {
  protected readonly store: Map<string, string>;

  constructor() {
    this.store = new Map();
  }

  public get length() {
    return this.store.size;
  }

  public clear(): void {
    this.store.clear();
  }

  public getItem(key: string): string | null {
    const stored = this.store.get(key);

    return stored === undefined ? null : stored;
  }

  public key(index: number): string | null {
    const keyAtIndex = Array.from(this.store.keys())[index];

    return keyAtIndex === undefined ? null : keyAtIndex;
  }

  public removeItem(key: string): void {
    this.store.delete(key);
  }

  public setItem(key: string, value: string): void {
    this.store.set(key, value);
  }
}
