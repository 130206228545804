import '@formatjs/intl-locale/polyfill';
import { Dictionary } from 'app/base/common';
import IntlMessageFormat from 'intl-messageformat';


export default class IcuFormatter {
  private readonly _locale: string;
  private readonly _messages: Dictionary<Dictionary<string | any>>;
  private readonly _caches: Dictionary<IntlMessageFormat>;


  constructor(locale: string, messages: Dictionary<Dictionary<string | any>>) {
    this._locale = locale;
    this._messages = messages;
    this._caches = {};
  }


  public translate(key: string, values?: unknown) {
    const localeMessages = this._messages[this._locale];
    const keys = key.split('.');
    let message = localeMessages[keys.shift()!];
    while (message && keys.length) {
      message = message[keys.shift()!];
    }

    if (!message) {
      return key;
    }

    return this.interpolate(message, values);
  }


  public interpolate(message: string, value: any) {
    const formatKey = `${this._locale}${message}`;
    let formatter = this._caches[formatKey];
    if (!formatter) {
      formatter = new IntlMessageFormat(message, this._locale);
      this._caches[formatKey] = formatter;
    }

    return formatter.format(value);
  }
}

