import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import { Bank } from './bank';
import { BankScopeMemory } from './bank-scope-memory';

export class BankScopeDomain extends BankScopeMemory implements Bank {
  constructor(name: string, data?: unknown) {
    super(name, data);

    if (window.localStorage) {
      window.addEventListener('storage', (evt: StorageEvent) => {
        // storage event fires in IE and Safari for focused tab as well
        // document.hasFocus checks if in active tab / window
        if (!document.hasFocus() && evt.key) {
          const key = this._toKey(evt.key);
          if (key) {
            APP.events.dispatch('bank:update', {
              key,
              newValue: evt.newValue,
              oldValue: evt.oldValue
            });
          }
        }
      });
    }
  }


  public getData() {
    return this._data;
  }


  protected _read(): {} {
    const data: Dictionary<string | null> = {};
    if (typeof window.localStorage === 'undefined') {
      console.warn('[BANK] localStorage disabled. No persistence for session.');

      return data;
    }

    this._eachRawPair((address, val) => {
      const key = this._toKey(address);
      if (key) {
        data[key] = val ? JSON.parse(val) : null;
      }
    });

    return data;
  }


  protected _commit(): void {
    if (typeof window.localStorage === 'undefined') {
      return;
    }
    this._eachFlushKey((key) => {
      const val = this.get(key);
      const address = this._toAddress(key);
      if (val === null || typeof val === 'undefined') {
        localStorage.removeItem(address);
      } else {
        try {
          localStorage.setItem(address, JSON.stringify(val));
        } catch (e) {
          console.warn('[BANK] error stringifying value:', e, address, val);
        }
      }
    });
  }


  protected _eachRawPair(fn: (address: string, value: string | null) => void): void {
    const addresses = [];
    for (let i = 0, ii = localStorage.length; i < ii; ++i) {
      const address = localStorage.key(i);
      if (C.isString(address)) {
        addresses.push(address);
      }
    }
    for (let i = 0, ii = addresses.length; i < ii; ++i) {
      fn(addresses[i], localStorage.getItem(addresses[i]));
    }
  }


  private _toAddress(key: string) {
    return `${this._name}:${key}`;
  }


  private _toKey(address: string) {
    const regex = new RegExp(`^${this._name}:(.+)\$`);
    const match = address.match(regex);

    return match ? match[1] : null;
  }
}
