<template>
  <header :class="$style.header">
    <div :class="$style.actions">
      <BreadcrumbNavigation
        :class="$style.breadcrumbs"
        :title="header"
        :breadcrumbList="breadcrumbs"
      />
      <slot name="context"></slot>
    </div>
    <h1 :class="$style.title">
      {{ header }}
    </h1>
    <slot name="subheader">
      <p
        v-if="subtitle"
        :class="$style.subtitle"
      >
        {{ subtitle }}
      </p>
    </slot>
  </header>
</template>

<script lang="ts">
import BreadcrumbNavigation from 'app/components/BreadcrumbNavigation.vue';
import { useBreadcrumbs } from 'app/functions/use-breadcrumbs';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BreadcrumbNavigation
  },
  props: {
    header: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const breadcrumbs = useBreadcrumbs();

    return {
      breadcrumbs
    };
  }
});
</script>

<style module>
.actions {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 1.5rem;
}

.breadcrumbs {
  margin-left: -0.5rem;
}

.title {
  margin-top: 1.5rem;
  font-size: var(--fs-large-head);
  font-weight: var(--fw-bold);
  line-height: 1.25;
}

.subtitle {
  line-height: 1.25;
  margin-top: 0.5rem;
}
</style>
