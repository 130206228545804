import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("p", {
    class: _normalizeClass(_ctx.$style.error),
    role: "alert"
  }, [
    _createVNode(_component_Icon, { name: "warning" }),
    _createTextVNode(" " + _toDisplayString(_ctx.contents), 1)
  ], 2))
}