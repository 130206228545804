import { APP } from 'app/base/app';
import { C } from 'app/base/common';

/**
 * Ping elrond-server periodically to check if the current version
 * has been updated.
 */
export class VersionMonitor {
  private readonly _checkVersionSoon: Function;


  constructor() {
    const checkVersionFrequenceyMin = 15 * C.MS_SECOND;
    const checkVersionFrequenceyMax = 5 * C.MS_MINUTE;
    this._checkVersionSoon = C.staggerInvocation(() => {
      this._checkVersion();
    }, checkVersionFrequenceyMin, checkVersionFrequenceyMax);
  }


  public start(): void {
    APP.events.on('router:navigate', (_) => this._checkVersionSoon());
    APP.events.on('msg:client:view:foreground', (_) => this._checkVersionSoon());
    APP.events.on('msg:client:view:background', (_) => this._checkVersionSoon());

    APP.events.on('network:info', (_) => {
      if (APP.network.reachable) {
        this._checkVersionSoon();
      }
    });

    // Check version on startup
    setTimeout(() => {
      this._checkVersion();
    }, 200);
  }


  private async _checkVersion(): Promise<void> {
    if (!APP.network.reachable) { return; }

    try {
      const response = await APP.services.elrond.fetchAsync<VersionResponse>({
        url: 'elrond/version',
        method: 'GET',
        headers: {
          Accept: 'application/json'
        },
        credentials: true
      });
      if (!response) {
        return;
      }
      const version = response.shortVer;

      if (APP.client.info.version.isOlderThan(version)) {
        APP.events.dispatch('app:update:available', { version: version });
      }
    } catch (err) {
      console.warn('[VERSION-MONITOR] Error getting version: ', err);
    }
  }
}


interface VersionResponse {
  sha: string;
  timestamp: string;
  branch: string;
  shortVer: string;
  semver: string[];
}
