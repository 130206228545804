import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "aria-controls", "aria-expanded", "onClick"]
const _hoisted_2 = {
  key: 0,
  class: "badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Collapsible = _resolveComponent("Collapsible")!
  const _component_InfoPanel = _resolveComponent("InfoPanel")!
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("ol", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
        return (_openBlock(), _createElementBlock("li", {
          key: section.id,
          class: _normalizeClass(_ctx.$style.sectionWrapper)
        }, [
          _createElementVNode("section", {
            class: _normalizeClass([_ctx.$style.section, _ctx.isActive(section.id) && !_ctx.mobile ? _ctx.$style.expanded : ''])
          }, [
            _createElementVNode("h1", {
              class: _normalizeClass(_ctx.$style.header)
            }, [
              _createElementVNode("button", {
                id: _ctx.sectionHeaderId(section.id),
                class: _normalizeClass(_ctx.$style.trigger),
                "aria-controls": _ctx.sectionPanelId(section.id),
                "aria-expanded": _ctx.isActive(section.id),
                onClick: ($event: any) => (_ctx.toggle(section.id))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.triggerContents)
                }, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t(section.label)) + " ", 1),
                    (section.badge)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(section.badge), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_Icon, {
                    name: "chevron-right",
                    class: _normalizeClass(_ctx.$style.arrow)
                  }, null, 8, ["class"])
                ], 2)
              ], 10, _hoisted_1)
            ], 2),
            _createVNode(_component_Collapsible, {
              class: _normalizeClass(_ctx.$style.panel),
              expanded: !_ctx.mobile && _ctx.isActive(section.id),
              panelId: _ctx.sectionPanelId(section.id),
              headerId: _ctx.sectionHeaderId(section.id)
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, section.id)
              ], undefined, true),
              _: 2
            }, 1032, ["class", "expanded", "panelId", "headerId"])
          ], 2)
        ], 2))
      }), 128))
    ]),
    _createVNode(_component_Overlay, {
      teleportTo: "#sidebars",
      show: _ctx.mobile && !!_ctx.activeSection,
      transition: "up",
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeSection = null))
    }, {
      default: _withCtx(() => [
        (_ctx.activeSection)
          ? (_openBlock(), _createBlock(_component_InfoPanel, {
              key: 0,
              header: _ctx.$t(_ctx.activeSection.label),
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeSection = null))
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, _ctx.activeSection.id, { mobile: true })
              ], undefined, true),
              _: 3
            }, 8, ["header"]))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 3
    }, 8, ["show"])
  ], 2))
}