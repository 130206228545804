// NOTE: FormatJS imports must be in order: polyfill => locale data
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-listformat/polyfill';

export function listFormatter(
  value: string[],
  locale: string
): string {
  const lf = new Intl.ListFormat(locale, {
    localeMatcher: 'best fit',
    style: 'long',
    type: 'unit'
  });

  return lf.format(value);
}

export function listFormatterParts(
  value: string[],
  locale: string
) {
  const lf = new Intl.ListFormat(locale, {
    localeMatcher: 'best fit',
    style: 'long',
    type: 'unit'
  });

  return lf.formatToParts(value);
}


/**
 * Intl.ListFormat
 */
declare namespace Intl {
  interface ListFormatOptions {
    style?: 'long' | 'short' | 'narrow';
    type?: 'conjunction' | 'disjunction' | 'unit';
    localeMatcher?: 'lookup' | 'best fit';
  }

  interface ListFormatPart {
    type: 'element' | 'literal';
    value: string;
  }

  interface ListFormat {
    constructor(locales?: string | string[], options?: ListFormatOptions): ListFormat;
    format(list: string[]): string;
    formatToParts(list: string[]): ListFormatPart[];
  }

  const ListFormat: {
    new (locales?: string | string[], options?: ListFormatOptions): ListFormat;
    (locales?: string | string[], options?: ListFormatOptions): ListFormat;
    supportedLocalesOf(
        locales: string | string[],
        options?: ListFormatOptions,
    ): string[];
  };
}
