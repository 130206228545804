<template>
  <div :class="$style.container">
    <input
      :id="id"
      v-model="internalValue"
      type="checkbox"
      :disabled="disabled"
      :aria-checked="ariaChecked"
      :aria-describedby="description ? descriptionId : undefined"
      :aria-label="ariaLabel"
      :class="`${$style.input} focus-outline`"
    />
    <div :class="$style.checkmark">
      <Icon
        v-if="modelValue === 'partial'"
        :key="modelValue"
        role="presentation"
        name="minus"
      />
      <Icon
        v-else-if="modelValue === 'on'"
        :key="modelValue"
        role="presentation"
        name="check"
      />
    </div>
    <label
      :for="id"
      :class="showLabel ? $style.label : 'visually-hidden'"
      :aria-hidden="!!ariaLabel"
    >
      <slot>
        {{ label }}
      </slot>
    </label>

    <p
      v-if="description"
      :id="descriptionId"
      :class="$style.description"
    >
      {{ description }}
    </p>
  </div>
</template>

<script lang="ts">
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, PropType } from 'vue';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'FormCheckbox',
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: String as PropType<FormCheckboxState>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    description: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    ariaLabel: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const id = generateUUID();
    const descriptionId = generateUUID();

    const internalValue = computed({
      get: () => props.modelValue === 'on',
      set: (val) => ctx.emit('update:modelValue', val ? 'on' : 'off')
    });

    const ariaChecked = computed(() => {
      return props.modelValue === 'on' ? 'true'
        : props.modelValue === 'off' ? 'false'
        : 'mixed';
    });

    return {
      ariaChecked,
      descriptionId,
      id,
      internalValue
    };
  }
});
</script>

<style module>
.container {
  max-width: max-content;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "checkbox label"
    "unused   description";
  column-gap: 1rem;
  row-gap: 0.25rem;
  align-items: center;
}

.input {
  grid-area: checkbox;

  border-radius: 0.25rem;
  border: 2px solid var(--c-dark-gray);
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  background-color: var(--c-white);
  margin: 0;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input:disabled {
  cursor: not-allowed;
  background-color: var(--c-light-gray);
}

.input:disabled + .checkmark {
  fill: var(--c-darkest-gray);
}

.input:disabled ~ .label {
  cursor: not-allowed;
}

.checkmark {
  grid-area: checkbox;
  pointer-events: none;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark svg {
  width: 1.25rem;
  height: 1.25rem;
}

.label {
  grid-area: label;
  line-height: 1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.description {
  grid-area: description;
}
</style>
