<template>
  <EmptyState
    :class="$style.emptyState"
    :link="helpLink"
    :isCompact="compact"
    :header="$t('alert.emptystate.header')"
    :content="$t('alert.emptystate.content')"
    :linkLabel="$t('alert.emptystate.link')"
  />
</template>

<script lang='ts'>
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  components: {
    EmptyState
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.ALERTS;
    const { windowWidth } = useWindowSize();
    const compact = computed(() => windowWidth.value > Breakpoint.HideNav);

    return {
      helpLink,
      compact
    };
  }
});
</script>

<style module>

.empty-state {
  text-align: left;
  border-radius: 0.5rem;
}

/* Match the empty state link to the alert link */
.empty-state a {
  color: var(--c-primary-blue);
  display: inline;
  line-height: var(--lh-body);
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  /* Focus padding */
  padding: 0.25rem;
  margin: -0.25rem;
}

@media screen and (max-width: 1200px /*px-vp-hide-nav*/) {
  .empty-state {
    text-align: center;
  }
}
</style>
