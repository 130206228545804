import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["disabled", "aria-haspopup", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PopoutDialog = _resolveComponent("PopoutDialog")!

  return (_openBlock(), _createBlock(_component_PopoutDialog, {
    reference: _ctx.reference,
    headerLabel: _ctx.headerLabel,
    placement: "bottom-start",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      (_ctx.subHeaderLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.subHeaderLabel), 1))
        : _createCommentVNode("", true),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(_ctx.$style.option)
          }, [
            ('slotName' in option)
              ? _renderSlot(_ctx.$slots, option.slotName, {
                  key: 0,
                  class: "focus-outline"
                })
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (option.iconName)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 0,
                        name: option.iconName
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true),
                  ('handler' in option)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: _normalizeClass({
              ['disabled']: option.inactive
            }),
                        disabled: option.inactive,
                        "aria-haspopup": option.hasPopup,
                        onClick: option.handler
                      }, _toDisplayString(_ctx.$t(option.label)), 11, _hoisted_2))
                    : (_openBlock(), _createBlock(_component_router_link, {
                        key: 2,
                        class: "focus-outline",
                        to: option.location
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(option.label)), 1)
                        ], undefined, true),
                        _: 2
                      }, 1032, ["to"]))
                ], 64))
          ], 2))
        }), 128))
      ])
    ], undefined, true),
    _: 3
  }, 8, ["reference", "headerLabel"]))
}