import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { SubjectCategory } from 'app/models/subject';
import { PartialView } from 'app/views/core/partial';
import { Freshable } from '../base/services/freshable';
import { Freshness } from '../base/services/freshness';
import { IdNamePair, ThunderFacets, ThunderMediaResponse, ThunderSearchResponse, ThunderSeriesResponse } from '../base/thunder';
import { Library } from './library';
import { ListParameters, SearchOptions } from './list-parameters';
import { Series } from './series';
import { SeriesListPage } from './series-list-page';
import { TitleRecord } from './title';
import { TitleListPage } from './title-list-page';

export type ListItemType = 'set' | 'title';
export class List<T extends TitleRecord | Series, R> implements Freshable {
  private readonly LIST_FILTERS = ['practiceAreas', 'classifications', 'jurisdictions', 'subjects', 'bisacCodes'];
  private readonly BISAC_NAME_PREFIX = 'Law / ';

  public readonly itemType: ListItemType;
  /**
   * @desc - The category this list belongs to
   */
  public category: SubjectCategory;
  public totalTitles: number;
  public priority: number;
  public description: string;
  public activePageNumber: number;
  public refinements: ListRefinements;
  public pages: {};
  public name: string;
  public params: ListParameters;
  public library: Library;
  public freshness: Freshness;
  public id: string;
  public breakpoint: string;
  protected _lastColor: string | [number, number, number];

  public static FALLBACK_COLORS = [
    '#B9121B',
    '#1F8A70',
    '#911146',
    '#BEDB39',
    '#31353D',
    '#FB2634',
    '#303828',
    '#FCE68D',
    '#C3094E',
    '#C6E5BB',
    '#E92A0E',
    '#601817',
    '#8ABD81',
    '#F5F2E8'
  ];


  constructor(library: Library, itemType: ListItemType, address: string) {
    this.library = library;
    this.params = new ListParameters();
    this.params.loadFromAddress(address);
    this.id = (this.params) ? this.params.id : this.id;
    this.name = this._buildProvisionalName();
    this.pages = {};
    this.activePageNumber = 1;
    this.refinements = { applied: {}, unapplied: {} };
    this.itemType = itemType;
    this.description = undefined;
    this.totalTitles = undefined;
    this.priority = undefined;
  }


  public async freshen(raiseNetworkError?: boolean, options: SearchOptions = {}): Promise<boolean> {
    if (options.makeStale) {
      this.page(1).freshness.makeStale();
    }

    return this.page(1).slice(options.limit).freshen(raiseNetworkError, options);
  }


  public arePropertiesFresh() {
    return true;
  }


  public page(pageNum?: number): TitleListPage | SeriesListPage {
    const page = typeof pageNum !== 'undefined' ? pageNum : this.activePageNumber;

    return (this.pages['p.' + page] =
      this.pages['p.' + page]
      || (this._isTitleList()
          ? new TitleListPage(this, page)
          : (this._isSetList()) ? new SeriesListPage(this, page)
          : undefined));
  }


  /**
   * Retrieves the current filter values for this list
   * @param skipSubject The subject ID to skip
   */
  public getFilterValues(skipSubject?: string): FilterGroups {
    const output: FilterGroups = {
      appliedSubjects: new Set(),
      selectedSubjects: new Set(),
      unappliedSubjects: new Set()
    };

    C.each(this.LIST_FILTERS, (filterSource) => {
      C.each(this.params[filterSource], (subjectID) => {
        if (skipSubject !== subjectID) {
          const sub = APP.library.catalog.getSubject(subjectID);
          sub.category = APP.library.catalog.getSubjectCategory(sub.id);
          output.selectedSubjects.add(sub);
          output.unappliedSubjects.add(sub);
        }
      });

      C.each(this.refinements.applied[filterSource], (subject: IdNamePair) => {
        if (skipSubject !== subject.id) {
          const sub = APP.library.catalog.getSubject(subject.id);
          sub.name = (sub.name === 'Subject') ? subject.name : sub.name;
          sub.category = APP.library.catalog.getSubjectCategory(sub.id);
          output.appliedSubjects.add(sub);
        }
      });

      C.each(this.refinements.unapplied[filterSource], (subject: IdNamePair) => {
        if (skipSubject !== subject.id) {
          const sub = APP.library.catalog.getSubject(subject.id);
          sub.name = (sub.name === 'Subject') ? subject.name : sub.name;
          if (filterSource === 'bisacCodes' && sub.name.startsWith(this.BISAC_NAME_PREFIX)) {
            sub.name = sub.name.substring(this.BISAC_NAME_PREFIX.length);
          }
          sub.category = APP.library.catalog.getSubjectCategory(sub.id);

          // Bisac codes only get added if they are for PracticeArea
          if (filterSource !== 'bisacCodes' || sub.category === 'PracticeArea') {
            output.unappliedSubjects.add(sub);
          }
        }
      });
    });

    return output;
  }


  public static getPath(params: ListParameters, itemType: ListItemType, pageNumber = 1): string {
    const path = [
      'browse',
      params.address()
    ];
    if (params.canBeSetOrTitle) {
      path.push(itemType);
    }
    path.push(`page-${pageNumber}`);

    return path.join('/');
  }


  private _isTitleList(): this is List<TitleRecord, ThunderMediaResponse> {
    return this.itemType === 'title';
  }


  private _isSetList(): this is List<Series, ThunderSeriesResponse> {
    return this.itemType === 'set';
  }


  /**
   * TODO: actually merge? rather than overwrite.
   * @param attrs
   */
  public mergeSearchAttributes(attrs: ThunderSearchResponse<R>) {
    let name;
    const qk = attrs.queryKeys;
    if (!name && qk) {
      if (this.params.source === 'subject' && qk.subjects) {
        for (const subj of qk.subjects) {
          if (subj.id === this.params.id) {
            name = subj.name;
            break;
          }
        }
      } else if (this.params.source === 'creator' && qk.creator) {
        name = qk.creator.name;
      } else if (this.params.source === 'publisher' && qk.publisher) {
        name = qk.publisher.name;
      } else if (this.params.source === 'imprint' && qk.imprint) {
        name = qk.imprint.name;
      }
    }

    this.name = this._christen(name);
    this.description = this._describe(undefined);
    if (typeof attrs.totalItems === 'number') {
      this.totalTitles = attrs.totalItems;
    } else {
      this.totalTitles = this.totalTitles || 0;
    }
    if (attrs.breakpoint) {
      this.breakpoint = attrs.breakpoint;
    }
    this.refinements = this._buildRefinements(attrs.facets) || this.refinements;
    if (attrs.sortOptions) {
      this.refinements.applied.sort = [];
      this.refinements.unapplied.sort = [];

      const allowedSorts = ListParameters.ALLOWED_SORTS.slice();
      const defaultSorts = ListParameters.DEFAULT_SORT[this.params.source]?.slice() || ListParameters.DEFAULT_SORT.default;
      if (this.params.source === 'curated' && !this.params.definition.isOrdered) {
        C.excise(defaultSorts, 'listorder');
      }

      // Max items and relevance sorting
      // are mutually exclusive in both implementation
      // and usage. Max items are only used in lists
      // which you don't search in, and relevance is not
      // deterministic so we can't guarantee an exact number of
      // results.
      if (this.params.source !== 'search'
          && (!this.params.query
          || (this.params.definition && this.params.definition.maxItems))) {
        C.excise(allowedSorts, 'relevance');
      }

      if (this.params.creator) {
        C.excise(allowedSorts, 'author');
      }

      for (let i = 0, ii = attrs.sortOptions.length; i < ii; ++i) {

        const opt = attrs.sortOptions[i];

        if (opt.id === 'seriesname') {
          opt.id = 'title';
        }

        opt.name = PartialView.text(`list.parameters.sort.${opt.id}`);

        // Elrond only wants to support a subset of sort options,
        // but sometimes we have lists that define a sort that's not
        // "allowed." In that case, we should add it back.
        if (!opt.isApplied
          && allowedSorts.indexOf(opt.id) < 0
          && (!this.params.sort || this.params.sort.id !== opt.id)
          && (this.params.composite().sort !== opt.id)
          && (!this.params.definition || this.params.definition.sort !== opt.id)
        ) {
          continue;
        }

        const sort: any = {
          id: opt.id,
          name: Constants.text(opt.id) || C.titleize(opt.name)
        };

        // Multiple sorts make things fun. Since the
        // default subsorts are relevance/title/releasedate,
        // we should ignore those if they're applied.
        // BUT, what if we manually applied one of those?
        // Then include it. Same for if a list's definition manually applies it.
        // Or, if it's not a list or a manual sort, but we have a query, we know
        // that default is relevance sorting.
        if (opt.isApplied
          && (ListParameters.DEFAULT_SORT.default.indexOf(opt.id) < 0
            || (this.params.sort && this.params.sort === opt.id)
            || (this.params.composite().sort === opt.id)
            || (this.params.query && opt.id === 'relevance')
            || (this.params.creator && opt.id === 'title')
            || (opt.id === defaultSorts[0])
          )
        ) {
          sort.innate = this.params.composite().sort !== sort.id;
          this.refinements.applied.sort = [sort];

          // We have multiple sorts, so we need to push
          // all of the sorts and excise the actual active one later.
          this.refinements.unapplied.sort.push(sort);
        } else if (opt.id !== 'default') {
          this.refinements.unapplied.sort.push(sort);
        }
      }
      // A temporary hack for Series Order sorting:
      if (this.params.source === 'series') {
        const sort = this.refinements.applied.sort[0];
        if (sort && sort.innate) {
          sort.id = '';
          sort.name = 'Series Order';
        } else {
          this.refinements.unapplied.sort.unshift({
            id: '',
            name: 'Series Order'
          });
        }
      }

      // We have multiple sorts, so let's find the one we
      // actually sorted by
      if (this.params.sort) {
        const currentApplied = this.refinements.applied.sort[0];
        const sortOption = attrs.sortOptions.find((s) => s.id === this.params.sort);

        if (currentApplied.id !== sortOption.id && sortOption.isApplied) {
          this.refinements.applied.sort = [ sortOption ];
        }
      }

      C.excise(this.refinements.unapplied.sort,
        (sortOption) => sortOption.id === this.refinements.applied.sort[0]?.id);
    }

    this.refinements.applied.availability = !!this.params.flagAvailable;
  }


  public mergeCollectionAttributes(attrs: { name: string; description?: string; priority?: number }) {
    const name = this._normalizeName(attrs.name);
    this.name = this._christen(name);
    this.description = this._describe(attrs.description);
    if (typeof attrs.priority === 'number') {
      this.priority = attrs.priority;
    }
    this.refinements.applied.availability = !!this.params.flagAvailable;
  }


  public path(pageNum?: number): string {
    return this._paramsToPath(this.params, pageNum || this.activePageNumber);
  }


  public subpath(paramProperties: any = {}): string {
    const clone = new ListParameters();
    clone.loadFromAddress(this.params.address());
    C.absorb(paramProperties, clone);

    return this._paramsToPath(clone);
  }


  protected _buildProvisionalName(): string {
    if (this.params.definition && this.params.definition.name) {
      return this.params.definition.name;
    }

    if (this.params.source === 'search') {
      if (this.params.query) {
        return (
          '&ldquo;' + C.safe(decodeURIComponent(this.params.query)) + '&rdquo;'
        );
      }

      return 'Advanced Search'; // ENGLISH
    }

    if (this.params.source === 'series') {
      const series = C.safe(this.params.id);

      return `${C.capitalize(this.params.source)}: ${series}`;
    }

    if (this.params.source === 'subject') {
      return 'Subject';
    }
    if (['curated', 'generated'].indexOf(this.params.source) >= 0) {
      return 'Collection'; // ENGLISH
    }
    if (this.params.source === 'everything') {
      return 'Everything!'; // ENGLISH
    }

    return this._normalizeName(this.params.source);
  }


  protected _buildRefinements(facets: ThunderFacets) {
    if (!facets) {
      return undefined;
    }
    const ref = { applied: {}, unapplied: {} };
    const addItemFn = (arrName, isApplied, item) => {
      const h = isApplied ? ref.applied : ref.unapplied;
      h[arrName] = h[arrName] || [];
      h[arrName].push(item);
    };
    C.each(facets.mediaTypes.items, (item) => {
      if (item.totalItems) {
        const fmt = Constants.toMediaType(item.id);
        const name = fmt === 'book' ? 'ebook' : fmt;
        addItemFn('formats', item.isApplied, {
          id: fmt,
          name: name,
          totalTitles: item.totalItems
        });
      }
    });
    C.each(
      facets.languages.items,
      (item) => {
        const lang = this.library.catalog.language(item.id, {
          id: item.id,
          name: item.name,
          totalTitles: item.totalItems
        });
        addItemFn('languages', item.isApplied, lang);
      }
    );
    C.each(
      facets.subjects.items,
      (item) => {
        const subj = new List<T, R>(this.library, this.itemType, 'subject-' + item.id);
        subj.id = item.id;
        subj.name = item.name;
        subj.totalTitles = item.totalItems;
        addItemFn('subjects', item.isApplied, subj);
      }
    );
    C.each(
      facets.practiceArea.items,
      (item) => {
        const subj = new List<T, R>(this.library, this.itemType, 'subject-' + item.id);
        subj.id = item.id;
        subj.name = item.name;
        subj.totalTitles = item.totalItems;
        addItemFn('practiceAreas', item.isApplied, subj);
      }
    );
    C.each(
      facets.classification.items,
      (item) => {
        const subj = new List<T, R>(this.library, this.itemType, 'subject-' + item.id);
        subj.id = item.id;
        subj.name = item.name;
        subj.totalTitles = item.totalItems;
        addItemFn('classifications', item.isApplied, subj);
      }
    );
    C.each(
      facets.jurisdiction.items,
      (item) => {
        const subj = new List<T, R>(this.library, this.itemType, 'subject-' + item.id);
        subj.id = item.id;
        subj.name = item.name;
        subj.totalTitles = item.totalItems;
        addItemFn('jurisdictions', item.isApplied, subj);
      }
    );
    C.each(
      facets.bisacCodes.items,
      (item) => {
        const subj = new List<T, R>(this.library, this.itemType, 'bisacCode-' + item.id);
        subj.id = item.id;
        subj.name = item.name;
        subj.totalTitles = item.totalItems;
        addItemFn('bisacCodes', item.isApplied, subj);
      }
    );

    C.each(facets.formats.items, (item) => {
      const fft = Constants.idToFulfillmentFormatType(item.id);
      if (fft) {
        addItemFn('devices', item.isApplied, {
          id: fft.id,
          name: fft.name,
          totalTitles: item.totalItems
        });
      }
    });

    return ref;
  }


  protected _paramsToPath(params: ListParameters, pageNum = 1): string {
    return List.getPath(params, this.itemType, pageNum);
  }


  protected _christen(name) {
    // Spotlight and generated lists:
    if (this.params.definition && this.params.definition.name) {
      return this.params.definition.name;
    }

    return name || this.name;
  }


  protected _describe(desc) {
    // Spotlight and generated lists:
    if (this.params.definition && this.params.definition.description) {
      return this.params.definition.description;
    }

    // Built-in descriptions:
    const description = desc || this.description;
    if (description && description !== this.name) {
      return this._normalizeDescription(description);
    }

    // Advanced searches:
    if (this.params.source === 'search') {
      const queries = [];
      const txt = (s) => Constants.text(s);
      if (this.params.flagPrerelease) {
        queries.push(txt('list.parameters.prerelease').toLowerCase());
      }
      if (this.params.flagReadalong) {
        queries.push(txt('list.parameters.readalong').toLowerCase());
      }
      C.each(
        ['title', 'creator', 'series', 'identifier'],
        (q) => {
          if (this.params[q]) {
            queries.push(
              (queries.length ? '' : 'titles ') + // ENGLISH
              'where the ' +
              q +
              ' matches ' + // ENGLISH
                '&ldquo;' +
                C.safe(this.params[q]) +
                '&rdquo;'
            );
          }
        }
      );
      if (this.params.dateRange) {
        queries.push(
          'added in the ' +
            txt('list.parameters.date-added.' + this.params.dateRange)
        );
      }

      if (queries.length) {
        return 'Looking for ' + C.listToSentence(queries) + '.'; // ENGLISH
      }
    }

    // Common collections: TMP! ENGLISH!
    const arr = {
      'Most Popular': [
        'The hottest <FORMATS> in the library right now. If it&rsquo;s',
        'not available, place a hold!'
      ],
      'Available Now': [
        'A selection of popular <FORMATS> that are',
        'ready for borrowing. Borrow one and start reading now!'
      ],
      'Newly Added': [
        'See what&rsquo;s new!',
        'Here are all the <FORMATS> our busy librarians have been',
        'adding to our collection.'
      ]
    }[this.name] || [''];
    const formats = this.params.formats.length ? this.params.formats : ['title'];

    return arr
      .join(' ')
      .replace(/<FORMATS>/, C.listToSentence(formats, C.pluralize));
  }


  protected _normalizeName(name: string) {
    return C.titleize(this._normalizeDescription(name));
  }


  protected _normalizeDescription(desc: string) {
    let description = desc || '';
    description = description.replace(/\be-?book/gi, 'book');
    description = description.replace(/e-?audiobook/gi, 'audiobook');

    return description;
  }
}


interface ListRefinements {
  applied: ListRefinement;
  unapplied: ListRefinement;
}

export interface ListRefinement {
  availability?: boolean;
  bisacCodes?: IdNamePair[];
  sort?: (IdNamePair & { innate?: boolean })[];
  formats?: IdNamePair[];
  subjects?: IdNamePair[];
  languages?: unknown[];
  devices?: unknown[];
  practiceAreas?: IdNamePair[];
  jurisdictions?: IdNamePair[];
  classifications?: IdNamePair[];
}

export interface FilterGroups {
  appliedSubjects: Set<TitleList>;
  unappliedSubjects: Set<TitleList>;
  selectedSubjects: Set<TitleList>;
}


// https://stackoverflow.com/a/54520829
type KeysMatching<T, V> = {[K in keyof T]: T[K] extends V ? K : never}[keyof T];
export type ListRefinementArrays = KeysMatching<ListRefinement, { length: number }>;

export class TitleList extends List<TitleRecord, ThunderMediaResponse> {
  constructor(library: Library, address: string) {
    super(library, 'title', address);
  }
}

export class SeriesList extends List<Series, ThunderSeriesResponse> {
  constructor(library: Library, address: string) {
    super(library, 'set', address);
  }
}

