<template>
  <table :class="$style.table">
    <thead>
      <tr :class="$style.header">
        <th>
          <span class="visually-hidden">
            {{ $t('annotations.display.property.expander') }}
          </span>
        </th>
        <th>
          {{ $t('annotations.display.property.color') }}
        </th>
        <th>
          {{ $t('annotations.display.property.highlight') }}
        </th>
        <th v-if="!hideNote">
          {{ $t('annotations.display.property.note') }}
        </th>
        <th>
          {{ $t('annotations.display.property.citation') }}
        </th>
        <th>
          {{ $t('annotations.display.property.release') }}
        </th>
        <th>
          {{ $t('annotations.display.property.lastUpdated') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="snapshot in snapshots"
        :key="snapshot.id"
        :class="{
          [$style.row]: true,
          [$style.expanded]: expanded.has(snapshot.id)
        }"
      >
        <td data-column="expander">
          <button
            :id="`expander-${snapshot.id}`"
            type="button"
            :class="$style.expanderButton"
            :aria-label="$t('annotations.display.expandText')"
            @click="() => toggleExpanded(snapshot.id)"
          >
            <Icon
              :class="$style.expander"
              name="chevron-down"
            />
          </button>
        </td>
        <td data-column="color_id">
          <HighlightColor
            :colorGroup="snapshot.color_id"
          />
        </td>
        <td data-column="quote">
          <p :class="$style.text">
            {{ snapshot.quote }}
          </p>
        </td>
        <td
          v-if="!hideNote"
          data-column="note"
        >
          <p
            v-if="snapshot.note"
            :class="$style.text"
          >
            {{ snapshot.note }}
          </p>
          <p
            v-else
            :class="$style.noNote"
          >
            {{ $t('annotations.display.noNote') }}
          </p>
        </td>
        <td data-column="citation">
          <p :class="$style.text">
            {{ snapshot.citation }}
          </p>
        </td>
        <td data-column="release">
          <p>{{ snapshot.release }}</p>
          <p>{{ snapshot.release_date }}</p>
        </td>
        <td data-column="syncstamp">
          <RelativeDate :timestamp="snapshot.syncstamp" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { PostishCopyAnnotationSnapshot } from 'app/base/postish';
import HighlightColor from 'app/components/HighlightColor.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import { defineComponent, nextTick, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    HighlightColor,
    RelativeDate
  },
  props: {
    snapshots: {
      type: Array as PropType<PostishCopyAnnotationSnapshot[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  setup: () => {
    const expanded = ref<Set<number>>(new Set());
    const toggleExpanded = async (id: number) => {
      if (expanded.value.has(id)) {
        expanded.value.delete(id);
      } else {
        expanded.value.add(id);
      }

      await nextTick();
      const expansionButton = document.getElementById(`expander-${id}`);
      expansionButton?.scrollIntoView({ behavior: 'smooth' });
    };

    return {
      expanded,
      toggleExpanded
    };
  }
});
</script>

<style module>

.table {
  --cell-padding: 12px 20px;
}

@media screen and (max-width: 1420px) {
  .table {
    --cell-padding: 12px 8px;
  }
}

.table {
  background-color: var(--c-white);
  border: 1px solid var(--c-dark-gray);
  text-align: start;
  width: 100%;
}

.header {
  background-color: var(--c-light-gray);
  color: var(--c-medium-black);
  font-weight: var(--fw-bold);
  font-size: var(--fs-metadata);
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
}

.header th {
  padding: var(--cell-padding);
}

.row td {
  padding: var(--cell-padding);
  vertical-align: top;
}

.row:not(:first-of-type) {
  border-block-start: 1px solid var(--c-dark-gray);
}

.row td[data-column="expander"],
.row td[data-column="color_id"],
.row td[data-column="syncstamp"] {
  width: auto;
  white-space: nowrap;
}

.row td[data-column="citation"],
.row td[data-column="release"] {
  min-width: 10ch;
  max-width: fit-content;
}

.row td[data-column="quote"],
.row td[data-column="note"] {
  min-width: 10ch;
}

.row td .text {
  white-space: pre-wrap;
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}

.row:not(.expanded) .text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.row .expander-button {
  height: 2rem;
  width: 2rem;
  scroll-margin: 3rem; /* May need to adjust depending on mobile layout */
}

.row .expander {
  transition: transform 200ms ease;
}

.row.expanded .expander {
  transform: rotate(-180deg);
}

.no-note {
  font-style: italic;
  color: var(--c-medium-black);
}

</style>
