<template>
  <Page
    :header="title"
    :subtitle="subtitle"
    :announceHeader="announcement"
  >
    <template #toolbar>
      <FilterButton
        v-if="allFilters"
        :options="allFilters"
        filterType="subject"
        :iconOnly="mobile"
        @input="(val) => $emit('update:filters', val)"
      />
      <SortSelect
        v-model="sort"
        :options="sortOptions"
        optionKey="id"
        optionLabel="label"
        :class="$style.sort"
      />
    </template>
    <template #default>
      <TabView
        v-if="tabs.length > 1"
        :tabs="tabs"
        :initialTab="currentTab"
        @tab="(val) => $emit('update:tab', val)"
      >
        <template
          v-for="tab in tabs"
          :key="tab.id"
          #[`panel-${tab.id}`]
        >
          <PaginatedCardList
            :state="tab.state"
            @update:currentPage="(val) => $emit('update:page', val)"
          />
        </template>
      </TabView>
      <PaginatedCardList
        v-else-if="tabs.length === 1"
        :state="tabs[0].state"
        @update:currentPage="(val) => $emit('update:page', val)"
      />
    </template>
  </Page>
</template>

<script lang='ts'>
import { SearchType } from 'app/base/search';
import PaginatedCardList from 'app/components/cards/PaginatedCardList.vue';
import FilterButton from 'app/components/FilterButton.vue';
import Page from 'app/components/Page.vue';
import SortSelect from 'app/components/SortSelect.vue';
import TabView, { Tab } from 'app/components/TabView.vue';
import { useI18n } from 'app/functions/use-i18n';
import { ListTabId } from 'app/functions/use-list-page';
import { SearchState } from 'app/functions/use-search';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Subject } from 'app/models/subject';
import { setDocumentTitle } from 'app/router/document-title';
import { computed, defineComponent, PropType, watchEffect } from 'vue';


type SearchTypeTabMapping<T extends ListTabId> = T extends 'set' ? SearchType.Series : SearchType.Title;

type ListTab<T extends ListTabId> = Tab & {
  id: T;
  state: SearchState<SearchTypeTabMapping<T>>;
};

type SearchSortOption = {
  label: string;
  id: string;
};

export default defineComponent ({
  name: 'BaseListPage',
  components : {
    Page,
    FilterButton,
    SortSelect,
    TabView,
    PaginatedCardList
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    titleState: {
      type: Object as PropType<SearchState<SearchType.Title>>,
      default: undefined
    },
    seriesState: {
      type: Object as PropType<SearchState<SearchType.Series>>,
      default: undefined
    },
    currentTab: {
      type: String as PropType<ListTabId>,
      default: undefined
    },
    sortOptions: {
      type: Array as PropType<SearchSortOption[]>,
      required: true
    },
    currentSort: {
      type: Object as PropType<SearchSortOption>,
      required: true
    },
    allFilters: {
      type: Array as PropType<Subject[]>,
      required: true
    },
    ariaTitle: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'update:page',
    'update:tab',
    'update:sort',
    'update:filters'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    //filters
    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.Narrow);

    //sort
    const sort = computed({
      get: () => {
        return props.currentSort;
      },
      set: (newSort) => ctx.emit('update:sort', newSort)
    });

    //tab view
    const tabs = computed(() => {
      const _tabs: ListTab<ListTabId>[] = [];

      if (props.titleState) {
        _tabs.push({
          id: 'title',
          label: t('list.tabs.title'),
          count: props.titleState.state === 'success' ? props.titleState.response.list.totalItems : undefined,
          state: props.titleState
        });
      }

      if (props.seriesState) {
        _tabs.push({
          id: 'set',
          label: t('list.tabs.set'),
          count: props.seriesState.state === 'success' ? props.seriesState.response.list.totalItems : undefined,
          state: props.seriesState
        });
      }

      return _tabs;
    });

    // If all relevant tabs are loading, don't announce anything
    const shouldMakeAnnouncement = computed(() => {
      const relevantStates = [props.titleState, props.seriesState].filter((state) => !!state);
      const allLoading = relevantStates.every((state) => state.state === 'loading');

      return !allLoading;
    });

    const announcement = computed(() => shouldMakeAnnouncement.value ? props.ariaTitle || props.title : false);

    watchEffect(() => setDocumentTitle(props.title));

    return {
      announcement,
      mobile,
      sort,
      tabs
    };
  }
});
</script>

<style lang='less' module>
  @import 'app/views/core/base.less';

  .sort {
    margin-left: auto;

    > div {
      min-width: 150px;
    }
  }

  @media screen and (max-width: @px-vp-narrow) {
    .sort > div {
      min-width: 130px;
    }
  }
</style>
