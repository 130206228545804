import { APP } from 'app/base/app';
import { BankScopeNative } from 'app/base/bank/bank-scope-native';
import { C } from 'app/base/common';
import { Bridge, InternalBridge } from 'app/base/shell/bridge';
import { BifocalView } from 'app/views/open/bifocal-view';
import { BifocalViewProxy } from 'app/views/open/bifocal-view-proxy';
import { Quirks } from 'lib/quirkbase/quirkbase';
import { Bank } from '../bank/bank';
import { EventHandler } from '../event-map';
import { Shell, ShellInfo, ShellTraits } from './shell';
import { ShellNone } from './shell-none';
declare const BRIDGE: Bridge;

export class ShellNative extends ShellNone implements Shell {
  private _bankScopesHandler?: EventHandler<'msg:bank:read'>;
  private _jsonStringifyEscapesSeparators?: boolean;


  constructor(info: ShellInfo) {
    super();
    this.info = info;
    this.info.type = 'Native';
    this.bridge = this._constructBridge();
    this._detectCompatibilityClasses();
    this._sendShortcuts();
    this._requestTraits();
  }


  public capability(capability: string): any {
    if (this.bridge.capabilities) {
      const answer = this.bridge.capabilities[capability];
      if (typeof answer !== 'undefined') {
        return answer;
      }
    }
    if (capability === 'rosters') {
      return true;
    }
    if (capability === 'soft-keyboard') {
      return navigator.userAgent.match(/Android|iOS/) ? true : false;
    }
    if (capability === 'geolocation' && navigator.userAgent.match(/Edge/)) {
      return false;
    }
  }


  // Go full-screen on android.
  public immerse(value: boolean): void {
    this.transmit({ name: 'surface:tint', immersive: value, tint: 'none' });
  }


  public dispatchMessageObjectToShell(object: {}): void {
    this._logMessageObject(object);
    const jsonString = this._safenObjectToJSON(object);
    this.bridge.clientToShellAsJSON(jsonString);
  }


  public openInNewView(url: string): Window | null | boolean {
    if (this.has('nav:url:handle')) {
      APP.shell.transmit({
        name: 'nav:url:handle',
        url: url,
        openWithExternalApp: true //may want this value to be passed in if we use this method elsewhere
      });

      return true;
    }

    return super.openInNewView(url);
  }


  protected _safenObjectToJSON(object: {}) {
    let jsonString = JSON.stringify(object);
    if (typeof this._jsonStringifyEscapesSeparators === 'undefined') {
      this._jsonStringifyEscapesSeparators =
        JSON.stringify(['\u2028\u2029']) !== '["\u2028\u2029"]';
    }
    if (!this._jsonStringifyEscapesSeparators) {
      jsonString = jsonString.replace(/\u2028/g, '\\u2028');
      jsonString = jsonString.replace(/\u2029/g, '\\u2029');
    }

    return jsonString;
  }


  protected _constructBridge(): InternalBridge {
    const bridge: InternalBridge = { capabilities: {} };
    if (BRIDGE) {
      if (typeof BRIDGE.clientToShellAsJSON === 'function') {
        bridge.clientToShellAsJSON = (jsonString) => {
          BRIDGE.clientToShellAsJSON!(jsonString);
        };
      } else if (typeof BRIDGE.clientToShell === 'function') {
        bridge.clientToShellAsJSON = (jsonString) => {
          BRIDGE.clientToShell!(JSON.parse(jsonString));
        };
      }
      if (typeof BRIDGE.capabilities === 'function') {
        bridge.capabilities = BRIDGE.capabilities();
      } else {
        bridge.capabilities = BRIDGE.capabilities;
      }
      if (C.isString(bridge.capabilities)) {
        bridge.capabilities = JSON.parse(bridge.capabilities);
      }
      if (typeof BRIDGE.userAgent === 'function') {
        bridge.userAgent = BRIDGE.userAgent();
      } else {
        bridge.userAgent = BRIDGE.userAgent;
      }
      if (typeof BRIDGE.environment === 'function') {
        bridge.environment = BRIDGE.environment();
      } else {
        bridge.environment = BRIDGE.environment;
      }
    }
    bridge.environment = bridge.environment || '';
    bridge.userAgent = bridge.userAgent || navigator.userAgent;
    bridge.capabilities = bridge.capabilities || {};
    if (!bridge.clientToShellAsJSON) {
      bridge.clientToShellAsJSON = this._clientToShellFallback.bind(this);
    }

    return bridge;
  }


  protected _listen(): void {
    super._listen();
    APP.events.on(
      this.bridgeMessageEventType('receive'),
      (evt) => this._onBridgeMessageEvent(evt),
      window
    );
    APP.events.on('title:switch', (_) => this._onTitleSwitch());
    APP.events.on('msg:platform:traits', (evt) => this._recordTraits(evt.m));
  }


  protected _startBank(onBankCallback: (bank: Bank) => void): void {
    if (this.has('bank')) {
      this._onBankCallback = onBankCallback;
      this._bankScopesHandler = APP.events.on('msg:bank:read', (evt) => this._onBankScopes(evt));
      this.transmit({
        name: 'bank:read',
        dest: 'shell',
        source: 'client',
        scopes: ['elrond']
      });
    } else {
      super._startBank(onBankCallback);
    }
  }


  protected _onBankScopes(evt: any): void {
    APP.events.off(this._bankScopesHandler);
    this._onBankStarted(
      new BankScopeNative('elrond', evt.m.scopes.elrond)
    );
  }


  protected _createSpecialViews(): void {
    if (this.has('ui:bifocal-webview')) {
      this.bifocal = new BifocalViewProxy();
    } else {
      this.bifocal = new BifocalView(APP.arena.surface);
    }
  }


  protected _onBridgeMessageEvent(evt: any): void {
    this._routeMessageObject(evt.detail, evt.detail.source || 'shell');
    evt.preventDefault();
  }


  protected _receiveMessageObject(object: {}, source: string): void {
    this._logMessageObject(object);
    super._receiveMessageObject(object, source);
  }


  protected _dispatchMessageObject(object: any): void {
    object.source = object.source || 'client';
    if (object.dest === 'shell') {
      this.dispatchMessageObjectToShell(object);
    } else {
      super._dispatchMessageObject(object);
    }
  }


  protected _detectCompatibilityClasses(): void {
    this._applyCompatibilityClass(
      'bridged-' + this.info.platform!.toLowerCase()
    );
    if (Quirks.ask('android-chrome chrome<40')) {
      this._applyCompatibilityClass('kitkat-webview');
    }
  }


  protected _clientToShellFallback(jsonString: string): void {
    const object = JSON.parse(jsonString);
    const evtType = this.bridgeMessageEventType('send');
    const evt = new CustomEvent(evtType, { detail: object });
    window.dispatchEvent(evt);
  }


  protected _logMessageObject(object: any): void {
    if (this.info && this.info.flavor === 'DEBUG') {
      console.log('[BRIDGE] ➜ %s — %s %o', object.dest, object.name, object);
    }
  }


  protected _onTitleSwitch()  {
    this._sendShortcuts();
  }


  protected _sendShortcuts() {
    let shortcuts: {type: string; title: string}[] = [];

    if (APP.activeTitle.isActive()) {
      shortcuts.push({
        type: 'nav:title',
        title: APP.activeTitle.title?.title
      });
    }

    shortcuts = shortcuts.concat([{
      type: 'nav:home:root',
      title: 'Home'
    }, {
      type: 'nav:library:root',
      title: 'Explore'
    }, {
      type: 'nav:mybooks:root',
      title: 'My books'
    },
    {
      type: 'nav:notes:root',
      title: 'Annotations'
    }]);

    this.transmit({ name: 'nav:shortcuts', shortcuts: shortcuts });
  }


  protected _requestTraits(): void {
    this.transmit('platform:traits');
  }


  protected _recordTraits(evt: ShellTraits): void {
    this.traits = evt;
  }
}
