import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagActions = _resolveComponent("TagActions")!
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_TextFilter = _resolveComponent("TextFilter")!
  const _component_TitleCard = _resolveComponent("TitleCard")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Page = _resolveComponent("Page")!
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      (_ctx.tag)
        ? (_openBlock(), _createBlock(_component_Page, {
            key: 0,
            header: _ctx.tag.name,
            announceHeader: _ctx.$t('tag.ariaHeader', { tag: _ctx.tag.name }),
            useBackToTop: true
          }, _createSlots({
            context: _withCtx(() => [
              _createVNode(_component_TagActions, { tag: _ctx.tag }, null, 8, ["tag"])
            ]),
            default: _withCtx(() => [
              (_ctx.hasTitles)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.subjectFilteredTitles.length)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(_ctx.$style.count)
                        }, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.showingCount), 1)
                        ], 2))
                      : _createCommentVNode("", true),
                    (_ctx.textAndSubjectFilteredTitles.length)
                      ? (_openBlock(), _createElementBlock("ol", {
                          key: 1,
                          class: _normalizeClass(_ctx.$style.cardList)
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textAndSubjectFilteredTitles, (item) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: item.slug
                            }, [
                              _createVNode(_component_TitleCard, { title: item }, null, 8, ["title"])
                            ]))
                          }), 128))
                        ], 2))
                      : (_openBlock(), _createBlock(_component_EmptyState, {
                          key: 2,
                          header: _ctx.$t('tag.filters.empty.header'),
                          content: _ctx.$t('tag.filters.empty.content')
                        }, null, 8, ["header", "content"]))
                  ], 64))
                : (_openBlock(), _createBlock(_component_EmptyState, {
                    key: 1,
                    header: _ctx.$t('tag.empty.header'),
                    content: _ctx.$t('tag.empty.content')
                  }, null, 8, ["header", "content"]))
            ]),
            _: 2
          }, [
            (_ctx.hasTitles)
              ? {
                  name: "toolbar",
                  fn: _withCtx(() => [
                    _createVNode(_component_FilterButton, {
                      options: _ctx.availableSubjects,
                      filterType: "subject",
                      iconOnly: _ctx.mobile,
                      onInput: _ctx.updatePath
                    }, null, 8, ["options", "iconOnly", "onInput"]),
                    _createVNode(_component_TextFilter, {
                      modelValue: _ctx.textFilter,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textFilter) = $event)),
                      class: _normalizeClass(_ctx.$style.textFilter),
                      label: _ctx.$t('tag.filters.text.placeholder')
                    }, null, 8, ["modelValue", "class", "label"])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["header", "announceHeader"]))
        : (_openBlock(), _createBlock(_component_NotFound, {
            key: 1,
            messageText: _ctx.$t('tag.error.missing')
          }, null, 8, ["messageText"]))
    ], undefined, true),
    _: 1
  }))
}