import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_NtcTitleCard = _resolveComponent("NtcTitleCard")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_Page, {
        header: _ctx.$t('ntc.header'),
        announceHeader: _ctx.$t('ntc.ariaHeader'),
        class: _normalizeClass(_ctx.$style.page)
      }, {
        default: _withCtx(() => [
          (_ctx.state === 'loading')
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                name: "spinner",
                class: _normalizeClass(_ctx.$style.loading),
                "aria-label": _ctx.$t('general.loading')
              }, null, 8, ["class", "aria-label"]))
            : (_ctx.providers.length)
              ? (_openBlock(), _createElementBlock("ol", {
                  key: 1,
                  class: _normalizeClass(_ctx.$style.cardList)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.providers, (provider) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: provider.id
                    }, [
                      _createVNode(_component_NtcTitleCard, {
                        provider: provider,
                        error: _ctx.ntcError === provider.id,
                        onAccess: ($event: any) => (_ctx.showPrompt(provider.id)),
                        onClearError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ntcError = ''))
                      }, null, 8, ["provider", "error", "onAccess"])
                    ]))
                  }), 128))
                ], 2))
              : (_openBlock(), _createBlock(_component_EmptyState, {
                  key: 2,
                  class: _normalizeClass(_ctx.$style.empty),
                  link: _ctx.helpLink,
                  header: _ctx.$t('ntc.emptystate.header'),
                  content: _ctx.$t('ntc.emptystate.content'),
                  linkLabel: _ctx.$t('ntc.emptystate.link', {
          b: (val) => { return `<b>${val}</b>`; }
        })
                }, null, 8, ["class", "link", "header", "content", "linkLabel"]))
        ], undefined, true),
        _: 1
      }, 8, ["header", "announceHeader", "class"])
    ], undefined, true),
    _: 1
  }))
}