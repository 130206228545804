<template>
  <header
    :class="$style.header"
    :style="`--c-channel:${channelColor}`"
  >
    <div
      class="notch"
      :class="isUpdating && $style.updateStripNotch"
    ></div>

    <UpdateStrip
      @show="isUpdating = true"
      @cancel="isUpdating = false"
    />

    <div
      v-if="mobile"
      :class="$style.mobile"
    >
      <button
        :aria-label="$t('header.menu')"
        :class="$style.icon"
        @click="$emit('show-nav')"
      >
        <Icon name="menu" />
      </button>
      <router-link
        :to="{ name: 'Home' }"
        :class="$style.logo"
      >
        <LibraryLogo />
      </router-link>

      <div :class="$style.menus">
        <AlertMenu />
        <UserMenu />
      </div>
    </div>
    <div :class="$style.strip">
      <MobileSearch
        v-if="mobile"
        v-model="query"
        :class="$style.searchBar"
      />
      <SearchBar
        v-else
        v-model="query"
        :class="$style.searchBar"
      />

      <template
        v-if="!mobile"
      >
        <div :class="$style.menus">
          <AlertMenu />
          <UserMenu />
        </div>
      </template>
    </div>
  </header>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import env from 'app/base/env';
import AlertMenu from 'app/components/AlertMenu.vue';
import { color } from 'app/components/ChannelIndicator.vue';
import LibraryLogo from 'app/components/LibraryLogo.vue';
import MobileSearch from 'app/components/MobileSearch.vue';
import SearchBar from 'app/components/SearchBar.vue';
import UpdateStrip from 'app/components/UpdateStrip.vue';
import UserMenu from 'app/components/UserMenu.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'HeaderBar',
  components: {
    LibraryLogo,
    SearchBar,
    MobileSearch,
    UpdateStrip,
    UserMenu,
    AlertMenu
},
  emits: [
    'show-nav'
  ],
  setup: () => {
    const { windowWidth } = useWindowSize();
    const isUpdating = ref(false);
    const mobile = computed(() => windowWidth.value <= Breakpoint.HideNav);
    const showUserMenu = ref(false);
    const showAlerts = ref(false);
    const query = ref('');

    const showChannel = APP.flags.get('debug');
    const channelColor = color(env.CHANNEL);

    return {
      channelColor,
      isUpdating,
      mobile,
      query,
      showChannel,
      showUserMenu,
      showAlerts
    };
  }
});
</script>

<style lang="less" module>
@import '../../app/views/core/base.less';

.header {
  --spacing: 1rem;
  display: flex;
  flex-direction: column;
}

.update-strip-notch {
  background-color: @c-primary;
}

.mobile {
  display: grid;
  grid-template-columns: 5rem 1fr 5rem;
  align-items: center;
  padding: 0.5rem;
  background-color: @c-white;
}

.logo {
  line-height: 0;
  margin: 0 auto;
  .ellipsis;
  .focus-outline;

  img {
    height: 2.2rem;
  }
}

.icon {
  display: inline-block;
  line-height: 0;
  border-radius: 0.25rem;

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  @media screen and (max-width: @px-vp-hide-nav) {
    justify-self: start;
  }
}

.account-icon {
  border-radius: 50%;
}

.nav-bar {
  position: fixed;
  height: 100%;
  width: @px-nav-width;
  box-sizing: border-box;
}

.strip {
  border-bottom: 1px solid var(--c-channel);
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0 1rem;

  .search-bar,
  .menus {

    & > button,
    & > input {
      .focus-outline-dark
    }
  }

  @media screen and (max-width: @px-vp-hide-nav) {
    padding: .5rem 0;
    margin: 0 .5rem;
  }
}

.search-bar {
  width: 60%;

  @media screen and (max-width: @px-vp-hide-nav) {
    width: 100%;
  }
}

.channel {
  align-self: center;
  margin: 0 1rem 0 auto;

  @media screen and (max-width: @px-vp-hide-nav) {
    margin-left: 1rem;
  }
}

.menus {
  display: grid;
  gap: var(--spacing);
  grid-template-columns: auto 1fr;
  margin-right: 0.5rem;

  @media screen and (max-width: @px-vp-narrow) {
    justify-self: end;
    margin-right: 0rem;
  }
}
</style>
