import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["id", "aria-label"]
const _hoisted_5 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _component_SeriesCover = _resolveComponent("SeriesCover")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.alert]: true,
      [_ctx.$style.unreviewed]: !_ctx.alertWithMetadata.reviewed
    }),
    "aria-label": _ctx.unreviewedLabel
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.metadata)
    }, [
      (_ctx.alertWithMetadata.type === 'TitleNewRelease' || _ctx.alertWithMetadata.type === 'TitleNotAvailable')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_Cover, {
              class: _normalizeClass(_ctx.$style.titleCover),
              item: _ctx.alertWithMetadata.title,
              bordered: true
            }, null, 8, ["class", "item"]),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.info)
            }, [
              _createElementVNode("h3", {
                class: _normalizeClass(_ctx.$style.type),
                "aria-label": _ctx.unreviewedLabel
              }, _toDisplayString(_ctx.typeName), 11, _hoisted_2),
              _createElementVNode("p", {
                class: _normalizeClass([_ctx.$style.title, _ctx.$style.truncated])
              }, _toDisplayString(_ctx.alertWithMetadata.title.title), 3),
              (_ctx.alertWithMetadata.title.subtitle)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: _normalizeClass([_ctx.$style.subtitle, _ctx.$style.truncated])
                  }, _toDisplayString(_ctx.alertWithMetadata.title.subtitle), 3))
                : _createCommentVNode("", true)
            ], 2)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_SeriesCover, {
              series: _ctx.alertWithMetadata.series,
              width: _ctx.seriesCoverSizes.alertShade,
              class: _normalizeClass(_ctx.$style.seriesCover)
            }, null, 8, ["series", "width", "class"]),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.info)
            }, [
              _createElementVNode("h3", {
                class: _normalizeClass(_ctx.$style.type),
                "aria-label": _ctx.unreviewedLabel
              }, _toDisplayString(_ctx.typeName), 11, _hoisted_3),
              _createElementVNode("p", {
                class: _normalizeClass([_ctx.$style.title, _ctx.$style.truncated])
              }, _toDisplayString(_ctx.alertWithMetadata.series.name), 3)
            ], 2)
          ], 64)),
      _createElementVNode("button", {
        id: `alert-review-${_ctx.alertWithMetadata.id}`,
        "aria-label": _ctx.$t('alert.review'),
        class: _normalizeClass(_ctx.$style.fullButton),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('view-details', _ctx.alertWithMetadata)))
      }, null, 10, _hoisted_4)
    ], 2),
    _createElementVNode("button", {
      "aria-label": _ctx.$t('alert.clear'),
      class: _normalizeClass(_ctx.$style.delete),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('clear')))
    }, [
      _createVNode(_component_Icon, { name: "trashcan" })
    ], 10, _hoisted_5)
  ], 10, _hoisted_1))
}