import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.card]: true,
      [_ctx.$style.unreviewed]: _ctx.job.status === 'FINISHED' && !_ctx.job.reviewed
    })
  }, [
    (_ctx.job.status === 'ERROR')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.error)
        }, [
          _createElementVNode("header", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.releaseLabel), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.additional)
          }, [
            _createVNode(_component_Icon, {
              name: "warning",
              class: _normalizeClass(_ctx.$style.errorIcon)
            }, null, 8, ["class"]),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('annotationCopying.manageCopying.card.error')), 1)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.actions)
          }, [
            _createVNode(_component_FormSubmitButton, {
              label: _ctx.$t('annotationCopying.banner.actions.retry'),
              size: "small",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', 'retry')))
            }, null, 8, ["label"]),
            _createVNode(_component_FormSubmitButton, {
              label: _ctx.$t('annotationCopying.banner.actions.clear'),
              size: "small",
              class: _normalizeClass(_ctx.$style.clear),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update', 'clear')))
            }, null, 8, ["label", "class"])
          ], 2)
        ], 2))
      : (_ctx.job.status === 'IN_PROGRESS' || _ctx.job.status === 'QUEUED')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.progress)
          }, [
            _createElementVNode("header", null, [
              _createElementVNode("p", null, _toDisplayString(_ctx.releaseLabel), 1),
              _createVNode(_component_Icon, {
                name: "spinner",
                class: _normalizeClass(_ctx.$style.progressIcon)
              }, null, 8, ["class"])
            ]),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.$style.additional)
            }, _toDisplayString(_ctx.$t('annotationCopying.manageCopying.card.inProgress')), 3)
          ], 2))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style.finished)
          }, [
            (!_ctx.job.reviewed)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.unreviewedTag)
                }, _toDisplayString(_ctx.$t('annotationCopying.manageCopying.card.complete.unreviewed')), 3))
              : _createCommentVNode("", true),
            _createElementVNode("header", null, [
              _createElementVNode("p", null, _toDisplayString(_ctx.releaseLabel), 1)
            ]),
            _createElementVNode("dl", {
              class: _normalizeClass(_ctx.$style.additional)
            }, [
              _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annotationCopying.manageCopying.card.complete.copied')), 1),
              _createElementVNode("dd", { innerHTML: _ctx.numCopied }, null, 8, _hoisted_1),
              _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annotationCopying.manageCopying.card.complete.finished')), 1),
              _createElementVNode("dd", null, [
                _createVNode(_component_RelativeDate, {
                  timestamp: Date.parse(_ctx.job.created_at)
                }, null, 8, ["timestamp"])
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.actions)
            }, [
              _createVNode(_component_FormSubmitButton, {
                label: _ctx.$t('annotationCopying.banner.actions.review'),
                size: "small",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update', 'review')))
              }, null, 8, ["label"])
            ], 2)
          ], 2))
  ], 2))
}