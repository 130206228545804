<template>
  <button
    v-bind="$attrs"
    id="context-menu"
    ref="contextMenuButton"
    :class="[$style.contextMenuButton, 'dark']"
    type="button"
    aria-haspopup="dialog"
    @click="showContextMenu = true"
  >
    {{ $t('title.contextMenu.header') }}
    <Icon name="overflow" />
  </button>

  <TitleDetailsContextMenu
    v-if="showContextMenu"
    :reference="contextMenuButton"
    :subscribeText="subscribeTextLong"
    :title="title"
    :allowCopying="allowManualCopy"
    @close="showContextMenu = false"
    @show:cover="showCoverView = true"
    @show:manualcopy="showManualCopy = true"
    @toggle:subscription="toggleSubscription"
  />

  <CoverView
    v-if="showCoverView"
    :title="title"
    @close="hideOverlay"
  />
  <ManualCopySidebar
    v-if="showManualCopy"
    :title="title"
    :releasesWithAnnotations="releasesWithAnnotations"
    @close="hideOverlay"
  />
</template>

<script lang="ts">
import CoverView from 'app/components/CoverView.vue';
import ManualCopySidebar from 'app/components/ManualCopySidebar.vue';
import TitleDetailsContextMenu from 'app/components/contextMenus/TitleDetailsContextMenu.vue';
import { getReleaseDisplayNameTitle } from 'app/functions/use-release-display-name';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import { TDReleasesWithAnnotationsSymbol } from 'app/keys/injection-keys';
import { FilterObject } from 'app/models/filter-object';
import { Title } from 'app/models/title';
import { ComputedRef, PropType, defineComponent, inject, nextTick, ref } from 'vue';

export default defineComponent({
  name: 'TitleDetailsContextMenuButton',
  components: {
    CoverView,
    TitleDetailsContextMenu,
    ManualCopySidebar
},
  props: {
    title: {
      type: Object as PropType<Title>,
      required: true
    }
  },
  setup: (props, ctx) => {
    const showContextMenu = ref<boolean>(false);
    const showCoverView = ref<boolean>(false);
    const showManualCopy = ref<boolean>(false);

    // Manual copy sidebar info
    const releasesWithAnnotations = inject(TDReleasesWithAnnotationsSymbol, undefined) as ComputedRef<FilterObject[]> | undefined;
    const allowManualCopy = !!(releasesWithAnnotations?.value.filter((release) => release.name !== getReleaseDisplayNameTitle(props.title)).length);

    const {
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'title actions menu');

    const contextMenuButton = ref<HTMLElement | null>(null);

    const hideOverlay = async () => {
      showCoverView.value = false;
      showManualCopy.value = false;

      await nextTick();
      contextMenuButton.value?.focus();
    };

    return {
      allowManualCopy,
      contextMenuButton,
      releasesWithAnnotations,
      showContextMenu,
      showCoverView,
      showManualCopy,
      subscribeTextLong,
      hideOverlay,
      toggleSubscription
    };
  }
});
</script>

<style module>
.context-menu-button {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.context-menu-button svg {
  fill: var(--c-white);
  width: 1.5rem;
  height: 1.5rem;
}

</style>
