import { Ref, computed } from 'vue';

type AutocompleteOptions = {
  maxResults?: number;
  caseInsensitiveMatching?: boolean;
  minChars?: number;
};

const autocompleteDefaults = {
  maxResults: 20,
  caseInsensitiveMatching: true,
  minChars: 2
} as AutocompleteOptions;

export function useAutocomplete(userTyping: Ref<string>, dataset: Ref<string[]>, options = {}) {
  const fullOptions = { ...autocompleteDefaults, ...options };
  const trimmedTyping = computed(() =>userTyping.value.trim());

  const getFilteredItems = (cleanQuery: string): string[] => {
    const filteredKeys: string[] = [];

    dataset.value.forEach((name) => {
      if (filteredKeys.length < fullOptions.maxResults!) {
        const nameComparable = fullOptions.caseInsensitiveMatching ? name.toLowerCase() : name;
        const queryComparable = fullOptions.caseInsensitiveMatching ? cleanQuery.toLowerCase() : cleanQuery;

        if (nameComparable.includes(queryComparable)) {
          filteredKeys.push(name);
        }
      }
    });

    return filteredKeys;
  };

  const hints = computed(() => {
    if (trimmedTyping.value.length >= fullOptions.minChars!) {
      return getFilteredItems(trimmedTyping.value);
    }

    return [];
  });


  return {
    hints
  };
};
