/**
 * Return a date that is `days` from `relativeTo` (defaults to now)
 *   Ex: at 2018-01-25 13:43:10
 *     - getDaysFromNow(-3) returns 2018-01-25 00:00:00
 *     - getDaysFromNow(-3, true) returns 2018-01-25 13:43:10
 * @param days
 */
export function getRelativeDate(days: number, currentTime = false, relativeTo = new Date()): Date {
  const result = new Date(relativeTo.getTime());
  result.setDate(result.getDate() + days);

  if (!currentTime) {
    result.setHours(0, 0, 0, 0);
  }

  return result;
}

/**
 * Return date object representing yesterday at midnight
 */
export function yesterday(): Date {
  return getRelativeDate(-1);
}

/**
 * Convert release date strings into Date objects that can be localized
 * @param releaseDate Release date string
 */
export function releaseDateToDate(releaseDate: string | undefined) {
  if (!releaseDate) { return undefined; }

  // Some release dates may only be listed as yyyy or perhaps even mm/yyyy
  // so we need to account for those so we don't pass around Invalid Dates
  const groups = [...releaseDate.matchAll(/\//g)];

  if (groups.length === 2) {
    const [month, day, year] = releaseDate.split('/').map((v) => parseInt(v, 10));

    return new Date(year, month - 1, day);
  } else if (groups.length === 1) {
    const [month, year] = releaseDate.split('/').map((v) => parseInt(v, 10));

    return new Date(year, month - 1);
  } else {
    return new Date(parseInt(releaseDate, 10), 0);
  }
};
