<template>
  <SidebarDialog
    ref="dialog"
    :heading="$t('annotationCopying.manualCopy.header')"
    :resizable="false"
    :dismissible="!loading"
    @close="$emit('close')"
  >
    <ManualCopyContent
      :title="title"
      :releasesWithAnnotations="releasesWithAnnotations"
      @loading="loading = true"
      @submit="onSubmit"
    />
  </SidebarDialog>
</template>

<script lang="ts">
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import ManualCopyContent from 'app/components/ManualCopyContent.vue';
import { FilterObject } from 'app/models/filter-object';
import { TitleRecord } from 'app/models/title';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'ManualCopySidebar',
  components: {
    ManualCopyContent,
    SidebarDialog
},
  props: {
     title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    releasesWithAnnotations: {
      type: Array as PropType<FilterObject[]>,
      default: () => []
    }
  },
  emits: [
    'close'
  ],
  setup: (_) => {
    const dialog = ref<InstanceType<typeof SidebarDialog> | null>(null);
    const loading = ref(false);

    const onSubmit = () => {
      dialog.value?.closeDialog();
    };

    return {
      dialog,
      loading,
      onSubmit
    };
  }
});
</script>

<style module>
</style>
