import { useColorFilter } from 'app/functions/use-color-filter';
import { useTextFilter } from 'app/functions/use-text-filter';
import { Annotation } from 'app/models/annotation';
import { Ref, computed } from 'vue';

export type ExportCategory = 'Color';

export type ExportFilters = {
  [category in ExportCategory]: string[] | undefined;
};

export function useExportFilters(annotations: Readonly<Ref<Annotation[]>>) {

  const {
    colorFilter,
    filteredItems: colorFilteredAnnotations,
    getFilterObjects: colorGetFilterObjects
  } = useColorFilter(annotations);

  const {
    textFilter,
    filteredItems: filteredAnnotations
  } = useTextFilter(colorFilteredAnnotations, (a, text) => Annotation.FILTER_FUNCTIONS.filterByText(a, text));

  const filterObjects = computed(() => colorGetFilterObjects(colorFilteredAnnotations.value));

  const displayCounts = computed(() => {
    return {
      showing: filteredAnnotations.value.length || 0,
      total: colorFilteredAnnotations.value.length || 0
    };
  });

  return {
    textFilter,
    colorFilter,

    filteredAnnotations,
    filterObjects,
    displayCounts
  };
};

export const getQueryParametersFromExportFilters = (filters: ExportFilters | undefined) => {
  if (!filters) { return {}; }

  return {
    color: filters.Color?.join(',') || undefined
  };
};
