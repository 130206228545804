export const MS_SECOND = 1000;
export const MS_MINUTE = MS_SECOND * 60;
export const MS_HOUR = MS_MINUTE * 60;
export const MS_DAY = MS_HOUR * 24;
export const MS_YEAR = MS_DAY * 365;

/**
 * @desc Constant for 'humanDateFromNow()' to define when the method should stop returning
 * "days ago" and start returning the actual short date.
 */
const MAX_DAYS_FROM_NOW = 3;

type Duration = keyof TimeUnits;

export interface DateUnits {
  date: number;
  month: number;
  year: number;
  monthName?: string;
  thisDate?: boolean;
  thisMonth?: boolean;
  thisDay?: boolean;
  thisYear?: boolean;
  thisPastYear?: boolean;
}

export interface TimeUnits {
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  weeks?: number;
}


export function epochMilliseconds(): number {
  return (new Date()).getTime();
}


export function epochSeconds(): number {
  return Math.floor(epochMilliseconds() / 1000);
}

export function toTimestamp(dateTime: string): number {
  return Date.parse(dateTime) / 1000;
}


export function secondsToUnits(seconds: number, maxUnit: Duration = 'weeks'): TimeUnits {
  const units: { label: Duration, mod: number }[] = [
    { label: 'seconds', mod: 60 },
    { label: 'minutes', mod: 60 },
    { label: 'hours', mod: 24 },
    { label: 'days', mod: 7 },
    { label: 'weeks', mod: 52 }
  ];
  const duration: { [key in Duration]: number } = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    weeks: 0
  };

  let x = seconds;

  for (const unit of units) {
    if (unit.label === maxUnit) {
      duration[unit.label] = x;
      break;
    }
    const tmp = x % unit.mod;
    duration[unit.label] = tmp;
    x = (x - tmp) / unit.mod;
  }

  return duration;
}


export function timestampToUnits(timestamp: number): DateUnits {
  const now = new Date();
  const then = new Date(timestamp);
  const units: DateUnits = {
    date: then.getDate(),
    month: then.getMonth(),
    year: then.getFullYear(),
    monthName: '',
    thisYear: false,
    thisMonth: false,
    thisDay: false,
    thisPastYear: false
  };

  const mons = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(' ');
  units.monthName = mons[units.month];

  if (now.getFullYear() === units.year) {
    units.thisYear = true;
  }
  if (now > then && now.getMilliseconds() - then.getMilliseconds() < MS_YEAR) {
    units.thisPastYear = true;
  }
  if (now.getMonth() === units.month && units.thisYear) {
    units.thisMonth = true;
  }
  if (now.getDate() === units.date && units.thisMonth && units.thisYear) {
    units.thisDay = true;
  }

  return units;
}

/**
 * @desc Converts an IDateUnits object into a human readable label
 * @param targetDate Date object to convert to a label
 * @param options Additional formatting options for the label
 * @returns A human readable label. Ex/ 27 MAR 2013, TODAY
 *
 */
export function timeUnitsToHumanDate(targetDate: DateUnits, textResolver: (label: string) => string, options: HumanDateOptions = {}): string {
  if (targetDate.thisDay && options.relative !== false) {
    return textResolver('units.day.today');
  }
  if (!options.year && (targetDate.thisYear || targetDate.thisPastYear)) {
    return [targetDate.date, targetDate.monthName].join(' ');
  }

  return [targetDate.date, targetDate.monthName, targetDate.year].join(' ');
}

/**
 * @desc Converts atimestamp into a human readable label
 * @param timestamp Date object to convert to a label
 * @returns A human readable label. Ex/ 27 5 hours, 1 day
 */
export function humanDateFromNow(timestamp: number, textResolver: (label: string) => string): string {
  if (!timestamp || timestamp <= 0) {
    return '';
  }

  const [n, sign, unitKey] = dateFromNow(timestamp);

  if (unitKey === 'date') {
    // TODO: i18n; date format currently restrictied to en-us
    return new Date(timestamp).toLocaleDateString('en-us', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  }

  const translateKey = `units.${unitKey}.${(n === 1 ? 'singular' : 'plural')}`;
  const prefix = (sign === 1) ? `${textResolver('prefixes.in')} ` : '';
  const suffix = (sign === -1) ? ` ${textResolver('suffixes.ago')}` : '';

  return `${prefix}${n}${textResolver(translateKey)}${suffix}`;
}

export function dateFromNow(timestamp: number): [number, -1 | 1, 'day' | 'hour' | 'minute' | 'second' | 'date' ] {
  const now = epochMilliseconds();
  const diff = Math.abs(now - timestamp);
  const sign = Math.sign(timestamp - now) as -1 | 1;

  let unitKey: 'day' | 'hour' | 'minute' | 'second' | 'date';
  let n: number;
  if (diff / MS_DAY >= 1) {
    unitKey = 'day';
    n = diff / MS_DAY;

    if (Math.round(n) > MAX_DAYS_FROM_NOW) {
      unitKey = 'date';
    }
  } else if (diff / MS_HOUR >= 1) {
    unitKey = 'hour';
    n = diff / MS_HOUR;
  } else if (diff / MS_MINUTE >= 1) {
    unitKey = 'minute';
    n = Math.max(1, diff / MS_MINUTE);
  } else {
    unitKey = 'second';
    n = Math.max(1, diff / MS_SECOND);
  }

  // We want to use round for future time, to avoid things like "in 1 day/tomorrow" when it's just less than 2 full days away
  // And we want to use floor for past time, to avoid things like "60 minutes ago"
  n = sign === 1 ? Math.round(n) : Math.floor(n);

  return [n, sign, unitKey];
}

/**
 * Take epoch time (in milliseconds) and convert to human readable formatt
 * @param timestamp
 * @param options
 */
export function timestampToHumanDate(timestamp: number, textResolver: (label: string) => string, options?: HumanDateOptions): string {
  return timeUnitsToHumanDate(timestampToUnits(timestamp), textResolver, options);
}

export function timeUnitsToNumericalDate(timeUnits: DateUnits) {
  const month = timeUnits.month + 1;
  let monthString = month.toString();
  monthString = monthString.length === 2 ? monthString : `0${monthString}`;
  const day = timeUnits.date;
  let dayString = day.toString();
  dayString = dayString.length === 2 ? dayString : `0${dayString}`;

  return [monthString, dayString, timeUnits.year].join('/');
}

interface HumanDateOptions {
  relative?: boolean;
  /**
   * @desc True to include the year in the label
   * @note Does not apply to dates older than the past year
   */
  year?: boolean;
  abbreviation?: boolean;
}
export function hmsToSeconds(interval: string): number {
  const intervalParts = interval.match(/([0-9]+):([0-9]+):([0-9]+)(\.[0-9]+)?/);
  if (intervalParts && intervalParts.length >= 4) {
    const hours = parseInt(intervalParts[1], 10);
    const minutes = parseInt(intervalParts[2], 10);
    const seconds = parseInt(intervalParts[3], 10);

    return (hours * 3600) + (minutes * 60) + seconds;
  }

  return 0;
}

/**
 * Options for the secondsToHumanDurationMethod
 */
interface HumanDurationOptions {
  /**
   * Max number of segments to display
   *  ex: if set to 2 it will show 1 hour 30 minutes instead of 1 hour 30 minutes 5 seconds
   */
  unitCount?: number;
  /**
   * Highest unit to show
   *  ex: if set to minutes, it will show 90 minutes instead of 1 hour 3 minutes
   */
  maxUnit?: Duration;
  minUnit?: Duration;
  abbreviate?: boolean;
}

/**
 * Convert a number of seconds to something more human readable.
 *   Ex: 3720 -> 1 hour 2 minutes
 * @param {string} seconds - Duration in seconds
 * @param {HumanDurationOptions} opts - Options for formatting result
 */
export function secondsToHumanDuration(seconds: number, opts: HumanDurationOptions = {}): string {
  if (seconds <= 0) {
    return pluralizeTimeUnit(0, opts.minUnit || 'seconds', opts.abbreviate);
  }

  const timeUnits = secondsToUnits(seconds, opts.maxUnit);
  const result: string[] = [];

  // Get keys in high to low order
  const keys = <Duration[]>Object.keys(timeUnits).reverse();

  for (const unit of keys) {
    let value = timeUnits[unit];
    value = Math.round(value!);
    if (value || result.length || unit === opts.minUnit) {
      result.push(`${pluralizeTimeUnit(value, unit, opts.abbreviate)}`);
      if (opts.unitCount === result.length || opts.minUnit === unit) {
        break;
      }
    }
  }

  return result.join(' ');
}


function pluralizeTimeUnit(value: number, label: Duration, abbreviate = false): string {
  let finalLabel: string = label;
  if (abbreviate) {
    finalLabel = {
      seconds: 'secs',
      minutes: 'mins',
      hours: 'hrs',
      days: 'days',
      weeks: 'wks'
    }[label];
  }
  const labelLength = finalLabel.length;
  if (value === 1 && labelLength > 1 && finalLabel.substr(labelLength - 1) === 's') {
    return `${value} ${finalLabel.substr(0, labelLength - 1)}`;
  }

  return `${value} ${finalLabel}`;
}
