import env from 'app/base/env';
import { APP } from './app';
import { Service } from './services/service';

export class Taggish extends Service {
  constructor() {
    super('TAGGISH', (path) => {
      return { url: `${env.TAGGISH_URI}/${path}` };
    });
  }


  public async getTags(): Promise<TaggishTagsResponse | null> {
    let result = await this.fetchAsync<TaggishTagsResponse>(
      {
        url: `tags/${APP.patron.accountId}`,
        headers: {
          Authorization: `Bearer ${APP.sentry.identityToken}`
        }
      }
    );

    if (!result) {
      result = { tags: { items: [] } };
    }

    return result;
  }
}

export interface TaggishTagsResponse {
  tags: {
    items: TaggishTagResponse[];
  };
}


export interface TaggishTagResponse {
  tagUUID: string;
  name: string;
  slug: string;
  flags: {
    useIcon?: boolean;
    isRenameable?: boolean;
    isLocked?: boolean;
  };
  timestamp: number;
  taggings: TaggishTaggingResponse;
}

interface TaggishTaggingResponse {
  count: number;
  offset: number;
  limit: number;
  items: TagRemoteResponse[];
}

export interface TagRemoteResponse {
  timestamp: number;
  title: { titleId: string };
}
