import ESGenDarkCompact_svg from 'res/svg/empty-states/compact/generic_dark_compact.svg?raw';
import ESGenLightCompact_svg from 'res/svg/empty-states/compact/generic_light_compact.svg?raw';
import ESTagsLightCompact_svg from 'res/svg/empty-states/compact/tags_light_compact.svg?raw';
import ESGenLightShort_svg from 'res/svg/empty-states/regular/generic_light_short.svg?raw';
import ESGenLightWide_svg from 'res/svg/empty-states/regular/generic_light_wide.svg?raw';
import ExclamationMark_svg from 'res/svg/exclamation-mark.svg?raw';
import IconAlertBellUnreviewed_svg from 'res/svg/icon-alert-bell-unreviewed.svg?raw';
import IconAlertBell_svg from 'res/svg/icon-alert-bell.svg?raw';
import IconNavNotes_svg from 'res/svg/icon-annotations.svg?raw';
import IconAudiobook_text_svg from 'res/svg/icon-audiobook.text.svg?raw';
import IconBook_text_svg from 'res/svg/icon-book.text.svg?raw';
import IconNavBrowse_svg from 'res/svg/icon-browse.svg?raw';
import IconCheck_svg from 'res/svg/icon-check.svg?raw';
import IconChevronDown_svg from 'res/svg/icon-chevron-down.svg?raw';
import IconChevronLeft_svg from 'res/svg/icon-chevron-left.svg?raw';
import IconChevronRight_svg from 'res/svg/icon-chevron-right.svg?raw';
import IconChevronUp_svg from 'res/svg/icon-chevron-up.svg?raw';
import IconCopyLinkToClipboard_svg from 'res/svg/icon-copy-link-to-clipboard.svg?raw';
import IconCopyToClipboard_svg from 'res/svg/icon-copy-to-clipboard.svg?raw';
import IconDismiss_text_svg from 'res/svg/icon-dismiss.svg?raw';
import IconDlDownloaded_text_svg from 'res/svg/icon-dl-downloaded.text.svg?raw';
import IconDlDownloading_text_svg from 'res/svg/icon-dl-downloading.text.svg?raw';
import IconDlErrored_text_svg from 'res/svg/icon-dl-errored.text.svg?raw';
import IconDlPaused_text_svg from 'res/svg/icon-dl-paused.text.svg?raw';
import IconDlQueued_text_svg from 'res/svg/icon-dl-queued.text.svg?raw';
import IconDlStreaming_text_svg from 'res/svg/icon-dl-streaming.text.svg?raw';
import IconEllipsis_svg from 'res/svg/icon-ellipsis.svg?raw';
import IconExpirationClock_svg from 'res/svg/icon-expire-clock.svg?raw';
import IconExport_svg from 'res/svg/icon-export.svg?raw';
import IconEyeClosed_svg from 'res/svg/icon-eye-closed.svg?raw';
import IconEyeOpen_svg from 'res/svg/icon-eye-open.svg?raw';
import IconFeedback_svg from 'res/svg/icon-feedback.svg?raw';
import IconFileCsv_svg from 'res/svg/icon-file-csv.svg?raw';
import IconFilePdf_svg from 'res/svg/icon-file-pdf.svg?raw';
import IconGoogleDrive_svg from 'res/svg/icon-google-drive.svg?raw';
import IconHighlight_svg from 'res/svg/icon-highlight.svg?raw';
import IconNavHome_svg from 'res/svg/icon-home.svg?raw';
import IconInfo_svg from 'res/svg/icon-info.svg?raw';
import IconMenu_svg from 'res/svg/icon-menu.svg?raw';
import IconNote_svg from 'res/svg/icon-note.svg?raw';
import IconNavNtc_svg from 'res/svg/icon-ntc.svg?raw';
import IconOverflow_svg from 'res/svg/icon-overflow.svg?raw';
import IconPdf_text_svg from 'res/svg/icon-pdf.svg?raw';
import IconRefineList_text_svg from 'res/svg/icon-refine-list.text.svg?raw';
import IconSearch_svg from 'res/svg/icon-search.svg?raw';
import IconSeries_svg from 'res/svg/icon-series.svg?raw';
import IconNavMyBooks_svg from 'res/svg/icon-shelf.svg?raw';
import IconSort_text_svg from 'res/svg/icon-sort.text.svg?raw';
import IconSpinner_text_svg from 'res/svg/icon-spinner.text.svg?raw';
import IconNavTags_svg from 'res/svg/icon-tags.svg?raw';
import TruncationExpander_svg from 'res/svg/icon-truncation-expander.svg?raw';
import IconUser_svg from 'res/svg/icon-user.svg?raw';
import IconVideo_text_svg from 'res/svg/icon-video.svg?raw';
import IconWarning_svg from 'res/svg/icon-warning.svg?raw';
import LN_Logo_svg from 'res/svg/logo_ln_wh.svg?raw';
import OD_Logo_svg from 'res/svg/logo_od_wh_sm.svg?raw';
import Minus_svg from 'res/svg/minus.svg?raw';
import Trashcan_svg from 'res/svg/trashcan.svg?raw';

/**
 * Icons that are used by the Icon.vue component
 * @note list sorted alphabetically, by key
 */
const Icons = {
  'alert-bell': IconAlertBell_svg,
  'alert-bell-unreviewed': IconAlertBellUnreviewed_svg,
  'audiobook': IconAudiobook_text_svg,
  'book': IconBook_text_svg,
  'check': IconCheck_svg,
  'chevron-down': IconChevronDown_svg,
  'chevron-left': IconChevronLeft_svg,
  'chevron-right': IconChevronRight_svg,
  'chevron-up': IconChevronUp_svg,
  'copy-to-clipboard': IconCopyToClipboard_svg,
  'copy-link-to-clipboard': IconCopyLinkToClipboard_svg,
  'csv': IconFileCsv_svg,
  'dismiss': IconDismiss_text_svg,

  // Download
  'dl-downloaded': IconDlDownloaded_text_svg,
  'dl-errored': IconDlErrored_text_svg,
  'dl-downloading': IconDlDownloading_text_svg,
  'dl-paused': IconDlPaused_text_svg,
  'dl-queued': IconDlQueued_text_svg,
  'dl-streaming': IconDlStreaming_text_svg,

  'ellipsis': IconEllipsis_svg,

  // Empty States
  'es-generic-dark-compact': ESGenDarkCompact_svg,
  'es-generic-light-compact': ESGenLightCompact_svg,
  'es-generic-light-short': ESGenLightShort_svg,
  'es-generic-light-wide': ESGenLightWide_svg,
  'es-tags-light-compact': ESTagsLightCompact_svg,

  'exclamation-mark': ExclamationMark_svg,
  'expire-clock': IconExpirationClock_svg,
  'export': IconExport_svg,
  'eye-closed': IconEyeClosed_svg,
  'eye-open': IconEyeOpen_svg,
  'feedback': IconFeedback_svg,
  'file-pdf': IconFilePdf_svg,
  'google-drive': IconGoogleDrive_svg,
  'highlight': IconHighlight_svg,
  'info': IconInfo_svg,
  'ln-logo': LN_Logo_svg,
  'menu': IconMenu_svg,
  'minus': Minus_svg,

  // Navigation
  'nav-browse': IconNavBrowse_svg,
  'nav-export': IconExport_svg,
  'nav-home': IconNavHome_svg,
  'nav-mybooks': IconNavMyBooks_svg,
  'nav-notes': IconNavNotes_svg,
  'nav-tags': IconNavTags_svg,
  'nav-ntc': IconNavNtc_svg,

  'note': IconNote_svg,
  'od-logo': OD_Logo_svg,
  'overflow': IconOverflow_svg,
  'pdf': IconPdf_text_svg,
  'refine-list': IconRefineList_text_svg,
  'search': IconSearch_svg,
  'series': IconSeries_svg,
  'sort': IconSort_text_svg,
  'spinner': IconSpinner_text_svg,
  'trashcan': Trashcan_svg,
  'truncation-expander': TruncationExpander_svg,
  'user': IconUser_svg,
  'video': IconVideo_text_svg,
  'warning': IconWarning_svg
};

export default Icons;
