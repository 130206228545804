import { Quirks } from 'lib/quirkbase/quirkbase';

declare var window: any;
declare var navigator: any;

// Is a standard implementation of the Pointer Events specification present?
// https://www.w3.org/TR/pointerevents/
//
Quirks.add('pointer-contact-events-w3', typeof PointerEvent !== 'undefined');

// Is any implementation of Pointer Events present?
//
Quirks.add(
  'pointer-contact-events',
  Quirks.has('pointer-contact-events-w3') || Quirks.has('pointer-contact-events-ms')
);

// Sometimes we want to change wording (e.g., 'tap' instead of 'click')
// if the device's pointers include touch.
//
Quirks.add('has-touch-pointers', 'ontouchstart' in window ? true : false);

// Do we need to register a touchstart listener on all click listeners
// in order to trigger the :active pseudoclass?
//
Quirks.add('active-pseudoclass-needs-touchstart', 'ios<11');

// Decides whether to use the legacy onTap handler if clicks have
// the old mobile 300ms delay.
//
Quirks.add('legacy-on-tap', 'ios-uiwebview');

export default Quirks;
