import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(_ctx.$style.subjectList)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjects, (subject) => {
      return (_openBlock(), _createElementBlock("li", {
        key: subject.id
      }, [
        _createVNode(_component_router_link, {
          to: _ctx.subjectLink(subject.id)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(subject.name), 1)
          ], undefined, true),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ], 2))
}