import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["aria-disabled"]
const _hoisted_3 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(_ctx.$style.card)
  }, [
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.header),
      style: _normalizeStyle([
        `--background-color: rgb(${_ctx.provider.backgroundColor.join(',')})`,
        `--font-color: var(${_ctx.fontColor})`
      ])
    }, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.provider.name), 1),
      _createElementVNode("img", {
        src: _ctx.provider.icon,
        class: _normalizeClass(_ctx.$style.icon),
        role: "presentation"
      }, null, 10, _hoisted_1)
    ], 6),
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.$style.content)
    }, _toDisplayString(_ctx.provider.description), 3),
    _createElementVNode("footer", {
      class: _normalizeClass(_ctx.$style.footer)
    }, [
      _createElementVNode("button", {
        ref: "accessButton",
        class: _normalizeClass({
          [_ctx.$style.access]: true,
          [_ctx.$style.disabled]: _ctx.provider.unavailable
        }),
        "aria-disabled": _ctx.provider.unavailable,
        "aria-haspopup": "dialog",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.provider.unavailable ? undefined : _ctx.$emit('access')))
      }, _toDisplayString(_ctx.$t('ntc.access')), 11, _hoisted_2),
      (_ctx.provider.unavailable)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.$style.unavailableMessage)
          }, _toDisplayString(_ctx.$t('ntc.unavailable')), 3))
        : _createCommentVNode("", true),
      (_ctx.provider.loan && !_ctx.provider.unavailable)
        ? (_openBlock(), _createElementBlock("dl", {
            key: 1,
            class: _normalizeClass(_ctx.$style.time)
          }, [
            _createElementVNode("dt", _hoisted_3, _toDisplayString(_ctx.$t('ntc.expireTime')), 1),
            _createElementVNode("dd", null, [
              _createVNode(_component_Icon, { name: "expire-clock" }),
              _createVNode(_component_RelativeDate, {
                timestamp: _ctx.provider.loan.expireTime
              }, null, 8, ["timestamp"])
            ])
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}