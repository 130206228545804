import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(_ctx.$style.notFound)
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.$style.header)
    }, _toDisplayString(_ctx.header), 3),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.$style.message)
      }, _toDisplayString(_ctx.message), 3),
      _createElementVNode("a", {
        class: _normalizeClass(_ctx.$style.link),
        href: _ctx.anchor
      }, _toDisplayString(_ctx.link), 11, _hoisted_1)
    ])
  ], 2))
}