import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.logoError)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: _normalizeClass(_ctx.$style.image),
        src: _ctx.logoUrl,
        alt: _ctx.logoAlt,
        onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logoError = true))
      }, null, 42, _hoisted_1))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass(_ctx.$style.fallback)
      }, _toDisplayString(_ctx.logoAlt), 3))
}