import { SetupContext, computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';

type DialogState = 'closed' | 'opening' | 'open' | 'closing';

export function useDialog(ctx: SetupContext) {
  const animationDurationMs = ref(300);

  const dialog = ref<HTMLDialogElement | null>(null);

  const state = ref<DialogState>('closed');

  const styles =  computed(() => {
    return {
      '--animation-duration': `${animationDurationMs.value}ms`
    };
  });

  /* For applying animation classes.
   * Gets set to 'true' as soon as the dialog opens and can start animating.
   * Gets set to 'false' as soon as the dialog is told to close and the timeout
   * to actually close the dialog starts.
   */
  const animationOpen = computed(() => ['open', 'opening'].includes(state.value));

  const closeDialog = async () => {
    await nextTick();
    state.value = 'closing';
    setTimeout(() => {
      ctx.emit('close');
    }, animationDurationMs.value);
  };

  onMounted(() => {
    dialog.value?.showModal();
    state.value = 'opening';
    setTimeout(() => {
      state.value = 'open';
    }, animationDurationMs.value);
  });

  onBeforeUnmount(() => {
    state.value = 'closed';
    dialog.value?.close();
  });

  ctx.expose({
    closeDialog
  });

  return {
    animationDurationMs,
    animationOpen,
    closeDialog,
    dialog,
    state,
    styles
  };
};
