import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Freshable } from './freshable';

export class Freshness {
  public static DEFAULT_TTL = 60 * 60 * 1000;

  public freshAt: number;
  public isAsyncFreshening: boolean;
  public ttl: number;

  protected owner: Freshable;


  /**
   *
   * @param owner
   * @param options - ttl: integer - in seconds, overrides default TTL
   */
  constructor(owner: Freshable, ttl?: number, freshAt?: number) {
    this.owner = owner;
    this.ttl = ttl || Freshness.DEFAULT_TTL;
    this.freshAt = freshAt || 0;
    this.isAsyncFreshening = false;
  }


  public isFresh(): boolean {
    if (this.freshAt < APP.freshHorizon) {
      return false;
    }

    if (!this.owner.arePropertiesFresh()) {
      return false;
    }

    return this.freshAt + this.ttl >= C.epochMilliseconds();
  }


  public isFreshening(): boolean {
    return this.isAsyncFreshening;
  }


  public makeStale(): void {
    this.freshAt = -1;
  }
}
