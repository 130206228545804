<template>
  <BrowseGroup
    :name="$t('browse.subjects')"
    description=""
    :buttonLabel="buttonLabel"
    :navigateLink="navigateLink"
    :aria-label="$t('browse.subjects')"
  >
    <ol :class="$style.content">
      <li
        v-for="subject in subjects.slice(0, 16)"
        :key="subject.id"
        :class="$style.item"
      >
        <span
          :class="$style.titleCount"
          :aria-hidden="true"
        >
          {{ subject.totalItems }}
        </span>
        <router-link
          :class="$style.name"
          :to="subjectRoute(subject.id)"
          :aria-label="$t('browse.subjectWithCount', { NAME: subject.name, N: subject.count })"
        >
          {{ subject.name }}
        </router-link>
      </li>
    </ol>
  </BrowseGroup>
</template>

<script lang='ts'>
import { ThunderFacetItem } from 'app/base/thunder';
import BrowseGroup from 'app/components/BrowseGroup.vue';
import { useI18n } from 'app/functions/use-i18n';
import { subjectRoute } from 'app/models/subject';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BrowseGroupSubjects',
  components: {
    BrowseGroup
  },
  props: {
    subjects: {
      type: Array as () => ThunderFacetItem[],
      required: true
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    const buttonLabel = computed(() => {
      return props.subjects.length
        ? t('browse.seeAll.subjects', { N: props.subjects.length })
        : t('browse.seeAll.generic');
    });

    const navigateLink = {
      name: RouteName.Subjects
    };

    return {
      buttonLabel,
      navigateLink,
      subjectRoute
    };
  }
});
</script>

<style module>

.content {
  composes: list-grid from global;
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 4;
  --grid-item-min-width: 250px;
}

.item {
  display: grid;
  grid-template-columns: 75px auto 1fr;
  grid-gap: 5px;
}

.title-count {
  color: var(--c-light-black);
}

.name {
  composes: linked from global;
  composes: focus-outline from global;
  color: var(--c-primary-blue);
}
</style>
