import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_WelcomeView = _resolveComponent("WelcomeView")!

  return (_openBlock(), _createBlock(_component_WelcomeView, {
    backButtonLabel: _ctx.backButtonLabel,
    onBack: _ctx.back
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ], undefined, true),
    _: 1
  }, 8, ["backButtonLabel", "onBack"]))
}