import { BuildEnvironment } from 'app/base/env';
import { Analytics, getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { FirebaseApp, deleteApp, initializeApp } from 'firebase/app';
import { RemoteConfig, ensureInitialized, fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { APP } from '../app';


/**
 * Wrapper to handle all things firebase
 * @see {@link https://firebase.google.com/docs}
 */
export class ODFirebase {
  private readonly RESERVED_ANALYTICS_USER_PROPERTIES = ['age', 'gender', 'interest'];

  protected app: FirebaseApp | undefined;
  protected remoteConfig: RemoteConfig | undefined;
  protected analytics: Analytics | undefined;


  /**
   * Initialize our firebase instance
   */
  constructor(configuration: FirebaseConfig) {
    this.app = initializeApp(configuration);
    this.remoteConfig = getRemoteConfig(this.app);
    this.analytics = getAnalytics(this.app);
  }


  /**
   * Tear down firebase instance. Will render the instance unusable, so only call before removing references.
   */
  public async release() {
    console.log('[OD-FIREBASE] Releasing Firebase resources');

    if (this.app) {
      await deleteApp(this.app);
    }
  }


  // ----- ANALYTICS -----

  /**
   * Make a call to the firebase analytics logEvent method
   * @param eventName Name of the event to log.
   * @param eventParams A flat object of properties to send to analytics.
   * Param names can be up to 40 characters long,
   * may only contain alphanumeric characters and underscores ("_"),
   * and must start with an alphabetic character.
   * Param values can be up to 100 characters long.
   * @see {@link https://developers.google.com/gtagjs/reference/event}
   */
  public logAnalyticsEvent(eventName: string, eventParams: { [key: string]: any } = {}): void {
    const win = (window as any); // Sorry TS, here be dragons

    if (win.AnalyticsWebInterface) { // Android
      win.AnalyticsWebInterface.logEvent(eventName, JSON.stringify(eventParams));
    } else if (win.webkit // iOS
        && win.webkit.messageHandlers
        && win.webkit.messageHandlers.firebase) {
      win.webkit.messageHandlers.firebase.postMessage({
        command: 'logEvent',
        name: eventName,
        parameters: eventParams
      });
    } else if (this.analytics) { // Web
      logEvent(this.analytics, eventName, eventParams);
    }
  }


  /**
   * Set user information for the currently active user
   * @see {@link https://firebase.google.com/docs/analytics/user-properties}
   */
  public setAnalyticsUserProperty(name: string, value: string): void {
    // Validation:
    if (!name) { return; }

    // Google reserved names
    if (this.RESERVED_ANALYTICS_USER_PROPERTIES.includes(name)) {
      throw new Error(
        `Unable to set user property. The following values are reserved: ${this.RESERVED_ANALYTICS_USER_PROPERTIES.join(',')}`
      );
    }

    const win = (window as any); // TS doesn't know about these analytic props

    if (win.AnalyticsWebInterface) { // Android
      win.AnalyticsWebInterface.setUserProperty(name, value);
    } else if (win.webkit // iOS
        && win.webkit.messageHandlers
        && win.webkit.messageHandlers.firebase) {
      win.webkit.messageHandlers.firebase.postMessage({
        command: 'setUserProperty',
        name: name,
        value: value
      });
    } else if (this.analytics) {
      setUserProperties(this.analytics, { [name]: value });
    }
  }


  /**
   * Set user information for the currently active user
   * @param properties User properties to set
   * @see {@link https://firebase.google.com/docs/analytics/user-properties}
   */
  public setAnalyticsUserId(userId: string): void {
    if (this.analytics) {
      setUserId(this.analytics, userId);
    }
  }


  // ----- REMOTE CONFIG -----

  public async initializeRemoteConfig(): Promise<void> {
    try {
      if (this.remoteConfig) {
        console.log('[OD-FIREBASE] Initializing Firebase Remote Config');
        await ensureInitialized(this.remoteConfig);
        await fetchAndActivate(this.remoteConfig);
      }
    } catch (ex: any) {
      console.warn('[OD-FIREBASE] Unexpected error initializing Firebase Remote Config', ex);
      APP.sage.submit('error', {
        errorMessage: `Error initializing Firebase Remote Config: ${ex.errorMessage}`,
        errorSource: ex.errorSource,
        errorData: ex.errorData
      });
    }
  }


  /**
   *
   */
  public getRemoteConfigValue(key: string) {
    if (!this.remoteConfig) {
      console.warn('[OD-FIREBASE] Remote Config unavailable. Unable to retrieve value for:', key);

      return null;
    }

    return getValue(this.remoteConfig, key).asString();
  }


  // ----- PRIVATE METHODS -----

  /**
   * Determines if the required config values are present for analytics
   * @param config The configuration values to inspect
   * @returns null if the configuration is missing any dependent values; otherwise, the config
   */
  public static configFromEnv(env: BuildEnvironment): FirebaseConfig | null {
    if (!(
      env.FIREBASE_API_KEY
      && env.FIREBASE_PROJECT_ID
      && env.FIREBASE_MEASUREMENT_ID
    )) {
      return null;
    }

    return {
      apiKey: env.FIREBASE_API_KEY,
      appId: env.FIREBASE_APP_ID!,
      authDomain: env.FIREBASE_AUTH_DOMAIN!,
      databaseURL: env.FIREBASE_DATABASE_URL!,
      measurementId: env.FIREBASE_MEASUREMENT_ID,
      messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID!,
      projectId: env.FIREBASE_PROJECT_ID,
      storageBucket: env.FIREBASE_STORAGE_BUCKET!
    };
  }
}


/**
 * Configuration object for Firebase
 * @see {@link https://firebase.google.com/docs}
 */
export interface FirebaseConfig {
  /**
   * General authentication for Firebase
   */
  apiKey?: string;
  /**
   * Unique identifier for Firebase application
   */
  appId?: string;
  /**
   * Auth with pop-up redirect
   */
  authDomain?: string;
  /**
   * Realtime database support
   */
  databaseURL?: string;
  /**
   * Google Analytics measurement ID
   */
  measurementId?: string;
  /**
   * Cloud messaging ID
   */
  messagingSenderId?: string;
  /**
   * Unique project identifier
   */
  projectId?: string;
  /**
   * Firebase storage
   */
  storageBucket?: string;
}
