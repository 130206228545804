type Serializer<T> = (o: T) => string;
type Deserializer<T> = (val: string) => T;

// Wraps a Storage object, restricts it to a specific type,
// and adds in serializers and deserializers for the typing.
export default class TypedStorage<T> {
  private readonly store: Storage;
  private readonly serializer: Serializer<T>;
  private readonly deserializer: Deserializer<T>;

  constructor(
    store: Storage,
    options: {
      serializer?: Serializer<T>;
      deserializer?: Deserializer<T>;
    } = {}
  ) {
    this.store = store;
    this.serializer = options.serializer || ((o: T) => JSON.stringify(o));
    this.deserializer = options.deserializer || ((val: string) => JSON.parse(val));
  }

  public get length() {
    return this.store.length;
  }

  public clear(): void {
    this.store.clear();
  }

  public getItem(key: string): T | null {
    const stored = this.store.getItem(key);

    return stored ? this.deserializer(stored) : null;
  }

  public key(index: number): string | null {
    return this.store.key(index);
  }

  public removeItem(key: string): void {
    this.store.removeItem(key);
  }

  public setItem(key: string, value: T): void {
    const serialized = this.serializer(value);

    this.store.setItem(key, serialized);
  }
}
