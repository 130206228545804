import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("h3", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createVNode(_component_Icon, { name: "book" }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('header.account.borrowingLimits.heading')), 1)
    ], 2),
    (_ctx.borrowLimits)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('header.account.borrowingLimits.borrowed', { BORROWED: _ctx.borrowLimits.current, TOTAL: _ctx.borrowLimits.maximum })), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('header.account.borrowingLimits.remaining', { REMAINING: _ctx.borrowLimits.remaining, TOTAL: _ctx.borrowLimits.maximum })), 1)
        ], 64))
      : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('header.account.borrowingLimits.loading')), 1))
  ]))
}