import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableOfContents = _resolveComponent("TableOfContents")!
  const _component_TitleDetailsAccordionDetails = _resolveComponent("TitleDetailsAccordionDetails")!
  const _component_AccordionSubjectList = _resolveComponent("AccordionSubjectList")!
  const _component_TitleDetailsAccordionVolumes = _resolveComponent("TitleDetailsAccordionVolumes")!
  const _component_TitlePriorReleaseList = _resolveComponent("TitlePriorReleaseList")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createBlock(_component_Accordion, { sections: _ctx.sections }, {
    toc: _withCtx(({ mobile }) => [
      _createVNode(_component_TableOfContents, {
        title: _ctx.title,
        showTitle: mobile
      }, null, 8, ["title", "showTitle"])
    ]),
    description: _withCtx(({ mobile }) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(mobile ? 'div' : 'ShowAll'), null, {
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.description),
            innerHTML: _ctx.title.description
          }, null, 10, _hoisted_1)
        ], undefined, true),
        _: 2
      }, 1024))
    ]),
    details: _withCtx(() => [
      _createVNode(_component_TitleDetailsAccordionDetails, { title: _ctx.title }, null, 8, ["title"])
    ]),
    subjects: _withCtx(() => [
      _createVNode(_component_AccordionSubjectList, {
        subjects: _ctx.title.subjects
      }, null, 8, ["subjects"])
    ]),
    volumes: _withCtx(() => [
      _createVNode(_component_TitleDetailsAccordionVolumes, { series: _ctx.series }, null, 8, ["series"])
    ]),
    releases: _withCtx(() => [
      _createVNode(_component_TitlePriorReleaseList, { title: _ctx.title }, null, 8, ["title"])
    ]),
    _: 1
  }, 8, ["sections"]))
}