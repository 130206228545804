import { APP } from 'app/base/app';
import { PostishCopyAnnotationSnapshotResponse } from 'app/base/postish';
import { useAppEvents } from 'app/functions/use-app-events';
import { isRef, readonly, Ref, ref, watch } from 'vue';


export function useCopyAnnotationSnapshots(jobIdRaw: Ref<string | null> | string) {
  const jobId = isRef(jobIdRaw) ? jobIdRaw : ref(jobIdRaw);

  const copyAnnotationSnapshots = ref<PostishCopyAnnotationSnapshotResponse | null>(null);
  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const update = async () => {
    if (!jobId.value) { return; }

    try {
      loading.value = true;

      const response = await APP.services.postish.getCopyAnnotationSnapshots(jobId.value);
      if (!response) { throw new Error; }
      copyAnnotationSnapshots.value = response;

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  watch(jobId, update, { immediate: true });

  if (!APP.patron.accountId || !APP.sentry.identityToken) {
    useAppEvents({
      'patron:accountId:acquired': () => update()
    });
  }

  return {
    copyAnnotationSnapshots: readonly(copyAnnotationSnapshots),
    loading: readonly(loading),
    error: readonly(error),
    update
  };

}
