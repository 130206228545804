import { Annotation } from 'app/models/annotation';
import { TitleRecord } from 'app/models/title';
import { computed, Ref, ref, watch } from 'vue';

type ReleaseWithAnnotations =
  {
    release: TitleRecord;
    annotations: Annotation[];
  };

export function useReleaseAnnotations(title: Ref<TitleRecord | null>, annotations: Ref<Annotation[]>) {
  const priorReleases = ref<TitleRecord[] | undefined>(undefined);
  const releasesLoaded = ref(false);

  watch(title, async () => {
    if (title.value) {
      priorReleases.value = await title.value.getPriorReleases();
      releasesLoaded.value = true;
    } else {
      priorReleases.value = undefined;
      releasesLoaded.value = false;
    }
  }, { immediate: true });

  const mostRecentReleaseWithAnnotations = computed<ReleaseWithAnnotations | undefined>(() => {
    if (annotations.value.length === 0) {
      return undefined;
    }

    if (!priorReleases.value || priorReleases.value.length === 0) {
      return undefined;
    }

    let i = 0;
    while (i < priorReleases.value.length) {
      const release = priorReleases.value[i];
      const releaseAnnotations = annotations.value.filter((a) =>
        a.release === release.lexisMetadata.release &&
        a.releaseDate === release.lexisMetadata.releaseDate);

      if (releaseAnnotations.length > 0) {
        return {
          release: release as TitleRecord,
          annotations: releaseAnnotations
        };
      }

      i++;
    }

    return undefined;
  });

  const isCopyingAvailable = computed(() => !!mostRecentReleaseWithAnnotations.value);

  return {
    isCopyingAvailable,
    mostRecentReleaseWithAnnotations,
    releasesLoaded
  };
};
