import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createBlock(_component_EmptyState, {
    class: _normalizeClass(_ctx.$style.emptyState),
    link: _ctx.helpLink,
    isCompact: _ctx.compact,
    header: _ctx.$t('alert.emptystate.header'),
    content: _ctx.$t('alert.emptystate.content'),
    linkLabel: _ctx.$t('alert.emptystate.link')
  }, null, 8, ["class", "link", "isCompact", "header", "content", "linkLabel"]))
}