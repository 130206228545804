import { C } from 'app/base/common';
import { Collation } from './collation';

export class Item {
  public static SERIAL_PROPERTY = 'v';
  public static SERIAL_VERSION = 1;

  public collation: Collation<any>;
  public removedFromCollation: boolean;


  public deserialize(attrs) {
    const norms = this._normalizeAttributes(attrs);
    if (norms) {
      this._deserializeAttributes(norms);
    }

    return this;
  }


  public serialize() {
    const out = this._serializeAttributes();
    out[Item.SERIAL_PROPERTY] = Item.SERIAL_VERSION;

    return out;
  }


  /**
   * Override in subclasses if you need to do something
   * when the item is removed from a collation
   */
  public removed() {
    return;
  }


  protected parseFreshenResponse(responseText) {
    let result = JSON.parse(responseText);
    if (result.items) {
      result = result.items[0];
      if (!result) { throw(new Error('Parse: item not found')); }
    }
    this.deserialize(result);
    if (this.collation) { this.collation.save(); }
  }


  protected _normalizeAttributes(attrs) {
    if (attrs[Item.SERIAL_PROPERTY]) {
      return C.absorb(attrs, {});
    }

    return this._transformRemoteAttributes(attrs);
  }


  /**
   * You should override in subclasses to take a thunder response object
   * and convert it into an object that can be deserialized.
   * @param attrs
   */
  protected _transformRemoteAttributes(attrs) {
    return attrs;
  }


  /**
   * Optionally override in subclasses
   * @param attrs
   */
  protected _deserializeAttributes(attrs) {
    C.each(attrs, (key, val) => { this[key] = val; });
  }


  /**
   * Override in subclasses if this object will ever be persisted
   */
  protected _serializeAttributes() {
    return {};
  }


  protected _utcStringToTime(utcString) {
    if (utcString) { return new Date(utcString).getTime(); }
  }
}
