import { APP } from 'app/base/app';
import { Library, LibraryMapper } from 'app/models/library';

type LibraryResolveState =
  | {
    state: 'success';
    library: Library;
  }
  | {
    state: 'error';
    error: LibraryResolveError;
  };

type LibraryResolveError
  = 'generic'
  | 'inaccessible'
  | 'invalid'
  | 'invalidType';

export async function resolve(key: string): Promise<LibraryResolveState> {
  try {
    const result = await APP.services.thunder.getLibraryByKey(key);

    if (!result) {
      return {
        state: 'error',
        error: 'invalid'
      };
    }

    const library = LibraryMapper.mapFromThunder(result);

    if (!library.isAccessible()) {
      return {
        state: 'error',
        error: 'inaccessible'
      };
    }

    if (!library.isElrondLibrary()) {
      return {
        state: 'error',
        error: 'invalidType'
      };
    }

    return {
      state: 'success',
      library
    };
  } catch (error) {
    console.error('[LIBRARY-RESOLVER] Failed to resolve library', error);
    APP.sage.submit('error', {
      errorMessage: 'Error resolving library',
      errorSource: 'library-resolver#resolve',
      errorData: {
        libraryKey: key,
        message: error.name + ': ' + error.message
      }
    });

    return {
      state: 'error',
      error: 'generic'
    };
  }
};
