<template>
  <svg
    :id="iconId"
    ref="root"
    :role="role"
    class="icon"
    :viewBox="viewBox"
    v-html="icon"
  />
</template>

<script lang="ts">
import { C } from 'app/base/common';
import Icons from 'app/base/icons';
import { generateUUID } from 'lib/common/uuid';
import { defineComponent, onBeforeUnmount, ref, watch } from 'vue';

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String as () => keyof typeof Icons,
      required: true
    },
    role: {
      type: String,
      default: 'presentation'
    }
  },
  setup: (props) => {
    const VIEWBOX_DEFAULT = '0 0 0 0';
    const iconId = `icon-${generateUUID()}`;
    const icon = ref('');
    const viewBox = ref(VIEWBOX_DEFAULT);
    const root = ref<SVGElement | null>(null);
    let element: HTMLElement | null = null;

    watch(() => props.name, () => {
      const rawSVG = Icons[props.name];
      if (!rawSVG) {
        // If the icon is missing, it will need to be set in ./src/app/base/icons.ts
        console.warn(`Unable to find icon for "${props.name}"`);

        return;
      }

      element = C.element({ html: rawSVG });
      const svgElement = element!.querySelector('svg');
      if (svgElement) {
        icon.value = svgElement.innerHTML;
        viewBox.value = svgElement.attributes.getNamedItem('viewBox')?.nodeValue || VIEWBOX_DEFAULT;
      }
    }, { immediate: true });

    onBeforeUnmount(() => {
      element?.remove();
    });

    return {
      icon,
      iconId,
      root,
      viewBox
    };
  }
});
</script>
